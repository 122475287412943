import { Button, Footer, Modal } from '@narvar/hum';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { Switch, useLocation } from 'react-router-dom';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import { fetchRetailerSettings } from '../../actions/exceptionAnalyticsActions';
import {
  fetchDashboards,
  fetchReportDefinitions,
} from '../../actions/reportingActions';
import { fetchStatus, fetchSelf } from '../../actions/userActions';
import { MONITOR_PRODUCT } from '../../constants/monitor/configurations';
import { CSM_LOGOUT, RETAILER_SWITCH } from '../../constants/storage';
import useInitialiseChameleonInfo from '../../hooks/useInitialiseChameleonInfo';
import useInitialiseFullStoryInfo from '../../hooks/useInitialiseFullStoryInfo';
import getRoleBasedLandingPage from '../../lib/getRoleBasedLandingPage';
import Navigation from '../Navigation';
import Layout from './Layout';
import routes from './routes';
import theme from '../../theme';

const Authorized = () => {
  const [isCsmStateOutOfSync, setCsmStateOutOfSync] = useState(false);
  const [
    isSwitchRetailerStateOutOfSync,
    setSwitchRetailerStateOutOfSync,
  ] = useState(false);
  const dispatch = useDispatch();
  const {
    user,
    profile,
    isCsm,
    retailerId,
    retailerIdToRetailerInfo,
    reporting: { hasFetchedDashboards } = {},
    exceptionsFeatureEnabled,
  } = useSelector(
    ({ userReducer, reporting, exceptionsAnalyticsReducer, uiReducer }) => ({
      user: userReducer.user,
      profile: userReducer.profileUser,
      isCsm: userReducer.user ? userReducer.user.customerSupportLogin : false,
      retailerId: userReducer.user ? userReducer.user.retailerId : undefined,
      retailerIdToRetailerInfo: userReducer.user
        ? userReducer.user.retailerIdToRetailerInfo
        : {},
      reporting,
      isNavOpen: uiReducer.isNavOpen,
      exceptionsFeatureEnabled:
        exceptionsAnalyticsReducer.exceptionsFeatureEnabled,
    }),
  );
  const location = useLocation();

  useInitialiseChameleonInfo(user, profile);
  useInitialiseFullStoryInfo(user, profile);

  const { t, i18n } = useTranslation('hum');

  useEffect(() => {
    if (!user) {
      dispatch(fetchStatus());
    }
  }, [user, dispatch]);

  useEffect(() => {
    dispatch(fetchSelf());
  }, [profile, dispatch]);

  useEffect(() => {
    if (
      exceptionsFeatureEnabled === null ||
      typeof exceptionsFeatureEnabled === 'undefined'
    ) {
      dispatch(fetchRetailerSettings());
    }
  }, [dispatch, exceptionsFeatureEnabled]);

  useEffect(() => {
    const handleStorage = event => {
      if (event.key === CSM_LOGOUT && isCsm) {
        setCsmStateOutOfSync(true);
      } else if (event.key === RETAILER_SWITCH) {
        setSwitchRetailerStateOutOfSync(true);
      }
    };

    window.addEventListener('storage', handleStorage);

    return () => window.removeEventListener('storage', handleStorage);
  }, [isCsm, retailerId]);

  useEffect(() => {
    if (user) {
      const { products = [] } = retailerIdToRetailerInfo[retailerId];
      if (products.includes(MONITOR_PRODUCT) && !hasFetchedDashboards) {
        dispatch(fetchDashboards());
        dispatch(fetchReportDefinitions());
      }
    }
  }, [
    dispatch,
    hasFetchedDashboards,
    retailerId,
    retailerIdToRetailerInfo,
    user,
  ]);

  const handleLocaleChange = async locale => {
    await i18n.changeLanguage(locale);
  };

  const homepageEnabled = useFeatureIsOn('hub-homepage');

  if (!user) return null;

  const isNarvarAdmin = user.rolesList.some(
    role => role.name === 'NARVAR_SUPER_ADMIN' || role.name === 'NARVAR_ADMIN',
  );

  // Only narvar admin can see locales still in development
  const availableLocales = isNarvarAdmin
    ? ['en', 'es', 'fr', 'it', 'ja']
    : ['en', 'ja'];

  return (
    <div>
      <Navigation />
      <Helmet
        defaultTitle="Narvar Hub"
        htmlAttributes={{ lang: 'en' }}
        meta={[
          { charset: 'utf-8' },
          { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
          { name: 'description', content: '' },
          {
            name: 'viewport',
            content: 'width=device-width, initial-scale=1',
          },
        ]}
        link={[
          { rel: 'icon', href: require('~src/assets/images/favicon.png') }, // eslint-disable-line global-require
          {
            rel: 'apple-touch-icon',
            href: require('~src/assets/images/apple-touch-icon.png'), // eslint-disable-line global-require
          },
          {
            rel: 'apple-touch-icon',
            sizes: '72x72',
            href: require('~src/assets/images/apple-touch-icon-72x72.png'), // eslint-disable-line global-require
          },
          {
            rel: 'apple-touch-icon',
            sizes: '114x114',
            href: require('~src/assets/images/apple-touch-icon-114x114.png'), // eslint-disable-line global-require
          },
        ]}
      />
      <div
        style={{
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          background: location.pathname === '/home' ? 'white' : 'inherit',
        }}
      >
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (!user) {
                return <Redirect to="/" />;
              }

              const { external, url } = getRoleBasedLandingPage(
                user,
                homepageEnabled,
              );

              if (external) {
                window.location.assign(url);
                return null;
              }

              return <Redirect to={url} />;
            }}
          />
          {routes.map(route => (
            <Layout
              component={route.component}
              key={route.path || '404'}
              exact={route.exact}
              path={route.path}
              route={Route}
            />
          ))}
        </Switch>
        <Footer
          i18n={t('footer', { returnObjects: true })}
          locales={availableLocales}
          showLocale
          currentLocale={i18n.resolvedLanguage}
          onLocaleChange={handleLocaleChange}
        />
        <Modal
          isOpen={isCsmStateOutOfSync || isSwitchRetailerStateOutOfSync}
          toggle={() => window.location.reload()}
          title="You are logged in as a different retailer"
        >
          <ModalBody>
            You switched to a different retailer account on another open tab.
            You will now be logged in to this page as that retailer.
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              isModal
              onClick={() => window.location.reload()}
            >
              GOT IT
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default Authorized;
