import React from 'react';
import Loadable from 'react-loadable';
import { Loader } from '@narvar/hum';
import {
  TEMP_RETURN_ANALYTICS,
  RETURN_ANALYTICS,
} from '../../constants/returns/dashboardAnalytics';

const Home = Loadable({
  loader: () => import(/* webpackChunkName: 'home' */ '../../containers/Home'),
  loading: Loader,
});

const Assist = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'home' */ '../../containers/Analytics/Assist'),
  loading: Loader,
});

export const Tracking = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'track' */ '../../containers/Analytics/Tracking'
    ),
  loading: Loader,
});

const Exceptions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'exceptions' */ '../../containers/Analytics/Exceptions'
    ),
  loading: Loader,
});

const ExceptionsReports = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'exceptionsReports' */ '../../containers/Exceptions/ExceptionsReports'
    ),
  loading: Loader,
});

const FeedbackEngagementDrilldown = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'feedbackEngagementDrilldown' */ '../../containers/Analytics/FeedbackEngagementDrilldown'
    ),
  loading: Loader,
});

// TODO: Remove when Returns analytics v2 is ready
const ReturnsAnalyticsV1 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsAnalyticsV1' */ '../../containers/Analytics/Returns'
    ),
  loading: Loader,
});

const ReturnsDashboardAnalytics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsDashboardAnalytics' */ '../../containers/Returns/ReturnsDashboardAnalytics'
    ),
  loading: Loader,
  render: (loaded, props) => {
    const ReturnsDashboardAnalyticsV1 = loaded.default;
    return (
      <ReturnsDashboardAnalyticsV1 {...props} version={RETURN_ANALYTICS} />
    );
  },
});

// Temporary url for version 2 returns dashboard
const ReturnsDashboardAnalyticsVersion2 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsDashboardAnalytics' */ '../../containers/Returns/ReturnsDashboardAnalytics'
    ),
  loading: Loader,
  render: (loaded, props) => {
    const ReturnsDashboardAnalyticsV2 = loaded.default;
    return (
      <ReturnsDashboardAnalyticsV2 {...props} version={TEMP_RETURN_ANALYTICS} />
    );
  },
});

const ReturnsOrderListView = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsOrderListView' */ '../../containers/Returns/ReturnsOrderListView'
    ),
  loading: Loader,
});

const Dashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'dashboard' */ '../../containers/Analytics/Dashboard'
    ),
  loading: Loader,
});

const DashboardsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'dashboardList' */ '../../containers/Analytics/Dashboards/List'
    ),
  loading: Loader,
});

const DashboardDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'dashboardDetails' */ '../../containers/Analytics/Dashboards/Details'
    ),
  loading: Loader,
});

const ChartDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'chartDetails' */ '../../containers/Analytics/ChartDetails'
    ),
  loading: Loader,
});

const PackagesList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'packagesList' */ '../../containers/Analytics/Packages/List'
    ),
  loading: Loader,
});

const PackageDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'packagesList' */ '../../containers/Analytics/Packages/Details'
    ),
  loading: Loader,
});

const Assets = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assets' */ '../../containers/ExperienceManager/Assets'
    ),
  loading: Loader,
});

const AssetsManager = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assetsManager' */ '../../containers/ExperienceManager/AssetsManager'
    ),
  loading: Loader,
});

const Asset = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'asset' */ '../../containers/ExperienceManager/AssetsManager/Asset'
    ),
  loading: Loader,
});

const Configurations = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'configurations' */ '../../containers/ExperienceManager/Configurations'
    ),
  loading: Loader,
});

const ConfigurationDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'configurationDetails' */ '../../containers/ExperienceManager/Configurations/ConfigurationDetails'
    ),
  loading: Loader,
});

const Files = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'files' */ '../../containers/ExperienceManager/Files'
    ),
  loading: Loader,
});

const GiftingSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'serviceCodesList' */ '../../containers/ExperienceManager/GiftingSettings'
    ),
  loading: Loader,
});

const ServiceCodesList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'serviceCodesList' */ '../../containers/ExperienceManager/ServiceCodes/List'
    ),
  loading: Loader,
});

const ServiceCodeDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'serviceCodeDetails' */ '../../containers/ExperienceManager/ServiceCodes/Details'
    ),
  loading: Loader,
});

const Style = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'style' */ '../../containers/ExperienceManager/Style'
    ),
  loading: Loader,
});

const TrackingCarrierCredentials = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'trackingCarrierCredentials' */ '../../containers/ExperienceManager/TrackingCarrierCredentials'
    ),
  loading: Loader,
});

const TrackingCarrierCredDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'trackingCarrierCredDetails' */ '../../containers/ExperienceManager/TrackingCarrierCredentials/TrackingCarrierCredDetails'
    ),
  loading: Loader,
});

const TrackSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'trackSettings' */ '../../containers/ExperienceManager/TrackSettings'
    ),
  loading: Loader,
});

const TrackEDDSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'trackEDDSettings' */ '../../containers/ExperienceManager/TrackEDDSettings'
    ),
  loading: Loader,
});

const Messages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'messages' */ '../../containers/Settings/Messages'
    ),
  loading: Loader,
});

const Message = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'message' */ '../../containers/Settings/Messages/Message'
    ),
  loading: Loader,
});

const Profile = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'profile' */ '../../containers/Settings/Profile'
    ),
  loading: Loader,
});

const ReturnsRules = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsRules' */ '../../containers/Settings/ReturnsRules'
    ),
  loading: Loader,
});

const ReturnsRulesV3 = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsRulesV3' */ '../../containers/Returns/ReturnsRules.v.3.0'
    ),
  loading: Loader,
});

const Tenants = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'tenants' */ '../../containers/Settings/Tenants'
    ),
  loading: Loader,
});

const AddTenants = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'tenants' */ '../../containers/Settings/Tenants/AddTenantModal'
    ),
  loading: Loader,
});

const Tenant = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'tenant' */ '../../containers/Settings/Tenant'),
  loading: Loader,
});

const Users = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'users' */ '../../containers/Settings/Users'),
  loading: Loader,
});

const Company = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'company' */ '../../containers/Settings/Company'
    ),
  loading: Loader,
});

const ReturnCarrierCredentialsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnCarrierCredentialsDetails' */ '../../containers/Returns/ReturnCarrierCredentials/List'
    ),
  loading: Loader,
});

const ReturnCarrierCredentialsDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnCarrierCredentialsList' */ '../../containers/Returns/ReturnCarrierCredentials/Details'
    ),
  loading: Loader,
});

const ReturnReasonSetsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnReasonSetsList' */ '../../containers/Returns/ReturnReasonSets/ReturnReasonSetsList'
    ),
  loading: Loader,
});

const NotifySettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'notifySettings' */ '../../containers/Notify/Settings'
    ),
  loading: Loader,
});

const CarrierPush = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'carrierPush' */ '../../containers/Carrier/Push'
    ),
  loading: Loader,
});

const PartnershipsSalesforce = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsSalesforce' */ '../../containers/Partnerships/Salesforce'
    ),
  loading: Loader,
});

const PartnershipsZendesk = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsZendesk' */ '../../containers/Partnerships/Zendesk'
    ),
  loading: Loader,
});

const PartnershipsMagento = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsMagento' */ '../../containers/Partnerships/Magento'
    ),
  loading: Loader,
});

const PartnershipsSfcc = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsSfcc' */ '../../containers/Partnerships/Sfcc'
    ),
  loading: Loader,
});

const PartnershipsLom = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsLom' */ '../../containers/Partnerships/Lom'
    ),
  loading: Loader,
});

const PartnershipsBc = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsBc' */ '../../containers/Partnerships/Bigcommerce'
    ),
  loading: Loader,
});

const PartnershipsSap = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsSap' */ '../../containers/Partnerships/Sap'
    ),
  loading: Loader,
});

const PartnershipsShopify = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsShopify' */ '../../containers/Partnerships/Shopify'
    ),
  loading: Loader,
});

const PartnershipsKlaviyo = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsKlaviyo' */ '../../containers/Partnerships/Klaviyo'
    ),
  loading: Loader,
});

const PartnershipsAttentive = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'partnershipsAttentive' */ '../../containers/Partnerships/Attentive'
    ),
  loading: Loader,
});

const ReturnReasonSetsDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnReasonSetsDetails' */ '../../containers/Returns/ReturnReasonSets/ReturnReasonSetsDetails'
    ),
  loading: Loader,
});

const ReturnSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnSettings' */ '../../containers/Returns/ReturnSettings'
    ),
  loading: Loader,
});

const ReturnUIBuilderList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsUIBuilderList' */ '../../containers/Returns/UIBuilder/List'
    ),
  loading: Loader,
});

const ReturnEmail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnEmail' */ '../../containers/Returns/ReturnEmail'
    ),
  loading: Loader,
});

const ReturnsFilesList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsFilesList' */ '../../containers/Returns/Files/List'
    ),
  loading: Loader,
});

const ReturnsUIBuilderDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsUIBuilderDetails' */ '../../containers/Returns/UIBuilder/Details'
    ),
  loading: Loader,
});

const ReturnsBranding = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'ReturnsBranding' */ '../../containers/Returns/ReturnsBranding'
    ),
  loading: Loader,
});

const ReturnsOptions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsOptions' */ '../../containers/ReturnsHeadlessConfig/Methods'
    ),
  loading: Loader,
});

const ReturnsFilesDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsFilesDetails' */ '../../containers/Returns/Files/Details'
    ),
  loading: Loader,
});

const ReturnsDistributionCentersList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsDistributionCentersList' */ '../../containers/Returns/DistributionCenters/List'
    ),
  loading: Loader,
});

const ReturnsDistributionCentersDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsDistributionCentersDetails' */ '../../containers/Returns/DistributionCenters/Details'
    ),
  loading: Loader,
});

const ReturnsRestrictedEmails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'returnsRestrictedEmails' */ '../../containers/Returns/RestrictedEmails'
    ),
  loading: Loader,
});

const NotifyStyling = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'notifyStyling' */ '../../containers/Notify/Styling'
    ),
  loading: Loader,
});

const CarrierAliasDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'carrierAliasDetails' */ '../../containers/Settings/CarrierAlias/CarrierAliasDetails'
    ),
  loading: Loader,
});

const CarrierAliasList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'carrierAliasList' */ '../../containers/Settings/CarrierAlias/CarrierAliasList'
    ),
  loading: Loader,
});

const HandoffsDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'handoffsDetails' */ '../../containers/Settings/Handoffs/HandoffsDetails'
    ),
  loading: Loader,
});

const HandoffsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'handoffsList' */ '../../containers/Settings/Handoffs/HandoffsSelectorList'
    ),
  loading: Loader,
});

const DistributionCenterDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'distributionCenterDetails' */ '../../containers/Platform/DistributionCenters/Details'
    ),
  loading: Loader,
});

const UnknownOrigin = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'unknownOriginSettings' */ '../../containers/Platform/UnknownOrigin'
    ),
  loading: Loader,
});

const ItemsCostsOptimization = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'itemsCostsOptimizationList' */ '../../containers/Platform/ItemsCostsOptimization'
    ),
  loading: Loader,
});

const ShipSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'shipSettings' */ '../../containers/Ship/Settings'
    ),
  loading: Loader,
});

const EddRulesDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'eddRulesDetails' */ '../../containers/Settings/EddRules/Details'
    ),
  loading: Loader,
});

const EddRulesList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'eddRulesList' */ '../../containers/Settings/EddRules/List'
    ),
  loading: Loader,
});

const EddPreviewSelect = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'eddPreviewSelect' */ '../../containers/Platform/EddPreview/EddPreviewForm'
    ),
  loading: Loader,
});

const EddPreviewList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'eddPreviewList' */ '../../containers/Platform/EddPreview/EddPreviewList'
    ),
  loading: Loader,
});

const EddPreviewDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'eddPreviewDetails' */ '../../containers/Platform/EddPreview/EddPreviewDetails'
    ),
  loading: Loader,
});

const Holidays = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'holidayClosures' */ '../../containers/Platform/Holidays'
    ),
  loading: Loader,
});

const WorkingDays = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'workingDays' */ '../../containers/Platform/WorkingDays'
    ),
  loading: Loader,
});

const NotFoundPage = Loadable({
  loader: () =>
    import(/* webpackChunkName: 'notFoundPage' */ '../NotFoundPage'),
  loading: Loader,
});

const CourierSelectorList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'courierSelectorList' */ '../../containers/Settings/Couriers/CourierSelectorList'
    ),
  loading: Loader,
});

const CourierDetailList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'courierDetailList' */ '../../containers/Settings/Couriers/CourierDetailList'
    ),
  loading: Loader,
});

const CourierDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'courierDetails' */ '../../containers/Settings/Couriers/CourierDetails'
    ),
  loading: Loader,
});

const UploadCourierDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'uploadCourierDetails' */ '../../containers/Settings/Couriers/UploadCourierDetails'
    ),
  loading: Loader,
});

const EmailTemplatesList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'emailTemplatesList' */ '../../containers/Notify/Emails/List'
    ),
  loading: Loader,
});

const EmailTemplateDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'emailTemplateDetails' */ '../../containers/Notify/Emails/Details'
    ),
  loading: Loader,
});

const AssistAdmin = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assistAdmin' */ '../../containers/Admin/AssistAdmin'
    ),
  loading: Loader,
});

const AssistConfigurations = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assistConfigurations' */ '../../containers/Assist/Configurations'
    ),
  loading: Loader,
});

const AssistSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assistSettings' */ '../../containers/Assist/Settings'
    ),
  loading: Loader,
});

const AssistContentList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assistContentList' */ '../../containers/Assist/Content/List'
    ),
  loading: Loader,
});

const AssistContentDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'assistContentDetails' */ '../../containers/Assist/Content/Details'
    ),
  loading: Loader,
});

const packingSlipLabelsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'packingSlipLabelsList' */ '../../containers/Returns/PackingSlipAndLabel/List'
    ),
  loading: Loader,
});

const PackingSlipLabelDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'packingSlipLabelsDetails' */ '../../containers/Returns/PackingSlipAndLabel/Details'
    ),
  loading: Loader,
});

const MessagingEmailDashboardAnalytics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'messagingEmailDashboardAnalytics' */ '../../containers/Messaging/MessagingEmailDashboardAnalytics'
    ),
  loading: Loader,
});

const MessagingSmsDashboardAnalytics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'messagingSmsDashboardAnalytics' */ '../../containers/Messaging/MessagingSmsDashboardAnalytics'
    ),
  loading: Loader,
});

const WysiwygTemplateEditor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'wysiwygTemplateEditor' */ '../../containers/Notify/WysiwygEditor/TemplateEditor/'
    ),
  loading: Loader,
});

const WysiwygBlockEditor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'wysiwygBlockEditor' */ '../../containers/Notify/WysiwygEditor/BlockEditor/'
    ),
  loading: Loader,
});

const WysiwygTemplateDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'wysiwygTemplateDetails' */ '../../containers/Notify/WysiwygEditor/TemplateDetails/'
    ),
  loading: Loader,
});

const ApiList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'oAuthClientList' */ '../../containers/Admin/Api/List'
    ),
  loading: Loader,
});

const ApiDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'oAuthClientDetails' */ '../../containers/Admin/Api/Details'
    ),
  loading: Loader,
});

const AbTestAnalytics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'shipAbTestDetails' */ '../../containers/Ship/AbTestAnalytics'
    ),
  loading: Loader,
});

const ShipDashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'shipDashboard' */ '../../containers/Ship/Dashboard'
    ),
  loading: Loader,
});

const PromiseROI = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'promiseAnalytics' */ '../../containers/Ship/PromiseAnalytics'
    ),
  loading: Loader,
});

const PromiseDataQuality = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: 'promiseDataQuality' */ '../../containers/Ship/PromiseDataQuality'
    ),
  loading: Loader,
});

export default [
  {
    exact: true,
    path: '/home',
    component: Home,
  },
  {
    exact: true,
    path: '/assist/analytics',
    component: Assist,
  },
  {
    exact: true,
    path: '/track/analytics',
    component: Tracking,
  },
  {
    exact: true,
    path: '/track/analytics/feedback-engagement',
    component: FeedbackEngagementDrilldown,
  },
  {
    exact: true,
    path: '/track/analytics/feedback-engagement',
    component: FeedbackEngagementDrilldown,
  },
  {
    exact: true,
    path: '/monitor/dashboards/edit',
    component: DashboardsList,
  },
  {
    exact: true,
    path: '/monitor/dashboards/:id/edit',
    component: DashboardDetails,
  },
  {
    exact: true,
    path: '/monitor/dashboards/:id',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/monitor/chart',
    component: ChartDetails,
  },
  {
    exact: true,
    path: '/monitor/packages',
    component: PackagesList,
  },
  {
    exact: true,
    path: '/monitor/package',
    component: PackageDetails,
  },
  {
    exact: true,
    path: '/track/trackingimageassets',
    component: Assets,
  },
  {
    exact: true,
    path: '/track/assets',
    component: AssetsManager,
  },
  {
    exact: true,
    path: '/track/assets/asset/:id',
    component: Asset,
  },
  { exact: true, path: '/track/files', component: Files },
  { exact: true, path: '/track/gifting', component: GiftingSettings },
  {
    exact: true,
    path: '/track/configurations',
    component: Configurations,
  },
  {
    exact: true,
    path: '/track/configurations/details',
    component: ConfigurationDetails,
  },
  {
    exact: true,
    path: '/track/credentials',
    component: TrackingCarrierCredentials,
  },
  {
    exact: true,
    path: '/track/credentials/:id',
    component: TrackingCarrierCredDetails,
  },
  { exact: true, path: '/track/message', component: Message },
  {
    exact: true,
    path: '/track/settings',
    component: TrackSettings,
  },
  {
    exact: true,
    path: '/track/settings/edd',
    component: TrackEDDSettings,
  },
  {
    exact: true,
    path: '/track/service-codes',
    component: ServiceCodesList,
  },
  {
    exact: true,
    path: '/track/service-codes/:id',
    component: ServiceCodeDetails,
  },
  {
    exact: true,
    path: '/track/service-codes/new/:step',
    component: ServiceCodeDetails,
  },
  { exact: true, path: '/track/style', component: Style },
  {
    exact: true,
    path: '/assist/admin',
    component: AssistAdmin,
  },
  {
    exact: true,
    path: '/assist/styling',
    component: AssistConfigurations,
  },
  {
    exact: true,
    path: '/assist/settings',
    component: AssistSettings,
  },
  {
    exact: true,
    path: '/assist/content',
    component: AssistContentList,
  },
  {
    exact: true,
    path: '/assist/content/:id',
    component: AssistContentDetails,
  },
  { exact: true, path: '/admin/clients', component: ApiList },
  {
    exact: true,
    path: '/admin/clients/:clientId',
    component: ApiDetails,
  },
  {
    exact: true,
    path: '/admin/messages',
    component: Messages,
  },
  {
    exact: true,
    path: '/admin/messages/create',
    component: Message,
  },
  {
    exact: true,
    path: '/admin/messages/:messageId',
    component: Message,
  },
  { exact: true, path: '/admin/profile', component: Profile },
  {
    exact: true,
    path: '/return/analytics',
    component: ReturnsAnalyticsV1,
  },
  {
    exact: true,
    path: '/return/return-rules',
    component: ReturnsRules,
  },
  {
    exact: true,
    path: '/return/return-rules-3.0',
    component: ReturnsRulesV3,
  },
  {
    exact: true,
    path: '/return/return-reasons',
    component: ReturnReasonSetsList,
  },
  {
    exact: true,
    path: '/return/return-reasons/new',
    component: ReturnReasonSetsDetails,
  },
  {
    exact: true,
    path: '/return/return-reasons/details',
    component: ReturnReasonSetsDetails,
  },
  {
    exact: true,
    path: '/return/dashboard-analytics',
    component: ReturnsDashboardAnalytics,
  },
  {
    exact: true,
    path: '/return/dashboard-analytics-2', // Temporary url for version 2
    component: ReturnsDashboardAnalyticsVersion2,
  },
  {
    exact: true,
    path: '/return/order-list',
    component: ReturnsOrderListView,
  },
  {
    exact: true,
    path: '/return/uibuilder/:locale',
    component: ReturnsUIBuilderDetails,
  },
  {
    exact: true,
    path: '/return/theme',
    component: ReturnsBranding,
  },
  {
    exact: true,
    path: '/return/options',
    component: ReturnsOptions,
  },
  {
    exact: true,
    path: '/return/files/:locale',
    component: ReturnsFilesDetails,
  },
  {
    exact: true,
    path: '/return/packing-slip-labels',
    component: packingSlipLabelsList,
  },
  {
    exact: true,
    path: '/return/packing-slip-labels/:labelId',
    component: PackingSlipLabelDetails,
  },
  {
    exact: true,
    path: '/return/packing-slip-labels/new/1',
    component: PackingSlipLabelDetails,
  },
  {
    exact: true,
    path: '/return/return-settings',
    component: ReturnSettings,
  },
  {
    exact: true,
    path: '/return/uibuilder',
    component: ReturnUIBuilderList,
  },
  {
    exact: true,
    path: '/return/email/:locale',
    component: ReturnEmail,
  },
  {
    exact: true,
    path: '/return/files',
    component: ReturnsFilesList,
  },
  { exact: true, path: '/admin/tenants', component: Tenants },
  { exact: true, path: '/admin/tenants/new', component: AddTenants },
  { exact: true, path: '/admin/tenants/:tenantId', component: Tenant },
  { exact: true, path: '/admin/users', component: Users },
  { exact: true, path: '/admin/company', component: Company },
  {
    exact: true,
    path: '/return/credentials',
    component: ReturnCarrierCredentialsList,
  },
  {
    exact: true,
    path: '/return/credentials/:id',
    component: ReturnCarrierCredentialsDetails,
  },
  {
    exact: true,
    path: '/return/credentials/new',
    component: ReturnCarrierCredentialsDetails,
  },
  {
    exact: true,
    path: '/return/distribution-centers',
    component: ReturnsDistributionCentersList,
  },
  {
    exact: true,
    path: '/return/distribution-centers/new',
    component: ReturnsDistributionCentersDetails,
  },
  {
    exact: true,
    path: '/return/restricted-emails',
    component: ReturnsRestrictedEmails,
  },
  {
    exact: true,
    path: '/platform/unknown-origin',
    component: UnknownOrigin,
  },
  {
    exact: true,
    path: '/promise/items-costs-optimization',
    component: ItemsCostsOptimization,
  },
  {
    path: '/promise/distribution-centers/:id',
    component: DistributionCenterDetails,
  },
  {
    exact: true,
    path: '/promise/settings',
    component: ShipSettings,
  },
  {
    exact: true,
    path: '/promise/edd-rules',
    component: EddRulesList,
  },
  {
    exact: true,
    path: '/promise/edd-rules/:ruleId',
    component: EddRulesDetails,
  },
  {
    exact: true,
    path: '/promise/edd-rules/new/:step',
    component: EddRulesDetails,
  },
  {
    exact: true,
    path: '/platform/holidays',
    component: Holidays,
  },
  {
    exact: true,
    path: '/platform/working-days',
    component: WorkingDays,
  },
  {
    exact: true,
    path: '/platform/edd-preview/list',
    component: EddPreviewList,
  },
  {
    exact: true,
    path: '/platform/edd-preview/details',
    component: EddPreviewDetails,
  },
  {
    exact: true,
    path: '/platform/edd-preview',
    component: EddPreviewSelect,
  },
  {
    exact: true,
    path: '/couriers/courier-selector',
    component: CourierSelectorList,
  },
  {
    exact: true,
    path: '/couriers/courier-detail-list/:courierName',
    component: CourierDetailList,
  },
  {
    exact: true,
    path: '/couriers/courier-details/:courierName/:id',
    component: CourierDetails,
  },
  {
    exact: true,
    path: '/couriers/upload-courier-details/:courierName',
    component: UploadCourierDetails,
  },
  {
    exact: true,
    path: '/notify/exceptions/analytics',
    component: Exceptions,
  },
  {
    exact: true,
    path: '/notify/exceptions/reports',
    component: ExceptionsReports,
  },
  {
    exact: true,
    path: '/notify/exceptions/analytics-details',
    component: Exceptions,
  },
  {
    exact: true,
    path: '/notify/styling',
    component: NotifyStyling,
  },
  {
    exact: true,
    path: '/notify/settings',
    component: NotifySettings,
  },
  {
    exact: true,
    path: '/carrier/push',
    component: CarrierPush,
  },
  {
    exact: true,
    path: '/notify/emails',
    component: EmailTemplatesList,
  },
  {
    exact: true,
    path: '/notify/emails/:id',
    component: EmailTemplateDetails,
  },
  {
    exact: true,
    path: '/partnerships/salesforce',
    component: PartnershipsSalesforce,
  },
  {
    exact: true,
    path: '/partnerships/zendesk',
    component: PartnershipsZendesk,
  },
  {
    exact: true,
    path: '/partnerships/magento',
    component: PartnershipsMagento,
  },
  {
    exact: true,
    path: '/partnerships/sfcc',
    component: PartnershipsSfcc,
  },
  {
    exact: true,
    path: '/partnerships/lom',
    component: PartnershipsLom,
  },
  {
    exact: true,
    path: '/partnerships/bigcommerce',
    component: PartnershipsBc,
  },
  {
    exact: true,
    path: '/partnerships/sap',
    component: PartnershipsSap,
  },
  {
    exact: true,
    path: '/partnerships/shopify',
    component: PartnershipsShopify,
  },
  {
    exact: true,
    path: '/partnerships/klaviyo',
    component: PartnershipsKlaviyo,
  },
  {
    exact: true,
    path: '/partnerships/attentive',
    component: PartnershipsAttentive,
  },
  {
    exact: true,
    path: '/couriers/carrier-alias',
    component: CarrierAliasList,
  },
  {
    path: '/couriers/carrier-alias/:id',
    component: CarrierAliasDetails,
  },
  {
    exact: true,
    path: '/handoffs/handoffs-selector',
    component: HandoffsList,
  },
  {
    exact: true,
    path: '/handoffs/handoff-details/new',
    component: HandoffsDetails,
  },
  {
    path: '/handoffs/handoff-details/:id',
    component: HandoffsDetails,
  },
  {
    exact: true,
    path: '/messaging/email-dashboard-analytics',
    component: MessagingEmailDashboardAnalytics,
  },
  {
    exact: true,
    path: '/messaging/sms-dashboard-analytics',
    component: MessagingSmsDashboardAnalytics,
  },
  {
    exact: true,
    path: '/notify/wysiwyg-editor/template/:locale/:id',
    component: WysiwygTemplateEditor,
  },
  {
    exact: true,
    path: '/notify/wysiwyg-editor/block/:locale/:id',
    component: WysiwygBlockEditor,
  },
  {
    exact: true,
    path: '/notify/wysiwyg-editor/template-details/:locale/:id',
    component: WysiwygTemplateDetails,
  },
  {
    exact: true,
    path: '/promise/ab-test/analytics/:testId',
    component: AbTestAnalytics,
  },
  {
    exact: true,
    path: '/platform/edd-audit',
    component: ShipDashboard,
  },
  {
    exact: true,
    path: '/promise/roi',
    component: PromiseROI,
  },
  {
    exact: true,
    path: '/promise/data-quality',
    component: PromiseDataQuality,
  },
  {
    exact: true,
    path: '/promise/holidays',
    component: Holidays,
  },
  {
    exact: true,
    path: '/promise/working-days',
    component: WorkingDays,
  },
  {
    exact: true,
    path: '/promise/edd-preview/list',
    component: EddPreviewList,
  },
  {
    exact: true,
    path: '/promise/edd-preview/details',
    component: EddPreviewDetails,
  },
  {
    exact: true,
    path: '/promise/edd-preview',
    component: EddPreviewSelect,
  },
  {
    exact: true,
    path: '/promise/edd-audit',
    component: ShipDashboard,
  },
  { component: NotFoundPage },
];
