import { css, injectGlobal } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  font-family: ${fonts.bold};
  font-size: 1rem;

  & h4 {
    color: ${colors.white};
    margin-bottom: 10px;
  }
`;

injectGlobal`
.tooltip {
  line-height: 1.3;

  .show {
    opacity: 1;
  }

  & .tooltip-inner {
    background-color: ${colors.black};
    box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 4px;
    font-family: ${fonts.bold};
    min-width: 160px;
    opacity: 0.98;
    padding: 10px;
    text-align: left;
  }

}

.tooltip.bs-tether-element-attached-bottom .tooltip-inner::before {
  display:none;
}

.tooltip.bs-tether-element-attached-right .tooltip-inner::before {
  border-left-color: ${colors.black};
  display:none;
}

.tooltip.bs-tether-element-attached-left .tooltip-inner::before {
  border-right-color: ${colors.black};
  display:none;
}

.tooltip.bs-tether-element-attached-top .tooltip-inner::before {
  border-bottom-color: ${colors.black};
  display:none;
}`;

export default s;
