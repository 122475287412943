/* eslint-disable camelcase */
import { API_REQUEST } from '../lib/api';
import {
  SHIP_AB_TEST_ANALYTICS_DASHBOARD_LAYOUT,
  SHIP_AB_TEST_ANALYTICS_REPORTING,
} from '../constants/endpoints';
import { pascalizeReportName } from '../lib/helpers';
import { createChartData } from '../containers/Ship/AbTestAnalytics/AbTestChartData';

export const RECEIVE_AB_TEST_DETAILS = 'SHIP_AB_TEST/RECEIVE_DETAILS';
export const RECEIVE_DASHBOARD = 'SHIP_AB_TEST_ANALYTICS/RECEIVE_DASHBOARD';
export const RECEIVE_CHART_DATA = 'SHIP_AB_TEST_ANALYTICS/RECEIVE_CHART_DATA';
export const RECEIVE_ERROR = 'RECEIVE_ERROR_EMAIL';
export const DISMISS_ALERT = 'DISMISS_ALERT_EMAIL';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const receiveError = error => ({
  type: RECEIVE_ERROR,
  meta: { error },
});

const receiveChartData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_DATA,
  payload: { chartData, tableData },
  meta: { chartName },
});

export const receiveAbTestDetails = ({
  status,
  createdBy,
  createdDate,
  startDate,
  endDate,
}) => ({
  type: RECEIVE_AB_TEST_DETAILS,
  payload: {
    status,
    createdBy,
    createdDate,
    startDate,
    endDate,
  },
});

export const fetchDashboard = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:ship_js_ab_test',
    path: SHIP_AB_TEST_ANALYTICS_DASHBOARD_LAYOUT,
  })
    .then(json => {
      const {
        dashboard_definitions,
        dashboard_definitions: {
          ship_js_ab_test,
          ship_js_ab_test: { layout },
        },
        report_instances,
        report_definitions,
        default_locale,
      } = json;
      dispatch({
        type: RECEIVE_DASHBOARD,
        payload: {
          dashboard_definitions: {
            ...dashboard_definitions,
            ship_js_ab_test: {
              ...ship_js_ab_test,
              layout,
            },
          },
          report_instances,
          report_definitions,
          default_locale,
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });

export const fetchChartData = (query, chartName, chartInfo) => dispatch => {
  const name = `fetch${pascalizeReportName(chartName)}`;
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: SHIP_AB_TEST_ANALYTICS_REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        throw new Error(`${json.error}: ${json.msg}`);
      }
      const object = query.object.replace(/"/g, '');
      const records = json[object];
      let chartData;
      if (chartInfo.type === 'single-metric') {
        chartData = Object.values(records[0].metrics)[0];
      } else if (chartInfo.type === 'single-metric-with-benchmark') {
        chartData = [];
        const [mainMetricName, subMetricName] = query.metrics.split(','); //  example: ""percent_click_through_rate"",""percent_diff_from_benchmark""
        const {
          [mainMetricName.replace(/"/g, '')]: firstChartData,
          [subMetricName.replace(/"/g, '')]: secondChartData,
        } = records[0].metrics;
        chartData = [firstChartData, secondChartData];
      } else if (chartInfo.type === 'multiitem') {
        chartData = { t_return_details: records };
      } else {
        chartData = createChartData(records, query, chartName, chartInfo);
      }
      dispatch(receiveChartData(chartData, chartName, records));
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};
