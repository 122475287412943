import {
  pickup,
  mail,
  keeptheitem,
  store,
  self,
  reject,
  printerlessMail,
} from './adapters';

const migrationTypes = {
  pickup,
  mail,
  keeptheitem,
  store,
  self,
  printerless_mail: printerlessMail,
};

const generateNewConfig = configs =>
  configs
    .map(item => (item.newOption ? item.newOption : null))
    .filter(Boolean)
    .reduce(
      (acc, migration) => ({
        options: [...acc.options, migration.option].filter(Boolean),
        methods: {
          ...acc.methods,
          ...migration.methods,
        },
      }),
      { options: [], methods: {} },
    );

const mergeOptions = options =>
  options
    .reduce((allOptions, option) => {
      const foundOption =
        option && option.id && allOptions.find(item => item.id === option.id);
      if (foundOption) {
        const [currentMethod] = option.methods;
        foundOption.methods.push(currentMethod);
        return allOptions;
      }
      return [...allOptions, option];
    }, [])
    .filter(Boolean);

const methodAdaptor = ({ option, returnOptionsTemplate }) =>
  !Object.keys(migrationTypes).includes(option.return_method)
    ? reject({ option })
    : migrationTypes[option.id]({ option, returnOptionsTemplate });

export const generateDiff = ({
  options: oldOptions,
  returnsSettingsTemplate,
}) => {
  const {
    returns: { returnOptions: returnOptionsTemplate },
  } = returnsSettingsTemplate;
  const flatConfigs = oldOptions.map(option =>
    methodAdaptor({ option, returnOptionsTemplate }),
  );
  const { methods, options: unmergedOptions } = generateNewConfig(flatConfigs);
  const options = mergeOptions(unmergedOptions);

  return {
    flatConfigs,
    mergedConfigs: {
      methods,
      options,
    },
  };
};

export * from './ui';
