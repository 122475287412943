import * as ENDPOINTS from '~src/constants/endpoints';
import { API_REQUEST } from '~src/lib/api';

export const RECEIVE_CONTENT = 'ASSIST/RECEIVE_CONTENT';
export const RECEIVE_CONTENT_BY_ID = 'ASSIST/RECEIVE_CONTENT_BY_ID';
export const RECEIVE_UPDATE_CONTENT = 'ASSIST/RECEIVE_UPDATE_CONTENT';
export const RECEIVE_ERROR = 'ASSIST/RECEIVE_ERROR';

export const transformAssistContent = json => {
  const { cards, categories, menu, noResults } = json;
  // Filter out evergreen cards for now
  let content = cards.filter(card => !card.oldId);

  // TODO: Consolidate `categories` into one model
  if (categories) {
    content = content.concat(categories);
  }

  if (menu) {
    content.push({
      id: menu.id,
      cardType: 'menu',
      content: menu.content,
      dateModified: menu.dateModified,
    });
  }

  if (noResults) {
    content.push(noResults);
  }

  return {
    content,
    categories: categories.map(category => ({
      slug: category.slug,
      id: category.id,
      name: category.content.title,
    })),
    cards,
  };
};

export const transformAssistContentById = data => {
  const newData = data;
  newData.card.related = newData.card.content.related;
  delete newData.card.content.related;
  delete newData.card.company;
  return newData;
};

export const fetchAssistContent = retailerMoniker => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.CARE_RETAILER_CONTENT}/${retailerMoniker}`,
    name: 'fetchAssistContent',
  }).then(
    json => {
      const content = transformAssistContent(json);

      dispatch({ type: RECEIVE_CONTENT, payload: content });
    },
    err => {
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      });
    },
  );

export const fetchAssistContentById = (id, retailerMoniker) => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.CARE_RETAILER_CONTENT}/${retailerMoniker}/cards/${id}`,
    name: 'fetchAssistContentById',
  })
    .then(json => transformAssistContentById(json))
    .then(
      payload => {
        dispatch({ type: RECEIVE_CONTENT_BY_ID, payload });
      },
      err => {
        dispatch({
          type: RECEIVE_ERROR,
          error: err.errorMessage,
        });
      },
    );

export const fetchAssistContentAndContentById = (
  id,
  retailerMoniker,
) => dispatch =>
  dispatch(fetchAssistContent(retailerMoniker)).then(() =>
    dispatch(fetchAssistContentById(id, retailerMoniker)),
  );

export const fetchPutAssistContentById = (
  content,
  retailerMoniker,
) => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.CARE_RETAILER_CONTENT}/${retailerMoniker}/cards/${content.id}`,
    name: 'fetchPutAssistContentById',
    body: JSON.stringify(content),
  }).then(
    json => {
      dispatch({ type: RECEIVE_UPDATE_CONTENT, payload: json.data });
    },
    err => {
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      });
    },
  );
};
