export const RETURN_RULES_API_VERSION = 3;
export const PUBLISH_EMAIL_TEMPLATE_ID = 'returns-ui-builder-confirmation';
export const PREVIEW_EMAIL_TEMPLATE_ID = 'returns-ui-builder-preview';
export const EXCHANGE = 'exchange';
export const MAIL = 'mail';
export const PRINTERLESS_MAIL = 'printerless_mail';
export const CONCIERGE = 'concierge';
export const HOME_PICKUP = 'home_pickup';
export const LOCKER_DROPOFF = 'locker_dropoff';
export const BOXLESS = 'boxless';
export const CARRIER_PICKUP = 'pickup';

export const RETURN_METHODS_WITH_DC = [
  MAIL,
  PRINTERLESS_MAIL,
  CONCIERGE,
  HOME_PICKUP,
  LOCKER_DROPOFF,
  BOXLESS,
  CARRIER_PICKUP,
];

export const RETURNS_LOCALES = [
  {
    display_name: 'Chinese (CN)',
    key: 'zh_CN',
    currencyCode: 'CNY',
  },
  {
    display_name: 'Chinese (HK)',
    key: 'zh_HK',
    currencyCode: 'HKD',
  },
  {
    display_name: 'Chinese (TW)',
    key: 'zh_TW',
    currencyCode: 'TWD',
  },
  {
    display_name: 'Chinese (US)',
    key: 'zh_US',
    currencyCode: 'USD',
  },
  {
    display_name: 'Czech (CZ)',
    key: 'cs_CZ',
    currencyCode: 'CZE',
  },
  {
    display_name: 'Croatian (HR)',
    key: 'hr_HR',
    currencyCode: 'HRV',
  },
  {
    display_name: 'Danish (DK)',
    key: 'da_DK',
    currencyCode: 'DKK',
  },
  {
    display_name: 'Dutch (BE)',
    key: 'nl_BE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Dutch (NL)',
    key: 'nl_NL',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (AT)',
    key: 'en_AT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (AU)',
    key: 'en_AU',
    currencyCode: 'AUD',
  },
  {
    display_name: 'English (BE)',
    key: 'en_BE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (BG)',
    key: 'en_BG',
    currencyCode: 'BGN',
  },
  {
    display_name: 'English (CA)',
    key: 'en_CA',
    currencyCode: 'CAD',
  },
  {
    display_name: 'English (CH)',
    key: 'en_CH',
    currencyCode: 'CHF',
  },
  {
    display_name: 'English (CY)',
    key: 'en_CY',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (CZ)',
    key: 'en_CZ',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (DE)',
    key: 'en_DE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (DK)',
    key: 'en_DK',
    currencyCode: 'DKK',
  },
  {
    display_name: 'English (EE)',
    key: 'en_EE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (ES)',
    key: 'en_ES',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (EU)',
    key: 'en_EU',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (FI)',
    key: 'en_FI',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (FR)',
    key: 'en_FR',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (GB)',
    key: 'en_GB',
    currencyCode: 'GBP',
  },
  {
    display_name: 'English (GR)',
    key: 'en_GR',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (HK)',
    key: 'en_HK',
    currencyCode: 'HKD',
  },
  {
    display_name: 'English (HR)',
    key: 'en_HR',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (HU)',
    key: 'en_HU',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (IE)',
    key: 'en_IE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (IL)',
    key: 'en_IL',
    currencyCode: 'ILS',
  },
  {
    display_name: 'English (IN)',
    key: 'en_IN',
    currencyCode: 'INR',
  },
  {
    display_name: 'English (IT)',
    key: 'en_IT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (JP)',
    key: 'en_JP',
    currencyCode: 'JPY',
  },
  {
    display_name: 'English (KW)',
    key: 'en_KW',
    currencyCode: 'KWD',
  },
  {
    display_name: 'English (LU)',
    key: 'en_LU',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (LV)',
    key: 'en_LV',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (MT)',
    key: 'en_MT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (NL)',
    key: 'en_NL',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (NO)',
    key: 'en_NO',
    currencyCode: 'NOK',
    unsupportedTemplate: true,
  },
  {
    display_name: 'English (NZ)',
    key: 'en_NZ',
    currencyCode: 'NZD',
  },
  {
    display_name: 'English (PL)',
    key: 'en_PL',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (PT)',
    key: 'en_PT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (RO)',
    key: 'en_RO',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (SE)',
    key: 'en_SE',
    currencyCode: 'SEK',
  },
  {
    display_name: 'English (SG)',
    key: 'en_SG',
    currencyCode: 'SGD',
  },
  {
    display_name: 'English (SI)',
    key: 'en_SI',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (SK)',
    key: 'en_SK',
    currencyCode: 'EUR',
  },
  {
    display_name: 'English (UA)',
    key: 'en_UA',
    currencyCode: 'UAH',
  },
  {
    display_name: 'English (US)',
    key: 'en_US',
    currencyCode: 'USD',
  },
  {
    display_name: 'Finnish (FI)',
    key: 'fi_FI',
    currencyCode: 'EUR',
  },
  {
    display_name: 'French (BE)',
    key: 'fr_BE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'French (CA)',
    key: 'fr_CA',
    currencyCode: 'CAD',
  },
  {
    display_name: 'French (CH)',
    key: 'fr_CH',
    currencyCode: 'CHF',
  },
  {
    display_name: 'French (FR)',
    key: 'fr_FR',
    currencyCode: 'EUR',
  },
  {
    display_name: 'French (LU)',
    key: 'fr_LU',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (AT)',
    key: 'de_AT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (BE)',
    key: 'de_BE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (CH)',
    key: 'de_CH',
    currencyCode: 'CHF',
  },
  {
    display_name: 'German (DK)',
    key: 'de_DK',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (EE)',
    key: 'de_EE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (FI)',
    key: 'de_FI',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (LI)',
    key: 'de_LI',
    currencyCode: 'CHF',
  },
  {
    display_name: 'German (LU)',
    key: 'de_LU',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (LV)',
    key: 'de_LV',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (SK)',
    key: 'de_SK',
    currencyCode: 'EUR',
  },
  {
    display_name: 'German (DE)',
    key: 'de_DE',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Hungarian (HU)',
    key: 'hu_HU',
    currencyCode: 'HUN',
  },
  {
    display_name: 'Italian (CH)',
    key: 'it_CH',
    currencyCode: 'CHF',
  },
  {
    display_name: 'Italian (IT)',
    key: 'it_IT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Japanese (JP)',
    key: 'ja_JP',
    currencyCode: 'JPY',
  },
  {
    display_name: 'Korean (KR)',
    key: 'ko_KR',
    currencyCode: 'KRW',
  },
  {
    display_name: 'Polish (PL)',
    key: 'pl_PL',
    currencyCode: 'PLN',
  },
  {
    display_name: 'Portugese (BR)',
    key: 'pt_BR',
    currencyCode: 'BRL',
  },
  {
    display_name: 'Portugese (PT)',
    key: 'pt_PT',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Romanian (RO)',
    key: 'ro_RO',
    currencyCode: 'ROU',
  },
  {
    display_name: 'Spanish (AR)',
    key: 'es_AR',
    currencyCode: 'ARS',
  },
  {
    display_name: 'Spanish (CL)',
    key: 'es_CL',
    currencyCode: 'CLP',
  },
  {
    display_name: 'Spanish (CO)',
    key: 'es_CO',
    currencyCode: 'COP',
  },
  {
    display_name: 'Spanish (ES)',
    key: 'es_ES',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Spanish (MX)',
    key: 'es_MX',
    currencyCode: 'MXN',
  },
  {
    display_name: 'Spanish (PE)',
    key: 'es_PE',
    currencyCode: 'PEN',
  },
  {
    display_name: 'Spanish (US)',
    key: 'es_US',
    currencyCode: 'USD',
  },
  {
    display_name: 'Slovak (SK)',
    key: 'sk_SK',
    currencyCode: 'EUR',
  },
  {
    display_name: 'Swedish (SE)',
    key: 'sv_SE',
    currencyCode: 'SEK',
  },
  {
    display_name: 'Thai (NO)',
    key: 'th_NO',
    currencyCode: 'NOK',
  },
  {
    display_name: 'Thai (SE)',
    key: 'th_SE',
    currencyCode: 'SEK',
  },
  {
    display_name: 'Vietnamese (US)',
    key: 'vi_US',
    currencyCode: 'USD',
  },
  {
    display_name: 'Vietnamese (US)',
    key: 'vt_US',
    currencyCode: 'USD',
    unsupportedTemplate: true,
  },
  {
    display_name: 'Ukrainian (UA)',
    key: 'uk_UA',
    currencyCode: 'UAH',
  },
  {
    display_name: 'Norwegian (NO)',
    key: 'no_NO',
    currencyCode: 'NOK',
  },
];

export const SUPPORTED_LOCALES = RETURNS_LOCALES.filter(
  ({ unsupportedTemplate }) => !unsupportedTemplate,
);
