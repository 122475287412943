import React, { useRef, useEffect, useState } from 'react';
import Editor from '@monaco-editor/react';
import { Button } from '@narvar/hum';
import { ViewWrapper, PreviewWrapper, EditorWrapper } from './style';

const bracketTypeMap = {
  angleToSquare: [['<<', '[['], ['>>', ']]']],
  squareToAngle: [['[[', '<<'], [']]', '>>']],
};

export const bracketConversion = ({ conversion = [], string }) =>
  conversion.reduce((acc, cur) => acc.replaceAll(...cur), string);

const getTokens = ({ codeSnippet, regex, type }) => {
  const foundTokens = codeSnippet.match(regex);
  if (!foundTokens) return [];
  return foundTokens.map(token => [
    token,
    token.replace(type[0], '').replace(type[1], ''),
  ]);
};

const tokenReplacement = ({ codeSnippet, data, type, regex }) => {
  if (!data) return codeSnippet;

  const tokens = getTokens({
    codeSnippet,
    regex,
    type,
  });

  return tokens.reduce(
    (string, tokenGroup) =>
      string.replaceAll(tokenGroup[0], data[tokenGroup[1]]),
    codeSnippet,
  );
};

const generatePreview = ({ templateData, codeSnippet }) => {
  let data;
  if (!codeSnippet) return '';
  const convertedSnippet = bracketConversion({
    conversion: bracketTypeMap.angleToSquare,
    string: codeSnippet,
  });

  try {
    data = JSON.parse(templateData);
  } catch (e) {
    data = {};
  }

  return [
    {
      data: data.order_level_data,
      regex: /\[\[(.*?)\]\]/g,
      type: ['[[', ']]'],
    },
  ].reduce(
    (string, replacementOptions) =>
      tokenReplacement({
        ...replacementOptions,
        codeSnippet: string,
      }),
    convertedSnippet,
  );
};

const PreviewBox = ({ codeSnippet }) => {
  if (!codeSnippet) return null;
  return (
    <PreviewWrapper>
      <h2 className="label">Quick Preview</h2>
      <p>
        This is only for quick reference as the final layout may differ from
        this preview. Please save and preview to see the final output.
      </p>

      <p>
        Use the <strong>Test Data</strong> section below to modify the test
        tokens.
      </p>
      <ViewWrapper>
        <div
          style={{
            width: 820,
          }}
        >
          <div className="preview">
            <div
              style={{
                width: '100%',
                height: '500px',
              }}
              dangerouslySetInnerHTML={{
                __html: codeSnippet,
              }}
            ></div>
          </div>
        </div>
      </ViewWrapper>
    </PreviewWrapper>
  );
};

export const PSSLCodeBoxWithPreview = ({
  data,
  formControl: {
    props: { onChange, name, language, templateData },
  },
}) => {
  const [previewData, setPreviewData] = useState(data);
  const monacoRef = useRef(null);

  useEffect(() => {
    setPreviewData(generatePreview({ templateData, codeSnippet: data }));
  }, [data, templateData]);

  const handleEditorDidMount = editor => {
    monacoRef.current = editor;
    monacoRef.current.trigger('anyString', 'editor.action.formatDocument');
  };

  const handleFormat = () => {
    monacoRef.current.trigger('anyString', 'editor.action.formatDocument');
  };

  const handleChange = value => {
    if (onChange) {
      onChange({
        target: { value },
        currentTarget: { name, value },
      });
    }
  };

  return (
    <div>
      <Button onClick={handleFormat}>Auto Align Code</Button>
      <EditorWrapper>
        <Editor
          {...(language ? { language } : {})}
          value={data}
          onMount={handleEditorDidMount}
          onChange={handleChange}
          minimapEnabled={false}
          options={{
            minimap: {
              enabled: false,
            },
            tabSize: 2,
            lineNumbers: 'off',
          }}
        />
      </EditorWrapper>
      <PreviewBox codeSnippet={previewData} />
    </div>
  );
};
