import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import { parseQueryString } from '~src/lib/helpers';

const isWhiteOrEmpty = (string = '') => {
  const lowerCase = string.toLowerCase();
  return (
    lowerCase.length === 0 || lowerCase === '#fff' || lowerCase === '#ffffff'
  );
};

export const STYLE_UPDATE_STORE = 'NOTIFY/STYLE_UPDATE_STORE';
export const RECEIVE_EDD_PREVIEW = 'NOTIFY/RECEIVE_EDD_PREVIEW';
export const RECEIVE_EDD_CONFIGS = 'NOTIFY/RECEIVE_EDD_CONFIGS';
export const RECEIVE_STYLING_ERROR = 'NOTIFY/RECEIVE_STYLING_ERROR';
export const RECEIVE_CURRENT_LOCALE = 'NOTIFY/RECEIVE_CURRENT_LOCALE';
export const STYLING_RESET = 'NOTIFY/STYLING_RESET';
export const RECEIVE_FACEBOOK_EDD_PUBLISH =
  'NOTIFY/RECEIVE_FACEBOOK_EDD_PUBLISH';

export const triggerUpdateStore = settings => ({
  type: STYLE_UPDATE_STORE,
  payload: settings,
});
const receiveNotifySaveResponse = previewUrl => ({
  type: RECEIVE_EDD_PREVIEW,
  payload: {
    previewUrl,
  },
});
const receiveEDDConfigs = payload => ({
  type: RECEIVE_EDD_CONFIGS,
  payload,
});
export const receiveNotifyStylingError = payload => ({
  type: RECEIVE_STYLING_ERROR,
  payload,
});
const receiveCurrentLocale = payload => ({
  type: RECEIVE_CURRENT_LOCALE,
  payload,
});
const receiveFacebookEDDPublish = payload => ({
  type: RECEIVE_FACEBOOK_EDD_PUBLISH,
  payload,
});
export const resetFormAndErrors = () => ({
  type: STYLING_RESET,
});

export const fetchEDDConfigs = () => async dispatch => {
  const path = `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}`;
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
    });

    const {
      retailer_settings,
      date_modified: dateModified,
      configuration_details: {
        modified: dateModifiedText,
        condition = [],
      } = {},
    } = json;

    const retailerSettings = JSON.parse(retailer_settings);

    const {
      branding: {
        brand_secondary: brandingBrandSecondary,
        brand_base: brandingBrandBase,
        header_bg: brandingHeaderBg,
      } = {},
      features: {
        fb_messenger: {
          edd_image_config: {
            header_bg_color: fbHeaderBackgroundColor, // = '#F6F6F6',
            body_bg_color: fbBodyBackgroundColor,
            divider_bar_color: fbDividerColor,
            header_color: fbHeaderTextColor,
            edd_header_color: fbStatusTitleColor,
            edd_color: fbStatusColor,
          } = {},
        } = {},
      } = {},
    } = retailerSettings;
    let dividerColor;
    if (fbDividerColor) {
      dividerColor = fbDividerColor;
    } else if (!isWhiteOrEmpty(brandingHeaderBg)) {
      dividerColor = brandingHeaderBg;
    } else if (!isWhiteOrEmpty(brandingBrandBase)) {
      dividerColor = brandingBrandBase;
    } else {
      dividerColor = '#BBBBBB';
    }

    const eddConfigs = {
      header_bg_color:
        fbHeaderBackgroundColor ||
        brandingHeaderBg ||
        brandingBrandBase ||
        '#FFFFFF',
      body_bg_color: fbBodyBackgroundColor || '#FFFFFF',
      divider_bar_color: dividerColor,
      header_color: fbHeaderTextColor || '#707070',
      edd_header_color: fbStatusTitleColor || '#707070',
      edd_color: fbStatusColor || brandingBrandSecondary || '#009DBC',
      dateModified,
      dateModifiedText,
      retailerSettings,
    };
    dispatch(receiveEDDConfigs(eddConfigs));

    const locale = condition.find(
      elem => elem.label.toLowerCase() === 'locale',
    );
    if (locale) {
      dispatch(receiveCurrentLocale(locale.selected));
    }
  } catch (e) {
    dispatch(
      receiveNotifyStylingError(
        `There was an issue loading the retailer info settings: ${e}`,
      ),
    );
  }
};

export const fetchSaveFacebookEdd = (updatedConfigs, currentLocale) => async (
  dispatch,
  getState,
) => {
  const {
    notifyStyling: { _retailerSettings: retailerInfoSettingsJson } = {},
    userReducer: {
      user: {
        current_retailer_id,
        retailerIdToRetailerInfo: {
          [current_retailer_id]: { uri_moniker: uriMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  try {
    const features = retailerInfoSettingsJson.features || {};
    const fbMessenger = features.fb_messenger || {};
    const eddConfig = fbMessenger.edd_image_config || {};
    const updatedSettings = {
      ...retailerInfoSettingsJson,
      features: {
        ...features,
        fb_messenger: {
          ...fbMessenger,
          edd_image_config: {
            ...eddConfig,
            ...updatedConfigs,
          },
        },
      },
    };

    const path = `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}?locale=${currentLocale}`;
    const body = JSON.stringify({
      retailer_settings: JSON.stringify(updatedSettings),
    });
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path,
      body,
    });
    const previewVersion = parseQueryString(json.preview_url).version;
    const previewUrl = `//trackingimg.narvar.com${ENDPOINTS.FACEBOOK_EDD_IMAGE_SAVE}/${uriMoniker}/narvar-speede?src=fb&edd_begin=2017-11-28%2000:00:00&status=none&tracking_numbers=1337&preview=true&version=${previewVersion}&locale=${currentLocale}`;
    dispatch(receiveNotifySaveResponse(previewUrl));
  } catch (err) {
    dispatch(
      receiveNotifyStylingError(
        err.description
          ? err.description[0]
          : 'Sorry, there was a problem saving your settings.',
      ),
    );
  }
};

export const fetchPublishFacebookImage = (
  currentLocale,
  dateModified,
) => async dispatch => {
  try {
    const body = JSON.stringify({
      date_modified: dateModified,
    });

    const path = `${ENDPOINTS.CSM_TRACKING_ASSETS_PUBLISH}?locale=${currentLocale}`;
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.CSM_TRACKING_ASSETS_SAVE,
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}?locale=${currentLocale}`,
    });
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path,
      body,
      name: 'fetchPublishFacebookEdd',
    });
    if (json.status_code === 200) {
      dispatch(receiveFacebookEDDPublish(json.date_modified));
    } else {
      dispatch(
        receiveNotifyStylingError(
          json.errors[0].message
            ? json.errors[0].message
            : 'Sorry, there was a problem publishing your settings.',
        ),
      );
    }
  } catch (err) {
    dispatch(
      receiveNotifyStylingError(
        err.description
          ? err.description[0]
          : 'Sorry, there was a problem publishing your settings.',
      ),
    );
  }
};
