import React from 'react';

const Error = () => (
  <div
    style={{
      display: 'flex',
      textAlign: 'center',
      margin: '80px auto',
      flexDirection: 'column',
    }}
  >
    <h4>Sorry, something went wrong.</h4>
    <p>Please try again later.</p>
  </div>
);

export default Error;
