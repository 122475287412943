import gql from 'graphql-tag';
import FRAGMENT_TIMESTAMP_FIELDS from '../fragments/timestampFields';
import { UI_SCHEMA } from '../../constants/returns/uiBuilder';

export const GET_RETURNS_SETTINGS = gql`
  query GetReturnsSettings {
    returns {
      locales {
        enabled
        default
        exchanges
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      exchangeSettings {
        shopify {
          enabled
          options {
            label
            value
          }
        }
        completeDraft {
          enabled
          options {
            label
            value
          }
        }
      }
      carriers {
        enabled
        options {
          key
          displayName
        }
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      productCategories {
        enabled {
          id
          category
        }
      }
      overrideCodes {
        enabled
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
    pickups {
      programs {
        pickupRetailerPrograms {
          active
          id
          pickupProgramId
          retailerMoniker
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const GET_ENABLED_LOCALES = gql`
  query GetEnabledLocales {
    returns {
      locales {
        enabled
      }
    }
  }
`;

export const GET_UI_BUILDER_SETTINGS = gql`
  query GetUIBuilderSettings($locale: String!) {
    returns {
      returnSettingsBlob(locale: $locale) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      globalReturnSettingsBlob(locale: $locale) {
        languageJson
        settingsJson
      }
      productAttributeSettings(locale: $locale) {
        enabled
        displayPropertyTypeList {
          locale
          propertyType
        }
      }
      availableProductAttributes {
        key
      }
      supportedReturnsMethods {
        configs
      }
      uiSchema(uiKey: "${UI_SCHEMA.UI_KEYS.UI_BUILDER_METHOD}") {
        configs
      }
      supportedRefundOptions {
        configs
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const GET_UI_BUILDER_SETTINGS_WITH_NAV = gql`
  query GetUIBuilderSettingsWithNav($locale: String!, $locales: [String]!, $products: [String]!) {
    returns {
      returnSettingsBlob(locale: $locale) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      globalReturnSettingsBlob(locale: $locale) {
        languageJson
        settingsJson
      }
      productAttributeSettings(locale: $locale) {
        enabled
        displayPropertyTypeList {
          locale
          propertyType
        }
      }
      availableProductAttributes {
        key
      }
      supportedReturnsMethods {
        configs
      }
      uiSchema(uiKey: "${UI_SCHEMA.UI_KEYS.UI_BUILDER_METHOD}", locale: $locale) {
        configs
      }
      supportedRefundOptions {
        configs
      }
    }
    navigationMenus(products: $products, locales: $locales) {
      product
      locale
      items {
        label
        url
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const GET_FILES_SETTINGS = gql`
  query GetFilesSettings($locale: String!) {
    returns {
      returnSettingsBlob(locale: $locale) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const GET_ALL_DISTRIBUTION_CENTERS = gql`
  query GetAllDistributionCenters {
    returns {
      distributionCenters {
        dcID
        locale
        isDefault
        modified {
          date
        }
      }
    }
  }
`;
export const GET_DCS_BY_RETAILER = gql`
  query getAllDcs($retailerMoniker: String!) {
    listDcs(retailerMoniker: $retailerMoniker) {
      default_returns_dc
      name
      retailer_dc_id
      supported_services
    }
  }
`;

export const GET_MODES = gql`
  query getModes {
    returns {
      policies {
        destinationMode
        returnMethodsMode
        feesMode
      }
    }
  }
`;

export const GET_DESTINATIONS_SETTINGS = gql`
  query GetDestinationsSettings {
    returns {
      policies {
        destinationMode
        returnMethodsMode
        feesMode
      }
    }
  }
`;

export const GET_BRANDING_SETTINGS = gql`
  query brandingStuff(
    $locale: String!
    $products: [String]!
    $locales: [String]!
  ) {
    returnsBranding(locale: $locale) {
      global {
        headerStyle
        backgroundImage {
          desktop
          mobile
        }
      }
      retailerSpecific {
        headerStyle
        backgroundImage {
          desktop
          mobile
        }
      }
    }
    navigationMenus(products: $products, locales: $locales) {
      product
      locale
      items {
        label
        url
      }
    }
  }
`;
