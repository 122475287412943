import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  RECEIVE_DASHBOARD,
  UPDATE_DATE_RANGE,
  UPDATE_SELECTED_LOCALE,
  RECEIVE_CHART_DATA,
  RECEIVE_NEW_SMS_ENABLED,
} from '~src/actions/messagingSmsDashboardAnalyticsActions';

const initialState = {
  startDate: moment()
    .subtract(30, 'days')
    .format(API_DATE_FORMAT),
  endDate: moment()
    .subtract(1, 'days')
    .format(API_DATE_FORMAT),
  supportedLocales: [],
  selectedLocale: {
    locale: 'en_US',
  },
  chartData: {},
  dimensions: [],
  errors: [],
  dashboard: {},
  object: '',
  reports: {},
  reportDefinitions: {},
  reportsPayload: [],
  newSMSEnabled: false,
};

export default (
  state = initialState,
  {
    type,
    meta: { chartName } = {},
    payload: {
      startDate,
      endDate,
      selectedLocale,
      chartData,
      tableData,
      reportDefinitions,
      reportInstances,
      newSMSEnabled,
      dashboard_definitions: dashboard,
    } = {},
  } = {},
) => {
  switch (type) {
    case RECEIVE_DASHBOARD:
      return {
        ...state,
        dashboard,
        object: Object.keys(reportDefinitions)[0],
        reports: {
          ...state.reports,
          ...reportInstances,
        },
        reportDefinitions,
      };
    case UPDATE_SELECTED_LOCALE:
      return {
        ...state,
        selectedLocale,
      };

    case UPDATE_DATE_RANGE:
      return {
        ...state,
        startDate,
        endDate,
      };
    case RECEIVE_NEW_SMS_ENABLED:
      return {
        ...state,
        newSMSEnabled,
      };
    case RECEIVE_CHART_DATA:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          [chartName]: chartData,
        },
        tableData: {
          ...state.tableData,
          [chartName]: tableData,
        },
      };

    default:
      return state;
  }
};
