import fetch from 'isomorphic-unfetch';

// eslint-disable-next-line prefer-destructuring
const ZENDESK_WIDGET_API_KEY = process.env.ZENDESK_WIDGET_API_KEY;

export const ZENDESK_SCRIPT_ID = 'ze-snippet';

const waitForZendeskEmbed = callback => {
  const interval = setInterval(() => {
    if (!window.zE) return;
    clearInterval(interval);
    callback();
  }, 500);

  return interval;
};

export default (urlPagePath = '') => {
  if (typeof window !== 'object') return;

  // Settings for authentication with JWT must be set before loading the script.
  const settingsLoaded = Boolean(window.zESettings);
  if (!settingsLoaded) {
    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: async callback => {
            try {
              const response = await fetch('/api/zendesk/widget/jwt');
              const jwt = await response.text();
              callback(jwt);
            } catch (err) {
              // Oh well, no Zendesk for you ¯\_(ツ)_/¯
            }
          },
        },
      },
    };
  }

  const scriptInjected = Boolean(document.getElementById(ZENDESK_SCRIPT_ID));
  if (!scriptInjected) {
    const scriptEl = document.createElement('script');
    scriptEl.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_WIDGET_API_KEY}`;
    scriptEl.id = ZENDESK_SCRIPT_ID;
    document.head.appendChild(scriptEl);
  }
};
