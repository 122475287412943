import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';
import Select from '../Select';
import Svg from '../Svg';

const s = css`
  display: flex;

  .is-open {
    &.shadow {
      opacity: 0.4;
      visibility: visible;
    }

   &.nav {
      transform: translate(0, 0);
    }
  }

  .shadow {
    background-color: ${colors.black};
    bottom: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    transition: opacity 420ms;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 3;
  }

  .nav {
    background-color: ${colors.white};
    bottom: 0;
    flex-direction: column;
    left: 0;
    position: fixed;
    top: 0;
    transform: translate(-320px, 0);
    transition: transform 200ms;
    width: 320px;
    z-index: 4;

    .multi-tenant {
      margin: 0 1rem 2rem 1rem;

      .dropdown-toggle {
        box-shadow: none;
        border: 0;
        color: ${colors.dark1};
        font-family: ${fonts.bold};
        font-size: 18px;
        padding-left: 0;
        line-height: 40px;
      }
    }

    .scroll-container {
      overflow-y: auto;
    }

    header {
      padding: 1rem 1rem 2rem 1rem;

      .close {
        transform: scale(0.5);
      }
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        padding: 5px 0 5px 30px;

        a {
          color: ${colors.black};
        }

        &:first-of-type {
          padding-top: 0;
        }

        &.no-icon {
          padding-left: 40px;

          &:first-of-type {
            padding-top: 10px;
          }
        }
      }

      h2 {
        color: ${colors.dark4};
        font-family: 'circular';
        font-size: 12px;
        margin: 0;
        padding: 1rem 1rem 0.25rem;
        text-transform: uppercase;
      }

      .sub-section {
        background-color: ${colors.grayLightest};
        margin-bottom: 2rem;
        overflow: hidden;
        padding: 1rem 0.4rem 1rem 1rem;

        &:last-child {
        }

        .product-icon {
          path {
            opacity: 1;
          }
        }

        &.is-collapsed {
          background-color: ${colors.white};
          max-height: 32px;

          .product-icon {
            path {
              opacity: 0.4;
            }
          }

          .triangle {
            transform: rotate(-90deg) scale(0.5);
          }
        }

        h3 {
          cursor: pointer;
          display: flex;

          svg {
            margin-right: 10px;
          }
        }

      }

      hr {
        margin: 0 30px 0 15px;
      }

      .triangle {
        transform: scale(0.5);
        transition: transform 200ms;
        margin-left: auto;
      }
    }
  }
`;

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedSections: [],
      retailerId: 0, // eslint-disable-line react/no-unused-state
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.retailerId !== state.retailerId) {
      return {
        expandedSections: [],
        retailerId: props.retailerId,
      };
    }

    return null;
  }

  handleToggleSection = event => {
    const { expandedSections } = this.state;
    const { dataset: { section } } = event.currentTarget;
    const expandedIndex = expandedSections.indexOf(section);

    if (expandedIndex > -1) {
      expandedSections.splice(expandedIndex, 1);
    } else {
      expandedSections.push(section);
    }

    this.setState({
      expandedSections,
    });
  }

  render() {
    const {
      handleSelectTenant,
      isOpen,
      link: Link,
      onToggle,
      retailerId,
      sections,
      selectItems,
      showTenantDropdown,
    } = this.props;
    const { expandedSections } = this.state;

    return (
      <nav className={s}>
        <div className={cx('shadow', isOpen && 'is-open')} onClick={onToggle} role="none" />
        <div className={cx('nav', isOpen && 'is-open')}>
          <div className="scroll-container">
            <header>
              <Svg
                name="narvar_logo"
                height="30"
                width="130"
              />
              <Svg
                className="close"
                onClick={onToggle}
                height="30"
                name="close"
                width="30"
              />
            </header>
            {showTenantDropdown && (
              <div className="multi-tenant">
                <Select
                  items={selectItems}
                  name="multi-tenant"
                  onSelect={handleSelectTenant}
                  readableKey="retailer_name"
                  selected={
                    selectItems
                    .find(retailer => retailerId === retailer.retailer_id)
                  }
                  testAttr="retailer"
                />
              </div>
            )}
            {sections.map((section, sectionIndex) => (
              <ul className="section" key={sectionIndex}>
                {section.length > 0 &&
                  section.map((subSection, subSectionIndex) => (
                    <div
                      className={cx('sub-section', expandedSections.includes(`${sectionIndex}/${subSectionIndex}`) ? '' : 'is-collapsed')}
                      key={subSection.title}
                    >
                      <div
                        data-test={`${subSection.title}-section`}
                        data-section={`${sectionIndex}/${subSectionIndex}`}
                        onClick={this.handleToggleSection}
                        role="none"
                      >
                        <h3>
                          <Svg className="product-icon" name={subSection.icon} />
                          {subSection.title}
                          <Svg className="triangle" name="triangle" />
                        </h3>
                      </div>
                      {subSection.items.map(item => (
                        <li key={item.title}>
                          <Link
                            activeStyle={{
                              borderLeftColor: colors.blue,
                            }}
                            data-test={`${subSection.title}-${item.title.replace(' ', '-')}-nav`}
                            onClick={onToggle}
                            to={item.link}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </div>
                  ))}
                {sectionIndex !== sections.length - 1 && <hr />}
              </ul>
            ))}
          </div>
        </div>
      </nav>
    );
  }
}

Nav.defaultProps = {
  handleSelectTenant: () => {},
  isOpen: false,
  link: () => <a href="/">Link</a>,
  onToggle: () => {},
  sections: [],
  selectItems: [],
  showTenantDropdown: false,
};

Nav.propTypes = {
  handleSelectTenant: PropTypes.func,
  isOpen: PropTypes.bool,
  link: PropTypes.object, // TODO: import react-router `Link` properly
  onToggle: PropTypes.func,
  retailerId: PropTypes.number.isRequired,
  sections: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
    })),
  }))),
  selectItems: PropTypes.arrayOf(PropTypes.shape({
  })),
  showTenantDropdown: PropTypes.bool,
};

Nav.displayName = 'Nav';

export default Nav;
