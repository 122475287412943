import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loader.styles';

export const Loader = ({
  className,
  show,
}) => (show ? (
  <div
    data-test="loader"
    className={cx(s, className)}
  />
) : null);

Loader.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
};

Loader.defaultProps = {
  className: '',
  show: true,
};

Loader.displayName = 'Loader';

export default Loader;
