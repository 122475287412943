import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Sensitive.styles';
import Svg from '../Svg';
import Tooltip from '../Tooltip';

export class Sensitive extends Component {
  static defaultProps = {
    disabled: false,
    name: 'inputName',
    onBlur: () => {},
    onChange: () => {},
    onKeyDown: () => {},
    value: '',
    tooltipText: 'Click to View/Hide',
  };

  static propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    value: PropTypes.string,
    tooltipText: PropTypes.string,
    validationError: PropTypes.bool,
  };

  state = {
    show: false,
    isTooltipOpen: false,
  }

  onToggleTooltip = () => {
    this.setState({
      isTooltipOpen: !this.state.isTooltipOpen,
    });
  };

  toggleVisibility = () => {
    this.setState({
      show: !this.state.show,
    });
  };

  render() {
    const {
      props: {
        name,
        value,
        onChange,
        onKeyDown,
        onBlur,
        disabled,
        tooltipText,
        validationError,
      } = {},
      state,
    } = this;

    return (
      <div className={cx(s, 'sensitive-input-container')}>
        <input
          data-test={
            `${name.toString().toLowerCase()}-input`
          }
          value={value}
          type={state.show ? 'text' : 'password'}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={disabled}
          className={cx(s,
            'form-control',
            'sensitive-input',
            validationError ? 'error' : null,
          )}
        />
        <Svg
          data-test={
            `${name.toString().toLowerCase()}-svg`
          }
          className={cx(s, 'svg-icon')}
          name={state.show ? 'show' : 'hide'}
          onClick={this.toggleVisibility}
          id={`show-sensitive-input-${name}`}
        />
        <Tooltip
          placement={'top'}
          target={`show-sensitive-input-${name}`}
          isOpen={Boolean(state.isTooltipOpen)}
          toggle={this.onToggleTooltip}
          delay={{ show: 0, hide: 0 }}
        >
          {tooltipText}
        </Tooltip>
      </div>
    );
  }
}

export default Sensitive;
