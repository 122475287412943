import {
  CREATE_NEW_MESSAGE,
  DISMISS_ALERT,
  RECEIVE_MESSAGES,
  RECEIVE_TENANT_MESSAGE,
  FILTER_MESSAGES_BY_ID,
  RECEIVE_CARRIERS,
  RECEIVE_UPDATE_MESSAGE,
  RECEIVE_PUBLISH_MESSAGE,
  RECEIVE_CREATE_MESSAGE,
  RECEIVE_MESSAGES_ERRORS,
  RECEIVE_HEADERS_LOCALES,
} from '~src/actions/broadcastMessageActions';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  alert: {
    show: false,
  },
  broadcast_messages: [],
  carriers: [],
  changeDetected: false,
  headers: [],
  isPreviewable: false,
  isPublishable: false,
  locale: 'en_US',
  locales: [],
  message: {
    title: '',
    content: '',
    status: 'Inactive',
    carrier: { carrier: 'All', carrier_id: 'all' },
    sub_messages: [],
    start_date: null,
    end_date: null,
    date_modified: '',
  },
  previewUrl: '',
};

const messagesManagementReducer = (state = initialState, action) => {
  let message;
  const { meta, payload, type } = action;
  let previewUrl;
  let locale;
  let currentMessageState;

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_TENANT_MESSAGE:
      locale = action.meta.locale;
      message = action.message || {
        title: '',
        content: '',
        status: 'Inactive',
        carrier: {},
        sub_messages: [],
        start_date: null,
        end_date: null,
        date_modified: '',
      };

      return {
        ...state,
        alert: initialState.alert,
        locale,
        message,
      };

    case RECEIVE_MESSAGES:
      locale = action.locale;
      const { broadcast_messages, headers } = action;

      return {
        ...state,
        alert: initialState.alert,
        broadcast_messages,
        headers,
        locale,
      };

    case FILTER_MESSAGES_BY_ID:
      message = state.broadcast_messages.find(
        m => m.id === Number(action.meta.id),
      );

      return {
        ...state,
        message,
      };

    case RECEIVE_HEADERS_LOCALES:
      const { locales } = action;

      return {
        ...state,
        locales,
      };

    case RECEIVE_UPDATE_MESSAGE:
      message = action.message;
      currentMessageState = state.message;
      previewUrl = action.previewUrl;

      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
        message: { ...currentMessageState, ...message },
        previewUrl,
        isPreviewable: true,
        isPublishable: true,
      };

    case CREATE_NEW_MESSAGE:
      return {
        ...state,
        message: {
          title: '',
          content: '',
          status: 'Inactive',
          carrier: { carrier: 'All', carrier_id: 'all' },
          sub_messages: [],
          start_date: null,
          end_date: null,
          date_modified: '',
        },
      };

    case RECEIVE_CREATE_MESSAGE:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
        isPublishable: true,
        isPreviewable: true,
        message: payload.message,
        previewUrl: payload.previewUrl,
      };

    case RECEIVE_MESSAGES_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            meta.errors && meta.errors[0]
              ? meta.errors[0].message
              : 'There was an error. Please try again.',
        },
      };

    case RECEIVE_PUBLISH_MESSAGE:
      message = action.message;

      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes will be published within 15 minutes.',
        },
        broadcast_messages: [...state.broadcast_messages, message],
        message,
        isPublishable: false,
        isPreviewable: false,
      };

    case RECEIVE_CARRIERS:
      let { carriers } = action;
      // For whatever reason in tenant broadcast where you can
      // only publish to one carrier the carriers are returned as
      // a single object vs an array with a single object in it
      if (!Array.isArray(carriers)) {
        carriers = [carriers];
      }

      return {
        ...state,
        carriers,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert,
          color: state.alert.color,
        },
      };

    default:
      return state;
  }
};

export default messagesManagementReducer;
