import { API_REQUEST, API_INVALIDATE_PATH } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';

export const REQUEST_SHIP_SETTINGS = 'REQUEST_SHIP_SETTINGS';
export const RECEIVE_SHIP_SETTINGS = 'RECEIVE_SHIP_SETTINGS';
export const REQUEST_MODIFY_SHIP_SETTINGS = 'REQUEST_MODIFY_SHIP_SETTINGS';
export const RECEIVE_MODIFY_SHIP_SETTINGS = 'RECEIVE_MODIFY_SHIP_SETTINGS';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const RECEIVE_SHIP_SETTINGS_ERRORS = 'UPDATE_SHIP_SETTINGS_ERRORS';
export const CLEAR_SHIP_SETTINGS_FORM_AND_ERRORS =
  'CLEAR_SHIP_SETTINGS_FORM_AND_ERRORS';
export const TOGGLE_COST_OPTIMIZATION = 'TOGGLE_COST_OPTIMIZATION';

export const resetFormAndErrors = () => ({
  type: CLEAR_SHIP_SETTINGS_FORM_AND_ERRORS,
});

const formatCarrierServices = carrierServicesArray =>
  carrierServicesArray.reduce(
    (
      acc,
      { carrier_name: carrierName, service_types: serviceTypes, enabled },
    ) => ({
      ...acc,
      [carrierName]: {
        serviceTypes,
        enabled,
      },
    }),
    {},
  );

export const receiveShipSettings = payload => {
  const {
    response: {
      product_categories: productCategories,
      shipping_countries: shippingCountries,
      shipping_tags: shippingTags,
      isOriginOptimizationEnabled,
      ship_settings_modified_date: dateModified,
    },
  } = payload;
  return {
    type: RECEIVE_SHIP_SETTINGS,
    payload: {
      productCategories,
      shippingCountries,
      shippingTags,
      isOriginOptimizationEnabled,
      dateModified,
    },
  };
};

export const receiveOriginOptimizationSettings = payload => {
  const { response: { isOriginOptimizationEnabled } = {} } = payload;
  return {
    type: TOGGLE_COST_OPTIMIZATION,
    payload: {
      isOriginOptimizationEnabled,
    },
  };
};

export const fetchShipSettings = () => async dispatch => {
  const json = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.SHIP_SETTINGS}`,
    name: 'fetchShipSettings',
  });
  dispatch(receiveShipSettings(json));
};

export const fetchOriginOptimizationSettings = retailerMoniker => async dispatch => {
  const query = { retailer_moniker: retailerMoniker };
  const json = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.UNKNOWN_ORIGIN}`,
    name: 'fetchOriginOptimizationSettings',
    query,
  });
  dispatch(receiveOriginOptimizationSettings(json));
};

export const receiveModifyShipSettings = payload => {
  const {
    response: {
      product_categories: productCategories,
      shipping_countries: shippingCountries,
      shipping_tags: shippingTags,
      isOriginOptimizationEnabled,
      ship_settings_modified_date: dateModified,
    },
  } = payload;
  return {
    type: UPDATE_SUCCESS,
    payload: {
      productCategories,
      shippingCountries,
      shippingTags,
      isOriginOptimizationEnabled,
      dateModified,
    },
  };
};

export const receiveModifyShipSettingsError = messages => ({
  type: RECEIVE_SHIP_SETTINGS_ERRORS,
  messages,
});

export const fetchModifyShipSettings = data => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.SHIP_SETTINGS}`,
    body: JSON.stringify(data),
    name: 'fetchModifyShipSettings',
  }).then(json => {
    if (json.status.toLowerCase() === 'success') {
      dispatch(receiveModifyShipSettings(json));
      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.DISTRIBUTION_CENTER_COSTS,
      });
    } else {
      dispatch(receiveModifyShipSettingsError(`${json.errors[0].message}.`));
    }
  });

export const toggleCostOptimization = flag => ({
  type: TOGGLE_COST_OPTIMIZATION,
  payload: {
    isOriginOptimizationEnabled: flag,
  },
});

export const fetchModifyOriginOptimizationSettings = data => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.UNKNOWN_ORIGIN}`,
    body: JSON.stringify(data),
    name: 'fetchModifyOriginOptimization',
  }).then(json => {
    if (json.status.toLowerCase() === 'success') {
      const {
        response: { isOriginOptimizationEnabled },
      } = json;
      dispatch(toggleCostOptimization(isOriginOptimizationEnabled));

      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.DISTRIBUTION_CENTER_COSTS,
      });
    } else {
      dispatch(receiveModifyShipSettingsError(`${json.errors[0].message}.`));
    }
  });

export const fetchSyncDistributionCenters = data => async dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.COPY_DISTRIBUTION_CENTERS}`,
    body: JSON.stringify(data),
    name: 'fetchSyncDistributionCenters',
  }).then(json => {
    if (json.status.toLowerCase() !== 'success') {
      dispatch(receiveModifyShipSettingsError(`${json.errors[0].message}.`));
    }
  });
