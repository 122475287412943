import moment from 'moment';

export const RETURN_ANALYTICS = 'return_analytics';
export const TEMP_RETURN_ANALYTICS = 'temp_return_analytics';
export const TEMP_RETURN_METHOD = 'temp_return_method';
export const TEMP_RETURN_REASONS_AGGREGATE = 'temp_return_reasons_aggregate';
export const TEMP_EXCHANGE_RATE = 'temp_exchange_rate';
export const TEMP_GIFT_CARD_RATE = 'temp_gift_card_rate';
export const GIFT_CARD_GMV = 'retained_gmv_credit';
export const TEMP_AVERAGE_DAYS_SHIPPED_CONCIERGE =
  'temp_average_days_shipped_concierge';
export const TEMP_AVERAGE_ITEMS_PER_CONCIERGE_RETURN =
  'temp_average_items_per_concierge_return';
export const TEMP_RETURN_RATE = 'temp_return_rate';

// Hardcoding this until Analytics API can pass back messages
export const RETURN_RATE_START_DATE = moment('20191001', 'YYYYMMDD');
export const RETURN_RATE_START_DATE_FORMATTED = RETURN_RATE_START_DATE.format(
  'll',
);

export const REFUND_OPTIONS = [
  'store_credit',
  'GIFT_CARD',
  'gift_card',
  'instant_store_credit',
];
