import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';
import { Svg } from '../Svg';

const s = css`
  position: fixed;
  height: 120px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .top-bar {
    background-color: ${colors.black};
    display: flex;
    height: 60px;
    padding: 0 20px;
    transition: background-color 150ms;
    width: 100%;

    h1 {
      color: ${colors.white};
      font-size: 18px;
      margin: auto 0;
    }

    svg {
      cursor: pointer;
      margin: auto 10px;
      transform: scale(0.8);

      path {
        fill: ${colors.white};
        fill-opacity: 0.4;
        transition: fill-opacity 250ms;
      }

    }

    &.is-customer-support {
      background-color: ${colors.blue};
    }

    &:active {
      path {
        fill-opacity: 0.8;
      }
    }

    &:hover {
      path {
        fill-opacity: 1;
      }
    }
  }

  .secondary-bar {
    align-items: center;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.gray};
    color: #2C3239;
    display: flex;
    height: 60px;
    justify-content: flex-start;
    padding: 0 20px;
    width: 100%;

    h2 {
      font-size: 20px;
      line-height: 40px;
      margin: 0;
      padding-left: 40px;
    }
  }
`;

export default ({ children, isCustomerSupport, onToggleNav, primaryTitle, secondaryTitle }) => {

  return (
    <header className={s}>
      <div className={cx('top-bar', isCustomerSupport ? 'is-customer-support' : '')}>
        <Svg name="menu" onClick={onToggleNav} width="24" height="16" />
        <h1>{primaryTitle}</h1>
      </div>
      <div className="secondary-bar">
        <h2>{secondaryTitle}</h2>
        {children}
      </div>
      <div>
      </div>
    </header>
  );
}
