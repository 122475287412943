import { css } from 'emotion';

const s = css`
  & .full-table {
    & tr:last-child div {
      border-bottom: 0;
    }
  }
`;

export default s;
