import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './ChangeStatusModal.styles.js';
import { ModalFooter, ModalBody } from 'reactstrap';
import Button from '../Button';
import Modal from '../Modal';

export const ChangeStatusModal = (
  { enabled, type, close, showModal, changeStatus }
) => {
  let ChangeStatusModalJSX;

  if (enabled) {
    ChangeStatusModalJSX = (
      <Modal
        isOpen={showModal}
        toggle={close}
        className={s}
        title={'Confirmation'}
      >
        <ModalBody>
          <p>
            You are about to deactive this
            {' '}
            {type}
            . All the information will be saved, but not available anymore.
          </p>
          <p>Are you sure you want to deactivate this {type}?</p>
        </ModalBody>
        <ModalFooter>
          <Button color={'link'} data-test={'cancel-button'} onClick={close}>
            Cancel
          </Button>
          <Button
            color={'danger'}
            data-test={'deactivate-button-modal'}
            onClick={changeStatus}
          >
            Deactivate
          </Button>
        </ModalFooter>
      </Modal>
    );
  } else {
    ChangeStatusModalJSX = (
      <Modal
        isOpen={showModal}
        toggle={close}
        title={'Confirmation'}
      >
        <ModalBody>
          <p>
            You are about to activate this
            {' '}
            {type}
            . All the information will be saved and available.
          </p>
          <p>Are you sure you want to activate this {type}?</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'link'}
            onClick={close}
            data-test={'cance-button-modal'}
          >
            Cancel
          </Button>
          <Button
            color={'primary'}
            onClick={changeStatus}
            data-test={'activate-button-modal'}
          >
            Activate
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return ChangeStatusModalJSX;
};

ChangeStatusModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  enabled: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

ChangeStatusModal.displayName = 'ChangeStatusModal';

export default ChangeStatusModal;
