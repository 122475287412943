import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  height: 100%;
  width: 100%;

  & .loader {
    border-top-color: ${colors.gray};
    border-right-color: ${colors.gray};
    border-bottom-color: ${colors.gray};
    border-left-color: transparent;
    border-width: 0.6em;
    height: 4em;
    margin: 0 auto;
    position: absolute;
    right: 12px;
    width: 4em;
  }

  & .no-results {
    padding-left: 10px;
  }


  & button + button {
    margin: 0;
  }

  & .btn-secondary.dropdown-toggle {
    background-color: #fff;
    border-color: ${colors.gray};

    & :after {
      color: transparent;
    }

    & :active {
      background-color: #fff;
      border-color: ${colors.gray};
    }

    & input[type="text"] {
      border: none;
      box-shadow: none;
      padding: 0px;
      height: inherit;
    }
  }

  &.is-fetching,
  &.is-selected, {
    & .dropdown-toggle:after {
      color: transparent;
    }
  }

  &.is-selected {
    & input {
      color: ${colors.dark1};
    }
  }

  & .close {
    margin-right: 5px;
    position: relative;
  }

  & .btn {
    border-color: ${colors.gray};
    border-radius: 2px;
    display: flex;
    font-family: ${fonts.regular};
    height: 100%;
    letter-spacing: .4;
    min-height: 40px;
    min-width: 100px;
    text-align: left;
    text-transform: none;
    width: 100%;
  }

  & .dropdown-toggle span {
    flex: 1;
  }

  & .dropdown-toggle.btn.btn-primary:after {
    color: ${colors.blue};
  }

  & .btn:disabled {
    @extend .btn;
    opacity: 1;
  }

  & .dropdown-toggle {
    background-color: ${colors.white};
  }

  & .dropdown-toggle:after {
    color: ${colors.gray};
    margin: auto 0 auto auto;
  }

  & .dropdown-toggle:disabled:after {
    display: none;
  }

  & .btn-secondary.active {
    background-color: ${colors.white};
  }

  &.show {
    & .dropdown-menu {
      border: 1px solid ${colors.gray};
      display: flex;
      flex-direction: column;
      max-height: calc((30px * 4.7) + 50px);
      min-height: 60px;
      width: 100%;
      min-width: 200px;
      overflow-y: scroll;
      font-size: 14px;
      line-height: 20px;
    }
  }

  & .dropdown-menu {
    border: none;
    min-width: 200px;
    padding: 10px 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }

  & .dropdown-menu:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    content: '';
  }

  & .dropdown-menu:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${colors.white};
    border-left: 6px solid transparent;
    content: '';
  }

  & .dropdown-menu.dropdown-menu-right:before {
    @extend .dropdown-menu:before;
    left: 135px;
  }

  & .dropdown-menu.dropdown-menu-right:after {
    @extend .dropdown-menu:after;
    left: 136px;
  }

  & .select-item {
    background: ${colors.white};
    border-width: 0 0 0 2px;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none;
    color: ${colors.dark2};
    line-height: 2.86rem;
    padding: 0 20px;
    transition: background-color 100ms;

    &.activeItem {
      background-color: ${colors.grayLight};
      color: ${colors.dark1};
    }


    &:hover {
      background-color: ${colors.grayLight};
      color: ${colors.dark1};
    }

    &:focus:active,
    &:active {
      background-color: ${colors.blue};
      color: ${colors.white};
      .description {
        color: ${colors.white};
      }

    }

    &.active {
      border-left-color: ${colors.brandPrimary};
      background-color: ${colors.grayLight};
      color: ${colors.dark1};
    }

    & > span {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      min-width: 0;
      white-space: nowrap;

    }

    & .description {
      margin: auto 10px auto 5px;
      color: ${colors.grayDark};
      font-size: 0.875rem;
    }

  }

  & .select-checkbox-label {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    padding: 10px 20px 0;

    & .select-checkbox-label-text {
      flex: 1;
      margin-right: 2px;
    }
  }

  & label {
    & svg {
      transform: scale(0.4);
      transform-origin: 10% 24% 0px;
      & path {
        fill: ${colors.white};
        fill-opacity: 1;
      }
    }
  }

  & .select-checkbox {
    border: 1px solid ${colors.blue};
    border-radius: 2px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  & .selected {
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95%;
  }

  & .form-control {
    color: ${colors.textMuted};
    font-family: ${fonts.regular};
  }

  & .select-group {
    color: ${colors.textMuted};
    font-size: 0.8rem;
    line-height: 1.4rem;
  }

  & .select-group:hover {
    background: ${colors.white};
    font-family: ${fonts.regular};
  }
  & .select-group:focus:active, .select-group:active{
    background: ${colors.white};
    color: ${colors.textMuted};
    font-family: ${fonts.regular};
  }
`;

export default s;
