import { orderSchema, generateCriteria } from './orderSchema';
import { customCriteriaDefinitions } from './customCriteriaDefinitions';
import { deprecatedDefinitions } from './deprecatedDefinitions';

export const criteriaDefinitions = ({ ruleType }) => {
  return [
    ...deprecatedDefinitions({ ruleType }),
    ...customCriteriaDefinitions({ ruleType }),
    ...generateCriteria({ schema: orderSchema, ruleType }),
  ].sort((a, b) =>
    a.attribute_display_value.localeCompare(b.attribute_display_value),
  );
};
