/* Helper/utility functions that don't need to be in components */

export function formatStringToTitle(str) {
  // Remove `"`s often found in queries
  if (str) {
    str = str.replace(/"/g, '');
    str = str.replace(/,/g, ', ');
    str = str.toLowerCase().split('_');

    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].split('');
      str[i][0] = str[i][0].toUpperCase();
      str[i] = str[i].join('');
    }

    return str.join(' ');
  } else {
    return '';
  }
}

export function debounce(fn, wait, immediate) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;

    function later() {
      timeout = null;
      if (!immediate) fn.apply(context, args);
    }

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      fn.apply(context, args);
    }
  };
}

export function shouldUpdate(a, b) {
  // Equal numbers, strings
  if ((typeof a === 'string' || typeof a === 'number') && a === b) {
    return false;
  }

  // Most common usecase where prop is an array of
  // objects that is expensive to update (e.g., <Select />).
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return true;
    }
  }

  if (JSON.stringify(a) === JSON.stringify(b)) {
    return false;
  } else {
    return true;
  }
}

export function capitalizeFirstLtrOfString(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}
