import cx from 'classnames';
import moment from 'moment';
import { isEmail } from 'validator';
import { getCardDiff } from '../../containers/Notify/Settings';
import {
  MESSAGING_LITE_EMAILS,
  MESSAGING_STANDARD_EMAIL,
} from '../../constants/messaging/engage';
// enabled status has been commented out as it may be requested to be put back in
// We likely don't want to be able to alter the ui without a preview

const narvarDomain = __ENV__ === 'production' ? '.narvar.com' : '.narvar.qa'; // eslint-disable-line no-undef

export const createSchema = (
  handlers = {},
  data = {},
  s = {},
  schemaProps = {},
) => {
  const {
    senderDomainChanged,
    facebook: {
      auth_token: fbAuthToken = '',
      custom_message: fbCustomMessage = '',
      // enabled: facebookEnabled = false,
      use_retailer_page: useRetailerPage = false,
      item_visibility_proactive: itemVisibilityProactive = false,
      responses_list: fbResponsesList,
      responses_list_selected: fbResponsesListSelected,
      response_frequency_list: fbResponseFrequencyList,
      response_frequency_list_selected: fbResponseFrequencyListSelected,
      item_visibility_image_aspect_ratio_list: fbOrderImageAspectRatioList,
      item_visibility_image_aspect_ratio_list_selected: fbOrderImageAspectRatioSelected,
      item_visibility_enabled: itemVisibilityEnabled,
      cold_start_message_enabled: coldStartMessageEnabled,
    } = {},
    messageService: { service_id: messageServiceId = '' } = {},
    email: {
      has_api_key: hasApiKey = false,
      domain_list: domainList,
      domain_list_selected: domainListSelected,
      sender_domain: senderDomain = '',
      validation_status: validationStatus = false,
      validation_error: validationError = null,
      dkim_record: dkimRecord = '',
      sender_email: senderEmail = '',
      sender_name: senderName = '',
      reply_to_email: replyToEmail = '',
      host_name: hostName = '',
    } = {},
    _dateModified: dateModified,
  } = data;
  let idCounter = 0;
  const getId = () => {
    idCounter += 1;
    return idCounter;
  };

  const compareDates = (obj1, obj2) => {
    if (!obj1.date_modified) {
      return obj2;
    }
    return new Date(obj1.date_modified).getTime() <
      new Date(obj2.date_modified).getTime()
      ? obj2
      : obj1;
  };
  const latestDateModified = Object.keys(dateModified).reduce(
    (fin, current) => compareDates(fin, dateModified[current]),
    {},
  );

  const { isFetching, uriMoniker, products = [] } = schemaProps;

  const replacedDomainListSelected = {
    ...domainListSelected,
    description: domainListSelected.description.replace(
      '%RETAILER_MONIKER%',
      uriMoniker,
    ),
  };
  const replacedDomainList = domainList.map(listItem => ({
    ...listItem,
    description: listItem.description.replace('%RETAILER_MONIKER%', uriMoniker),
  }));

  const domain =
    replacedDomainListSelected.type === 'narvar'
      ? `${uriMoniker}${narvarDomain}`
      : senderDomain;

  /* ************************ Message Service View *************************** */
  const messageServiceInput = {
    props: {
      key: getId(),
      label: 'Message Service',
      name: 'messageService/service_id',
      className: s['notify-half-width'],
      onChange: handlers.handleTextChange,
      placeholder: 'e.g. 0139410234912039423094',
      type: 'text',
      optionalField: true,
      isOptional: true,
      data: messageServiceId == null ? '' : messageServiceId,
    },
  };
  /* ************************************************************************** */

  /* ********************** FB Messenger section handler ********************** */
  // const fbBotResponsesView = {
  //   props: {
  //     id: getId(),
  //     label: '',
  //     name: 'facebook/enabled',
  //     className: s['facebook-bot-switch'],
  //     onChange: handlers.handleSwitch,
  //     type: 'switch',
  //     showSubText: true,
  //     enabledText: 'Enabled',
  //     disabledText: 'Disabled',
  //     // data: facebookEnabled,
  //   },
  // };
  // fbMessengerServiceView.push(fbBotResponsesView);

  const fbUseRetailerPage = {
    props: {
      key: getId(),
      label: 'Use Retailer Facebook Account',
      name: 'facebook/use_retailer_page',
      onChange: handlers.handleSwitch,
      type: 'switch',
      showSubText: true,
      enabledText: 'On',
      disabledText: 'Off',
      data: useRetailerPage,
    },
  };

  const fbAuthTokenView = {
    props: {
      key: getId(),
      label: 'Page Access Token',
      name: 'auth_token',
      className: s['notify-half-width'],
      placeholder: 'e.g. 1234567890',
      onChange: handlers.handleFbTokenChange,
      type: 'sensitive',
      validationError:
        fbAuthToken.length === 0 ? 'You must provide a page access token.' : '',
      data: fbAuthToken,
    },
  };
  const fbResponsesListView = {
    props: {
      key: getId(),
      label: 'Responses to FBM Notifications Customers',
      name: 'facebook/responses_list',
      className: s['notify-half-width'],
      onChange: handlers.handleSelect,
      type: 'select',
      items: fbResponsesList,
      selected: fbResponsesListSelected,
      readableKey: 'description',
      data: fbResponsesList,
    },
  };
  const fbResponseFrequency = {
    props: {
      key: getId(),
      label: 'Response Frequency',
      name: 'facebook/response_frequency_list',
      className: s['notify-half-width'],
      onChange: handlers.handleSelect,
      type: 'select',
      data: fbResponseFrequencyList,
      selected: fbResponseFrequencyListSelected,
      readableKey: 'description',
      items: fbResponseFrequencyList,
    },
  };

  const fbCustomMessageCharLimit = 160;
  const isCustomEmpty =
    fbCustomMessage.length === 0 ? 'You must enter a message.' : false;
  const isCustomTooLong =
    fbCustomMessage.length > fbCustomMessageCharLimit
      ? 'Your message must be 160 characters or less'
      : false;
  const fbCustomMessageView = {
    props: {
      key: getId(),
      label: 'Custom Message',
      name: 'facebook/custom_message',
      onChange: handlers.handleTextChange,
      type: 'textarea',
      charLimit: fbCustomMessageCharLimit,
      validationError: isCustomEmpty || isCustomTooLong,
      data: fbCustomMessage,
      placeholder:
        'Hi! Thanks for your message. Someone will respond within 24 hours.',
    },
  };

  const fbUserOrderLevelProactive = {
    props: {
      key: getId(),
      label: 'Item Visibility',
      name: 'facebook/item_visibility_proactive',
      onChange: handlers.handleSwitch,
      type: 'switch',
      showSubText: true,
      enabledText: 'On',
      disabledText: 'Off',
      data: itemVisibilityProactive,
    },
  };

  const fbOrderInfoImageAspectRatio = {
    props: {
      key: getId(),
      label: 'Item Visibility Image Aspect Ratio',
      name: 'facebook/item_visibility_image_aspect_ratio_list',
      className: s['notify-half-width'],
      onChange: handlers.handleSelect,
      type: 'select',
      data: fbOrderImageAspectRatioList,
      selected: fbOrderImageAspectRatioSelected,
      readableKey: 'description',
      items: fbOrderImageAspectRatioList,
    },
  };

  const fbColdStartEnabled = {
    props: {
      key: getId(),
      label: 'Responses to Anyone Else',
      name: 'facebook/cold_start_message_enabled',
      onChange: handlers.handleSwitch,
      type: 'switch',
      showSubText: true,
      enabledText: 'On',
      disabledText: 'Off',
      data: coldStartMessageEnabled,
    },
  };

  const fbMessengerServiceView = [fbUseRetailerPage];
  if (useRetailerPage) {
    fbMessengerServiceView.push(fbAuthTokenView, fbResponsesListView);
    if (fbResponsesListSelected.type === 'custom') {
      fbMessengerServiceView.push(fbResponseFrequency, fbCustomMessageView);
    }
    if (fbResponsesListSelected.type !== 'mute') {
      fbMessengerServiceView.push(fbColdStartEnabled);
    }
  }
  if (itemVisibilityEnabled) {
    fbMessengerServiceView.push(fbUserOrderLevelProactive);
    if (itemVisibilityProactive) {
      fbMessengerServiceView.push(fbOrderInfoImageAspectRatio);
    }
  }
  /* ********************** Email Channel section handler ********************** */
  // Account / DKIM
  const isNarvarDomain = replacedDomainListSelected.type === 'narvar';

  const emailDomainListView = {
    props: {
      key: getId(),
      label: 'Sender Domain',
      name: 'email/domain_list',
      className: s['notify-half-width'],
      onChange: handlers.handleSelect,
      type: 'select',
      items: replacedDomainList,
      selected: replacedDomainListSelected,
      readableKey: 'description',
      data: replacedDomainList,
      disabled: hasApiKey,
    },
  };
  const senderValidationError = (
    isANarvarDomain,
    sendDomainChanged,
    sendDomain,
  ) => {
    if (!isANarvarDomain && sendDomainChanged) {
      if (sendDomain.length === 0) {
        return 'You must enter a domain.';
      } else if (!isEmail(`test@${sendDomain}`)) {
        return 'Not a valid domain';
      }
    }
    return false;
  };

  const senderDomainView = {
    props: {
      key: getId(),
      label: 'Customer Sender Domain',
      name: 'email/sender_domain',
      className: s['notify-half-width'],
      hintText: hasApiKey
        ? ''
        : 'Once you create an account this domain cannot be changed.',
      onChange: handlers.handleTextChange,
      type: 'text',
      validationError: senderValidationError(
        isNarvarDomain,
        senderDomainChanged,
        senderDomain,
      ),
      data: isNarvarDomain ? `${uriMoniker}${narvarDomain}` : senderDomain,
      disabled: isNarvarDomain ? true : hasApiKey,
    },
  };

  const createAccountView = {
    props: {
      key: getId(),
      label: '',
      type: 'button',
      buttonColor: 'link',
      name: 'create-subaccount',
      // className: cx('btn-link'),
      buttonText: 'Create Account',
      onClick: handlers.handleAccountAndDkim,
    },
  };
  const createAccountAndDkimView = {
    props: {
      key: getId(),
      label: '',
      type: 'button',
      buttonColor: 'link',
      name: 'create-subaccount',
      // className: cx('btn-link'),
      buttonText: 'Create Account & Get DKIM Record',
      onClick: handlers.handleAccountAndDkim,
    },
  };
  const regenerateDkimView = {
    props: {
      key: getId(),
      label: '',
      type: 'button',
      buttonColor: 'link',
      name: 'create-subaccount',
      // className: cx('btn-link'),
      buttonText: 'Get New Dkim Record',
      onClick: handlers.handleAccountAndDkim,
    },
  };
  let message;
  if (validationError) {
    message = validationError === 404 ? 'DKIM Record Expired' : 'Invalid';
  } else {
    message = validationStatus ? 'Verified' : 'Waiting for verification';
  }
  const validationStatusView = {
    props: {
      key: getId(),
      label: 'Validation Status',
      className: s['notify-plain-text'],
      name: 'email/validation_status',
      onChange: () => {},
      type: 'static-text',
      data: message,
      disabled: true,
    },
  };
  const dkimView = {
    props: {
      key: getId(),
      label: 'DKIM Record',
      name: 'email/dkim_record',
      className: cx('notify-dkim-key-view'),
      onChange: () => {},
      type: 'textarea',
      data: dkimRecord,
      readOnly: true,
    },
  };
  const copyDkimView = {
    props: {
      key: getId(),
      label: '',
      type: 'button',
      buttonColor: 'link',
      name: 'copy-dkim',
      buttonText: 'copy',
      onClick: () => {
        handlers.handleCopyElement('.notify-dkim-key-view textarea');
      },
    },
  };

  const hostNameView = {
    props: {
      key: getId(),
      label: 'Hostname',
      name: 'email/dkim_record',
      className: cx('notify-host-name-view'),
      onChange: () => {},
      type: 'textarea',
      data: hostName,
      readOnly: true,
    },
  };
  const copyHostNameView = {
    props: {
      key: getId(),
      label: '',
      type: 'button',
      buttonColor: 'link',
      name: 'copy-dkim',
      buttonText: 'copy',
      onClick: () => {
        handlers.handleCopyElement('.notify-host-name-view textarea');
      },
    },
  };

  // Sender Attributes
  const divider = {
    props: {
      key: getId(),
      name: 'sender_divider',
      type: 'divider',
    },
  };

  const emailInputOk = input => input.length === 0 || isEmail(input);
  const emailSenderOk = input =>
    input.length === 0 || isEmail(`${input}@${domain}`);

  const senderEmailView = {
    props: {
      key: getId(),
      label: 'Sender Email',
      name: 'email/sender_email',
      className: s['sender-email-view'],
      onChange: handlers.handleTextChange,
      type: 'text',
      data: senderEmail,
      validationError: emailSenderOk(senderEmail)
        ? false
        : 'Not a valid email.',
    },
  };
  const senderEmailDomainView = {
    props: {
      key: getId(),
      label: '',
      name: 'email/sender_email_domain',
      className: s['static-domain-text'],
      onChange: () => {},
      type: 'static-text',
      data: `@${domain}`,
      readOnly: true,
    },
  };
  const senderNameView = {
    props: {
      key: getId(),
      label: 'Sender Name',
      name: 'email/sender_name',
      className: s['notify-half-width'],
      onChange: handlers.handleTextChange,
      type: 'text',
      data: senderName,
    },
  };

  const replyToEmailView = {
    props: {
      key: getId(),
      label: 'Reply-To Email',
      name: 'email/reply_to_email',
      className: s['notify-half-width'],
      onChange: handlers.handleTextChange,
      type: 'text',
      data: replyToEmail,
      validationError: emailInputOk(replyToEmail)
        ? false
        : 'Not a valid email.',
    },
  };

  const emailView = [emailDomainListView, senderDomainView];
  if (dkimRecord) {
    emailView.push(validationStatusView);
    if (validationError === 404) {
      emailView.push(regenerateDkimView);
    } else if (!validationError && !isNarvarDomain) {
      emailView.push(dkimView, copyDkimView, hostNameView, copyHostNameView);
    }
    emailView.push(
      divider,
      senderEmailView,
      senderEmailDomainView,
      senderNameView,
      replyToEmailView,
    );
  } else if (isNarvarDomain) {
    emailView.push(createAccountView);
  } else {
    emailView.push(createAccountAndDkimView);
  }

  const hasAlerts = products.includes('alerts');
  const hasEngageTierEmailsEnabled =
    products.includes(MESSAGING_STANDARD_EMAIL) ||
    products.includes(MESSAGING_LITE_EMAILS);

  /* ************************************************************************** */

  const fbChanged = Object.keys(getCardDiff(data, 'facebook')).length > 0;
  const messageChanged =
    Object.keys(getCardDiff(data, 'messageService')).length > 0;
  const emailChanged = Object.keys(getCardDiff(data, 'email')).length > 0;
  const hasChanged = fbChanged || messageChanged || emailChanged;

  const smsFetching = isFetching.messageService || false;
  const fbFetching =
    isFetching.facebook || isFetching.retailerInfoSettings || false;

  const emailFetching = isFetching.emailChannel || false;
  const metaFetching = isFetching.metaDetails || false;
  const smsCard = {
    header: 'SMS',
    props: {
      key: getId(),
      isFetching: smsFetching,
      collapsable: true,
    },
    formControls: [messageServiceInput],
  };
  const messengerCard = {
    header: 'Messenger',
    props: {
      key: getId(),
      isFetching: fbFetching,
      collapsable: true,
    },
    formControls: fbMessengerServiceView,
  };
  const emailCard = {
    header: 'Email',
    props: {
      collapsable: true,
      isFetching: emailFetching,
    },
    formControls: emailView,
  };

  const engageTierWarningCard = {
    header: 'Email',
    props: {
      collapsable: true,
      isFetching: emailFetching,
    },
    formControls: [
      {
        props: {
          label:
            'Sparkpost setup cannot be updated for tier customers from this page. Please reach out to messaging team.',
        },
      },
    ],
  };

  const cards = [smsCard, messengerCard];
  if (hasAlerts && !hasEngageTierEmailsEnabled) {
    cards.push(emailCard);
  }

  if (hasEngageTierEmailsEnabled) {
    cards.push(engageTierWarningCard);
  }

  let metaText = latestDateModified.date_modified
    ? moment(latestDateModified.date_modified).format('lll')
    : '';

  if (latestDateModified.date_modified) {
    metaText = moment(latestDateModified.date_modified).format('lll');
  }

  const rightMeta = metaText
    ? [
        {
          label: 'Last Modified',
          text: metaText,
        },
      ]
    : [];

  return {
    details: {
      header: {
        secondaryTitle: 'Notify Settings',
        backButton: false,
        rightActions: [
          {
            component: 'Button',
            props: {
              key: getId(),
              color: 'primary',
              onClick: handlers.handleSave,
              isFetching: false,
              disabled: !hasChanged,
            },
            children: 'PUBLISH',
          },
        ],
      },
      cards,
      rightSection: {
        header: 'Details',
        isFetching: metaFetching,
        formControls: [],
        meta: rightMeta,
      },
    },
  };
};
export default createSchema;
