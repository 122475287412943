import moment from 'moment';

export default ({ orders = [], columns = [], columnConfiguration = {} }) => ({
  tableHeaders: columns.map(column => {
    const {
      [column]: { label },
    } = columnConfiguration;

    return {
      field: column,
      label,
    };
  }),

  tableBody: orders.map(order => ({
    text: columns.map(column => {
      const { [column]: columnValue } = order;
      const { [column]: { format } = {} } = columnConfiguration;

      if (format === 'date' && moment(columnValue).isValid()) {
        return moment.utc(columnValue).format('MMM DD, YYYY hh:mm:ss a');
      }

      return columnValue;
    }),
  })),
});
