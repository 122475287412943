import {
  UPDATE_BRANDING_FORM,
  UPDATE_BRANDING_HAS_PREVIEWED,
  RESET_BRANDING_PUBLISH_STATE,
  TOGGLE_BRANDING_PUBLISH_MODAL,
  RECEIVE_BRANDING_SETTINGS,
  RECEIVE_BRANDING_PUBLISH,
  FETCHING_BRANDING_SETTINGS,
  UPDATE_BRANDING_SELECTED_LOCALE,
  UPDATE_BRANDING_SETTINGS,
  FETCHING_BRANDING_ERROR,
} from '../actions/returnsBrandingActions';

export const initialState = {
  selectedLocale: {
    locale: 'en_US',
  },
  isFetching: false,
  errors: {},
  navigationMenus: {},
  form: {
    desktopImage: null,
    mobileImage: null,
    headerStyle: null,
    navigationMenuItems: [],
    useTrackNav: false,
    errors: {},
  },
  showPublishModal: false,
  changeDetected: false,
  hasPreviewed: false,
};

const returnsBrandingReducer = (
  state = initialState,
  {
    type,
    payload: {
      selectedLocale,
      form,
      changeDetected,
      hasPreviewed,
      navigationMenus,
    } = {},
  } = {},
) => {
  switch (type) {
    case UPDATE_BRANDING_SELECTED_LOCALE:
      return {
        ...state,
        selectedLocale,
      };

    case FETCHING_BRANDING_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case FETCHING_BRANDING_ERROR:
      return {
        ...state,
        isFetching: false,
        form: {
          navigationMenuItems: [],
          errors: {},
        },
      };

    case UPDATE_BRANDING_SETTINGS:
      return {
        ...state,
        form,
        navigationMenus,
        changeDetected,
        hasPreviewed,
      };

    case RECEIVE_BRANDING_PUBLISH:
      return {
        ...state,
        isFetching: false,
        navigationMenus,
        form,
      };

    case RECEIVE_BRANDING_SETTINGS:
      return {
        ...state,
        isFetching: false,
        form,
        navigationMenus,
      };

    case UPDATE_BRANDING_FORM:
      return {
        ...state,
        form,
        changeDetected,
        hasPreviewed,
      };

    case UPDATE_BRANDING_HAS_PREVIEWED:
      return {
        ...state,
        hasPreviewed,
      };

    case RESET_BRANDING_PUBLISH_STATE:
      return {
        ...state,
        changeDetected: false,
        hasPreviewed: false,
      };

    case TOGGLE_BRANDING_PUBLISH_MODAL:
      return {
        ...state,
        showPublishModal: !state.showPublishModal,
      };

    default:
      return state;
  }
};

export default returnsBrandingReducer;
