export const EMPTY_MARKETING_GRID_ASSET = {
  alt_text: '',
  click_category: 'Marketing',
  desktop_src: '',
  desktop_src_2x: '',
  mobile_src: '',
  mobile_src_2x: '',
  target_url: '',
  link_title: '',
  tracking_id: '',
  tracking_id_mobile: '',
};

export const EMPTY_MARKETING_GRID_HEADER_COPY = {
  header: '',
  headerHref: '',
  tracking_id: '',
  tracking_id_mobile: '',
  subHeader: '',
  subHeaderHref: '',
  sub_tracking_id: '',
  sub_tracking_id_mobile: '',
};

export const EMPTY_MARKETING_GRID_HEADER_STYLES = {
  headerColor: '',
  headerFont: '',
  headerFontSize: '',
  headerTextAlign: '',
  headerHover: '',
  subHeaderColor: '',
  subHeaderFont: '',
  subHeaderFontSize: '',
  subHeaderTextAlign: '',
  subHeaderHover: '',
};

export const TRACKING_IMG_PANELS = [
  'trackingImg',
  'trackingImgAsset',
  'trackingImgFont',
];

export const TRACKING_PANELS = [
  'branding',
  'gridSettings',
  'header',
  'logo',
  'trackingFont',
  'trackingAsset',
  'header',
  'marketing_grid_',
  'marketing_panel',
  'tracking_chat',
];

export const CONDITION_PLACEHOLDER = {
  locale: 'e.g. en_US',
  carrier: 'e.g. ups',
  type: 'e.g. b',
  category: 'e.g. women',
};

export const CONFIGURATIONS_CONDITION_READABLE_MAP = {
  b: 'BOSS (b)',
  p: 'Pre-Shipment (p)',
  ret: 'Narvar Returns (ret)',
  r: 'Narvar Returns (r)',
  mp: 'Walmart Marketplace (mp)',
  '': 'None',
};
