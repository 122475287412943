import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormControl } from '../FormControl';
import s from './TimePicker.styles';
import timezoneList, { tzListSorted } from './timezoneList';

export class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      tzList: props.tzSettings?.sort ? tzListSorted() : timezoneList,
    };
  }

  componentDidMount() {
    const {
      time,
      timezone,
    } = this.props;

    this.setState({
      time: time || '12:00 AM',
      timezone: timezone || timezoneList[0],
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      time: nextProps.time || '12:00 AM',
      timezone: nextProps.timezone || timezoneList[0],
    });
  }

 handleKeyDown = event => {
   /* Accepted Values
    * Numbers 0 - 9
    * Letters : [AM,PM,am,pm]
    * ASCII Codes: 3 -> ETX, 8 -> backspace, 186 -> colon, 32 -> space, 37 -> left arrow, 39 -> right arrow
   */
   const keyValue = event.key.toUpperCase();
   const { keyCode } = event;
   const acceptedKeyCodes = [3, 8, 186, 32, 37, 39];
   const acceptedKeyValues = ['A', 'P', 'M'];
   if (
     !((keyCode >= 48 && keyCode <= 58)
     || acceptedKeyCodes.includes(keyCode)
     || acceptedKeyValues.includes(keyValue))
   ) {
     event.preventDefault();
   }
 }

  handleBlur = event => {
    const { timezone } = this.state;
    const { onChange } = this.props;
    let updatedTime = event.currentTarget.value;
    let updatedHours = '12';
    let updatedMins = '00';
    let updatedPeriod = 'AM';
    const validationError = this.validateTimeInput(updatedTime);
    // Formatting Input Field for time format hh:mm am/pm
    if (!validationError) {
      updatedHours = updatedTime.split(':')[0];
      updatedMins = updatedTime.split(':')[1].split(' ')[0];
      updatedPeriod = updatedTime.split(':')[1].split(' ')[1];
      if (updatedHours.length < 2) updatedHours = `0${updatedHours}`;
      if (updatedMins.length < 2) updatedMins = `0${updatedMins}`;
      updatedTime = `${updatedHours}:${updatedMins} ${updatedPeriod}`;
    }
    onChange(updatedTime, timezone);
    this.setState({
      time: updatedTime,
      error: validationError,
    });
  }

  handleSelectTimezone = event => {
    const { time, tzList } = this.state;
    const selectedItem = tzList[event.currentTarget.name];
    this.props.onChange(time, selectedItem);
    this.setState({
      timezone: selectedItem,
    });
  }

  handleChange = event => {
    const inputLength = event.currentTarget.value.length;
    const inputValue = event.currentTarget.value;
    let updatedTime = event.currentTarget.value.toUpperCase();

    if (inputLength === 1 && (inputValue < 10 && inputValue > 1)) {
      updatedTime = `0${inputValue}:`;
    }

    if (inputLength === 2 && (inputValue.indexOf(':') < 0)) {
      updatedTime += ':';
    }

    this.setState({
      time: updatedTime,
      error: '',
    });
  }

  validateTimeInput = time => {
    const timeRegEx = /^(0?[1-9]|1[012])(:([0-5]\d|[0-9])) [AP][M]$/;
    return timeRegEx.test(time) ? '' : 'Please use 12:00 AM format';
  }

  render() {
    const {
      timezone,
      time,
      error,
      tzList,
    } = this.state;
    const {
      timeInputLabel,
      timezoneLabel,
      enableTimeInput,
      enableTimezone,
      customStyles,
      tzSettings = {},
    } = this.props;

    const tzProps = {};
    if (enableTimezone) {
      if (tzSettings.search) {
        tzProps.type = 'searchableSelect';
        tzProps.autoSearch = true;
        tzProps.onSelect = this.handleSelectTimezone;
        tzProps.removeDisabled = true;
      } else {
        tzProps.type = 'select';
        tzProps.onChange = this.handleSelectTimezone;
      }
    }
    return (
      <div className={cx('flex-row', s)}>
        {enableTimeInput &&
          <FormControl
            data={time}
            className="time-input"
            label={timeInputLabel}
            name="time"
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            onBlur={this.handleBlur}
            validationError={error}
            placeholder="12:00 AM"
            type="text"
            value={time}
          />
        }
        {enableTimezone &&
          <FormControl
            data={tzList}
            className={customStyles}
            label={timezoneLabel}
            name="Time Zone"
            readableKey="timezone"
            selected={timezone}
            {...tzProps}
          />
        }
      </div>
    );
  }
}

TimePicker.propTypes = {
  enableTimeInput: PropTypes.bool,
  enableTimezone: PropTypes.bool,
  timeInputLabel: PropTypes.string,
  timezoneLabel: PropTypes.string,
  customStyles: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tzSettings: PropTypes.shape({
    search: PropTypes.bool,
    sort: PropTypes.bool,
  }),
};

TimePicker.defaultProps = {
  enableTimeInput: true,
  enableTimezone: true,
  timeInputLabel: 'Time',
  timezoneLabel: 'Timezone',
  customStyles: 'timezone-input',
  tzSettings: {
    search: false,
    sort: false,
  },
};

export default TimePicker;
