import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  background-color: ${colors.blue};
  color: ${colors.white};
  font-family: 'circular';
  min-height: 40px;
  left: 0;
  line-height: 20px;
  padding: 10px 50px;
  position: fixed;
  right: 0;
  top: 120px;
  text-align: center;
  transform: translate(0, -50px);
  // Delay on color so when reset to "initialState" the color doesnt switch 
  // back to ${colors.blue} on close
  transition: transform 200ms ease-in, background-color 10ms ease-in 165ms;
  z-index: 1;
  
  &.active {
    transform: translate(0, 0);
  }

  & a {
    color: ${colors.white};
    text-decoration: underline;
  }

  &.info {
    background-color: ${colors.blue};
  }

  &.success {
    background-color: ${colors.green};
  }

  &.danger {
    background-color: ${colors.red};
  }

  & .icon-close {
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 10px;
    transform: scale(0.5);

    &:hover {
      & path {
        fill-opacity: 1;
      }
    }
    
    &:active {
      & path {
        fill-opacity: .8;
      }
    }
        
    & path {
      fill: ${colors.white};
      fill-opacity: ${colors.iconMute};
      transition: fill-opacity 200ms;
    }

  }

@media print {
  .Alert {
    display: none;
  }
}`;

export default s;
