import { push } from 'connected-react-router';
import { isEmail } from 'validator';
import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const RECEIVE_TEMPLATE_LIST = 'EMAIL_TEMPLATES/RECIEVE_TEMPLATE_LIST';
export const RECEIVE_TEMPLATE = 'EMAIL_TEMPLATES/RECEIVE_TEMPLATE';
export const RECEIVE_SUPPORTED_TYPES =
  'EMAIL_TEMPLATES/RECEIVE_SUPPORTED_TYPES';
export const RECEIVE_SUPPORTED_LOCALES =
  'EMAIL_TEMPLATES/RECEIVE_SUPPORTED_LOCALES';
export const SUCCESS = 'EMAIL_TEMPLATES/SUCCESS';
export const ERROR = 'EMAIL_TEMPLATES/ERROR';
export const TEST_EMAIL_SUCCESS = 'EMAIL_TEMPLATES/TEST_EMAIL_SUCCESS';
export const CANNOT_PUBLISH = 'EMAIL_TEMPLATES/CANNOT_PUBLISH';

const formatTemplateForSave = (template, templateDataString) => {
  const formattedTemplate = {
    ...template,
    template_data_string: templateDataString,
    test_data_json: template.formattedTestJson,
    email_type: 'custom',
  };
  delete formattedTemplate.formattedJson;
  delete formattedTemplate.formattedTestJson;
  return formattedTemplate;
};

const errorAlert = message => ({
  type: ERROR,
  payload: { message },
});

const successAlert = message => ({
  type: SUCCESS,
  payload: { message },
});

const formatTemplateForDisplay = (template, dispatch) => {
  const {
    template_data_string: templateDataString,
    test_data_json: testDataJson,
  } = template;
  let formattedJson;
  try {
    formattedJson = JSON.stringify(JSON.parse(templateDataString), null, 2);
  } catch (e) {
    dispatch(
      errorAlert(
        'Error: unable to parse email JSON; there may be something wrong with this email',
      ),
    );
    formattedJson = templateDataString;
  }
  // no try..catch as test_data_json is validated in db:
  const formattedTestJson = testDataJson
    ? JSON.stringify(testDataJson, null, 2)
    : '';
  const formattedTemplate = {
    ...template,
    formattedJson,
    formattedTestJson,
  };
  delete formattedTemplate.template_data_string;
  delete formattedTemplate.test_data_json;
  return formattedTemplate;
};

const InvalidateTemplateAPIs = id => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.EMAIL_TEMPLATES}`,
  });
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.EMAIL_TEMPLATES}/${id}`,
  });
};

const generateSparkPostBody = ({ recipients, html, subject }) => {
  const generateRecipientList = recipientString => {
    const emails = recipientString.split(',');
    return emails.map(email => ({
      address: { email: email.trim(), name: email.split('@')[0].trim() },
    }));
  };

  const recipientList = generateRecipientList(recipients);
  const sendFromEmail = 'support@narvar.com';
  const replyToEmail = 'support@narvar.com';
  const sendFromName = 'Narvar Support';
  return {
    options: {
      open_tracking: true,
      click_tracking: true,
      transactional: true,
    },
    recipients: recipientList,
    content: {
      from: {
        email: sendFromEmail,
        name: sendFromName,
      },
      text: '',
      html,
      subject,
      reply_to: replyToEmail,
    },
  };
};

const handleApiError = (dispatch, err, fallbackMessage) => {
  const { clientMessage, errorMessage } = err;
  const alertMsg = clientMessage || `${fallbackMessage} [${errorMessage}]`;
  dispatch(errorAlert(alertMsg));
  if (typeof err.clientMessage === 'undefined') {
    throw err; // throw unexpected api errors for logging by bugsnag
  }
};

const validateEmailList = list => {
  const emails = list.split(',');
  return emails.every(email => isEmail(email.trim()));
};

export const fetchTemplateList = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.EMAIL_TEMPLATES_LIST_CUSTOM,
    name: 'fetchEmailTemplateList',
  })
    .then(json => {
      const { templates } = json;
      dispatch({
        type: RECEIVE_TEMPLATE_LIST,
        payload: { templates },
      });
    })
    .catch(err => {
      dispatch(errorAlert('Error: unable to fetch email templates'));
      throw err; // throw unexpected api errors for logging by bugsnag
    });
};

export const fetchTemplate = id => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.EMAIL_TEMPLATES}/${id}`,
    name: 'fetchEmailTemplate',
  })
    .then(json => {
      const { template } = json;
      const displayableTemplate = formatTemplateForDisplay(template, dispatch);
      dispatch({
        type: RECEIVE_TEMPLATE,
        payload: { template: displayableTemplate },
      });
    })
    .catch(err => {
      handleApiError(dispatch, err, `Error: unable to fetch email ${id}`);
    });
};

export const fetchSupportedTypes = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.EMAIL_TEMPLATES_SUPPORTED_TYPES,
    name: 'fetchEmailTemplateSupportedTypes',
  })
    .then(json => {
      const { supportedTypes } = json;
      dispatch({
        type: RECEIVE_SUPPORTED_TYPES,
        payload: { supportedTypes },
      });
    })
    .catch(err => {
      dispatch(errorAlert('Error: unable to fetch supported template types'));
      throw err; // throw unexpected api errors for logging by bugsnag
    });
};

export const fetchSupportedLocales = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TRACKING_ASSETS_SAVE,
    name: 'fetchAssets',
  })
    .then(json => {
      if (json.status_code === 200) {
        const parsedRetailerSettings = JSON.parse(json.retailer_settings);
        dispatch({
          type: RECEIVE_SUPPORTED_LOCALES,
          payload: {
            supportedLocales: parsedRetailerSettings.locale.supported_locales,
          },
        });
      } else {
        throw new Error(`Unexpected response code: ${json.status_code}`);
      }
    })
    .catch(err => {
      dispatch(errorAlert('Error: unable to fetch supported locales'));
      throw err; // throw unexpected api errors for logging by bugsnag
    });
};

export const publishTemplate = template => dispatch => {
  let templateDataString;
  try {
    // parsing and then stringifying removes extra whitespace:
    templateDataString = JSON.stringify(JSON.parse(template.formattedJson));
  } catch (err) {
    dispatch(errorAlert('Invalid email JSON; unable to publish'));
    return;
  }
  const body = JSON.stringify({
    template: formatTemplateForSave(template, templateDataString),
  });
  const method = template.id ? 'PUT' : 'POST';

  dispatch({
    type: API_REQUEST,
    method,
    body,
    path: `${ENDPOINTS.EMAIL_TEMPLATES}`,
    name: 'publishEmailTemplate',
  })
    .then(json => {
      const updatedTemplate = formatTemplateForDisplay(json.template);
      const { id: newId } = updatedTemplate;
      if (method === 'POST') {
        // new template was created
        dispatch(push(`/notify/emails/${newId}`));
        // To ensure that apiReducer.isFetching.fetchEmailTemplate gets updated:
        dispatch(fetchTemplate(newId));
      } else {
        // existing template was updated
        dispatch(InvalidateTemplateAPIs(newId));
      }
      dispatch({
        type: RECEIVE_TEMPLATE,
        payload: { template: updatedTemplate },
      });
      dispatch({
        type: SUCCESS,
        payload: { message: 'Email published successfully' },
      });
      dispatch(fetchTemplateList());
    })
    .catch(err => {
      handleApiError(dispatch, err, 'Error publishing template');
    });
};

export const deleteTemplate = id => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.EMAIL_TEMPLATES}/${id}`,
    name: 'deleteEmailTemplate',
  })
    .then(() => {
      dispatch(InvalidateTemplateAPIs(id));
      dispatch(fetchTemplateList());
      dispatch(push('/notify/emails'));
      dispatch(successAlert(`Email ${id} deleted successfully`));
    })
    .catch(err => {
      handleApiError(dispatch, err, `Error: error deleting email ${id}`);
    });
};

export const sendTestEmail = (template, recipients) => (dispatch, getState) => {
  const {
    formattedJson,
    formattedTestJson,
    locale: { locale_code: localeCode },
  } = template;
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const body = JSON.stringify({
    template_data_string: formattedJson,
    test_data_string: formattedTestJson,
    retailer_moniker: retailerMoniker,
    locale: localeCode,
  });
  if (!validateEmailList(recipients)) {
    dispatch(errorAlert('Error: invalid recipient address(es)'));
    return;
  }
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    body,
    path: ENDPOINTS.TEMPLATE_PROC_GENERATE_FROM_JSON,
    name: 'templateProcGenerateFromJson',
  })
    .then(json => {
      const { subject, body: html } = json;
      const sparkPostBody = generateSparkPostBody({
        html,
        subject,
        recipients,
      });
      dispatch({
        type: API_REQUEST,
        method: 'POST',
        body: JSON.stringify(sparkPostBody),
        path: ENDPOINTS.SEND_EMAIL,
        name: 'sendEmail',
      })
        .then(() => {
          dispatch({
            type: TEST_EMAIL_SUCCESS,
            payload: {
              message: `Test email sent to ${recipients}`,
            },
          });
        })
        .catch(err => {
          const { errors: [{ message: spMessage } = {}] = [] } = err;
          const message = spMessage || 'Unknown sparkpost error';
          dispatch(errorAlert(`Error: ${message}`));
          if (typeof spMessage === 'undefined') {
            throw err; // throw unexpected api errors for logging by bugsnag
          }
        });
    })
    .catch(err => {
      handleApiError(
        dispatch,
        err,
        'Error: Unable to generate email HTML for test email',
      );
    });
};

export const cannotPublish = () => dispatch => {
  dispatch({
    type: CANNOT_PUBLISH,
  });
};
