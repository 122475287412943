import moment from 'moment';
import { push } from 'connected-react-router';
import {
  EDD_PREVIEW,
  EDD_PREVIEW_DETAILS,
  EDD_PREVIEW_DEFINITION,
} from '../../constants/endpoints';

import { API_REQUEST } from '../../lib/api';
import { buildQueryString } from '../../lib/helpers';

export const RECEIVE_EDD_PREVIEW_LIST = 'RECEIVE_EDD_PREVIEW_LIST';
export const RECEIVE_EDD_PREVIEW_DEFINITION = 'RECEIVE_EDD_PREVIEW_DEFINITION';
export const RECEIVE_EDD_PREVIEW_DETAILS = 'RECEIVE_EDD_PREVIEW_DETAILS';
export const EDD_PREVIEW_TOGGLE_PREVIEW_TYPE =
  'EDD_PREVIEW_TOGGLE_PREVIEW_TYPE';
export const EDD_PREVIEW_RECEIVE_ERROR = 'EDD_PREVIEW_RECEIVE_ERROR';
export const EDD_PREVIEW_DISMISS_ERROR = 'EDD_PREVIEW_DISMISS_ERROR';
export const EDD_PREVIEW_UPDATE_FORM = 'EDD_PREVIEW_UPDATE_FORM';

export const LIST_VIEW_METADATA_TO_TAG_LABEL_MAP = {
  orderDate: 'Order Date',
  shipDate: 'Ship Date',
  timeString: 'Order Time',
  originZip: 'Origin Zip',
  destinationZip: 'Destination Zip',
  productCategory: 'Category',
  distributionCenter: 'DC',
  originCountry: 'Origin Country',
  destinationCountry: 'Destination Country',
  retailerServiceCode: 'Service Method',
};

export const DETAILS_VIEW_METADATA_TO_TAG_LABEL_MAP = {
  ...LIST_VIEW_METADATA_TO_TAG_LABEL_MAP,
  carrierCode: 'Carrier',
  shipMethod: 'Service Method',
  startDate: 'EDD Start Date',
  endDate: 'EDD End Date',
};

export const DETAILS_VIEW_RIGHT_SECTION_META_MAP = [
  'carrierCode',
  'shipMethod',
  'startDate',
  'endDate',
];

const isInvalidMetaTag = (data, key, labelMapToCheck) =>
  !data ||
  typeof labelMapToCheck[key] === 'undefined' ||
  labelMapToCheck[key] === null;

export const generateMetaTags = (metadata = {}, tagLabelMap, isOrderView) => {
  const { time, timezone } = metadata;
  const timeString = `${time} ${timezone}`;

  return Object.keys(metadata).reduce((allTags, tagLabelKey) => {
    const metadataEntry = metadata[tagLabelKey];
    const timeStringTagLabel = tagLabelMap.timeString;

    if (tagLabelKey === 'date') {
      const previewType = isOrderView
        ? tagLabelMap.orderDate
        : tagLabelMap.shipDate;

      return [...allTags, `${previewType}: ${metadataEntry}`];
    }

    if ((tagLabelKey === 'time' || tagLabelKey === 'timezone') && isOrderView) {
      if (allTags.find(tag => tag.indexOf(timeStringTagLabel) > -1)) {
        return allTags;
      }

      return [...allTags, `${timeStringTagLabel}: ${timeString}`];
    }

    if (isInvalidMetaTag(metadataEntry, tagLabelKey, tagLabelMap)) {
      return allTags;
    }

    return [...allTags, `${tagLabelMap[tagLabelKey]}: ${metadataEntry}`];
  }, []);
};

export const getDetailsViewRightSection = (metadata = {}) =>
  DETAILS_VIEW_RIGHT_SECTION_META_MAP.reduce((rightSection, metaKey) => {
    const rightSectionData = metadata[metaKey];

    if (!rightSectionData) return rightSection;

    return [
      ...rightSection,
      {
        label: DETAILS_VIEW_METADATA_TO_TAG_LABEL_MAP[metaKey],
        text: rightSectionData,
      },
    ];
  }, []);

export const toggleEddPreviewType = isOrderView => ({
  type: EDD_PREVIEW_TOGGLE_PREVIEW_TYPE,
  payload: {
    isOrderView,
  },
});

export const dismissEddPreviewError = () => ({
  type: EDD_PREVIEW_DISMISS_ERROR,
});

const ORDER_DATE_VIEW_REQUIRED_FIELDS = ['date', 'time', 'timezone'];

const SHIP_DATE_VIEW_REQUIRED_FIELDS = ['date', 'carrierCode'];

const requiredFieldsComplete = (isOrderView, form) => {
  const required = isOrderView
    ? ORDER_DATE_VIEW_REQUIRED_FIELDS
    : SHIP_DATE_VIEW_REQUIRED_FIELDS;

  return required.reduce((isValid, requiredField) => {
    if (!form[requiredField] || !isValid) return false;

    return true;
  }, true);
};

export const fetchEddPreviewList = () => async (dispatch, getState) => {
  try {
    await dispatch(dismissEddPreviewError());

    const {
      eddPreview: { isOrderView, eddPreviewForm },
    } = getState();

    if (!requiredFieldsComplete(isOrderView, eddPreviewForm)) {
      throw new Error('Please complete all required fields.');
    }

    const queryString = buildQueryString({
      ...eddPreviewForm,
      type: isOrderView ? 'order' : 'ship',
    });

    const {
      result: { content, meta: eddPreviewListMeta },
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${EDD_PREVIEW}${queryString}`,
      name: 'fetchEddPreviewList',
    });

    await dispatch({
      type: RECEIVE_EDD_PREVIEW_LIST,
      payload: {
        content,
        eddPreviewListMeta,
      },
    });

    dispatch(push('/platform/edd-preview/list'));
  } catch (err) {
    const errorMessage = Array.isArray(err)
      ? err.find(error => error)
      : err.message;

    console.error(errorMessage); // eslint-disable-line no-console

    dispatch({
      type: EDD_PREVIEW_RECEIVE_ERROR,
      payload: {
        errorMessage: errorMessage || 'Failed to retrieve EDD Preview List',
      },
    });
  }
};

export const fetchEddPreviewDetails = listIndex => async (
  dispatch,
  getState,
) => {
  try {
    await dispatch(dismissEddPreviewError());

    const {
      eddPreview: { eddPreviewList, eddPreviewListMeta } = {},
    } = getState();

    const filteredEddPreviewListMeta = Object.entries(
      eddPreviewListMeta,
    ).reduce(
      (acc, [key, value]) => (value ? { ...acc, [key]: value } : acc),
      {},
    );

    const queryString = buildQueryString({
      ...eddPreviewList[listIndex],
      ...filteredEddPreviewListMeta,
    });

    const {
      result: {
        content,
        meta,
        meta: { startDate = null, endDate = null },
      } = {},
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${EDD_PREVIEW_DETAILS}${queryString}`,
      name: 'fetchEddPreviewDetails',
    });

    const eddPreviewDetailsMeta = { ...meta };

    if (startDate)
      eddPreviewDetailsMeta.startDate = moment(startDate).format(
        'MMMM Do, YYYY',
      );
    if (endDate)
      eddPreviewDetailsMeta.endDate = moment(endDate).format('MMMM Do, YYYY');

    await dispatch({
      type: RECEIVE_EDD_PREVIEW_DETAILS,
      payload: {
        content,
        eddPreviewDetailsMeta,
      },
    });

    dispatch(push('/platform/edd-preview/details'));
  } catch (err) {
    const errorMessage = Array.isArray(err)
      ? err.find(error => error)
      : 'Failed to retrieve EDD Details';

    console.error(errorMessage); // eslint-disable-line no-console

    dispatch({
      type: EDD_PREVIEW_RECEIVE_ERROR,
      payload: {
        errorMessage,
      },
    });
  }
};

const mapShipMethods = (shipMethods = []) =>
  shipMethods.reduce(
    ({ carriers, methods }, method) => {
      const {
        carrier_moniker: carrier,
        code: methodCode,
        display_value: methodName,
      } = method;

      const carrierMethods = methods[carrier] || [];

      return {
        carriers: !carriers.includes(carrier)
          ? [...carriers, carrier]
          : carriers,
        methods: {
          ...methods,
          [carrier]: [...carrierMethods, { carrier, methodCode, methodName }],
        },
      };
    },
    {
      carriers: [],
      methods: {},
    },
  );

export const fetchEddPreviewSelect = () => async dispatch => {
  try {
    const {
      result: { productCategories, distributionCenters, shipMethods },
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: EDD_PREVIEW_DEFINITION,
    });

    const { carriers, methods } = mapShipMethods(shipMethods);

    dispatch({
      type: RECEIVE_EDD_PREVIEW_DEFINITION,
      payload: {
        distributionCenters,
        carriers: carriers.map(carrier => ({ display_value: carrier })),
        productCategories: productCategories.map(category => ({
          display_value: category,
        })),
        serviceCodes: methods,
      },
    });
  } catch (err) {
    console.error(err);
  }
};

export const updateEddPreviewForm = (formKey, formValue) => ({
  type: EDD_PREVIEW_UPDATE_FORM,
  payload: {
    formKey,
    formValue,
  },
});
