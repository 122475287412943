import { colors, fonts } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  border-bottom: 2px solid ${colors.gray};
  border-radius: 4px 4px 0 0;
  color: ${colors.dark1};
  cursor: default;
  font-family: ${fonts.bold};
  width: 100%;

  & .table-row {
    & .table-heading {
      margin: 0 auto;
      overflow: hidden;

    & .label-icon-container {
        padding: 0px 10px 0px 10px;
        height: 100%;
        width: 100%;
      }
    }

    & th {
      font-weight: normal;

      & .label {
        padding: 10px 0 10px 0;
        min-height: 40px;
        align-items: center;
        display: flex;
        text-overflow: ellipsis;
      }

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }

    }

  }

  &.sortable {
    & tr, th {
      height: 100%;
    }

    & .table-row {
      & .sort-icon {
        margin: 5px 10px 0px auto;
      }

      & .label {
        width: 70%;
      }

      & .table-heading {
        cursor: pointer;
        position: relative;
        overflow: visible;

        & .label-icon-container {
          &:hover {
            background: ${colors.grayLightest};

            & .sort-icon-hover {
              opacity: 1;
            }
          }
        }

        &.selected-sort {
          border-bottom: 2px solid ${colors.blue};
        }
      }

      & th:last-child.selected-sort,
      & th:first-child.selected-sort {
        border-bottom: 2px solid ${colors.gray};
        position: relative;
      }

      & th:last-child.selected-sort:after,
      & th:first-child.selected-sort:after {
        content: "";
        display: block;
        position: relative;
        margin: 0 auto;
        border-bottom: 2px solid ${colors.blue};
        bottom: -1px;
      }

      & .sort-icon-hover {
        opacity: 0;
        margin: 5px 10px 0px auto;
        transition: opacity 200ms;
      }

      & .label-icon-container {
        &:hover {
          background: ${colors.grayLightest};
        }
      }
    }
  }

  &.sortable-header {

    & tr, th {
       height: 100%;
    }

    & .row {
      & .sort-icon {
        margin: 5px 10px 0px auto;
      }

      & .item {
        position: relative;
        overflow: visible;
        &.selected-sort {
          border-bottom: 2px solid ${colors.blue};
        }
      }


      & th:last-child.selected-sort,
      & th:first-child.selected-sort {
        border-bottom: 2px solid ${colors.gray};
        position: relative;
      }

      & th:last-child.selected-sort:after,
      & th:first-child.selected-sort:after {
        content: "";
        display: block;
        position: relative;
        margin: 0 auto;
        border-bottom: 2px solid ${colors.blue};
        bottom: -1px;
      }

      & .sort-icon-hover {
        opacity: 0;
        margin: 5px 10px 0px auto;
        transition: opacity 200ms;
      }

      & .label-icon-container {
        &:hover {
          background: ${colors.grayLightest};
        }
      }
    }
  }

& .table-heading {
  margin: 0 auto;
  overflow: hidden;

  & .label-icon-container {
    padding: 0px 10px 0px 10px;
    height: 100%;
    width: 100%;

    &:hover {
      .sort-icon-hover {
        opacity: 1;
      }
    }
  }
}
`;

export default s;
