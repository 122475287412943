import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import {
  downloadPortableConfig,
  uploadPortableConfig,
  confirmUploadPortableConfig,
  isManifestHasValidModules,
} from '~src/actions/portableConfigActions';

export const RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE =
  'PACKING_SLIP_LABEL/RECEIVE_UPLOAD_RESPONSE';
export const RESET_UPLOADED_PORTABLE_CONFIG =
  'PACKING_SLIP_LABEL/RESET_UPLOADED_CONFIG';
export const RECEIVE_PACKING_SLIP_AND_LABEL_LIST =
  'RECEIVE_PACKING_SLIP_AND_LABEL_LIST';
export const RECEIVE_PACKING_SLIP_LABEL_ERROR =
  'RECEIVE_PACKING_SLIP_LABEL_ERROR';
export const RECEIVE_PACKING_SLIP_LABEL_SUCCESS =
  'RECEIVE_PACKING_SLIP_LABEL_SUCCESS';
export const RECEIVE_PACKING_SLIP_LABEL_UPDATE =
  'RECEIVE_PACKING_SLIP_LABEL_UPDATE';
export const RECEIVE_PACKING_SLIP_LABEL_SAVE =
  'RECEIVE_PACKING_SLIP_LABEL_SAVE';
export const RECEIVE_PACKING_SLIP_LABEL_PREVIEW =
  'RECEIVE_PACKING_SLIP_LABEL_PREVIEW';
export const RECEIVE_PACKING_SLIP_LABEL_PUBLISH =
  'RECEIVE_PACKING_SLIP_LABEL_PUBLISH';
export const RECEIVE_PACKING_SLIP_LABEL_DETAILS =
  'RECEIVE_PACKING_SLIP_LABEL_DETAILS';
export const RECEIVE_PACKING_SLIP_LABEL_CONFIGS =
  'RECEIVE_PACKING_SLIP_LABEL_CONFIGS';
export const RECEIVE_SAVE_ERRORS = 'RECEIVE_SAVE_ERRORS';
export const RESET_FORM_VALUES_AND_ERRORS = 'RESET_FORM_VALUES_AND_ERRORS';

export const receivePackingSlipAndLabelError = messages => ({
  type: RECEIVE_PACKING_SLIP_LABEL_ERROR,
  messages,
});

export const receivePackingSlipAndLabelDeleteSuccess = messages => ({
  type: RECEIVE_PACKING_SLIP_LABEL_SUCCESS,
  messages,
});

export const resetFormAndErrors = () => ({
  type: RESET_FORM_VALUES_AND_ERRORS,
});

export const fetchPackingSlipAndLabelDetails = (
  id,
  params,
  updateConfig = true,
) => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/${id}${params}`,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/${id}${params}`,
    name: 'fetchPackingSlipAndLabelDetails',
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKING_SLIP_LABEL_DETAILS,
        payload: { ...json.response, updateConfig },
      });
    })
    .catch(err => {
      dispatch(
        receivePackingSlipAndLabelError(
          `An error occurred while fetching the details - ${err}`,
        ),
      );
    });
};

export const fetchPackingSlipAndLabelConfigs = (id, params) => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL_CONFIGS}${params}`,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL_CONFIGS}${params}`,
    name: 'fetchPackingSlipAndLabelConfigs',
  })
    .then(json => {
      if (json.status_code === 200) {
        dispatch({
          type: RECEIVE_PACKING_SLIP_LABEL_CONFIGS,
          payload: json.response,
        });
        if (id) {
          dispatch(fetchPackingSlipAndLabelDetails(id, params));
        }
      } else {
        dispatch(
          receivePackingSlipAndLabelError(
            'An error occurred while fetching return configs',
          ),
        );
      }
    })
    .catch(err => {
      dispatch(
        receivePackingSlipAndLabelError(
          `An error occurred while fetching return configs - ${err}`,
        ),
      );
    });
};

export const fetchPackingSlipAndLabelList = () => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/list`,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/list`,
    name: 'fetchPackingSlipAndLabelList',
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKING_SLIP_AND_LABEL_LIST,
        payload: json.response,
      });
    })
    .catch(err => console.error(err));
};

export const fetchSavePackingSlipAndLabel = packingSlipLabelConfig => dispatch => {
  const body = JSON.stringify(packingSlipLabelConfig);
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    body,
    path: ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL,
    name: 'fetchSavePackingSlipAndLabel',
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKING_SLIP_LABEL_SAVE,
        payload: json.response,
      });
    })
    .catch(err => {
      dispatch(
        receivePackingSlipAndLabelError(
          `An error occurred while saving - ${err.errors[0].message}`,
        ),
      );
    });
};

export const fetchUpdatePackingSlipAndLabel = (
  id,
  packingSlipLabelConfig,
) => dispatch => {
  const body = JSON.stringify(packingSlipLabelConfig);
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    body,
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}`,
    name: 'fetchUpdatePackingSlipAndLabel',
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKING_SLIP_LABEL_UPDATE,
        payload: json.response,
      });
    })
    .catch(err => {
      dispatch(
        receivePackingSlipAndLabelError(
          `An error occurred while updating - ${err.errors[0].message}`,
        ),
      );
    });
};

export const fetchPreviewPackingSlipAndLabel = packingSlipLabelConfig => async dispatch => {
  try {
    const body = JSON.stringify(packingSlipLabelConfig);
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body,
      path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/preview`,
      name: 'fetchPreviewPackingSlipAndLabel',
    });
    await dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_PREVIEW,
      payload: json.preview_url,
    });
  } catch (err) {
    dispatch(
      receivePackingSlipAndLabelError(
        `An error occurred while fetching the preview - ${err.errors[0].message}`,
      ),
    );
  }
};

export const fetchPublishPackingSlipAndLabel = packingSlipLabelConfig => dispatch => {
  const body = JSON.stringify(packingSlipLabelConfig);
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    body,
    path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}/publish`,
    name: 'fetchPublishPackingSlipAndLabel',
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKING_SLIP_LABEL_SUCCESS,
        messages: json.response,
      });
    })
    .catch(err => {
      dispatch(
        receivePackingSlipAndLabelError(
          `An error occurred while publishing template - ${err.errors[0].message}`,
        ),
      );
    });
};

export const fetchDeletePackingSlipAndLabel = (
  id,
  templateFields,
) => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}`,
    });
    const body = JSON.stringify({
      id,
      template_config: {
        ...templateFields,
      },
    });
    await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      body,
      path: `${ENDPOINTS.RETURNS_PACKING_SLIP_AND_LABEL}`,
      name: 'fetchDeletePackingSlipAndLabel',
    });
  } catch (err) {
    dispatch(
      receivePackingSlipAndLabelError(
        `An error occurred while trying deleting template - ${err.errors[0].message}`,
      ),
    );
  }
};

export const downloadPackingSlipAndLabelPortableConfig = () => async dispatch => {
  try {
    await dispatch(
      downloadPortableConfig({
        appName: 'returns',
        module: { packing_slip: true, shipping_label: true },
      }),
    );
    dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_SUCCESS,
      messages: 'Successfully Download',
    });
  } catch (error) {
    dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_ERROR,
      messages: 'Download Fail',
    });
  }
};

export const uploadPackingSlipAndLabelPortableConfig = ({
  selectedFile,
}) => async dispatch => {
  try {
    const {
      data: manifest,
      data: { module },
    } = await dispatch(
      uploadPortableConfig({
        selectedFile,
      }),
    );

    const isUploadedConfigValid = isManifestHasValidModules({
      manifestModule: module,
      validModules: ['packing_slip', 'shipping_label'],
    });

    if (isUploadedConfigValid) {
      dispatch({
        type: RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
        payload: { manifest },
      });
    } else {
      dispatch({
        type: RECEIVE_PACKING_SLIP_LABEL_ERROR,
        messages:
          'Please only upload Packing Slip and Shipping Label Configuration on this page',
      });
    }
  } catch (error) {
    dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_ERROR,
      messages: 'Upload Fail',
    });
  }
};

export const resetUploadedPortableConfig = () => dispatch => {
  dispatch({
    type: RESET_UPLOADED_PORTABLE_CONFIG,
  });
};

export const confirmUploadPackingSlipAndLabelPortableConfig = ({
  manifest,
}) => async dispatch => {
  try {
    await dispatch(
      confirmUploadPortableConfig({
        manifest: {
          ...manifest,
          module: {
            packing_slip: true,
            shipping_label: true,
          },
        },
      }),
    );
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_SUCCESS,
      messages: 'Successfully Uploaded',
    });
    dispatch(fetchPackingSlipAndLabelList());
  } catch (error) {
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch({
      type: RECEIVE_PACKING_SLIP_LABEL_ERROR,
      messages: 'Upload Fail',
    });
  }
};
