import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
import ja from './ja';

export default {
  en,
  es,
  fr,
  it,
  ja,
};

export const i18nDateFormat = {
  en: ['ll', 'LT'],
  es: ['lll'],
  fr: ['lll'],
  it: ['lll'],
  ja: ['L', 'LT'],
};
