import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import { EDD_CACHE_TYPE } from '../constants/copy/shipEddRules';

export const RECEIVE_SERVICE_CODES_LIST = 'RECEIVE_SERVICE_CODES_LIST';
export const RECEIVE_SERVICE_CODES_ERROR = 'RECEIVE_SERVICE_CODES_ERROR';
export const RECEIVE_SERVICE_CODES_UPDATE = 'RECEIVE_SERVICE_CODES_UPDATE';
export const RECEIVE_SERVICE_CODES_SAVE = 'RECEIVE_SERVICE_CODES_SAVE';
export const RECEIVE_CARRIER_SERVICES = 'RECEIVE_CARRIER_SERVICES';
export const RECEIVE_SERVICE_CODE_DETAILS = 'RECEIVE_SERVICE_CODE_DETAILS';
export const RECEIVE_TNT_DAYS = 'RECEIVE_TNT_DAYS';
export const RECEIVE_SAVE_ERRORS = 'RECEIVE_SAVE_ERRORS';
export const CLEAR_FORM_VALUES_AND_ERRORS = 'CLEAR_FORM_VALUES_AND_ERRORS';

const receiveServiceCodesError = err => {
  console.error(err); // eslint-disable-line no-console
  const { errors: [{ message = 'Error' } = {}] = [] } = err || {};
  return {
    type: RECEIVE_SERVICE_CODES_ERROR,
    messages: message,
  };
};

export const resetFormAndErrors = () => ({
  type: CLEAR_FORM_VALUES_AND_ERRORS,
});

export const receiveSaveErrors = () => ({
  type: RECEIVE_SAVE_ERRORS,
});

export const IS_FETCHING_CARRIER_SERVICES = 'fetchCarrierServices'; // Used to check isFetching from the apiReducer
export const fetchCarrierServices = () => async dispatch => {
  await dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.SERVICE_CODES_CARRIER_SERVICES,
  });
  try {
    const { response } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.SERVICE_CODES_CARRIER_SERVICES,
      name: IS_FETCHING_CARRIER_SERVICES,
    });
    dispatch({
      type: RECEIVE_CARRIER_SERVICES,
      payload: {
        carrierServices: response.carrier_services,
      },
    });
  } catch (err) {
    dispatch(receiveServiceCodesError(err));
  }
};

export const IS_FETCHING_SERVICE_CODES = 'fetchServiceCodes'; // Used to check isFetching from the apiReducer
export const fetchServiceCodesList = ({
  activePage = 1,
  recordsPerPage = 20,
} = {}) => async dispatch => {
  await dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.SERVICE_CODES,
  });

  let serviceCodesList = {
    content: [],
    total_pages: 0,
    total_records: 0,
    current_page_index: 0,
    records_per_page: 0,
  };
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.SERVICE_CODES,
      name: IS_FETCHING_SERVICE_CODES,
      query: {
        current_page_index:
          Number.isNaN(activePage) || activePage < 1 ? 0 : activePage - 1,
        records_per_page: recordsPerPage,
      },
    });
    serviceCodesList = json;
  } catch (err) {
    if (err.status_code !== 400) {
      dispatch(receiveServiceCodesError(err));
      return;
    }
  }

  dispatch({
    type: RECEIVE_SERVICE_CODES_LIST,
    payload: { serviceCodesList },
  });
};

export const IS_FETCHING_SERVICE_CODE_DETAILS = 'fetchServiceCodeDetails';
export const fetchServiceCodeDetails = id => async dispatch => {
  try {
    const { response } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.SERVICE_CODES}/${id}`,
      name: IS_FETCHING_SERVICE_CODE_DETAILS,
    });
    dispatch({
      type: RECEIVE_SERVICE_CODE_DETAILS,
      payload: {
        serviceCodeDetails: response,
      },
    });
  } catch (err) {
    dispatch(receiveServiceCodesError(err));
  }
};

export const IS_FETCHING_TIME_IN_TRANSIT_DAYS = 'fetchTimeInTransitDays';
export const fetchTimeInTransitDays = (
  carrierId,
  carrierServiceId,
  originCountry,
  destCountry,
) => async dispatch => {
  let timeInTransit = {
    tnt_min_days: '',
    tnt_max_days: '',
  };

  try {
    const { response } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.SERVICE_CODES}/tnt`,
      name: IS_FETCHING_TIME_IN_TRANSIT_DAYS,
      query: {
        carrier_id: carrierId,
        carrier_service_id: carrierServiceId,
        origin_country_code: originCountry,
        dest_country_code: destCountry,
      },
    });

    timeInTransit = response;
  } catch (err) {
    if (err.status_code !== 400) {
      dispatch(receiveServiceCodesError(err));
      return;
    }
  }

  dispatch({
    type: RECEIVE_TNT_DAYS,
    payload: {
      timeInTransit,
    },
  });
};

export const refreshEddCache = () => async dispatch => {
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    body: JSON.stringify({ cacheType: EDD_CACHE_TYPE.TRACK }),
    path: ENDPOINTS.REFRESH_EDD_CACHE,
    name: 'refreshEDDApiCache',
  });
};

export const IS_FETCHING_SAVE_SERVICE_CODE = 'fetchSaveServiceCode';
export const fetchSaveServiceCode = serviceCode => async dispatch => {
  try {
    const { response } = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(serviceCode),
      path: ENDPOINTS.SERVICE_CODES,
      name: IS_FETCHING_SAVE_SERVICE_CODE,
    });

    await dispatch(refreshEddCache());
    dispatch({
      type: RECEIVE_SERVICE_CODES_SAVE,
      payload: {
        serviceCodeDetails: response,
      },
    });
  } catch (err) {
    dispatch(receiveServiceCodesError(err));
  }
};

export const IS_FETCHING_UPDATE_SERVICE_CODES = 'fetchUpdateServiceCode';
export const fetchUpdateServiceCode = (id, serviceCode) => async dispatch => {
  try {
    const { response } = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      body: JSON.stringify(serviceCode),
      path: `${ENDPOINTS.SERVICE_CODES}/${id}`,
      name: IS_FETCHING_UPDATE_SERVICE_CODES,
    });

    await dispatch(refreshEddCache());
    dispatch({
      type: RECEIVE_SERVICE_CODES_UPDATE,
      payload: {
        serviceCodeDetails: response,
      },
    });
  } catch (err) {
    dispatch(receiveServiceCodesError(err));
  }
};
