import { API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const RECEIVE_METABASE_URL = 'RECEIVE_METABASE_URL';
export const RECEIVE_SHIP_METABASE_URL = 'RECEIVE_SHIP_METABASE_URL';

export const receiveMetabaseUrl = (url, dashboardType) => ({
  type: RECEIVE_METABASE_URL,
  url,
  dashboardType,
});

export const receiveShipMetabaseUrl = url => ({
  type: RECEIVE_SHIP_METABASE_URL,
  url,
});

export const fetchMetabaseURL = type => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.METABASE}/${type}`,
    name: 'fetchMetabaseURL',
  })
    .then(({ text }) => dispatch(receiveMetabaseUrl(text, type)))
    .catch(err => console.error(err));
};

export const fetchShipMetabaseURL = retailerMoniker => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${ENDPOINTS.SHIP_DASHBOARD}`,
    name: 'fetchShipMetabaseURL',
    body: JSON.stringify({
      retailer_moniker: retailerMoniker,
    }),
  })
    .then(({ text }) => dispatch(receiveShipMetabaseUrl(text)))
    .catch(err => console.error(err));
};
