import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  RECEIVE_ORDER_DASHBOARD,
  RECEIVE_SHIPMENT_DASHBOARD,
  RECEIVE_CHART_ORDER_DATA,
  RECEIVE_CHART_SHIPMENT_DATA,
  RECEIVE_ERROR,
  DISMISS_ALERT,
  UPDATE_DATE_RANGE,
} from '../actions/promiseDataQualityActions';

const initialState = {
  startDate: moment()
    .subtract(30, 'days')
    .format(API_DATE_FORMAT),
  endDate: moment()
    .subtract(1, 'days')
    .format(API_DATE_FORMAT),
  orderDataQuality: {
    chartData: {},
    dimensions: [],
    errors: [],
    dashboard: {},
    object: '',
    reports: {},
    reportDefinitions: {},
    reportsPayload: [],
  },
  shipmentDataQuality: {
    chartData: {},
    dimensions: [],
    errors: [],
    dashboard: {},
    object: '',
    reports: {},
    reportDefinitions: {},
    reportsPayload: [],
  },
  alert: {
    show: false,
  },
};

export default (
  state = initialState,
  {
    type,
    meta,
    meta: { chartName } = {},
    payload: {
      startDate,
      endDate,
      orderDataQuality: {
        chartData,
        tableData,
        report_definitions: reportDefinitions,
        report_instances: reportInstances,
        dashboard_definitions: dashboard,
      } = {},
      shipmentDataQuality: {
        chartData: shipmentChartData,
        tableData: shipmentTableData,
        report_definitions: shipmentReportDefinitions,
        report_instances: shipmentReportInstances,
        dashboard_definitions: shipmentDashboard,
      } = {},
    } = {},
  } = {},
) => {
  let object;
  switch (type) {
    case RECEIVE_ORDER_DASHBOARD:
      object = Object.keys(reportDefinitions)[0];
      return {
        ...state,
        orderDataQuality: {
          ...state.orderDataQuality,
          dashboard,
          object,
          reports: {
            ...state.orderDataQuality.reports,
            ...reportInstances,
          },
          reportDefinitions,
        },
      };
    case RECEIVE_SHIPMENT_DASHBOARD:
      object = Object.keys(shipmentReportDefinitions)[0];
      return {
        ...state,
        shipmentDataQuality: {
          ...state.shipmentDataQuality,
          dashboard: shipmentDashboard,
          object,
          reports: {
            ...state.shipmentDataQuality.reports,
            ...shipmentReportInstances,
          },
          reportDefinitions: shipmentReportDefinitions,
        },
      };
    case RECEIVE_CHART_ORDER_DATA:
      return {
        ...state,
        orderDataQuality: {
          ...state.orderDataQuality,
          chartData: {
            ...state.orderDataQuality.chartData,
            [chartName]: chartData,
          },
          tableData: {
            ...state.orderDataQuality.tableData,
            [chartName]: tableData,
          },
        },
      };
    case RECEIVE_CHART_SHIPMENT_DATA:
      return {
        ...state,
        shipmentDataQuality: {
          ...state.shipmentDataQuality,
          chartData: {
            ...state.shipmentDataQuality.chartData,
            [chartName]: shipmentChartData,
          },
          tableData: {
            ...state.shipmentDataQuality.tableData,
            [chartName]: shipmentTableData,
          },
        },
      };
    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occured.'
              : `${meta.error}.`,
        },
      };
    case UPDATE_DATE_RANGE:
      return {
        ...state,
        startDate,
        endDate,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };
    default:
      return state;
  }
};
