import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  height: 100%;
  width: 100%;
  
  & + button {
    margin-left: 10px;
  }
  & .Dropdown {
    height: 100%;
    width: 100%;

    & button + button {
      margin: 0;
    }

    & .show {
      & .dropdown-menu {
        display: flex;
        flex-direction: column;
      }
    }

    & .btn {
      border-radius: 2px;
      height: 100%;
      min-height: 40px;
      min-width: 100px;
      text-align: left;
      text-transform: none;
      width: 100%;
      font-family: ${fonts.bold};
      
      &.btn-primary {
        background-color: ${colors.white};
        border: 1px solid ${colors.blue};
        color: ${colors.blue};
        
        &:hover {
          color: ${colors.darkBlue};
          border-color: ${colors.darkBlue};
        }
        
        &.active {
          background-color: ${colors.white};
        }
      }
      
      &.btn-secondary {
        background-color: ${colors.white};
        border-color: transparent;
        border-bottom-color: ${colors.gray};
        color: ${colors.red};
        
        &:hover {
          color: ${colors.darkBlue};
          border-color: ${colors.darkBlue};
        }
        
        &.active {
          background-color: ${colors.white};
        }
      }
      
    }

    & .dropdown-toggle span {
      flex: 1;
    }

    & .btn:disabled {
      @extend .btn;
      opacity: 1;
    }

    & .dropdown-toggle {
      
      &:after {
        color: ${colors.blue};
        margin: auto;
        margin-right: 0;
        margin-left: 10px;
      }
      
      &:hover:after {
        color: ${colors.darkBlue};
      }
      
      &:active:after,
      &:focus:after {
        opacity: .8;
      }
      
      &:disabled:after {
        opacity: .2;
      }
      
    }

    & .dropdown-menu {
      border: none;
      min-height: 60px;
      max-width: 400px;
      min-width: 200px;
      padding: 10px 0;
      width: 100%;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
    }

    & .dropdown-item {
      border-left: 2px solid transparent;
      font-family: ${fonts.regular};
      line-height: 40px;
      padding-left: 18px;
      padding-right: 20px;
      padding-top: 0;
      transition: background-color .1s;
      margin-bottom: 0;
      color: ${colors.dark2};

      &:hover {
        background-color: ${colors.grayLight};
        color: ${colors.dark1};
      }
      
      &:focus:active {
        background-color: ${colors.blue};
        color: ${colors.white};
      }

      &:focus {
        background-color: ${colors.white};
      }

      &.color-red{
        color: ${colors.red};
      }

      & > span {
        display: flex;

        & .description {
          margin: auto 10px auto auto;
          color: ${colors.darkDray};
          font-size: 0.875rem;
        }
      }
    }

    & .dropdown-item.color-red {
      color: ${colors.red};

      &:focus,
      &:hover {
        color:red;
      }

      &:active {
        color: ${colors.white};
      }
    }

    & .dropdown-item.active {
      @extend .dropdown-item;
      border-left-color: ${colors.brandPrimary};
      background-color: ${colors.grayLightest};
      color: ${colors.black};
      font-family: ${fonts.bold};
    }

    & .dropdown-uppercase {
      text-transform: uppercase;
    }

    & .dropdown-divider {
      margin: 0.5rem 20px;
    }
  }
`;

export default s;
