import * as ENDPOINTS from '~src/constants/endpoints';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';

export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ERROR_MODAL = 'DISMISS_ERROR_MODAL';
export const RECEIVE_UPLOAD_COURIER_DETAILS_ASSETS =
  'RECEIVE_UPLOAD_COURIER_DETAILS_ASSETS';
export const RECEIVE_COURIER_DETAILS_CSV_UPLOAD =
  'RECEIVE_COURIER_DETAILS_CSV_UPLOAD';
export const RECEIVE_COURIER_DETAIL_LIST = 'RECEIVE_COURIER_DETAIL_LIST';
export const RECEIVE_COURIER_DETAILS = 'RECEIVE_COURIER_DETAILS';
export const RECEIVE_COURIER_STATUS_CODE_ERROR =
  'RECEIVE_COURIER_STATUS_CODE_ERROR';
export const RECEIVE_COURIER_DETAILS_UPDATE = 'RECEIVE_COURIER_DETAILS_UPDATE';
export const RECEIVE_COURIER_DETAILS_DELETE = 'RECEIVE_COURIER_DETAILS_DELETE';
export const RECEIVE_COURIERS_SELECTOR_LIST = 'RECEIVE_COURIERS_SELECTOR_LIST';
export const RECEIVE_SAVE_COURIER_DETAILS_ERROR =
  'RECEIVE_SAVE_COURIER_DETAILS_ERROR';
export const RECEIVE_SAVE_COURIER_DETAILS_SUCCESS =
  'RECEIVE_SAVE_COURIER_DETAILS_SUCCESS';
export const ON_SHIPPING_STATUS_CHANGE = 'ON_SHIPPING_STATUS_CHANGE';
export const TOGGLE_DELETE_CONFIRM_MODAL = 'TOGGLE_DELETE_CONFIRM_MODAL';
export const TOGGLE_REDIRECT_CONFIRM_MODAL = 'TOGGLE_REDIRECT_CONFIRM_MODAL';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const dismissErrorModal = () => ({ type: DISMISS_ERROR_MODAL });

export const receiveCourierStatusCodeError = error => ({
  type: RECEIVE_COURIER_STATUS_CODE_ERROR,
  meta: { error },
});

export const fetchCourierDetailsViewData = id => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/id`,
      name: 'fetchCourierDetailsViewData',
      query: {
        id,
      },
    });
    dispatch({
      type: RECEIVE_COURIER_DETAILS,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveCourierStatusCodeError(err.errors[0].message));
  }
};

export const fetchCourierDetailListViewData = ({
  activePage = 0,
  courierName,
  recordsPerPage = 20,
  search = '',
}) => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.COURIER_STATUS_CODES}`,
      name: 'fetchCourierDetailListViewData',
      query: {
        courier_id: courierName,
        current_page_index: activePage ? activePage - 1 : 0,
        records_per_page: recordsPerPage,
        search,
      },
    });
    const { totalElements, content } = json.response;
    dispatch({
      meta: {
        totalElements,
      },
      payload: {
        content,
      },
      type: RECEIVE_COURIER_DETAIL_LIST,
    });
  } catch (err) {
    console.error(err);
  }
};

export const onShippingStatusChange = value => ({
  type: ON_SHIPPING_STATUS_CHANGE,
  payload: value,
});

export const fetchCourierDetailsUpdate = courierDetails => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/update`,
      body: JSON.stringify(courierDetails),
      name: 'fetchCourierDetailsUpdate',
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/all`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/id`,
    });
    dispatch({
      type: RECEIVE_COURIER_DETAILS_UPDATE,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveCourierStatusCodeError(err.errors[0].message));
  }
};

export const fetchCourierDetailsDelete = courierDetailsId => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/${courierDetailsId}`,
      name: 'fetchCourierDetailsDelete',
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/all`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/id`,
    });
    dispatch({
      type: RECEIVE_COURIER_DETAILS_DELETE,
    });
    dispatch(toggleDeleteConfirmModal());
    dispatch(toggleRedirectConfirmModal());
  } catch (err) {
    dispatch(receiveCourierStatusCodeError(err.errors[0].message));
    dispatch(toggleDeleteConfirmModal());
  }
};

export const fetchCourierSelectorListViewData = ({
  activePage = 0,
  recordsPerPage = 20,
  search = '',
}) => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/all`,
      name: 'fetchCourierSelectorListViewData',
      query: {
        search,
        current_page_index: activePage ? activePage - 1 : 0,
        records_per_page: recordsPerPage,
      },
    });
    const { totalElements, content } = json.response;
    dispatch({
      meta: {
        totalElements,
      },
      payload: {
        content,
      },
      type: RECEIVE_COURIERS_SELECTOR_LIST,
    });
  } catch (err) {
    console.error(err);
  }
};

export const receiveCourierDetailsCsvUpload = selectedFile => ({
  type: RECEIVE_COURIER_DETAILS_CSV_UPLOAD,
  payload: selectedFile,
});

export const fetchSaveCourierDetailsAssets = courierName => async (
  dispatch,
  getState,
) => {
  const {
    courierStatusCodesReducer: {
      files: {
        uploadedCourierDetails: { selectedFile },
      },
    },
  } = getState();
  const formData = new FormData();
  formData.set('file', selectedFile[0]);
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: formData,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/upload/${courierName}`,
      name: 'saveUploadCourierDetailsAssets',
      contentType: false,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/all`,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.COURIER_STATUS_CODES}/id`,
    });
    if (json.response.failedRows > 0) {
      dispatch({
        type: RECEIVE_SAVE_COURIER_DETAILS_ERROR,
        payload: json.response,
      });
    } else {
      dispatch({
        type: RECEIVE_SAVE_COURIER_DETAILS_SUCCESS,
      });
    }
  } catch (err) {
    dispatch(receiveCourierStatusCodeError(err));
  }
};

export const toggleDeleteConfirmModal = () => ({
  type: TOGGLE_DELETE_CONFIRM_MODAL,
});

export const toggleRedirectConfirmModal = () => ({
  type: TOGGLE_REDIRECT_CONFIRM_MODAL,
});
