import moment from 'moment'; // eslint-disable-line
import { Base64 } from 'js-base64'; // eslint-disable-line
import { API_DATE_FORMAT } from '~src/constants/dateFormats';
import { parseDateFilter } from '~src/lib/monitor/dateFilter';
import { parseQueryString } from '~src/lib/helpers';
import { getDashNameFromPathname } from '~src/lib/monitor/urls';
import { analytics, EVENTS, EVENT_FIELDS } from './setup';

const getDateRange = dateFilter => {
  const { startDate, endDate } = parseDateFilter(dateFilter);
  return {
    startDate: moment(startDate).format(API_DATE_FORMAT),
    endDate: moment(endDate).format(API_DATE_FORMAT),
  };
};

const getPackagesProperties = search => {
  const {
    date_filter: dateFilter,
    // secondReferrer is a base 64 encoded string that contains the url of the chart page
    // that led to this packages page:
    secondReferrer = '',
  } = parseQueryString(search);
  let refUrl = '';
  try {
    refUrl = Base64.decode(secondReferrer);
  } catch (err) {
    console.error(err);
  }

  const { startDate, endDate } = getDateRange(dateFilter);
  const refSearch = `?${refUrl.split(/\?(.+)/)[1]}`;
  const refParsedQuery = parseQueryString(refSearch);
  const { id: chartId = null, referrer: dashPathname = null } = refParsedQuery;
  return {
    level001: chartId,
    level000: dashPathname ? getDashNameFromPathname(dashPathname) : null,
    drillLevel: '002',
    pageName: 'packages',
    startDate,
    endDate,
  };
};

const getDashboardProperties = ({ pathname, dashStartDate, dashEndDate }) => ({
  drillLevel: '000',
  pageName: getDashNameFromPathname(pathname),
  startDate: moment(dashStartDate).format(API_DATE_FORMAT),
  endDate: moment(dashEndDate).format(API_DATE_FORMAT),
});

const getChartProperties = search => {
  const { id, referrer, date_filter: dateFilter } = parseQueryString(search);
  const { startDate, endDate } = getDateRange(dateFilter);
  return {
    drillLevel: '001',
    level000: getDashNameFromPathname(referrer),
    pageName: id,
    startDate,
    endDate,
  };
};

const getCommonProperties = state => {
  const {
    userReducer: {
      user: {
        id: userId,
        current_retailer_id: retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
    reporting: { dashStartDate, dashEndDate } = {},
  } = state;

  // pulled from here rather than redux for consistant excaping;
  // state.router.location.search is sometimes url encoded and sometimes not:
  const { href: url, pathname, search } = document && document.location;

  let pageProperties;
  if (pathname.startsWith('/monitor/chart')) {
    pageProperties = getChartProperties(search);
  } else if (pathname.startsWith('/monitor/packages')) {
    pageProperties = getPackagesProperties(search);
  } else {
    pageProperties = getDashboardProperties({
      pathname,
      dashStartDate,
      dashEndDate,
    });
  }
  const {
    pageName,
    drillLevel,
    startDate,
    endDate,
    level000,
    level001,
  } = pageProperties;

  return {
    [EVENT_FIELDS.PAGE_NAME]: pageName,
    [EVENT_FIELDS.DRILL_LEVEL]: drillLevel,
    [EVENT_FIELDS.USER_ID]: userId,
    [EVENT_FIELDS.START_DATE]: startDate,
    [EVENT_FIELDS.END_DATE]: endDate,
    [EVENT_FIELDS.URL]: url,
    [EVENT_FIELDS.NAME_000]: level000,
    [EVENT_FIELDS.NAME_001]: level001,
    retailer_moniker: retailerMoniker,
  };
};

const logExport = exportType => (dispatch, getState) => {
  analytics.log({
    eventType: EVENTS.EXPORT,
    properties: {
      ...getCommonProperties(getState()),
      [EVENT_FIELDS.EXPORT_TYPE]: exportType,
    },
  });
};

const logPageLoad = apiResponseTimes => (dispatch, getState) => {
  const maxResponseTime = Math.max(...Object.values(apiResponseTimes));
  analytics.log({
    eventType: EVENTS.PAGE_LOAD,
    properties: {
      ...getCommonProperties(getState()),
      [EVENT_FIELDS.RESPONSE_MS]: Math.round(maxResponseTime),
    },
  });
};

export { logExport, logPageLoad };
