import React from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Alert, Button, Dropdown, Table, Tag, SvgButton } from '@narvar/hum';
import { Header, Tabs, LocalePicker } from '~src/components';
import { dismissAlert } from '~src/actions/uiActions';

const mapDispatchToProps = dispatch => ({
  handleDismissAlert: () => {
    dispatch(dismissAlert());
  },
});

const mapComponent = component => {
  switch (component) {
    case 'Dropdown':
      return Dropdown;
    case 'SvgButton':
      return SvgButton;
    case 'LocalePicker':
      return LocalePicker;
    default:
      return Button;
  }
};

const ListView = ({
  aboveList,
  alert: { color: alertColor, show: showAlert, text: alertText } = {},
  tabs,
  isFetching,
  schema: {
    table: {
      body,
      enableSearch,
      headings,
      isTableSearch,
      onSubmit,
      pagination,
      totalPages,
      totalResults,
      activePage,
      onClickPagination,
      zeroStateProps,
      tags,
      isScrollable,
    } = {},
    header: {
      backButton,
      secondaryTitle,
      rightActions,
      leftActions,
      className,
    } = {},
  } = {},
  handleDismissAlert,
}) => (
  <div>
    <Helmet />
    <Header backButton={backButton} secondaryTitle={secondaryTitle}>
      {Array.isArray(leftActions) && (
        <div className={cx('flex-left', className)}>
          {leftActions.map(({ component, props, children }, index) => {
            const Action = mapComponent(component);
            return (
              <Action
                key={typeof children === 'string' ? children : index}
                {...props}
              >
                {children}
              </Action>
            );
          })}
        </div>
      )}
      {Array.isArray(rightActions) && (
        <div className={cx('flex-right', className)}>
          {rightActions.map(({ component, props, children }, index) => {
            const Action = mapComponent(component);
            return (
              <Action
                key={typeof children === 'string' ? children : index}
                {...props}
              >
                {children}
              </Action>
            );
          })}
        </div>
      )}
    </Header>
    {tabs && <Tabs tabs={tabs}></Tabs>}
    <Alert color={alertColor} show={showAlert} toggle={handleDismissAlert}>
      {alertText}
    </Alert>
    <Container>
      {aboveList && aboveList()}
      {Array.isArray(tags) && (
        <Row>
          <Col md={12}>
            <div className="d-flex flex-wrap">
              {tags.map(tag => (
                <Tag label={tag} readOnly />
              ))}
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}>
          <Table
            activePage={activePage}
            body={body}
            enableSearch={enableSearch}
            headings={headings}
            isFetching={isFetching}
            isTableSearch={isTableSearch}
            onSubmit={onSubmit}
            pagination={pagination}
            totalPages={totalPages}
            totalResults={totalResults}
            onClickPagination={onClickPagination}
            zeroStateProps={zeroStateProps}
            isScrollable={isScrollable}
          />
        </Col>
      </Row>
    </Container>
  </div>
);

ListView.defaultProps = {
  tabs: [],
  aboveList: null,
};
ListView.propTypes = {
  aboveList: PropTypes.func,
  alert: PropTypes.shape({
    color: PropTypes.string,
    show: PropTypes.bool.isRequired,
    text: PropTypes.string,
  }).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ),
  handleDismissAlert: PropTypes.func.isRequired, // eslint-disable-line react/no-typos
  schema: PropTypes.shape({
    table: PropTypes.shape({
      // eslint-disable-line react/no-typos
      body: PropTypes.array.isRequired,
      headings: PropTypes.array.isRequired,
      zeroStateProps: PropTypes.shape({
        objectName: PropTypes.string,
        onClickAdd: PropTypes.func,
        message: PropTypes.string,
        subMessage: PropTypes.string,
        iconName: PropTypes.string,
      }).isRequired,
      pagination: PropTypes.bool,
      totalPages: PropTypes.number,
      totalResults: PropTypes.number,
      activePage: PropTypes.number,
      onClickPagination: PropTypes.func,
    }).isRequired,
    header: PropTypes.shape({
      backButton: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
      ]),
      secondaryTitle: PropTypes.string,
      rightActions: PropTypes.arrayOf(PropTypes.shape(Button.propTypes)),
      leftActions: PropTypes.arrayOf(
        PropTypes.oneOfType([Button.propTypes, Dropdown.propTypes]),
      ),
    }).isRequired,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default connect(
  null,
  mapDispatchToProps,
)(ListView);
