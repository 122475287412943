import moment from 'moment';
import { API_REQUEST } from '~src/lib/api';
import { pascalizeReportName } from '~src/lib/helpers';
import { logExport } from '~src/analytics/monitor';
import * as ENDPOINTS from '~src/constants/endpoints';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';
import qs from 'qs';

export const RECEIVE_TRACKING_ANALYTICS_REPORT =
  'RECEIVE_TRACKING_ANALYTICS_REPORT';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS_TRACKING';
export const DISMISS_ALERT = 'DISMISS_ALERT_TRACKING';
export const REQUEST_FEEDBACK_ENGAGMENT_DRILLDOWN =
  'REQUEST_FEEDBACK_ENGAGMENT_DRILLDOWN'; // what module is this for? @mceoin
export const RECEIVE_FEEDBACK_ENGAGMENT_DRILLDOWN =
  'RECEIVE_FEEDBACK_ENGAGMENT_DRILLDOWN'; // what module is this for? @mceoin
export const REQUEST_DELIVERY_EXPERIENCE_RATING =
  'REQUEST_DELIVERY_EXPERIENCE_RATING';
export const RECEIVE_DELIVERY_EXPERIENCE_RATING =
  'RECEIVE_DELIVERY_EXPERIENCE_RATING';
export const GET_RETAILER_SETTINGS = 'GET_RETAILER_SETTINGS';
export const RECEIVE_NEW_SMS_ENABLED = 'RECEIVE_NEW_SMS_ENABLED';
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const SELECT_LOCALE = 'SELECT_LOCALE';
export const SELECT_TRACKING_TYPE = 'SELECT_TRACKING_TYPE';
export const SELECT_SEGMENT_IDS = 'SELECT_SEGMENT_IDS';
export const RECEIVE_TRACK_SEGMENTS = 'RECEIVE_TRACK_SEGMENTS';
export const RECEIVE_CUSTOM_COMPONENTS_INSTALLED =
  'RECEIVE_CUSTOM_COMPONENTS_INSTALLED';

// TODO: Replace hard-coded types with dynamic options
// once experience segmentation is ready to go
export const OUTBOUND = 'OUTBOUND_SHIPPING';
export const INBOUND = 'RETURNS';

export const DATE_RANGE_ERROR =
  'Please reduce the date range to 100 days or fewer';
export const dismissAlert = () => ({ type: DISMISS_ALERT, alert: null });

export const receiveError = error => {
  console.error(error);
  return {
    type: RECEIVE_ERRORS,
    meta: { error },
  };
};

export const formatDates = ({ startDate, endDate }) => ({
  startDateFormatted: moment(startDate).format(API_DATE_FORMAT),
  endDateFormatted: moment(endDate).format(API_DATE_FORMAT),
});

export const receiveNewSMSEnabled = payload => ({
  type: RECEIVE_NEW_SMS_ENABLED,
  payload,
});

export const receiveRetailerCustomComponents = payload => ({
  type: RECEIVE_CUSTOM_COMPONENTS_INSTALLED,
  payload,
});

export const fetchRetailerSettings = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}`,
    });

    const {
      retailer_settings: retailerSettings,
      retailer_custom_components: retailerCustomComponents,
    } = json;

    const parsedSettings = JSON.parse(retailerSettings);
    const {
      features: {
        messaging_preferences: {
          sms: { enabled: newSMSEnabled = false } = {},
        } = {},
      } = {},
    } = parsedSettings;
    dispatch(receiveNewSMSEnabled({ newSMSEnabled }));
    dispatch(receiveRetailerCustomComponents({ retailerCustomComponents }));
  } catch (e) {
    dispatch(receiveError(e));
  }
};

export const receiveTrackSegments = payload => dispatch => {
  dispatch({
    type: RECEIVE_TRACK_SEGMENTS,
    payload,
  });
};

export const fetchTrackSegments = () => async dispatch => {
  try {
    const segments = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.TRACK_SEGMENTS,
      name: 'fetchTrackSegments',
    });
    dispatch(receiveTrackSegments({ segments }));
  } catch (e) {
    dispatch(receiveError(e));
  }
};

const receiveBigQueryAnalyticsReport = (json, key) => dispatch => {
  const payload = json.messaging;
  payload.key = key;
  dispatch({
    type: RECEIVE_TRACKING_ANALYTICS_REPORT,
    payload,
  });
};

const receiveTrackingAnalyticsReport = json => dispatch => {
  // NOTE: FEEDBACK_ENGAGEMENT_ARRIVAL returns an `reports` as Object vs Array (like
  // all the other reports. Additionally DASHBOARD_TOP_BAR returns Array[2] where
  // all others return Array[0], MT
  if (json.response.reports) {
    const payload =
      json.response.reports.length > 1 || !json.response.reports[0]
        ? json.response.reports
        : json.response.reports[0];

    if (payload.length) {
      payload.forEach(p => {
        if (p.key) {
          dispatch({
            type: RECEIVE_TRACKING_ANALYTICS_REPORT,
            payload: p,
          });
        }
      });
    } else {
      dispatch({
        type: RECEIVE_TRACKING_ANALYTICS_REPORT,
        payload,
      });
    }
  }
};

const fetchBigQueryAnalytics = () => (dispatch, getState) => {
  const {
    trackingAnalyticsReducer: { dateRange } = {},
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const { startDateFormatted, endDateFormatted } = formatDates(dateRange);

  const name = 'fetchMessagingSmsAnalytics';
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: ENDPOINTS.REPORTING,
    query: {
      dataset: '"messaging"',
      date_filter: `"event_ts" ge ${startDateFormatted} and "event_ts" lt ${endDateFormatted}`,
      object: '"messaging"',
      metrics: '"count"',
      retailer: retailerMoniker,
      // eslint-disable-next-line quotes
      filters: `( "service_name" eq 'consumer-notification-preferences' ),( "metric_name" eq 'PREFERENCE_UPDATE_EVENT' ),( "notification_channel" eq 'sms' )`,
      dimensions: '"user_action"',
    },
  })
    .then(json => {
      dispatch(receiveBigQueryAnalyticsReport(json, 'MESSAGING_PREFERENCES'));
    })
    .catch(error => dispatch(receiveError(error)));
};

const fetchTrackingAnalytics = (
  isDynamicTrack = false,
  isBigQuery = false,
  trackingType = '',
  segmentIds = [],
) => (dispatch, getState) => {
  const {
    trackingAnalyticsReducer: { dateRange, selectedLocale } = {},
  } = getState();
  const { startDateFormatted, endDateFormatted } = formatDates(dateRange);

  Object.keys(
    isDynamicTrack ? ENDPOINTS.REPORTS_DYNAMIC_TRACK : ENDPOINTS.REPORTS,
  ).forEach(report => {
    const name = `fetch${pascalizeReportName(report)}`;

    const query = {
      report,
      start_date: startDateFormatted,
      end_date: endDateFormatted,
      dynamicTrack: !!isDynamicTrack,
      bigQuery: !!isBigQuery,
      trackingType,
    };
    if (selectedLocale) query.locale = selectedLocale;
    if (segmentIds.length) query.segmentIds = segmentIds;

    const urlParams = qs.stringify(query, {
      arrayFormat: 'comma',
      encode: false,
    });
    const path = `${ENDPOINTS.TRACKING_ANALYTICS}?${urlParams}`;

    dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
      name,
    })
      .then(json => {
        dispatch(receiveTrackingAnalyticsReport(json));
      })
      .catch(error => dispatch(receiveError(error)));
  });
};

export const fetchAnalyticsData = () => async (dispatch, getState) => {
  const {
    user: { userProducts = [] } = {},
    trackingAnalyticsReducer: {
      newSMSEnabled,
      trackingType,
      segmentIds,
      dateRange: { clientSide },
    } = {},
    router: { location: { search } } = {},
  } = getState();

  if (!clientSide) {
    await dispatch({
      type: SELECT_DATE_RANGE,
      payload: {
        dateRange: {
          startDate: moment()
            .subtract(30, 'days')
            .format(API_DATE_FORMAT),
          endDate: moment()
            .subtract(1, 'days')
            .format(API_DATE_FORMAT),
        },
      },
    });
  }

  const hasNewSmsPermission = userProducts.includes('sms_analytics_dashboard');

  const { dynamicTrack, bigQuery } = qs.parse(search, {
    ignoreQueryPrefix: true,
  });
  const isDynamicTrack = dynamicTrack === 'true';
  const isBigQuery = bigQuery === 'true';

  dispatch(
    fetchTrackingAnalytics(
      isDynamicTrack,
      isBigQuery,
      trackingType,
      segmentIds,
    ),
  );

  if (hasNewSmsPermission && newSMSEnabled) {
    dispatch(fetchBigQueryAnalytics());
  }
};

export const exportExcel = (trackingType = '') => (dispatch, getState) => {
  const {
    router: {
      location: { pathname, search },
    },
    trackingAnalyticsReducer: { dateRange, selectedLocale } = {},
  } = getState();

  const { startDateFormatted, endDateFormatted } = formatDates(dateRange);

  const link = document.createElement('a');
  link.href = `${ENDPOINTS.TRACKING_ANALYTICS_EXCEL_ENDPOINT}${
    search ? `${search}&` : '?'
  }start_date=${startDateFormatted}&end_date=${endDateFormatted}${
    selectedLocale ? `&locale=${selectedLocale}` : ''
  }${trackingType ? `&trackingType=${trackingType}` : ''}`;
  link.style = 'visibility:hidden';
  link.target = '_blank';
  if (document) document.body.appendChild(link);
  link.click();
  if (document) document.body.removeChild(link);

  if (pathname.includes('monitor')) {
    dispatch(logExport('excel'));
  }
};

export const exportPdf = () => (dispatch, getState) => {
  if (window) window.print();
  const {
    router: {
      location: { pathname = '' },
    },
  } = getState();
  if (pathname.includes('monitor')) {
    dispatch(logExport('pdf'));
  }
};

export const selectDateRange = dateRange => async dispatch => {
  await dispatch({
    type: SELECT_DATE_RANGE,
    payload: { dateRange },
  });
  dispatch(fetchAnalyticsData());
};

export const selectLocale = selectedLocale => async dispatch => {
  await dispatch({
    type: SELECT_LOCALE,
    payload: { selectedLocale },
  });
  dispatch(fetchAnalyticsData());
};

export const selectInitialLocale = () => (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: uriMoniker, locales },
        } = {},
        userLocales: { [uriMoniker]: userLocales = [] } = {},
        trackingAnalyticsReducer: { selectedLocale } = {},
      },
    },
  } = getState();
  const userHasAllLocales = locales.every(
    v => userLocales && userLocales.includes(v),
  );
  if (!selectedLocale && !userHasAllLocales && userLocales.length > 0) {
    dispatch(selectLocale(userLocales[0]));
  }
};

export const selectTrackingType = trackingType => async dispatch => {
  await dispatch({
    type: SELECT_TRACKING_TYPE,
    payload: { trackingType },
  });
  dispatch(fetchAnalyticsData());
};

export const selectSegmentIds = segmentIds => async dispatch => {
  await dispatch({
    type: SELECT_SEGMENT_IDS,
    payload: { segmentIds },
  });
  dispatch(fetchAnalyticsData());
};
