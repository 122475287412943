export const RETURNS_VERSION_3_PRODUCT_NAME = 'Returns 3.0';
export const RETURN_RULES_VERSION_3_PRODUCT_NAME = 'Return Rules 3.0';
export const RETURN_RULES_PRODUCT_NAME = 'Returns rules';
export const RETURNS_PRODUCT_NAME = 'Returns';
export const RETURNS_RESTRICTED_EMAILS_NAME = 'returns_restricted_emails';
export const PARTNERSHIPS_CSP_API = 'csp_api';
export const MAGENTO_PRODUCT = 'magento';
export const SFCC_PRODUCT = 'sfcc';
export const LOM_PRODUCT = 'lom';
export const BC_PRODUCT = 'bigcommerce';
export const SAP_PRODUCT = 'sap';
export const SHOPIFY_PRODUCT = 'shopify';
export const EMAIL_DASHBOARD_PRODUCT = 'email_analytics_dashboard';
export const SMS_DASHBOARD_PRODUCT = 'sms_analytics_dashboard';
export const MONITOR_PRODUCT = 'monitor';
export const MONITOR_V2_PRODUCT = 'monitor_v2';
export const SHIP_PRODUCT = 'ship';
export const ASSIST_PRODUCT = 'assist';
export const NOTIFY_PRODUCT = 'alerts';
export const WYSIWYG_EMAIL_EDITOR_PRODUCT = 'wysiwyg_email_editor';
export const WYSIWYG_SMS_EDITOR_PRODUCT = 'wysiwyg_sms_editor';
export const NOTIFICATION_HISTORY_PRODUCT = 'notification_history';
export const SHIP_EMBED_PRODUCT = 'ship_embed';
export const TRANSPORTATION_MANAGEMENT_PRODUCT = 'tms';
export const DELIVERY_PRODUCT = 'delivery';
export const TRACK_PRODUCT = 'Tracking';
export const TRACK_BETA = 'Track Beta';
export const APPLE_WALLET_PRODUCT = 'apple_wallet';
export const SHIP_UNKNOWN_ORIGIN = 'unknown_origin';
export const PROMISE_V2_PRODUCT = 'promise_v2';
