const ERRORS = {
  invalidNestedCondition: 'Cannot add OR condition.',
  invalidNumber: 'Not a valid number for condition - ',
  invalidAction: 'EDD Rule must contain at least one valid action',
  invalidCondition: 'EDD Rule must contain at least one valid condition',
  missingConditionValue: 'No value selected for condition - ',
  missingOperator: 'No operator selected for condition - ',
  missingActionValue: 'No value selected for action - ',
  invalidActionValue: 'Invalid values selected for action - ',
  invalidZipCode: 'Invalid destination zip code.',
  missingName: 'Please enter a name for the Rule',
  invalidDefaultCondition:
    'Retailer default cannot be selected with other conditions',
};

export const EDD_CACHE_TYPE = {
  DC: 'DC',
  SHIP: 'SHIP',
  TRACK: 'TRACK',
  EDD: 'EDD',
  ORIGIN: 'ORIGIN',
};

export const SHIP_API_PRODUCT = 'ship';
export const SHIP_EMBED_PRODUCT = 'ship_embed';

export const DC_LOCAL_DELIVERY = {
  CARRIER_INSTRUCTIONS:
    'e.g. Use the main store entrance and any store associate will direct you to the pick-up area.',
  ELIGIBLE_ZIPS: 'e.g. 94607,94105,95104,94610',
  ELIGIBLE_ZIPS_SUB_TEXT:
    'Determine which ZIP codes are eligible for Local Delivery. (Separate Entries by a comma)',
  CARRIER_INSTRUCTIONS_SUB_TEXT:
    'Let carriers know how they can pick up Local Delivery items from your store',
};

export default ERRORS;
