import { push } from 'connected-react-router';
import * as ENDPOINTS from '~src/constants/endpoints';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';

export const CLEAR_FORM_VALUES = 'HANDOFFS/CLEAR_FORM_VALUES';
export const RECEIVE_CARRIERS = 'HANDOFFS/RECEIVE_CARRIERS';
export const RECEIVE_SELECTOR_LIST = 'HANDOFFS/RECEIVE_SELECTOR_LIST';
export const RECEIVE_DETAILS = 'HANDOFFS/RECEIVE_DETAILS';
export const RECEIVE_ERROR = 'HANDOFFS/RECEIVE_ERROR';
export const RECEIVE_DETAILS_UPDATE = 'HANDOFFS/RECEIVE_DETAILS_UPDATE';
export const RECEIVE_DETAILS_DELETE = 'HANDOFFS/RECEIVE_DETAILS_DELETE';
export const RESET_DETAILS = 'HANDOFFS/RESET_DETAILS';
export const RECEIVE_SAVE_ADD_DETAILS = 'HANDOFFS/RECEIVE_SAVE_ADD_DETAILS';
export const ON_DETAILS_CHANGE = 'HANDOFFS/ON_DETAILS_CHANGE';
export const ON_HANDOFFS_SELECTER_CHANGE =
  'HANDOFFS/ON_HANDOFFS_SELECTER_CHANGE';
export const TOGGLE_DELETE_CONFIRM_MODAL =
  'HANDOFFS/TOGGLE_DELETE_CONFIRM_MODAL';

const invalidateHandoffsListAndDetailsPath = () => async dispatch => {
  await dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.HANDOFFS}/all`,
  });
  await dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.HANDOFFS,
  });
};

export const receiveHandoffsError = error => ({
  type: RECEIVE_ERROR,
  meta: { error },
});

export const changeHandoffsCarriers = (name, selectedName) => ({
  type: ON_HANDOFFS_SELECTER_CHANGE,
  payload: {
    name,
    selectedName,
  },
});

export const resetForm = () => ({
  type: CLEAR_FORM_VALUES,
});

export const toggleDeleteConfirmModal = () => ({
  type: TOGGLE_DELETE_CONFIRM_MODAL,
});

export const fetchHandoffsCarriers = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.HANDOFFS}/carrierList`,
      name: 'fetchHandoffsCarrierList',
    });
    dispatch({
      type: RECEIVE_CARRIERS,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
  }
};

export const fetchHandoffsDetailsViewData = handofId => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.HANDOFFS,
      name: 'fetchHandoffsDetailsViewData',
      query: {
        handoff_id: handofId,
      },
    });
    dispatch({
      type: RECEIVE_DETAILS,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
  }
};

export const changeExpression = value => ({
  type: ON_DETAILS_CHANGE,
  payload: value,
});

export const fetchSaveHandoffsDetails = handoffsDetails => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: ENDPOINTS.HANDOFFS,
      body: JSON.stringify(handoffsDetails),
      name: 'fetchSaveHandoffsDetails',
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.HANDOFFS}/all`,
    });
    dispatch({
      type: RECEIVE_SAVE_ADD_DETAILS,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
  }
};

export const fetchHandoffsDetailsUpdate = handoffsDetails => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.HANDOFFS,
      body: JSON.stringify(handoffsDetails),
      name: 'fetchHandoffsDetailsUpdate',
    });
    dispatch(invalidateHandoffsListAndDetailsPath());
    dispatch({
      type: RECEIVE_DETAILS_UPDATE,
      payload: json.response,
    });
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
  }
};

export const fetchHandoffsDetailsDelete = dbRowId => async dispatch => {
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.HANDOFFS}/${dbRowId}`,
      name: 'fetchHandoffsDetailsDelete',
    });
    dispatch(invalidateHandoffsListAndDetailsPath());
    dispatch({
      type: RECEIVE_DETAILS_DELETE,
    });
    dispatch(toggleDeleteConfirmModal());
    dispatch(push('/handoffs/handoffs-selector'));
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
    dispatch(toggleDeleteConfirmModal());
  }
};

export const fetchHandoffsSelectorListViewData = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.HANDOFFS}/all`,
      name: 'fetchHandoffsSelectorListViewData',
    });
    const { totalElements, content } = json.response;
    dispatch({
      meta: {
        totalElements,
      },
      payload: {
        content,
      },
      type: RECEIVE_SELECTOR_LIST,
    });
  } catch (err) {
    dispatch(receiveHandoffsError(err.errors[0].message));
  }
};
