import {
  DISPLAY_ERROR,
  DISPLAY_SUCCESS,
  RECEIVE_BIGCOMMERCE_INPUT_CHANGES,
  IS_BIGCOMMERCE_CREDS_SET_SUCCESSFULLY,
  RESET_BIGCOMMERCE_CREDS,
  RECEIVE_BIGCOMMERCE_CREDS,
} from '../actions/partnershipsBigcommerceActions';
import { DISMISS_ALERT } from '../actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  isValid: false,
  currentValues: {
    clientId: '',
    clientSecret: '',
    accessToken: '',
    storeBaseUrl: '',
  },
  updatedValues: {
    clientId: '',
    clientSecret: '',
    accessToken: '',
    storeBaseUrl: '',
  },
  isSuccessfullySetCredentials: false,
};

function partnershipsBigcommerceReducer(
  state = initialState,
  {
    payload: {
      text = '',
      currentValues = {
        clientId: '',
        clientSecret: '',
        accessToken: '',
        storeBaseUrl: '',
      },
      updatedValues = {
        clientId: '',
        clientSecret: '',
        accessToken: '',
        storeBaseUrl: '',
      },
      inputData = {
        key: '',
        value: '',
      },
      isSuccessfullySetCredentials = false,
    } = {},
    type,
  },
) {
  switch (type) {
    case DISPLAY_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    case RECEIVE_BIGCOMMERCE_INPUT_CHANGES: {
      return {
        ...state,
        updatedValues: {
          ...state.updatedValues,
          [inputData.key]: inputData.value,
        },
      };
    }
    case IS_BIGCOMMERCE_CREDS_SET_SUCCESSFULLY: {
      return {
        ...state,
        isSuccessfullySetCredentials,
      };
    }
    case RESET_BIGCOMMERCE_CREDS: {
      return {
        ...state,
        updatedValues: {
          ...currentValues,
        },
      };
    }
    case RECEIVE_BIGCOMMERCE_CREDS: {
      return {
        ...state,
        currentValues: {
          ...currentValues,
        },
        updatedValues: {
          ...updatedValues,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default partnershipsBigcommerceReducer;
