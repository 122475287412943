import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  &.headwayapp {
    display: flex;
    align-items: center;
    position: relative;

    &.is-customer-support {
      & #HW_badge_cont {
        & #HW_badge {
          border: 2px solid ${colors.blue};
        }
      }
    }

    & #HW_badge_cont {
      position: absolute;
      right: -16px;
      top: -2px;

      & #HW_badge {
        border: 2px solid ${colors.black};
        height: 17px;
        line-height: 11px;
        transform: scale(0.75);
        width: 17px;

        &.HW_softHidden {
          opacity: 0;
        }
      }
    }
    & svg {
      & path {
        fill: ${colors.white};
      }
    }
  }
`;

export default s;
