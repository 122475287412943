import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray};
  margin-bottom: 20px;
  min-height: 115px;

  .col {
    text-align: center;
    padding: 10px 0;

    h1 {
      font-size: 40px;
    }
  }

  .icon-section {
    position: relative;
    top: 11px;
    transform: scale(0.5);
  }
`;

export default s;
