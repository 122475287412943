import React from 'react';
import { connect } from 'react-redux';
import { DetailsView } from '~src/components';
import { createSchema } from '~src/schemas/views/notifySettings';
import DualActionModal from '~src/components/DualActionModal';
import {
  fetchRetailerInfoSettings,
  fetchNotifySettings,
  receiveNotifySettingsError,
  toggleConfirmModal,
  resetFormAndErrors,
  updateSettingsStore,
  validateNotifySettings,
  fetchSaveNotifySettings,
  createSubaccount,
  generateDkim,
} from '~src/actions/notifySettingsActions';
import PropTypes from 'prop-types';
import withStyles from '~src/lib/isomorphic-style-loader/withStyles';
import s from './NotifySettings.scss';

const narvarDomain = __ENV__ === 'production' ? '.narvar.com' : '.narvar.qa'; // eslint-disable-line no-undef
// enabled status has been commented out as it may be requested to be put back in
// We likely don't want to be able to alter the ui without a preview

const mapStateToProps = state => {
  const {
    apiReducer: api = {},
    userReducer: { user } = {},
    notifySettingsReducer: notifySettings,
  } = state;
  return {
    api,
    notifySettings,
    user,
  };
};

// utility function to find the diff of initial/updated card states
export const getCardDiff = (state, cardName) => {
  const { [`_${cardName}`]: initialCard, [cardName]: newCard } = state;
  const update = Object.keys(initialCard).reduce((diff, key) => {
    if (initialCard[key] !== newCard[key]) {
      return {
        ...diff,
        [key]: newCard[key],
      };
    }
    return diff;
  }, {});
  return update;
};

export class NotifySettingsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.schemaHandlers = {
      handleSwitch: this.handleSwitch,
      handleSave: this.handleSave,
      handleFbTokenChange: this.handleFbTokenChange,
      handleTextChange: this.handleTextChange,
      handleSelect: this.handleSelect,
      handleToggleConfirmationModal: this.handleToggleConfirmationModal,
      handleCreateEmailAccount: this.handleCreateEmailAccount,
      handleAccountAndDkim: this.handleAccountAndDkim,
      handleCopyElement: this.handleCopyElement,
      handleCopyDkim: this.handleCopyDkim,
      handleCopyHostName: this.handleCopyHostname,
    };
    const { notifySettings } = props;
    const {
      api: { isFetching },
      user: {
        retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: uriMoniker, products = [] } = {},
        } = {},
      } = {},
    } = this.props;

    const schemaProps = {
      uriMoniker,
      isFetching,
      products,
    };
    const { details } = createSchema(
      this.schemaHandlers,
      notifySettings,
      s,
      schemaProps,
    );

    this.state = {
      ...notifySettings,
      details,
    };
  }
  async componentDidMount() {
    const {
      props: {
        dispatch,
        user: {
          retailerId,
          retailerIdToRetailerInfo: {
            [retailerId]: { products = [] } = {},
          } = {},
        } = {},
      } = {},
    } = this;
    // todo abstract into util function
    let hasAlerts = false;
    for (let i = 0; i < products.length; i++) {
      if (products[i].toLowerCase() === 'alerts') {
        hasAlerts = true;
        break;
      }
    }

    dispatch(fetchRetailerInfoSettings());
    await dispatch(fetchNotifySettings(hasAlerts));
  }

  componentWillReceiveProps(nextProps) {
    const { notifySettings, api: { isFetching } = {} } = nextProps;
    this.updateSchema(notifySettings, isFetching);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(resetFormAndErrors());
  }

  getSettings = () => {
    const {
      state: {
        retailerInfoSettingsJson: retailerSettings,
        facebook: {
          page_id: pageId,
          app_id: appId,
          auth_token: authToken,
          custom_message: customMessage,
          use_retailer_page: useRetailerPage,
          item_visibility_proactive: itemVisibilityProactive,
          responses_list_selected: { type: responseType } = {},
          response_frequency_list_selected: { type: responseFrequency } = {},
          item_visibility_image_aspect_ratio_list_selected: {
            type: imageAspectRatio,
          } = {},
          cold_start_message_enabled: coldStartMessageEnabled,
        } = {},
        messageService: { service_id: serviceId } = {},
      } = {},
    } = this;

    const retailerInfoParams = {
      fbAccessToken: authToken,
      messageServiceId: serviceId,
    };
    const retailerInfoSettingsParams = {
      retailerSettings,
      newSettings: {
        page_id: pageId,
        app_id: appId,
        custom_message: customMessage,
        use_retailer_page: useRetailerPage,
        response_type: responseType,
        response_frequency: responseFrequency,
        item_visibility_proactive: itemVisibilityProactive,
        image_aspect_ratio: imageAspectRatio,
        cold_start_message_enabled: coldStartMessageEnabled,
      },
    };

    return { retailerInfoParams, retailerInfoSettingsParams };
  };

  reduceCardsToError = cards =>
    cards.reduce((hasError, currentCard) => {
      const { formControls = [] } = currentCard;
      return (
        hasError ||
        formControls.reduce((cardHasError, currentFormControl) => {
          const {
            props: { validationError = false } = {},
          } = currentFormControl;
          return cardHasError || Boolean(validationError);
        }, false)
      );
    }, false);

  handleSave = async () => {
    const {
      state,
      state: {
        facebook: { auth_token: authToken } = {},
        messageService: { service_id: messageServiceId } = {},
      } = {},
      props: { dispatch } = {},
    } = this;

    dispatch(updateSettingsStore(state));

    const {
      reduceCardsToError,
      state: { details: { cards = [] } = {} } = {},
    } = this;

    const formInvalid = reduceCardsToError(cards);
    if (formInvalid) {
      dispatch(
        receiveNotifySettingsError(
          'Please fix all errors before you hit Publish.',
        ),
      );
    } else {
      dispatch(validateNotifySettings(authToken, messageServiceId));
    }
  };

  handleConfirmSave = async () => {
    const {
      handleToggleConfirmationModal,
      getSettings,
      props: { dispatch } = {},
      state: {
        email: {
          reply_to_email: replyToEmail,
          sender_email: senderEmail,
          sender_name: senderName,
        } = {},
      } = {},
    } = this;
    const {
      retailerInfoParams: { fbAccessToken, messageServiceId },
      retailerInfoSettingsParams,
    } = getSettings();
    handleToggleConfirmationModal();
    const emailConfigs = {
      sender_email: senderEmail,
      sender_name: senderName,
      reply_to_email: replyToEmail,
    };

    dispatch(
      fetchSaveNotifySettings(
        fbAccessToken,
        messageServiceId,
        retailerInfoSettingsParams,
        emailConfigs,
      ),
    );
  };

  handleDenySave = () => {
    const { handleToggleConfirmationModal } = this;
    handleToggleConfirmationModal();
  };

  handleToggleConfirmationModal = () => {
    const { props: { dispatch } = {} } = this;
    dispatch(toggleConfirmModal());
  };

  updateSchema = (state, isFetching) => {
    const {
      props: {
        api: { isFetching: stateIsFetching } = {},
        user: {
          retailerId,
          retailerIdToRetailerInfo: {
            [retailerId]: { uri_moniker: uriMoniker, products = [] } = {},
          } = {},
        } = {},
      } = {},
    } = this;

    const schemaProps = {
      uriMoniker,
      isFetching: isFetching || stateIsFetching,
      products,
    };
    const { details } = createSchema(
      this.schemaHandlers,
      state,
      s,
      schemaProps,
    );
    this.setState({
      ...state,
      details,
    });
  };

  handleTextChange = event => {
    const { name, value } = event.currentTarget;
    const [component, field] = name.split('/');

    const newData = {
      ...this.state,
      [component]: {
        ...this.state[component],
        [field]: value,
      },
    };

    if (component === 'email' && field === 'sender_domain') {
      newData.senderDomainChanged = true;
    }

    this.updateSchema(newData);
  };

  handleFbTokenChange = event => {
    const { value } = event.currentTarget;
    const newData = {
      ...this.state,
      facebook: {
        ...this.state.facebook,
        auth_token: value,
      },
    };

    this.updateSchema(newData);
  };

  handleSwitch = event => {
    const { name, checked } = event.currentTarget;
    const [component, field] = name.split('/');

    const newData = {
      ...this.state,
      [component]: {
        ...this.state[component],
        [field]: checked,
      },
    };

    if (field === 'use_retailer_page') {
      newData.facebook.auth_token = '';
    }

    this.updateSchema(newData);
  };

  handleSelect = (event, name) => {
    const [component, field] = name.split('/');
    const targetName = event.currentTarget.name;
    const selected = this.state[component][field][targetName];
    const newData = {
      ...this.state,
      [component]: {
        ...this.state[component],
        [`${field}_selected`]: selected,
      },
    };
    this.updateSchema(newData);
  };

  handleCreateEmailAccount = () =>
    new Promise((resolve, reject) => {
      const {
        props: {
          dispatch,
          user: {
            retailerId,
            retailerIdToRetailerInfo: {
              [retailerId]: { uri_moniker: uriMoniker } = {},
            } = {},
          } = {},
        } = {},
      } = this;
      dispatch(createSubaccount(uriMoniker)).then(resolve, reject);
    });

  handleAccountAndDkim = () => {
    const {
      state,
      state: {
        email: {
          sender_domain: domain,
          domain_list_selected: { type: domainType } = {},
        } = {},
      } = {},
      props: {
        dispatch,
        user: {
          retailerId,
          retailerIdToRetailerInfo: {
            [retailerId]: { uri_moniker: uriMoniker } = {},
          } = {},
        } = {},
      } = {},
    } = this;
    const senderDomain =
      domainType === 'customer' ? domain : `${uriMoniker}${narvarDomain}`;

    if (senderDomain.length === 0) {
      const updatedState = {
        ...state,
        senderDomainChanged: true,
      };

      this.updateSchema(updatedState);
      dispatch(updateSettingsStore(updatedState));
      dispatch(
        receiveNotifySettingsError(
          'Please fix all errors before you hit Publish.',
        ),
      );
    } else {
      dispatch(updateSettingsStore(state));
      dispatch(
        generateDkim({
          name: uriMoniker,
          domain: senderDomain.toLowerCase(),
        }),
      );
    }
  };

  handleCopyElement = selector => {
    const copyTextarea = window.document.querySelector(selector);
    copyTextarea.select();
    window.document.execCommand('copy');
  };

  render = () => {
    const {
      handleToggleConfirmationModal,
      handleConfirmSave,
      handleDenySave,
      state: { details } = {},
      props: {
        notifySettings: {
          alert,
          showConfirmModal,
          facebook: { page_name: pageName = '' } = {},
        } = {},
      } = {},
    } = this;

    const title = 'Publish your changes?';
    const mainText =
      'Your changes will be live and available to your customers within 15 minutes.';
    const subText = pageName ? `Facebook Page Name: ${pageName}` : null;
    const showModal = showConfirmModal;
    const close = handleToggleConfirmationModal;
    const secondaryActionText = 'CANCEL';
    const secondaryAction = handleDenySave;
    const secondaryActionColor = 'link';
    const primaryActionText = 'PUBLISH';
    const primaryAction = handleConfirmSave;
    const primaryActionColor = 'primary';

    return (
      <div className={s.NotifySettings}>
        <DetailsView
          schema={details}
          alert={alert}
          isFetching={false} // TODO: properly handle isFetching
          isDynamicSchema
        />
        <DualActionModal
          title={title}
          mainText={mainText}
          subText={subText}
          showModal={showModal}
          close={close}
          primaryActionText={primaryActionText}
          primaryActionColor={primaryActionColor}
          primaryAction={primaryAction}
          secondaryActionText={secondaryActionText}
          secondaryActionColor={secondaryActionColor}
          secondaryAction={secondaryAction}
        />
      </div>
    );
  };
}

NotifySettingsDetails.propTypes = {
  notifySettings: PropTypes.shape({
    alert: PropTypes.shape({
      color: PropTypes.string,
      show: PropTypes.bool.isRequired,
      toggle: PropTypes.func,
      text: PropTypes.string,
    }).isRequired,
    saved: PropTypes.bool,
    date_modified: PropTypes.string,
    facebook: PropTypes.shape({
      page_id: PropTypes.string,
      page_name: PropTypes.string, // obtained from facebook validation
      auth_token: PropTypes.string,
      custom_message: PropTypes.string,
      app_id: PropTypes.string, // obtained from facebook validation
      use_retailer_page: PropTypes.bool,
      responses_list: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          type: PropTypes.string,
        }),
      ).isRequired,
      responses_list_selected: PropTypes.shape({
        description: PropTypes.string,
        type: PropTypes.string,
      }),
      response_frequency_list: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          type: PropTypes.string,
        }),
      ).isRequired,
      response_frequency_list_selected: PropTypes.shape({
        description: PropTypes.string,
        type: PropTypes.string,
      }),
    }).isRequired,
    _facebook: PropTypes.shape({
      auth_token: PropTypes.string,
      custom_message: PropTypes.string,
      use_retailer_page: PropTypes.bool,
      responses_list_selected: PropTypes.shape({
        description: PropTypes.string,
        type: PropTypes.string,
      }),
      response_frequency_list_selected: PropTypes.shape({
        description: PropTypes.string,
        type: PropTypes.string,
      }),
    }),
    messageService: PropTypes.shape({
      service_id: PropTypes.string,
    }).isRequired,
    _messageService: PropTypes.shape({
      service_id: PropTypes.string,
    }),
    email: PropTypes.shape({
      domain_list: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          type: PropTypes.string,
        }),
      ).isRequired,
      domain_list_selected: PropTypes.shape({
        description: PropTypes.string,
        type: PropTypes.string,
      }),
      sender_domain: PropTypes.string,
      validation_status: PropTypes.bool,
      dkim_record: PropTypes.string,
    }).isRequired,
    retailerInfoSettingsJson: PropTypes.object,
    currentLocale: PropTypes.string,
    showConfirmModal: PropTypes.bool,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  api: PropTypes.shape({}).isRequired,
};

NotifySettingsDetails.defaultProps = {};

export default connect(mapStateToProps)(withStyles(s)(NotifySettingsDetails));
