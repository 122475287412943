import moment from 'moment';
import { buildQueryString } from '~src/lib/helpers';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';

export default ({
  dataset = '"reporting"',
  retailer,
  retailerId,
  columns,
  id,
  offset = 0,
  startDate,
  endDate,
  limit,
  orderBy = '"order_date" DESC',
  whereFilters = [],
}) => {
  const formattedStartDate = moment(startDate).format(API_DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(API_DATE_FORMAT);
  const mappedWhereFilters = whereFilters
    .map(
      ({ filterKey, filterValue }) => `( "${filterKey}" eq '${filterValue}' )`,
    )
    .join(',');
  const whereQuery = mappedWhereFilters.length
    ? { where: mappedWhereFilters }
    : {};

  return buildQueryString({
    offset,
    from: `"${id}"`,
    select: columns.map(field => `"${field}"`).join(),
    date_filter: `"order_date" ge ${formattedStartDate} and "order_date" lt ${formattedEndDate}`,
    order_by: orderBy,
    ...whereQuery,
    ...(limit ? { limit } : {}),
    dataset,
    retailer,
    retailerId,
  });
};
