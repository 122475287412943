import {
  DISMISS_ALERT,
  DISMISS_ERROR_MODAL,
  RECEIVE_COURIER_DETAILS,
  RECEIVE_COURIER_DETAIL_LIST,
  RECEIVE_COURIER_DETAILS_CSV_UPLOAD,
  RECEIVE_COURIER_DETAILS_DELETE,
  RECEIVE_COURIER_DETAILS_UPDATE,
  RECEIVE_COURIERS_SELECTOR_LIST,
  RECEIVE_COURIER_STATUS_CODE_ERROR,
  RECEIVE_SAVE_COURIER_DETAILS_ERROR,
  RECEIVE_SAVE_COURIER_DETAILS_SUCCESS,
  ON_SHIPPING_STATUS_CHANGE,
  TOGGLE_DELETE_CONFIRM_MODAL,
  TOGGLE_REDIRECT_CONFIRM_MODAL,
} from '~src/actions/courierStatusCodesActions';

const initialState = {
  alert: {
    show: false,
  },
  changeDetected: false,
  courierSelectorList: {
    body: [],
    header: [],
  },
  courierDetailList: {
    body: [],
    header: [],
  },
  courierDetails: {},
  errors: [],
  csvUploadError: false,
  csvUploadErrorDetails: {
    failed_rows_details: [],
    message: '',
  },
  files: {
    uploadedCourierDetails: {
      format: 'csv',
      name: 'courier-status-code',
      selectedFile: [],
    },
  },
  locales: {
    locale_code: 'en_US',
  },
  messages: [],
  showDeleteConfirmModal: false,
  showRedirectModal: false,
  showUploadCourierDetailsModal: false,
  totalResults: 0,
};

const courierStatusCodes = (
  state = initialState,
  { meta, type, payload } = {},
) => {
  switch (type) {
    case RECEIVE_COURIER_DETAIL_LIST:
      return {
        ...state,
        courierDetailList: payload.content,
        errors: [],
        totalResults: meta.totalElements,
      };

    case RECEIVE_COURIERS_SELECTOR_LIST:
      return {
        ...state,
        errors: [],
        courierSelectorList: payload.content,
        totalResults: meta.totalElements,
      };

    case RECEIVE_COURIER_STATUS_CODE_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occured.'
              : `${meta.error}`,
        },
        changeDetected: false,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_COURIER_DETAILS:
      return {
        ...state,
        courierDetails: payload,
      };

    case ON_SHIPPING_STATUS_CHANGE:
      return {
        ...state,
        changeDetected: true,
        courierDetails: {
          ...state.courierDetails,
          shipping_status_code: payload,
        },
      };

    case RECEIVE_COURIER_DETAILS_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your Courier Status Code were updated successfully.',
        },
        changeDetected: false,
        courierDetails: payload,
      };

    case RECEIVE_COURIER_DETAILS_DELETE:
      return {
        ...state,
        changeDetected: false,
        courierDetails: {},
      };

    case RECEIVE_COURIER_DETAILS_CSV_UPLOAD:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Upload successful.',
        },
        changeDetected: true,
        files: {
          ...state.files,
          uploadedCourierDetails: {
            ...state.files.uploadedCourierDetails,
            name: payload.fileName,
            selectedFile: payload.selectedFile,
          },
        },
      };

    case RECEIVE_SAVE_COURIER_DETAILS_ERROR:
      return {
        ...state,
        csvUploadError: true,
        csvUploadErrorDetails: payload,
      };

    case RECEIVE_SAVE_COURIER_DETAILS_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Save successful.',
        },
        changeDetected: false,
      };

    case TOGGLE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: !state.showDeleteConfirmModal,
      };

    case TOGGLE_REDIRECT_CONFIRM_MODAL:
      return {
        ...state,
        showRedirectModal: !state.showRedirectModal,
      };

    case DISMISS_ERROR_MODAL:
      return {
        ...state,
        csvUploadError: initialState.csvUploadError,
      };

    default:
      return state;
  }
};

export default courierStatusCodes;
