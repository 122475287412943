import { API_REQUEST } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';

export const RECEIVE_EDD_RULES_DEFINITION = 'RECEIVE_EDD_RULES_DEFINITION';
export const REQUEST_EDD_RULES_DEFINITION = 'REQUEST_EDD_RULES_DEFINITION';
export const RECEIVE_EDD_RULES_DEFINITION_ERRORS =
  'RECEIVE_EDD_RULES_DEFINITION_ERRORS';

export const receiveEddRulesDefinition = payload => ({
  type: RECEIVE_EDD_RULES_DEFINITION,
  payload,
});

export const fetchEddRulesDefinition = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchEddRulesDefinition',
    path: ENDPOINTS.EDD_RULES_DEFINITION,
  })
    .then(json => {
      dispatch(receiveEddRulesDefinition(json.response));
    })
    .catch(err => console.error(err));
};
