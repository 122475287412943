export const returnMethodsTransformer = userInput => {
  const result = [];
  const prefix = `result/${userInput.result}/`;

  Object.keys(userInput).forEach(key => {
    if (
      key.startsWith(prefix) &&
      key.endsWith(':enabled') &&
      userInput[key] === 'true'
    ) {
      const method = key.slice(prefix.length, key.indexOf(':enabled'));
      const methodDetails = { method };
      const carriersKey = `${prefix}${method}:carriers`;
      const carriers = userInput[carriersKey];

      if (carriers) {
        try {
          if (Array.isArray(carriers)) {
            if (!Array.isArray(carriers)) {
              return console.error('Carriers data is not an array:', carriers);
            }
            methodDetails.carriers = carriers
              .map(carrierString => JSON.parse(carrierString))
              .filter(carrier => carrier !== null);
          }
        } catch (error) {
          console.error('Error parsing carriers for method:', method, error);
        }
      }

      result.push(methodDetails);
    }
  });

  return result;
};
