import 'isomorphic-unfetch';
import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-hooks';
import { IntlProvider } from 'react-intl';
import { NoflakeProvider } from './hooks/useNoflake';
import configureStore from './store/configureStore';
import { Error } from './components';
import apolloClient from './graphql/apolloClient';
import theme from './theme';
import './i18n';

const reduxState = window.__INITIAL_STATE__;
const history = createBrowserHistory();
const store = configureStore(history, reduxState);
const container = document.getElementById('app');

const bugsnagClient = bugsnag(process.env.BUGSNAG_API_KEY);
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const renderApp = () => {
  const App = require('./components/App').default; // eslint-disable-line global-require

  hydrate(
    <ErrorBoundary FallbackComponent={Error}>
      <ApolloProvider client={apolloClient}>
        <StylesProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Provider store={store}>
              <IntlProvider locale="en">
                <NoflakeProvider
                  value={{ noflakeUrl: process.env.NOFLAKE_URL }}
                >
                  <ConnectedRouter history={history}>
                    <App />
                  </ConnectedRouter>
                </NoflakeProvider>
              </IntlProvider>
            </Provider>
          </ThemeProvider>
        </StylesProvider>
      </ApolloProvider>
    </ErrorBoundary>,
    container,
  );
};

renderApp();

if (module.hot) {
  module.hot.accept();
}
