import moment from 'moment';
import { API_DATE_FORMAT } from '../../constants/dateFormats';
import {
  RECEIVE_EDD_PREVIEW_LIST,
  RECEIVE_EDD_PREVIEW_DEFINITION,
  RECEIVE_EDD_PREVIEW_DETAILS,
  EDD_PREVIEW_TOGGLE_PREVIEW_TYPE,
  EDD_PREVIEW_RECEIVE_ERROR,
  EDD_PREVIEW_DISMISS_ERROR,
  EDD_PREVIEW_UPDATE_FORM,
} from '~src/actions/platform/eddPreview';
import countryList from '~src/lib/countryList';

export const initialState = {
  alert: {
    show: false,
  },
  isOrderView: true,
  eddPreviewForm: {
    date: moment().format(API_DATE_FORMAT),
    time: '12:00 AM',
    timezone: 'America/Los_Angeles',
  },
  eddPreviewList: [],
  eddPreviewListMeta: {},
  eddPreviewDetails: {},
  eddPreviewDetailsMeta: {},
  productCategories: [],
  distributionCenters: [],
  carriers: [],
  serviceCodes: {},
  originCountry: countryList.slice(),
  destinationCountry: countryList.slice(),
};

export default (state = initialState, action = {}) => {
  const { payload = {}, type } = action;
  const {
    productCategories,
    distributionCenters,
    carriers,
    serviceCodes,
    content,
    isOrderView,
    eddPreviewListMeta,
    eddPreviewDetailsMeta,
    errorMessage,
    formKey,
    formValue,
  } = payload;

  switch (type) {
    case RECEIVE_EDD_PREVIEW_DETAILS:
      return {
        ...state,
        eddPreviewDetails: content,
        eddPreviewDetailsMeta,
      };

    case RECEIVE_EDD_PREVIEW_DEFINITION:
      return {
        ...state,
        productCategories,
        distributionCenters,
        carriers,
        serviceCodes,
      };

    case RECEIVE_EDD_PREVIEW_LIST:
      return {
        ...state,
        eddPreviewList: content,
        eddPreviewListMeta,
      };

    case EDD_PREVIEW_TOGGLE_PREVIEW_TYPE:
      return {
        ...state,
        isOrderView,
        eddPreviewForm: initialState.eddPreviewForm,
      };

    case EDD_PREVIEW_RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: true,
          text: errorMessage,
          color: 'danger',
        },
      };

    case EDD_PREVIEW_DISMISS_ERROR:
      return {
        ...state,
        alert: initialState.alert,
      };

    case EDD_PREVIEW_UPDATE_FORM:
      return {
        ...state,
        eddPreviewForm: {
          ...state.eddPreviewForm,
          [formKey]: formValue,
        },
      };

    default:
      return state;
  }
};
