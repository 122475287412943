import { invalidateCacheTable } from '~src/actions/cacheActions';
import { push } from 'connected-react-router';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';
import {
  createRequestData,
  flattenRetailerSettings,
} from '~src/reducers/experienceManagerHelper';
import { memorySizeOf } from '~src/lib/helpers';
import {
  EMPTY_MARKETING_GRID_ASSET,
  TRACKING_PANELS,
  TRACKING_IMG_PANELS,
} from '~src/constants/experienceManager';

export const REQUEST_ASSETS = 'REQUEST_ASSETS';
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS';
export const RECEIVE_CSS_FILES = 'RECEIVE_CSS_FILES';
export const RECEIVE_TRACKING_IMG_SETTINGS = 'RECEIVE_TRACKING_IMG_SETTINGS';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ASSET_MODAL = 'DISMISS_ASSET_MODAL';
export const PUBLISH_ASSETS = 'PUBLISH_ASSETS';
export const REQUEST_IMAGE_UPLOAD = 'REQUEST_IMAGE_UPLOAD';
export const RECEIVE_IMAGE_UPLOAD = 'RECEIVE_IMAGE_UPLOAD';
export const REQUEST_FONT_UPLOAD = 'REQUEST_FONT_UPLOAD';
export const RECEIVE_FONT_UPLOAD = 'RECEIVE_FONT_UPLOAD';
export const REQUEST_FILE_UPLOAD = 'REQUEST_FILE_UPLOAD';
export const RECEIVE_FILE_UPLOAD = 'RECEIVE_FILE_UPLOAD';
export const RECEIVE_SETTINGS_UPLOAD = 'RECEIVE_SETTINGS_UPLOAD';
export const RECEIVE_TRANSLATIONS_UPLOAD = 'RECEIVE_TRANSLATIONS_UPLOAD';
export const REQUEST_ASSETS_SAVE = 'REQUEST_ASSETS_SAVE';
export const RECEIVE_ASSETS_SAVE = 'RECEIVE_ASSETS_SAVE';
export const RECEIVE_TRACKING_ASSETS_SAVE = 'RECEIVE_TRACKING_ASSETS_SAVE';
export const RECEIVE_TRACKING_IMG_SETTINGS_SAVE =
  'RECEIVE_TRACKING_IMG_SETTINGS_SAVE';
export const REQUEST_ASSETS_PUBLISH = 'REQUEST_ASSETS_PUBLISH';
export const RECEIVE_ASSETS_PUBLISH = 'RECEIVE_ASSETS_PUBLISH';
export const RECEIVE_TRACKING_ASSETS_PUBLISH =
  'RECEIVE_TRACKING_ASSETS_PUBLISH';
export const RECEIVE_TRACKING_IMG_SETTINGS_PUBLISH =
  'RECEIVE_TRACKING_IMG_SETTINGS_PUBLISH';
export const RECEIVE_RETAILER_SETTINGS_JSON_UPLOAD =
  'RECEIVE_RETAILER_SETTINGS_JSON_UPLOAD';
export const CHANGE_DETECTED = 'CHANGE_DETECTED';
export const RESET_MARKETING_GRID = 'RESET_MARKETING_GRID';
export const RECEIVE_CONFIGURATIONS_LIST = 'RECEIVE_CONFIGURATIONS_LIST';
export const RECEIVE_CONFIGURATION_DETAILS = 'RECEIVE_CONFIGURATION_DETAILS';
export const UPDATE_CONFIGURATION_SELECTED_CONDITION =
  'UPDATE_CONFIGURATION_SELECTED_CONDITION';
export const RECEIVE_DEFAULT_CONDITIONED_CONFIGURATION_ASSETS =
  'RECEIVE_DEFAULT_CONDITIONED_CONFIGURATION_ASSETS';
export const RECEIVE_ASSETS_SAVED_CONDITION_DUPLICATED =
  'RECEIVE_ASSETS_SAVED_CONDITION_DUPLICATED';
export const TOGGLE_DELETE_CONFIRM_MODAL = 'TOGGLE_DELETE_CONFIRM_MODAL';
export const RECEIVE_SUCCESS_MESSAGE = 'RECEIVE_SUCCESS_MESSAGE';
export const RECEIVE_TRACK_CHAT_ICON_UPLOAD = 'RECEIVE_TRACK_CHAT_ICON_UPLOAD';

import * as ENDPOINTS from '~src/constants/endpoints';

// Function to sort the marketing banner_ad array
// switch the default banner_ad to be the first element
const sortBannerAds = retailerSettings => {
  const {
    features: { marketing_ads: { banner_ad: bannerAd } = {} } = {},
  } = retailerSettings;
  if (!bannerAd) return retailerSettings;
  const sortedBannerAd = [...bannerAd].sort(
    (
      { start_date: aStart, end_date: aEnd },
      { start_date: bStart, end_date: bEnd },
    ) => {
      if (!(aStart || aEnd) && (bStart || bEnd)) {
        return -1;
      }
      if (!(bStart || bEnd) && (aStart || aEnd)) {
        return 1;
      }
      return 0;
    },
  );

  return {
    ...retailerSettings,
    features: {
      ...retailerSettings.features,
      marketing_ads: {
        ...retailerSettings.features.marketing_ads,
        banner_ad: sortedBannerAd,
      },
    },
  };
};

const formConfigurationConditionParamForInvalidation = conditionParams => {
  const param = Object.keys(conditionParams)
    .filter(key => conditionParams[key] && key !== 'locale')
    .map(key => `${key}=${conditionParams[key]}`)
    .join(',');
  return param || 'none';
};

const formConfigurationConditionParam = (
  conditionParams,
  addNewConfiguration,
) => {
  const param = Object.keys(conditionParams)
    .filter(key => conditionParams[key] && key !== 'locale')
    .map(key => `${key}=${conditionParams[key]}`)
    .join(',');

  return `locale=${conditionParams.locale ||
    'en_US'}&condition=${encodeURIComponent(param) || 'none'}${
    addNewConfiguration ? '&addConfiguration=true' : ''
  }`;
};

export const containsTrackingPanels = panel => {
  return TRACKING_PANELS.includes(panel) || panel.startsWith('marketing_grid_');
};

export const containsTrackingImgPanels = panel => {
  return TRACKING_IMG_PANELS.includes(panel);
};

export const receiveErrors = errors => {
  return {
    type: RECEIVE_ERRORS,
    meta: { errors },
  };
};

export const receiveSuccess = successText => {
  return {
    type: RECEIVE_SUCCESS_MESSAGE,
    meta: { successText },
  };
};

export const dismissAlert = () => ({
  type: DISMISS_ALERT,
});

export const dismissAssetModal = () => ({
  type: DISMISS_ASSET_MODAL,
});

export const toggleDeleteConfirmModal = () => ({
  type: TOGGLE_DELETE_CONFIRM_MODAL,
});

export const triggerChangeDetected = (panel = null, settings) => ({
  type: CHANGE_DETECTED,
  meta: { panel, settings },
});

export const resetMarketingGrid = newLength => {
  const grid = [...Array(newLength)].map((grid, index) =>
    Object.assign({}, EMPTY_MARKETING_GRID_ASSET),
  );

  return {
    type: RESET_MARKETING_GRID,
    meta: { grid },
  };
};

export const receiveAssets = (
  json,
  fileSize,
  localeCode,
  uriMoniker,
  dateModified,
) => {
  return {
    type: RECEIVE_ASSETS,
    payload: json,
    meta: { localeCode, uriMoniker, dateModified, fileSize },
  };
};

export const receiveCssFiles = cssFiles => {
  return {
    type: RECEIVE_CSS_FILES,
    payload: cssFiles,
  };
};

export const receiveTrackingImgSettings = trackingImgObj => {
  return {
    type: RECEIVE_TRACKING_IMG_SETTINGS,
    payload: trackingImgObj,
  };
};

export const receiveAssetsPublish = status => {
  return {
    type: RECEIVE_ASSETS_PUBLISH,
    payload: status,
  };
};

export const receiveTrackingAssetsPublish = status => {
  return {
    type: RECEIVE_TRACKING_ASSETS_PUBLISH,
    payload: status,
  };
};

export const receiveTrackingImgSettingsPublish = payload => {
  return {
    type: RECEIVE_TRACKING_IMG_SETTINGS_PUBLISH,
    payload,
  };
};

export const requestImageUpload = (gridPos, device) => ({
  type: REQUEST_IMAGE_UPLOAD,
  meta: { gridPos, device },
});

export const receiveImageUpload = (payload, meta) => {
  return { type: RECEIVE_IMAGE_UPLOAD, payload, meta };
};

export const recieveTrackChatIconUpload = (payload, meta) => {
  return { type: RECEIVE_TRACK_CHAT_ICON_UPLOAD, payload, meta };
};

export const receiveFileUpload = (payload, meta) => {
  return { type: RECEIVE_FILE_UPLOAD, payload, meta };
};

export const receiveRetailerSettingsJsonUpload = payload => {
  return { type: RECEIVE_RETAILER_SETTINGS_JSON_UPLOAD, payload };
};

export const receiveSettingsUpload = (
  fileName,
  retailerSettingsUpload,
) => dispatch => {
  dispatch(
    receiveRetailerSettingsJsonUpload({
      retailerSettings: flattenRetailerSettings(retailerSettingsUpload),
      _retailerSettings: retailerSettingsUpload,
    }),
  );
  dispatch({
    type: RECEIVE_SETTINGS_UPLOAD,
    payload: {
      fileName,
      retailerSettingsUpload,
    },
  });
};

export const receiveTranslationsUpload = (fileName, translationsUpload) => {
  return {
    type: RECEIVE_TRANSLATIONS_UPLOAD,
    payload: {
      fileName,
      translationsUpload,
    },
  };
};

export const receiveTrackingAssetsSave = (status, json) => {
  return {
    type: RECEIVE_TRACKING_ASSETS_SAVE,
    preview_url: json.preview_url,
  };
};

export const receiveTrackingImgSettingsSave = (status, json) => {
  return {
    type: RECEIVE_TRACKING_IMG_SETTINGS_SAVE,
    preview_url: json.preview_url,
  };
};

export const requestAssetsSave = cmsObj => ({
  type: REQUEST_ASSETS_SAVE,
  cmsObj,
});

export const receiveAssetsSave = () => {
  return {
    type: RECEIVE_ASSETS_SAVE,
  };
};

export const fetchSaveTrackingAssets = (
  fetchSaveTrackingAssetsOnly = false,
) => (dispatch, getState) => {
  const state = getState();
  const experienceManagerState = state.experienceManagerReducer;
  const configurationsState = experienceManagerState.configurations;
  const configurationCondition =
    configurationsState.configurationDetails.conditionSelected;
  const addNewConfig = configurationsState.isAdding;
  const currentLocaleCode = experienceManagerState.currentLocale.locale_code;
  const isCustomerSupport = state.userReducer.user.customerSupportLogin;
  const basePath = isCustomerSupport
    ? ENDPOINTS.CSM_TRACKING_ASSETS_SAVE
    : ENDPOINTS.TRACKING_ASSETS_SAVE;

  const path = configurationCondition
    ? `${basePath}?${formConfigurationConditionParam(
        configurationCondition,
        addNewConfig,
      )}`
    : `${basePath}?locale=${currentLocaleCode}`;

  const body = JSON.stringify(
    createRequestData(experienceManagerState, isCustomerSupport),
  );

  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path,
    body,
    name: 'fetchSaveTrackingAssets',
  })
    .then(json => {
      dispatch(receiveTrackingAssetsSave(body, json));
      if (fetchSaveTrackingAssetsOnly) {
        if (json.conditionDuplitcated) {
          dispatch(receiveAssetsSavedConditionDuplicated());
        } else {
          dispatch(receiveAssetsSave(json));
        }
      }
    })
    .catch(error => {
      dispatch(
        receiveErrors(
          error.description
            ? error.description[0]
            : 'Sorry, there was a problem uploading your image.',
        ),
      );
    });
};

export const fetchSaveTrackingImg = (fetchSaveTrackingImgOnly = false) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const experienceManagerState = state.experienceManagerReducer;
  let updatedTrackingImgSettings = {
    ...experienceManagerState.trackingImg.settings,
  };
  experienceManagerState.trackingImg.assets.map(asset => {
    updatedTrackingImgSettings[asset.src] = asset.url;
  });
  const body = JSON.stringify({
    tracking_img: updatedTrackingImgSettings,
  });

  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.TRACKING_IMG_SETTINGS_SAVE,
    body,
    name: 'fetchSaveTrackingImg',
  })
    .then(json => {
      dispatch(receiveTrackingImgSettingsSave(body, json));
      if (fetchSaveTrackingImgOnly) {
        dispatch(receiveAssetsSave(json));
      }
    })
    .catch(error => {
      dispatch(
        receiveErrors(
          error.description
            ? error.description[0]
            : 'Sorry, there was a problem saving the Tracking Image Settings.',
        ),
      );
    });
};

export const saveAssets = () => (dispatch, getState) => {
  const { experienceManagerReducer } = getState();
  const { panelsModified } = experienceManagerReducer;
  const trackingSettingsModified = panelsModified.some(containsTrackingPanels);
  const trackingImgModified = panelsModified.some(containsTrackingImgPanels);

  if (trackingSettingsModified && trackingImgModified) {
    Promise.all([
      dispatch(fetchSaveTrackingAssets()),
      dispatch(fetchSaveTrackingImg()),
    ]).then(json => {
      dispatch(receiveAssetsSave(json));
    });
  } else if (trackingImgModified) {
    dispatch(fetchSaveTrackingImg(true));
  } else {
    dispatch(fetchSaveTrackingAssets(true));
  }
};

export const fetchUploadFont = (
  fileIndex,
  selectedFile,
  font,
  file,
  newFontName,
  isCustomerSupport,
) => dispatch => {
  const body = font;
  const basePath = isCustomerSupport
    ? ENDPOINTS.CSM_TRACKING_ASSETS_SAVE
    : ENDPOINTS.TRACKING_ASSETS_SAVE;
  const path = `${basePath}/assets`;

  dispatch({ type: REQUEST_FONT_UPLOAD, meta: { file, newFontName } });

  dispatch({
    type: API_REQUEST,
    contentType: false,
    method: 'POST',
    path,
    body,
    name: 'fetchUploadFont',
  }).then(
    json => {
      dispatch({
        type: RECEIVE_FONT_UPLOAD,
        payload: json.asset_url,
        meta: {
          file,
          newFontName,
          fileIndex,
          selectedFile,
        },
      });
    },
    err => {
      console.error(err);
      dispatch(
        receiveErrors('Sorry, there was a problem uploading your font.'),
      );
    },
  );
};

export const uploadTrackChatIcon = (
  iconForm,
  currentLocaleCode,
  originalName,
) => dispatch => {
  const body = iconForm;
  const meta = { originalName };
  const path = `${ENDPOINTS.TRACKING_ASSETS_UPLOAD}?locale=${currentLocaleCode}&type=image`;

  dispatch({
    type: API_REQUEST,
    body,
    contentType: false,
    path,
    method: 'POST',
    name: 'uploadImageAsset',
  }).then(
    json => {
      dispatch(recieveTrackChatIconUpload(json, meta));
    },
    err => {
      console.error(err);
      dispatch(
        receiveErrors(
          err.description
            ? err.description[0]
            : 'Sorry, there was a problem uploading your icon.',
        ),
      );
    },
  );
};

export const uploadImageAsset = (
  image,
  gridPos,
  device,
  currentLocale,
  isCustomerSupport,
  clickCategory,
) => dispatch => {
  const body = image;
  const path = `${ENDPOINTS.TRACKING_ASSETS_UPLOAD}?locale=${currentLocale}&type=image`;

  dispatch(requestImageUpload(gridPos, device));

  dispatch({
    type: API_REQUEST,
    body,
    contentType: false,
    path,
    method: 'POST',
    name: 'uploadImageAsset',
  }).then(
    json => {
      dispatch(
        receiveImageUpload(json, {
          clickCategory,
          gridPos,
          device,
        }),
      );
    },
    err => {
      console.error(err);
      dispatch(
        receiveErrors(
          err.description
            ? err.description[0]
            : 'Sorry, there was a problem uploading your image.',
        ),
      );
    },
  );
};

export const fetchPublishTrackingImg = (
  date_modified_p,
  fetchPublishTrackingImgOnly = false,
) => async dispatch => {
  const body = JSON.stringify({ date_modified: date_modified_p });
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: ENDPOINTS.TRACKING_IMG_SETTINGS_PUBLISH,
      body,
      name: 'fetchPublishTrackingImg',
    });
    dispatch(receiveTrackingImgSettingsPublish(json));
    if (fetchPublishTrackingImgOnly) {
      dispatch(receiveAssetsPublish(json));
    }
  } catch (err) {
    dispatch(
      receiveErrors(
        'Sorry, there was a problem publishing your tracking image settings.',
      ),
    );
  }
};

export const fetchPublishTrackingAssets = (
  date_modified_p,
  currentLocale,
  isCustomerSupport,
  fetchPublishTrackingAssetsOnly = false,
) => (dispatch, getState) => {
  const {
    experienceManagerReducer,
    userReducer: { user: { current_retailer_id: currentRetailerId } = {} } = {},
  } = getState();
  const configurationsState = experienceManagerReducer.configurations;
  const configurationCondition =
    configurationsState.configurationDetails.conditionSelected;
  const addNewConfig = configurationsState.isAdding;
  const configurationParam = configurationsState.requestParam;
  const body = JSON.stringify({ date_modified: date_modified_p });
  const basePath = isCustomerSupport
    ? ENDPOINTS.CSM_TRACKING_ASSETS_PUBLISH
    : ENDPOINTS.TRACKING_ASSETS_PUBLISH;
  const path = configurationCondition
    ? `${basePath}?${formConfigurationConditionParam(
        configurationCondition,
        addNewConfig,
      )}`
    : `${basePath}?locale=${currentLocale}`;

  dispatch({
    type: API_INVALIDATE_PATH,
    path: configurationParam
      ? `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}?${configurationParam}`
      : ENDPOINTS.CSM_TRACKING_ASSETS_SAVE,
  });
  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.TRACKING_ASSETS_SAVE,
  });
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.TRACKING_ASSETS_SAVE}?locale=${currentLocale}`,
  });
  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.RETAILER_CONFIGURATIONS_LIST,
  });
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path,
    body,
    name: 'fetchPublishTrackingAssets',
  })
    .then(json => {
      dispatch(receiveTrackingAssetsPublish(json));
      if (fetchPublishTrackingAssetsOnly) {
        dispatch(receiveAssetsPublish(json));
      }
      const invalidationLocale =
        (configurationCondition && configurationCondition.locale) ||
        currentLocale;
      if (invalidationLocale) {
        const condition = configurationCondition
          ? formConfigurationConditionParamForInvalidation(
              configurationCondition,
            )
          : 'none';
        const [languageCode, countryCode] = invalidationLocale.split('_');
        dispatch(
          invalidateCacheTable({
            type: 'update',
            table: 'translation_workbench',
            primary_key: 'retailer_id:language:country:product:condition',
            key_value: `${currentRetailerId}:${languageCode}:${countryCode}:tracking:${condition}`,
          }),
        );
      }
    })
    .catch(err =>
      dispatch(
        receiveErrors('Sorry, there was a problem publishing your assets.'),
      ),
    );
};

export const publishAssets = (
  date_modified_p,
  currentLocale,
  isCustomerSupport,
) => (dispatch, getState) => {
  const { experienceManagerReducer } = getState();
  const { panelsSaved } = experienceManagerReducer;
  const trackingSettingsSaved = panelsSaved.some(containsTrackingPanels);
  const trackingImgSaved = panelsSaved.some(containsTrackingImgPanels);
  if (trackingSettingsSaved && trackingImgSaved) {
    Promise.all([
      dispatch(fetchPublishTrackingImg(date_modified_p)),
      dispatch(
        fetchPublishTrackingAssets(
          date_modified_p,
          currentLocale,
          isCustomerSupport,
        ),
      ),
    ]).then(json => dispatch(receiveAssetsPublish()));
  } else if (trackingImgSaved) {
    dispatch(fetchPublishTrackingImg(date_modified_p, true));
  } else {
    dispatch(
      fetchPublishTrackingAssets(
        date_modified_p,
        currentLocale,
        isCustomerSupport,
        true,
      ),
    );
  }
};

export const fetchAssets = (
  currentLocale = null,
  requestParam = '',
) => dispatch => {
  const path = `${
    requestParam
      ? ENDPOINTS.CSM_TRACKING_ASSETS_SAVE
      : ENDPOINTS.TRACKING_ASSETS_SAVE
  }${requestParam}${currentLocale ? `?locale=${currentLocale}` : ''}`;
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path,
    name: 'fetchAssets',
  }).then(json => {
    if (json.status_code === 200) {
      const cssFiles = {
        customCss: json.custom_css,
        fontCss: json.font_css,
        headerCss: json.header_css,
      };
      const parsedRetailerSettings = sortBannerAds(
        JSON.parse(json.retailer_settings),
      );
      const retailerSettingsFileSize = memorySizeOf(parsedRetailerSettings);
      dispatch(
        receiveAssets(
          {
            retailerSettings: flattenRetailerSettings(parsedRetailerSettings),
            translations: json.translations
              ? JSON.parse(json.translations)
              : {},
            translationsCsv: json.translations_csv,
            _retailerSettings: parsedRetailerSettings,
          },
          retailerSettingsFileSize,
          currentLocale,
          json.uri_moniker,
          json.date_modified,
        ),
      );

      if (requestParam) {
        dispatch(
          receiveConfigurationDetails(
            json.configuration_details,
            requestParam.match(/\?(.*)/)[1],
          ),
        );
      }
      dispatch(receiveCssFiles(cssFiles));
    }
  });

  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TRACKING_IMG_SETTINGS_SAVE,
    name: 'fetchAssets',
  }).then(json => {
    if (json.status.toLowerCase() === 'success') {
      dispatch(receiveTrackingImgSettings(json.tracking_img));
    }
  });
};

export const fecthConfigurationsList = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.RETAILER_CONFIGURATIONS_LIST,
    name: 'fetchConfigurationsList',
  }).then(json => {
    dispatch(receiveConfigurationsList(json));
  });
};

export const receiveConfigurationsList = payload => dispatch => {
  dispatch({
    type: RECEIVE_CONFIGURATIONS_LIST,
    payload,
  });
};

export const receiveConfigurationDetails = (payload, param) => dispatch => {
  dispatch({
    type: RECEIVE_CONFIGURATION_DETAILS,
    payload,
    meta: {
      param,
    },
  });
};

export const invalidateConfigurationsListPath = () => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.RETAILER_CONFIGURATIONS_LIST,
  });
};

export const deleteConfiguration = configurationCondition => async (
  dispatch,
  getState,
) => {
  try {
    const {
      userReducer: {
        user: { current_retailer_id: currentRetailerId } = {},
      } = {},
    } = getState();
    const path = `?product=tracking&${formConfigurationConditionParam(
      configurationCondition,
      false,
    )}`;
    await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.CARRIER_CONFIGURATION_DELETE}${path}`,
      name: 'deleteConfiguration',
    });
    await dispatch(invalidateConfigurationsListPath());
    dispatch(push('/track/configurations'));
    const invalidationLocale = configurationCondition.locale;
    if (invalidationLocale) {
      const condition = configurationCondition
        ? formConfigurationConditionParamForInvalidation(configurationCondition)
        : 'none';
      const [languageCode, countryCode] = invalidationLocale.split('_');
      await dispatch(
        invalidateCacheTable({
          type: 'remove',
          table: 'translation_workbench',
          primary_key: 'retailer_id:language:country:product:condition',
          key_value: `${currentRetailerId}:${languageCode}:${countryCode}:tracking:${condition}`,
        }),
      );
    }
    dispatch(receiveSuccess('Configuration deleted successfully.'));
  } catch (err) {
    dispatch(
      receiveErrors(
        'Something went wrong. Configuration not able to be deleted.',
      ),
    );
  }
  dispatch(toggleDeleteConfirmModal());
};

export const updateConfigurationSelectedCondition = (
  conditionType,
  newCondition,
) => async dispatch => {
  await dispatch({
    type: UPDATE_CONFIGURATION_SELECTED_CONDITION,
    meta: {
      conditionType,
      newCondition,
    },
  });
  dispatch(fetchDefaultConditionedConfigurationAssets());
};

export const fetchDefaultConditionedConfigurationAssets = () => (
  dispatch,
  getState,
) => {
  const state = getState();
  const experienceManagerState = state.experienceManagerReducer;
  const configurationsState = experienceManagerState.configurations;
  const configurationCondition =
    configurationsState.configurationDetails.conditionSelected;
  const addNewConfig = configurationsState.isAdding;

  const path = `${
    ENDPOINTS.CSM_TRACKING_ASSETS_SAVE
  }?${formConfigurationConditionParam(configurationCondition, addNewConfig)}`;

  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path,
    name: 'fetchDefaultConditionedConfigurationAsset',
  }).then(json => {
    if (json.status_code === 200) {
      const parsedRetailerSettings = JSON.parse(json.retailer_settings);
      const retailerSettingsFileSize = memorySizeOf(parsedRetailerSettings);
      const parsedTranslations = JSON.parse(json.translations);
      const translationsFileSize = memorySizeOf(parsedTranslations);
      const translationsCsvFileSize = memorySizeOf(json.translations_csv);
      dispatch(
        receiveDefaultConditionedConfigurationAssets(
          {
            retailerSettings: flattenRetailerSettings(parsedRetailerSettings),
            translations: parsedTranslations,
            translationsCsv: json.translations_csv,
            _retailerSettings: parsedRetailerSettings,
          },
          retailerSettingsFileSize,
          translationsFileSize,
          translationsCsvFileSize,
          json.uri_moniker,
          json.date_modified,
        ),
      );
    }
  });
};

export const receiveDefaultConditionedConfigurationAssets = (
  json,
  retailerSettingsFileSize,
  translationsFileSize,
  translationsCsvFileSize,
  uriMoniker,
  dateModified,
) => dispatch => {
  dispatch({
    type: RECEIVE_DEFAULT_CONDITIONED_CONFIGURATION_ASSETS,
    payload: json,
    meta: {
      uriMoniker,
      dateModified,
      retailerSettingsFileSize,
      translationsFileSize,
      translationsCsvFileSize,
    },
  });
};

export const receiveAssetsSavedConditionDuplicated = () => dispatch => {
  dispatch({
    type: RECEIVE_ASSETS_SAVED_CONDITION_DUPLICATED,
  });
};
