import objectPath from 'object-path';
import { API_INVALIDATE_PATH, API_REQUEST } from '../../../lib/api';
import { RETURNS_RULES_DEFINITIONS } from '../../../constants/endpoints';
import { returnDestinationPolicyDefinitions } from '../../../containers/ReturnsHeadlessConfig/PolicyBuilder/mocks';
import { RULE_TYPES } from '../../../constants/returns/returnRules';
import apolloClient from '../../../graphql/apolloClient';
import {
  GET_DCS_BY_RETAILER,
  GET_MODES,
} from '../../../graphql/queries/returns';
import { getReturnMultiCarrier } from '../../../containers/ReturnsHeadlessConfig/MultiCarrier/schema';
import { multiCarrierDefinitionMigration } from '../../../containers/ReturnsHeadlessConfig/MultiCarrier/migration';
import {
  apiErrors,
  RECEIVE_RETURNS_RULES_DEFINITIONS,
  receiveDCs,
  receiveModes,
} from '../../returnsRulesActions';

export const receiveDefinitions = definitions => ({
  type: RECEIVE_RETURNS_RULES_DEFINITIONS,
  definitions,
});

const versionRules = (rules, v2Defs) =>
  Object.keys(rules).reduce((acc, key) => {
    if (!v2Defs[key] || !v2Defs[key].schema) {
      acc[key] = {
        versioned: true,
        v1: rules[key],
      };
      return acc;
    }

    acc[key] = {
      versioned: true,
      v1: rules[key],
      v2: v2Defs[key].schema,
    };
    return acc;
  }, {});

export const fetchDefinitions = (
  { apiVersion = 1, retailerMoniker = '', customerSupportLogin = false } = {
    apiVersion: 1,
    retailerMoniker: '',
    customerSupportLogin: false,
  },
) => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDefinitions',
    path: `${RETURNS_RULES_DEFINITIONS}?version=${apiVersion}`,
  })
    .then(async json => {
      const dcPicker = returnDestinationPolicyDefinitions[
        RULE_TYPES.RETURN_DESTINATION_POLICY
      ].actionsDefinitions
        .map(e => e.category)
        .indexOf('return_dc');

      let returnDestinationPolicyRules = {};
      let returnMultiCarrier = {};
      let resDcs;
      let resModes;
      let dcs = [];
      let modes = {};
      let v2Defs = {};

      if (apiVersion !== 1) {
        returnMultiCarrier = {
          [RULE_TYPES.RETURN_METHODS_POLICY]: {
            versioned: true,
            v1: getReturnMultiCarrier({
              actions: multiCarrierDefinitionMigration({
                returnMethods: json.response.returns.rule_types.return_methods,
              }),
            }),
          },
        };
      }

      if (customerSupportLogin && apiVersion > 1) {
        try {
          resDcs = await apolloClient.query({
            query: GET_DCS_BY_RETAILER,
            variables: {
              retailerMoniker,
              ordering: {
                sort: 'NAME',
                direction: 'ASC',
              },
              countPerPage: 200,
              page: 1,
            },
            fetchPolicy: 'no-cache',
          });
        } catch (err) {
          console.error(err);
          apiErrors({ error: { fetchDcs: { hasError: true, message: err } } });
        }

        try {
          resModes = await apolloClient.query({
            query: GET_MODES,
            variables: {
              retailerMoniker,
              ordering: {
                sort: 'NAME',
                direction: 'ASC',
              },
              countPerPage: 200,
              page: 1,
            },
            fetchPolicy: 'no-cache',
          });
        } catch (err) {
          console.error(err);
          apiErrors({ error: { getModes: { hasError: true, message: err } } });
        }

        if (resDcs?.data) {
          let dcList = objectPath.get(resDcs, 'data.listDcs', []);
          if (!dcList) {
            dcList = [];
          }
          dcs = dcList
            .filter(({ supported_services: s = [] }) =>
              !s || !s.length ? false : s.includes('RETURNS'),
            )
            .map(({ name, retailer_dc_id: id }) => ({
              picklist_value: `${name} (${id})`,
              picklist_token: id,
            }));
          dispatch(receiveDCs(dcList));
        }

        if (resModes?.data) {
          const {
            destinationMode,
            returnMethodsMode,
            feesMode,
          } = objectPath.get(resModes, 'data.returns.policies');
          modes = {
            destinationMode,
            returnMethodsMode,
            feesMode,
          };
        }

        const destinationRuleset = returnDestinationPolicyDefinitions;
        destinationRuleset[
          RULE_TYPES.RETURN_DESTINATION_POLICY
        ].actionsDefinitions[dcPicker].values = dcs;

        try {
          const v2Urls = {
            [RULE_TYPES.RETURN_DESTINATION_POLICY]:
              '/api/returns/policies/ui_schema?policy_type=destinations_policy',
            [RULE_TYPES.RETURN_METHODS_POLICY]:
              '/api/returns/policies/ui_schema?policy_type=return_methods_policy',
            [RULE_TYPES.RETURN_FEES]:
              '/api/returns/policies/ui_schema?policy_type=fees_policy',
          };

          const responses = await Promise.all(
            Object.entries(v2Urls).map(async ([key, url]) =>
              fetch(url)
                .then(async response => {
                  if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                  }
                  const jsonData = await response.json();
                  return [key, jsonData];
                })
                .catch(error => [
                  key,
                  {
                    schema: {
                      error,
                    },
                  },
                ]),
            ),
          );
          v2Defs = Object.fromEntries(responses);

          if (v2Defs?.[RULE_TYPES.RETURN_DESTINATION_POLICY]?.schema) {
            returnDestinationPolicyRules = {
              [RULE_TYPES.RETURN_DESTINATION_POLICY]: {
                versioned: true,
                v1: destinationRuleset[RULE_TYPES.RETURN_DESTINATION_POLICY],
                v2: v2Defs[RULE_TYPES.RETURN_DESTINATION_POLICY].schema,
              },
            };
          }

          if (v2Defs?.[RULE_TYPES.RETURN_METHODS_POLICY]?.schema) {
            returnMultiCarrier = {
              [RULE_TYPES.RETURN_METHODS_POLICY]: {
                versioned: true,
                v1: returnMultiCarrier[RULE_TYPES.RETURN_METHODS_POLICY].v1,
                v2: v2Defs[RULE_TYPES.RETURN_METHODS_POLICY].schema,
              },
            };
          }
        } catch (error) {
          console.log(error);
        }

        dispatch(receiveModes(modes));
      }

      const ruleTypes =
        apiVersion !== 1
          ? versionRules(json.response.returns.rule_types, v2Defs)
          : json.response.returns.rule_types;

      dispatch(
        receiveDefinitions({
          ...ruleTypes,
          ...returnDestinationPolicyRules,
          ...returnMultiCarrier,
        }),
      );

      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${RETURNS_RULES_DEFINITIONS}?version=${apiVersion}`,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch(
        apiErrors({
          error: { fetchDefinitions: { hasError: true, message: err } },
        }),
      );
    });
};
