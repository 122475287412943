import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import { push } from 'connected-react-router';

export const RECEIVE_RETURN_CARRIER_CREDENTIALS_LIST =
  'RECEIVE_RETURN_CARRIER_CREDENTIALS_LIST';
export const RECEIVE_RETURN_CARRIERS_LIST = 'RECEIVE_RETURN_CARRIERS_LIST';
export const RECEIVE_ADD_RETURN_CARRIER_CREDENTIALS_ERROR =
  'RECEIVE_ADD_RETURN_CARRIER_CREDENTIALS_ERROR';
export const RECEIVE_ADD_RETURN_CARRIER_CREDENTIALS =
  'RECEIVE_ADD_RETURN_CARRIER_CREDENTIALS';
export const RECEIVE_RETURN_CARRIER_CREDENTIAL =
  'RECEIVE_RETURN_CARRIER_CREDENTIAL';
export const RECEIVE_DELETE_RETURN_CARRIER_CREDENTIAL =
  'RECEIVE_DELETE_RETURN_CARRIER_CREDENTIAL';
export const RECEIVE_RETURN_CARRIER_CREDENTIAL_SUCCESS =
  'RECEIVE_RETURN_CARRIER_CREDENTIAL_SUCCESS';
export const RECEIVE_RETURN_CARRIER_CREDENTIAL_ERRORS =
  'RECEIVE_RETURN_CARRIER_CREDENTIAL_ERRORS';
export const RESET_CARRIER_CREDENTIAL = 'RESET_CARRIER_CREDENTIAL';
export const UPDATE_CARRIER = 'UPDATE_CARRIER';
export const RESET_FORM_VALUES_AND_ERRORS = 'RESET_FORM_VALUES_AND_ERRORS';

export const resetFormAndErrors = () => ({
  type: RESET_FORM_VALUES_AND_ERRORS,
});

export const receiveReturnCarrierCredentialErrors = (errors, carrier = {}) => ({
  type: RECEIVE_RETURN_CARRIER_CREDENTIAL_ERRORS,
  payload: { errors, carrier },
});

export const receiveReturnCarrierCredentialSuccess = (payload, message) => ({
  type: RECEIVE_RETURN_CARRIER_CREDENTIAL_SUCCESS,
  payload,
  meta: message,
});

export const fetchReturnCarriersList = () => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.CARRIERS,
      name: 'fetchReturnCarriersList',
    });

    if (response.status === 'error') {
      dispatch(receiveReturnCarrierCredentialErrors(response.errors));
      return;
    }
    dispatch({
      type: RECEIVE_RETURN_CARRIERS_LIST,
      payload: response,
    });
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err));
  }
};

export const fetchReturnCarrierCredential = carrierId => async dispatch => {
  try {
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CARRIERS}${carrierId}`,
    });
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CARRIERS}${carrierId}`,
      name: 'fetchReturnCarrierCredential',
    });
    await dispatch(fetchReturnCarriersList());
    if (response.status === 'error') {
      dispatch(receiveReturnCarrierCredentialErrors(response.errors));
      return;
    }
    const {
      selected_carrier_config: {
        mobile_api_credentials: { oauth_config: oauthConfig } = {},
      } = {},
    } = response;
    if (oauthConfig) {
      dispatch({
        type: RECEIVE_RETURN_CARRIER_CREDENTIAL,
        payload: {
          ...response,
          selected_carrier_config: {
            ...response.selected_carrier_config,
            mobile_api_credentials: {
              enabled: true,
              ...oauthConfig,
              custom_args: oauthConfig.custom_args
                ? Object.values(oauthConfig.custom_args).join()
                : undefined,
            },
          },
        },
      });
      return;
    }
    dispatch({
      type: RECEIVE_RETURN_CARRIER_CREDENTIAL,
      payload: response,
    });
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err));
  }
};

export const fetchDeleteReturnCarrierCredential = carrierId => async dispatch => {
  try {
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.CARRIERS,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CARRIER_CONFIGURATION}/returns`,
    });
    const response = await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.CARRIERS}${carrierId}`,
      name: 'fetchDeleteReturnCarrierCredential',
    });

    if (response.status === 'error') {
      dispatch(receiveReturnCarrierCredentialErrors(response.errors));
      return;
    }
    await dispatch(fetchReturnCarriersList());
    await dispatch(push('/return/credentials'));
    dispatch({
      type: RECEIVE_DELETE_RETURN_CARRIER_CREDENTIAL,
      payload: 'Carrier Config deleted successfully',
    });
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err.message));
  }
};

export const receiveReturnCarrierCredentialsList = payload => ({
  type: RECEIVE_RETURN_CARRIER_CREDENTIALS_LIST,
  payload,
});

export const updateCarrier = (carrier, services) => ({
  type: UPDATE_CARRIER,
  carrier,
  services,
});

export const fetchReturnCarrierCredentialsList = () => async dispatch => {
  try {
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CARRIER_CONFIGURATION}/returns`,
    });
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CARRIER_CONFIGURATION}/returns`,
      name: 'fetchReturnCarrierCredentialsList',
    });

    if (response.status === 'error') {
      dispatch(receiveReturnCarrierCredentialErrors(response.errors));
      return;
    }
    dispatch(receiveReturnCarrierCredentialsList(response));
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err));
  }
};

export const fetchAddReturnCarrierCredentials = carrierCredential => async dispatch => {
  try {
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.CARRIERS,
    });
    const response = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.CARRIERS,
      body: JSON.stringify({ ...carrierCredential }),
      name: 'fetchAddReturnCarrierCredentials',
    });
    if (response.status === 'error') {
      dispatch(
        receiveReturnCarrierCredentialErrors(
          response.errors,
          carrierCredential,
        ),
      );
      return;
    }
    dispatch(
      receiveReturnCarrierCredentialSuccess(
        response,
        'Carrier Config added successfully',
      ),
    );
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err.errors));
  }
};

export const fetchUpdateReturnCarrierCredential = (
  id,
  carrierCredential,
) => async dispatch => {
  try {
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.CARRIERS,
    });
    await dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CARRIER_CONFIGURATION}/returns`,
    });

    const response = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: ENDPOINTS.CARRIERS,
      body: JSON.stringify({
        ...carrierCredential,
        carrier_config: {
          ...carrierCredential.carrier_config,
          id,
        },
      }),
      name: 'fetchUpdateReturnCarrierCredential',
    });
    if (response.status === 'error') {
      dispatch(
        receiveReturnCarrierCredentialErrors(
          response.errors,
          carrierCredential,
        ),
      );
      return;
    }
    dispatch(
      receiveReturnCarrierCredentialSuccess(
        response,
        'Carrier Config updated successfully',
      ),
    );
  } catch (err) {
    dispatch(receiveReturnCarrierCredentialErrors(err.errors));
  }
};
