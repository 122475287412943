import React from 'react';
import PropTypes from 'prop-types';

export const XIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    style={{ width: '1.5rem', padding: '5px' }}
  >
    <g fill="none" stroke="red">
      <path d="M6.4 6.4l87.2 87.2" strokeWidth="18.1" />
      <path d="M6.4 93.6L93.8 6.4" strokeWidth="17.8" />
    </g>
  </svg>
);

export const TickIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    viewBox="0 0 100 100"
    style={{ width: '1.5rem', padding: '5px' }}
  >
    <path
      d="M91.9 4.4L48.3 86.7 5 58.2"
      stroke="green"
      strokeWidth="18.4"
      fill="none"
    />
  </svg>
);

const Notes = ({ notes }) => (
  <div>
    <h3>Notes</h3>
    <ul>
      {notes.map(note => (
        <li>{note}</li>
      ))}
    </ul>
  </div>
);

export const MigrationCard = ({
  title,
  validation = 'invalid',
  children,
  notes,
}) => (
  <div
    style={{
      display: 'flex',
      border: '1px solid',
      margin: '10px 0',
      padding: '10px',
      alignItems: 'start',
      borderRadius: '10px',
    }}
  >
    {validation === 'valid' && <TickIcon />}
    {validation === 'invalid' && <XIcon />}
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <h2 style={{ textTransform: 'capitalize' }}>{title}</h2>
      <div>{children}</div>
      {notes && <Notes notes={notes} />}
    </div>
  </div>
);

export const MigrationItems = ({ diff }) => (
  <div style={{ maxHeight: '500px', overflow: 'auto' }}>
    {diff.map(({ message }) => message)}
  </div>
);

MigrationItems.propTypes = {
  diff: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.node,
    }),
  ).isRequired,
};

MigrationCard.defaultProps = {
  notes: null,
};

MigrationCard.propTypes = {
  title: PropTypes.string.isRequired,
  validation: PropTypes.oneOf(['valid', 'invalid']).isRequired,
  children: PropTypes.node.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string),
};

Notes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
