exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* App z-index tracking */\n._1ffpC4ri0CCi-6Bzu1esi7 .btn-danger {\n  background-color: #e53935;\n  color: #ffffff; }\n  ._1ffpC4ri0CCi-6Bzu1esi7 .btn-danger:hover {\n    background: #CE332F; }\n", ""]);

// exports
exports.locals = {
	"DualActionModal": "_1ffpC4ri0CCi-6Bzu1esi7"
};