import { colors, fonts } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightPurple};
  box-shadow: 0 4px 11px -8px ${colors.darkGrey}, 0 0 4px 1px ${colors.lightBlue};
  border-top-width: 0;
  border-radius: 0 0 4px 4px;
  width: 100%;

  & ul {
    justify-content: center;
    margin: 0;

    & li {
      height: 40px;
      min-width: 40px;

      & span {
        height: 24px;
      }

      & .ellipsis {
        & a {
          cursor: default;
        }

        & a:hover {
          background-color: ${colors.white};
        }
        
        & a:active {
          color: ${colors.dark2};
        }
        
      }

      & .chevron-left {
        transform: rotate(180deg) scale(0.4);
      }

      & svg {
        transform: scale(0.4);
      }

      &:first-child,
      &:last-child {
        width: auto;
        padding: 0;
        height: 40px;
        width: 40px;

        & a {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          height: 100%;
        }
      }

      & a {
        border: 0;
        color: ${colors.dark2};
        border-radius: 0 !important;
        text-align: center;
        
      }

    }

  }

  & .page-item {
    line-height: none;

    & path {
      fill-opacity: 0.4;
      transition: fill-opacity .1s;
    }

    &:hover {
      background-color: ${colors.grayLight};

      & path {
        fill-opacity: .6;
      }
    }
    
    &.disabled {
      opacity: 0;
      
      & .page-link {
        border-color: transparent;
        color: ${colors.gray};
      }
      
    }
    
    &.active {
      
      & .page-link {
        background-color: ${colors.grayLight};
        border-bottom-color: ${colors.blue};
        color: ${colors.dark2};
        font-family: ${fonts.bold};
        
        &:active {
          color: ${colors.dark2};
        }
      }
      
    }
    
    &:active {
      color: ${colors.white};
      
      & path {
        fill: ${colors.white};
        fill-opacity: 1;
      }
    }
  }

  & .page-link {
    border-bottom: 2px solid transparent;
    cursor: pointer;
    padding: 0.75rem 0.75rem;

    &:hover {
      background-color: ${colors.grayLight};
      color: ${colors.dark2};
      text-decoration: none;
    }
    
    &:active {
      background-color: ${colors.blue};
    }
  }
`;

export default s;
