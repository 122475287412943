export const MESSAGING_LITE_EMAILS = 'messaging_lite';
export const MESSAGING_STANDARD_EMAIL = 'messaging_standard_email';
export const MESSAGING_STANDARD_SMS = 'messaging_standard_sms';

export const TIER_PRODUCT_MAPPING = {
  [MESSAGING_LITE_EMAILS]: ['emailEditor', 'emailAnalytics'],
  [MESSAGING_STANDARD_EMAIL]: ['emailEditor', 'emailAnalytics'],
  [MESSAGING_STANDARD_SMS]: ['smsEditor', 'smsAnalytics'],
};

export const TIER_TYPE = {
  [MESSAGING_LITE_EMAILS]: 'MESSAGING_LITE',
  [MESSAGING_STANDARD_EMAIL]: 'MESSAGING_STANDARD_EMAIL',
  [MESSAGING_STANDARD_SMS]: 'MESSAGING_STANDARD_SMS',
};
