import { LOCATION_CHANGE } from 'connected-react-router';
import { RECEIVE_HOLIDAYS, SHOW_ALERT } from '~src/actions/platform/holidays';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    show: false,
    color: 'info',
    text: '',
  },
  currentPage: 0,
  totalRecords: 0,
  items: [],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case LOCATION_CHANGE:
      return initialState;

    case RECEIVE_HOLIDAYS:
      return {
        ...state,
        items: payload.content,
        currentPage: payload.current_page_index,
        totalRecords: payload.total_records,
        totalPages: payload.total_pages,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          text: payload.text,
          color: payload.color,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    default:
      return state;
  }
};
