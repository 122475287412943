export const TOGGLE_NAVIGATION_MENU = 'TOGGLE_NAVIGATION_MENU';
export const SHOW_ALERT = 'SHOW_ALERT';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const DISMISS_ALERT_CARD = 'DISMISS_ALERT_CARD';

export const toggleNavigation = () => ({
  type: TOGGLE_NAVIGATION_MENU,
});

export const showAlert = ({ color, text }) => ({
  type: SHOW_ALERT,
  meta: { color, text },
});

export const dismissAlert = () => ({
  type: DISMISS_ALERT,
});

export const dismissAlertCard = () => ({
  type: DISMISS_ALERT_CARD,
});
