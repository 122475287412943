import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import PropTypes from 'prop-types';
import i18n from '../i18n';

const NarvarGrowthBookProvider = ({ children, growthbook }) => {
  const { user } = useSelector(({ userReducer }) => ({
    user: userReducer.user,
  }));

  const [lang, setLang] = useState(i18n.language);
  useEffect(() => {
    const sl = l => setLang(l);
    i18n.on('languageChanged', sl);
    return () => i18n.off('languageChanged', sl);
  }, [i18n]);

  if (user) {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      ...growthbookAttributes(user, lang),
    });
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      ...growthbookAttributes(user, lang),
    });
  }, [user, growthbook, lang]);

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

export default NarvarGrowthBookProvider;

function growthbookAttributes(user, i18nLanguage) {
  const {
    retailerIdToRetailerInfo: {
      [user.retailerId]: { uri_moniker: retailerMoniker = '' },
    },
  } = user;

  return {
    userId: String(user.id),
    retailer: retailerMoniker,
    hubLanguage: i18nLanguage,
  };
}

NarvarGrowthBookProvider.propTypes = {
  children: PropTypes.node.isRequired,
  growthbook: PropTypes.instanceOf(GrowthBook).isRequired,
};
