import React from 'react';
import PropTypes from 'prop-types';
import Svg from '../Svg';
import cx from 'classnames';
import s from './SvgButton.styles';

const SvgButton = ({
  svgType,
  buttonText,
  onClick,
  disabled,
}) => (
  <div
    className={cx(s, 'svgbutton')}
    onClick={onClick}
    onKeyPress={onClick}
    role="button"
    tabIndex="0"
    data-test={`${buttonText}-row`}
  >
    <div className="table-align-cell">
      <Svg
        className={cx('svgbutton-icon', { muted: disabled })}
        name={svgType}
        height={20}
        width={20}
      />
    </div>
    <div className={cx('table-align-cell', 'svgbutton-button', { muted: disabled })}>
      {buttonText}
    </div>
  </div>
);

SvgButton.propTypes = {
  svgType: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

SvgButton.defaultProps = {
  buttonText: '',
  onClick: null,
  disabled: false,
};

export default SvgButton;
