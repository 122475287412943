export const DATASET = 'analytics';
export const REPORT_ID = {
  EXCEPTIONS_TOP_ROW_SINGLE_QUERY: 'exceptions_top_row_single_query',
  DELIVERY_RATES: 'delivery_rates',
  NOTIFICATION_CLICKTHROUGH: 'notification_clickthrough',
  CTR_BREAKDOWN: 'ctr_breakdown',
  NDR_RESPONSE_PERCENTAGE: 'ndr_response_percentage',
  MEDIAN_NDR_RESPONSE_TIME: 'median_ndr_response_time',
  NDR_DELIVERY_PERCENTAGE: 'ndr_delivery_percentage',
  DELIVERED_NDRS: 'delivered_ndrs',
  ONTIME_DELIVERY_BREAKDOWN: 'ontime_delivery_breakdown',
};

// Exceptions Analytics Apis
const EXCEPTIONS_ANALYTICS_REPORTS_CONFIG = {
  [REPORT_ID.EXCEPTIONS_TOP_ROW_SINGLE_QUERY]: {
    metrics: [
      'count_distinct_ndr',
      'count_distinct_ndr_response',
      'count_distinct_ndr_delivered',
      'percent_rto',
      'sum_incremental_revenue',
    ],
    reportId: REPORT_ID.EXCEPTIONS_TOP_ROW_SINGLE_QUERY,
    precision: 'carrier_moniker',
  },
  [REPORT_ID.DELIVERY_RATES]: {
    metrics: [
      'count_distinct_shipments',
      'count_distinct_shipped',
      'count_distinct_delivered',
      'count_distinct_ndr',
      'count_distinct_rto',
    ],
    reportId: REPORT_ID.DELIVERY_RATES,
    precision: 'carrier_moniker',
    dimensions: 'day',
  },
  [REPORT_ID.NOTIFICATION_CLICKTHROUGH]: {
    metrics: [
      'count_distinct_sms_sent',
      'count_distinct_sms_clicked',
      'count_distinct_email_sent',
      'count_distinct_email_clicked',
    ],
    reportId: REPORT_ID.NOTIFICATION_CLICKTHROUGH,
    precision: 'carrier_moniker',
  },
  [REPORT_ID.CTR_BREAKDOWN]: {
    metrics: [
      'count_distinct_email',
      'count_distinct_sms',
      'count_distinct_csv',
    ],
    reportId: REPORT_ID.CTR_BREAKDOWN,
    precision: 'carrier_moniker',
  },
  [REPORT_ID.NDR_RESPONSE_PERCENTAGE]: {
    metrics: ['percent_email', 'percent_sms', 'percent_csv', 'percent_overall'],
    reportId: REPORT_ID.NDR_RESPONSE_PERCENTAGE,
    precision: 'carrier_moniker',
  },
  [REPORT_ID.MEDIAN_NDR_RESPONSE_TIME]: {
    metrics: ['sum_median_in_min'],
    reportId: REPORT_ID.MEDIAN_NDR_RESPONSE_TIME,
    precision: 'carrier_moniker',
    dimensions: 'type',
  },
  [REPORT_ID.NDR_DELIVERY_PERCENTAGE]: {
    metrics: ['percent_ndr_delivery'],
    reportId: REPORT_ID.NDR_DELIVERY_PERCENTAGE,
    precision: 'carrier_moniker',
    dimensions: 'day',
  },
  [REPORT_ID.DELIVERED_NDRS]: {
    metrics: ['percent_responded_delivery', 'percent_no_response_delivery'],
    reportId: REPORT_ID.DELIVERED_NDRS,
    precision: 'carrier_moniker',
  },
  [REPORT_ID.ONTIME_DELIVERY_BREAKDOWN]: {
    metrics: [
      'count_distinct_on_time_delivery',
      'count_distinct_one_day_late',
      'count_distinct_two_plus_days_late',
    ],
    reportId: REPORT_ID.ONTIME_DELIVERY_BREAKDOWN,
    precision: 'carrier_moniker',
  },
};

export const CARRIER_READABLE_KEY = 'carrier';
export const ALL_CARRIERS_VALUE = 'All Carriers';
export const ALL_CARRIERS_API_VALUE = 'all_carriers';
// TODO: Move this to a constant file which contains all the router paths
export const EXCEPTIONS_DETAILED_VIEW_PATH =
  '/notify/exceptions/analytics-details';

export const DEFAULT_DIVISOR = 100;

export default EXCEPTIONS_ANALYTICS_REPORTS_CONFIG;
