import {
  RECEIVE_WORKING_DAYS,
  RECEIVE_UPDATE_WORKING_DAYS,
  SHOW_ALERT,
} from '~src/actions/platform/workingDays';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    show: false,
    color: 'info',
    message: '',
  },
  items: [false, false, false, false, false, false],
};

export default (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_WORKING_DAYS:
      return {
        ...state,
        items: payload,
      };

    case RECEIVE_UPDATE_WORKING_DAYS:
      return {
        ...state,
        alert: {
          show: true,
          text: 'Your working days have been updated successfully',
          color: 'success',
        },
        items: payload,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          text: payload.text,
          color: payload.color,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    default:
      return state;
  }
};
