import { push } from 'connected-react-router';
import { API_REQUEST, API_INVALIDATE_PATH } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';

export const RECEIVE_EDD_RULES_INFO = 'RECEIVE_EDD_RULES_INFO';
export const REQUEST_EDD_RULES_INFO = 'REQUEST_EDD_RULES_INFO';
export const RECEIVE_EDD_RULES_INFO_PUBLISH = 'RECEIVE_EDD_RULES_INFO_PUBLISH';
export const RECEIVE_EDD_RULE_DELETE_SUCCESS =
  'RECEIVE_EDD_RULE_DELETE_SUCCESS';
export const RECEIVE_EDD_RULES_INFO_UPDATE = 'RECEIVE_EDD_RULES_INFO_UPDATE';
export const RECEIVE_EDD_RULES_INFO_ERROR = 'RECEIVE_EDD_RULES_INFO_ERROR';
export const RECEIVE_EDD_RULE_DATA = 'RECEIVE_EDD_RULE_DATA';
export const CLEAR_EDD_RULES_FORM_AND_ERRORS =
  'CLEAR_EDD_RULES_FORM_AND_ERRORS';

export const receiveEddRulesInfo = json => ({
  type: RECEIVE_EDD_RULES_INFO,
  payload: json.content,
  meta: {
    totalRecords: json.total_records,
    totalPages: json.total_pages,
    currentPageIndex: json.current_page_index,
    recordsPerPage: json.records_per_page,
  },
});

export const receiveEddRulesInfoError = messages => ({
  type: RECEIVE_EDD_RULES_INFO_ERROR,
  messages,
});

export const receiveEddRuleData = payload => ({
  type: RECEIVE_EDD_RULE_DATA,
  payload,
});
export const receiveEddRuleUpdate = payload => ({
  type: RECEIVE_EDD_RULES_INFO_UPDATE,
  payload,
});

export const resetFormAndErrors = () => ({
  type: CLEAR_EDD_RULES_FORM_AND_ERRORS,
});

export const receiveEddRulePublish = payload => ({
  type: RECEIVE_EDD_RULES_INFO_PUBLISH,
  payload,
});

export const fetchEddRulesInfo = ({
  recordsPerPage = 20,
  activePage = 1,
  keyword,
  searchBy,
}) => dispatch => {
  const query = {
    current_page_index: activePage ? activePage - 1 : 0,
    records_per_page: recordsPerPage,
    keyword,
    search_by: searchBy,
  };
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.EDD_RULES_INFO}`,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.EDD_RULES_INFO,
    name: 'fetchEddRulesInfo',
    query,
  })
    .then(json => {
      if (json.status === 'SUCCESS') {
        dispatch(receiveEddRulesInfo(json));
      } else {
        dispatch(
          receiveEddRulesInfoError(
            `An error occurred while fetching EDD Rules. ${json.messages[0]}`,
          ),
        );
      }
    })
    .catch(err => console.error(err));
};

export const fetchEddRuleData = ruleId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.EDD_RULES_INFO}/${ruleId}`,
    name: 'fetchEddRuleData',
  })
    .then(json => {
      if (json.status === 'SUCCESS') {
        dispatch(receiveEddRuleData(json.response));
      } else {
        dispatch(
          receiveEddRulesInfoError(
            `An error occurred while fetching rule. ${json.messages[0]}`,
          ),
        );
      }
    })
    .catch(err => console.error(err));

export const fetchPublishEddRuleInfo = rule => dispatch => {
  const body = JSON.stringify({ ...rule });
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    body,
    path: ENDPOINTS.EDD_RULES_INFO,
    name: 'fetchPublishEddRuleInfo',
  })
    .then(json => {
      const { status, response } = json;
      if (status === 'SUCCESS') {
        dispatch(push(`/promise/edd-rules/${response.id}`));
        dispatch(receiveEddRulePublish(response));
      } else {
        dispatch(
          receiveEddRulesInfoError(
            `An error occurred while publishing. ${json.messages[0]}`,
          ),
        );
      }
    })
    .catch(err =>
      dispatch(
        receiveEddRulesInfoError(
          `An error occurred while publishing. ${err.errors[0]}`,
        ),
      ),
    );
};

export const fetchDeleteEddRuleInfo = ruleId => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.EDD_RULES_INFO,
    });
    const { status, messages } = await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.EDD_RULES_INFO}/${ruleId}`,
      name: 'fetchDeleteEddRuleInfo',
    });
    if (status === 'SUCCESS') {
      dispatch(push('/promise/edd-rules'));
      dispatch({
        type: RECEIVE_EDD_RULE_DELETE_SUCCESS,
        meta: 'EDD Rule deleted successfully.',
      });
    } else if (status === 'FAILURE') {
      dispatch(
        receiveEddRulesInfoError(
          `An error occurred while deleting Rule. ${messages[0]}`,
        ),
      );
    }
  } catch (err) {
    dispatch(
      receiveEddRulesInfoError(`An error occurred while deleting Rule. ${err}`),
    );
  }
};

export const fetchUpdateEddRuleInfo = (ruleId, rule) => dispatch => {
  const body = JSON.stringify({
    ...rule,
  });
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    body,
    path: `${ENDPOINTS.EDD_RULES_INFO}/${ruleId}`,
    name: 'fetchUpdateEddRuleInfo',
  })
    .then(json => {
      if (json.status === 'SUCCESS') {
        dispatch(receiveEddRuleUpdate(json.response));
      } else {
        dispatch(
          receiveEddRulesInfoError(
            `An error occurred while updating the rule. ${json.messages[0]}`,
          ),
        );
      }
    })
    .catch(err =>
      dispatch(
        receiveEddRulesInfoError(
          `An error occurred while publishing. ${err.errors[0]}`,
        ),
      ),
    );
};
