import moment from 'moment';
import { buildQueryString } from '~src/lib/helpers';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';

export default ({
  columns,
  id,
  offset = 0,
  startDate,
  endDate,
  limit,
  orderBy = '"return_created_date" ASC',
  whereFilters = [],
  dataset,
}) => {
  const formattedStartDate = moment(startDate).format(API_DATE_FORMAT);
  const formattedEndDate = moment(endDate).format(API_DATE_FORMAT);
  const mappedWhereFilters = whereFilters
    .map(
      ({ filterKey, filterValue }) => `( "${filterKey}" eq '${filterValue}' )`,
    )
    .join(',');
  const whereQuery = mappedWhereFilters.length
    ? { where: mappedWhereFilters }
    : {};

  // Despite 'le', the query is still exclusive on the endDate
  return buildQueryString({
    offset,
    from: `"${id}"`,
    select: columns.map(field => `"${field.split('.')[1]}"`).join(),
    date_filter: `"return_created_date" ge ${formattedStartDate} and "return_created_date" le ${formattedEndDate}`,
    order_by: orderBy,
    ...whereQuery,
    ...(limit ? { limit } : {}),
    ...(dataset ? { dataset: `"${dataset}"` } : {}),
  });
};
