import { RECEIVE_METABASE_URL } from '~src/actions/metabaseAction';

const initialState = {
  urlMap: {
    overview: '',
    track: '',
    messaging: '',
    returns: '',
    ship: '',
    assist: '',
  },
};

function metabaseReducer(state = initialState, action) {
  const { type, url, dashboardType } = action;

  switch (type) {
    case RECEIVE_METABASE_URL:
      return {
        ...state,
        urlMap: {
          ...state.urlMap,
          [dashboardType]: url,
        },
      };

    default:
      return state;
  }
}

export default metabaseReducer;
