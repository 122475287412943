export const checkValidation = ({
  returnMethod,
  carrier,
  returnOptionsTemplate,
}) => {
  return Object.keys(returnOptionsTemplate.methods).includes(
    `${returnMethod}_${carrier}`,
  )
    ? 'valid'
    : 'invalidcarrier';
};

export const configOptions = ({ returnMethod, carrier, validation }) => {
  if (validation !== 'valid') return null;
  return {
    id: returnMethod,
    displayMethods: true,
    methods: [`${returnMethod}_${carrier}`],
  };
};

export const configMethods = ({
  returnMethod,
  carrier,
  validation,
  extraConfig,
}) => {
  if (validation !== 'valid') return null;
  return {
    [`${returnMethod}_${carrier}`]: {
      id: returnMethod,
      ...extraConfig,
    },
  };
};
