import { combineReducers } from 'redux';
import {
  DISMISS_ALERT,
  RECEIVE_TENANT,
  RECEIVE_PARENT_TENANT,
  RECEIVE_TENANT_ERROR,
  RECEIVE_TENANT_UPDATE,
  RECEIVE_TENANT_UPDATE_STATUS,
  RECEIVE_COMPANY_VIEW_DATA,
  RECEIVE_API_KEY,
  RECEIVE_RESET_API_KEY,
  RECEIVE_COMPANY_ERROR,
  RECEIVE_HAS_HMAC_TOKEN,
  RECEIVE_SET_HMAC_TOKEN,
  RECEIVE_HMAC_TOKEN_ERROR,
  RESET_TENANT_STATE,
  RECEIVE_TIER_1_SETUP_SUCCESS,
  RECEIVE_TIER_1_SETUP_ERROR,
  MESSAGING_ON_BOARDING_JOB_SUBMITTED,
} from '~src/actions/tenantActions';
import { RECEIVE_TENANTS_FOR_SEARCH } from '~src/actions/tenantsActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { RECEIVE_TENANT_ENTITLEMENTS } from '../actions/tenantActions';

const initialTenantViewState = {
  alert: {
    show: false,
  },
  changeDetected: false,
  tenant: {
    account_created_date: '',
    apis: [],
    carriers: [],
    feature_management: {},
    dtFeatures: [],
    enabled: false,
    locales: [],
    products: [],
    retailer_id: null,
    retailer_name: '',
    types: [],
    uri_moniker: '',
    carrier_services: [],
  },
  parentTenant: null,
  tenantEntitlements: {
    entitlementActivated: false,
    productEntitlements: [],
    features: [],
  },
};

const featuresTenantData = tenantPayload => {
  const entries = Object.entries(
    tenantPayload.feature_management &&
      tenantPayload.feature_management.dynamic_track,
  ).reduce((allKeys, [nextKey, nextValue]) => {
    if (nextValue) allKeys.push(nextKey);
    return allKeys;
  }, []);

  const messagingEntries = Object.entries(
    (tenantPayload.feature_management &&
      tenantPayload.feature_management.messaging) ||
      {},
  ).reduce((allKeys, [nextKey, nextValue]) => {
    if (nextValue) allKeys.push(nextKey);
    return allKeys;
  }, []);

  return {
    ...tenantPayload,
    dtFeatures: entries,
    messagingFeatures: messagingEntries,
  };
};

const tenantEntitlementData = entitlementPayload => {
  return {
    ...entitlementPayload,
  };
};

function tenantViewDataReducer(state = initialTenantViewState, action) {
  const { meta, payload, type } = action;

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: initialTenantViewState.alert,
      };

    case RECEIVE_TENANT_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occurred.'
              : `${meta.error}.`,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialTenantViewState.alert,
      };

    case RECEIVE_TENANT:
      return {
        ...state,
        parentTenant: null,
        tenant: featuresTenantData(payload.tenant),
      };

    case RECEIVE_TENANT_ENTITLEMENTS:
      return {
        ...state,
        tenantEntitlements: tenantEntitlementData(payload.entitlements),
      };

    case RECEIVE_PARENT_TENANT:
      return {
        ...state,
        isFetching: false,
        parentTenant: {
          ...featuresTenantData(payload),
          company: payload.retailer_name,
        },
      };

    case RECEIVE_TENANTS_FOR_SEARCH:
      return {
        ...state,
        changeDetected: true,
      };

    case RECEIVE_TENANT_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your company settings were updated successfully.',
        },
        changeDetected: false,
        tenant: featuresTenantData(payload.tenant),
      };

    case RECEIVE_TENANT_UPDATE_STATUS:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
        tenant: {
          ...state.tenant,
          ...action.tenant,
        },
      };

    case RESET_TENANT_STATE:
      return {
        ...initialTenantViewState,
      };

    case RECEIVE_TIER_1_SETUP_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Messaging tier setup successful.',
        },
      };

    case RECEIVE_TIER_1_SETUP_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: `Messaging tier setup failed ${
            payload.length > 0 ? payload.join('\n') : ''
          }.`,
        },
      };

    case MESSAGING_ON_BOARDING_JOB_SUBMITTED:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: `Messaging OnBoarding job submitted, note this jobId: ${payload.jobId} for future reference.`,
        },
      };

    default:
      return state;
  }
}

const companyViewInitialState = {
  alert: {
    show: false,
  },
};

function companyViewDataReducer(state = companyViewInitialState, action) {
  const { meta, payload, type } = action;

  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: companyViewInitialState.alert,
      };

    case RECEIVE_COMPANY_VIEW_DATA:
      return {
        ...state,
        [meta.company]: {
          accountKey: payload,
        },
      };

    case RECEIVE_API_KEY:
      return {
        ...state,
        [meta.company]: {
          ...state[meta.company],
          [payload.access_token_name]: {
            ...payload,
          },
        },
      };

    case RECEIVE_RESET_API_KEY:
      return {
        ...state,
        [meta.company]: {
          ...state[meta.company],
          [meta.tokenId]: {},
        },
      };

    case RECEIVE_COMPANY_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            'We are unable to provide your API information at this time. Please try again later.',
        },
      };

    case RECEIVE_HAS_HMAC_TOKEN:
      return {
        ...state,
        [meta.company]: {
          ...state[meta.company],
          hasHmacToken: payload.has_hmac_token,
          hmacToken: payload,
        },
      };

    case RECEIVE_SET_HMAC_TOKEN:
      return {
        ...state,
        [meta.company]: {
          ...state[meta.company],
          hasHmacToken: payload.has_hmac_token,
          hmacToken: payload,
        },
        alert: {
          color: payload.has_hmac_token ? 'success' : 'danger',
          show: true,
          text: payload.has_hmac_token
            ? 'HMAC Token set successful'
            : 'Failed to set HMAC Token',
        },
      };

    case RECEIVE_HMAC_TOKEN_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occurred.'
              : `${meta.error}.`,
        },
      };

    default:
      return state;
  }
}

const tenantViewReducer = combineReducers({
  companyViewDataReducer,
  tenantViewDataReducer,
});

export default tenantViewReducer;
