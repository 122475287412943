import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FormGroup from 'reactstrap/lib/FormGroup';
import Button from '../Button';
import Checkbox from '../Checkbox';
import DatePicker from '../DatePicker';
import Dimensions from '../Dimensions';
import Expression from '../Expression';
import Filters from '../Filters';
import ImageUploader from '../ImageUploader';
import MultiSelect from '../MultiSelect';
import MultiTimeInput from '../MultiTimeInput';
import Switch from '../Switch';
import Select from '../Select';
import SearchableSelect from '../SearchableSelect';
import TimePicker from '../TimePicker';
import Svg from '../Svg';
import Tagger from '../Tagger';
import Sensitive from '../Sensitive';
import FileUploader from '../FileUploader';
import SvgButton from '../SvgButton';
import s from './FormControl.styles';

const hintTextTypes = [
  'text',
  'password',
  'number',
  'textarea',
  'sensitive',
  'switch',
];
const acceptsHintText = type => hintTextTypes.includes(type);

export class FormControl extends Component {
  constructor(props) {
    super(props);
    const textInputs = ['text', 'password', 'textarea', 'sensitive'];
    let charCount = false;
    if (textInputs.includes(props.type)) {
      charCount = props.data ? props.data.length : 0;
    }
    this.state = {
      charCount,
    };
  }

  handleTextChange = event => {
    if (this.props.type === 'number') {
      this.props.onChange(event, false);
      return;
    }

    const {
      charLimit,
      enforceCharLimit,
    } = this.props;

    if (enforceCharLimit) {
      // TODO: Figure how to not do this by passing the value back to onChange nicely.
      event.currentTarget.value = event.currentTarget.value.slice(0, charLimit);
    }

    const charCount = event.currentTarget.value.length;
    this.setState({ charCount });

    const isTooLong = enforceCharLimit ? false : charLimit && (charCount > charLimit);
    this.props.onChange(event, isTooLong);
  }

  render() {
    const {
      autoComplete,
      autoFocus,
      filterTypes,
      buttonText,
      buttonColor,
      charLimit,
      checkbox,
      checked,
      children,
      className,
      code,
      columns,
      connector,
      customStyles,
      data,
      disabled,
      disableKey,
      disableRemove,
      descriptionKey,
      disableAdd,
      hidden,
      hintText,
      ignoreHandleClickOutside,
      index,
      endDate,
      enableTimeInput,
      enableTimezone,
      icon,
      label,
      minSchemaLength,
      name,
      noResize,
      onFocus,
      onBlur,
      onChange,
      onAdd,
      onNestedAdd,
      onRemove,
      onClick,
      onSelect,
      onKeyDown,
      optionalField,
      placeholder,
      readableKey,
      readOnly,
      selected,
      startDate,
      singleDate,
      noDatePlaceHolder,
      singleDateReset,
      stylesObj,
      minDate,
      maxDate,
      nestedConnector,
      isNestedExpression,
      nestedExpressionButtonText,
      parentExpressionHeader,
      tags,
      tooltipText,
      testAttr,
      type,
      time,
      timezone,
      timezoneLabel,
      timeInputLabel,
      schema,
      isDateRange,
      validationError,
      showSubText,
      enabledText,
      disabledText,
      onDrop,
      accept,
      device,
      fileKey,
      onDownload,
      onDelete,
      svgType,
      downloadBtnText,
      uploadBtnText,
      autoSearch,
      tzSettings,
      removeDisabled,
    } = this.props;


    let charCounter = null;
    if (charLimit) {
      let charLimitStyle = 'char-limit';
      if (this.state.charCount > charLimit) {
        charLimitStyle += ' text-danger';
      }
      charCounter = (
        <span className={cx('small', 'text-muted', charLimitStyle)}>
          {`${this.state.charCount}/${charLimit}`}
        </span>
      );
    }

    const defaultTestAttr = testAttr || (label ? label.toLowerCase() : String(name).toLowerCase());

    return (
      <div
        className={cx(s, className)}
        data-test={
          `${label ? label.toLowerCase() : readableKey ? readableKey.toLowerCase() : null}-formgroup`
        }
      >
        <FormGroup>
          {label &&
            <span className="label">
              {label}
              {' '}
              <span className={cx('small', 'text-muted', 'ml-1')}>
                {optionalField && 'Optional'}
              </span>
              {charCounter}
            </span>
          }
          {type === 'filters' && (
            <Filters
              filters={data}
              filterTypes={filterTypes}
              name={name}
              onChange={onChange}
              onSelect={onSelect}
              onRemove={onRemove}
              selectedFilters={selected}
              ignoreHandleClickOutside={ignoreHandleClickOutside}
            />
          )}
          {type === 'fileUploader' && (
            <FileUploader
              file={data}
              fileKey={fileKey}
              name={name}
              onDrop={onDrop}
              onDownload={onDownload}
              onDelete={onDelete}
              placeholder={placeholder}
              uploadBtnText={uploadBtnText}
              downloadBtnText={downloadBtnText}
            />
          )}
          {type === 'svgButton' && (
            <SvgButton
              svgType={svgType}
              buttonText={buttonText}
              onClick={onClick}
              disabled={disabled}
            />
          )}
          {type === 'dimensions' && (
            <Dimensions
              dimensions={data}
              name={name}
              onRemove={onRemove}
              onSelect={onSelect}
              selectedDimensions={selected}
              ignoreHandleClickOutside={ignoreHandleClickOutside}
            />
          )}
          {type === 'searchableSelect' && (
            <SearchableSelect
              disableOnClickOutside
              disabled={disabled}
              descriptionKey={descriptionKey}
              items={data}
              name={name}
              onFocus={onFocus}
              onChange={onChange}
              onSelect={onSelect}
              readableKey={readableKey}
              selected={selected}
              placeholder={placeholder}
              testAttr={defaultTestAttr}
              autoSearch={autoSearch}
              removeDisabled={removeDisabled}
            />
          )}
          {type === 'select' && (
            <Select
              checkbox={checkbox}
              columns={columns}
              disabled={disabled}
              disableKey={disableKey}
              descriptionKey={descriptionKey}
              items={data}
              name={name}
              onSelect={onChange}
              readableKey={readableKey}
              selected={selected}
              placeholder={placeholder}
              testAttr={defaultTestAttr}
            />
          )}
          {type === 'button' && (
            <Button
              color={buttonColor}
              disabled={disabled || false}
              hidden={hidden}
              icon={icon}
              name={name}
              onClick={onClick}
              className={className}
              testAttr={defaultTestAttr}
            >
              {buttonText}
            </Button>
          )}
          {type === 'tagger' && (
            <Tagger
              className={className}
              name={name}
              onChange={onChange}
              onClick={onClick}
              onKeyDown={onKeyDown}
              tags={tags}
              value={data}
            />
          )}
          {type === 'multiSelect' && (
            <MultiSelect
              items={data}
              onChange={onChange}
              readableKey={readableKey}
            />
          )}
          {type === 'multiTimeInput' && (
            <MultiTimeInput
              items={data}
              onChange={onChange}
              readableKey={readableKey}
            />
          )}
          {type === 'switch' && (
            <Switch
              disabled={disabled}
              name={name}
              onChange={onChange}
              testAttr={testAttr}
              value={data}
              data-index={index}
              showSubText={showSubText}
              enabledText={enabledText}
              disabledText={disabledText}
            />
          )}
          {type === 'sensitive' && (
            <Sensitive
              disabled={disabled}
              name={name}
              onBlur={onBlur}
              onChange={this.handleTextChange}
              onKeyDown={onKeyDown}
              value={data}
              tooltipText={tooltipText}
              validationError={Boolean(validationError)}
            />
          )}
          {type === 'hum-checkbox' && (
            <Checkbox
              data-test={
                `${label ? label.toLowerCase() : name === 'short_comment' ? data : name
                          .toString()
                          .toLowerCase()}-input`
              }
              disabled={disabled}
              name={name}
              onChange={onChange}
              type={type}
              readableKey={readableKey}
              item={data}
              checked={checked}
            />
          )}
          {type === 'checkbox' && (
            <input
              data-test={
                `${label ? label.toLowerCase() : name === 'short_comment' ? data : name
                          .toString()
                          .toLowerCase()}-input`
              }
              disabled={disabled}
              name={name}
              onChange={onChange}
              type={type}
              value={data}
              checked={checked}
            />
          )}
          {(type === 'text' || type === 'password' || type === 'number') && (
            <input
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              className={cx(
                'form-control',
                validationError ? 'error' : null,
              )}
              data-test={
                `${label ? label.toLowerCase() : name
                      .toString()
                      .toLowerCase()}-input`
              }
              disabled={disabled}
              name={name}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onChange={this.handleTextChange}
              placeholder={placeholder}
              type={type}
              value={data}
            />
          )}
          {type === 'static-text' && (
            <div className="static-text">
              {data}
            </div>
          )}
          {type === 'divider' && (
            <div className="divider" />
          )}
          {type === 'hex-color' && (
            <div className="hex-color">
              <input
                autoFocus={autoFocus}
                className={cx(
                  'form-control',
                  'hex-color',
                  validationError ? 'error' : null,
                )}
                data-test={
                  `${label ? label.toLowerCase() : name
                    .toString()
                    .toLowerCase()}-input`
                }
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                placeholder={placeholder}
                type={type}
                value={data}
              />
              <div className="preview" style={{ backgroundColor: data }} />
            </div>
          )}
          {type === 'textarea' && (
            <textarea
              autoFocus={autoFocus}
              className={cx(
                'form-control',
                validationError ? 'error' : '',
                noResize ? 'no-resize' : '',
                code ? 'code' : '',
              )}
              data-test={
                `${label ? label.toLowerCase() : String(name).toLowerCase()}-input`
              }
              disabled={disabled}
              name={name}
              onChange={this.handleTextChange}
              placeholder={placeholder}
              readOnly={readOnly}
              value={data || ''}
            />
          )}
          {type === 'time' && (
            <TimePicker
              enableTimeInput={enableTimeInput}
              enableTimezone={enableTimezone}
              onChange={onChange}
              time={time}
              timezone={timezone}
              timeInputLabel={timeInputLabel}
              timezoneLabel={timezoneLabel}
              customStyles={customStyles}
              tzSettings={tzSettings}
            />
          )}
          {type === 'date' && (
            <div className={cx(
              'date-picker',
              validationError ? 'error' : null,
              )}
            >
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                singleDate={singleDate}
                noDatePlaceHolder={noDatePlaceHolder}
                singleDateReset={singleDateReset}
                handleSelect={onSelect}
                isDateRange={isDateRange}
                minDate={minDate}
                maxDate={maxDate}
                showDropdown
              />
            </div>
          )}
          {type === 'expression' && (
            <Expression
              buttonText={buttonText}
              connector={connector}
              data={data}
              disabled={disabled}
              disableAdd={disableAdd}
              minSchemaLength={Number.isNaN(minSchemaLength) ? 1 : minSchemaLength}
              name={name}
              onAdd={onAdd}
              onNestedAdd={onNestedAdd}
              nestedConnector={nestedConnector}
              isNestedExpression={isNestedExpression}
              nestedExpressionButtonText={nestedExpressionButtonText}
              parentExpressionHeader={parentExpressionHeader}
              onChange={onChange}
              onClick={onClick}
              onRemove={onRemove}
              disableRemove={disableRemove === true}
              schema={schema}
              stylesObj={stylesObj || {}}
            />
          )}
          {type === 'svg' && (
            <Svg
              className={className}
              name={name}
              onChange={onChange}
            />
          )}
          {type === 'imageUploader' && (
            <ImageUploader
              accept={accept}
              src={data}
              onDrop={onDrop}
              device={device}
              name={name}
            />
          )}
          {hintText && acceptsHintText(type) && (
            <p className={cx('small', 'hint-msg')}>{hintText}</p>
          )}
          {validationError && (
            <p className="error-msg">{validationError}</p>
          )}
          {children || null}
        </FormGroup>
      </div>
    );
  }
}

FormControl.propTypes = {
  autoFocus: PropTypes.bool,
  charLimit: PropTypes.number,
  enforceCharLimit: PropTypes.bool,
  className: PropTypes.string,
  columns: PropTypes.number,
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  buttonColor: PropTypes.oneOf([
    'add',
    'danger',
    'info',
    'primary',
    'secondary',
    'success',
    'link',
    'warning',
  ]),
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  disableKey: PropTypes.string,
  disableAdd: PropTypes.bool,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  singleDate: PropTypes.object,
  noDatePlaceHolder: PropTypes.string,
  singleDateReset: PropTypes.func,
  schema: PropTypes.array,
  buttonText: PropTypes.string,
  time: PropTypes.string,
  timezone: PropTypes.object,
  enableTimeInput: PropTypes.bool,
  enableTimezone: PropTypes.bool,
  name: PropTypes.string.isRequired,
  connector: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onNestedAdd: PropTypes.func,
  onSelect: PropTypes.func,
  onKeyDown: PropTypes.func,
  onRemove: PropTypes.func,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  enabledText: PropTypes.string,
  disabledText: PropTypes.string,
  optionalField: PropTypes.bool,
  placeholder: PropTypes.string,
  testAttr: PropTypes.string,
  showSubText: PropTypes.bool,
  readOnly: PropTypes.bool,
  noResize: PropTypes.bool,
  type: PropTypes.oneOf([
    'checkbox',
    'date',
    'dropdown',
    'button',
    'expression',
    'searchableSelect',
    'hex-color',
    'divider',
    'static-text',
    'multiSelect',
    'multiTimeInput',
    'number',
    'password',
    'tagger',
    'text',
    'textarea',
    'time',
    'select',
    'switch',
    'svg',
    'filters',
    'dimensions',
    'sensitive',
    'imageUploader',
    'fileUploader',
    'svgButton',
    'childNode',
  ]).isRequired,
  hintText: PropTypes.string,
  validationError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  autoComplete: PropTypes.string,
  filterTypes: PropTypes.any,
  checkbox: PropTypes.any,
  checked: PropTypes.bool,
  children: PropTypes.node,
  code: PropTypes.any,
  customStyles: PropTypes.any,
  disableRemove: PropTypes.any,
  ignoreHandleClickOutside: PropTypes.any,
  index: PropTypes.any,
  icon: PropTypes.any,
  minSchemaLength: PropTypes.any,
  readableKey: PropTypes.string,
  selected: PropTypes.any,
  stylesObj: PropTypes.any,
  minDate: PropTypes.any,
  maxDate: PropTypes.any,
  nestedConnector: PropTypes.string,
  nestedExpressionButtonText: PropTypes.string,
  parentExpressionHeader: PropTypes.string,
  tagId: PropTypes.any,
  tagOnFocusOut: PropTypes.any,
  timezoneLabel: PropTypes.any,
  timeInputLabel: PropTypes.any,
  isDateRange: PropTypes.bool,
  isNestedExpression: PropTypes.bool,
  autoSearch: PropTypes.bool,
  accept: PropTypes.string,
  device: PropTypes.string,
  onDrop: PropTypes.func,
  src: PropTypes.string,
  fileKey: PropTypes.string,
  onDownload: PropTypes.func,
  onDelete:  PropTypes.func,
  uploadBtnText: PropTypes.string,
  downloadBtnText: PropTypes.string,
  svgType: PropTypes.string,
  tzSettings: PropTypes.shape({
    search: PropTypes.bool,
    sort: PropTypes.bool,
  }),
  removeDisabled: PropTypes.bool,
};

FormControl.defaultProps = {
  autoSearch: false,
  enforceCharLimit: false,
  autoComplete: '',
  validationError: false,
  hintText: '',
  checked: false,
  isDateRange: false,
  readableKey: '',
  children: null,
  disabled: false,
  hidden: false,
  disableAdd: false,
  label: '',
  tzSettings: {
    search: false,
    sort: false,
  },
  removeDisabled: false,
};

FormControl.displayName = 'FormControl';

export default FormControl;
