import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  DISMISS_ALERT,
  LAUNCH_REPORTS_PAYLOAD_CONFIRMATION_MODAL,
  LAUNCH_DASHBOARD_PAYLOAD_CONFIRMATION_MODAL,
  RESET_PAYLOAD_CONFIRMATION_STATE,
  RECEIVE_CHART_DATA,
  RECEIVE_FILTERS_DATA,
  RECEIVE_DASHBOARD,
  RECEIVE_DASHBOARDS,
  RECEIVE_REPORT,
  RECEIVE_REPORT_DEFINITIONS,
  RECEIVE_DASHBOARD_DETAILS_ERROR,
  RECEIVE_SAVE_REPORT_ERROR,
  RECEIVE_CREATE_REPORT,
  RECEIVE_CREATE_DASHBOARD,
  RECEIVE_SAVE_DASHBOARD,
  RECEIVE_DELETE_DASHBOARD,
  DISMISS_PAYLOAD_ERRORS,
  UPDATE_DASHBOARD_DATES,
} from '../actions/reportingActions';

export const initialState = {
  alert: {
    color: 'info',
    show: false,
    text: '',
  },
  changeDetected: false,
  chartData: {},
  dimensions: [],
  errors: [],
  filters: [],
  filterTypes: {},
  dashboard: {},
  dashboardPayload: [],
  dashStartDate: moment()
    .subtract(30, 'days')
    .format(API_DATE_FORMAT),
  dashEndDate: moment().format(API_DATE_FORMAT),
  dashboards: {},
  hasFetchedDashboards: false,
  object: '',
  reports: {},
  reportDefinitions: {},
  reportsPayload: [],
  tableData: [],
};

function reporting(state = initialState, action) {
  const { error, meta, payload, type } = action;
  let object;

  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };
    case RECEIVE_FILTERS_DATA:
      return {
        ...state,
        dimensions: payload.dimensions,
        filters: payload.filters,
        filterTypes: payload.filterTypes,
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: initialState.alert,
      };
    case RECEIVE_CHART_DATA:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          [meta.chartName]: payload.chartData,
        },
        tableData: {
          ...state.tableData,
          [meta.chartName]: payload.tableData,
        },
      };
    case RECEIVE_DASHBOARD:
      object = Object.keys(payload.report_definitions)[0];
      return {
        ...state,
        dashboard: payload.dashboard_definitions,
        object,
        reports: {
          ...state.reports,
          ...payload.report_instances,
        },
        reportDefinitions: payload.report_definitions,
      };
    case RECEIVE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload.report_instances,
        },
        reportDefinitions: payload.report_definitions,
      };
    case RECEIVE_DASHBOARDS:
      return {
        ...state,
        dashboards: payload.dashboard_definitions,
        reports: payload.report_instances,
        hasFetchedDashboards: true,
      };
    case RECEIVE_REPORT_DEFINITIONS:
      return {
        ...state,
        object: payload.report_definitions.id,
        reportDefinitions: {
          [payload.report_definitions.id]: payload.report_definitions,
        },
      };
    case RECEIVE_DASHBOARD_DETAILS_ERROR: {
      let text = 'Sorry an unknown error has occured.';
      if (error) {
        text = [...state.alert, ...error.map(err => err.message)];
      }
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case RECEIVE_SAVE_REPORT_ERROR: {
      let reportError = {};
      if (meta && meta.reportIndex !== undefined) {
        reportError = {
          reportIndex: meta.reportIndex,
          validationError: error,
        };
      }
      return {
        ...state,
        errors: [...state.errors, reportError],
      };
    }
    case RECEIVE_CREATE_DASHBOARD:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          // TODO: Make this a link
          text: `"${payload.display_name}" created successfully.`,
        },
        dashboards: {
          ...state.dashboards,
          [payload.id]: payload,
        },
      };
    case RECEIVE_CREATE_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [payload.id]: payload,
        },
      };
    case RECEIVE_SAVE_DASHBOARD:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your dashboard was saved successfully.',
        },
        changeDetected: false,
        dashboardPayload: [],
      };
    case RESET_PAYLOAD_CONFIRMATION_STATE:
      return {
        ...state,
        reportsPayload: [],
        dashboardPayload: [],
      };
    case LAUNCH_REPORTS_PAYLOAD_CONFIRMATION_MODAL:
      return {
        ...state,
        reportsPayload: [...state.reportsPayload, payload],
      };
    case LAUNCH_DASHBOARD_PAYLOAD_CONFIRMATION_MODAL:
      return {
        ...state,
        reportsPayload: [],
        dashboardPayload: [payload],
      };
    case RECEIVE_DELETE_DASHBOARD:
      return {
        ...state,
      };
    case DISMISS_PAYLOAD_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case UPDATE_DASHBOARD_DATES:
      return {
        ...state,
        dashStartDate: payload.startDate,
        dashEndDate: payload.endDate,
      };
    default:
      return state;
  }
}

export default reporting;
