export const TRACKING = 'tracking';
export const RETURNS = 'returns';
export const INTERPOLATION_KEY = '<METHOD_KEY>';
export const DELIMITER = '*';

export const FLAT_RETURNS_SETTINGS = 'flatReturnsSettings';

export const MAX_IMAGE_SIZE = 6291456;

export const IMAGE_SIZE_ERROR_MSG = 'File size exceeds 5 MB';

export const IMAGE_DEFAULT_ERROR_MSG =
  'There was an issue uploading the image.';

export const RETURN_METHODS = 'return_methods';

export const REFUND_OPTION = 'refund_option';

export const ESTIMATES = 'estimates';

export const TOOLTIP = 'display_order_tooltip';

export const GIFT_RETURNS = 'gift_returns_enabled';

export const HELP_PILL = 'display_help';

export const CANCEL_RETURNS = 'disableItemCancel';

export const REFUND_OPTION_ERROR_MSG =
  'Unable to update an already configured Refund Option';

export const HEADER_STYLES = [
  { label: 'Logo Left of Nav', value: 'CenterMenuLeftLogo' },
  { label: 'Logo in Center above Nav', value: 'CenterMenuCenterLogo' },
  { label: 'Logo on Left above Nav', value: 'LeftMenuTopLogo' },
  { label: 'Logo on Left', value: 'NoMenuLeftLogo' },
  { label: 'Logo in Center', value: 'NoMenuCenterLogo' },
  { label: 'Logo in Center of Nav', value: 'SplitMenuCenterLogo' },
];

export const INPUT_CONTROL_1_OPTIONS = [
  {
    label: 'Order Number',
    giftLabel: 'Order Number',
    value: {
      css: '100',
      enableOn: '',
      is_required: true,
      name: 'number',
      id: 'number',
      type: 'text',
    },
  },
];

export const INPUT_CONTROL_2_OPTIONS = [
  {
    label: 'Zip Code (CAUTION - Security Risk!)',
    giftLabel: 'Shipping Zip Code',
    value: {
      css: '100',
      enableOn: '',
      is_required: true,
      name: 'zip',
      id: 'zipCode',
      type: 'text',
    },
  },
  {
    label: 'Email',
    giftLabel: 'Email',
    value: {
      css: '100',
      enableOn: '',
      is_required: true,
      name: 'email',
      id: 'email',
      type: 'email',
    },
  },
];

export const BRANDING_JSON_PROPERTIES = {
  HEADER_LAYOUT: `returns${DELIMITER}headerStyle`,
  DESKTOP_IMAGE: 'returns.validate.background_image_link',
  MOBILE_IMAGE: 'returns.validate.background_image_link_mobile',
};

export const SIGNIN_JSON_PROPERTIES = {
  INPUT_CONTROL_1_NAME: `returns${DELIMITER}validation${DELIMITER}input_controls[0][0]${DELIMITER}name`,
  INPUT_CONTROL_2_NAME: `returns${DELIMITER}validation${DELIMITER}input_controls[1][0]${DELIMITER}name`,
  SIGN_IN_MESSAGE: 'returns.validate.login_sidebar_subtitle',
  RETURN_POLICY_DISPLAY_TEXT: 'returns.validate.return_policy_link_text',
  RETURN_POLICY_LINK: 'returns.validate.return_policy_link',
  TOOLTIP_ENABLED: `returns${DELIMITER}display_order_tooltip`,
  TOOLTIP_TITLE: 'returns.validate.help_tooltip_title',
  TOOLTIP_DESCRIPTION: 'returns.validate.help_tooltip_description',
  GIFT_RETURNS_ENABLED: `returns${DELIMITER}gift_returns_enabled`,
  GIFT_SIGN_IN_MESSAGE: 'returns.validate.login_gift_sidebar_subtitle',
  HELP_PILL_ENABLED: `returns${DELIMITER}display_help`,
  HELP_PILL_LABEL: 'returns.landing.help_text',
  HELP_PILL_LINK: 'returns.landing.help_url',
};

export const SIGNIN_INPUT_JSON_PROPERTIES = {
  INPUT_BASE: 'returns.validate.form.',
  INPUT_LABEL: '.label',
  INPUT_GIFT_LABEL: '.gift_label',
};

export const ITEM_SELECTION_JSON_PROPERTIES = {
  RETURN_WINDOW_DISPLAY_COPY: [
    'returns.step_1.header_msg1.sub_msg',
    'returns.step_1.header_msg2.sub_msg',
    'returns.step_1.header_msg5.sub_msg',
    'returns.step_1.header_msg6.sub_msg',
  ],
  CANCEL_RETURNS_ENABLED: `returns${DELIMITER}disableItemCancel`,
};

export const METHOD_SELECTION_JSON_PROPERTIES = {
  STEP_1: {
    INITIATED_RETURNS_HEADER: 'returns.step_1.initiated_returns_header.',
  },
  STEP_2: {
    RETURN_METHODS_BASE: 'returns.return_methods.',
    TITLE: '.title',
    FEATURE_1: '.features[0].feature',
    FEATURE_2: '.features[1].feature',
    FEATURE_3: '.features[2].feature',
    STORE_LINK_TEXT: '.store_link_text',
    STORE_LINK: '.store_link',
    DESCRIPTION: '.description',
    DISCLAIMER: '.disclaimer',
    PRICE_LABEL: '.method_price_label',
  },
  STEP_3: {
    SUBHEADER: 'returns.step_3.step_3_subheader_',
    REVIEW_LABEL: 'returns.step_3.',
  },
  STEP_4: {
    EMAIL_CONFIRMATION: 'returns.step_4.email_confirmation.',
    EMAIL_SECONDARY_CONFIRMATION:
      'returns.step_4.email_secondary_confirmation.',
    INSTRUCTIONS_TITLE: 'returns.step_4.return_instructions_header.',
    INSTRUCTION_0_HEADER: 'returns.instruction[0].',
    INSTRUCTION_0_DESCRIPTION: 'returns.instruction[0].description_',
    INSTRUCTION_0_BUTTON: 'returns.instruction[0].button_',
    INSTRUCTION_1_HEADER: 'returns.instruction[1].',
    INSTRUCTION_1_DESCRIPTION: 'returns.instruction[1].description_',
    INSTRUCTION_2_HEADER: 'returns.instruction[2].',
    INSTRUCTION_2_DESCRIPTION: 'returns.instruction[2].description_',
  },
};

export const REVIEW_JSON_PROPERTIES = {
  REFUND_OPTIONS: `returns${DELIMITER}refund_option${DELIMITER}options`,
  REFUND_OPTION_ENABLED: `returns${DELIMITER}refund_option${DELIMITER}enabled`,
  ESTIMATES: `returns${DELIMITER}estimates${DELIMITER}options`,
  ESTIMATES_ENABLED: `returns${DELIMITER}estimates${DELIMITER}enabled`,
  REFUND_OPTION_HEADER_TEXT: 'returns.step_3.refund_label',
  GIFT_RETURNS_HEADER_TEXT: 'returns.step_3.gift_box_title',
  GIFT_RETURNS_BODY_TEXT: 'returns.step_3.gift_box_msg',
  DISCLAIMER_TEXT: 'returns.step_3.refund_disclaimer',
};

export const FEATURE_LANGUAGE_KEYS = {
  [TOOLTIP]: [
    SIGNIN_JSON_PROPERTIES.TOOLTIP_TITLE,
    SIGNIN_JSON_PROPERTIES.TOOLTIP_DESCRIPTION,
  ],
  [GIFT_RETURNS]: [
    SIGNIN_JSON_PROPERTIES.GIFT_RETURNS_ENABLED,
    SIGNIN_JSON_PROPERTIES.GIFT_SIGN_IN_MESSAGE,
    REVIEW_JSON_PROPERTIES.GIFT_RETURNS_HEADER_TEXT,
    REVIEW_JSON_PROPERTIES.GIFT_RETURNS_BODY_TEXT,
  ],
  [HELP_PILL]: [
    SIGNIN_JSON_PROPERTIES.HELP_PILL_LABEL,
    SIGNIN_JSON_PROPERTIES.HELP_PILL_LINK,
  ],
  [ESTIMATES]: [REVIEW_JSON_PROPERTIES.REFUND_OPTION_HEADER_TEXT],
  [REFUND_OPTION]: [REVIEW_JSON_PROPERTIES.REFUND_OPTION_HEADER_TEXT],
  [CANCEL_RETURNS]: [],
};

export const UI_SCHEMA = {
  UI_KEYS: {
    UI_BUILDER_METHOD: 'uibuilder_method',
  },
};
