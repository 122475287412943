export const getSnowplow = (snowplowName) => {
  if (typeof window !== 'object' || typeof window[snowplowName] !== 'function') return null;
  return window[snowplowName];
};

export const getSegmentTrack = (segmentName) => {
  if (typeof window !== 'object') return null;
  const {
    [segmentName]: {
      track,
    } = {},
  } = window;
  return (typeof track === 'function') ? track : null;
};

export const getSegmentPage = (segmentName) => {
  if (typeof window !== 'object') return null;
  const {
    [segmentName]: {
      page,
    } = {},
  } = window;
  return (typeof page === 'function') ? page : null;
};
