import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  UPDATE_SELECTED_LOCALE,
  SEARCH_FILTERS,
  UPDATE_DATE_RANGE,
  RECEIVE_ORDER_LIST,
  UPDATE_ACTIVE_PAGE,
  UPDATE_SEARCH_FILTER,
  UPDATE_SEARCH_INPUT,
  TOGGLE_IS_SEARCHING,
  CANCEL_SEARCH,
  RECEIVE_EXPORT_ORDERS,
} from '~src/actions/exceptionsReportsActions';

const initialState = {
  startDate: moment()
    .subtract(7, 'days')
    .format(API_DATE_FORMAT),
  endDate: moment()
    .subtract(1, 'days')
    .format(API_DATE_FORMAT),
  selectedLocale: {
    locale: 'en_US',
  },
  exportParameters: '',
  searchFilter: SEARCH_FILTERS[0],
  searchInput: '',
  isSearching: false,
  searchValidationError: false,
  tableHeaders: [],
  tableBody: [],
  exportOrders: [],
  totalRecords: 0,
  totalRecordsWithFilters: 0,
  activePage: 1,
};

export default (
  state = initialState,
  {
    type,
    payload: {
      selectedLocale,
      startDate,
      endDate,
      exportParameters,
      searchFilter,
      searchInput,
      isSearching,
      tableHeaders,
      tableBody,
      exportOrders,
      totalRecordsWithFilters,
      activePage,
    } = {},
  },
) => {
  switch (type) {
    case UPDATE_SELECTED_LOCALE:
      return {
        ...state,
        selectedLocale,
      };

    case RECEIVE_EXPORT_ORDERS:
      return {
        ...state,
        exportOrders,
      };

    case RECEIVE_ORDER_LIST:
      return {
        ...state,
        exportParameters,
        tableHeaders,
        tableBody,
        totalRecordsWithFilters,
      };

    case UPDATE_DATE_RANGE:
      return {
        ...state,
        startDate,
        endDate,
      };

    case UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        activePage,
      };

    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        searchFilter,
      };

    case UPDATE_SEARCH_INPUT:
      return {
        ...state,
        searchInput,
      };

    case TOGGLE_IS_SEARCHING:
      return {
        ...state,
        isSearching,
      };

    case CANCEL_SEARCH:
      return {
        ...state,
        searchInput: '',
        isSearching: false,
      };

    default:
      return state;
  }
};
