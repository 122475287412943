import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const AbsoluteLink = ({ to, children, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link href={to} rel="noopener noreferrer" target="_blank" {...props}>
    {children}
  </Link>
);

AbsoluteLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default AbsoluteLink;
