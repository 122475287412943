import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import cx from 'classnames';
import Loader from '../Loader';
import Svg from '../Svg';
import s from './Button.styles';

export class NarvarButton extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      isFetching,
      disabled,
      hidden,
    } = this.props;
    return isFetching !== nextProps.isFetching ||
      disabled !== nextProps.disabled ||
      hidden !== nextProps.hidden;
  }

  render() {
    const {
      color,
      children,
      device,
      disabled,
      hidden,
      icon,
      inline,
      isFetching,
      isModal,
      name,
      outline,
      onClick,
      size,
      title,
      type,
      className,
    } = this.props;
    const isFetchingClassName = 'is-fetching';

    const buttonContent = icon ? (
      <div className="icon-inner">
        <Svg className={icon} name={icon} />
        {children}
      </div>
    ) : children;

    return (
      <Button
        color={color}
        className={cx(
          s,
          { [isFetchingClassName]: isFetching },
          className,
          icon ? 'btn-icon' : null,
          hidden ? 'btn-hidden' : null,
          inline ? 'btn-inline' : null,
        )}
        data-test={
          `${String(children).toLowerCase()}-button${isModal ? '-modal' : ''}${device ? `-${device}` : ''}${title ? `-${title}` : ''}`
        }
        disabled={disabled || isFetching}
        name={name}
        onClick={onClick}
        outline={outline}
        type={type}
        size={size}
      >
        {isFetching
          ? <Loader show className="button-loader" />
          : buttonContent}
      </Button>
    );
  }
}

NarvarButton.defaultProps = {
  color: 'primary',
};

NarvarButton.propTypes = {
  color: PropTypes.oneOf([
    'add',
    'danger',
    'info',
    'primary',
    'secondary',
    'success',
    'link',
    'warning',
  ]),
  className: PropTypes.string,
  device: PropTypes.string,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  isFetching: PropTypes.bool,
  isModal: PropTypes.bool,
  icon: PropTypes.string,
  inline: PropTypes.bool,
  name: PropTypes.string,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.any,
};

NarvarButton.displayName = 'NarvarButton';

export default NarvarButton;
