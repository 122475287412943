import qs from 'qs';
import { push } from 'connected-react-router';
import moment from 'moment';
import { API_REQUEST, API_INVALIDATE, API_INVALIDATE_PATH } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import { fetchRetailerSettings } from './exceptionAnalyticsActions';
import { CSM_LOGOUT, RETAILER_SWITCH } from '../constants/storage';
import getRoleBasedLandingPage from '../lib/getRoleBasedLandingPage';
import { fetchDashboards, fetchReportDefinitions } from './reportingActions';

export const RECEIVE_CSM_LOGIN = 'RECEIVE_CSM_LOGIN';
export const RECEIVE_CSM_LOGOUT = 'RECEIVE_CSM_LOGOUT';
export const RECEIVE_SWITCH_TENANT = 'RECEIVE_SWITCH_TENANT';
export const RECEIVE_FORM_ERROR = 'RECEIVE_FORM_ERROR';
export const RECEIVE_CHANGE_PASSWORD = 'RECEIVE_CHANGE_PASSWORD';
export const RECEIVE_UPDATE_PASSWORD = 'RECEIVE_UPDATE_PASSWORD';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';
export const RECEIVE_USER_STATUS = 'RECEIVE_USER_STATUS';
export const RECEIVE_USER_STATUS_ERROR = 'RECEIVE_USER_STATUS_ERROR';
export const RECEIVE_USERS = 'RECEIVE_USERS';
export const RECEIVE_USER = 'RECEIVE_USER';
export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const RECEIVE_ADD_USER = 'RECEIVE_ADD_USER';
export const RECEIVE_ADD_USER_ERROR = 'RECEIVE_ADD_USER_ERROR';
export const RECEIVE_VERIFIED_SIGNUP = 'RECEIVE_VERIFIED_SIGNUP';
export const SAVE_NEW_USER_DATA = 'SAVE_NEW_USER_DATA';
export const RECEIVE_RESET_PASSWORD = 'RECEIVE_RESET_PASSWORD';
export const RECEIVE_USER_CHANGE_STATUS = 'RECEIVE_USER_CHANGE_STATUS';
export const RECEIVE_UPDATE_USER_INFO = 'RECEIVE_UPDATE_USER_INFO';
export const RECEIVE_RESEND_INVITATION_EMAIL =
  'RECEIVE_RESEND_INVITATION_EMAIL';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const RECEIVE_ERROR = 'RECEIVE_ERROR';

export const dismissAlert = () => ({ type: DISMISS_ALERT, alert: null });

const reloadTenantData = dispatch => {
  dispatch(fetchRetailerSettings());
  dispatch(fetchDashboards());
  dispatch(fetchReportDefinitions());
};

export const fetchCsmLogin = (
  retailerId,
  next = null,
  query = null,
  growthbook = undefined,
) => async dispatch => {
  const body = qs.stringify({ retailerId });

  dispatch({ type: API_INVALIDATE });

  const json = await dispatch({
    type: API_REQUEST,
    body,
    contentType: 'application/x-www-form-urlencoded',
    method: 'POST',
    path: ENDPOINTS.CUSTOMER_SUPPORT_LOGIN,
    name: 'fetchCsmLogin',
  });

  const { user } = json;

  await dispatch({ type: RECEIVE_CSM_LOGIN, payload: json });

  reloadTenantData(dispatch);

  if (next) {
    dispatch(push({ pathname: next, search: query }));
    return;
  }

  const homepageEnabled = growthbook && growthbook.isOn('hub-homepage');
  const { external, url } = getRoleBasedLandingPage(user, homepageEnabled);

  if (external) {
    window.location.href = url;
    return;
  }

  dispatch(push(url));
};

const receiveUserStatus = json => ({
  type: RECEIVE_USER_STATUS,
  payload: json.response,
});

const receiveUserStatusError = error => ({
  type: RECEIVE_USER_STATUS_ERROR,
  meta: { error },
});

export const fetchStatus = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.SIGNED_IN,
      name: 'fetchStatus',
    });
    dispatch(receiveUserStatus(json));
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};

export const fetchRoles = (current_page_index = 0) => async dispatch => {
  const query = {
    records_per_page: 25,
    current_page_index,
  };

  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.ROLES,
      query,
      name: 'fetchRoles',
    });
    dispatch({
      type: RECEIVE_ROLES,
      payload: { roles: json.content },
    });
  } catch (err) {
    dispatch(receiveUserStatusError(err));
  }
};

export const fetchSwitchTenant = retailerId => async dispatch => {
  try {
    dispatch({ type: API_INVALIDATE });
    await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CHANGE_USER_RETAILER}/${retailerId}`,
      name: 'change',
    });
    dispatch({ type: RECEIVE_SWITCH_TENANT, meta: { retailerId } });
    window.localStorage.setItem(RETAILER_SWITCH, moment().format());
    reloadTenantData(dispatch);
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};

export const fetchCsmLogout = (growthbook = undefined) => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.SWITCHBACK,
      name: 'fetchCsmLogout',
    });
    await dispatch({ type: API_INVALIDATE });
    await dispatch({
      type: RECEIVE_CSM_LOGOUT,
      payload: { user: response.user },
    });
    window.localStorage.setItem(CSM_LOGOUT, moment().format());
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
    return;
  }

  dispatch(push('/'));
};

export const logout = () => async dispatch => {
  try {
    dispatch({ type: API_INVALIDATE });
    await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: '/uilogout',
      name: 'logout',
    });
    dispatch({ type: RECEIVE_LOGOUT });
    dispatch(push('/'));
    if (window.chmln) {
      window.chmln.clear();
    }
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};

export const fetchSelf = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.SELF,
      name: 'fetchSelf',
    });
    dispatch({ type: RECEIVE_USER, payload: { user: json.response } });
  } catch (err) {
    dispatch(receiveUserStatusError(err));
  }
};

export const fetchUpdateUserInfo = (body, userId) => async dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.USERS}/${userId}`,
  });
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.USERS}`,
  });
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: ENDPOINTS.SELF,
      body,
      name: 'fetchUpdateUserInfo',
    });
    dispatch({
      type: RECEIVE_UPDATE_USER_INFO,
      payload: json,
    });
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};

export const fetchResetPassword = userId => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: `${ENDPOINTS.USERS}/${userId}/reset-password`,
      name: 'fetchResetPassword',
    });
    dispatch({
      type: RECEIVE_RESET_PASSWORD,
      payload: json.response,
    });
  } catch (err) {
    console.error(err); // eslint-disable-line no-console
  }
};
