import gql from 'graphql-tag';

const UPDATE_BIGCOMMERCE_CREDS = gql`
  mutation updateBigCommerceCreds(
    $retailer: String!
    $clientId: String!
    $clientSecret: String!
    $accessToken: String
    $storeBaseUrl: String!
  ) {
    updateBigCommerceCreds(
      authDetails: {
        retailer: $retailer
        clientId: $clientId
        clientSecret: $clientSecret
        accessToken: $accessToken
        storeBaseUrl: $storeBaseUrl
      }
    ) {
      statusCode
      responseMessage
    }
  }
`;

export const DELETE_BIGCOMMERCE_CREDS = gql`
  mutation deleteBigCommerceCreds($retailer: String!, $storeBaseUrl: String!) {
    deleteBigCommerceCreds(
      retailerDetails: { retailer: $retailer, storeBaseUrl: $storeBaseUrl }
    ) {
      errorMessage
      responseMessage
    }
  }
`;

export default UPDATE_BIGCOMMERCE_CREDS;
