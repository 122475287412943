import { useEffect, useState } from 'react';
import getSections from './lib/getSections';

export const SIGNED_IN = '/api/users/signedin';
export const RETAILER_SETTINGS =
  '/api/designer/tracking?locale=en_US&condition=none';
export const REPORT_DEFINITIONS = '/api/report-definitions/';
export const DASHBOARD = '/api/dashboard-instances?product=monitor';
export const MONITOR_PRODUCT = 'monitor';
export const MONITOR_V2_SETTINGS = '/api/monitor-v2';

export default ({
  dashboardProp,
  exceptionsFeatureEnabledProp,
  reportDefinitionProp,
  user,
  i18n,
  growthbook,
}) => {
  const [currentUser, updateUser] = useState(user);
  const [exceptionsEnabled, updateExceptionsEnabled] = useState(
    exceptionsFeatureEnabledProp,
  );
  const [reportDefinition, updateReportDefinition] = useState(
    reportDefinitionProp,
  );
  const [dashboards, updateDashboards] = useState(dashboardProp);
  const [monitorV2UrlBase, updateMonitorV2UrlBase] = useState(undefined);

  useEffect(() => {
    if (Object.keys(user).length) {
      updateUser({ ...user, isCsm: user.customerSupportLogin });
    } else {
      (async () => {
        try {
          const result = await fetch(SIGNED_IN);
          const { response } = await result.json();

          const {
            customerSupportLogin: isCsm,
            retailerId,
            retailerIdToRetailerInfo,
            rolesList = [],
            t2Retailer,
            userRetailerAssociationList,
          } = response || {};

          updateUser({
            isCsm,
            retailerId,
            retailerIdToRetailerInfo,
            rolesList,
            t2Retailer,
            userRetailerAssociationList,
          });
        } catch (err) {
          console.error(err); // eslint-disable-line no-console
        }
      })();
    }
  }, [user]);

  useEffect(() => {
    if (typeof exceptionsFeatureEnabledProp !== 'boolean') {
      (async () => {
        try {
          const result = await fetch(RETAILER_SETTINGS);
          const {
            retailer_settings: retailerSettingsJsonString,
          } = await result.json();
          const retailerSettings = JSON.parse(retailerSettingsJsonString);
          const {
            features: {
              customer_exceptions: { enableDashboard = false } = {},
            } = {},
          } = retailerSettings;
          updateExceptionsEnabled(enableDashboard);
        } catch (err) {
          console.error(err); // eslint-disable-line no-console
        }
      })();
    } else {
      updateExceptionsEnabled(exceptionsFeatureEnabledProp);
    }
  }, [exceptionsFeatureEnabledProp]);

  useEffect(() => {
    if (!reportDefinitionProp) {
      (async () => {
        try {
          const result = await fetch(REPORT_DEFINITIONS);
          const { response = [] } = await result.json();
          // NOTE: Old hub incorrectly assumes one definition per tenant
          const firstDefinition = response.length
            ? response[0]
            : {
                fields: [],
                id: undefined,
              };
          updateReportDefinition(firstDefinition);
        } catch (err) {
          console.error(err); // eslint-disable-line no-console
        }
      })();
    } else {
      updateReportDefinition(reportDefinitionProp);
    }
  }, [reportDefinitionProp]);

  useEffect(() => {
    if (!dashboardProp) {
      if (Object.keys(currentUser).length) {
        const {
          retailerId,
          retailerIdToRetailerInfo: {
            [retailerId]: { products = [] } = {},
          } = {},
        } = currentUser;
        if (products.includes(MONITOR_PRODUCT)) {
          (async () => {
            try {
              const result = await fetch(DASHBOARD);
              const {
                dashboard_definitions: dashboardDefinitions = {},
              } = await result.json();
              updateDashboards(dashboardDefinitions);
            } catch (err) {
              console.error(err); // eslint-disable-line no-console
            }
          })();
        } else {
          updateDashboards({});
        }
      }
    } else {
      updateDashboards(dashboardProp);
    }
  }, [dashboardProp, currentUser]);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(MONITOR_V2_SETTINGS);
        const { monitorV2UrlBase: urlBase } = await result.json();
        updateMonitorV2UrlBase(urlBase);
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
      }
    })();
  }, []);

  if (!Object.keys(currentUser).length || !dashboards) {
    return null;
  }

  const {
    rolesList,
    isCsm,
    retailerIdToRetailerInfo,
    retailerId,
    t2Retailer,
    userRetailerAssociationList,
  } = currentUser;

  const role = rolesList[0];

  if (!role) return null;

  const sections = getSections({
    role,
    isCsm,
    retailerIdToRetailerInfo,
    retailerId,
    t2Retailer,
    exceptionsEnabled,
    dashboards,
    reportDefinition,
    monitorV2UrlBase,
    i18n,
    growthbook,
  });

  const selectItems = Object.values(retailerIdToRetailerInfo);
  const showTenantDropdown = !isCsm && userRetailerAssociationList.length > 1;

  return {
    retailerId,
    sections,
    selectItems,
    showTenantDropdown,
    retailerIdToRetailerInfo,
  };
};
