import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  & .item {
    margin-bottom: 10px;
  }

  & .item {
    & .close {
      position: relative;
    }
  }

  &.is-chart-detail {
    display: flex;
    flex-direction: row;
    margin: 0 0 10px 20px;

    & .item {
      border-right: 1px solid ${colors.gray};
      margin-right: 20px;
      min-width: 200px;
      padding-right: 10px;

      &:last-of-type {
        margin-right: 0;
      }
    
      & h4 {
        margin-bottom: 0;
      }

      & .dropdown {
        height: 15px;
        margin-top: 4px;
      }

      & .dropdown-menu {
        margin-top: 5px;
      }

      & .dropdown-toggle {
        background: transparent;
        border: none;
        box-shadow: none;
        height: 17px;
        min-height: 15px;
        padding: 0;

        & input + div {
          right: 0;
          top: -1px;
        }

        & div {
          color: $dark-gray;
        }

        & .active {
          background-color: transparent;
        }

        & input {
          background-color: transparent;
        }
      }
    }
  }`;

export default s;
