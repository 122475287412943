import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Card.styles';
import Loader from '../Loader';
import Svg from '../Svg';

export class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: typeof props.startCollapsed === 'undefined' ? false : props.startCollapsed,
    };
  }

  handleCollapse = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed,
    });
  }

  handleSort = (event, direction) => {
    const { index, name } = this.props;
    this.props.onSort(event, index, name, direction);
  }

  handleRemove = event => {
    const {
      index,
      name,
      onRemove,
    } = this.props;
    onRemove(event, index, name);
  }

  // Matt: tooltips props missing
  render() {
    const {
      children,
      className,
      collapsable,
      isFetching,
      onClick,
      removable,
      renderChildren,
      sortable,
      hideUpSort,
      hideDownSort,
      testAttr,
      title,
    } = this.props;
    const { isCollapsed } = this.state;
    const isCollapsedClassName = isCollapsed ? 'is-collapsed' : '';

    return (
      <div
        className={cx(s, !isFetching ? 'loaded' : '', isCollapsedClassName, className)}
        data-test={testAttr}
        onClick={onClick}
      >
        <div className="controls">
          {removable &&
            <span onClick={this.handleRemove}>
              <Svg
                name="delete"
                width={16}
                height={26}
                data-test={`${testAttr}-icon_delete`}
              />
            </span>
          }
          {sortable && (
            <span>
              {!hideUpSort && (
                <Svg
                  onClick={e => { this.handleSort(e, 'up'); }}
                  name="arrow_up"
                  width={16}
                  height={26}
                  data-test={`${testAttr}-icon_arrow_up`}
                />
              )}
              {!hideDownSort && (
                <Svg
                  onClick={e => { this.handleSort(e, 'down'); }}
                  name="arrow_down"
                  width={16}
                  height={26}
                  data-test={`${testAttr}-icon_arrow_down`}
                />
              )}
            </span>
          )}
          {collapsable &&
            <span onClick={this.handleCollapse}>
              {isCollapsed ?
                <Svg
                  name="chevron"
                  className={cx('chevron', 'chevron-up')}
                  width={16}
                  height={26}
                  data-test={`${testAttr}-chevron_up`}
                /> :
                <Svg
                  name="chevron"
                  className={cx('chevron', 'chevron-down')}
                  width={16}
                  height={26}
                  data-test={`${testAttr}-chevron_down`}
                />
              }
            </span>
          }
        </div>
        <Loader className="card-loader" show={isFetching} />
        <div className={cx('content', isFetching ? 'is-fetching' : '')}>
          {!isFetching && title && <h3>{title}</h3>}
          {children}
          {renderChildren && renderChildren()}
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  testAttr: 'card',
  isFetching: false,
  hideUpSort: false,
  hideDownSort: false,
};

Card.propTypes = {
  className: PropTypes.string,
  collapsable: PropTypes.bool,
  isFetching: PropTypes.bool,
  removable: PropTypes.bool,
  sortable: PropTypes.bool,
  hideUpSort: PropTypes.bool,
  hideDownSort: PropTypes.bool,
  testAttr: PropTypes.string,
  startCollapsed: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  onSort: PropTypes.func,
  title: PropTypes.string,
  renderChildren: PropTypes.func,
  children: PropTypes.node,
  index: PropTypes.number,
  name: PropTypes.string,
};

Card.displayName = 'Card';

export default Card;
