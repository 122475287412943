import styled from 'styled-components';

export const PickerContainer = styled.div`
  position: relative;
`;

export const TextButton = styled.button`
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
`;

export const ListButton = styled(TextButton)`
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: left;
`;

export const Picker = styled.div`
  position: absolute;
  bottom: 0.5rem;
  background: white;
  padding: 0.625rem 0;
  min-width: 18.75rem;
  max-width: 25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
  z-index: 2;
`;

export const LanguageList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const LanguageListItem = styled.li`
  &:hover {
    background: whitesmoke;
  }
`;
