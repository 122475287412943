import { createChartData } from '../containers/Returns/ReturnsDashboardAnalytics/returnsChartData';
import { API_REQUEST } from '../lib/api';
import { parseQueryFilters } from '../lib/monitor/filters';
import {
  RETURNS_ANALYTICS_DASHBOARD,
  RETURNS_ANALYTICS_REPORTING,
} from '../constants/endpoints';
import { pascalizeReportName } from '../lib/helpers';
import { RETURN_ANALYTICS } from '../constants/returns/dashboardAnalytics';

export const UPDATE_SELECTED_LOCALE =
  'RETURNS_ANALYTICS/UPDATE_SELECTED_LOCALE';
export const RECEIVE_DASHBOARD = 'RETURNS_ANALYTICS/RECEIVE_DASHBOARD';
export const UPDATE_DATE_RANGE = 'RETURNS_ANALYTICS/UPDATE_DATE_RANGE';
export const RECEIVE_CHART_DATA = 'RETURNS_ANALYTICS/RECEIVE_CHART_DATA';
export const RECEIVE_REPORT_DEFINITIONS =
  'RETURNS_ANALYTICS/RECEIVE_REPORT_DEFINITIONS';
export const DISMISS_ALERT = 'DISMISS_ALERT_RETURNS';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS_RETURNS';
export const DATE_RANGE_ERROR =
  'Please reduce the date range to 100 days or fewer';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const receiveError = error => ({
  type: RECEIVE_ERRORS,
  meta: { error },
});

const mapLocales = locales => locales.map(locale => ({ locale }));

export const updateSelectedLocale = selectedLocale => dispatch =>
  dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });

const receiveChartData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_DATA,
  payload: { chartData, tableData },
  meta: { chartName },
});

export const updateDateRange = (startDate, endDate) => dispatch =>
  dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

export const fetchDashboard = (
  path = RETURN_ANALYTICS,
  userLocales = [],
) => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:return_analytics',
    path: `${RETURNS_ANALYTICS_DASHBOARD}/${path}`,
  })
    .then(json => {
      const {
        dashboard_definitions /* eslint-disable-line camelcase */,
        dashboard_definitions: {
          [path]: { layout },
        },
        report_instances /* eslint-disable-line camelcase */,
        report_definitions /* eslint-disable-line camelcase */,
        supported_locales /* eslint-disable-line camelcase */,
        default_locale /* eslint-disable-line camelcase */,
      } = json;
      const supportedLocales = supported_locales.filter(l =>
        userLocales.includes(l),
      );
      const defaultLocale =
        supportedLocales &&
        supportedLocales.length &&
        !supportedLocales.includes(default_locale)
          ? supportedLocales[0]
          : default_locale; /* eslint-disable-line camelcase */

      // TODO: Refactor the mapp logic of laypout property once API structure updated
      dispatch({
        type: RECEIVE_DASHBOARD,
        payload: {
          dashboard_definitions: {
            ...dashboard_definitions /* eslint-disable-line camelcase */,
            [path]: {
              ...[path] /* eslint-disable-line camelcase */,
              layout: layout.reduce((formedLayout, chartRow, index) => {
                let mostReturnedItemsChart;
                chartRow.forEach(chart => {
                  if (chart.id === 'most_returned_items') {
                    mostReturnedItemsChart = chart;
                  }
                });
                if (mostReturnedItemsChart) {
                  const updatedLayout = [...formedLayout];
                  updatedLayout[index - 1] = [
                    mostReturnedItemsChart,
                    updatedLayout[index - 1],
                  ];
                  return updatedLayout;
                }
                return [...formedLayout, chartRow];
              }, []),
            },
          },
          report_instances,
          report_definitions,
          supported_locales: mapLocales(supportedLocales),
          default_locale: defaultLocale,
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });

export const fetchChartData = (queryParams, chartName, chartInfo) => (
  dispatch,
  getState,
) => {
  // TODO Copied from reportingActions - clean up this logic for Returns Analytics
  let { object } = getState().reporting;
  const name = `fetch${pascalizeReportName(chartName)}`;
  const query = { ...queryParams };
  // let object =  query.object.replace(/"/g, '');
  // Remove unecessary keys from the request
  Object.keys(query).forEach(key => {
    if (
      key === 'id' ||
      key === 'title' ||
      key === 'type' ||
      key === 'referrer' ||
      !query[key].length
    ) {
      delete query[key];
    }
  });

  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: RETURNS_ANALYTICS_REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        throw new Error(`${json.error}: ${json.msg}`);
      }

      // If we don't have the `object` from the report definition lets
      // get it from the query:

      object = query.object.replace(/"/g, '');
      const records = json[object];
      let chartData;

      if (chartInfo.type === 'single-metric') {
        let metricFilter;
        let dimension;
        let filter;

        if (chartInfo.metric_filter) {
          metricFilter = parseQueryFilters(chartInfo.metric_filter)[0];
          dimension = metricFilter.name.split('.')[1];
          filter = metricFilter.value;
        } else {
          dimension = queryParams.dimensions;
        }
        const { metrics } = records.find(
          r => r.dimensions[dimension] === filter,
        );
        const metricName = chartInfo.metric.split('.')[1]; // often undefined
        chartData = metricName
          ? metrics[metricName]
          : Object.values(metrics)[0];
      } else if (chartInfo.type === 'single-metric-with-benchmark') {
        chartData = []; // Adding benchmark value for version 2 dashboard
        const [mainMetricName, subMetricName] = query.metrics.split(','); //  example: ""percent_click_through_rate"",""percent_diff_from_benchmark""
        const {
          [mainMetricName.replace(/"/g, '')]: firstChartData,
          [subMetricName.replace(/"/g, '')]: secondChartData,
        } = records[0].metrics;
        chartData = [firstChartData, secondChartData];
      } else if (chartInfo.type === 'multiitem') {
        chartData = { t_return_details: records };
      } else {
        chartData = createChartData(records, query, chartName, chartInfo);
      }

      dispatch(receiveChartData(chartData, chartName, records));
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};
