export const RULE_MONIKERS = {
  RETURN_FEES: 'return_fees',
  RETURN_ELIGIBILITY: 'return_eligibility',
  RETURN_METHODS: 'return_method',
  RETURN_REASONS: 'return_reasons',
  RETURN_REFUND: 'return_refund_options',
  RETURN_DESTINATION_POLICY: 'destinations_policy',
  RETURN_METHODS_POLICY: 'return_methods_policy',
};

const RULE_TYPES = {
  CODE: 'code',
  LAMBDA: 'lambda',
  DATA: 'data',
};

const DEFAULT_ENABLEMENT = true;
const DEFAULT_SETTINGS = {
  [RULE_MONIKERS.RETURN_ELIGIBILITY]: DEFAULT_ENABLEMENT,
  [RULE_MONIKERS.RETURN_METHODS]: DEFAULT_ENABLEMENT,
  [RULE_MONIKERS.RETURN_REFUND]: DEFAULT_ENABLEMENT,
  [RULE_MONIKERS.RETURN_FEES]: DEFAULT_ENABLEMENT,
  [RULE_MONIKERS.RETURN_REASONS]: DEFAULT_ENABLEMENT,
};

const DEFAULT_NARVAR_USER_SETTINGS = {
  [RULE_MONIKERS.RETURN_ELIGIBILITY]: true,
  [RULE_MONIKERS.RETURN_METHODS]: true,
  [RULE_MONIKERS.RETURN_REFUND]: true,
  [RULE_MONIKERS.RETURN_FEES]: true,
  [RULE_MONIKERS.RETURN_REASONS]: true,
};

export const isCustomCode = (ruleMoniker, configs) => {
  if (!configs) return false;
  const config = configs.find(c => c.rule_moniker === ruleMoniker);
  if (!config) return false;
  return Boolean(
    config.rule_type === RULE_TYPES.CODE ||
      config.rule_type === RULE_TYPES.LAMBDA,
  );
};

const isRuleMonikerEnabled = (ruleMoniker, configs) => {
  const config = configs.find(c => c.rule_moniker === ruleMoniker);
  if (!config) return DEFAULT_ENABLEMENT;
  return !(
    config.rule_type === RULE_TYPES.CODE ||
    config.rule_type === RULE_TYPES.LAMBDA
  );
};

export const getRulesConfig = (retailerInfo, user) => {
  if (user && user.customerSupportLogin) return DEFAULT_NARVAR_USER_SETTINGS;

  if (!retailerInfo) return DEFAULT_SETTINGS;

  const configs = retailerInfo.returns_rules_config;

  if (!configs) {
    console.error('No rules configs found on retailerInfo object');
    return DEFAULT_SETTINGS;
  }

  return Object.values(RULE_MONIKERS).reduce(
    (acc, value) => ({
      ...acc,
      [value]: isRuleMonikerEnabled(value, configs),
    }),
    {},
  );
};
