export const COLORS = [
  '#3990c0',
  '#e9722e',
  '#983192',
  '#87ccce',
  '#f6b94d',
  '#eb678d',
  '#1b2781',
];

export const CHART_TYPES = {
  BAR: 'bar',
  DONUT: 'donut',
  GEO: 'geo',
  TIME_SERIES: 'time_series',
  LINE: 'line',
  GROUPED_BAR: 'grouped_bar',
  STACKED_BAR: 'stacked_bar',
};

export const CHART_AXIS_TYPES = {
  DAY: 'day',
  DAYS: 'days',
  GEO: 'geo',
  STRING: 'string',
  PERCENT: 'percent',
  INTEGER: 'integer',
  TIME: 'time',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
  AVERAGE: 'average',
  FLOAT: 'float',
  DATETIME: 'datetime',
  CATEGORY: 'category',
};

export const CHART_STACKING_TYPES = {
  PERCENT: 'percent',
  NORMAL: 'normal',
};

export const CHART_DATE_FORMATS = {
  FORMAT_1: 'MMM D', // Jan 1
  FORMAT_2: 'MMM DD, YYYY', // Jan 01, 2019
};

export const CHART_NUMERIC_FORMATS = {
  FORMAT_1: '0,0', // round numbers like -> 10, 20, 30
  FORMAT_2: '0.00', // Floating numbers like 10.6555-> 10.65, 20.343-> 20.34, 30 -> 30.00
  FORMAT_3: '0.[0]a', // Converts like 1400 -> 1.4k
  FORMAT_4: '0,0.00', // Just append the two digits floating points -> 10.00, 20.00, 30.00
};
