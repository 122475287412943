import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Button from '../Button';
import s from './ImageUploader.styles';

class ImageUploader extends React.Component {
  state = {
    width: 0,
    height: 0,
  };

  componentDidMount() {
    const { src } = this.props;

    if (src) {
      this.updateHeightAndWidth(src);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { src } = nextProps;
    const { src: oldSrc } = this.props;
    if (src && src !== oldSrc) {
      this.updateHeightAndWidth(src);
    }
  }

  updateHeightAndWidth = src => {
    const imagePlaceholder = new Image();
    imagePlaceholder.addEventListener('load', () => {
      this.setState({
        width: imagePlaceholder.width,
        height: imagePlaceholder.height,
      });
    });
    imagePlaceholder.src = src;
  };

  handleDrop = (...args) => {
    const { name, onDrop } = this.props;
    onDrop(...args, name);
  };

  render() {
    const {
      accept,
      title,
      device,
      src,
    } = this.props;

    const {
      height,
      width,
    } = this.state;

    return (
      <div className={s}>
        <Dropzone
          accept={accept}
          data-test={`${title.toLowerCase()}-${device.toLowerCase()}-dropzone`}
          onDrop={this.handleDrop}
          data-tip
          data-for="wrong-image"
          activeClassName="media-drop"
        >
          <div className="images-container">
            <div className="upload-button">
              <Button>UPLOAD ASSET</Button>
            </div>
            {src ? (
              <img
                alt="Preview"
                src={src}
                className={`${'image-preview'} ${width > height
                  ? 'image-wide'
                  : 'image-tall'}`}
              />
            ) : (
              <div>
                <div className="uploader-details" />
              </div>
            )}
          </div>
        </Dropzone>
      </div>
    );
  }
}

ImageUploader.defaultProps = {
  accept: '.jpg,.jpeg,.png',
  title: 'uploader',
  device: 'desktop',
  name: undefined,
  src: '',
};

ImageUploader.propTypes = {
  accept: PropTypes.string,
  title: PropTypes.string,
  device: PropTypes.string,
  name: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  src: PropTypes.string,
};

export default ImageUploader;
