import { colors, fonts } from '@narvar/hum-constants';

const datePickerTheme = {
  DatePicker: {
    background: colors.white,
  },
  Calendar: {
    background: 'transparent',
    color: colors.black,
    width: '230px',
    paddingTop: '13px',
  },
  MonthAndYear: {
    background: colors.white,
    color: colors.black,
    fontFamily: fonts.regular,
    fontSize: '14px',
  },
  Divider: {
    opacity: '0',
  },
  MonthButton: {
    background: colors.gray,
  },
  MonthArrowPrev: {
    borderRightColor: colors.white,
  },
  MonthArrowNext: {
    borderLeftColor: colors.white,
  },
  Weekday: {
    background: colors.white,
    color: colors.black,
    width: '30px',
    height: '20px',
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: 'normal',
    fontFamily: fonts.regular,
  },
  Day: {
    height: '20px',
    width: '30px',
    lineHeight: '18px',
    fontSize: '12px',
    color: colors.darkGray,
  },
  DayPassive: {
    color: 'rgba(97, 97, 97, 0.6)',
    opacity: '0',
    lineHeight: '18px',
    font: '12px',
  },
  DaySelected: {
    background: colors.blue,
    height: '20px',
    width: '30px',
    lineHeight: '18px',
    fontSize: '12px',
    color: colors.white,
  },
  DayActive: {
    background: colors.blue,
    boxShadow: 'none',
    height: '20px',
    width: '30px',
    lineHeight: '18px',
    fontSize: '12px',
  },
  DayInRange: {
    background: 'rgba(19, 153, 214, 0.1)',
    height: '20px',
    width: '30px',
    lineHeight: '18px',
    fontSize: '12px',
    color: colors.darkGray,
  },
  DayHover: {
    background: colors.blue,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },
  DayActive: {
    background: colors.darkBlue,
    color: colors.white,
    transform: 'none',
    boxShadow: 'none',
  },
};

export default datePickerTheme;
