/* eslint-disable camelcase */
import { API_REQUEST } from '../lib/api';
import {
  PROMISE_ORDER_DATA_DASHBOARD_LAYOUT,
  PROMISE_SHIPMENT_DATA_DASHBOARD_LAYOUT,
  REPORTING,
} from '../constants/endpoints';
import { pascalizeReportName } from '../lib/helpers';
import { createChartData } from '../containers/Ship/PromiseAnalytics/PromiseChartData';

export const RECEIVE_ORDER_DASHBOARD = 'PROMISE_ORDER_DATA/RECEIVE_DASHBOARD';
export const RECEIVE_CHART_ORDER_DATA = 'PROMISE_ORDER_DATA/RECEIVE_CHART_DATA';
export const RECEIVE_SHIPMENT_DASHBOARD =
  'PROMISE_SHIPMENT_DATA/RECEIVE_DASHBOARD';
export const RECEIVE_CHART_SHIPMENT_DATA =
  'PROMISE_SHIPMENT_DATA/RECEIVE_CHART_DATA';
export const UPDATE_DATE_RANGE = 'PROMISE_ORDER_DATA/UPDATE_DATE_RANGE';
export const RECEIVE_ERROR = 'PROMISE_ORDER_DATA/RECEIVE_ERROR_EMAIL';
export const DISMISS_ALERT = 'PROMISE_ORDER_DATA/DISMISS_ALERT_EMAIL';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const receiveError = error => ({
  type: RECEIVE_ERROR,
  meta: { error },
});

const receiveChartOrderData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_ORDER_DATA,
  payload: { orderDataQuality: { chartData, tableData } },
  meta: { chartName },
});

const receiveChartShipmentData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_SHIPMENT_DATA,
  payload: { shipmentDataQuality: { chartData, tableData } },
  meta: { chartName },
});

export const updateDateRange = (startDate, endDate) => dispatch =>
  dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

export const fetchOrderQualityDashboard = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:promise_data_quality',
    path: PROMISE_ORDER_DATA_DASHBOARD_LAYOUT,
  })
    .then(json => {
      const {
        dashboard_definitions,
        dashboard_definitions: {
          promise_data_quality,
          promise_data_quality: { layout },
        },
        report_instances,
        report_definitions,
        default_locale,
      } = json;
      dispatch({
        type: RECEIVE_ORDER_DASHBOARD,
        payload: {
          orderDataQuality: {
            dashboard_definitions: {
              ...dashboard_definitions,
              promise_data_quality: {
                ...promise_data_quality,
                layout,
              },
            },
            report_instances,
            report_definitions,
            default_locale,
          },
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });

export const fetchCarrierQualityDashboard = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:promise_shipment_quality',
    path: PROMISE_SHIPMENT_DATA_DASHBOARD_LAYOUT,
  })
    .then(json => {
      const {
        dashboard_definitions,
        dashboard_definitions: {
          promise_shipment_quality,
          promise_shipment_quality: { layout },
        },
        report_instances,
        report_definitions,
        default_locale,
      } = json;
      dispatch({
        type: RECEIVE_SHIPMENT_DASHBOARD,
        payload: {
          shipmentDataQuality: {
            dashboard_definitions: {
              ...dashboard_definitions,
              promise_shipment_quality: {
                ...promise_shipment_quality,
                layout,
              },
            },
            report_instances,
            report_definitions,
            default_locale,
          },
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });

export const fetchOrderData = (
  query,
  chartName,
  chartInfo,
  isShipment,
) => dispatch => {
  const name = `fetch${pascalizeReportName(chartName)}`;
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        throw new Error(`${json.error}: ${json.msg}`);
      }

      let chartData;
      const object = query.object.replace(/"/g, '');
      const records = json[object];

      /**
       * chartInfo.type is going to be single-metric for order data quality
          and bar for shipment data quality in our Data Quality Page
          so we can just check the isShipment flag
       */
      if (isShipment) {
        chartData = createChartData(records, query, chartName, chartInfo);
        dispatch(receiveChartShipmentData(chartData, chartName, records));
      } else {
        chartData = Object.values(records[0].metrics)[0];
        dispatch(receiveChartOrderData(chartData, chartName, records));
      }
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};
