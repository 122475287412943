import {
  downloadPortableConfig,
  uploadPortableConfig,
  confirmUploadPortableConfig,
  isManifestHasValidModules,
} from './portableConfigActions';

import {
  fetchPublishRules,
  fetchDefinitions,
  fetchRules,
  receiveRules,
  receiveDefinitions,
} from './returns/rules';

export const RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE =
  'RETURN_RULES/RECEIVE_UPLOAD_RESPONSE';
export const RESET_UPLOADED_PORTABLE_CONFIG =
  'RETURN_RERULES/RESET_UPLOADED_CONFIG';
export const RECEIVE_RETURNS_RULES = 'RECEIVE_RETURNS_RULES';
export const RECEIVE_RETURNS_RULES_DEFINITIONS =
  'RECEIVE_RETURNS_RULES_DEFINITIONS';
export const SAVE_RULE = 'SAVE_RULE';

export const API_ERRORS = 'API_ERRORS';
export const RECEIVE_RETURNS_RULES_PUBLISH = 'RECEIVE_RETURNS_RULES_PUBLISH';
export const RECEIVE_RETURNS_RULES_ERROR = 'RECEIVE_RETURNS_RULES_ERROR';
export const RECEIVE_RETURNS_RULES_SUCCESS = 'RECEIVE_RETURNS_RULES_SUCCESS';
export const RECEIVE_RETURNS_RULES_METADATA = 'RECEIVE_RETURNS_RULES_METADATA';

export const RECEIVE_MODES = 'RECEIVE_MODES';

export const RECEIVE_DCS = 'RECEIVE_DCS';

export const MULTI_CARRIER_MIGRATION = 'MULTI_CARRIER_MIGRATION';

export const PUBLISHING_STATE = 'PUBLISHING_STATE';

export const PRELOAD_RETURN_RULES = 'PRELOAD_RETURN_RULES';

export const setPublishingState = ({ isPublishing }) => ({
  type: PUBLISHING_STATE,
  isPublishing,
});

export const receiveModes = modes => {
  return {
    type: RECEIVE_MODES,
    modes,
  };
};

export const receiveDCs = dcs => {
  return {
    type: RECEIVE_DCS,
    dcs,
  };
};

export const receiveRulesSuccess = messages => {
  return {
    type: RECEIVE_RETURNS_RULES_SUCCESS,
    messages,
  };
};

export const receiveRulesError = messages => ({
  type: RECEIVE_RETURNS_RULES_ERROR,
  messages,
});

export const receiveRulesMetadata = ({ modifiedBy, modifiedDate }) => ({
  type: RECEIVE_RETURNS_RULES_METADATA,
  modifiedBy,
  modifiedDate,
});

export const saveRule = () => ({
  type: SAVE_RULE,
});

const getConfigPortModuleName = apiVersion => {
  if (apiVersion === 1) return 'return_rules_v1';
  return 'return_rules_v3';
};

export const downloadReturnRulesPortableConfig = (
  apiVersion = 1,
) => async dispatch => {
  try {
    const moduleName = getConfigPortModuleName(apiVersion);
    await dispatch(
      downloadPortableConfig({
        appName: 'returns',
        module: { [moduleName]: true },
      }),
    );
    dispatch(receiveRulesSuccess('Successfully Download'));
  } catch (error) {
    dispatch(receiveRulesError('Download Fail'));
  }
};

export const uploadReturnRulesPortableConfig = ({
  selectedFile,
  apiVersion = 1,
}) => async dispatch => {
  try {
    const {
      data: manifest,
      data: { module },
    } = await dispatch(
      uploadPortableConfig({
        selectedFile,
      }),
    );

    const moduleName = getConfigPortModuleName(apiVersion);
    const isUploadedConfigValid = isManifestHasValidModules({
      manifestModule: module,
      validModules: [moduleName],
    });

    if (isUploadedConfigValid) {
      dispatch({
        type: RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
        payload: manifest,
      });
    } else {
      dispatch(
        receiveRulesError(
          'Please only upload Return Rules Configuration on this page',
        ),
      );
    }
  } catch (error) {
    dispatch(receiveRulesError('Upload Fail'));
  }
};

export const resetUploadedPortableConfig = () => dispatch => {
  dispatch({
    type: RESET_UPLOADED_PORTABLE_CONFIG,
  });
};

export const confirmReturnRulesPortableConfig = ({
  apiVersion = 1,
  manifest,
}) => async dispatch => {
  try {
    const moduleName = getConfigPortModuleName(apiVersion);
    await dispatch(
      confirmUploadPortableConfig({
        manifest: {
          ...manifest,
          module: { [moduleName]: true },
        },
      }),
    );
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch(receiveRulesSuccess('Successfully Uploaded'));
    dispatch(fetchRules({ apiVersion }));
    dispatch(fetchDefinitions({ apiVersion }));
  } catch (error) {
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch(receiveRulesError('Upload Fail'));
  }
};

export const apiErrors = ({ error }) => ({
  type: API_ERRORS,
  error,
});

export const preloadReturnsRules = ({ id, ruleType } = {}) => ({
  type: PRELOAD_RETURN_RULES,
  payload: { id, ruleType },
});

export {
  fetchPublishRules,
  fetchDefinitions,
  fetchRules,
  receiveRules,
  receiveDefinitions,
};
