import React, { Component } from 'react';
import PropTypes from 'prop-types';
import addAttachmentIcon from '../../assets/images/add_attachment.svg';
import addEmailIcon from '../../assets/images/add_email.svg';
import adminIcon from '../../assets/images/admin.svg';
import appleIcon from '../../assets/images/apple.svg';
import arrowDownIcon from '../../assets/images/arrow_down.svg';
import arrowUpIcon from '../../assets/images/arrow_up.svg';
import assistIcon from '../../assets/images/assist.svg';
import bellIcon from '../../assets/images/bell.svg';
import carriersIcon from '../../assets/images/carriers.svg';
import checkIcon from '../../assets/images/check.svg';
import chevronIcon from '../../assets/images/chevron.svg';
import clockIcon from '../../assets/images/clock.svg';
import closeIcon from '../../assets/images/close.svg';
import deleteIcon from '../../assets/images/delete.svg';
import deliveredIcon from '../../assets/images/delivered.svg';
import desktopIcon from '../../assets/images/desktop.svg';
import downloadIcon from '../../assets/images/download.svg';
import editIcon from '../../assets/images/edit.svg';
import emailIcon from '../../assets/images/email.svg';
import emailSentIcon from '../../assets/images/email_sent.svg';
import equalIcon from '../../assets/images/equal.svg';
import exceptionIcon from '../../assets/images/exception.svg';
import exitIcon from '../../assets/images/exit.svg';
import failIcon from '../../assets/images/fail.svg';
import flagIcon from '../../assets/images/flag.svg';
import hideIcon from '../../assets/images/hide.svg';
import homeIcon from '../../assets/images/home.svg';
import imgIcon from '../../assets/images/img.svg';
import inTransitIcon from '../../assets/images/in_transit.svg';
import infoIcon from '../../assets/images/info.svg';
import justShippedIcon from '../../assets/images/just_shipped.svg';
import menuIcon from '../../assets/images/menu.svg';
import mobileIcon from '../../assets/images/mobile.svg';
import monitorIcon from '../../assets/images/monitor.svg';
import narvarLogoIcon from '../../assets/images/narvar_logo.svg';
import newIcon from '../../assets/images/new.svg';
import notificationIcon from '../../assets/images/notification.svg';
import notifyIcon from '../../assets/images/notify.svg';
import platformIcon from '../../assets/images/platform.svg';
import plusIcon from '../../assets/images/plus.svg';
import refreshIcon from '../../assets/images/refresh.svg';
import returnIcon from '../../assets/images/return.svg';
import searchIcon from '../../assets/images/search.svg';
import settingsIcon from '../../assets/images/settings.svg';
import shipIcon from '../../assets/images/ship.svg';
import showIcon from '../../assets/images/show.svg';
import starIcon from '../../assets/images/star.svg';
import starHalfIcon from '../../assets/images/star_half.svg';
import tabletIcon from '../../assets/images/tablet.svg';
import trackIcon from '../../assets/images/track.svg';
import triangleIcon from '../../assets/images/triangle.svg';
import unfilledArrowIcon from '../../assets/images/unfilledArrow.svg';
import uploadIcon from '../../assets/images/upload.svg';

const icons = {
  add_attachment: addAttachmentIcon,
  add_email: addEmailIcon,
  admin: adminIcon,
  apple: appleIcon,
  arrow_down: arrowDownIcon,
  arrow_up: arrowUpIcon,
  assist: assistIcon,
  bell: bellIcon,
  carriers: carriersIcon,
  check: checkIcon,
  chevron: chevronIcon,
  clock: clockIcon,
  close: closeIcon,
  delete: deleteIcon,
  delivered: deliveredIcon,
  desktop: desktopIcon,
  download: downloadIcon,
  edit: editIcon,
  email: emailIcon,
  email_sent: emailSentIcon,
  equal: equalIcon,
  exception: exceptionIcon,
  exit: exitIcon,
  fail: failIcon,
  flag: flagIcon,
  hide: hideIcon,
  home: homeIcon,
  img: imgIcon,
  in_transit: inTransitIcon,
  info: infoIcon,
  just_shipped: justShippedIcon,
  menu: menuIcon,
  mobile: mobileIcon,
  monitor: monitorIcon,
  narvar_logo: narvarLogoIcon,
  new: newIcon,
  notification: notificationIcon,
  notify: notifyIcon,
  platform: platformIcon,
  plus: plusIcon,
  refresh: refreshIcon,
  return: returnIcon,
  search: searchIcon,
  settings: settingsIcon,
  ship: shipIcon,
  show: showIcon,
  star: starIcon,
  star_half: starHalfIcon,
  tablet: tabletIcon,
  track: trackIcon,
  triangle: triangleIcon,
  unfilledArrow: unfilledArrowIcon,
  upload: uploadIcon,
};

export class Svg extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.name !== nextProps.name ||
      this.props.className !== nextProps.className;
  }

  render() {
    const { className, width, height, title, name } = this.props;
    return (
      <svg
        className={className}
        width={width || 20}
        height={height || 20}
        data-test={name}
        {...this.props}
      >
        {title && <title id="title">{title}</title>}
        <g
          dangerouslySetInnerHTML={{
            __html: icons[name],
          }}
        />
      </svg>
    );
  }
}

Svg.propTypes = {};

export default Svg;
