import { RECEIVE_SHIP_METABASE_URL } from '~src/actions/metabaseAction';

const initialState = {
  shipDashboard: '',
};

function shipMetabaseReducer(state = initialState, action) {
  const { type, url } = action;

  switch (type) {
    case RECEIVE_SHIP_METABASE_URL:
      return {
        ...state,
        shipDashboard: url,
      };

    default:
      return state;
  }
}

export default shipMetabaseReducer;
