import { API_REQUEST } from '~src/lib/api';
import {
  MESSAGING_EMAIL_ANALYTICS_DASHBOARD_LAYOUT,
  MESSAGING_EMAIL_ANALYTICS_REPORTING,
} from '~src/constants/endpoints';
import { pascalizeReportName } from '~src/lib/helpers';
import { createChartData } from '~src/containers/Messaging/MessagingEmailDashboardAnalytics/messagingChartData';

export const RECEIVE_DASHBOARD = 'MESSAGING_EMAIL_ANALYTICS/RECEIVE_DASHBOARD';
export const UPDATE_DATE_RANGE = 'MESSAGING_EMAIL_ANALYTICS/UPDATE_DATE_RANGE';
export const UPDATE_SELECTED_LOCALE =
  'MESSAGING_EMAIL_ANALYTICS/UPDATE_SELECTED_LOCALE';
export const RECEIVE_CHART_DATA =
  'MESSAGING_EMAIL_ANALYTICS/RECEIVE_CHART_DATA';
export const RECEIVE_ERROR = 'RECEIVE_ERROR_EMAIL';
export const DISMISS_ALERT = 'DISMISS_ALERT_EMAIL';
export const DATE_RANGE_ERROR =
  'Please reduce the date range to 100 days or fewer';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const receiveError = error => ({
  type: RECEIVE_ERROR,
  meta: { error },
});

const mapLocales = locales => locales.map(locale => ({ locale }));

export const updateSelectedLocale = selectedLocale => dispatch =>
  dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });

export const updateDateRange = (startDate, endDate) => dispatch =>
  dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

const receiveChartData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_DATA,
  payload: { chartData, tableData },
  meta: { chartName },
});

export const fetchDashboard = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:email_engagement',
    path: MESSAGING_EMAIL_ANALYTICS_DASHBOARD_LAYOUT,
  })
    .then(json => {
      const {
        dashboard_definitions /* eslint-disable-line camelcase */,
        dashboard_definitions: {
          email_engagement /* eslint-disable-line camelcase */,
          email_engagement: { layout },
        },
        report_instances /* eslint-disable-line camelcase */,
        report_definitions /* eslint-disable-line camelcase */,
        supported_locales /* eslint-disable-line camelcase */,
        default_locale /* eslint-disable-line camelcase */,
      } = json;
      // JSON Returned
      // console.log(json);
      // TODO: Refactor the mapp logic of laypout property once API structure updated
      dispatch({
        type: RECEIVE_DASHBOARD,
        payload: {
          dashboard_definitions: {
            ...dashboard_definitions /* eslint-disable-line camelcase */,
            email_engagement: {
              ...email_engagement /* eslint-disable-line camelcase */,
              layout,
            },
          },
          report_instances,
          report_definitions,
          supported_locales: mapLocales(supported_locales),
          default_locale,
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });

export const fetchChartData = (query, chartName, chartInfo) => dispatch => {
  const name = `fetch${pascalizeReportName(chartName)}`;
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: MESSAGING_EMAIL_ANALYTICS_REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        throw new Error(`${json.error}: ${json.msg}`);
      }
      const object = query.object.replace(/"/g, '');
      const records = json[object];
      let chartData;
      if (chartInfo.type === 'single-metric') {
        chartData = Object.values(records[0].metrics)[0];
      } else if (chartInfo.type === 'single-metric-with-benchmark') {
        chartData = [];
        const [mainMetricName, subMetricName] = query.metrics.split(','); //  example: ""percent_click_through_rate"",""percent_diff_from_benchmark""
        const {
          [mainMetricName.replace(/"/g, '')]: firstChartData,
          [subMetricName.replace(/"/g, '')]: secondChartData,
        } = records[0].metrics;
        chartData = [firstChartData, secondChartData];
      } else if (chartInfo.type === 'multiitem') {
        chartData = { t_return_details: records };
      } else {
        chartData = createChartData(records, query, chartName, chartInfo);
      }
      dispatch(receiveChartData(chartData, chartName, records));
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};
