import { API_REQUEST, API_INVALIDATE_PATH } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import { res } from '../constants/storeLocator.js';
import { EDD_CACHE_TYPE } from '../constants/copy/shipEddRules';

export const RECEIVE_TRACK_SETTINGS = 'RECEIVE_TRACK_SETTINGS';
export const RECEIVE_TRACK_SETTINGS_ERROR = 'RECEIVE_TRACK_SETTINGS_ERROR';
export const RECEIVE_TRACK_SETTINGS_SAVE = 'RECEIVE_TRACK_SETTINGS_SAVE';
export const DISMISS_TRACK_SETTINGS_ALERT = 'DISMISS_TRACK_SETTINGS_ALERT';
export const RECEIVE_STORE_LOCATOR_CONFIG = 'RECEIVE_STORE_LOCATOR_CONFIG';
export const UPDATE_EDD_USE_PROMISE_DATE = 'UPDATE_EDD_USE_PROMISE_DATE';
export const UPDATE_URL_OVERRIDE_ORDER = 'UPDATE_URL_OVERRIDE_ORDER';
export const UPDATE_EDD_FORMAT_SELECT = 'UPDATE_EDD_FORMAT_SELECT';
export const UPDATE_EDD_INTERNATIONAL_DATE_FORMATTING =
  'UPDATE_EDD_INTERNATIONAL_DATE_FORMATTING';
export const ADD_NO_EDD_CARRIER = 'ADD_NO_EDD_CARRIER';
export const REMOVE_NO_EDD_CARRIER = 'REMOVE_NO_EDD_CARRIER';
export const ADD_EDD_HOLIDAY_CLOSURE_FIELD = 'ADD_EDD_HOLIDAY_CLOSURE_FIELD';
export const UPDATE_EDD_HOLIDAY_CLOSURE_FIELD =
  'UPDATE_EDD_HOLIDAY_CLOSURE_FIELD';
export const DELETE_EDD_HOLIDAY_CLOSURE_FIELD =
  'DELETE_EDD_HOLIDAY_CLOSURE_FIELD';
export const UPDATE_EDD_WORKING_SCHEDULE = 'UPDATE_EDD_WORKING_SCHEDULE';
export const UPDATE_STORE_LOCATOR_SELECT = 'UPDATE_STORE_LOCATOR_SELECT';
export const UPDATE_STORE_LOCATOR_INPUT = 'UPDATE_STORE_LOCATOR_INPUT';
export const SWITCH_STORE_LOCATOR_CONFIG = 'SWITCH_STORE_LOCATOR_CONFIG';
export const DELETE_STORE_LOCATOR_CONFIG = 'TURN_ON_STORE_LOCATOR_CONFIG';
export const RECEIVE_DELETE_STORE_LOCATOR_CONFIG =
  'RECEIVE_DELETE_STORE_LOCATOR_CONFIG';
export const RESET_STORE_LOCATOR_STATE = 'RESET_STORE_LOCATOR_STATE';
export const UPDATE_IS_EDITING_FORM = 'UPDATE_IS_EDITING_FORM';

export const updateIsEditingForm = () => ({
  type: UPDATE_IS_EDITING_FORM,
  isEditingForm: true,
});

export const receiveTrackSettings = payload => ({
  type: RECEIVE_TRACK_SETTINGS,
  payload,
});

export const receiveTrackSettingsError = messages => ({
  type: RECEIVE_TRACK_SETTINGS_ERROR,
  messages,
});

export const receiveTrackSettingsSave = () => ({
  type: RECEIVE_TRACK_SETTINGS_SAVE,
});

export const dismissTrackSettingsAlert = () => ({
  type: DISMISS_TRACK_SETTINGS_ALERT,
});

export const fetchEddTrackConfig = () => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.TRACK_SETTINGS,
      name: 'fetchEddConfig',
    });
    dispatch(receiveTrackSettings(response));
  } catch (err) {
    console.error(err);
  }
};

export const fetchSaveTrackEddSettings = () => async (dispatch, getState) => {
  const {
    trackSettingsReducer: { eddConfig },
  } = getState();
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.TRACK_SETTINGS,
    });
    const eddResponse = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      body: JSON.stringify(eddConfig),
      path: ENDPOINTS.TRACK_SETTINGS,
      name: 'fetchUpdateTrackSettings',
    });
    if (eddResponse.status === 'FAILURE') {
      throw new Error();
    } else {
      await dispatch({
        type: API_REQUEST,
        method: 'POST',
        body: JSON.stringify({ cacheType: EDD_CACHE_TYPE.TRACK }),
        path: ENDPOINTS.REFRESH_EDD_CACHE,
        name: 'refreshEDDApiCache',
      });
      dispatch(receiveTrackSettingsSave());
    }
  } catch (err) {
    dispatch(
      receiveTrackSettingsError(
        'There was an error updating the Track EDD settings. Please, try again.',
      ),
    );
  }
};

export const fetchSaveTrackSettings = () => async (dispatch, getState) => {
  const {
    trackSettingsReducer: { eddConfig, storeLocatorConfig },
  } = getState();
  const storeLocatorForm = res[storeLocatorConfig.source.selected];
  const storeLocatorBody = storeLocatorForm.reduce(
    (bodyGroup, key) => {
      if (bodyGroup[key] === undefined) return bodyGroup;
      return {
        ...bodyGroup,
        [key]:
          typeof bodyGroup[key] === 'object'
            ? {
                selected: storeLocatorConfig[key].selected,
                value: storeLocatorConfig[`${key}_drilldown`].selected,
              }
            : storeLocatorConfig[key].selected ||
              storeLocatorConfig[key].data ||
              '',
      };
    },
    {
      enabled: storeLocatorConfig.enabled,
      source: '',
      website_url: '',
      business_type: '',
      store_name: '',
      search_by: {
        selected: '',
        value: '',
      },
    },
  );

  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.TRACK_SETTINGS,
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.STORE_LOCATOR_CONFIGURATION,
    });
    const eddResponse = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      body: JSON.stringify(eddConfig),
      path: `${ENDPOINTS.TRACK_SETTINGS}`,
      name: 'fetchUpdateTrackSettings',
    });
    const storeLocatorResponse = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      body: JSON.stringify(storeLocatorBody),
      path: `${ENDPOINTS.STORE_LOCATOR_CONFIGURATION}/publish`,
      name: 'fetchUpdateStoreLocatorSettings',
    });

    if (
      eddResponse.status === 'FAILURE' ||
      storeLocatorResponse.status === 'FAILURE'
    ) {
      dispatch(
        receiveTrackSettingsError(
          'There was an error updating the track settings.',
        ),
      );
    } else {
      await dispatch({
        type: API_REQUEST,
        method: 'POST',
        body: JSON.stringify({ cacheType: EDD_CACHE_TYPE.TRACK }),
        path: ENDPOINTS.REFRESH_EDD_CACHE,
        name: 'refreshEDDApiCache',
      });

      dispatch(receiveTrackSettingsSave());
    }
  } catch (err) {
    dispatch(
      receiveTrackSettingsError(
        'There was an error updating the track settings.',
      ),
    );
  }
};

export const updateUseRetailerPromiseDate = checked => ({
  type: UPDATE_EDD_USE_PROMISE_DATE,
  meta: {
    checked,
  },
});

export const updateUrlOverrideOrder = checked => ({
  type: UPDATE_URL_OVERRIDE_ORDER,
  meta: {
    checked,
  },
});

export const updateInternationalDateFormatting = checked => ({
  type: UPDATE_EDD_INTERNATIONAL_DATE_FORMATTING,
  meta: {
    checked,
  },
});

export const updateEddFormatSelect = value => ({
  type: UPDATE_EDD_FORMAT_SELECT,
  meta: {
    value,
  },
});

export const addNoEddCarrier = value => ({
  type: ADD_NO_EDD_CARRIER,
  meta: {
    value,
  },
});

export const removeNoEddCarrier = value => ({
  type: REMOVE_NO_EDD_CARRIER,
  meta: {
    value,
  },
});

export const saveRetailerTntFile = ([csvFile]) => async dispatch => {
  try {
    const formData = new FormData();
    formData.set('file', csvFile);
    const fileResponse = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: formData,
      path: ENDPOINTS.TRACK_SETTINGS_FILE_UPLOAD,
      name: 'saveRetailerTntFile',
      contentType: 'multipart/form-data',
    });
  } catch (err) {
    console.error(err);
  }
};

export const addHolidayClosure = defaultDate => ({
  type: ADD_EDD_HOLIDAY_CLOSURE_FIELD,
  meta: {
    defaultDate,
  },
});

export const updateHolidayClosureField = (closureIndex, field, value) => ({
  type: UPDATE_EDD_HOLIDAY_CLOSURE_FIELD,
  meta: {
    closureIndex,
    field,
    value,
  },
});

export const deleteHolidayClosureField = closureIndex => ({
  type: DELETE_EDD_HOLIDAY_CLOSURE_FIELD,
  meta: {
    closureIndex,
  },
});

export const updateWorkingSchedule = (weekdayIndex, value) => ({
  type: UPDATE_EDD_WORKING_SCHEDULE,
  meta: {
    weekdayIndex,
    value: value ? 1 : 0,
  },
});

export const updateStoreLocatorInput = (field, value) => ({
  type: UPDATE_STORE_LOCATOR_INPUT,
  meta: {
    field,
    value,
  },
});

export const receiveStoreLocatorConfig = payload => ({
  type: RECEIVE_STORE_LOCATOR_CONFIG,
  payload,
});

export const fetchStoreLocatorConfig = () => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.STORE_LOCATOR_CONFIGURATION,
      name: 'fetchStoreLocatorConfig',
    });

    dispatch(receiveStoreLocatorConfig(response));
  } catch (err) {
    console.error(err);
  }
};

export const receiveDeleteStoreLocatorConfig = () => ({
  type: RECEIVE_DELETE_STORE_LOCATOR_CONFIG,
});

export const resetStoreLocatorState = () => ({
  type: RESET_STORE_LOCATOR_STATE,
});

export const deleteStoreLocatorConfig = () => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.STORE_LOCATOR_CONFIGURATION}/delete`,
      name: 'DeleteStoreLocatorConfig',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.STORE_LOCATOR_CONFIGURATION,
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.STORE_LOCATOR_CONFIGURATION}/delete`,
    });

    if (response.status === 'FAILURE') {
      dispatch(receiveTrackSettingsError(response.error));
      dispatch(resetStoreLocatorState());
    } else {
      dispatch(receiveDeleteStoreLocatorConfig());
      dispatch(resetStoreLocatorState());
    }
  } catch (err) {
    console.error(err);
  }
};

export const switchStoreLocatorConfig = (field, value) => ({
  type: SWITCH_STORE_LOCATOR_CONFIG,
  meta: {
    field,
    value,
  },
});

export const updateStoreLocatorEnabledSwitch = (field, value) => dispatch => {
  if (!value) {
    dispatch(deleteStoreLocatorConfig());
  } else {
    dispatch(switchStoreLocatorConfig(field, value));
  }
};

export const updateStoreLocatorSelect = (field, value) => ({
  type: UPDATE_STORE_LOCATOR_SELECT,
  meta: {
    field,
    value,
  },
});
