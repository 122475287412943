import { css, keyframes } from 'emotion';
import { colors } from '@narvar/hum-constants';

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const thickness = '1em';

const s = css`
  margin: 60px auto;
  font-size: 4px;
  position: relative;
  text-indent: -9999em;
  border-top: ${thickness} solid ${colors.blue};
  border-right: ${thickness} solid ${colors.blue};
  border-bottom: ${thickness} solid ${colors.blue};
  border-left: ${thickness} solid transparent;
  transform: translateZ(0);
  animation: ${spin} 1.1s infinite linear;
  border-radius: 50%;
  width: 10em;
  height: 10em;
`;
// NOTE how to make the loading icon bigger

export default s;
