import moment from 'moment';
import { API_REQUEST } from '~src/lib/api';
import { pascalizeReportName } from '~src/lib/helpers';
import * as ENDPOINTS from '~src/constants/endpoints';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';
import EXCEPTIONS_ANALYTICS_REPORTS_CONFIG, {
  ALL_CARRIERS_VALUE,
  DATASET,
} from '~src/constants/exceptionsAnalyticsApis';
import generateCarrierDataPerChart from '~src/lib/exceptions/generateCarrierDataPerChart';
import generateOrderListQuery from '~src/lib/exceptions/generateOrderListQuery';
import mapOrderRowsToTable from '~src/containers/Exceptions/ExceptionsReports/mapOrderRowsToTable';
import copy from '~src/constants/copy/exceptions';

export const DISMISS_ALERT = 'DISMISS_ALERT';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const SELECT_DATE_RANGE = 'SELECT_DATE_RANGE';
export const RECEIVE_FEATURES_ENABLED_IN_EXCEPTIONS =
  'RECEIVE_FEATURES_ENABLED_IN_EXCEPTIONS';
export const RECEIVE_EXCEPTIONS_ANALYTICS_REPORT =
  'RECEIVE_EXCEPTIONS_ANALYTICS_REPORT';
export const SELECT_CARRIER = 'SELECT_CARRIER';
export const RECEIVE_CHART_DETAILS = 'EXCEPTIONS_REPORTS/RECEIVE_CHART_DETAILS';
export const RECEIVE_EXPORT_CHART_DETAILS =
  'EXCEPTIONS_REPORTS/RECEIVE_EXPORT_CHART_DETAILS';
export const UPDATE_ACTIVE_PAGE = 'EXCEPTIONS_REPORTS/UPDATE_ACTIVE_PAGE';
export const UPDATE_HAS_DATE_CHANGED =
  'EXCEPTIONS_REPORTS/UPDATE_HAS_DATE_CHANGED';
export const RECEIVE_CARRIERS_LIST = 'RECEIVE_CARRIERS_LIST';

export const RECORDS_PER_PAGE = 25;

const receiveError = error => ({
  type: RECEIVE_ERRORS,
  meta: { error },
});

export const formatDates = ({ startDate, endDate }) => ({
  startDateFormatted: moment(startDate).format(API_DATE_FORMAT),
  endDateFormatted: moment(endDate).format(API_DATE_FORMAT),
});

export const dateChanged = hasDateChanged => ({
  type: UPDATE_HAS_DATE_CHANGED,
  payload: { hasDateChanged },
});

export const receivedEnabledFeaturesInExceptions = payload => ({
  type: RECEIVE_FEATURES_ENABLED_IN_EXCEPTIONS,
  payload,
});

export const receivedCarriersList = payload => ({
  type: RECEIVE_CARRIERS_LIST,
  payload,
});

export const fetchRetailerSettings = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}?locale=en_US&condition=none`,
    });

    const { retailer_settings: retailerSettings } = json;

    const parsedSettings = JSON.parse(retailerSettings);
    const {
      features: {
        customer_exceptions: {
          enableDashboard: exceptionsFeatureEnabled = false,
        } = {},
      } = {},
      carriers = [],
    } = parsedSettings;
    dispatch(receivedEnabledFeaturesInExceptions({ exceptionsFeatureEnabled }));
    dispatch(receivedCarriersList({ carriers }));
  } catch (e) {
    dispatch(receiveError(e));
  }
};

const receiveBigQueryExceptionsDetails = payload => dispatch => {
  dispatch({
    type: RECEIVE_EXCEPTIONS_ANALYTICS_REPORT,
    payload,
  });
};

export const fetchExceptionsAnalytics = id => (dispatch, getState) => {
  const {
    exceptionsAnalyticsReducer: { dateRange } = {},
    userReducer: {
      user: {
        retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const { startDateFormatted, endDateFormatted } = formatDates(dateRange);

  const exceptionsConfigKeys = id
    ? [id]
    : Object.keys(EXCEPTIONS_ANALYTICS_REPORTS_CONFIG);

  // eslint-disable-next-line array-callback-return
  exceptionsConfigKeys.map(config => {
    const {
      metrics,
      reportId,
      precision = '',
      dimensions = '',
    } = EXCEPTIONS_ANALYTICS_REPORTS_CONFIG[config];
    const name = `fetch${pascalizeReportName(reportId)}`;

    let query = '';
    query = {
      date_filter: `"order_date" ge ${startDateFormatted} and "order_date" lt ${endDateFormatted}`,
      object: '"exceptions_dashboard"',
      metrics: `"${metrics.join('","')}"`,
      reportId,
      dataset: DATASET,
      retailer: retailerMoniker,
      retailerId,
    };

    if (precision) {
      query.precision = precision;
    }

    if (dimensions) {
      query.dimensions = `"${dimensions}"`;
    }

    dispatch({
      type: API_REQUEST,
      method: 'GET',
      name,
      path: ENDPOINTS.REPORTING,
      query,
    })
      .then(json => {
        const filteredData = generateCarrierDataPerChart(reportId, json);
        dispatch(receiveBigQueryExceptionsDetails(filteredData));
      })
      .catch(error => {
        dispatch(receiveError(error));
      });
  });
};

export const selectDateRange = dateRange => dispatch => {
  dispatch({
    type: SELECT_DATE_RANGE,
    payload: { dateRange },
  });
};

export const selectCarrier = selectedCarrier => dispatch => {
  dispatch({
    type: SELECT_CARRIER,
    payload: { selectedCarrier },
  });
};

export const fetchExceptionsAnalyticsDetailedReports = ({
  reportId,
  offset = 0,
  whereFilters = [],
  exportOnly = false,
} = {}) => async (dispatch, getState) => {
  const {
    exceptionsChartDetails: {
      [reportId]: { columns },
      labels: columnConfiguration,
    },
  } = copy;

  const {
    exceptionsAnalyticsReducer: { dateRange, selectedCarrier } = {},
    userReducer: {
      user: {
        retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const { startDate, endDate } = dateRange;

  if (selectedCarrier !== ALL_CARRIERS_VALUE) {
    whereFilters.push({
      filterKey: 'carrier_moniker',
      filterValue: selectedCarrier,
    });
  }

  const queryBase = {
    dataset: `"${DATASET}"`,
    retailer: retailerMoniker,
    retailerId,
    columns,
    id: `v_iex_${reportId}`,
    offset,
    startDate,
    endDate,
    whereFilters: [...whereFilters],
  };

  const name = `fetchExceptionsDetails${pascalizeReportName(reportId)}`;

  try {
    const {
      [`v_iex_${reportId}`]: { rows: orders },
      total_records_with_filters: totalRecordsWithFilters = 0,
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      name,
      path: `${ENDPOINTS.REPORTING_ROWS}${generateOrderListQuery({
        ...queryBase,
        ...(exportOnly ? { limit: 1000000 } : { limit: RECORDS_PER_PAGE }),
      })}`,
    });

    if (exportOnly) {
      dispatch({
        type: RECEIVE_EXPORT_CHART_DETAILS,
        payload: {
          exportDetails: {
            [reportId]: {
              exportOrders: orders,
            },
          },
        },
      });
    } else {
      const { tableHeaders, tableBody } = mapOrderRowsToTable({
        orders,
        columns,
        columnConfiguration,
      });

      dispatch({
        type: RECEIVE_CHART_DETAILS,
        payload: {
          chartDetails: {
            [reportId]: {
              exportParameters: generateOrderListQuery(queryBase),
              tableHeaders,
              tableBody,
              totalRecordsWithFilters,
            },
          },
        },
      });
    }
  } catch (err) {
    dispatch(receiveError(err));
  }
};

export const updateActivePage = newPage => ({
  type: UPDATE_ACTIVE_PAGE,
  payload: { activePage: newPage },
});

export const requestNewPage = (reportId, requestedPage) => async dispatch => {
  const offset = (requestedPage - 1) * RECORDS_PER_PAGE;

  await dispatch(fetchExceptionsAnalyticsDetailedReports({ reportId, offset }));
  dispatch(updateActivePage(requestedPage));
};
