import moment from 'moment';

/* Helper/utility functions that don't need to be in components */

export function memorySizeOf(obj) {
  let bytes = 0;

  const sizeOf = obj => {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          bytes += 8;
          break;
        case 'string':
          bytes += obj.length * 2;
          break;
        case 'boolean':
          bytes += 4;
          break;
        case 'object':
          const objClass = Object.prototype.toString.call(obj).slice(8, -1);

          if (objClass === 'Object' || objClass === 'Array') {
            for (let key in obj) {
              if (!obj.hasOwnProperty(key)) continue;
              sizeOf(obj[key]);
            }
          } else {
            bytes += obj.toString().length * 2;
          }
          break;
      }
    }

    return bytes;
  };

  return formatByteSize(sizeOf(obj));
}

export function formatByteSize(bytes) {
  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

export function removeLineBreaks(str) {
  return str.replace(/(\r\n|\n|\r)/gm, '');
}

export function formatStringToTitle(str) {
  // Remove `"`s often found in queries
  if (str) {
    str = str.replace(/"/g, '');
    str = str.replace(/,/g, ', ');
    str = str.toLowerCase().split(/_|\-/);

    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].split('');
      str[i][0] = str[i][0].toUpperCase();
      str[i] = str[i].join('');
    }

    return str.join(' ');
  } else {
    return '';
  }
}

export function pascalizeReportName(str) {
  let tokens = str.split('_');
  let pascalized = '';

  tokens.forEach((token, index) => {
    pascalized += token.charAt(0).toUpperCase() + token.slice(1).toLowerCase();
  });

  return pascalized;
}

export function debounce(fn, wait, immediate) {
  let timeout;

  return function() {
    const context = this;
    const args = arguments;

    function later() {
      timeout = null;
      if (!immediate) fn.apply(context, args);
    }

    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      fn.apply(context, args);
    }
  };
}

export function throttle(fn, threshold = 150, scope) {
  let last;
  let deferTimer;

  return function() {
    const args = arguments;
    const context = scope || this;
    const now = +new Date();

    if (last && now < last + threshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function() {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

export function parseQueryString(input) {
  let result = {};
  const queryString = input.substring(1);
  const re = /([^&=]+)=([^&]*)/g;
  let m;

  while ((m = re.exec(queryString))) {
    result[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
  }

  return result;
}

export const getAllQueryParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const allKeys = {};
  for (const [key, val] of urlParams.entries()) {
    if (/^\d+$/.test(val)) {
      allKeys[key] = Number.parseInt(val, 10);
    }
    if (val === 'true' || val === 'false') {
      allKeys[key] = val === 'true';
    } else {
      allKeys[key] = val;
    }
  }

  return allKeys;
};

export function buildQueryString(query) {
  if (!query) {
    return '';
  }

  let queryString = '';

  Object.keys(query).forEach(q => {
    // Remove empty values
    if (query[q] === null || query[q] === 'undefined') {
      queryString += '';
    } else {
      let value = query[q];

      if (Array.isArray(value)) {
        value = value.join();
      }

      value = encodeURIComponent(value);

      if (queryString[0] !== '?') {
        queryString += `?${q}=${value}`;
      } else {
        queryString += `&${q}=${value}`;
      }
    }
  });

  return queryString;
}

export function shallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) {
    return false;
  }

  // Test for A's keys different from B.
  var bHasOwnProperty = Object.prototype.hasOwnProperty.bind(objB);
  for (var i = 0; i < keysA.length; i++) {
    if (!bHasOwnProperty(keysA[i]) || objA[keysA[i]] !== objB[keysA[i]]) {
      return false;
    }
  }

  return true;
}

export function shouldUpdate(a, b) {
  // Equal numbers, strings
  if ((typeof a === 'string' || typeof a === 'number') && a === b) {
    return false;
  }

  // Most common usecase where prop is an array of
  // objects that is expensive to update (e.g., <Select />).
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return true;
    }
  }

  if (JSON.stringify(a) === JSON.stringify(b)) {
    return false;
  } else {
    return true;
  }
}

export function capitalizeFirstLtrOfString(str) {
  return `${str[0].toUpperCase()}${str.slice(1)}`;
}

export function isEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function getUndefinedKeys(o, requiredFields) {
  return requiredFields.find(field => typeof o[field] === 'undefined');
}

export function kebabToSnakeCase(string) {
  return string.replace(/-/g, '_');
}

export function snakeToKebabCase(string) {
  return string.replace(/_/g, '-');
}

export function toLowerCaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

// Flattens objects in dropdowns for POST/PUT requests
export function flattenSelectedDropdownValues(o) {
  return Object.keys(o).reduce((result, item) => {
    result[item] = o[item]
      ? o[item].key || o[item].type || o[item].id || o[item].value || o[item]
      : o[item];
    return result;
  }, {});
}

export function getSelectedDropdownValue(dataSet = [], key, selectedValue) {
  return dataSet.find(item => item[key] === selectedValue);
}

export function isObject(item) {
  return typeof item === 'object' && !Array.isArray(item) && item !== null;
}

export const deepClone = object => JSON.parse(JSON.stringify(object));

export const deepFreeze = o => {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
};

export const flatten = (data, { delimiter = '.' } = {}) => {
  var result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      for (var i = 0, l = cur.length; i < l; i++)
        recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      var isEmpty = true;
      for (var p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + delimiter + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

export const unflatten = (data, { delimiter = '.' } = {}) => {
  if (Object(data) !== data || Array.isArray(data)) return data;
  var regex = new RegExp(
      `\\${delimiter}?([^${delimiter}\\[\\]]+)|\\[(\\d+)\\]`,
      'g',
    ),
    resultholder = {};
  for (var p in data) {
    var cur = resultholder,
      prop = '',
      m;
    while ((m = regex.exec(p))) {
      cur = cur[prop] || (cur[prop] = m[2] ? [] : {});
      prop = m[2] || m[1];
    }
    cur[prop] = data[p];
  }
  return resultholder[''] || resultholder;
};

export const swap = (data, firstIndex, secondIndex) => {
  const swappedData = [...data];
  const temp = data[firstIndex];
  swappedData[firstIndex] = data[secondIndex];
  swappedData[secondIndex] = temp;
  return swappedData;
};

export const formatCreatedAndModifiedDate = ({
  date = '',
  firstName = '',
  lastName = '',
} = {}) => {
  const formattedDate = moment(date).format('lll');
  const name = lastName ? `${firstName} ${lastName}` : firstName;

  if (name && date) {
    return `${formattedDate}, by ${name}`;
  } else if (name) {
    return `by ${name}`;
  } else if (date) {
    return formattedDate;
  }

  return '';
};

/**
 * Intersperses the given array with the result of the `separatorFn`
 * @param {*} arr
 * @param {*} separatorFn Accepts the current index of the original
 * array, and returns the separator element to be inserted between
 * each item
 * @returns A new array with the results of the separatorFn
 * inserted between each items in the original array
 */
export const intersperse = (arr, separatorFn) => {
  return arr.reduce((acc, el, i) => {
    const isLastElement = i === arr.length - 1;
    const next = [...acc, el];
    if (!isLastElement && typeof separatorFn === 'function')
      next.push(separatorFn(i));
    return next;
  }, []);
};

// Fix this with ?? support in webpack
export const nullishCoalescing = (left, right) =>
  left !== null && left !== undefined ? left : right;
