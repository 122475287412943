import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Alert.styles';
import { Svg } from '../Svg';

export class Alert extends Component {
  static propTypes = {
    color: PropTypes.oneOf(['success', 'danger', 'info']),
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  static defaultProps = {
    color: 'info',
    toggle: null,
    children: null,
  }

  shouldComponentUpdate(nextProps) {
    const {
      show,
      color,
      children,
    } = this.props;
    return show !== nextProps.show ||
      color !== nextProps.color ||
      children !== nextProps.children;
  }

  render() {
    const {
      children,
      color,
      show,
      toggle,
    } = this.props;

    return (
      <span>
        <div
          className={cx(s, show ? 'active' : '', color)}
          key={0}
          role="alert"
        >
          {Array.isArray(children) ?
            /* eslint-disable react/no-array-index-key */
            children.map((err, index) => <div className="error" key={index}>{err}</div>)
            : children}
          {toggle && (
            <Svg
              className="icon-close"
              name="close"
              onClick={toggle}
            />
          )}
        </div>
      </span>
    );
  }
}

export default Alert;
