import { REPORTING_ROWS } from '~src/constants/endpoints';
import { ENTER_KEY } from '~src/constants/keycodes';
import { API_REQUEST } from '~src/lib/api';
import generateOrderListQuery from '~src/lib/exceptions/generateOrderListQuery';
import mapOrderRowsToTable from '~src/containers/Exceptions/ExceptionsReports/mapOrderRowsToTable';
import copy from '~src/constants/copy/exceptions';

export const RECORDS_PER_PAGE = 25;
export const SEARCH_FILTERS = [
  {
    displayName: 'Order Number',
    searchKey: 'order_number',
  },
  {
    displayName: 'Tracking Number',
    searchKey: 'tracking_number',
  },
];

export const UPDATE_SELECTED_LOCALE =
  'EXCEPTIONS_REPORTS/UPDATE_SELECTED_LOCALE';
export const RECEIVE_ORDER_LIST = 'EXCEPTIONS_REPORTS/RECEIVE_ORDER_LIST';
export const RECEIVE_EXPORT_ORDERS = 'EXCEPTIONS_REPORTS/RECEIVE_EXPORT_ORDERS';
export const UPDATE_DATE_RANGE = 'EXCEPTIONS_REPORTS/UPDATE_DATE_RANGE';
export const UPDATE_ACTIVE_PAGE = 'EXCEPTIONS_REPORTS/UPDATE_ACTIVE_PAGE';
export const UPDATE_SEARCH_FILTER = 'EXCEPTIONS_REPORTS/UPDATE_SEARCH_FILTER';
export const UPDATE_SEARCH_INPUT = 'EXCEPTIONS_REPORTS/UPDATE_SEARCH_INPUT';
export const TOGGLE_IS_SEARCHING = 'EXCEPTIONS_REPORTS/TOGGLE_IS_SEARCHING';
export const CANCEL_SEARCH = 'EXCEPTIONS_REPORTS/CANCEL_SEARCH';

export const fetchOrders = ({
  offset,
  whereFilters = [],
  exportOnly = false,
} = {}) => async (dispatch, getState) => {
  const {
    exceptionsReports: { columns, labels: columnConfiguration },
  } = copy;

  const {
    exceptionsReportsReducer: { startDate, endDate } = {},
    userReducer: {
      user: {
        retailerId,
        retailerIdToRetailerInfo: {
          [retailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const queryBase = {
    dataset: '"reporting"',
    retailer: retailerMoniker,
    retailerId,
    columns,
    id: 'exceptions_dashboard_raw',
    offset,
    startDate,
    endDate,
    whereFilters: [...whereFilters],
  };

  try {
    const {
      exceptions_dashboard_raw: { rows: orders },
      total_records_with_filters: totalRecordsWithFilters,
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      name: 'fetchExceptionsReports',
      path: `${REPORTING_ROWS}${generateOrderListQuery({
        ...queryBase,
        ...(exportOnly ? { limit: 1000000 } : { limit: 25 }),
      })}`,
    });

    if (exportOnly) {
      dispatch({
        type: RECEIVE_EXPORT_ORDERS,
        payload: {
          exportOrders: orders,
        },
      });
    } else {
      const { tableHeaders, tableBody } = mapOrderRowsToTable({
        orders,
        columns,
        columnConfiguration,
      });

      dispatch({
        type: RECEIVE_ORDER_LIST,
        payload: {
          exportParameters: generateOrderListQuery(queryBase),
          tableHeaders,
          tableBody,
          totalRecordsWithFilters,
        },
      });
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
};

export const clearSearchInput = () => ({
  type: UPDATE_SEARCH_INPUT,
  payload: { searchInput: '' },
});

export const updateSelectedLocale = selectedLocale => dispatch => {
  dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });

  dispatch(clearSearchInput());
  dispatch(fetchOrders());
};

export const updateActivePage = newPage => ({
  type: UPDATE_ACTIVE_PAGE,
  payload: { activePage: newPage },
});

export const updateDateRange = (startDate, endDate) => async dispatch => {
  await dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

  dispatch(clearSearchInput());
  dispatch(updateActivePage(1));
};

export const updateSearchFilter = searchFilter => ({
  type: UPDATE_SEARCH_FILTER,
  payload: { searchFilter },
});

export const handleSearchInputChange = searchInput => async dispatch => {
  if (!searchInput) await dispatch(fetchOrders());

  dispatch({
    type: UPDATE_SEARCH_INPUT,
    payload: { searchInput },
  });
};

export const toggleIsSearching = isSearching => ({
  type: TOGGLE_IS_SEARCHING,
  payload: { isSearching },
});

export const cancelSearch = () => ({ type: CANCEL_SEARCH });

export const submitSearchInput = () => async (dispatch, getState) => {
  const {
    exceptionsReportsReducer: {
      searchFilter: { searchKey: filterKey } = {},
      searchInput: filterValue,
    } = {},
  } = getState();

  dispatch(toggleIsSearching(true));

  await dispatch(
    fetchOrders({
      whereFilters: [
        {
          filterKey,
          filterValue,
        },
      ],
    }),
  );

  dispatch(toggleIsSearching(false));
};

export const handleSearchKeydown = keyCode => (dispatch, getState) => {
  const { exceptionsReportsReducer: { isSearching } = {} } = getState();

  if (keyCode === ENTER_KEY) {
    dispatch(submitSearchInput());
  } else if (isSearching) {
    dispatch(toggleIsSearching(false));
  }
};

export const requestNewPage = requestedPage => async dispatch => {
  const offset = (requestedPage - 1) * RECORDS_PER_PAGE;

  await dispatch(fetchOrders({ offset }));
  dispatch(updateActivePage(requestedPage));
};
