import { push } from 'connected-react-router';
import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const RECEIVE_CARRIER_ALIASES = 'CARRIER_ALIAS/RECEIVE_ALIASES';
export const RECEIVE_CARRIER_ALIAS_DATA = 'CARRIER_ALIAS/RECEIVE_DATA';
export const RECEIVE_NEW_CARRIER_ALIAS_DATA = 'CARRIER_ALIAS/RECEIVE_NEW_DATA';
export const CLEAR_CARRIER_ALIAS_ALERTS = 'CARRIER_ALIAS/CLEAR_ALERTS';
export const UPSERT_CARRIER_ALIAS_ERROR = 'CARRIER_ALIAS/UPSERT_ERROR';
export const UPSERT_CARRIER_ALIAS_SUCCESS = 'CARRIER_ALIAS/UPSERT_SUCCESS';

export const receiveCarrierAliases = payload => ({
  type: RECEIVE_CARRIER_ALIASES,
  payload,
});

export const fetchCarrierAliases = () => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: `${ENDPOINTS.CARRIER_ALIAS}`,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.CARRIER_ALIAS}`,
    name: 'fetchCarrierAliases',
  }).then(json => {
    dispatch(receiveCarrierAliases(json));
  });
};

export const clearCarrierAliasAlerts = () => ({
  type: CLEAR_CARRIER_ALIAS_ALERTS,
});

export const receiveCarrierAliasData = payload => ({
  type: RECEIVE_CARRIER_ALIAS_DATA,
  payload,
});

export const receiveNewCarrierAliasCenter = payload => ({
  type: RECEIVE_NEW_CARRIER_ALIAS_DATA,
  payload,
});

export const fetchCarrierAliasData = id => dispatch => {
  if (id) {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.CARRIER_ALIAS}/${id}`,
    });
    return dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.CARRIER_ALIAS}/${id}`,
      name: 'fetchCarrierAliasData',
    }).then(json => {
      dispatch(receiveCarrierAliasData(json));
    });
  }
  return dispatch(receiveNewCarrierAliasCenter());
};

export const receiveUpsertCarrierAlias = payload => ({
  type: UPSERT_CARRIER_ALIAS_SUCCESS,
  payload,
});

export const receiveUpsertCarrierAliasError = messages => ({
  type: UPSERT_CARRIER_ALIAS_ERROR,
  messages,
});

export const fetchUpsertCarrierAlias = data => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${ENDPOINTS.CARRIER_ALIAS}overwrite`,
    body: JSON.stringify(data),
    name: 'fetchUpsertDistributionCenter',
  })
    .then(json => {
      if (json.status.toLowerCase() === 'success') {
        dispatch(receiveUpsertCarrierAlias(json));
        dispatch(push('/couriers/carrier-alias/'));
      } else {
        dispatch(receiveUpsertCarrierAliasError(`${json.errors[0].message}.`));
      }
    })
    .catch(err => {
      dispatch(receiveUpsertCarrierAliasError(`${err.errors[0].message}.`));
    });
