import moment from 'moment';
import uuid from '~src/lib/uuid';
import cloneDeep from 'lodash.clonedeep';

export const trackChatDefaults = {
  enabled: false,
  welcome_message:
    'Hello, this digital assistant is designed to help you with your order and delivery. What can we do for you?',
  header_label: 'Assist',
  font: 'font_family_primary',
  style: {
    user_bubble: {
      backgroundColor: '#1399D6',
    },
  },
  persistent_menu: {
    enabled: true,
    items: [
      {
        name: 'Contact Customer Service',
        link: 'RETAILER_URL',
        uuid: uuid(),
      },
      {
        name: 'Contact CARRIER_NAME',
        link: 'CARRIER_CONTACT_URL',
        uuid: uuid(),
      },
      {
        name: 'FAQs',
        link: 'FAQ_URL',
        uuid: uuid(),
      },
      {
        name: 'Returns & Exchanges',
        link: 'RETAILER_RETURNS_URL',
        uuid: uuid(),
      },
    ],
  },
};

const anyChildEnabled = obj =>
  Object.keys(obj).reduce(
    (enabled, key) => enabled || Boolean(obj[key].enabled),
    false,
  );

export const addLinkTitle = items => {
  const itemsArr = Object.keys(items).map(k => items[k]);
  return itemsArr.map(item => {
    return { ...item, link_title: item.alt_text };
  });
};

const addUuids = arrOfObjects =>
  arrOfObjects.map(obj => ({
    ...obj,
    uuid: uuid(),
  }));

const removeUuids = arrOfObjects =>
  arrOfObjects.map(obj => {
    const newObj = {
      ...obj,
    };
    if (newObj.uuid) {
      delete newObj.uuid;
    }
    return newObj;
  });

/*
 * To make the retailerSettings more usable in the UI
 * we flatten the schema so we don't have to mantain
 * all of that deep nesting
 */
export const flattenRetailerSettings = retailerSettings => {
  const flattenedRetailerSettings = {
    assets: {
      brandLogo: retailerSettings.brand_logo
        ? {
            ...retailerSettings.brand_logo,
            link_title: retailerSettings.brand_logo.alt_text,
          }
        : {},
      grid:
        retailerSettings.features &&
        retailerSettings.features.marketing_ads &&
        retailerSettings.features.marketing_ads.ads
          ? addLinkTitle(retailerSettings.features.marketing_ads.ads)
          : [],
      gridSettings:
        retailerSettings.features &&
        retailerSettings.features.marketing_ads &&
        retailerSettings.features.marketing_ads.grid
          ? {
              grid: retailerSettings.features.marketing_ads.grid || [],
              headerStyles:
                retailerSettings.features.marketing_ads.headerStyles || {},
              headerCopy:
                retailerSettings.features.marketing_ads.headerCopy || [],
            }
          : {},
      marketingPanel:
        retailerSettings.features &&
        retailerSettings.features.marketing_ads &&
        retailerSettings.features.marketing_ads.banner_ad
          ? addLinkTitle(retailerSettings.features.marketing_ads.banner_ad)
          : [{}],
      header: retailerSettings.header || {},
      branding: retailerSettings.branding || {},
      layout: retailerSettings.layout || {},
    },
    locales:
      retailerSettings.locale && retailerSettings.locale.supported_locales
        ? retailerSettings.locale
        : {
            ...retailerSettings.locale,
            supported_locales: [{}],
          },
    panelsLastModified: retailerSettings.panels_last_modified,
  };

  if (
    retailerSettings.features &&
    retailerSettings.features.assist &&
    retailerSettings.features.assist.tracking_chat
  ) {
    flattenedRetailerSettings.assets.tracking_chat =
      retailerSettings.features.assist.tracking_chat;

    const {
      assets: {
        tracking_chat: {
          persistent_menu,
          persistent_menu: { items } = {},
        } = {},
      } = {},
    } = flattenedRetailerSettings;

    if (items) {
      persistent_menu.items = addUuids(items);
    }
  }

  return flattenedRetailerSettings;
};

/*
 * generates from the experience manager object a retailerSettings object of the form
 * saved in the db
 */
export const expandRetailerSettings = experienceManager => {
  const {
    _retailerSettings,
    assets: {
      brandLogo,
      grid,
      marketingPanel,
      header,
      branding,
      layout,
      tracking_chat,
      tracking_chat: {
        persistent_menu: menu,
        welcome_message,
        header_label,
      } = {},
    } = {},
  } = experienceManager;

  // remove uuids from menu items:
  let formattedMenu = menu;
  if (menu && menu.items) {
    formattedMenu = cloneDeep(menu);
    formattedMenu.items = removeUuids(formattedMenu.items);
  }

  const features = _retailerSettings.features || {};
  const marketingAds = features.marketing_ads || {};
  const assist = {
    ...(features.assist || {}),
    tracking_chat: {
      ...tracking_chat,
      persistent_menu: formattedMenu,
      welcome_message: welcome_message || trackChatDefaults.welcome_message,
      header_label: header_label || trackChatDefaults.header_label,
    },
  };

  const retailerSettings = {
    ..._retailerSettings,
    brand_logo: brandLogo,
    header: {
      ...header,
      menu:
        (Array.isArray(header.menu) ? header.menu : []).map(item => ({
          ...item,
          // assign default click category until this setting is configurable
          click_category: item.click_category || 'Navigation',
        })) || [],
    },
    branding,
    layout,
    features: {
      ...features,
      marketing_ads: {
        ...marketingAds,
        ads: grid,
        banner_ad: marketingPanel,
      },
      assist: {
        ...assist,
        enabled: anyChildEnabled(assist), // set the global assist enabled bool
      },
    },
  };

  return retailerSettings;
};

/*
 * Build a request payload for sending to the API
 */
export const createRequestData = (experienceManager, isCustomerSupport) => {
  const requestData = {};
  const retailerSettings = expandRetailerSettings(experienceManager);
  if (isCustomerSupport) {
    requestData.retailer_settings = JSON.stringify(retailerSettings);
    requestData.translations =
      typeof experienceManager.translations === 'string'
        ? experienceManager.translations
        : JSON.stringify(experienceManager.translations);
    requestData.header_css = experienceManager.files.headerCss;
    requestData.font_css = experienceManager.files.fontCss;
    requestData.custom_css = experienceManager.files.customCss;
    requestData.main_css_file_name = `main-${moment().format(
      'YYYYMMDDHHmmSS',
    )}.css`;
  } else {
    requestData.ads = retailerSettings.features.marketing_ads.ads;
    requestData.brand_logo = retailerSettings.brand_logo;
    requestData.banner_ad = retailerSettings.features.marketing_ads.banner_ad;
  }
  requestData.panels_modified = experienceManager.panelsModified;

  return requestData;
};
