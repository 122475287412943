import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './ColoredCircle.styles';

export class ColoredCircle extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.className !== nextProps.className ||
      this.props.hexCode !== nextProps.hexCode;
  }

  render() {
    const { className, hexCode } = this.props;

    return (
      <div
        className={cx(className, s)}
        style={{ backgroundColor: hexCode }}
      />
    );
  }
}

ColoredCircle.defaultProps = {
  className: '',
};

ColoredCircle.propTypes = {
  className: PropTypes.string,
  hexCode: PropTypes.string,
};

export default ColoredCircle;
