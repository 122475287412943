import { returnMethodsTransformer } from '../transformers';

/**
 * Given a template string with formatting markers like `${color}` and a
 * params map, replace the formatting markers with the values from
 * the params map.
 *
 * Additionally, markers can have options. Currently the only one supported
 * is "quote", as an example: `${color:quote}` will result in `"Blue"` being
 * inserted, rather then `Blue`.
 */
export const format = ({ template, args }) => {
  return template.replace(/(\\?\$\{([\w;.-_]+)})/g, (match, expr, attr) => {
    if (expr.startsWith('\\')) return expr.substring(1);
    const [key, opts] = attr.split(';', 2);
    const v = args?.[key];
    if (v === null || v === undefined) return null;
    return opts === 'quote' && typeof v === 'string' ? `"${v}"` : v;
  });
};

const getEnumValue = (uiSchema, k) => {
  const parent = uiSchema.enums.find(e => k.startsWith(e.attribute + '.'));
  if (parent === null || parent === undefined || parent.type !== 'enum') return;
  return parent.values.find(ev => ev.attribute === k);
};

const evaluateEnum = (uiSchema, userInput, path, formatArgs, optional) => {
  const attribute = userInput[path];
  if (!attribute) {
    if (optional === true) return null;
    throw new Error(`UserInput missing path; path: ${path}.`);
  }

  if (Array.isArray(attribute)) {
    return format({
      template: attribute
        .map(a => getEnumValue(uiSchema, a)?.template.value)
        .toString(),
      args: formatArgs,
    });
  }

  const enumValue = getEnumValue(uiSchema, attribute);
  if (!enumValue)
    throw new Error(
      `EnumValue not found in ui schema; path: ${path}, attribute: ${attribute}.`,
    );
  path = `${path}/${enumValue.attribute}`;

  formatArgs = (enumValue.params ?? []).reduce(
    (formatParams, p) => ({
      ...formatParams,
      [p.attribute]: evaluateParam(
        uiSchema,
        userInput,
        `${path}/${p.attribute}`,
        p,
        formatParams,
      ),
    }),
    formatArgs,
  );

  return format({ template: enumValue.template.value, args: formatArgs });
};

export const evaluateParam = (uiSchema, userInput, path, p, formatParams) => {
  switch (p.type) {
    case 'input.date_time':
    case 'input.number': {
      const v = userInput[path];
      if ((v === null || v === undefined) && !p.optional)
        throw new Error('Required parameter not defined; path: ' + path + '.');
      if (Array.isArray(v))
        throw new Error(`${p.type} should never have array values.`);
      return v;
    }
    case 'input.text': {
      if (Array.isArray(userInput[path]))
        throw new Error(`input.text should never have array values.`);
      const v = JSON.stringify(userInput[path])?.slice(1, -1);
      if ((v === null || v === undefined) && !p.optional)
        throw new Error('Required parameter not defined; path: ' + path + '.');
      return v;
    }
    case 'input.boolean':
      return userInput[path] ? 'true' : 'false';
    case 'let':
      return format({ template: p.template.value, args: formatParams });
    case 'refs': {
      const v = evaluateEnum(
        uiSchema,
        userInput,
        path,
        formatParams,
        p.optional,
      );
      if ((v === null || v === undefined) && !p.optional)
        throw new Error('Required parameter not defined; path: ' + path + '.');
      return v;
    }
  }
};

export const generateCondition = (uiSchema, userInput) => {
  return evaluateEnum(uiSchema, userInput, 'subject', {}, false);
};

export const generateResult = (uiSchema, userInput) => {
  if (userInput.result === 'result.return_methods') {
    // We should come back to this and make it more generic
    const transformedResult = returnMethodsTransformer(userInput);
    const template =
      '{"op": "literal<List<ReturnMethodResult>>", "value": ${transformedResult}}';
    return format({
      template,
      args: { transformedResult: JSON.stringify(transformedResult) },
    });
  }
  return evaluateEnum(uiSchema, userInput, 'result', {}, false);
};

export const generatePolicyStatement = (uiSchema, userInput) => {
  const condition =
    userInput.conditions.length === 1
      ? generateCondition(uiSchema, userInput.conditions[0])
      : `{"op": "all", "exprs": [${userInput.conditions
          .map(c => generateCondition(uiSchema, c))
          .join(', ')}]}`;
  return `{"condition": ${condition}, "result": ${generateResult(
    uiSchema,
    userInput.result,
  )}}`;
};
