/*
 * We need these functions because the current way the backend sends UPS Ready
 * data is in a nested object that does not fit the Track Carrier Credentials
 * schema at all.
 * A commitment was made to flatten the UPS Ready data when work on UPS Ready
 * for Returns.
 *
 * See https://narvar.atlassian.net/wiki/spaces/NH/pages/21463133/UPS+Ready
 */

export const hackyWayToReceiveUpsReadyData = apiResponse => {
  const {
    api_credentials: apiCredentials,
    retailer_profile: retailerProfile,
  } = apiResponse;

  if (!retailerProfile) return apiCredentials;

  const {
    primary_contact: { first_name: firstName, title, email, phone } = {},
    address: {
      address_1: address,
      city,
      state,
      zip_code: zipCode,
      country,
    } = {},
  } = retailerProfile;

  return {
    'retailer_profile.primary_contact.first_name': firstName,
    'retailer_profile.primary_contact.email': email,
    'retailer_profile.primary_contact.title': title,
    'retailer_profile.primary_contact.phone': phone,
    'retailer_profile.address.address_1': address,
    'retailer_profile.address.city': city,
    'retailer_profile.address.state': state,
    'retailer_profile.address.zip_code': zipCode,
    'retailer_profile.address.country': country,
    'retailer_profile.license_agreement': true,
  };
};

export const hackyWayToSendUpsReadyData = form => {
  const formCopy = { ...form };
  const finalForm = {
    retailer_profile: {
      address: {},
      primary_contact: {},
    },
  };

  Object.keys(formCopy).forEach(input => {
    const splitInputName = input.split('.');
    if (splitInputName.length < 3) return;
    finalForm.retailer_profile[splitInputName[1]][splitInputName[2]] =
      formCopy[input];
  });

  return {
    ...finalForm,
    retailer_profile: {
      ...finalForm.retailer_profile,
      retailer_name: formCopy.retailerName,
      retailer_account_info: {
        account_name: formCopy['retailer_profile.primary_contact.company'],
        account_info: null,
        access_apis: ['TrackXML', 'TrackWSXML'],
      },
    },
  };
};
