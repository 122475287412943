import { v4 as uuid } from 'uuid';
import objectPath from 'object-path';
import { convertRulesToPolicy } from '../../../containers/ReturnsHeadlessConfig/PolicyBuilder/policyTranslation';
import { API_REQUEST } from '../../../lib/api';
import { RETURNS_RULES } from '../../../constants/endpoints';
import { RULE_TYPES } from '../../../constants/returns/returnRules';
import { queryParams } from '../../../containers/ReturnsHeadlessConfig/helpers';
import { RULE_TITLES } from '../../../constants/copy/returns';
import {
  RECEIVE_RETURNS_RULES_PUBLISH,
  receiveRules,
  receiveRulesError,
  receiveRulesMetadata,
  setPublishingState,
} from '../../returnsRulesActions';
import { generatePolicyStatement } from '../../../containers/Returns/ReturnsRules.v.3.0/AddRuleModalV2/schema/generator/ui-schema-generate-policy';

export const v2Publish = props =>
  new Promise((resolve, reject) => {
    try {
      const { rules, url, policies, ruleType, uiSchema, dcs } = props;
      const payload = policies[ruleType];

      const statements = rules.map(rule => ({
        ...JSON.parse(generatePolicyStatement(uiSchema, rule)),
        enabled: rule.enabled,
        id: rule.id || uuid(),
      }));

      objectPath.set(payload, 'policy_ui', rules);
      objectPath.set(payload, 'policy.statements', statements);

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async e => {
          if (e.status === 201) {
            return resolve({
              type: 'expression',
              name: ruleType,
              value: {
                rules,
                policyPayload: await e.json(),
                dcs,
              },
            });
          }
          throw new Error(await e.text());
        })
        .catch(err => {
          console.error(new Error(err));
          reject(new Error(err));
        });
    } catch (e) {
      console.error(new Error(e));
      reject(new Error(e));
    }
  });

const expressionEnginePublish = ({
  policyRules,
  policies,
  rules,
  dcs,
  url,
  ruleType,
  uiSchema,
}) =>
  new Promise((resolve, reject) => {
    if (policyRules && policies[ruleType] && policies[ruleType].policy) {
      const policy = policyRules.map(rule =>
        !rule.id
          ? {
              ...rule,
              id: uuid(),
            }
          : rule,
      );

      const policyUi = policy.map(rule => ({
        ...rule,
        actions: rule.selectedActionType
          ? rule.actions.filter(
              action => action.category === rule.selectedActionType,
            )
          : rule.actions,
        name: rule.name || 'Unnamed Rule',
      }));

      const newPolicy = convertRulesToPolicy({ rules: policy, uiSchema });
      newPolicy.policy.version = policies[ruleType].policy.version;
      const payload = {
        ...newPolicy,
        policy_ui: policyUi,
        last_updated_at: policies[ruleType].last_updated_at,
      };

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(async e => {
          if (e.status === 201) {
            return resolve({
              type: 'expression',
              name: ruleType,
              value: {
                rules,
                policyPayload: await e.json(),
                dcs,
              },
            });
          }
          throw new Error(await e.text());
        })
        .catch(err => {
          console.error(new Error(err));
          reject(new Error(err));
        });
    } else {
      reject(new Error('No policy rules found'));
    }
  });

const droolsPublish = ({ data, dispatch, apiVersion }) =>
  new Promise((resolve, reject) => {
    Object.keys(data).forEach(datum => {
      if (data[datum] && data[datum].criteria) {
        data[datum].criteria.forEach(criteria => {
          delete criteria.operators;
          delete criteria.values;
        });
      }
    });

    const body = JSON.stringify({
      returns: {
        rule_types: data,
      },
    });

    dispatch({
      type: API_REQUEST,
      method: 'POST',
      body,
      path: `${RETURNS_RULES}?version=${apiVersion}`,
      name: 'fetchPublishRules',
    })
      .then(json => {
        const {
          errors,
          status_code: statusCode,
          response: {
            returns: {
              modified_by: modifiedBy,
              modified_date: modifiedDate,
            } = {},
          } = {},
        } = json;
        if (statusCode === 400) {
          throw errors[0].message;
        } else {
          resolve({
            type: 'drools',
            name: 'drools',
            value: {
              modifiedBy,
              modifiedDate,
            },
          });
        }
      })
      .catch(err => reject(err.message ? err.message : err));
  });

export const fetchPublishRules = ({
  payload: { policies, rules, dcs, definitions },
  apiVersion = 1,
  customerSupportLogin,
  modes,
}) => dispatch => {
  dispatch(setPublishingState({ isPublishing: true }));

  const { multi, fees } = queryParams();
  const isFeesV2 = fees || modes?.feesMode;

  const {
    [RULE_TYPES.RETURN_DESTINATION_POLICY]: returnDestinationPolicyRules = [],
    [RULE_TYPES.RETURN_METHODS_POLICY]: returnMultiCarrierPolicyRules = [],
    // eslint-disable-next-line no-unused-vars
    [RULE_TYPES.RETURN_FEES_POLICY]: returnFeesPolicyRules = [], // intentionally unused
    ...data
  } = rules;

  if (isFeesV2) {
    const v1FeeRules = data[RULE_TYPES.RETURN_FEES].filter(
      e => e.version !== 'v2',
    );
    objectPath.set(data, RULE_TYPES.RETURN_FEES, v1FeeRules);
  }

  if (data?.[RULE_TYPES.RETURN_FEES]?.length === 0) {
    objectPath.del(data, RULE_TYPES.RETURN_FEES);
  }

  const expressionProps = {
    policies,
    definitions,
    rules,
    dcs,
  };

  const publishes = [
    droolsPublish({ data, dispatch, apiVersion }).catch(error => {
      console.error(error);
      return error;
    }),
  ];

  if (multi || modes?.returnMethodsMode) {
    publishes.push(
      expressionEnginePublish({
        policyRules: returnMultiCarrierPolicyRules,
        ruleType: RULE_TYPES.RETURN_METHODS_POLICY,
        url: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_METHODS_POLICY}`,
        uiSchema: definitions?.[RULE_TYPES.RETURN_METHODS_POLICY]?.v2,
        ...expressionProps,
      }).catch(error => {
        console.error(error);
        return {
          type: 'expression',
          ruleType: RULE_TYPES.RETURN_METHODS_POLICY,
          name: RULE_TITLES[RULE_TYPES.RETURN_METHODS_POLICY],
          error,
        };
      }),
    );
  }

  if (fees || modes?.feesMode) {
    publishes.push(
      v2Publish({
        ...expressionProps,
        rules: rules[[RULE_TYPES.RETURN_FEES]].filter(rule =>
          Boolean(rule?.result),
        ),
        ruleType: RULE_TYPES.RETURN_FEES_POLICY,
        url: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_FEES_POLICY}`,
        uiSchema: definitions?.[RULE_TYPES.RETURN_FEES]?.v2,
      }),
    );
  }

  if (customerSupportLogin) {
    publishes.push(
      expressionEnginePublish({
        policyRules: returnDestinationPolicyRules,
        ruleType: RULE_TYPES.RETURN_DESTINATION_POLICY,
        url: `/api/destinations/policies`,
        uiSchema: definitions?.[RULE_TYPES.RETURN_DESTINATION_POLICY]?.v2,
        ...expressionProps,
      }).catch(error => {
        console.error(error);
        return {
          type: 'expression',
          ruleType: RULE_TYPES.RETURN_DESTINATION_POLICY,
          name: RULE_TITLES[RULE_TYPES.RETURN_DESTINATION_POLICY],
          error,
        };
      }),
    );
  }

  Promise.all(publishes)
    .then(items => {
      const drools = items.find(item => item.type === 'drools');
      const expressionRules = items
        .filter(item => item.type === 'expression')
        .filter(Boolean);

      const droolsError = drools && drools.message;
      const expressionErrors = [];
      const expressionSuccess = [];
      expressionRules.forEach(e => {
        if (e && e.error) {
          expressionErrors.push(e.name);
        } else {
          expressionSuccess.push(e);
        }
      });

      // drools fail but policy success
      if (droolsError) {
        dispatch(
          receiveRulesError(
            `A Publishing error has occurred. Please try again.`,
          ),
        );
      }

      // drools success but policy fail
      if (expressionErrors.length > 0 && !droolsError) {
        dispatch(
          receiveRulesError(
            `Publishing ${expressionErrors.join(
              ' and ',
            )} has failed but other rules have published successfully. Please try again.`,
          ),
        );
      }

      if (!droolsError) {
        dispatch(receiveRulesMetadata(drools.value));
      }

      const updatedPayload = expressionSuccess.reduce(
        (acc, expressionRule) => ({
          ...acc,
          policies: {
            ...acc.policies,
            [expressionRule.name]: expressionRule.value.policyPayload,
          },
        }),
        expressionProps,
      );

      dispatch(receiveRules(updatedPayload));

      // All Success
      if (!droolsError && expressionErrors.length === 0) {
        dispatch({
          type: RECEIVE_RETURNS_RULES_PUBLISH,
        });
      }
      dispatch(setPublishingState({ isPublishing: false }));
    })
    .catch(err => {
      dispatch(
        receiveRulesError(
          typeof err === 'string'
            ? err
            : 'Publishing has failed. Please try again.',
        ),
      );
      dispatch(setPublishingState({ isPublishing: false }));
      console.error(err);
    });
};
