import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  height: 20px;
  position: relative;
  width: 30px;

  .showSubText {
    display: inline-block;
  }
  .subText {
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
  }

	& input {
    cursor: pointer;
    height: 100%;
    opacity: 0;
    position: absolute !important;
    width: 100%;
    z-index: 1;
	}

  & span {
    display: inline-block;
    position: relative;
    background-color: ${colors.gray};
    text-indent: -5000px;
    height: 20px;
    width: 30px;
    border-radius: 15px;
	}

	& span:before {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 30px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: rgba(19,191,17,0);
    -moz-transition: .25s ease-in-out;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
	}

  & span:after {
    content: "";
    position: absolute;
    display: block;
    height: 16px;
    width: 16px;
    top: 2px;
    left: 2px;
    border-radius: 15px;
    background: #fff;
    -moz-transition: .25s ease-in-out;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
	}

	& input:checked + span:before {
    width: 30px;
    background: ${colors.green};
	}

	& input:checked + span:after {
    left: 12px;
	}
`;

export default s;
