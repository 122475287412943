export const getRetailerMoniker = ({
  user: { retailerId, retailerIdToRetailerInfo },
}) => retailerIdToRetailerInfo[retailerId].uri_moniker;

export const queryParams = ({ includesKeys } = {}) => {
  const params = Object.fromEntries(
    [
      ...new URLSearchParams(
        typeof window !== 'undefined' ? window.location.search : '',
      ),
    ].map(([key, val]) => {
      let newVal = val;
      newVal = val === '' ? true : newVal;
      newVal = val === 'true' ? true : newVal;
      newVal = val === 'false' ? false : newVal;
      newVal = /^\d+$/.test(val) ? Number(val) : newVal;
      return [key, newVal];
    }),
  );

  if (includesKeys) {
    if (!Array.isArray(includesKeys) || includesKeys.length === 0) {
      return [];
    }
    return includesKeys.reduce(
      (acc, cur) => (Object.keys(params).includes(cur) ? [...acc, cur] : acc),
      [],
    );
  }

  return params;
};
