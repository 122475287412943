import * as ENDPOINTS from '~src/constants/endpoints';
import { API_REQUEST } from '~src/lib/api';
import { buildQueryString } from '~src/lib/helpers';
import { formatDates } from '~src/actions/trackingAnalyticsActions';

export const REQUEST_COMMENTS = 'REQUEST_COMMENTS';
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS';
export const RECEIVE_FEEDBACK_ERROR = 'RECEIVE_FEEDBACK_ERROR';

export const requestComments = (filters, page) => ({
  type: REQUEST_COMMENTS,
  filters,
  page,
});

export const receiveComments = (comments, total, carriers) => ({
  type: RECEIVE_COMMENTS,
  comments,
  total,
  carriers,
});

export const fetchComments = (query, page, recordsPerPage, isDynamicTrack) => (
  dispatch,
  getState,
) => {
  const {
    trackingAnalyticsReducer: { dateRange, selectedLocale } = {},
  } = getState();

  const { startDateFormatted, endDateFormatted } = formatDates(dateRange);
  query = {
    ...query,
    start_date: startDateFormatted,
    end_date: endDateFormatted,
    locale: selectedLocale || null,
    report: ENDPOINTS.FEEDBACK_ENGAGEMENT_DRILL_DOWN,
    current_page_index: page - 1,
    records_per_page: recordsPerPage,
    dynamicTrack: !!isDynamicTrack,
  };

  dispatch(requestComments(query, page));

  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TRACKING_ANALYTICS,
    query,
    name: 'fetchComments',
  })
    .then(json => {
      const { reports } = json.response;
      const comments = reports[0].groups || [];
      const carriers = reports[0].carriers;
      const total = (reports[0].summary && reports[0].summary[0].value) || 0;

      dispatch(receiveComments(comments, total, carriers));
    })
    .catch(err => dispatch(receiveFeedbackError(err)));
};

export const exportComments = filters => {
  let url = buildQueryString(filters);

  window && window.open(`${ENDPOINTS.TRACKING_ANALYTICS}/csv${url}`);
};

const receiveFeedbackError = err => dispatch => {
  dispatch({
    type: RECEIVE_FEEDBACK_ERROR,
    meta: err,
  });
};
