import { LOCATION_CHANGE } from 'connected-react-router';
import {
  RECEIVE_DISTRIBUTION_CENTERS,
  RECEIVE_DISTRIBUTION_CENTER_DATA,
  RECEIVE_DISTRIBUTION_NEW_CENTER,
  UPDATE_SUCCESS,
  CREATE_SUCCESS,
  MODIFY_DISTRIBUTION_CENTER_ERROR,
  CREATE_DISTRIBUTION_CENTER_ERROR,
  CLEAR_DC_ALERTS,
  RECEIVE_DELETE_DISTRIBUTION_CENTER_ERROR,
  RECEIVE_SHIPPING_TAGS,
  SELECT_SHIPPING_TAG,
  SHOW_ALERT,
  TOGGLE_LOCAL_DELIVERY_OPTION,
  SET_ELIGIBLE_ZIPS,
  SET_CARRIER_INSTRUCTIONS,
  RECEIVE_RULE_DATA,
} from '../actions/distributionCentersActions';
import { DISMISS_ALERT } from '../actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  dc_details: {
    dc_name: '',
    dc_id: '',
    time_zone: 'America/New_York',
    phone: '',
    address_line_one: '',
    address_line_two: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    dc_shipping_tags: {
      selectedTags: [],
    },
    is_local_delivery_enabled: false,
    eligible_zips: '',
    carrier_instructions: '',
    default_returns_dc: false,
    supported_services: [],
    returns_settings: { attention: '', department: '' },
  },
  holiday_closures: [],
  working_schedule: [false, true, true, true, true, true, false],
  distributionCenters: [],
  totalResults: 0,
  shippingTags: [],
  isTagChangesDetected: false,
};

function distributionCenters(state = initialState, action) {
  const { meta, payload = {}, type } = action;
  const { response = {} } = payload;
  const {
    dc_details: dcDetails = '',
    working_schedule: workingSchedule = '',
    holiday_closures: holidayClosures = [],
    shipping_tags: shippingTags = [],
  } = response;

  switch (type) {
    case LOCATION_CHANGE:
      return initialState;

    case RECEIVE_DISTRIBUTION_CENTERS:
      return {
        ...state,
        is_dc_created: false,
        distributionCenters: payload.distributionCenters,
        totalResults: meta.totalResults,
      };

    case RECEIVE_DISTRIBUTION_CENTER_DATA:
      return {
        ...state,
        dc_details: {
          ...dcDetails,
          dc_shipping_tags: {
            selectedTags: dcDetails.dc_shipping_tags
              ? dcDetails.dc_shipping_tags.selectedTags
              : [],
          },
        },
        working_schedule: workingSchedule,
        holiday_closures: holidayClosures,
        is_dc_created: false,
      };

    case CLEAR_DC_ALERTS:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    case RECEIVE_RULE_DATA:
      return {
        ...state,
        rules: payload,
      };

    case RECEIVE_DISTRIBUTION_NEW_CENTER:
      return {
        ...state,
        alert: {
          show: false,
        },
        dc_details: {
          dc_name: '',
          dc_id: '',
          time_zone: 'America/New_York',
          phone: '',
          address_line_one: '',
          address_line_two: '',
          city: '',
          state: '',
          status: 'Active',
          zip: '',
          country: '',
          dc_shipping_tags: {
            selectedTags: [],
          },
          is_local_delivery_enabled: false,
          eligible_zips: '',
          carrier_instructions: '',
          default_returns_dc: false,
          supported_services: [],
          returns_settings: { attention: '', department: '' },
        },
        holiday_closures: [],
        working_schedule: [false, true, true, true, true, true, false],
        distributionCenters: [],
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        is_dc_created: false,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes are published successfully.',
        },
      };

    case CREATE_SUCCESS:
      return {
        ...state,
        is_dc_created: true,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes are published successfully.',
        },
      };

    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          text: payload.text,
          color: payload.color,
        },
      };

    case MODIFY_DISTRIBUTION_CENTER_ERROR:
      return {
        ...state,
        is_dc_created: false,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case CREATE_DISTRIBUTION_CENTER_ERROR:
      return {
        ...state,
        is_dc_created: false,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        is_dc_created: false,
        alert: initialState.alert,
      };

    case RECEIVE_DELETE_DISTRIBUTION_CENTER_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            'Sorry, there was an issue with deleting that Distribution Center. Please contact your Customer Support Representative.',
        },
      };

    case RECEIVE_SHIPPING_TAGS:
      return {
        ...state,
        shippingTags: shippingTags.map(shippingTag => ({
          tag: shippingTag,
          selected: state.dc_details.dc_shipping_tags.selectedTags.includes(
            shippingTag,
          ),
        })),
        isTagChangesDetected: false,
      };

    case SELECT_SHIPPING_TAG:
      return {
        ...state,
        shippingTags: state.shippingTags.map((tag, index) => {
          if (index === meta.selectedIndex) {
            return {
              ...tag,
              selected: !tag.selected,
            };
          }
          return tag;
        }),
        isTagChangesDetected: true,
      };

    case TOGGLE_LOCAL_DELIVERY_OPTION:
      return {
        ...state,
        dc_details: {
          ...state.dc_details,
          is_local_delivery_enabled: meta.flag,
        },
        isTagChangesDetected: true,
      };
    case SET_ELIGIBLE_ZIPS:
      return {
        ...state,
        dc_details: {
          ...state.dc_details,
          eligible_zips: meta.zips,
        },
        isTagChangesDetected: true,
      };
    case SET_CARRIER_INSTRUCTIONS:
      return {
        ...state,
        dc_details: {
          ...state.dc_details,
          carrier_instructions: meta.instructions,
        },
        isTagChangesDetected: true,
      };

    default:
      return state;
  }
}

export default distributionCenters;
