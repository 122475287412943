import { LOCATION_CHANGE } from 'connected-react-router';
import {
  RECEIVE_CSM_LOGIN,
  RECEIVE_CSM_LOGOUT,
  RECEIVE_SWITCH_TENANT,
  RECEIVE_USER_STATUS,
  RECEIVE_USER_STATUS_ERROR,
  RECEIVE_USERS,
  RECEIVE_USER,
  RECEIVE_ROLES,
  RECEIVE_ADD_USER,
  RECEIVE_ADD_USER_ERROR,
  RECEIVE_FORM_ERROR,
  RECEIVE_LOGOUT,
  RECEIVE_VERIFIED_SIGNUP,
  SAVE_NEW_USER_DATA,
  RECEIVE_CHANGE_PASSWORD,
  DISMISS_ALERT,
  RECEIVE_RESET_PASSWORD,
  RECEIVE_USER_CHANGE_STATUS,
  RECEIVE_ERROR,
  RECEIVE_UPDATE_PASSWORD,
  RECEIVE_UPDATE_USER_INFO,
  RECEIVE_RESEND_INVITATION_EMAIL,
} from '../actions/userActions';

const createProfileUser = () => ({
  roleResponseSet: [],
  department: null,
  level: null,
  how_to_use_hub: [],
});

const createInitialState = () => ({
  alert: {
    show: false,
  },
  formErrors: [],
  loginAttempts: 0,
  newUser: null,
  newUserData: {
    hardData: {
      phone: '',
      title: '',
    },
    securityQuestions: [
      {
        answer: '',
        customQuestion: false,
        question: '',
        questionId: '',
      },
      {
        answer: '',
        customQuestion: false,
        question: '',
        questionId: '',
      },
      {
        answer: '',
        customQuestion: false,
        question: '',
        questionId: '',
      },
    ],
    password: '',
    password1: '',
    user: null,
  },
  profileUser: createProfileUser(),
  roles: [],
  securityQuestions: [],
  user: null,
  users: [],
});

export const initialState = createInitialState();

const user = (state = initialState, action) => {
  const { meta, payload, type } = action;

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: initialState.alert,
        formErrors: [],
        profileUser: createProfileUser(),
      };

    case RECEIVE_LOGOUT:
      return {
        ...state,
        user: null,
      };

    case RECEIVE_FORM_ERROR:
      return {
        ...state,
        formErrors: [
          meta.error && (meta.error.description || meta.error.message),
        ],
      };

    case RECEIVE_USER_STATUS:
      return {
        ...state,
        user: payload,
      };

    case RECEIVE_USER_STATUS_ERROR:
      return {
        ...state,
      };

    case RECEIVE_CSM_LOGIN:
      return {
        ...state,
        user: payload.user,
      };

    case RECEIVE_CSM_LOGOUT:
      return {
        ...state,
        user: payload.user,
      };

    case RECEIVE_SWITCH_TENANT:
      return {
        ...state,
        user: {
          ...state.user,
          retailerId: meta.retailerId,
          current_retailer_id: meta.retailerId,
        },
      };

    case RECEIVE_VERIFIED_SIGNUP:
      return {
        ...state,
        newUser: payload,
      };

    case SAVE_NEW_USER_DATA:
      return {
        ...state,
        newUserData: {
          ...state.newUserData,
          ...payload,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_USERS:
      return {
        ...state,
        users: payload.users,
      };

    case RECEIVE_USER:
      return {
        ...state,
        profileUser: payload.user,
      };

    case RECEIVE_ROLES:
      return {
        ...state,
        roles: payload.roles,
      };

    case RECEIVE_ADD_USER:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'User was successfully created.',
        },
        newUser: payload.newUser,
        users: {
          ...state.users,
          content: [...state.users.content, payload.newUser],
        },
      };

    case RECEIVE_ADD_USER_ERROR:
      return {
        ...state,
        formErrors: meta.error ? meta.error : initialState.formErrors,
      };

    case RECEIVE_CHANGE_PASSWORD:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: meta.response,
        },
      };

    case RECEIVE_USER_CHANGE_STATUS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: action.payload,
        },
        profileUser: {
          ...state.profileUser,
          status: !state.profileUser.status,
        },
      };

    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: meta.error,
        },
      };

    case RECEIVE_UPDATE_PASSWORD:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: payload,
        },
      };

    case RECEIVE_RESET_PASSWORD:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: payload,
        },
      };
    case RECEIVE_UPDATE_USER_INFO:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'User info updated successfully.',
        },
      };

    case RECEIVE_RESEND_INVITATION_EMAIL:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: payload,
        },
      };

    default:
      return state;
  }
};

export default user;
