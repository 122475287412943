export default {
  title: 'Returns Restricted Emails',
  upload: {
    header: 'Upload Restricted Emails File',
    description:
      'A list of restricted emails can be uploaded here. The file must be a single-column CSV file where each row is an email address. The first row is reserved for header and will be skipped.',
    exampleHeader: 'Example file:',
    exampleFile:
      'Current Blocked Users\njohn.doe@example.com\njane.smith@example.net\n',
  },
  alert: {
    success: 'Restricted emails updated.',
    uploadError: 'Error updating restricted emails. Please try again.',
    checkCsv: 'Please check file is CSV',
  },
};
