import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import s from './Checkbox.styles';
import Svg from '../Svg';

export const Checkbox = ({
  index,
  isDropdown,
  item,
  onChange,
  readableKey,
  checked,
  disabled,
}) => {
  const id = `${item[readableKey]}-${index}`;

  return (
    <div key={id} className={cx(s, disabled || (item.disabled && 'disabled'))}>
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      <label
        className={cx(
          'select-checkbox-label',
          item.selected && 'select-checkbox-label-selected',
          isDropdown && 'select-checkbox-label-dropdown',
        )}
      >
        <input
          checked={Boolean(checked || item.selected)}
          disabled={Boolean(disabled || item.disabled)}
          type="checkbox"
          name={item.name || index}
          onChange={onChange}
        />
        <span className="select-checkbox-label-text">{item[readableKey]}</span>
        <div
          className="select-checkbox"
          data-test={`${String(item[readableKey]).toLowerCase()}-checkbox`}
        >
          <Svg name="check" className="checkmark" height={25} width={33} />
        </div>
      </label>
    </div>
  );
};

// NOTE why item has selected key and component also has check prop. Seems redundant no? RG
Checkbox.propTypes = {
  index: PropTypes.number,
  isDropdown: PropTypes.bool,
  // The prop 'readableKey' references a property on 'item', so we don't know the shape of the object ahead of time
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  readableKey: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  index: 0,
  isDropdown: false,
  readableKey: '',
  checked: false,
  disabled: false,
};

export default Checkbox;
