import { inConditionMutation, nullishConditionMutation } from './mutations';

const exprMap = {
  ab: { expr_a: 'expr_a', expr_b: 'expr_b' },
  needleHaystack: { expr_a: 'needle_expr', expr_b: 'haystack_expr' },
  haystackNeedle: { expr_a: 'haystack_expr', expr_b: 'needle_expr' },
  prefix: { expr_a: 'string_expr', expr_b: 'prefix_expr' },
  suffix: { expr_a: 'string_expr', expr_b: 'suffix_expr' },
};

export const opMap = {
  eq: {
    value: ['eq', '=', 'equals', 'equal to'],
    exprMap: exprMap.ab,
  },
  neq: {
    value: ['neq', '!=', 'not =', 'not equals'],
    exprMap: exprMap.ab,
  },
  starts_with: {
    value: ['starts_with', 'starts with'],
    exprMap: exprMap.prefix,
  },
  ends_with: {
    value: ['ends_with', 'ends with'],
    exprMap: exprMap.suffix,
  },
  contains: {
    value: ['contains'],
    exprMap: exprMap.haystackNeedle,
  },
  not_contains: {
    value: ['not_contains', 'not contains'],
    exprMap: exprMap.haystackNeedle,
  },
  is_null: {
    value: ['is_null', 'null', 'is null'],
    exprMap: exprMap.ab,
    mutations: {
      condition: (condition, index) =>
        nullishConditionMutation(condition, index, 'is_null'),
    },
  },
  is_not_null: {
    value: ['is_not_null', 'not null', '!null'],
    exprMap: exprMap.ab,
    mutations: {
      condition: (condition, index) =>
        nullishConditionMutation(condition, index, 'is_not_null'),
    },
  },
  in: {
    value: ['in'],
    exprMap: exprMap.needleHaystack,
    mutations: {
      condition: (condition, index) =>
        inConditionMutation(condition, index, 'in'),
    },
  },
  not_in: {
    value: ['not_in', 'not in'],
    exprMap: exprMap.needleHaystack,
    mutations: {
      condition: (condition, index) =>
        inConditionMutation(condition, index, 'not_in'),
    },
  },
  gt: { value: ['gt', 'greater than', '>', 'after'], exprMap: exprMap.ab },
  lt: { value: ['lt', 'less than', '<', 'before'], exprMap: exprMap.ab },
  gte: {
    value: ['gte', 'greater than or equal to', '>='],
    exprMap: exprMap.ab,
  },
  lte: {
    value: ['lte', 'less than or equal to', '<='],
    exprMap: exprMap.ab,
  },
};
