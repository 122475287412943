export default {
  ups: [
    {
      title: 'Profile',
      isEditable: false,
      form: [
        {
          label: 'Full Name',
          type: 'text',
          requestProperty: 'retailer_profile.primary_contact.first_name',
          required: true,
        },
        {
          label: 'Email',
          type: 'text',
          requestProperty: 'retailer_profile.primary_contact.email',
          required: true,
        },
        {
          label: 'Company',
          type: 'text',
          requestProperty: 'retailer_profile.primary_contact.company',
          required: true,
        },
        {
          label: 'Title (Optional)',
          type: 'text',
          requestProperty: 'retailer_profile.primary_contact.title',
        },
        {
          label: 'Phone',
          type: 'text',
          requestProperty: 'retailer_profile.primary_contact.phone',
          required: true,
        },
        {
          label: 'Address Line',
          type: 'text',
          requestProperty: 'retailer_profile.address.address_1',
          required: true,
        },
        {
          label: 'City',
          type: 'text',
          requestProperty: 'retailer_profile.address.city',
          required: true,
        },
        {
          label: 'State / Province / Region',
          type: 'text',
          requestProperty: 'retailer_profile.address.state',
          required: true,
        },
        {
          label: 'ZIP / Postal Code',
          type: 'text',
          requestProperty: 'retailer_profile.address.zip_code',
          required: true,
        },
        {
          label: 'Country',
          type: 'text',
          requestProperty: 'retailer_profile.address.country',
          required: true,
        },
      ],
    },
    {
      title: 'UPS Technology Agreement',
      isEditable: false,
      form: [
        {
          type: 'textarea',
          requestProperty: 'retailer_profile.license_agreement_text',
          readOnly: true,
          dynamicSource: '/api/carriers/ups-ready/license/agreement',
          pathToDynamicValue: ['license_info'],
        },
        {
          label: 'I read and agree to the UPS Technology Terms.',
          type: 'checkbox',
          requestProperty: 'retailer_profile.license_agreement',
          required: true,
        },
      ],
    },
  ],
};
