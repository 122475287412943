import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  cursor: pointer;
  background-image: url('https://assets.narvar.com/retailers/general/checkerboard.png');
  border: 1px solid ${colors.gray};
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  width: 202px;

  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border: 1px dashed ${colors.blue};
    background: ${colors.white};

    & .image-preview {
      opacity: 0.1;
    }

    & .upload-button{
      width: auto;
      height: 40px;
      position: absolute;
      display: initial;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;

      & .btn-secondary {
        background-color: transparent;
        &:hover {
          background-color: ${colors.blue};
        }
      }
    }
  }

  & .images-container {
    line-height: 242px;
    height: 242px;
    text-align: center;

    & .image-preview {
      max-height: 100%;
      max-width: 100%;
      vertical-align: baseline;
    }
  }

  & .upload-button {
    display: none;
  }

  & .img-uploader-container {
    display: inline-block;
    margin-right: 15px;
  }

  & .image-loader-pic {
    background-image: url('https://assets.narvar.com/retailers/general/iconMedia.png');
    background-color: ${colors.white};
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70px;
    height: 175px;

    & .image-loader-container {
      width: 165px;
      height: 2px;
      background-color: #CCC;
      display: block;
      position: relative;
      margin: 0 auto;
      top: 170px;

      & .image-loader {
        height: 2px;
        width: 0px;
        background-color: #1399D6;
      }
    }
  }

  & .media-drop {
    background: url('https://assets.narvar.com/retailers/general/iconMedia.png') no-repeat center center;
    border: 1px dashed ${colors.blue};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
    background-size: 70px;

    & .uploader-details {
      content: 'Drop asset here';
      background-color: ${colors.white};
    }

    & img {
      display: none;
    }
  }

  & .uploader-details {
    background: url('https://assets.narvar.com/retailers/general/iconMedia.png') no-repeat center center;
    background-size: 70px;
    background-color: ${colors.lightGray};
    height: 175px;
  }

  & .btn {
    width: 100%;
  }
`;

export default s;
