import {
  STYLE_UPDATE_STORE,
  RECEIVE_EDD_CONFIGS,
  RECEIVE_EDD_PREVIEW,
  RECEIVE_FACEBOOK_EDD_PUBLISH,
  RECEIVE_STYLING_ERROR,
  STYLING_RESET,
  RECEIVE_CURRENT_LOCALE,
} from '~src/actions/notifyStylingActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  currentLocale: '',
  isPublishable: false,
  previewUrl: '',
  dateModified: '',
  dateModifiedText: '',
  willPublishModal: false,
  messengerStatusCard: {
    header_bg_color: '',
    body_bg_color: '',
    divider_bar_color: '',
    header_color: '',
    edd_header_color: '',
    edd_color: '',
  },
  _messengerStatusCard: {
    header_bg_color: '',
    body_bg_color: '',
    divider_bar_color: '',
    header_color: '',
    edd_header_color: '',
    edd_color: '',
  },
};

function notifyStyling(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case RECEIVE_STYLING_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload,
        },
      };
    }
    case STYLE_UPDATE_STORE: {
      return {
        ...state,
        messengerStatusCard: {
          ...payload,
        },
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    case RECEIVE_EDD_PREVIEW: {
      return {
        ...state,
        isPublishable: true,
        previewUrl: payload.previewUrl,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
      };
    }
    case RECEIVE_FACEBOOK_EDD_PUBLISH: {
      return {
        ...state,
        dateModified: payload,
        isPublishable: false,
        alert: {
          color: 'success',
          show: true,
          text:
            'Publish successful. The updates will be available to all your customers shortly.',
        },
      };
    }
    case RECEIVE_EDD_CONFIGS: {
      const {
        header_bg_color,
        body_bg_color,
        divider_bar_color,
        header_color,
        edd_header_color,
        edd_color,
        dateModified,
        dateModifiedText,
        retailerSettings,
      } = payload;

      return {
        ...state,
        dateModified,
        dateModifiedText,
        messengerStatusCard: {
          ...state.messengerStatusCard,
          header_bg_color,
          body_bg_color,
          divider_bar_color,
          header_color,
          edd_header_color,
          edd_color,
        },
        _messengerStatusCard: {
          header_bg_color,
          body_bg_color,
          divider_bar_color,
          header_color,
          edd_header_color,
          edd_color,
        },
        _retailerSettings: retailerSettings,
      };
    }
    case RECEIVE_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: payload,
      };
    }
    case STYLING_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export default notifyStyling;
