import React from 'react';
import { MigrationCard } from '../ui';

export const reject = ({ option }) => ({
  option,
  newOption: null,
  message: (
    <MigrationCard title={option.return_method} validation="invalid">
      This method ({option.return_method}) is not supported in the migration.
    </MigrationCard>
  ),
});
