import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import cx from 'classnames';
import { DateRange, Calendar } from 'react-date-range';
import Button from '../Button';
import FormControl from '../FormControl';
import Svg from '../Svg';
import s from './DatePicker.styles';
import datePickerTheme from './datePickerTheme.js';

export const dateReadableFormat = 'MMM D, YYYY';

export class NarvarDatePicker extends Component {
  constructor(props) {
    super(props);

    const { i18n } = props;

    this.clicks = 0;
    this.state = {
      showDateRange: false,
      startDate: '',
      endDate: '',
      isStartDateChanged: false,
      isEndDateChanged: false,
      newStartDate: '',
      newEndDate: '',
      isApplyEnabled: false,
      isStartDateError: false,
      isEndDateError: false,
      isOpen: false,
      buttons: {
        selectLastSevenDays: {
          title: i18n.last7Days || 'Last 7 Days',
          type: 'selectLastSevenDays',
          selected: false,
        },
        selectLastThirtyDays: {
          title: i18n.last30Days || 'Last 30 Days',
          type: 'selectLastThirtyDays',
          selected: false,
        },
        selectYearToDate: {
          title: i18n.calendarYearToDate || 'Calendar Year to Date',
          type: 'selectYearToDate',
          selected: false,
        },
        customRange: {
          title: i18n.customRange || 'Custom Range',
          type: 'customRange',
          stayFocused: true,
        },
      },
      lastSelectedButton: null,
    };
  }

  componentDidMount() {
    const { startDate, endDate, singleDate } = this.props;
    this.setState({
      startDate,
      endDate,
      singleDate,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { singleDate, startDate, endDate } = this.props;
    const {
      singleDate: nextSingleDate,
      startDate: nextStartDate,
      endDate: nextEndDate,
    } = nextProps;
    if (singleDate !== nextSingleDate) {
      this.setState({
        singleDate: nextSingleDate,
      });
    }
    if (startDate !== nextStartDate) {
      this.setState({
        startDate: nextStartDate,
      });
    }
    if (endDate !== nextEndDate) {
      this.setState({
        endDate: nextEndDate,
      });
    }
  }

  onToggle = () => {
    const { isOpen, showDateRange } = this.state;
    if (!showDateRange) {
      this.setState({
        isOpen: !isOpen,
      });
    }
  };

  setDate = (startDate, endDate) => {
    this.setState(
      {
        startDate,
        endDate,
      },
      this.apply,
    );
  };

  handleClickOutside() {
    this.setState({
      showDateRange: false,
    });
  }

  singleDateOnToggleReset = () => {
    const { singleDateReset } = this.props;
    singleDateReset();
    this.setState({
      singleDate: '',
    });
  };

  isEndDateBeforeStartDate = () => {
    const {
      isEndDateChanged,
      isStartDateChanged,
      newEndDate,
      newStartDate,
      startDate,
      endDate,
    } = this.state;
    const momentedNewEndDate = moment(newEndDate, dateReadableFormat, true);
    const momentedNewStartDate = moment(newStartDate, dateReadableFormat, true);

    if (isStartDateChanged && !isEndDateChanged) {
      return momentedNewStartDate.diff(endDate) > 0;
    }
    if (isStartDateChanged && isEndDateChanged) {
      return momentedNewStartDate.diff(momentedNewEndDate) > 0;
    }
    if (!isStartDateChanged && isEndDateChanged) {
      return startDate.diff(momentedNewEndDate) > 0;
    }
    return false;
  };

  dateIsOutOfBounds(date) {
    const { minDate, maxDate } = this.props;
    return date.isAfter(maxDate) || (minDate && date.isBefore(minDate));
  }

  validateStartDate() {
    const { newStartDate } = this.state;
    const { i18n } = this.props;
    const startDate = moment(newStartDate, dateReadableFormat, true);

    if (!startDate.isValid()) {
      this.setState({
        isStartDateError:
          i18n.enterDateLike || 'Enter date format like Feb 1, 2017.',
      });
      return false;
    }

    if (this.dateIsOutOfBounds(startDate)) {
      this.setState({
        isStartDateError:
          i18n.dateShouldBeSelectable || 'Date should be in selectable range.',
      });
      return false;
    }

    this.setState({
      startDate,
      isStartDateError: false,
      newStartDate: '',
      isStartDateChanged: false,
      isEndDateError: false,
    });

    return true;
  }

  validateEndDate() {
    const { newEndDate } = this.state;
    const { i18n } = this.props;
    const endDate = moment(newEndDate, dateReadableFormat, true);

    if (!endDate.isValid()) {
      this.setState({
        isEndDateError:
          i18n.enterDateLike || 'Enter date format like Feb 1, 2017.',
      });
      return false;
    }

    if (this.dateIsOutOfBounds(endDate)) {
      this.setState({
        isEndDateError:
          i18n.dateShouldBeSelectable || 'Date should be in selectable range.',
      });
      return false;
    }

    this.setState({
      endDate,
      isEndDateError: false,
      newEndDate: '',
      isEndDateChanged: false,
      isStartDateError: false,
    });

    return true;
  }

  handleBlur = dateType => () => {
    const { isEndDateChanged, isStartDateChanged } = this.state;

    const { i18n } = this.props;

    if (this.isEndDateBeforeStartDate()) {
      this.setState({
        isEndDateError:
          i18n.endDateAfterStartDate || 'End Date should be after Start Date',
      });
    } else if (dateType === 'startDate' && isStartDateChanged) {
      this.validateStartDate();
    } else if (dateType === 'endDate' && isEndDateChanged) {
      this.validateEndDate();
    }
  };

  handleChange = dateType => e => {
    if (dateType === 'startDate') {
      this.setState({
        isApplyEnabled: true,
        isStartDateChanged: true,
        newStartDate: e.target.value,
      });
    } else {
      this.setState({
        isApplyEnabled: true,
        isEndDateChanged: true,
        newEndDate: e.target.value,
      });
    }
    this.setState({
      isEndDateError: false,
      isStartDateError: false,
    });
  };

  handleSelectItem = btn => () => {
    const { buttons, lastSelectedButton } = this.state;
    const newButton = buttons[btn];
    const newButtonType = newButton.type;
    newButton.selected = true;

    let newButtons = {
      ...buttons,
      [newButtonType]: newButton,
    };

    if (lastSelectedButton) {
      const oldSelectedButton = lastSelectedButton;
      const oldSelectButtonType = oldSelectedButton.type;
      oldSelectedButton.selected = false;

      newButtons = {
        ...newButtons,
        [oldSelectButtonType]: oldSelectedButton,
      };
    }

    this.setState(
      {
        buttons: newButtons,
        lastSelectedButton: buttons[btn],
      },
      this[btn],
    );
  };

  handleSingleDateSelected = data => {
    const { handleSelect } = this.props;
    const { isOpen } = this.state;

    this.setState({
      singleDate: data,
      isOpen: !isOpen,
    });
    handleSelect(data);
  };

  dateRangeSelected = data => {
    const { startDate, endDate } = data;
    const { newStartDate } = this.state;
    const { showDropdown, i18n } = this.props;

    if (this.clicks < 2) {
      this.clicks += 1;
    }

    if (this.clicks === 2) {
      const dateTest = moment(newStartDate, dateReadableFormat);
      if (
        newStartDate !== moment(startDate).format(dateReadableFormat) &&
        dateTest.diff(startDate) > 0
      ) {
        this.setState({
          isApplyEnabled: false,
          isEndDateError:
            i18n.endDateAfterStartDate || 'End Date should be after Start Date',
          endDate,
          startDate: endDate,
          isStartDateChanged: false,
          newStartDate: false,
          isEndDateChanged: false,
        });
      } else {
        this.setState(
          {
            startDate,
            endDate,
            isApplyEnabled: true,
            isEndDateError: false,
            isStartDateError: false,
            isStartDateChanged: false,
            newStartDate: false,
            isEndDateChanged: false,
          },
          () => {
            if (!showDropdown) {
              this.apply();
            }
          },
        );
      }
      this.clicks = 0;
    } else {
      this.setState({
        isStartDateChanged: true,
        newStartDate: moment(startDate).format(dateReadableFormat),
        isEndDateError: false,
        isStartDateError: false,
        isApplyEnabled: false,
      });
    }
  };

  selectLastSevenDays = () => {
    const startDate = moment().subtract('days', 7);
    const endDate = moment().subtract('days', 1);
    this.setDate(startDate, endDate);
  };

  selectLastThirtyDays = () => {
    const startDate = moment().subtract('days', 30);
    const endDate = moment().subtract('days', 1);
    this.setDate(startDate, endDate);
  };

  selectYearToDate = () => {
    const startDate = moment().startOf('year');
    const endDate = moment().subtract('days', 1);
    this.setDate(startDate, endDate);
  };

  customRange = () => {
    this.setState({
      showDateRange: true,
    });
  };

  apply = () => {
    const { handleSelect, i18n } = this.props;
    const { isStartDateError, isEndDateError, startDate, endDate } = this.state;
    if (this.isEndDateBeforeStartDate()) {
      this.setState({
        isEndDateError:
          i18n.endDateAfterStartDate || 'End Date should be after Start Date',
      });
      return;
    }
    if (!isStartDateError && !isEndDateError) {
      this.setState({
        showDateRange: false,
        isOpen: false,
      });
      handleSelect(startDate, endDate);

      if (this.clicks === 2) {
        this.clicks = 0;
      }
    }
    this.setState({
      isStartDateChanged: false,
      isEndDateChanged: false,
      isApplyEnabled: false,
    });
  };

  cancel = () => {
    const { onCancel } = this.props;

    if (onCancel) {
      onCancel();
    }
    this.setState({
      showDateRange: false,
    });
  };

  render() {
    const {
      showDropdown,
      ignoreHandleClickOutside,
      singleDateReset,
      noDatePlaceHolder,
      i18n,
    } = this.props;
    const {
      buttons,
      endDate,
      isOpen,
      isStartDateChanged,
      isStartDateError,
      isEndDateError,
      isEndDateChanged,
      newStartDate,
      newEndDate,
      singleDate,
      startDate,
    } = this.state;

    const { isDateRange, minDate, maxDate } = this.props;
    const emptyDatePlaceHolder =
      noDatePlaceHolder || moment().format(dateReadableFormat);
    const displayStartDate = startDate
      ? moment(startDate).format(dateReadableFormat)
      : '';
    const displayEndDate = endDate
      ? moment(endDate).format(dateReadableFormat)
      : '';
    const displayDate = `${displayStartDate} - ${displayEndDate}`;

    const showDateRange = showDropdown ? this.state.showDateRange : true;
    const displaySingleDate = singleDate
      ? moment(singleDate).format(dateReadableFormat)
      : emptyDatePlaceHolder;

    return isDateRange ? (
      <div
        className={cx(
          ignoreHandleClickOutside && 'ignore-react-onclickoutside',
          s,
          'DateRange',
          !showDropdown && 'no-dropdown',
        )}
      >
        {showDropdown && (
          <Dropdown
            className={cx('Dropdown', { 'dropdown-tick-right': 'right' })}
            data-test="date-range-dropdown"
            isOpen={isOpen || showDateRange}
            toggle={this.onToggle}
          >
            <DropdownToggle caret>
              <span className={cx('dropdown-input-display')}>
                {displayDate}
              </span>
            </DropdownToggle>
            <DropdownMenu
              right
              style={
                showDateRange
                  ? {
                      height: '308px',
                      borderRadius: 0,
                      borderTopRightRadius: '4px',
                      boxShadow: '2px 0px 4px -2px rgba(0, 0, 0, 0.2)',
                    }
                  : null
              }
            >
              {Object.keys(buttons).map(btn => (
                <button
                  key={buttons[btn].type}
                  onClick={this.handleSelectItem(buttons[btn].type)}
                  className={cx(
                    'dropdown-item',
                    'paragraph',
                    (buttons[btn].stayFocused && showDateRange) ||
                      buttons[btn].selected
                      ? 'active'
                      : null,
                  )}
                  data-test={`${buttons[btn].title.toLowerCase()}-menu-item`}
                >
                  {buttons[btn].title}
                </button>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
        {showDateRange ? (
          <div>
            <div className="date-picker-container">
              <div className="date-display-container">
                <div className="date-display-button-container">
                  <FormControl
                    type="text"
                    label={i18n.startDate || 'Start Date'}
                    data={isStartDateChanged ? newStartDate : displayStartDate}
                    onChange={this.handleChange('startDate')}
                    onBlur={this.handleBlur('startDate')}
                    validationError={isStartDateError}
                    validate={!isStartDateError}
                  />
                </div>
                <div className="date-display-button-container">
                  <FormControl
                    type="text"
                    label={i18n.endDate || 'End Date'}
                    data={isEndDateChanged ? newEndDate : displayEndDate}
                    onChange={this.handleChange('endDate')}
                    onBlur={this.handleBlur('endDate')}
                    validationError={isEndDateError}
                  />
                </div>
              </div>
              {showDropdown && <div className="vertical-line" />}
              <DateRange
                startDate={startDate}
                endDate={endDate}
                format="MMM/DD/YYYY"
                linkedCalendars
                onChange={this.dateRangeSelected}
                theme={datePickerTheme}
                minDate={minDate}
                maxDate={maxDate || moment()}
              />
            </div>
            {showDropdown && (
              <div className="bottom-section-container">
                <div className="horizontal-line" />
                <div className="bottom-button-container">
                  <div className="apply-button-container">
                    <Button
                      color="primary"
                      disabled={!this.state.isApplyEnabled}
                      onClick={this.apply}
                    >
                      {i18n.apply}
                    </Button>
                  </div>
                  <div className="button-spacer" />
                  <div className="cancel-button-container">
                    <Button color="link" onClick={this.cancel}>
                      {i18n.cancel}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    ) : (
      <div className={s}>
        <Dropdown
          className={cx('Dropdown', { 'dropdown-tick-right': 'right' })}
          data-test="single-date-dropdown"
          isOpen={isOpen}
          toggle={this.onToggle}
        >
          <DropdownToggle
            className={singleDateReset && isOpen ? 'singleDateReset' : ''}
            caret
          >
            <span className={cx('dropdown-input-display', 'single-input-date')}>
              {displaySingleDate}
            </span>
            {singleDateReset && isOpen && (
              <div className="close" onClick={this.singleDateOnToggleReset}>
                <Svg className="icon" name="close" />
              </div>
            )}
          </DropdownToggle>
          <DropdownMenu>
            <div className="date-picker-container">
              <Calendar
                onChange={this.handleSingleDateSelected}
                date={singleDate}
                theme={datePickerTheme}
                format="MMM/DD/YYYY"
              />
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

NarvarDatePicker.defaultProps = {
  minDate: null,
  i18n: {},
};

NarvarDatePicker.propTypes = {
  handleSelect: PropTypes.func,
  singleDate: PropTypes.object,
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]),
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
  ]),
  isDateRange: PropTypes.bool,
  onCancel: PropTypes.func,
  noDatePlaceHolder: PropTypes.string,
  singleDateReset: PropTypes.func,
  showDropdown: PropTypes.bool.isRequired,
  ignoreHandleClickOutside: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  i18n: PropTypes.objectOf(PropTypes.string),
};

NarvarDatePicker.displayName = 'DatePicker';

export default NarvarDatePicker;
