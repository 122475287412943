import {
  ERROR,
  INVALIDATE,
  INVALIDATE_PATH,
  REQUEST,
  RECEIVE,
} from '~src/lib/api';

export const initialState = {
  isFetching: {},
  paths: {},
};

function apiReducer(state = initialState, action) {
  const { contentType, json, method, name, path, queryString, type } = action;
  let pathObject = {};

  // Could possibly refactor switch to
  // an if, else if looking for
  // `type.startsWith('REQUEST_')`
  // `type.startsWith('RECEIVE_')`
  switch (type) {
    case ERROR:
      if (queryString) {
        pathObject = {
          [path]: {
            ...state.paths[path],
            [queryString]: null,
          },
        };
      } else {
        pathObject = {
          [path]: null,
        };
      }
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [name]: false,
        },
        paths: {
          ...state.paths,
          ...pathObject,
        },
      };

    case INVALIDATE:
      return initialState;

    case INVALIDATE_PATH:
      return {
        ...state,
        paths: {
          ...state.paths,
          [path]: null,
        },
      };

    case REQUEST:
      // Build path data
      if (queryString) {
        pathObject = {
          [path]: {
            ...state.paths[path],
            [queryString]: {
              contentType,
              method,
            },
          },
        };
      } else {
        pathObject = {
          [path]: {
            ...state.paths[path],
            contentType,
            method,
          },
        };
      }

      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [name]: true,
        },
        paths: {
          ...state.paths,
          ...pathObject,
        },
      };

    case RECEIVE:
      // Build path data
      if (queryString) {
        pathObject = {
          [path]: {
            ...state.paths[path],
            [queryString]: {
              ...state.paths[path][queryString],
              json,
            },
          },
        };
      } else {
        pathObject = {
          [path]: {
            ...state.paths[path],
            json,
          },
        };
      }

      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          [name]: false,
        },
        paths: {
          ...state.paths,
          ...pathObject,
        },
      };

    default:
      return state;
  }
}

export default apiReducer;
