import React, { Component } from 'react';

export default ({}) => {
  const styles = {};

  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        top: '20%',
        width: '500px',
        marginLeft: '-250px',
        textAlign: 'center',
      }}
    >
      <h1>Whoops!</h1>
      <p>Sorry, a critical error occurred on this page.</p>
    </div>
  );
};
