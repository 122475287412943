import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  background-color: ${colors.grayLightest};
  height: 100%;
  width: 100%;

  & .header {
    background-color: ${colors.black};
    height: 60px;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }
`;

export default s;
