import {
  RECEIVE_TRACKING_CARRIER_CREDENTIALS_LIST,
  RECEIVE_TRACKING_CARRIER_CREDENTIALS,
  FORM_CHANGE_DETECTED,
  CARRIER_SELECTED,
  TRACKING_TYPE_SELECTED,
  RECEIVE_CREDENTIALS_ERROR,
  RECEIVE_CREDENTIALS_SUCCESS,
  DISMISS_CREDENTIALS_ALERT,
  TOGGLE_DELETE_CONFIRM_MODAL,
  CLEAR_TRACK_CREDENTIALS_STATE,
  UPDATE_CREDENTIAL_FORM,
  UPDATE_DETAILS_FORM,
  RESET_CREDENTIAL_FROM,
  TOGGLE_EDITABLE_FORM,
  SET_REQUIRED_INPUTS,
  SET_REQUIRED_INPUTS_FILLED,
  TOGGLE_FORM_CARD_LOADING,
} from '~src/actions/trackingCarrierCredActions';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';
import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = {
  credentialsListHead: [],
  credentialsList: [],
  carrierCredentialFields: [],
  formCredentials: {},
  loadingFormCards: {},
  requiredInputs: [],
  requiredInputsFilled: true,
  trackingNumber: '',
  selectedType: '',
  carrierSelector: '',
  alert: {
    show: false,
  },
  showDeleteConfirmModal: false,
  isNewForm: false,
  isEditableForm: true,
};

export default (state = initialState, action) => {
  const {
    payload,
    type,
    formCredentials,
    cardIndex,
    requiredInputs,
    requiredInputsFilled,
    carrierCredentialFields,
    selectedCarrier,
    availableCarriers,
    selectedType,
    carrierSelector,
    isNewForm,
    isEditableForm,
    lastModifiedDate,
    createdDate,
    errorText,
    successText,
  } = action;

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    case RECEIVE_TRACKING_CARRIER_CREDENTIALS_LIST:
      return {
        ...state,
        credentialsListHead: payload.header[0].row,
        credentialsList: payload.body,
      };
    case RECEIVE_TRACKING_CARRIER_CREDENTIALS:
      return {
        ...state,
        formCredentials: formCredentials || {},
        selectedCarrier,
        availableCarriers,
        carrierSelector,
        isNewForm,
        lastModifiedDate,
        createdDate,
        isEditingForm: false,
      };
    case FORM_CHANGE_DETECTED:
      return {
        ...state,
        isEditingForm: true,
      };
    case CARRIER_SELECTED:
      return {
        ...state,
        selectedCarrier,
        carrierCredentialFields,
      };
    case TRACKING_TYPE_SELECTED:
      return {
        ...state,
        selectedType,
      };
    case RECEIVE_CREDENTIALS_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: successText || 'Success.',
        },
      };
    case RECEIVE_CREDENTIALS_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: errorText || 'An unknown error occurred.',
        },
      };
    case DISMISS_CREDENTIALS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };
    case TOGGLE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: !state.showDeleteConfirmModal,
      };
    case CLEAR_TRACK_CREDENTIALS_STATE:
      return {
        ...initialState,
      };
    case UPDATE_CREDENTIAL_FORM:
      return {
        ...state,
        formCredentials: {
          ...state.formCredentials,
          ...payload,
        },
      };
    case UPDATE_DETAILS_FORM:
      return {
        ...state,
        ...payload,
      };
    case RESET_CREDENTIAL_FROM:
      return {
        ...state,
        formCredentials: {},
      };
    case TOGGLE_EDITABLE_FORM:
      return {
        ...state,
        isEditableForm,
      };
    case SET_REQUIRED_INPUTS:
      return {
        ...state,
        requiredInputs,
      };
    case SET_REQUIRED_INPUTS_FILLED:
      return {
        ...state,
        requiredInputsFilled,
      };
    case TOGGLE_FORM_CARD_LOADING:
      return {
        ...state,
        loadingFormCards: {
          ...state.loadingFormCards,
          [cardIndex]: !state.loadingFormCards[cardIndex],
        },
      };
    default:
      return state;
  }
};
