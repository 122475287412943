import {
  DISPLAY_ERROR,
  DISPLAY_SUCCESS,
  ADD_NEW_TOKEN,
  RECEIVE_TOKENS,
  RECEIVE_SALESFORCE_AUTH_VALID,
  RECEIVE_SALESFORCE_CONFIG,
  RECEIVE_SALESFORCE_MULTI_BRAND,
  RECEIVE_SALESFORCE_ASSIST_METADATA,
  UPDATE_SALESFORCE_IS_MULTI_BRAND_ENABLED,
  RECEIVE_SALESFORCE_METADATA,
} from '~src/actions/partnershipsSalesforceActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  host: '',
  brands: '',
  assistMetadataId: null,
  metadataId: null,
  isValid: false,
  multiBrandEnabled: false,
  orderNumberField: '',
  emailField: '',
  readyForOauth: false,
  brandField: '',
  accessTokens: [],
};

function partnershipsSalesforceReducer(
  state = initialState,
  {
    payload: {
      text = '',
      accessToken = '',
      accessTokens = [],
      host = '',
      metadataId = '',
      assistMetadataId = '',
      brands = '',
      multiBrandEnabled = false,
      isValid = false,
    } = {},
    type,
  } = {},
) {
  switch (type) {
    case DISPLAY_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };
    }
    case ADD_NEW_TOKEN: {
      return {
        ...state,
        accessTokens: [...state.accessTokens, accessToken],
      };
    }
    case RECEIVE_SALESFORCE_AUTH_VALID: {
      return {
        ...state,
        isValid,
      };
    }
    case RECEIVE_SALESFORCE_CONFIG: {
      return {
        ...state,
        host,
        readyForOauth: Boolean(host),
      };
    }
    case RECEIVE_SALESFORCE_MULTI_BRAND: {
      return {
        ...state,
        brands,
      };
    }
    case RECEIVE_SALESFORCE_ASSIST_METADATA: {
      return {
        ...state,
        assistMetadataId,
      };
    }
    case RECEIVE_SALESFORCE_METADATA: {
      return {
        ...state,
        metadataId,
      };
    }
    case UPDATE_SALESFORCE_IS_MULTI_BRAND_ENABLED:
      return {
        ...state,
        multiBrandEnabled,
      };
    case RECEIVE_TOKENS: {
      return {
        ...state,
        accessTokens,
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    default: {
      return state;
    }
  }
}

export default partnershipsSalesforceReducer;
