import { REPORT_ID } from '../../constants/exceptionsAnalyticsApis';

const EXCEPTION_ANALYTICS = {
  title: 'Exceptions Dashboard',
  charts: {
    topBar: {
      exceptionsTotalNdr: {
        title: 'NDR',
        tooltips: 'Total NDR',
      },
      exceptionsTotalResponseToNdr: {
        title: 'NDR Response',
        tooltips: 'Total NDR Response',
      },
      exceptionsTotalOrdersDelivered: {
        title: 'NDR Delivery',
        tooltips: 'Total NDR Delivery',
      },
      exceptionsTotalIncrementalRevenue: {
        title: 'Incremental Revenue',
        tooltips: 'Total Incremental Revenue',
      },
      exceptionsRto: {
        title: 'RTO',
        tooltips: 'RTOs %',
      },
    },
    [REPORT_ID.DELIVERY_RATES]: {
      title: 'Shipment Journey',
      tooltips: 'Count of different statues throughout a shipment journey',
      displayNames: [
        'Total Shipments',
        'Total Shipped',
        'Total Delivered',
        'Total NDRs',
        'Total RTOs',
      ],
      chartInfo: {
        type: 'line',
        X: {
          axis_type: 'day',
        },
        Y: {
          axis_type: '',
        },
      },
    },
    [REPORT_ID.DELIVERED_NDRS]: {
      title: 'Delivered NDRs',
      tooltips: 'NDR Delivery rate for responded and non responded cases',
      displayNames: ['Responded Deliveries %', 'Non Responded Deliveries %'],
      chartInfo: {
        type: 'bar',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'percent',
        },
      },
    },
    [REPORT_ID.NOTIFICATION_CLICKTHROUGH]: {
      title: 'Notification Clickthrough',
      tooltips:
        'All SMS and Emails clicked out of total alerts SMS and Emails sent',
      displayNames: [
        'SMS Sent',
        'SMS Clicked',
        'Emails Sent',
        'Emails Clicked',
      ],
      chartInfo: {
        type: 'bar',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'integer',
        },
      },
    },
    [REPORT_ID.CTR_BREAKDOWN]: {
      title: 'CTR Breakdown',
      tooltips:
        'Exception response received from different communication channels',
      displayNames: [
        'SMS Responses',
        'Email Responses',
        'Call Centre Responses',
      ],
      chartInfo: {
        type: 'donut',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'percent',
        },
      },
    },
    [REPORT_ID.NDR_RESPONSE_PERCENTAGE]: {
      title: 'NDR Response Percentage',
      tooltips:
        'Customer response received on NDRs from different channels as a percentage of total NDR alerts sent on each channel',
      displayNames: ['Overall', 'SMS', 'Email', 'Call Centre'],
      chartInfo: {
        type: 'bar',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'percent',
        },
      },
    },
    [REPORT_ID.MEDIAN_NDR_RESPONSE_TIME]: {
      title: 'Median NDR Response Time',
      tooltips:
        'Middle number from all NDR response times arranged in an ascending order',
      displayNames: ['SMS', 'Email'],
      chartInfo: {
        type: 'bar',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'time',
        },
      },
    },
    [REPORT_ID.ONTIME_DELIVERY_BREAKDOWN]: {
      title: 'On-Time Delivery Breakdown',
      tooltips: 'Reschedule adherence performance at carrier level',
      displayNames: ['On-time', '1 day late', '2+ days late'],
      chartInfo: {
        type: 'bar',
        X: {
          axis_type: '',
        },
        Y: {
          axis_type: 'integer',
        },
        isCustomTooltipEnabled: true,
      },
    },
    [REPORT_ID.NDR_DELIVERY_PERCENTAGE]: {
      title: 'NDR Delivery Percentage',
      tooltips: 'NDRs delivered out of total NDRs',
      displayNames: [],
      chartInfo: {
        type: 'line',
        X: {
          axis_type: 'day',
        },
        Y: {
          axis_type: 'percent',
        },
      },
    },
  },
  chartLayout: [
    [
      {
        reportId: REPORT_ID.DELIVERY_RATES,
        row: 1,
        isDetailedView: false,
      },
    ],
    [
      {
        reportId: REPORT_ID.NOTIFICATION_CLICKTHROUGH,
        row: 2,
        isDetailedView: true,
      },
      {
        reportId: REPORT_ID.CTR_BREAKDOWN,
        row: 2,
        isDetailedView: true,
      },
    ],
    [
      {
        reportId: REPORT_ID.NDR_RESPONSE_PERCENTAGE,
        row: 2,
        isDetailedView: true,
      },
      {
        reportId: REPORT_ID.MEDIAN_NDR_RESPONSE_TIME,
        row: 2,
        isDetailedView: true,
      },
    ],
    [
      {
        reportId: REPORT_ID.NDR_DELIVERY_PERCENTAGE,
        row: 1,
        isDetailedView: false,
      },
    ],
    [
      {
        reportId: REPORT_ID.ONTIME_DELIVERY_BREAKDOWN,
        row: 1,
        isDetailedView: true,
      },
    ],
    [
      {
        reportId: REPORT_ID.DELIVERED_NDRS,
        row: 2,
        isDetailedView: true,
      },
    ],
  ],
  exceptionsChartDetails: {
    [REPORT_ID.NOTIFICATION_CLICKTHROUGH]: {
      columns: [
        'retailer_moniker',
        'time',
        'order_date',
        'tracking_number',
        'channel',
        'carrier_moniker',
        'message_body',
      ],
    },
    [REPORT_ID.CTR_BREAKDOWN]: {
      columns: [
        'order_date',
        'carrier_moniker',
        'retailer_moniker',
        'shipments',
        'ndrs',
        'redirect_requests',
        'reschedule_requests',
        'unique_responses',
        'retailer_redirect_requests',
        'retailer_reschedule_requests',
        'retailer_unique_responses',
      ],
    },
    [REPORT_ID.NDR_RESPONSE_PERCENTAGE]: {
      columns: [
        'order_date',
        'carrier_moniker',
        'retailer_moniker',
        'shipments',
        'ndrs',
        'ndr_pct',
        'email_response_pct',
        'email_sms_pct',
        'email_csv_pct',
      ],
    },
    [REPORT_ID.MEDIAN_NDR_RESPONSE_TIME]: {
      columns: ['order_date', 'carrier_moniker', 'channel', 'median_in_min'],
    },
    [REPORT_ID.DELIVERED_NDRS]: {
      columns: [
        'order_date',
        'carrier_moniker',
        'retailer_moniker',
        'ndr_source',
        'activity',
        'response_count',
      ],
    },
    [REPORT_ID.ONTIME_DELIVERY_BREAKDOWN]: {
      columns: [
        'order_date',
        'carrier_moniker',
        'retailer_moniker',
        'date_diff',
        'shipment_counts',
      ],
    },
    labels: {
      order_date: {
        label: 'Order Date',
      },
      shipped_date: {
        label: 'Shipped Date',
        format: 'date',
      },
      tracking_number: {
        label: 'Tracking Number',
      },
      order_number: {
        label: 'Order Number',
      },
      carrier_moniker: {
        label: 'Carrier',
      },
      customer_response_time: {
        label: 'Customer Response Time',
        format: 'date',
      },
      activity: {
        label: 'Activity',
      },
      delivery_attempt_count: {
        label: 'Delivery Attempt Count',
      },
      current_status: {
        label: 'Current Status',
      },
      destination: {
        label: 'Updated Pincode',
      },
      first_attempt_date: {
        label: 'First Attempt Date',
        format: 'date',
      },
      last_attempt_date: {
        label: 'Last Attempt Date',
        format: 'date',
      },
      message_body: {
        label: 'Message',
      },
      channel: {
        label: 'Channel',
      },
      shipments: {
        label: 'Shipments',
      },
      ndrs: {
        label: 'NDRs',
      },
      redirect_requests: {
        label: 'Redirect Requests',
      },
      reschedule_requests: {
        label: 'Reschedule Requests',
      },
      unique_responses: {
        label: 'Unique Responses',
      },
      retailer_redirect_requests: {
        label: 'Retailer Redirect Requests',
      },
      retailer_reschedule_requests: {
        label: 'Retailer Reschedule Requests',
      },
      retailer_unique_responses: {
        label: 'Retailer Unique Responses',
      },
      ndr_pct: {
        label: 'NDR%',
      },
      email_response_pct: {
        label: 'Response % on Email',
      },
      email_sms_pct: {
        label: 'Response % on SMS',
      },
      email_csv_pct: {
        label: 'Response % on Call Centre',
      },
      median_in_minutes: {
        label: 'Median NDR Response Time',
      },
      median_to_sms: {
        label: 'Median NDR Response Time to SMS',
      },
      median_to_email: {
        label: 'Median NDR Response Time to Email',
      },
      median_to_csv: {
        label: 'Median NDR Response Time to Call Centre',
      },
      response_count: {
        label: 'Count of Responses Per Day',
      },
      date_diff: {
        label: 'Date Difference',
      },
      shipment_counts: {
        label: 'Shipment Counts',
      },
      time: {
        label: 'Time',
        format: 'date',
      },
      median_in_min: {
        label: 'Avg. Resp. Time (min)',
      },
      ndr_source: {
        label: 'Source',
      },
      retailer_moniker: {
        label: 'Retailer',
      },
    },
  },
  exceptionsReports: {
    title: 'Exceptions Reports',
    columns: [
      'order_date',
      'shipped_date',
      'tracking_number',
      'order_number',
      'carrier_moniker',
      'customer_response_time',
      'activity',
      'delivery_attempt_count',
      'current_status',
      'destination',
      'first_attempt_date',
      'last_attempt_date',
    ],
    labels: {
      order_date: {
        label: 'Order Date',
        format: 'date',
      },
      shipped_date: {
        label: 'Shipped Date',
        format: 'date',
      },
      tracking_number: {
        label: 'Tracking Number',
      },
      order_number: {
        label: 'Order Number',
      },
      carrier_moniker: {
        label: 'Carrier',
      },
      customer_response_time: {
        label: 'Customer Response Time',
        format: 'date',
      },
      activity: {
        label: 'Activity',
      },
      delivery_attempt_count: {
        label: 'Delivery Attempt Count',
      },
      current_status: {
        label: 'Current Status',
      },
      destination: {
        label: 'Updated Pincode',
      },
      first_attempt_date: {
        label: 'First Attempt Date',
        format: 'date',
      },
      last_attempt_date: {
        label: 'Last Attempt Date',
        format: 'date',
      },
    },
  },
};

export default EXCEPTION_ANALYTICS;
