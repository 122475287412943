import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formStyles, labelStyles } from './PillSelect.styles';

const Label = ({ children, id }) => (
  <span className={cx(labelStyles, 'label')} id={id}>
    {children}
  </span>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
};

Label.defaultProps = {
  id: '',
};
const PillSelect = ({
  data: optionData = [],
  name,
  onChange,
  clearable = false,
  disabled = false,
  placeholder = 'Select an option',
  value: incomingValue,
  options: incomingOptions,
  checkbox = false,
  isMulti = false,
  readableKey = 'label',
  label: selectLabel,
  valueKey = 'value',
  className = '',
}) => {
  const options =
    incomingOptions ||
    optionData
      .map(option => {
        const value =
          option[valueKey] || option.name || option.key || option.value;

        if (!value) return null;

        const label =
          option[readableKey] || option.display_name || option.label || value;

        return {
          ...option,
          label,
          value,
          disabled: option.disabled,
        };
      })
      .filter(option => option !== null);

  const value = incomingValue || options?.filter(option => option?.selected);

  const multi = isMulti || checkbox;

  const handleChange = (selected, event) => {
    const positionMap = {
      'remove-value': 'removedValue',
      'select-option': 'option',
    };

    const valueRoot = !multi ? selected : event[positionMap[event.action]];

    const actionName = valueKey
      ? valueRoot?.[valueKey]
      : options
          .findIndex(
            e =>
              (e.name && e.name === valueRoot.name) ||
              (e.key && e.key === valueRoot.key),
          )
          .toString();

    onChange(
      {
        currentTarget: {
          name: actionName,
        },
        selected,
        event,
      },
      name,
    );
  };

  return (
    <div className={cx(formStyles, className)}>
      {selectLabel && (
        <Label htmlFor={name} id={name}>
          {selectLabel}
        </Label>
      )}
      <Select
        onChange={handleChange}
        options={options}
        value={value}
        isMulti={multi}
        isClearable={clearable}
        isDisabled={disabled}
        placeholder={placeholder}
        isOptionDisabled={option => option.disabled}
        ariaLabel={name}
        aria-labelledby={name}
      />
    </div>
  );
};

export default PillSelect;

PillSelect.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      display_name: PropTypes.string,
      name: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  checkbox: PropTypes.bool,
  isMulti: PropTypes.bool,
  readableKey: PropTypes.string,
  label: PropTypes.string,
  valueKey: PropTypes.string,
  className: PropTypes.string,
};

PillSelect.defaultProps = {
  data: null,
  clearable: false,
  disabled: false,
  placeholder: 'Select an option',
  value: null,
  options: null,
  checkbox: false,
  isMulti: false,
  readableKey: 'label',
  label: '',
  valueKey: 'value',
  className: '',
};
