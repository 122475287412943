export const schemaLabelOverrides = {
  '$.request.brand': null,
  '$.request.carrier_moniker': 'Carrier Moniker',
  '$.request.customer.address': null,
  '$.request.customer.address.address1': 'Customer Address 1',
  '$.request.customer.address.address2': 'Customer Address 2',
  '$.request.customer.address.attention': 'Customer Attention',
  '$.request.customer.address.city': 'Customer City',
  '$.request.customer.address.country_code': 'Customer Country Code',
  '$.request.customer.address.department': 'Customer Department',
  '$.request.customer.address.name': 'Customer Name',
  '$.request.customer.address.postal_code': 'Customer Postal Code',
  '$.request.customer.address.region_code': 'Customer Region Code',
  '$.request.locale': null,
  '$.request.retailer_moniker': null,
  '$.request.ship_from_address': null,
  '$.request.ship_from_address.address1': 'Ship From Address 1',
  '$.request.ship_from_address.address2': 'Ship From Address 2',
  '$.request.ship_from_address.attention': 'Ship From Attention',
  '$.request.ship_from_address.city': 'Ship From City',
  '$.request.ship_from_address.country_code': 'Ship From Country Code',
  '$.request.ship_from_address.department': 'Ship From Department',
  '$.request.ship_from_address.name': 'Ship From Name',
  '$.request.ship_from_address.postal_code': 'Ship From Postal Code',
  '$.request.ship_from_address.region_code': 'Ship From Region Code',
  '$.request.type': null,
  '$.item.categories': null,
  '$.item.custom_attributes': null,
  '$.item.destination': null,
  '$.item.destination.address1': 'Item Destination Address 1',
  '$.item.destination.address2': 'Item Destination Address 2',
  '$.item.destination.attention': 'Item Destination Attention',
  '$.item.destination.city': 'Item Destination City',
  '$.item.destination.country_code': 'Item Destination Country Code',
  '$.item.destination.department': 'Item Destination Department',
  '$.item.destination.name': 'Item Destination Name',
  '$.item.destination.postal_code': 'Item Destination Postal Code',
  '$.item.destination.region_code': 'Item Destination Region Code',
  '$.item.dimensions.height': 'Item Dimensions Height',
  '$.item.dimensions.length': 'Item Dimensions Length',
  '$.item.dimensions.unit': 'Item Dimensions Unit',
  '$.item.dimensions.width': 'Item Dimensions Width',
  '$.item.hazmat_code': null,
  '$.item.item_id': 'Item Id',
  '$.item.item_name': 'Item Name',
  '$.item.item_return_type': 'Item Return Type',
  '$.item.order_id': null,
  '$.item.personalized': null,
  '$.item.purchase_price.amount': null,
  '$.item.purchase_price.currency': null,
  '$.item.quantity': null,
  '$.item.return_reason_code': 'Return Reason',
  '$.item.shipment_id': null,
  '$.item.sku': null,
  '$.item.special_handling': null,
  '$.item.tags': null,
  '$.item.variant': null,
  '$.item.vendor': null,
  '$.item.vendor.address': null,
  '$.item.vendor.address.address1': 'Item Vendor Address 1',
  '$.item.vendor.address.address2': 'Item Vendor Address 2',
  '$.item.vendor.address.city': 'Item Vendor City',
  '$.item.vendor.address.country_code': 'Item Vendor Country Code',
  '$.item.vendor.address.department': 'Item Vendor Department',
  '$.item.vendor.address.name': 'Item Vendor Name',
  '$.item.vendor.address.postal_code': 'Item Vendor Postal Code',
  '$.item.vendor.address.region_code': 'Item Vendor Region Code',
  '$.item.vendor.name': null,
  '$.item.vendor.type': null,
  '$.item.weight.unit': null,
  '$.item.weight.value': null,
};
