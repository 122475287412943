module.exports = {
  MOST_VISITED_STATUS_V1: [
    {
      groups: [
        {
          value: 97766,
          percentage: '16.8',
          display_name: 'Just Shipped',
          type: 'INTEGER',
          key: 'JUST_SHIPPED',
        },
        {
          value: 410312,
          percentage: '70.5',
          display_name: 'In Transit',
          type: 'INTEGER',
          key: 'IN_TRANSIT',
        },
        {
          value: 74145,
          percentage: '12.7',
          display_name: 'Delivered',
          type: 'INTEGER',
          key: 'DELIVERED',
        },
      ],
      summary: null,
      data_present: true,
      display_name: 'Most Visited Status',
      type: 'GROUPS',
      key: 'MOST_VISITED_STATUS_V1',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
    },
  ],
  MARKETING_ENGAGEMENT_CLICKS_DAILY: [
    {
      groups: [
        {
          value: 2,
          display_name: '2017-03-22',
          type: 'INTEGER',
          key: '2017-03-22',
        },
        {
          value: 12,
          display_name: '2017-03-23',
          type: 'INTEGER',
          key: '2017-03-23',
        },
        {
          value: 1,
          display_name: '2017-03-24',
          type: 'INTEGER',
          key: '2017-03-24',
        },
        {
          value: 1,
          display_name: '2017-03-27',
          type: 'INTEGER',
          key: '2017-03-27',
        },
        {
          value: 1,
          display_name: '2017-03-30',
          type: 'INTEGER',
          key: '2017-03-30',
        },
        {
          value: 52,
          display_name: '2017-04-07',
          type: 'INTEGER',
          key: '2017-04-07',
        },
        {
          value: 33,
          display_name: '2017-04-08',
          type: 'INTEGER',
          key: '2017-04-08',
        },
        {
          value: 28,
          display_name: '2017-04-09',
          type: 'INTEGER',
          key: '2017-04-09',
        },
        {
          value: 22,
          display_name: '2017-04-10',
          type: 'INTEGER',
          key: '2017-04-10',
        },
        {
          value: 17,
          display_name: '2017-04-11',
          type: 'INTEGER',
          key: '2017-04-11',
        },
        {
          value: 23,
          display_name: '2017-04-12',
          type: 'INTEGER',
          key: '2017-04-12',
        },
        {
          value: 87,
          display_name: '2017-04-13',
          type: 'INTEGER',
          key: '2017-04-13',
        },
        {
          value: 1453,
          display_name: '2017-04-14',
          type: 'INTEGER',
          key: '2017-04-14',
        },
        {
          value: 624,
          display_name: '2017-04-15',
          type: 'INTEGER',
          key: '2017-04-15',
        },
        {
          value: 293,
          display_name: '2017-04-16',
          type: 'INTEGER',
          key: '2017-04-16',
        },
        {
          value: 863,
          display_name: '2017-04-17',
          type: 'INTEGER',
          key: '2017-04-17',
        },
        {
          value: 2430,
          display_name: '2017-04-18',
          type: 'INTEGER',
          key: '2017-04-18',
        },
      ],
      summary: [
        {
          value: 410.43333333333334,
          display_name: 'Average',
          type: 'DOUBLE',
          key: 'AVERAGE',
        },
        {
          value: 12313,
          display_name: 'Total Clicks',
          type: 'INTEGER',
          key: 'TOTAL_CLICKS',
        },
        {
          value: 634,
          display_name: 'Max Clicks',
          type: 'INTEGER',
          key: 'MAX_CLICKS',
        },
      ],
      data_present: true,
      display_name: 'Total Marketing Clicks',
      type: 'GROUPS',
      key: 'MARKETING_ENGAGEMENT_CLICKS_DAILY',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
    },
  ],
  MARKETING_ASSETS_PERFORMANCE: [
    {
      groups: [
        {
          value: 1418,
          display_name: 'New Asset 9/1',
          type: 'INTEGER',
          key: 'NEW_ASSET_9/1',
          src_url:
            'https://assets.narvar.com/retailers/gap/gap_MKTG_D_P_20160901161299_New-Asset-9/1_1_1.jpg',
        },
      ],
      summary: null,
      data_present: true,
      display_name: 'Marketing Assets Performance',
      type: 'GROUPS',
      key: 'MARKETING_ASSETS_PERFORMANCE',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: ['Images', 'Total'],
    },
  ],
  FEEDBACK_ENGAGEMENT_RATING: [
    {
      groups: [
        {
          value: 563,
          display_name: '5',
          type: 'INTEGER',
          key: '5',
        },
        {
          value: 57,
          display_name: '4',
          type: 'INTEGER',
          key: '4',
        },
        {
          value: 48,
          display_name: '3',
          type: 'INTEGER',
          key: '3',
        },
        {
          value: 72,
          display_name: '2',
          type: 'INTEGER',
          key: '2',
        },
        {
          value: 446,
          display_name: '1',
          type: 'INTEGER',
          key: '1',
        },
      ],
      summary: [
        {
          value: '3.2',
          display_name: 'Average',
          type: 'DOUBLE',
          key: 'AVERAGE',
        },
      ],
      data_present: true,
      display_name: 'RATING',
      type: 'GROUPS',
      key: 'FEEDBACK_ENGAGEMENT_RATING',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: ['Stars Rating', 'Total'],
    },
  ],
  SUPPORT_ENGAGEMENT: [
    {
      groups: [
        {
          value: 2263,
          display_name: 'Contact',
          type: 'INTEGER',
          key: 'CONTACT',
        },
      ],
      summary: [
        {
          value: '',
          display_name: 'Contact Rate',
          type: 'PERCENTAGE',
          key: 'CONTACT_RATE',
        },
        {
          value: 2263,
          display_name: 'TOTAL_SUPPORT_CLICKS',
          type: 'INTEGER',
          key: 'TOTAL_SUPPORT_CLICKS',
        },
      ],
      data_present: true,
      display_name: 'Support Engagement',
      type: 'GROUPS',
      key: 'SUPPORT_ENGAGEMENT',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: ['Behavior', 'Total'],
    },
  ],
  MARKETING_ENGAGEMENT_BY_FEATURE: [
    {
      groups: [
        {
          value: 10895,
          display_name: 'Navigation Bar',
          type: 'INTEGER',
          key: 'NAVIGATION',
        },
        {
          value: 1418,
          display_name: 'Marketing Assets',
          type: 'INTEGER',
          key: 'MARKETING',
        },
      ],
      summary: [
        {
          value: '14.18',
          display_name: 'Marketing Engagement Rate',
          type: 'PERCENTAGE',
          key: 'MARKETING_ENGAGEMENT_RATE',
        },
        {
          value: 12313,
          display_name: 'TOTAL_MARKETING_ENGAGEMENT_CLICKS',
          type: 'INTEGER',
          key: 'TOTAL_MARKETING_ENGAGEMENT_CLICKS',
        },
      ],
      data_present: true,
      display_name: 'Marketing Engagement',
      type: 'GROUPS',
      key: 'MARKETING_ENGAGEMENT_BY_FEATURE',
      groups_desktop: [
        {
          value: 5206,
          display_name: 'Navigation Bar',
          type: 'INTEGER',
          key: 'NAVIGATION',
        },
        {
          value: 366,
          display_name: 'Marketing Assets',
          type: 'INTEGER',
          key: 'MARKETING',
        },
      ],
      groups_mobile: [
        {
          value: 5689,
          display_name: 'Navigation Bar',
          type: 'INTEGER',
          key: 'NAVIGATION',
        },
        {
          value: 1052,
          display_name: 'Marketing Assets',
          type: 'INTEGER',
          key: 'MARKETING',
        },
      ],
      carrier_groups: null,
      groups_header: ['Features', 'Total'],
    },
  ],
  FEEDBACK_ENGAGEMENT_TOP_KEYWORDS_IN_COMMENTS: [
    {
      groups: [
        {
          value: 280,
          display_name: 'package',
          type: 'INTEGER',
          key: 'PACKAGE',
        },
        {
          value: 185,
          display_name: 'order',
          type: 'INTEGER',
          key: 'ORDER',
        },
        {
          value: 180,
          display_name: 'receive',
          type: 'INTEGER',
          key: 'RECEIVE',
        },
        {
          value: 168,
          display_name: 'ship',
          type: 'INTEGER',
          key: 'SHIP',
        },
        {
          value: 153,
          display_name: 'day',
          type: 'INTEGER',
          key: 'DAY',
        },
      ],
      summary: null,
      data_present: true,
      display_name: 'COMMENTS',
      type: 'GROUPS',
      key: 'FEEDBACK_ENGAGEMENT_TOP_KEYWORDS_IN_COMMENTS',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: ['Main Used Keywords', 'Total'],
    },
  ],
  DASHBOARD_TOP_BAR: [
    {
      value: 12313,
      data_present: true,
      display_name: 'Marketing Engagement Clicks',
      type: 'INTEGER',
      key: 'MARKETING_ENGAGEMENT_CLICKS',
    },
    {
      value: 201709,
      data_present: true,
      display_name: 'Unique Packages Tracked',
      type: 'INTEGER',
      key: 'UNIQUE_PACKAGES_TRACKED',
    },
    {
      value: 582223,
      data_present: true,
      display_name: 'Total Visits',
      type: 'INTEGER',
      key: 'TOTAL_USER_VISITS',
    },
    {
      value: '14.18',
      data_present: true,
      display_name: 'Marketing Engagement Rate',
      type: 'PERCENTAGE',
      key: 'MARKETING_ENGAGEMENT_RATE',
    },
  ],
  MARKETING_ENGAGEMENT_CLICKS: {
    value: 12313,
    data_present: true,
    display_name: 'Marketing Engagement Clicks',
    type: 'INTEGER',
    key: 'MARKETING_ENGAGEMENT_CLICKS',
  },
  UNIQUE_PACKAGES_TRACKED: {
    value: 201709,
    data_present: true,
    display_name: 'Unique Packages Tracked',
    type: 'INTEGER',
    key: 'UNIQUE_PACKAGES_TRACKED',
  },
  TOTAL_USER_VISITS: {
    value: 582223,
    data_present: true,
    display_name: 'Total Visits',
    type: 'INTEGER',
    key: 'TOTAL_USER_VISITS',
  },
  MARKETING_ENGAGEMENT_RATE: {
    value: '6.1',
    data_present: true,
    display_name: 'Marketing Engagement Rate',
    type: 'PERCENTAGE',
    key: 'MARKETING_ENGAGEMENT_RATE',
  },
  RETURN_REASONS: {
    name: 'RETURN_REASONS',
    keywordList: [
      {
        keyword: 'Fit',
        count: 188615,
      },
      {
        keyword: 'Style / Color',
        count: 32268,
      },
      {
        keyword: 'Changed mind',
        count: 27138,
      },
      {
        keyword: 'Fabric',
        count: 16267,
      },
      {
        keyword: 'Other',
        count: 9382,
      },
      {
        keyword: 'Defective / Damaged',
        count: 2265,
      },
      {
        keyword: 'Wrong item sent',
        count: 2235,
      },
    ],
    totalCommentsCount: null,
    totalCount: 278170,
    maxKeywordCount: 188615,
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  RETURNS_RECEIVED: {
    name: 'RETURNS_RECEIVED',
    result: [
      {
        date: '2017-03-20',
        clicks: 14,
      },
      {
        date: '2017-03-18',
        clicks: 1,
      },
      {
        date: '2017-03-17',
        clicks: 2480,
      },
      {
        date: '2017-03-16',
        clicks: 2701,
      },
      {
        date: '2017-03-15',
        clicks: 2445,
      },
      {
        date: '2017-03-14',
        clicks: 3034,
      },
      {
        date: '2017-03-13',
        clicks: 4419,
      },
      {
        date: '2017-03-12',
        clicks: 64,
      },
      {
        date: '2017-03-11',
        clicks: 728,
      },
      {
        date: '2017-03-10',
        clicks: 3939,
      },
      {
        date: '2017-03-09',
        clicks: 4090,
      },
      {
        date: '2017-03-08',
        clicks: 4785,
      },
      {
        date: '2017-03-07',
        clicks: 3727,
      },
      {
        date: '2017-03-06',
        clicks: 4122,
      },
      {
        date: '2017-03-05',
        clicks: 111,
      },
      {
        date: '2017-03-04',
        clicks: 1902,
      },
      {
        date: '2017-03-03',
        clicks: 3420,
      },
      {
        date: '2017-03-02',
        clicks: 3673,
      },
      {
        date: '2017-03-01',
        clicks: 3856,
      },
      {
        date: '2017-02-28',
        clicks: 2919,
      },
      {
        date: '2017-02-27',
        clicks: 2889,
      },
      {
        date: '2017-02-26',
        clicks: 99,
      },
      {
        date: '2017-02-25',
        clicks: 935,
      },
      {
        date: '2017-02-24',
        clicks: 2092,
      },
      {
        date: '2017-02-23',
        clicks: 1619,
      },
      {
        date: '2017-02-22',
        clicks: 883,
      },
      {
        date: '2017-02-21',
        clicks: 169,
      },
      {
        date: '2017-02-20',
        clicks: 42,
      },
      {
        date: '2017-02-19',
        clicks: 77,
      },
      {
        date: '2017-02-18',
        clicks: 42,
      },
    ],
    average: 2042.5666666666666,
    totalClicks: 61277,
    maxClick: 4785,
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  RETURN_INITIATED_RECEIVED: {
    name: 'RETURN_INITIATED_RECEIVED',
    totalVisits: null,
    count: 111127,
    countReceived: 61396,
    percentage: 0.5524849946457656,
    sourceCountMap: null,
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  RETURN_METHODS: {
    name: 'RETURN_METHODS',
    mainCategories: [
      {
        name: 'overall',
        categoryList: [
          {
            category: 'Mail: Standard',
            count: 109411,
            percentage: 0.9845582081762309,
          },
          {
            category: 'In-Store',
            count: 1716,
            percentage: 0.0154417918237692,
          },
        ],
        subCategories: null,
        listOfSubCategories: null,
      },
    ],
    count: 0,
    exceptionCount: 0,
    percentage: 0.0,
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  RETURNS_PER_ORDER: {
    name: 'RETURNS_PER_ORDER',
    mostVisitedStatus: null,
    category: [
      {
        count: 100594,
        average: 0.9526849133440667,
        status: null,
        keyword: '1',
      },
      {
        count: 4537,
        average: 0.042968084098873,
        status: null,
        keyword: '2',
      },
      {
        count: 459,
        average: 0.0043470025570603275,
        status: null,
        keyword: '3+',
      },
    ],
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  ITEMS_PER_RETURN: {
    name: 'ITEMS_PER_RETURN',
    mostVisitedStatus: null,
    category: [
      {
        count: 43040,
        average: 0.40761435742021024,
        status: null,
        keyword: '1',
      },
      {
        count: 26032,
        average: 0.24653849796382232,
        status: null,
        keyword: '2',
      },
      {
        count: 13213,
        average: 0.1251349559617388,
        status: null,
        keyword: '3',
      },
      {
        count: 8137,
        average: 0.07706222180130694,
        status: null,
        keyword: '4',
      },
      {
        count: 15168,
        average: 0.14364996685292167,
        status: null,
        keyword: '5+',
      },
    ],
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  MOST_RETURNED_ITEM: {
    name: 'MOST_RETURNED_ITEM',
    result: [
      {
        clicks: 0,
        count: 79,
        average: 0.0,
        linkeTitle: 'Modal spacedye tie dress',
        linkSubTitle: 'navy space dye/M',
        linkHref:
          'https://secure-www.gap.com/resources/productImage/v1/640972012/Z?appId=narvar',
        linkId: null,
      },
      {
        clicks: 0,
        count: 79,
        average: 0.0,
        linkeTitle: 'Ponte pencil skirt',
        linkSubTitle: 'black &amp; white stripe/S',
        linkHref:
          'https://secure-www.gap.com/resources/productImage/v1/713297022/Z?appId=narvar',
        linkId: null,
      },
      {
        clicks: 0,
        count: 74,
        average: 0.0,
        linkeTitle: 'Ponte pencil skirt',
        linkSubTitle: 'true black/M',
        linkHref:
          'https://secure-www.gap.com/resources/productImage/v1/713297002/Z?appId=narvar',
        linkId: null,
      },
      {
        clicks: 0,
        count: 69,
        average: 0.0,
        linkeTitle: 'Ponte pencil skirt',
        linkSubTitle: 'black &amp; white stripe/M',
        linkHref:
          'https://secure-www.gap.com/resources/productImage/v1/713297022/Z?appId=narvar',
        linkId: null,
      },
      {
        clicks: 0,
        count: 66,
        average: 0.0,
        linkeTitle: 'Linen-cotton stripe cardigan',
        linkSubTitle: 'dark night/XS/S',
        linkHref:
          'https://secure-www.gap.com/resources/productImage/v1/525134012/Z?appId=narvar',
        linkId: null,
      },
    ],
    totalClicksToBrand: null,
    totalCount: 367,
    data_present: true,
    display_name: '',
    type: '',
    key: '',
  },
  TOTAL_OPTINS: [
    {
      groups: [
        {
          value: 56049,
          display_name: 'SMS',
          type: 'Integer',
          key: 'SMS',
        },
        {
          value: 25412,
          display_name: 'Messenger',
          type: 'Integer',
          key: 'FB',
        },
        {
          value: 40827,
          display_name: 'Email',
          type: 'Integer',
          key: 'EMAIL',
        },
      ],
      summary: [
        {
          value: 122288,
          display_name: 'Total',
          type: 'Integer',
          key: 'TOTAL_OPTINS',
        },
      ],
      data_present: true,
      display_name: 'Total Opt-ins',
      type: 'GROUPS',
      key: 'TOTAL_OPTINS',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
      carriers: null,
    },
  ],
  UNIQUE_OPTINS: [
    {
      groups: [
        {
          value: 56339,
          display_name: 'SMS',
          type: 'Integer',
          key: 'SMS',
        },
        {
          value: 15071,
          display_name: 'Messenger',
          type: 'Integer',
          key: 'FB',
        },
        {
          value: 38801,
          display_name: 'Email',
          type: 'Integer',
          key: 'EMAIL',
        },
      ],
      summary: [
        {
          value: 110211,
          display_name: 'Total',
          type: 'Integer',
          key: 'UNIQUE_OPTINS_TOTAL',
        },
      ],
      data_present: true,
      display_name: 'Unique Opt-ins',
      type: 'GROUPS',
      key: 'UNIQUE_OPTINS',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
      carriers: null,
    },
  ],
  OPTINS_BY_USER: [
    {
      groups: [
        {
          value: '0.994853',
          display_name: 'SMS',
          type: 'Double',
          key: 'SMS',
        },
        {
          value: '1.666667',
          display_name: 'Messenger',
          type: 'Double',
          key: 'FB',
        },
      ],
      summary: [
        {
          value: '0.995031',
          display_name: 'Avg',
          type: 'Double',
          key: 'OPTINS_BY_USER_AVG',
        },
      ],
      data_present: true,
      display_name: 'Opt-ins by User',
      type: 'GROUPS',
      key: 'OPTINS_BY_USER',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
      carriers: null,
    },
  ],
  OPTIN_BREAKDOWN: [
    {
      groups: [
        {
          value: '45.83',
          display_name: 'SMS',
          type: 'Double',
          key: 'SMS',
        },
        {
          value: '20.78',
          display_name: 'Messenger',
          type: 'Double',
          key: 'FB',
        },
        {
          value: '33.39',
          display_name: 'Email',
          type: 'Double',
          key: 'EMAIL',
        },
      ],
      summary: null,
      data_present: true,
      display_name: 'Opt-in Breakdown',
      type: 'GROUPS',
      key: 'OPTIN_BREAKDOWN',
      groups_desktop: null,
      groups_mobile: null,
      carrier_groups: null,
      groups_header: null,
      carriers: null,
    },
  ],
  ORDER_VOLUME_BY_DAY: [
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 91964,
        day: '2017-06-06T00:00:00',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 89199,
        day: '2017-06-07T00:00:00',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 83590,
        day: '2017-06-08T00:00:00',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 69701,
        day: '2017-06-09T00:00:00',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 91756,
        day: '2017-06-10T00:00:00',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 143042,
        day: '2017-06-11T00:00:00',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 109019,
        day: '2017-06-12T00:00:00',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-13T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 81773,
        day: '2017-06-13T00:00:00',
      },
      dimensions: {
        day: '2017-06-13T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 77020,
        day: '2017-06-14T00:00:00',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-15T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 73488,
        day: '2017-06-15T00:00:00',
      },
      dimensions: {
        day: '2017-06-15T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 76441,
        day: '2017-06-16T00:00:00',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 78831,
        day: '2017-06-17T00:00:00',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 101118,
        day: '2017-06-18T00:00:00',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 91710,
        day: '2017-06-19T00:00:00',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 83274,
        day: '2017-06-20T00:00:00',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-21T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 89242,
        day: '2017-06-21T00:00:00',
      },
      dimensions: {
        day: '2017-06-21T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 89545,
        day: '2017-06-22T00:00:00',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 77927,
        day: '2017-06-23T00:00:00',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 127631,
        day: '2017-06-24T00:00:00',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 120230,
        day: '2017-06-25T00:00:00',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 112880,
        day: '2017-06-26T00:00:00',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 83106,
        day: '2017-06-27T00:00:00',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 77533,
        day: '2017-06-28T00:00:00',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 68475,
        day: '2017-06-29T00:00:00',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 67645,
        day: '2017-06-30T00:00:00',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 54054,
        day: '2017-07-01T00:00:00',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 53521,
        day: '2017-07-02T00:00:00',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 22215,
        day: '2017-07-03T00:00:00',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count_distinct_order_number: 6118,
        day: '2017-07-04T00:00:00',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
      },
    },
  ],
  ORDER_VOLUME_BY_BRAND: [
    {
      precision: {},
      metrics: {
        brand_moniker: 'Athleta',
        count_distinct_order_number: 221917,
      },
      dimensions: {
        brand_moniker: 'Athleta',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy',
        count_distinct_order_number: 1230199,
      },
      dimensions: {
        brand_moniker: 'Old Navy',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP',
        count_distinct_order_number: 588840,
      },
      dimensions: {
        brand_moniker: 'GAP',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy Canada',
        count_distinct_order_number: 4568,
      },
      dimensions: {
        brand_moniker: 'Old Navy Canada',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'BR Factory Store',
        count_distinct_order_number: 34509,
      },
      dimensions: {
        brand_moniker: 'BR Factory Store',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Banana Republic',
        count_distinct_order_number: 324978,
      },
      dimensions: {
        brand_moniker: 'Banana Republic',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Factory Store',
        count_distinct_order_number: 41020,
      },
      dimensions: {
        brand_moniker: 'GAP Factory Store',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Canada',
        count_distinct_order_number: 36930,
      },
      dimensions: {
        brand_moniker: 'GAP Canada',
      },
    },
  ],
  DELIVERY_BY_PROMISE_DAYS: [
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -192,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -192,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -272,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -272,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -214,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -214,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -244,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -244,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -140,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -140,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -43,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -43,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 27,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: 27,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -250,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -250,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -274,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -274,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -323,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -323,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -287,
        count: 3,
      },
      dimensions: {
        delivery_to_promise_days: -287,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -145,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -145,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -19,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -19,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -183,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -183,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -213,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -213,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -291,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -291,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 19,
        count: 10,
      },
      dimensions: {
        delivery_to_promise_days: 19,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -190,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -190,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -28,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -28,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -297,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -297,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -285,
        count: 3,
      },
      dimensions: {
        delivery_to_promise_days: -285,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -211,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -211,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -123,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -123,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 22,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: 22,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -278,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -278,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -289,
        count: 3,
      },
      dimensions: {
        delivery_to_promise_days: -289,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -206,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -206,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -229,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -229,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -259,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -259,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -121,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -121,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 21,
        count: 4,
      },
      dimensions: {
        delivery_to_promise_days: 21,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -334,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -334,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 14,
        count: 58,
      },
      dimensions: {
        delivery_to_promise_days: 14,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -16,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -16,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -169,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -169,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 13,
        count: 113,
      },
      dimensions: {
        delivery_to_promise_days: 13,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -248,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -248,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -247,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -247,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -168,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -168,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -83,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -83,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -296,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -296,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -177,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -177,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -321,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -321,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -311,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -311,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -44,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -44,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -324,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -324,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 26,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: 26,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 20,
        count: 8,
      },
      dimensions: {
        delivery_to_promise_days: 20,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -246,
        count: 3,
      },
      dimensions: {
        delivery_to_promise_days: -246,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -288,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -288,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -2,
        count: 365682,
      },
      dimensions: {
        delivery_to_promise_days: -2,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -181,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -181,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -7,
        count: 148220,
      },
      dimensions: {
        delivery_to_promise_days: -7,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -292,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -292,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -308,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -308,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -149,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -149,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 2,
        count: 10391,
      },
      dimensions: {
        delivery_to_promise_days: 2,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -176,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -176,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -162,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -162,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -307,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -307,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -280,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -280,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -125,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -125,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 11,
        count: 150,
      },
      dimensions: {
        delivery_to_promise_days: 11,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -231,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -231,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 15,
        count: 60,
      },
      dimensions: {
        delivery_to_promise_days: 15,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -298,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -298,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 6,
        count: 1608,
      },
      dimensions: {
        delivery_to_promise_days: 6,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 1,
        count: 59905,
      },
      dimensions: {
        delivery_to_promise_days: 1,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -243,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -243,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -300,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -300,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -316,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -316,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 7,
        count: 870,
      },
      dimensions: {
        delivery_to_promise_days: 7,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -11,
        count: 3709,
      },
      dimensions: {
        delivery_to_promise_days: -11,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -184,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -184,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -302,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -302,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -98,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -98,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -4,
        count: 384479,
      },
      dimensions: {
        delivery_to_promise_days: -4,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 23,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: 23,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -113,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -113,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 3,
        count: 10091,
      },
      dimensions: {
        delivery_to_promise_days: 3,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 8,
        count: 555,
      },
      dimensions: {
        delivery_to_promise_days: 8,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 5,
        count: 3242,
      },
      dimensions: {
        delivery_to_promise_days: 5,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -286,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -286,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -13,
        count: 1245,
      },
      dimensions: {
        delivery_to_promise_days: -13,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -3,
        count: 322849,
      },
      dimensions: {
        delivery_to_promise_days: -3,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -124,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -124,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -6,
        count: 348572,
      },
      dimensions: {
        delivery_to_promise_days: -6,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -194,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -194,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 10,
        count: 210,
      },
      dimensions: {
        delivery_to_promise_days: 10,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 16,
        count: 22,
      },
      dimensions: {
        delivery_to_promise_days: 16,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -197,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -197,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -92,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -92,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -1,
        count: 407849,
      },
      dimensions: {
        delivery_to_promise_days: -1,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 9,
        count: 252,
      },
      dimensions: {
        delivery_to_promise_days: 9,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -309,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -309,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -15,
        count: 131,
      },
      dimensions: {
        delivery_to_promise_days: -15,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 12,
        count: 147,
      },
      dimensions: {
        delivery_to_promise_days: 12,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 17,
        count: 13,
      },
      dimensions: {
        delivery_to_promise_days: 17,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -171,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -171,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -251,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -251,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -306,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -306,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -188,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -188,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -12,
        count: 2885,
      },
      dimensions: {
        delivery_to_promise_days: -12,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -238,
        count: 2,
      },
      dimensions: {
        delivery_to_promise_days: -238,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 0,
        count: 405329,
      },
      dimensions: {
        delivery_to_promise_days: 0,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -329,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -329,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -295,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -295,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -14,
        count: 1530,
      },
      dimensions: {
        delivery_to_promise_days: -14,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -10,
        count: 8525,
      },
      dimensions: {
        delivery_to_promise_days: -10,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -239,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -239,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -9,
        count: 18468,
      },
      dimensions: {
        delivery_to_promise_days: -9,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -8,
        count: 30705,
      },
      dimensions: {
        delivery_to_promise_days: -8,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -17,
        count: 67,
      },
      dimensions: {
        delivery_to_promise_days: -17,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -5,
        count: 446818,
      },
      dimensions: {
        delivery_to_promise_days: -5,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: null,
        count: 66400,
      },
      dimensions: {
        delivery_to_promise_days: null,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -195,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -195,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 18,
        count: 7,
      },
      dimensions: {
        delivery_to_promise_days: 18,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 4,
        count: 3843,
      },
      dimensions: {
        delivery_to_promise_days: 4,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -299,
        count: 5,
      },
      dimensions: {
        delivery_to_promise_days: -299,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -271,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -271,
      },
    },
  ],
  LIVE_ORDER_AGING: [
    {
      precision: {},
      metrics: {
        days_since_order: 1,
        count: 8068,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 1,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 1,
        count: 142,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 1,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 1,
        count: 2684,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 1,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 1,
        count: 11,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 1,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 1,
        count: 247,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 1,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 2,
        count: 29538,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 2,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 2,
        count: 371,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 2,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 2,
        count: 427,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 2,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 2,
        count: 757,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 2,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 2,
        count: 11803,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 2,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 27526,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 62,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 1024,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 57,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 2563,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 19235,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 3,
        count: 843,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 3,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 1753,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 1050,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 9230,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 82,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 32641,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 52746,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 4,
        count: 29,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 4,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 83,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 1063,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 59,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 20122,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 53399,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 12526,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 5,
        count: 849,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 5,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 17976,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 56144,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 1180,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 55,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 100,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 22233,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 6,
        count: 710,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 6,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 133,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 6722,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 261,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 15843,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 34108,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 38341,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 7,
        count: 1495,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 7,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 11778,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 449,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 198,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 1683,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 11219,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 27050,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 8,
        count: 62168,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 8,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 8050,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 1179,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 81044,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 14619,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 1975,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 9653,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 9,
        count: 1610,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 9,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 201,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 15476,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 3679,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 1830,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 131376,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 9,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 10,
        count: 19295,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 10,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 109,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 6672,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 6652,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 14,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 39668,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 129499,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 11,
        count: 58,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 11,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 26020,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 6142,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 15,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 4775,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 143279,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 4,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 12,
        count: 42,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 12,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 2649,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 10174,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 95144,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 55,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 7,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 2498,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 13,
        count: 9,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 13,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 14,
        count: 3238,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        days_since_order: 14,
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 14,
        count: 78,
        on_time_status: 'On Time',
      },
      dimensions: {
        days_since_order: 14,
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 14,
        count: 10873,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        days_since_order: 14,
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 14,
        count: 1937,
        on_time_status: 'Overdue',
      },
      dimensions: {
        days_since_order: 14,
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        days_since_order: 14,
        count: 111470,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        days_since_order: 14,
        on_time_status: 'Delivered Early',
      },
    },
  ],
  ON_TIME_PERFORMANCE: [
    {
      precision: {},
      metrics: {
        count: 2507870,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        count: 9142,
        on_time_status: 'Trending Late',
      },
      dimensions: {
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {},
      metrics: {
        count: 456375,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        count: 38956,
        on_time_status: 'Overdue',
      },
      dimensions: {
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {},
      metrics: {
        count: 298639,
        on_time_status: 'Trending Early',
      },
      dimensions: {
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {},
      metrics: {
        count: 156676,
        on_time_status: 'On Time',
      },
      dimensions: {
        on_time_status: 'On Time',
      },
    },
    {
      precision: {},
      metrics: {
        count: 91750,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        on_time_status: 'Delivered Late',
      },
    },
  ],
  ORDER_TO_DELIVERY_BY_CUST_SHIPMENT: [
    {
      precision: {},
      metrics: {
        avg_order_to_deliver_days: 4.04,
        customer_service_type: 'Expedited',
      },
      dimensions: {
        customer_service_type: 'Expedited',
      },
    },
    {
      precision: {},
      metrics: {
        avg_order_to_deliver_days: 2.07,
        customer_service_type: 'Next Day',
      },
      dimensions: {
        customer_service_type: 'Next Day',
      },
    },
    {
      precision: {},
      metrics: {
        avg_order_to_deliver_days: 5.72,
        customer_service_type: 'Basic',
      },
      dimensions: {
        customer_service_type: 'Basic',
      },
    },
  ],
  ON_TIME_BY_BRAND: [
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy',
        count: 47339,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'Old Navy',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Factory Store',
        count: 6075,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'GAP Factory Store',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy',
        count: 1306737,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'Old Navy',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP',
        count: 103732,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'GAP',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy Canada',
        count: 4420,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'Old Navy Canada',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP',
        count: 22337,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'GAP',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Athleta',
        count: 43955,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'Athleta',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Canada',
        count: 841,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'GAP Canada',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Factory Store',
        count: 27085,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'GAP Factory Store',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Banana Republic',
        count: 11485,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'Banana Republic',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Banana Republic',
        count: 318155,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'Banana Republic',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Canada',
        count: 35652,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'GAP Canada',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy Canada',
        count: 88,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'Old Navy Canada',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy',
        count: 247181,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'Old Navy',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Banana Republic',
        count: 48609,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'Banana Republic',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'BR Factory Store',
        count: 1124,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'BR Factory Store',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Factory Store',
        count: 1024,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'GAP Factory Store',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Old Navy Canada',
        count: 72,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'Old Navy Canada',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Athleta',
        count: 7607,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'Athleta',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'BR Factory Store',
        count: 24136,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'BR Factory Store',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP Canada',
        count: 576,
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        brand_moniker: 'GAP Canada',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'GAP',
        count: 618715,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'GAP',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'Athleta',
        count: 172470,
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        brand_moniker: 'Athleta',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {},
      metrics: {
        brand_moniker: 'BR Factory Store',
        count: 5719,
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        brand_moniker: 'BR Factory Store',
        on_time_status: 'Delivered On Time',
      },
    },
  ],
  SHIP_TO_DELIVERY_BY_CUSTOMER_SHIPMENT_METHOD: [
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 3.99,
        carrier_service_code: 'UPS - Mail Innovations',
      },
      dimensions: {
        carrier_service_code: 'UPS - Mail Innovations',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.88,
        carrier_service_code: 'UPS - Standard',
      },
      dimensions: {
        carrier_service_code: 'UPS - Standard',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.76,
        carrier_service_code: 'UPS - 2nd Day Air',
      },
      dimensions: {
        carrier_service_code: 'UPS - 2nd Day Air',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 3.57,
        carrier_service_code: 'UPS - SurePost',
      },
      dimensions: {
        carrier_service_code: 'UPS - SurePost',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 4.5,
        carrier_service_code: 'Other',
      },
      dimensions: {
        carrier_service_code: 'Other',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.23,
        carrier_service_code: 'UPS - Expedited',
      },
      dimensions: {
        carrier_service_code: 'UPS - Expedited',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 3.32,
        carrier_service_code: 'UPS - 3 Day Select',
      },
      dimensions: {
        carrier_service_code: 'UPS - 3 Day Select',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.07,
        carrier_service_code: 'OnTrac - Ground',
      },
      dimensions: {
        carrier_service_code: 'OnTrac - Ground',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.3,
        carrier_service_code: 'USPS - First Class',
      },
      dimensions: {
        carrier_service_code: 'USPS - First Class',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 0.83,
        carrier_service_code: 'Lasership - Consumer Direct',
      },
      dimensions: {
        carrier_service_code: 'Lasership - Consumer Direct',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 0.47,
        carrier_service_code: 'UDS - NEXT DAY',
      },
      dimensions: {
        carrier_service_code: 'UDS - NEXT DAY',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 0.61,
        carrier_service_code: 'UPS - Next Day Air Saver',
      },
      dimensions: {
        carrier_service_code: 'UPS - Next Day Air Saver',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: null,
        carrier_service_code: 'UPS - Worldwide Saver',
      },
      dimensions: {
        carrier_service_code: 'UPS - Worldwide Saver',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 0.08,
        carrier_service_code: 'UDS - SAME DAY',
      },
      dimensions: {
        carrier_service_code: 'UDS - SAME DAY',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 3.94,
        carrier_service_code: 'UPS - Ground',
      },
      dimensions: {
        carrier_service_code: 'UPS - Ground',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.46,
        carrier_service_code: 'Lasership - Routed Delivery',
      },
      dimensions: {
        carrier_service_code: 'Lasership - Routed Delivery',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 2.6,
        carrier_service_code: 'UPS - Next Day Air',
      },
      dimensions: {
        carrier_service_code: 'UPS - Next Day Air',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 0.03,
        carrier_service_code: 'UDS - A',
      },
      dimensions: {
        carrier_service_code: 'UDS - A',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 1.53,
        carrier_service_code: 'UPS - Worldwide Expedited',
      },
      dimensions: {
        carrier_service_code: 'UPS - Worldwide Expedited',
      },
    },
    {
      precision: {},
      metrics: {
        avg_ship_to_deliver_days: 4.02,
        carrier_service_code: 'USPS - Priority',
      },
      dimensions: {
        carrier_service_code: 'USPS - Priority',
      },
    },
  ],
  SHIPMENT_VOLUME_BY_STATUS: [
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count: 4548,
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count: 99548,
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count: 967,
        day: '2017-06-06T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count: 38,
        day: '2017-06-06T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-06T00:00:00',
      },
      metrics: {
        count: 22972,
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-06T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count: 17749,
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count: 3556,
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count: 1060,
        day: '2017-06-07T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count: 88,
        day: '2017-06-07T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-07T00:00:00',
      },
      metrics: {
        count: 100786,
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-07T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count: 2897,
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count: 340,
        day: '2017-06-08T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count: 90925,
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count: 1008,
        day: '2017-06-08T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-08T00:00:00',
      },
      metrics: {
        count: 20124,
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-08T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count: 745,
        day: '2017-06-09T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count: 178,
        day: '2017-06-09T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count: 72869,
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count: 1857,
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-09T00:00:00',
      },
      metrics: {
        count: 20315,
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-09T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count: 3639,
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count: 25972,
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count: 1081,
        day: '2017-06-10T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count: 100463,
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-10T00:00:00',
      },
      metrics: {
        count: 107,
        day: '2017-06-10T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-10T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count: 116,
        day: '2017-06-11T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count: 1680,
        day: '2017-06-11T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count: 50991,
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count: 9324,
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-11T00:00:00',
      },
      metrics: {
        count: 153077,
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-11T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count: 28,
        day: '2017-06-12T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count: 25771,
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count: 1380,
        day: '2017-06-12T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count: 5933,
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-12T00:00:00',
      },
      metrics: {
        count: 131551,
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-12T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-13T00:00:00',
      },
      metrics: {
        count: 95481,
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-13T00:00:00',
      },
      metrics: {
        count: 982,
        day: '2017-06-13T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-13T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-13T00:00:00',
      },
      metrics: {
        count: 4122,
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-13T00:00:00',
      },
      metrics: {
        count: 18388,
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-13T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count: 13034,
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count: 93787,
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count: 1004,
        day: '2017-06-14T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count: 4963,
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-14T00:00:00',
      },
      metrics: {
        count: 2,
        day: '2017-06-14T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-14T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-15T00:00:00',
      },
      metrics: {
        count: 926,
        day: '2017-06-15T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-15T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-15T00:00:00',
      },
      metrics: {
        count: 8438,
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-15T00:00:00',
      },
      metrics: {
        count: 93274,
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-15T00:00:00',
      },
      metrics: {
        count: 3111,
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-15T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count: 2,
        day: '2017-06-16T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count: 97567,
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count: 9310,
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count: 2186,
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-16T00:00:00',
      },
      metrics: {
        count: 974,
        day: '2017-06-16T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-16T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count: 3011,
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count: 1099,
        day: '2017-06-17T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count: 95889,
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count: 1,
        day: '2017-06-17T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-17T00:00:00',
      },
      metrics: {
        count: 13199,
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-17T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count: 5387,
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count: 1412,
        day: '2017-06-18T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count: 2,
        day: '2017-06-18T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count: 119964,
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-18T00:00:00',
      },
      metrics: {
        count: 24050,
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-18T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count: 13437,
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count: 5,
        day: '2017-06-19T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count: 4255,
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count: 1242,
        day: '2017-06-19T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-19T00:00:00',
      },
      metrics: {
        count: 113756,
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-19T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count: 1,
        day: '2017-06-20T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count: 1389,
        day: '2017-06-20T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count: 99511,
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count: 4294,
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-20T00:00:00',
      },
      metrics: {
        count: 15326,
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-20T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-21T00:00:00',
      },
      metrics: {
        count: 109650,
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-21T00:00:00',
      },
      metrics: {
        count: 13932,
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-21T00:00:00',
      },
      metrics: {
        count: 3791,
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-21T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-21T00:00:00',
      },
      metrics: {
        count: 1658,
        day: '2017-06-21T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-21T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count: 111470,
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count: 78,
        day: '2017-06-22T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count: 10873,
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count: 1937,
        day: '2017-06-22T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-22T00:00:00',
      },
      metrics: {
        count: 3238,
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-22T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 55,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 2498,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 9,
        day: '2017-06-23T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 7,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 2649,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 10174,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-23T00:00:00',
      },
      metrics: {
        count: 95144,
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-23T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 4,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 42,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 143279,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 26020,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 15,
        day: '2017-06-24T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 6142,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-24T00:00:00',
      },
      metrics: {
        count: 4775,
        day: '2017-06-24T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-24T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 6652,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 109,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 129499,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 58,
        day: '2017-06-25T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 39668,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 6672,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-25T00:00:00',
      },
      metrics: {
        count: 14,
        day: '2017-06-25T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-25T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 9,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 3679,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 1830,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 19295,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 15476,
        day: '2017-06-26T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 131376,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-26T00:00:00',
      },
      metrics: {
        count: 201,
        day: '2017-06-26T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-26T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 1610,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 14619,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 81044,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 8050,
        day: '2017-06-27T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 1179,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 9653,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-27T00:00:00',
      },
      metrics: {
        count: 1975,
        day: '2017-06-27T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-27T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 1683,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 27050,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 449,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 11219,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 62168,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 11778,
        day: '2017-06-28T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-28T00:00:00',
      },
      metrics: {
        count: 198,
        day: '2017-06-28T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-28T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 261,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 34108,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 38341,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 133,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 6722,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 15843,
        day: '2017-06-29T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-29T00:00:00',
      },
      metrics: {
        count: 1495,
        day: '2017-06-29T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-29T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 1180,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 17976,
        day: '2017-06-30T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 55,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 100,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 56144,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 710,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-06-30T00:00:00',
      },
      metrics: {
        count: 22233,
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-06-30T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 1063,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 59,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 12526,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 20122,
        day: '2017-07-01T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 53399,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 849,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-07-01T00:00:00',
      },
      metrics: {
        count: 83,
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-07-01T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 9230,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 1050,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 82,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 1753,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 52746,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 32641,
        day: '2017-07-02T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-07-02T00:00:00',
      },
      metrics: {
        count: 29,
        day: '2017-07-02T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-07-02T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 843,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 19235,
        day: '2017-07-03T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 27526,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 2563,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 1024,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 62,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered Late',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Delivered Late',
      },
    },
    {
      precision: {
        day: '2017-07-03T00:00:00',
      },
      metrics: {
        count: 57,
        day: '2017-07-03T00:00:00',
        on_time_status: 'Overdue',
      },
      dimensions: {
        day: '2017-07-03T00:00:00',
        on_time_status: 'Overdue',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count: 427,
        day: '2017-07-04T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count: 757,
        day: '2017-07-04T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count: 29538,
        day: '2017-07-04T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count: 371,
        day: '2017-07-04T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-07-04T00:00:00',
      },
      metrics: {
        count: 11803,
        day: '2017-07-04T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-07-04T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-07-05T00:00:00',
      },
      metrics: {
        count: 2684,
        day: '2017-07-05T00:00:00',
        on_time_status: 'On Time',
      },
      dimensions: {
        day: '2017-07-05T00:00:00',
        on_time_status: 'On Time',
      },
    },
    {
      precision: {
        day: '2017-07-05T00:00:00',
      },
      metrics: {
        count: 8068,
        day: '2017-07-05T00:00:00',
        on_time_status: 'Trending Early',
      },
      dimensions: {
        day: '2017-07-05T00:00:00',
        on_time_status: 'Trending Early',
      },
    },
    {
      precision: {
        day: '2017-07-05T00:00:00',
      },
      metrics: {
        count: 11,
        day: '2017-07-05T00:00:00',
        on_time_status: 'Trending Late',
      },
      dimensions: {
        day: '2017-07-05T00:00:00',
        on_time_status: 'Trending Late',
      },
    },
    {
      precision: {
        day: '2017-07-05T00:00:00',
      },
      metrics: {
        count: 142,
        day: '2017-07-05T00:00:00',
        on_time_status: 'Delivered Early',
      },
      dimensions: {
        day: '2017-07-05T00:00:00',
        on_time_status: 'Delivered Early',
      },
    },
    {
      precision: {
        day: '2017-07-05T00:00:00',
      },
      metrics: {
        count: 247,
        day: '2017-07-05T00:00:00',
        on_time_status: 'Delivered On Time',
      },
      dimensions: {
        day: '2017-07-05T00:00:00',
        on_time_status: 'Delivered On Time',
      },
    },
  ],
  PROMISE_DATE_PERFORMANCE: [
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -27,
        count: 1,
      },
      dimensions: {
        delivery_to_promise_days: -27,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -4,
        count: 218280,
      },
      dimensions: {
        delivery_to_promise_days: -4,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: -1,
        count: 233585,
      },
      dimensions: {
        delivery_to_promise_days: -1,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 0,
        count: 236082,
      },
      dimensions: {
        delivery_to_promise_days: 0,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: 2,
        count: 6110,
      },
      dimensions: {
        delivery_to_promise_days: 2,
      },
    },
    {
      precision: {},
      metrics: {
        delivery_to_promise_days: null,
        count: 13695,
      },
      dimensions: {
        delivery_to_promise_days: null,
      },
    },
  ],
  SUPPORT_CHAT_ENGAGEMENT: [
    {
      groups: [
        {
          value: 10,
          display_name: 'widget:opened',
          type: 'INTEGER',
          key: 'widget:opened',
        },
        {
          value: 3,
          display_name: 'widget:closed',
          type: 'INTEGER',
          key: 'widget:closed',
        },
        {
          value: 3,
          display_name: 'message:sent',
          type: 'INTEGER',
          key: 'message:sent',
        },
        {
          value: 3,
          display_name: 'typing:stop',
          type: 'INTEGER',
          key: 'typing:stop',
        },
      ],
      data_present: true,
      display_name: 'Gorgias Chat',
      type: 'GROUPS',
      key: 'GORGIAS CHAT',
    },
    {
      groups: [
        {
          value: 7,
          display_name: 'open',
          type: 'INTEGER',
          key: 'open',
        },
      ],
      data_present: true,
      display_name: 'Zendesk Chat',
      type: 'GROUPS',
      key: 'ZENDESK CHAT',
    },
    {
      groups: [
        {
          value: 125,
          display_name: 'open',
          type: 'INTEGER',
          key: 'open',
        },
        {
          value: 20,
          display_name: 'Contact Form Shown',
          type: 'INTEGER',
          key: 'Contact Form Shown',
        },
        {
          value: 105,
          display_name: 'Chat Opened',
          type: 'INTEGER',
          key: 'Chat Opened',
        },
        {
          value: 60,
          display_name: 'Chat Served by Operator',
          type: 'INTEGER',
          key: 'Chat Served by Operator',
        },
        {
          value: 25,
          display_name: 'Chat Comment Submitted',
          type: 'INTEGER',
          key: 'Chat Comment Submitted',
        },
        {
          value: 15,
          display_name: 'Contact Form Submitted',
          type: 'INTEGER',
          key: 'Contact Form Submitted',
        },
      ],
      data_present: true,
      display_name: 'Zendesk Web Widget (Classic)',
      type: 'GROUPS',
      key: 'ZENDESK WEB WIDGET (CLASSIC)',
    },
  ],
};
