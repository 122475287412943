import { RETURNS_LOCALES } from '../../../constants/returns/configurations';

export const getTypeWrap = ({ op, value }) => {
  if (op && op.ofType) {
    return `${op.ofType}<${value}>`;
  }

  return value;
};

export const findValues = (object, key, path) => {
  let value = [];
  Object.keys(object).forEach(k => {
    if (k === key) {
      value.push({ value: object[k], key: k, path });
    }

    if (object[k] && typeof object[k] === 'object') {
      const deepVal = findValues(object[k], key, `${path}.${k}`);
      value = [...value, ...deepVal];
    }
  });
  return value;
};

export const getParams = () =>
  Object.fromEntries(
    [...new URLSearchParams(window.location.search)].map(e =>
      e.map(v => (v === 'true' ? true : v)),
    ),
  );

export const policyEnabled = () => {
  const { destinations, policy } = getParams();
  return destinations || policy;
};

export const getCurrencyCodes = () => {
  const generatedSet = [
    ...new Set([
      'USD',
      'CAD',
      'EUR',
      'GBP',
      ...RETURNS_LOCALES.map(({ currencyCode }) => currencyCode).sort(),
    ]),
  ].map(code => ({ picklist_token: code, picklist_value: code }));
  return [
    { picklist_token: null, picklist_value: 'Any Currency' },
    ...generatedSet,
  ];
};
