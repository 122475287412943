import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './TableHeader.styles';
import Svg from '../Svg';

export class TableHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, headings, sortable } = this.props;

    return (
      <thead
        className={cx(className, s, sortable ? 'sortable' : '')}
      >
        <tr className={'table-row'}>
          {headings.map((heading, index) => {
            let selected = heading.sort && heading.sort.includes(heading.field);

            return (
              <th
                className={cx(
                  'table-heading',
                  `table-heading-col-${index}`,
                  selected ? s['selected-sort'] : ''
                )}
                key={index}
                onClick={heading.onHeaderClick}
                width={`${heading.width / 12 * 100}%`}
              >
                <div
                  className={cx(
                    'flex-row',
                    'flex-align-center',
                    'label-icon-container'
                  )}
                >
                  <div className={'label'}>
                    {heading.label}
                  </div>

                  {sortable &&
                    !selected &&
                    <div className={'sort-icon-hover'}>
                      <Svg name={'arrow_down'} height={14} width={14} />
                    </div>}
                  {selected &&
                    <div className={'sort-icon'}>
                      {heading.sort.includes('DESC')
                        ? <Svg
                            name={'arrow_down'}
                            height={14}
                            width={14}
                          />
                        : <Svg name={'arrow_up'} height={14} width={14} />}
                    </div>}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
    );
  }
}

TableHeader.propTypes = {
  className: PropTypes.string,
  headings: PropTypes.array.isRequired,
  sortable: PropTypes.bool,
};

export default TableHeader;
