import { Dropdown, Svg, Tooltip } from '@narvar/hum';
import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import cx from 'classnames';
import { goBack, push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dismissAlert, toggleNavigation } from '~src/actions/uiActions';
import { fetchCsmLogout, logout } from '~src/actions/userActions';
import { parseQueryString, throttle } from '~src/lib/helpers';
import withStyles from '~src/lib/isomorphic-style-loader/withStyles';
import s from './Header.scss';
import { withTranslation } from 'react-i18next';

function mapStateToProps(state) {
  const {
    router: { location },
    userReducer: { user },
  } = state;

  return {
    user,
    location,
  };
}

export class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScrolled: false,
      isTooltipOpen: false,
    };

    this.throttledScroll = throttle(this.handleScroll, 200, this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.throttledScroll);
  }

  componentWillReceiveProps(nextProps) {
    const locationChanged = nextProps.location !== this.props.location;

    if (locationChanged) {
      window.scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledScroll);
  }

  handleClickProfile = () => {
    const { dispatch } = this.props;

    dispatch(push('/admin/profile'));
  };

  handleLogout = () => {
    const { dispatch } = this.props;

    dispatch(logout());
  };

  handleCsmLogout = () => {
    const { dispatch } = this.props;

    dispatch(fetchCsmLogout());
  };

  handleGoBack = () => {
    const { backButton, dispatch } = this.props;

    // Explicit pathname override provided vs default history behavior
    if (typeof backButton === 'string') {
      dispatch(push(backButton));
    } else if (typeof backButton === 'function') {
      backButton();
    } else {
      dispatch(goBack());
    }
    dispatch(dismissAlert());
  };

  handleScroll = event => {
    const { scrollTop } = event.target.body;

    if (scrollTop > 50 && !this.state.isScrolled) {
      this.setState({
        isScrolled: true,
      });
    } else if (scrollTop < 50 && this.state.isScrolled) {
      this.setState({
        isScrolled: false,
      });
    }
  };

  handleToggleTooltip = () => {
    this.setState({
      isTooltipOpen: !this.state.isTooltipOpen,
    });
  };

  render() {
    const {
      backButton,
      dispatch,
      secondaryTitle,
      user,
      location,
      showSubHeader,
      t,
    } = this.props;
    if (!user) return null;
    const isCustomerSupport = user.customerSupportLogin;
    const topBarTitle =
      user.retailerIdToRetailerInfo[user.retailerId].retailer_name;
    let queryString = '';

    if (location) {
      queryString = parseQueryString(location.search);
    }

    const userButtons = isCustomerSupport
      ? [
          {
            onClick: this.handleLogout,
            text: t('header.logOut'),
            className: 'color-red',
          },
        ]
      : [
          {
            onClick: this.handleClickProfile,
            text: t('header.viewProfile'),
          },
          {
            onClick: this.handleLogout,

            text: t('header.logOut'),
            className: 'color-red',
          },
        ];

    return (
      <>
        <div
          className={cx(this.state.isScrolled ? 'is-scrolled' : '', s.Header)}
          data-test="header"
        >
          <nav
            className={cx(s['top-bar'], {
              'is-customer-support': isCustomerSupport,
            })}
          >
            <span // eslint-disable-line
              className={s['nav-toggle']}
              data-test="header-navigation-button"
              role="button"
              aria-label="menu"
              onClick={() => dispatch(toggleNavigation())}
            >
              <Svg name="menu" width="24" height="16" />
            </span>
            <div className={s['top-bar-title']}>
              <h3 data-test="header-title">
                {topBarTitle}{' '}
                {isCustomerSupport && (
                  <span className={cx(s['csm-warning'])}>
                    {t('header.supportMode')}
                  </span>
                )}
              </h3>
            </div>
            <div className={s['top-bar-user']}>
              <Dropdown
                items={userButtons}
                buttonText={user.username}
                right
                testAttr="username"
                isFetching={false}
              />
              {isCustomerSupport && (
                <span // eslint-disable-line
                  id="exit-icon"
                  className={s['exit-toggle']}
                  data-test="header-navigation-button"
                  role="button"
                  onClick={this.handleCsmLogout}
                >
                  <Svg
                    data-test="icon-exit-test"
                    name="exit"
                    width="16.5"
                    height="16.5"
                  />
                  <Tooltip
                    className={s['header-tooltip']}
                    isOpen={this.state.isTooltipOpen}
                    placement="top"
                    target="exit-icon"
                    toggle={this.handleToggleTooltip}
                    delay={{ show: 0, hide: 20 }}
                  >
                    {t('header.exitSupportMode')}
                  </Tooltip>
                </span>
              )}
            </div>
          </nav>
        </div>
        {showSubHeader && (
          <div
            className={cx(
              this.state.isScrolled ? 'is-scrolled' : '',
              s.subHeader,
            )}
            data-test="header"
          >
            <nav className={cx(s.secondary)}>
              {backButton && !queryString.redirect && (
                <div // eslint-disable-line
                  className={s['secondary-back-button']}
                  data-test="back-button"
                  role="button"
                  onClick={this.handleGoBack}
                >
                  <Svg name="chevron" width="16" height="26" />
                </div>
              )}
              <div
                className={
                  backButton && !queryString.redirect
                    ? s['secondary-title-has-back']
                    : s['secondary-title']
                }
              >
                <h2>{secondaryTitle}</h2>
              </div>
              {this.props.children}
            </nav>
            <div className={s['scrolled-shadow']} />
          </div>
        )}
      </>
    );
  }
}

Header.defaultProps = {
  backButton: false,
  user: null,
  children: null,
  showSubHeader: true,
};

Header.propTypes = {
  backButton: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  dispatch: PropTypes.func.isRequired,
  secondaryTitle: PropTypes.string.isRequired,
  user: PropTypes.shape({
    customerSupportLogin: PropTypes.bool,
    retailerId: PropTypes.number,
    retailerIdToRetailerInfo: PropTypes.objectOf(
      PropTypes.shape({ retailer_name: PropTypes.string }),
    ),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  showSubHeader: PropTypes.bool,
};

export default connect(mapStateToProps)(
  withStyles(s)(withTranslation('general')(Header)),
);
