import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './AlertCard.styles';

const SvgCheck = (
  <svg width="45" height="45" fill="#27AE60">
    <path d="M19.4477612,32.7761194 L11.6119403,24.9402985 L9,27.5522388 L19.4477612,38 L41.8358209,15.6119403 L39.2238806,13 L19.4477612,32.7761194 Z" />
  </svg>
);

const SvgClose = (
  <svg width="45" height="45" fill="#e53935">
    <path d="M35,17 L33,15 L25,23 L17,15 L15,17 L23,25 L15,33 L17,35 L25,27 L33,35 L35,33 L27,25 L35,17 Z" />
  </svg>
);

const svgMap = {
  success: SvgCheck,
  error: SvgClose,
};

export class AlertCard extends Component {
  shouldComponentUpdate(nextProps) {
    const {
      show,
      type,
      children,
    } = this.props;

    const {
      show: newShow,
      type: newType,
      children: newChildren,
    } = nextProps;

    return (
      show !== newShow
      || type !== newType
      || children !== newChildren
    );
  }

  render() {
    const {
      show,
      children,
      type,
      title,
      toggle,
      toggleText,
    } = this.props;

    return show && (
      <div className={cx(s, type)}>
        {title && (
          <div data-test="alert-card-title" className="alert-title">
            {title}
          </div>
        )}
        {children}
        <div
          data-test="alert-card-dismiss"
          className="alert-button"
          role="button"
          tabIndex="0"
          onClick={toggle}
          onKeyPress={toggle}
        >
          {toggleText}
        </div>
        <div className="icon">
          {svgMap[type]}
        </div>
      </div>
    );
  }
}

AlertCard.defaultProps = {
  show: false,
  children: null,
  title: '',
  toggle: () => {},
  toggleText: 'Dismiss',
};

AlertCard.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.oneOf([
    'success',
    'error',
  ]).isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  toggle: PropTypes.func,
  toggleText: PropTypes.string,
};

export default AlertCard;
