import React from 'react';
import s from './Precision.styles';
import cx from 'classnames';
import { Select } from '../Select';
import { capitalizeFirstLtrOfString } from '../../lib/helpers';

export const Precision = ({ precision, options, onSelect }) => (
  <div className={s}>
    <div className={'item'}>
      <h4>Precision</h4>
      <Select
        items={options}
        selected={
          precision
            ? { precision: capitalizeFirstLtrOfString(precision) }
            : null
        }
        name={'Precision'}
        type={'select'}
        readableKey={'precision'}
        onSelect={onSelect}
        placeholder={'Select a precision'}
        testAttr={'precision'}
        showCloseIcon
      />
    </div>
  </div>
);

export default Precision;
