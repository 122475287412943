import { css } from 'emotion';

const s = css`
  &.sensitive-input-container {
    position: relative;
  }

  .sensitive-input {
    width: 100%;
    text-overflow: ellipsis;
    padding-right: 40px; /* 20px svg width, 10px padding for each side */
  }

  .svg-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: scale(0.8) translate(-10px, -50%);
    cursor: pointer;
    opacity: 0.4;
    transition: opacity 250ms;
    &.show{
      opacity: 1.0;
    }
  }
`;

export default s;
