import React, { useRef, useState } from 'react';
import Editor from '@monaco-editor/react';
import { Button } from '@narvar/hum';
import { EditorWrapper, FooterBar } from './style';

const FooterButton = ({
  actions,
  action,
  label,
  disabled,
  localChanges,
  ...props
}) => {
  const onClick =
    typeof action === 'string' && Object.keys(actions).includes(action)
      ? actions[action]
      : action;

  const isDisabled =
    // eslint-disable-next-line no-eval
    typeof disabled === 'string' ? eval(disabled) : disabled;

  return (
    <Button onClick={onClick} disabled={isDisabled} {...props}>
      {label}
    </Button>
  );
};

const FooterButtons = ({ footerButtons, actions, localChanges }) => {
  if (!footerButtons) return null;

  return (
    <FooterBar>
      {footerButtons.map(props => (
        <FooterButton
          {...{ localChanges, actions, ...props }}
          key={props.action}
        />
      ))}
    </FooterBar>
  );
};

export const CodeBox = ({ formControl, data }) => {
  const {
    onChange,
    onSave,
    name,
    language,
    footerButtons = [],
  } = formControl.props;
  const [code, setCode] = useState(data);
  const [savedCode, setSavedCode] = useState(data);
  const [localChanges, setLocalChanges] = useState(false);
  const monacoRef = useRef(null);

  const handleEditorDidMount = editor => {
    monacoRef.current = editor;
    monacoRef.current.trigger('anyString', 'editor.action.formatDocument');
  };

  const handleFormat = () => {
    monacoRef.current.trigger('anyString', 'editor.action.formatDocument');
  };

  const handleChange = value => {
    setCode(value);
    setLocalChanges(true);
    if (onChange) {
      onChange({
        target: { value },
        currentTarget: { name, value, source: 'codeBox' },
      });
    }
  };

  const handleSave = () => {
    const value = monacoRef.current.getValue();
    setSavedCode(value);
    setLocalChanges(false);
    if (onSave) {
      onSave({
        target: { value },
        currentTarget: { name, value, source: 'codeBox' },
      });
    }
  };

  const handleCancel = () => {
    setCode(savedCode);
    setLocalChanges(false);
  };

  const actions = {
    save: handleSave,
    cancel: handleCancel,
  };

  return (
    <div>
      <Button onClick={handleFormat}>Auto Align Code</Button>
      <EditorWrapper>
        <Editor
          {...(language ? { language } : {})}
          defaultValue={data}
          value={code}
          onMount={handleEditorDidMount}
          onChange={handleChange}
          minimapEnabled={false}
          options={{
            minimap: {
              enabled: false,
            },
            tabSize: 2,
            lineNumbers: 'off',
          }}
        />
      </EditorWrapper>
      <FooterButtons {...{ footerButtons, actions, localChanges }} />
    </div>
  );
};
