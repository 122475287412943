import {
  PORTABILITY_DOWNLOAD,
  PORTABILITY_UPLOAD,
  PORTABILITY_POST,
} from '~src/constants/endpoints';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';

// JSON Payload format:
// const configPayload = {
//     "retailer_name": uriMoniker,
//     "env": "", // should be set by environment var
//     "app_name": "returns",
//     "module": {
//       "settings": true,
//       "reason_codes": true,
//       "return_rules_v1": true,
//       "return_rules_v3": true,
//       "packing_slip": true,
//       "shipping_label": true
//     }
// };

export const isManifestHasValidModules = ({ manifestModule, validModules }) =>
  validModules.reduce((isValid, module) => {
    if (manifestModule[module] !== true) return false;
    return true;
  }, true);

export const downloadPortableConfig = ({ appName, module }) => async (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: { current_retailer_id: retailerId, retailerIdToRetailerInfo },
    },
  } = getState();

  const retailerName = retailerIdToRetailerInfo[retailerId].uri_moniker;
  const modules = Object.keys(module).join('&modules=');
  const params = `retailer_name=${retailerName}&app_name=${appName}&modules=${modules}`;
  const downloadURL = `//${window.location.hostname}${PORTABILITY_DOWNLOAD}?${params}`;
  window.open(downloadURL);

  dispatch({
    type: API_INVALIDATE_PATH,
    path: PORTABILITY_DOWNLOAD,
  });
};

export const uploadPortableConfig = ({ selectedFile }) => async (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: { current_retailer_id: retailerId, retailerIdToRetailerInfo },
    },
  } = getState();

  const retailerName = retailerIdToRetailerInfo[retailerId].uri_moniker;
  const form = new FormData();
  form.append('zipFile', selectedFile, selectedFile.name);
  form.append('retailer', retailerName);

  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: PORTABILITY_UPLOAD,
      body: form,
      contentType: false,
      name: 'uploadPortableConfig',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path: PORTABILITY_UPLOAD,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const confirmUploadPortableConfig = ({ manifest }) => async dispatch => {
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify({
        manifest,
      }),
      path: PORTABILITY_POST,
      name: 'confirmUploadPortableConfig',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path: PORTABILITY_POST,
    });
  } catch (error) {
    throw Error;
  }
};
