import { RETURNS_LOCALES } from '~src/constants/returns/configurations';

export default ({ currencyValue, locale, roundToNearestDollar }) => {
  const { key, currencyCode: currency } = RETURNS_LOCALES.filter(
    ({ key: localeKey }) => localeKey === locale,
  )[0];
  const parsedKey = key.replace('_', '-');
  const currencyConfiguration = { style: 'currency', currency };

  if (roundToNearestDollar) {
    currencyConfiguration.minimumFractionDigits = 0;
    currencyConfiguration.maximumFractionDigits = 0;
  }

  return (currencyValue || 0.0).toLocaleString(
    parsedKey,
    currencyConfiguration,
  );
};
