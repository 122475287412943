import { opMap } from './maps';
import { methodMap } from './mutations';
import { generatePolicyStatement } from '../../../Returns/ReturnsRules.v.3.0/AddRuleModalV2/schema/generator/ui-schema-generate-policy';

const getOp = value => {
  const opEntry = Object.entries(opMap).find(([, opValue]) =>
    opValue.value.includes(value),
  );
  return opEntry ? opEntry[0] : null;
};

export const replaceTokens = (input, tokens) => {
  // Check if input is an array
  if (Array.isArray(input)) {
    return input.map(item => replaceTokens(item, tokens));
  }

  // If input is an object
  return Object.entries(input).reduce((acc, [key, value]) => {
    // Handle keys
    let newKey = key;
    if (key.includes('{{') && key.includes('}}') && tokens.operator) {
      const { operator } = tokens;
      const expression = key.replace(/{{|}}/g, '');
      if (opMap[operator]) {
        newKey = opMap[operator].exprMap[expression];
      }
    }

    // Handle values
    let newValue;
    if (typeof value === 'object' && value !== null) {
      newValue = replaceTokens(value, tokens);
    } else if (
      typeof value === 'string' &&
      value.includes('{{') &&
      value.includes('}}')
    ) {
      const tokenKey = value.replace(/{{|}}/g, '');
      newValue = tokens[tokenKey];
      // ? tokens[tokenKey]
      // : opMap[tokenKey]?.value?.[0] ?? value;
    } else {
      newValue = value;
    }

    return { ...acc, [newKey]: newValue };
  }, {});
};

export const generateStatement = ({ rule, index, uiSchema }) => {
  if (!rule) return;
  const isV2Rule = Object.keys(rule).includes('result');
  if (isV2Rule) {
    return {
      ...JSON.parse(generatePolicyStatement(uiSchema, rule)),
      title: rule.name || 'Unnamed Rule',
      id: rule.id,
      enabled: rule.enabled,
    };
  }
  if (!rule.criteria || !rule.actions) return;
  const statementRule = rule.criteria.map(criterion => {
    let tokens;
    const {
      type,
      sub_type,
      value,
      separator,
      operator: critOp,
      aggregator,
      sub_selection_item_values: subSelectionItemValues,
    } = criterion;
    let { condition } = criterion.dc_criteria;
    const operator = getOp(critOp);
    if (operator) {
      const { mutations } = opMap[operator];
      if (mutations && mutations.condition) {
        condition = opMap[operator].mutations.condition(condition, index);
      }
    }
    if (type === 'key_value_text' && separator) {
      tokens = value.split(separator).reduce(
        (acc, val, i) => {
          acc[i === 0 ? 'key' : 'value'] = val.trim();
          return acc;
        },
        { operator, aggregator },
      );
    } else if (type === 'numeric') {
      // The UI should display normal prices like $25.75, but the policy
      // json needs to represent it as 2575.
      // TODO: the factor needs to be based on the currency as not all
      // currencies have two decimal digits.
      const scale = sub_type === 'price' ? 100.0 : 1.0;
      tokens = {
        value: parseFloat(value) * scale,
        operator,
        aggregator,
      };
    } else if (type === 'date') {
      tokens = {
        value: value.map(e => new Date(e).toISOString())[0],
        operator,
        aggregator,
      };
    } else {
      tokens = {
        value,
        operator,
        aggregator,
      };
    }

    if (subSelectionItemValues) {
      tokens = {
        ...tokens,
        ...subSelectionItemValues.reduce((acc, val, i) => {
          acc[`sub_selection_items.${i}`] = val;
          return acc;
        }, {}),
      };
    }

    const statement = {
      // Stringify and parse creates a deep copy of the object
      // to prevent mutating the original object
      condition: JSON.parse(JSON.stringify(condition)),
      enabled: rule.enabled,
      title: rule.name || 'Unnamed Rule',
      id: rule.id,
      result: [],
    };

    statement.condition = replaceTokens(statement.condition, tokens);

    const action =
      rule.actions.length === 1
        ? rule.actions[0]
        : rule.actions.find(
            ({ category }) => category === rule.selectedActionType,
          );

    if (action.dc_criteria === 'parseValue') {
      action.dc_criteria = JSON.parse(action.value[0].picklist_token);
    }
    statement.result =
      action.dc_criteria.result || action.dc_criteria.destinations;
    if (action.return_value_type === 'text') {
      tokens = { value: action.value };
      statement.result = replaceTokens(statement.result, tokens);
    } else if (action.return_value_type === 'picklist') {
      tokens = { value: action.value[0].picklist_token };
      statement.result = replaceTokens(statement.result, tokens);
    } else if (action.return_value_type === 'address') {
      tokens = { value: action.value };
      statement.result = replaceTokens(statement.result, tokens);
    } else if (action.return_value_type === 'multi_carrier_methods') {
      statement.result = {
        op: 'literal<List<ReturnMethodResult>>',
        value: action.value.map(({ picklist_token: method, carriers }) => ({
          method: methodMap[method],
          carriers: carriers
            ? carriers.map(({ picklist_token: t }) => ({
                carrier_moniker: t.split('~')[0].startsWith('$')
                  ? t.split('~')[0].slice(1)
                  : t.split('~')[0] || null,
                service_type: t.split('~')[1] || null,
                carrier_account: t.split('~')[2] || null,
              }))
            : null,
        })),
      };
    }

    return statement;
  });

  return statementRule.length > 1
    ? statementRule.reduce(
        (rules, ruleItem) => ({
          ...rules,
          condition: {
            ...rules.condition,
            exprs: [...rules.condition.exprs, ruleItem.condition],
          },
        }),
        { ...statementRule[0], condition: { op: 'all', exprs: [] } },
      )
    : statementRule;
};
export const convertRulesToPolicy = ({ rules, uiSchema }) => ({
  policy: {
    version: new Date().toISOString().split('T')[0],
    statements: rules
      .flatMap((rule, index) => generateStatement({ rule, index, uiSchema }))
      .filter(Boolean),
  },
  policy_effective_from: new Date().toISOString(),
  last_updated_at: new Date().toISOString(),
});
