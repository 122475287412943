import * as ENDPOINTS from '~src/constants/endpoints';
import { API_REQUEST } from '~src/lib/api';

export const RECEIVE_RETAILER_CONFIGURATION =
  'CARE/RECEIVE_RETAILER_CONFIGURATION';
export const REQUEST_RETAILER_CONFIGURATION =
  'CARE/REQUEST_RETAILER_CONFIGURATION';
export const RECEIVE_ERROR = 'CARE/CONFIGURATION/RECEIVE_ERROR';
export const REQUEST_UPDATE_RETAILER_CONFIGURATION =
  'CARE/REQUEST_UPDATE_RETAILER_CONFIGURATION';
export const RECEIVE_UPDATE_RETAILER_CONFIGURATION =
  'CARE/RECEIVE_UPDATE_RETAILER_CONFIGURATION';

export const receiveRetailerConfiguration = (payload, uriMoniker) => ({
  type: RECEIVE_RETAILER_CONFIGURATION,
  payload,
  meta: {
    uriMoniker,
  },
});

export const fetchRetailerConfiguration = uriMoniker => dispatch => {
  dispatch({
    type: REQUEST_RETAILER_CONFIGURATION,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.CARE_RETAILER_CONFIGURATION}/${uriMoniker}`,
    name: 'fetchRetailerConfiguration',
  }).then(
    res =>
      dispatch(
        receiveRetailerConfiguration(res.data.configuration, uriMoniker),
      ),
    err =>
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      }),
  );
};

export const receiveUpdateRetailerConfiguration = (
  payload,
  uriMoniker,
  message,
) => ({
  type: RECEIVE_UPDATE_RETAILER_CONFIGURATION,
  payload,
  meta: {
    uriMoniker,
    message,
  },
});

export const fetchUpdateAssistRetailerConfiguration = (
  uriMoniker,
  configuration,
) => dispatch => {
  dispatch({
    type: REQUEST_UPDATE_RETAILER_CONFIGURATION,
  });
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.CARE_RETAILER_CONFIGURATION}/${uriMoniker}`,
    name: 'fetchUpdateAssistRetailerConfiguration',
    body: JSON.stringify(configuration),
  }).then(
    res =>
      dispatch(
        receiveUpdateRetailerConfiguration(
          res.data.configuration,
          uriMoniker,
          res.meta.message,
        ),
      ),
    err =>
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      }),
  );
};
