import React from 'react';
import { MigrationCard } from '../ui';

export const self = ({ option }) => ({
  option,
  newOption: {
    option: {
      id: 'mail',
      methods: ['self'],
      displayMethods: false,
    },
    methods: {
      self: {
        id: 'self',
        get_address: option.get_address,
        disable_email: option.disable_email,
        use_method_price_label: true,
        payments: option.payments,
      },
    },
  },
  message: (
    <MigrationCard title="Ship on Your Own" validation="valid">
      Ship on Your Own will be migrated. This will be merged into the{' '}
      <strong>Mail</strong> method.
    </MigrationCard>
  ),
});
