import moment from 'moment';
import numeral from 'numeral';
import i18n from '../../../i18n.js';
import { COLORS, CHART_TYPES } from '../../../constants/chartConstants';
import { TEMP_RETURN_METHOD } from '../../../constants/returns/dashboardAnalytics';
import {
  pascalizeReportName,
  toLowerCaseFirstLetter,
} from '../../../lib/helpers';
import currencyFormatter from './convertCurrency';

export default class ChartData {
  constructor() {
    this._colors = COLORS;

    /**
     * Array of series for x & y data points
     * @example
     * [
     *     {
     *         x: 12,
     *         y: 22,000
     *         color: #eee
     *     },
     *     {
     *         x: 13,
     *         y: 15,563
     *         color: undefined
     *     }
     *     ...
     * ]
     * @type {Array}
     */
    this._data = []; // array of series point for x & y

    /**
     * Formatter is a object with 2 keys for xAxis & yAxis
     * This function is suppose to follow the same semantic
     * as Highcharts label.formatter function
     *
     * @type {Object}
     */
    this._formatter = {
      xAxis: undefined, // eslint-disable-line no-undefined
      yAxis: undefined, // eslint-disable-line no-undefined
      tooltip: undefined, // eslint-disable-line no-undefined
    };

    /**
     * Represents the stack type for column/bar charts
     * when multiple series is present
     *
     * @type {String}
     * @default [normal]
     */
    this._stackingType = 'normal';
    this._chartType = undefined;
    this._tickInterval = undefined;
    this._categories = {};
    this._categoryIndex = 0;
  }

  /**
   * Highcharts accepts multiple series (Like Multiple Linecharts)
   * This function allows adding series to our ChartData._data
   *
   * @param {Object} series Object which is returned from `createSeries`
   */
  addSeries(series) {
    this._data.push(series);
    return this;
  }

  /**
   * A formatter function for highchart label
   * in X Axis
   *
   * @param {Function} func
   */
  setXAxisFormatter(func) {
    if (typeof func !== 'function') {
      throw new Error('X Axis Formatter must be a function');
    }
    this._formatter.xAxis = func;
    return this;
  }

  /**
   * A formatter function for highchart label
   * in Y Axis
   *
   * @param {Function} func
   */
  setYAxisFormatter(func) {
    if (typeof func !== 'function') {
      throw new Error('Y Axis Formatter must be a function');
    }
    this._formatter.yAxis = func;
    return this;
  }

  setTooltipFormatter(func) {
    this._formatter.tooltip = func;
    return this;
  }

  /*
   * `normal`: stacking will happen based on values
   * 'percent': stacking will happen based on total percentage
   * null: stacking will be cleared & multiple series will be placed
   * side by side
   *
   */
  setStackingType(type) {
    if (type && ['normal', 'percent'].indexOf(type) === -1) {
      throw new Error('Stacking type can be null, normal or percent only');
    }
    this._stackingType = type;
    return this;
  }

  setChartType(type) {
    if (type && ['datetime', 'category'].indexOf(type) === -1) {
      throw new Error('Chart type can be null, category, or datetime only');
    }
    if (type === 'datetime') {
      this._tickInterval = 24 * 3600 * 1000;
    }
    this._chartType = type;
    return this;
  }

  setTickInterval(interval) {
    if (interval && typeof interval !== 'number') {
      throw new Error('Chart interval must be a number');
    }
    this._tickInterval = interval;
    return this;
  }

  createCategories(category) {
    if (this._categories[category] === undefined) {
      this._categories[category] = this._categoryIndex;
      this._categoryIndex += 1;
    }
    return this._categories[category];
  }

  addCategories() {
    /*
    this._categories is an object with { value: index } pairs.
    where index is the index of that data point in the original api response
    (see createCategories method)
    */
    const catKeys = Object.keys(this._categories);
    const sortedCatKeys = catKeys.sort((keyA, keyB) => {
      const { [keyA]: indexA, [keyB]: indexB } = this._categories;
      return indexA - indexB;
    });
    this._categories = sortedCatKeys;
    // this._catogories is now an array of just the values, sorted by index.
    return this;
  }

  sortCategories(func) {
    this._categories.sort(func);
    return this;
  }
}

/**
 * It creates a series object which can be stored in ChartData._data
 *
 * @param  {Array} dataPoints Array of points which are returned from `createDataPoint`
 * @param  {String=} name
 * @param  {String=} color
 *
 * @return {Object}
 */
export function createSeries(dataPoints, name, color) {
  if (!Array.isArray(dataPoints)) {
    throw new Error('Passed `dataPoints` should be an array of DataPoint type');
  }
  if (!name) {
    throw new Error('Name of the series is required');
  }
  return {
    name,
    color,
    points: dataPoints,
  };
}

/**
 * It created a data point object which together consitutes a `points`
 * for a series
 *
 * @param  {String|Number} x
 * @param  {String|Number} y
 * @param  {String=} color
 * @return {Object}
 */
export function createDataPoint(x, y, color) {
  return {
    x,
    y,
    color,
  };
}

export function applyChartFormatters(chartData, chartInfo, tooltipText, query) {
  if (chartInfo && chartInfo.X.axis_type === 'day') {
    chartData.setXAxisFormatter(value => moment.utc(value).format('MMM D'));

    if (chartInfo.type !== 'bar') {
      chartData.setChartType('datetime');
    }
  }

  if (chartInfo && chartInfo.X.axis_type === 'days') {
    chartData.setXAxisFormatter(value => {
      const suffix = value > 1 ? 'days' : 'day';
      return `${value} ${suffix}`;
    });
  }

  if (chartInfo && chartInfo.X.axis_type === 'string') {
    chartData.setXAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return value;
      }
      return value.length > 13 ? `${value.substring(0, 12)}...` : value;
    });
  }

  if (chartInfo && chartInfo.Y.axis_type === 'integer') {
    chartData.setYAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return numeral(value).format('0,0');
      }
      return numeral(value)
        .format('0.[0]a')
        .toUpperCase();
    });
  }

  if (chartInfo && chartInfo.Y.axis_type === 'currency') {
    chartData.setYAxisFormatter(value =>
      currencyFormatter({
        currencyValue: value,
        locale: query.locale,
      }),
    );
  }

  if (chartInfo && chartInfo.Y.axis_type === 'percentage') {
    const percentFormat = chartInfo.type === CHART_TYPES.DONUT ? '0.0' : '0,0';

    chartData
      .setYAxisFormatter(value => `${numeral(value).format(percentFormat)}%`)
      .setStackingType('percent');
  }

  if (
    chartInfo &&
    (chartInfo.Y.axis_type === 'average' || chartInfo.Y.axis_type === 'float')
  ) {
    chartData.setYAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return numeral(value).format('0,0.00');
      }
      return numeral(value)
        .format('0.[0]a')
        .toUpperCase();
    });
  }

  if (
    (chartInfo && chartInfo.X.axis_type === 'day') ||
    !chartData._formatter.tooltip
  ) {
    chartData.setTooltipFormatter((title, subtitle, value, itemTotal) => {
      let formattedTitle;

      if (chartInfo.X.axis_type === 'day') {
        formattedTitle = moment.utc(title).format('MMM DD, YYYY');
      } else if (chartData._formatter.xAxis) {
        formattedTitle = chartData._formatter.xAxis(title, true);
      }

      let tooltipValue;

      if (chartInfo.type === CHART_TYPES.DONUT && itemTotal) {
        tooltipValue = numeral(value).format('0,0');
      } else if (
        typeof itemTotal !== 'undefined' ||
        !chartData._formatter.yAxis
      ) {
        const percentage = parseFloat(value / 100);
        tooltipValue = `${numeral(percentage * itemTotal).format('0,0')}`;
      } else if (chartData._formatter.yAxis) {
        tooltipValue = chartData._formatter.yAxis(value, true);
      }

      return {
        title: formattedTitle,
        subtitle,
        value: tooltipValue,
        type: tooltipText,
      };
    });
  }

  return chartData;
}

export function createChartData(records, query, chartName, chartInfo) {
  const chartData = new ChartData();
  const reportTooltipKey = toLowerCaseFirstLetter(
    pascalizeReportName(chartName),
  );
  let metric;

  // This is a flag to check for reportId (temp_return_method) for new version
  const isOldReportId = query.reportId !== TEMP_RETURN_METHOD;
  if (query.percent_col && isOldReportId) {
    metric = 'percent';
  } else {
    metric = JSON.parse(query.metrics);
  }
  const dimensions = query.dimensions
    ? query.dimensions.split(',').map(d => JSON.parse(d))
    : [];
  const precision = query.precision || '';
  const tooltipText = i18n.t([
    `returns:dashboardAnalytics.chartHoverTooltips.${reportTooltipKey}`,
    'returns:dashboardAnalytics.chartHoverTooltips.default',
  ]);
  const xValueLabel = dimensions.length ? dimensions : precision;
  let dataPoints;
  const isDate = chartInfo && chartInfo.X.axis_type === 'day';
  const isDonut = chartInfo.type === CHART_TYPES.DONUT;

  const donutChartTotal =
    isDonut &&
    records.reduce((currentItemTotal, { metrics }) => {
      const {
        // metrics value for old version for tooltip
        count_distinct_return_process_info_id: itemTotalV1,
        sum_return_count: itemTotalV2, // metrics value for new version for tooltip
      } = metrics;

      const itemTotal = isOldReportId ? itemTotalV1 : itemTotalV2;

      return currentItemTotal + itemTotal;
    }, 0);

  // If dimensions is delivery_to_promise_days do this logic
  if (chartInfo && chartInfo.X.bucket) {
    const { start, end } = chartInfo.X.bucket_values;

    const groupedRecords = records.reduce((acc, r) => {
      const value = r.metrics[metric];
      let dimensionOne = r.metrics.delivery_to_promise_days;
      if (dimensionOne >= end) {
        dimensionOne = end;
      } else if (dimensionOne <= start) {
        dimensionOne = start;
      }

      if (!acc[dimensionOne]) {
        acc[dimensionOne] = 0;
      }

      acc[dimensionOne] += value;

      return acc;
    }, {});

    dataPoints = [];
    chartData._categories = {};
    chartData._categoryIndex = 0;
    // Sort them in increasing order
    for (let i = start; i <= end; i++) {
      let color = COLORS[2];

      if (i === 0) {
        color = COLORS[1];
      } else if (i < 0) {
        color = COLORS[0];
      }
      chartData.createCategories(i);
      dataPoints.push(
        createDataPoint(undefined, groupedRecords[i] || 0, color),
      );
    }

    return chartData
      .setXAxisFormatter(val => (val <= start || val > end ? `${val}+` : val))
      .setTooltipFormatter((title, subtitle, value) => ({
        title: title < start || title > end ? `${title}+` : title,
        subtitle,
        value: numeral(value).format('0,0'),
        type: tooltipText,
      }))
      .addSeries(createSeries(dataPoints, []))
      .addCategories()
      .sortCategories((a, b) => a - b);
  } else if (
    (dimensions.length > 1 && !precision.length) ||
    (dimensions.length && precision.length && chartInfo.type === 'line') ||
    (dimensions.length === 1 && precision.length)
  ) {
    if (precision.length) {
      dimensions.unshift(precision);
    }
    const [dimensionOne, dimensionTwo] = dimensions;

    const groupedRecords = records.reduce((gr, r) => {
      if (!gr[r.metrics[dimensionTwo]]) {
        gr[r.metrics[dimensionTwo]] = []; // eslint-disable-line no-param-reassign
      }
      gr[r.metrics[dimensionTwo]].push({
        [metric]: r.metrics[metric],
        [dimensionOne]: r.dimensions[dimensionOne],
      });
      return gr;
    }, {});

    Object.keys(groupedRecords).forEach(status => {
      dataPoints = groupedRecords[status].map(r => {
        const xValue = isDate
          ? moment.utc(r[dimensionOne]).valueOf()
          : chartData.createCategories(r[dimensionOne]);
        return createDataPoint(xValue, r[metric]);
      });
      chartData.addSeries(
        createSeries(dataPoints.sort((a, b) => a.x - b.x), status),
      );
    });
    chartData.addCategories();
  } else if (dimensions.length === 1 && precision.length) {
    const flattenedPrecisionData = records.reduce((acc, r) => {
      if (acc[r.dimensions[xValueLabel[0]]] !== undefined) {
        acc[r.dimensions[xValueLabel[0]]] += r.metrics[metric];
      } else {
        acc[r.dimensions[xValueLabel[0]]] = r.metrics[metric];
        chartData.createCategories(r.dimensions[xValueLabel[0]]);
      }
      return acc;
    }, {});

    dataPoints = Object.keys(flattenedPrecisionData).map(r => {
      // Need to take into account Donut charts. Currently need xValue at dimensions length of 1
      const xValue = dimensions.length === 1 ? undefined : r;

      return createDataPoint(xValue, flattenedPrecisionData[r]);
    });

    chartData.addSeries(createSeries(dataPoints, [])).addCategories();
  } else if (dimensions.length > 1 && precision.length) {
    const dimensionOne = dimensions[0];
    const dimensionTwo = dimensions[1];
    const groupedRecords = records.reduce((acc, r) => {
      if (acc[r.dimensions[dimensionTwo]] === undefined) {
        acc[r.dimensions[dimensionTwo]] = {
          [r.dimensions[dimensionOne]]: {
            metric: r.metrics[metric],
            [dimensionOne]: r.dimensions[dimensionOne],
          },
        };
      } else if (
        acc[r.dimensions[dimensionTwo]][r.dimensions[dimensionOne]] ===
        undefined
      ) {
        acc[r.dimensions[dimensionTwo]] = {
          ...acc[r.dimensions[dimensionTwo]],
          [r.dimensions[dimensionOne]]: {
            metric: r.metrics[metric],
            [dimensionOne]: r.dimensions[dimensionOne],
          },
        };
      } else {
        acc[r.dimensions[dimensionTwo]][r.dimensions[dimensionOne]].metric +=
          r.metrics[metric];
      }
      return acc;
    }, {});

    Object.keys(groupedRecords).forEach(status => {
      dataPoints = Object.keys(groupedRecords[status]).map(r => {
        const xValue = chartData.createCategories(
          groupedRecords[status][r][dimensionOne],
        );
        return createDataPoint(xValue, groupedRecords[status][r].metric);
      });

      chartData.addSeries(
        createSeries(dataPoints.sort((a, b) => a.x - b.x), status),
      );
    });
    chartData.addCategories();
  } else {
    dataPoints = records.map(r => {
      if (dimensions.length) {
        const category = r.dimensions[xValueLabel[0]];
        chartData.createCategories(category);
      }

      // Need to take into account Donut charts. Currently need xValue at dimensions length of 1
      const xValue =
        dimensions.length === 1 ? undefined : r.dimensions[xValueLabel];

      return createDataPoint(xValue, r.metrics[metric]);
    });
    chartData.addSeries(createSeries(dataPoints, [])).addCategories();
  }

  const finalChartData = applyChartFormatters(
    chartData,
    chartInfo,
    tooltipText,
    query,
  );

  if (isDonut) finalChartData.totalValue = donutChartTotal;

  return finalChartData;
}
