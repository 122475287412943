import React from 'react';
import PropTypes from 'prop-types';
import LocaleOption from './LocaleOption';
import { LanguageList, Picker } from '../styled';

const LocalePicker = ({ show, locales, onOptionClick }) => {
  if (!show) return <></>;

  return (
    <Picker role="menu" aria-hidden={show}>
      <LanguageList>
        {locales.map(locale => (
          <LocaleOption
            key={locale}
            locale={locale}
            onOptionClick={onOptionClick}
          />
        ))}
      </LanguageList>
    </Picker>
  );
};

LocalePicker.propTypes = {
  show: PropTypes.bool,
  locales: PropTypes.arrayOf(PropTypes.string),
  onOptionClick: PropTypes.func,
};

LocalePicker.defaultProps = {
  show: false,
  locales: [],
  onOptionClick: undefined,
};

export default LocalePicker;
