import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import 'moment/min/locales';
import locales from './locales';

const getCookieDomain = () => {
  if (typeof window === 'undefined') return '';

  const { hostname } = window.location;

  if (hostname.includes('localhost')) return 'localhost';

  return hostname.includes('.narvar.qa') ? '.narvar.qa' : '.narvar.com';
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    resources: locales,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
      cookieDomain: getCookieDomain(),
    },
  });

moment.locale(i18n.language);
i18n.on('languageChanged', lng => moment.locale(lng));

export default i18n;
