import { API_REQUEST } from '~src/lib/api';
import { RETURNS_RESTRICTED_EMAILS } from '../constants/endpoints';
import { showAlert } from '../actions/uiActions';
import copy from '~src/constants/copy/returns/restrictedEmails';

export const RESTRICTED_EMAILS_PAGE_LOADED = 'RESTRICTED_EMAILS_PAGE_LOADED';
export const RECEIVE_RESTRICTED_EMAILS_FILE_SELECTED =
  'RECEIVE_RESTRICTED_EMAILS_FILE_SELECTED';

export const pageLoaded = () => ({
  type: RESTRICTED_EMAILS_PAGE_LOADED,
});

export const restrictedEmailsFileSelected = file => ({
  type: RECEIVE_RESTRICTED_EMAILS_FILE_SELECTED,
  file,
});

export const uploadFile = file => dispatch => {
  const form = new FormData();
  form.set('file', file);
  dispatch({
    type: API_REQUEST,
    name: 'uploadRestrictedEmails',
    method: 'POST',
    path: RETURNS_RESTRICTED_EMAILS,
    contentType: false,
    body: form,
  })
    .then(json => {
      dispatch(pageLoaded());
      dispatch(showAlert({ color: 'success', text: copy.alert.success }));
    })
    .catch(err => {
      dispatch(pageLoaded());
      dispatch(
        showAlert({
          color: 'danger',
          text: copy.alert.uploadError,
        }),
      );
    });
};
