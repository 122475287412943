import { operators, policyUiVersion, standardActions } from './orderSchema';

const productVendor = ({ ruleType }) => ({
  policyUiVersion,
  actions: standardActions({ ruleType }),
  attribute: 'product_vendor_all',
  attribute_display_value: 'Vendor',
  type: 'text',
  operators: operators.String,
  values: [],
  separator: null,
  deprecated: true,
  tokens: {},
  dc_criteria: {
    condition: {
      op: 'all',
      exprs: [
        {
          op: 'is_list_unique_and_non_null',
          expr: {
            op: 'read_env',
            type: 'List<PolicyVendor>',
            json_path: '$.request.items[*].vendor',
          },
        },
        {
          op: '{{operator}}',
          expr_a: {
            op: 'literal<String>',
            value: '{{value}}',
          },
          expr_b: {
            op: 'read_env',
            type: 'String',
            json_path: '$.request.items[0].vendor.name',
          },
        },
      ],
    },
  },
});

export const deprecatedDefinitions = ({ ruleType }) => [
  productVendor({ ruleType }),
];
