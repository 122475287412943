import addAttachment from './assets/images/add_attachment.svg';
import addEmail from './assets/images/add_email.svg';
import admin from './assets/images/admin.svg';
import apple from './assets/images/apple.svg';
import arrowDown from './assets/images/arrow_down.svg';
import arrowUp from './assets/images/arrow_up.svg';
import assist from './assets/images/assist.svg';
import bell from './assets/images/bell.svg';
import carriers from './assets/images/carriers.svg';
import check from './assets/images/check.svg';
import checkWhite from './assets/images/bit_icon_check_white.svg';
import chevron from './assets/images/chevron.svg';
import clock from './assets/images/clock.svg';
import close from './assets/images/close.svg';
import deleteSvg from './assets/images/delete.svg';
import delivered from './assets/images/delivered.svg';
import desktop from './assets/images/desktop.svg';
import download from './assets/images/download.svg';
import edit from './assets/images/edit.svg';
import email from './assets/images/email.svg';
import emailSent from './assets/images/email_sent.svg';
import equal from './assets/images/equal.svg';
import exception from './assets/images/exception.svg';
import exit from './assets/images/exit.svg';
import fail from './assets/images/fail.svg';
import flag from './assets/images/flag.svg';
import hide from './assets/images/hide.svg';
import home from './assets/images/home.svg';
import img from './assets/images/img.svg';
import inTransit from './assets/images/in_transit.svg';
import info from './assets/images/info.svg';
import justShipped from './assets/images/just_shipped.svg';
import labs from './assets/images/labs.svg';
import location from './assets/images/location_pin.svg';

import menu from './assets/images/menu.svg';
import mobile from './assets/images/mobile.svg';
import monitor from './assets/images/monitor.svg';
import narvarLogo from './assets/images/narvar_logo.svg';
import newStatus from './assets/images/new_status.svg';
import newSvg from './assets/images/new.svg';
import notification from './assets/images/notification.svg';
import notify from './assets/images/notify.svg';
import platform from './assets/images/platform.svg';
import plus from './assets/images/plus.svg';
import refresh from './assets/images/refresh.svg';
import returnSvg from './assets/images/return.svg';
import search from './assets/images/search.svg';
import settings from './assets/images/settings.svg';
import ship from './assets/images/ship.svg';
import show from './assets/images/show.svg';
import star from './assets/images/star.svg';
import starHalf from './assets/images/star_half.svg';
import tablet from './assets/images/tablet.svg';
import track from './assets/images/track.svg';
import triangle from './assets/images/triangle.svg';
import unfilledArrow from './assets/images/unfilledArrow.svg';
import upload from './assets/images/upload.svg';

const files = {
  addAttachment,
  addEmail,
  admin,
  apple,
  arrowDown,
  arrowUp,
  assist,
  bell,
  carriers,
  check,
  checkWhite,
  chevron,
  clock,
  close,
  delete: deleteSvg,
  delivered,
  desktop,
  download,
  edit,
  email,
  emailSent,
  equal,
  exception,
  exit,
  fail,
  flag,
  hide,
  home,
  img,
  inTransit,
  info,
  justShipped,
  labs,
  location,
  menu,
  mobile,
  monitor,
  narvarLogo,
  newStatus,
  new: newSvg,
  notification,
  notify,
  platform,
  plus,
  refresh,
  return: returnSvg,
  search,
  settings,
  ship,
  show,
  star,
  starHalf,
  tablet,
  track,
  triangle,
  unfilledArrow,
  upload,
};

export default name => {
  const matchedFile = files[name];
  if (!matchedFile) throw new Error(`Module ${name} not found.`);
  return matchedFile;
};
