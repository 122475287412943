import * as ENDPOINTS from '~src/constants/endpoints';
import { API_REQUEST } from '~src/lib/api';

export const REQUEST_RETAILER_SETTINGS = 'REQUEST_RETAILER_SETTINGS';
export const RECEIVE_ERROR = 'RECEIVE_ERROR';
export const RECEIVE_RETAILER_SETTINGS = 'RECEIVE_RETAILER_SETTINGS';
export const REQUEST_UPDATE_RETAILER_SETTINGS =
  'REQUEST_UPDATE_RETAILER_SETTINGS';
export const RECEIVE_UPDATE_RETAILER_SETTINGS =
  'RECEIVE_UPDATE_RETAILER_SETTINGS';
export const RECEIVE_UPDATE_ASSET_IMAGE = 'RECEIVE_UPDATE_ASSET_IMAGE';
export const REQUEST_UPLOAD_ASSET_IMAGE = 'REQUEST_UPLOAD_ASSET_IMAGE';

export const receiveRetailerSettings = (payload, uriMoniker) => ({
  type: RECEIVE_RETAILER_SETTINGS,
  payload,
  meta: {
    uriMoniker,
  },
});

export const fetchRetailerSettings = uriMoniker => dispatch => {
  dispatch({
    type: REQUEST_RETAILER_SETTINGS,
  });
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.CARE_RETAILER_SETTINGS}/${uriMoniker}`,
    name: 'fetchRetailerSettings',
  }).then(
    res =>
      dispatch(
        receiveRetailerSettings(
          {
            settings: res.data.settings,
          },
          uriMoniker,
        ),
      ),
    err =>
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      }),
  );
};

export const receiveUpdateRetailerSettings = (
  payload,
  uriMoniker,
  message,
) => ({
  type: RECEIVE_UPDATE_RETAILER_SETTINGS,
  payload,
  meta: {
    uriMoniker,
    message,
  },
});

export const fetchUpdateAssistRetailerSettings = (
  uriMoniker,
  settings,
) => dispatch => {
  dispatch({
    type: REQUEST_UPDATE_RETAILER_SETTINGS,
  });
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.CARE_RETAILER_SETTINGS}/${uriMoniker}`,
    name: 'fetchUpdateAssistRetailerSettings',
    body: JSON.stringify(settings),
  }).then(
    res =>
      dispatch(
        receiveUpdateRetailerSettings(
          res.data.settings,
          uriMoniker,
          res.meta.message,
        ),
      ),
    err =>
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      }),
  );
};

export const fetchUploadAssetImage = (
  imageObject,
  fileName,
  locale,
  key,
) => dispatch => {
  const path = `${ENDPOINTS.TRACKING_ASSETS_UPLOAD}?locale=${locale}&type=image`;
  const form = new FormData();

  form.append('file', imageObject, fileName);

  dispatch({
    type: REQUEST_UPLOAD_ASSET_IMAGE,
  });

  dispatch({
    type: API_REQUEST,
    path,
    body: form,
    contentType: false,
    method: 'POST',
    name: 'uploadImageAsset',
  }).then(response => {
    if (response.status === 'success') {
      const { asset_url: uploadedAssetUrl } = response;

      dispatch({
        type: RECEIVE_UPDATE_ASSET_IMAGE,
        meta: {
          key,
        },
        payload: uploadedAssetUrl,
      });
    } else {
      dispatch({
        type: RECEIVE_ERROR,
        error: 'Sorry, there was a problem uploading your image.',
      });
    }
  });
};
