import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Stars.styles';
import Svg from '../Svg';

export class Stars extends PureComponent {
  render() {
    const { count } = this.props;

    return (
      <div data-test={`${count}-stars`} className={s}>
        {[...Array(5)].map((star, index) => {
          return (
            <Svg
              name={'star'}
              className={index < count ? 'star' : 'star-empty'}
              key={index}
              height={'30'}
              width={'32'}
            />
          );
        })}
      </div>
    );
  }
}

Stars.propTypes = {
  count: PropTypes.number.isRequired,
};

export default Stars;
