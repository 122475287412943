import { LOCATION_CHANGE } from 'connected-react-router';
import {
  TOGGLE_NAVIGATION_MENU,
  DISMISS_ALERT,
  DISMISS_ALERT_CARD,
  SHOW_ALERT,
} from '../actions/uiActions';

const initialState = {
  alert: {
    color: 'info',
    show: false,
    text: '',
  },
  alertCard: {
    show: false,
  },
  isNavOpen: false,
};

function uiReducer(state = initialState, action) {
  const { type, meta } = action;
  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: {
          show: initialState.alert.show,
          color: state.alert.color,
          text: initialState.alert.text,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          show: initialState.alert.show,
          color: state.alert.color,
          text: initialState.alert.text,
        },
      };

    case DISMISS_ALERT_CARD:
      return {
        ...state,
        alertCard: initialState.alertCard,
      };

    case TOGGLE_NAVIGATION_MENU:
      return {
        ...state,
        isNavOpen: !state.isNavOpen,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alert: {
          show: true,
          color: meta.color,
          text: meta.text,
        },
      };

    default:
      return state;
  }
}

export default uiReducer;
