import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './MultiSelect.styles';
import Checkbox from '../Checkbox';

class MultiSelect extends Component {
  render() {
    const { items, onChange, readableKey } = this.props;
    return (
      <div className={s}>
        {items.map((item, i) => (
          <Checkbox
            item={item}
            index={i}
            readableKey={readableKey}
            onChange={onChange}
            key={`${item[readableKey]}-${i}`}
          />
        ))}
      </div>
    )
  }
};

MultiSelect.propTypes = {
  items: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  readableKey: PropTypes.string
};

export default MultiSelect;
