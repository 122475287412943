import { API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
export const DISPLAY_ERROR = 'DISPLAY_ERROR_SALESFORCE';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS_SALESFORCE';
export const ADD_NEW_TOKEN = 'ADD_NEW_TOKEN_SALESFORCE';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS_SALESFORCE';
export const RECEIVE_SALESFORCE_AUTH_VALID = 'RECEIVE_SALESFORCE_AUTH_VALID';
export const RECEIVE_SALESFORCE_CONFIG = 'RECEIVE_SALESFORCE_CONFIG';
export const RECEIVE_SALESFORCE_MULTI_BRAND = 'RECEIVE_SALESFORCE_MULTI_BRAND';
export const RECEIVE_SALESFORCE_ASSIST_METADATA =
  'RECEIVE_SALESFORCE_ASSIST_METADATA';
export const RECEIVE_SALESFORCE_METADATA = 'RECEIVE_SALESFORCE_METADATA';
export const UPDATE_SALESFORCE_IS_MULTI_BRAND_ENABLED =
  'UPDATE_SALESFORCE_IS_MULTI_BRAND_ENABLED';

export const displayError = text => ({
  type: DISPLAY_ERROR,
  payload: { text },
});

export const displaySuccess = text => ({
  type: DISPLAY_SUCCESS,
  payload: { text },
});

export const addNewToken = accessToken => ({
  type: ADD_NEW_TOKEN,
  payload: { accessToken },
});

export const receiveTokens = accessTokens => ({
  type: RECEIVE_TOKENS,
  payload: { accessTokens },
});

export const receiveSalesforceAuthValid = ({ isValid }) => ({
  type: RECEIVE_SALESFORCE_AUTH_VALID,
  payload: { isValid },
});

export const receiveSalesforceConfigs = ({ host }) => ({
  type: RECEIVE_SALESFORCE_CONFIG,
  payload: {
    host,
  },
});

export const receiveSalesforceMultiBrand = ({ brands }) => ({
  type: RECEIVE_SALESFORCE_MULTI_BRAND,
  payload: {
    brands,
  },
});

export const receiveSalesforceAssistMetadata = ({ assistMetadataId }) => ({
  type: RECEIVE_SALESFORCE_ASSIST_METADATA,
  payload: {
    assistMetadataId,
  },
});

export const receiveSalesforceMetadata = ({ metadataId }) => ({
  type: RECEIVE_SALESFORCE_METADATA,
  payload: {
    metadataId,
  },
});

export const updateSalesforceIsMultiBrandEnabled = ({ multiBrandEnabled }) => ({
  type: UPDATE_SALESFORCE_IS_MULTI_BRAND_ENABLED,
  payload: {
    multiBrandEnabled,
  },
});

export const fetchIsSalesforceAuthValid = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { isValid } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_VERIFY({ retailerMoniker }),
    name: 'partnershipsFetchIsSalesforceAuthValid',
  });
  await dispatch(receiveSalesforceAuthValid({ isValid }));
};

export const fetchSalesforceConfigs = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { host } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_CONFIGS({ retailerMoniker }),
    name: 'partnershipsFetchSalesforceConfigs',
  });
  dispatch(receiveSalesforceConfigs({ host }));
};

export const updateSalesforceConfigs = ({ host }) => async (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: {
        id: userId,
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_CONFIGS({ retailerMoniker }),
    name: 'partnershipsUpdateSalesforceConfigs',
    body: JSON.stringify({
      host,
      retailerId: currentRetailerId,
      userId,
    }),
  });
  dispatch(fetchSalesforceConfigs());
};

export const updateSalesforceMultiBrand = ({
  multiBrandMap,
  assistEnabled,
}) => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_MULTIBRAND({ retailerMoniker }),
    name: 'partnershipsUpdateSalesforceMultiBrands',
    body: JSON.stringify({
      multiBrandMap,
      assistEnabled,
    }),
  });
  dispatch(fetchSalesforceMultiBrand());
};

export const fetchSalesforceMultiBrand = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { brands } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_MULTIBRAND({ retailerMoniker }),
    name: 'partnershipsFetchSalesforceBrands',
  });
  dispatch(receiveSalesforceMultiBrand({ brands }));
};

export const updateSalesforceAssistMetadata = ({
  multiBrandEnabled,
  orderNumberField,
  emailField,
  brandField,
}) => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_ASSIST_METADATA({
      retailerMoniker,
    }),
    name: 'partnershipsUpdateSalesforceAssistMetadata',
    body: JSON.stringify({
      orderNumberField,
      emailField,
      brandField,
      multiBrandEnabled,
    }),
  });
  dispatch(fetchSalesforceAssistMetadata());
};

export const fetchSalesforceAssistMetadata = () => async (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { id: assistMetadataId } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_ASSIST_METADATA({
      retailerMoniker,
    }),
    name: 'partnershipsFetchSalesforceAssistMetadata',
  });
  dispatch(receiveSalesforceAssistMetadata({ assistMetadataId }));
};
export const updateSalesforceMetadata = ({
  assistEnabled,
  multiBrandEnabled,
}) => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_METADATA({
      retailerMoniker,
    }),
    name: 'partnershipsUpdateSalesforceMetadata',
    body: JSON.stringify({
      assistEnabled,
      multiBrandEnabled,
    }),
  });
  dispatch(fetchSalesforceMetadata());
};

export const fetchSalesforceMetadata = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { id: metadataId } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_SALESFORCE_METADATA({
      retailerMoniker,
    }),
    name: 'partnershipsFetchSalesforceMetadata',
  });
  dispatch(receiveSalesforceMetadata({ metadataId }));
};
