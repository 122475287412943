import i18n from '../../../i18n';
import { RULE_TYPES } from '../../returns/returnRules';

export const RULE_TITLES = {
  [RULE_TYPES.RETURN_ELIGIBILITY]: i18n.t(
    'returnRules:eligibility_container:container_title',
  ),
  [RULE_TYPES.RETURN_FEES]: i18n.t(
    'returnRules:fee_rule_container:container_title',
  ),
  [RULE_TYPES.RETURN_METHODS]: i18n.t(
    'returnRules:return_method_rule_container:container_title',
  ),
  [RULE_TYPES.RETURN_REASONS]: i18n.t(
    'returnRules:return_reasons_container:container_title',
  ),
  [RULE_TYPES.RETURN_REFUND_OPTIONS]: i18n.t(
    'returnRules:return_refund_options_container:container_title',
  ),
  [RULE_TYPES.RETURN_DESTINATION_POLICY]: i18n.t(
    'returnRules:return_dest_policy_container:container_title',
  ),
  [RULE_TYPES.RETURN_METHODS_POLICY]: i18n.t(
    'returnRules:return_options_container:container_title',
  ),
};
