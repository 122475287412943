import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const RECEIVE_TENANT_ERROR = 'RECEIVE_TENANT_ERROR';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const RECEIVE_TENANT = 'RECEIVE_TENANT';
export const RECEIVE_TENANT_ENTITLEMENTS = 'RECEIVE_TENANT_ENTITLEMENTS';
export const RECEIVE_PARENT_TENANT = 'RECEIVE_PARENT_TENANT';
export const RECEIVE_TENANT_UPDATE = 'RECEIVE_TENANT_UPDATE';
export const RECEIVE_TENANT_UPDATE_STATUS = 'RECEIVE_TENANT_UPDATE_STATUS';
export const RECEIVE_COMPANY_VIEW_DATA = 'RECEIVE_COMPANY_VIEW_DATA';
export const RECEIVE_RESET_API_KEY = 'RECEIVE_RESET_API_KEY';
export const RECEIVE_API_KEY = 'RECEIVE_API_KEY';
export const RECEIVE_COMPANY_ERROR = 'RECEIVE_COMPANY_ERROR';
export const RESET_TENANT_STATE = 'RESET_TENANT_STATE';
export const RECEIVE_HMAC_TOKEN_ERROR = 'RECEIVE_HMAC_TOKEN_ERROR';
export const RECEIVE_HAS_HMAC_TOKEN = 'RECEIVE_HAS_HMAC_TOKEN';
export const RECEIVE_SET_HMAC_TOKEN = 'RECEIVE_SET_HMAC_TOKEN';
export const RECEIVE_TIER_1_SETUP_SUCCESS = 'RECEIVE_TIER_1_SETUP_SUCCESS';
export const RECEIVE_TIER_1_SETUP_ERROR = 'RECEIVE_TIER_1_SETUP_ERROR';
export const MESSAGING_ON_BOARDING_JOB_SUBMITTED =
  'MESSAGING_ON_BOARDING_JOB_SUBMITTED';

export const dismissAlert = () => ({ type: DISMISS_ALERT });

export const resetTenantState = () => ({ type: RESET_TENANT_STATE });

export const receiveCompanyError = () => ({ type: RECEIVE_COMPANY_ERROR });

export const receiveHmacTokenError = error => ({
  type: RECEIVE_HMAC_TOKEN_ERROR,
  meta: { error },
});

export const receiveTenant = tenant => ({
  type: RECEIVE_TENANT,
  payload: { tenant },
});

export const receiveTenantEntitlements = entitlements => ({
  type: RECEIVE_TENANT_ENTITLEMENTS,
  payload: { entitlements },
});

export const receiveParentTenant = parentTenant => ({
  type: RECEIVE_PARENT_TENANT,
  payload: parentTenant,
});

export const fetchParentTenant = tenantId => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TENANTS}/${tenantId}`,
    name: 'fetchParentTenant',
  })
    .then(json => dispatch(receiveParentTenant(json)))
    .catch(err => console.error(err));
};

export const fetchTenantViewData = tenantId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TENANTS}/${tenantId}`,
    name: 'fetchTenantViewData',
  })
    .then(json => {
      dispatch(receiveTenant(json));
      if (json.parent_tenant_id) {
        dispatch(fetchParentTenant(json.parent_tenant_id));
      }
    })
    .catch(err => console.error(err));

export const fetchTenantEntitlements = tenantId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TENANT_ENTITLEMENTS(tenantId),
    name: 'fetchTenantEntitlements',
  })
    .then(json => {
      dispatch(receiveTenantEntitlements(json));
    })
    .catch(err => console.error(err));

// Activate/Deactivate Tenant
export const receiveTenantUpdateStatus = tenant => ({
  type: RECEIVE_TENANT_UPDATE_STATUS,
  tenant,
});

export const fetchTenantUpdateStatus = (tenantId, tenantStatus) => dispatch => {
  const body = JSON.stringify({
    tenantId,
  });
  const query = {
    enabled: !tenantStatus,
  };

  return dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.TENANTS}/${tenantId}/status`,
    query,
    body,
    name: 'fetchTenantUpdateStatus',
  })
    .then(json => {
      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.TENANTS,
      });

      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.TENANTS}/${tenantId}`,
      });

      dispatch(receiveTenantUpdateStatus(json));
    })
    .catch(() =>
      dispatch({
        type: RECEIVE_TENANT_ERROR,
        meta: { error: 'Sorry there was a problem updating this tenant.' },
      }),
    );
};

export const receiveTenantUpdate = tenant => ({
  type: RECEIVE_TENANT_UPDATE,
  payload: { tenant },
});

// Update Tenant Settings
export const fetchTenantUpdate = (tenantId, tenant) => dispatch => {
  const body = JSON.stringify({
    tenant,
  });

  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.TENANTS,
  });

  return dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.TENANTS}/${tenantId}`,
    body,
    name: 'fetchTenantUpdate',
  })
    .then(json => {
      dispatch(receiveTenantUpdate(json));
    })
    .catch(err => {
      let { message } = err.errors[0];
      if (err.errors[0].field) {
        message += ` for ${err.errors[0].field}`;
      }

      dispatch({
        type: RECEIVE_TENANT_ERROR,
        meta: { error: message },
      });
    });
};

export const receiveCompanyViewData = (payload, company) => ({
  type: RECEIVE_COMPANY_VIEW_DATA,
  payload,
  meta: { company },
});

export const fetchCompanyViewData = company => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.COMPANY}`,
    name: 'fetchCompanyViewData',
  }).then(
    json => dispatch(receiveCompanyViewData(json.account_key, company)),
    err => {
      console.error(err);
      dispatch(receiveCompanyError());
    },
  );
};

export const receiveResetApiKey = (tokenId, company) => ({
  type: RECEIVE_RESET_API_KEY,
  meta: { tokenId, company },
});

export const fetchResetApiKey = (tokenId, company) => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.COMPANY}/access-tokens/${tokenId}/reset`,
    name: 'fetchResetApiKey',
  }).then(
    () => {
      dispatch(receiveResetApiKey(tokenId, company));
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.COMPANY}/access-tokens/${tokenId}`,
      });
    },
    err => {
      console.error(err);
      dispatch(receiveCompanyError());
    },
  );
};

export const receiveApiKey = (payload, company) => ({
  type: RECEIVE_API_KEY,
  meta: { company },
  payload,
});

export const fetchApiKey = (tokenId, company) => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.COMPANY}/access-tokens/${tokenId}`,
    name: 'fetchApiKey',
  }).then(
    json => dispatch(receiveApiKey(json, company)),
    err => {
      console.error(err);
      dispatch(receiveCompanyError());
    },
  );
};

export const fetchDeleteRetailerInContentApi = retailerMoniker => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.CARE_RETAILER_SETTINGS}/${retailerMoniker}`,
    name: 'fetchDeleteRetailerInContentApi',
  }).then(
    () => {},
    err => {
      console.error(err);
      dispatch({
        type: RECEIVE_TENANT_ERROR,
        meta: {
          error:
            'Sorry there was a problem updating this tenant in content-api.',
        },
      });
    },
  );
};

export const fetchCreateRetailerInContentApi = retailerMoniker => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${ENDPOINTS.CARE_RETAILER_SETTINGS}/${retailerMoniker}`,
    name: 'fetchCreateRetailerInContentApi',
  }).then(
    () => {},
    err => {
      console.error(err);
      dispatch({
        type: RECEIVE_TENANT_ERROR,
        meta: {
          error:
            'Sorry there was a problem updating this tenant in content-api.',
        },
      });
    },
  );
};

export const receiveHasHmacToken = (payload, company) => ({
  type: RECEIVE_HAS_HMAC_TOKEN,
  meta: { company },
  payload,
});

export const receiveSetHmacToken = (payload, company) => ({
  type: RECEIVE_SET_HMAC_TOKEN,
  meta: { company },
  payload,
});

export const fetchHasHmacToken = company => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.COMPANY}/tenant-tokens/hmac-token`,
    name: 'fetchHasHmacToken',
  }).then(
    json => dispatch(receiveHasHmacToken(json, company)),
    err => {
      console.error(err);
      dispatch(
        receiveHmacTokenError(
          'Unable to check your HMAC Token at this time. Please try again later.',
        ),
      );
    },
  );
};

export const fetchSetHmacToken = (hmacToken, company) => dispatch => {
  const body = JSON.stringify({
    token: hmacToken,
  });
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.COMPANY}/tenant-tokens/hmac-token`,
    body,
    name: 'fetchSetHmacToken',
    reqBodyRedactor: reqBody => {
      const redactedBody = JSON.parse(reqBody);
      delete redactedBody.token;
      return JSON.stringify(redactedBody);
    },
  }).then(
    json => dispatch(receiveSetHmacToken(json, company)),
    err => {
      console.error(err);
      dispatch(
        receiveHmacTokenError(
          'Unable get your HMAC Token at this time. Please try again later.',
        ),
      );
    },
  );
};

export const messagingOnBoardingJobSubmitted = jobId => ({
  type: MESSAGING_ON_BOARDING_JOB_SUBMITTED,
  payload: { jobId },
});
