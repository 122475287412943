import * as PropTypes from 'prop-types';
import * as React from 'react';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Svg from '@narvar/hum-svg';
import AbsoluteLink from './AbsoluteLink';
import HubLink from './HubLink';
import NeohubLink from './NeohubLink';

const getLinkComponent = (isAbsolute, isNeohubLink) => {
  if (isAbsolute) return AbsoluteLink;
  if (isNeohubLink) return NeohubLink;
  return HubLink;
};

const NavigationDropdown = ({
  title,
  link,
  icon,
  iconRight,
  items,
  onClick,
  isNeohub,
  isAbsolute,
  isNeohubLink,
  subMenuPrefix = '',
}) => {
  const [open, setOpen] = React.useState(false);

  const isSubmenu = !!subMenuPrefix;
  const titlePreH = subMenuPrefix ? `${subMenuPrefix}-` : '';
  const hyphenatedSectionTitle = `${titlePreH}${title.replace(' ', '-')}`;

  const boxCss = {};
  if (open) {
    boxCss.backgroundColor = 'rgba(0, 0, 0, 0.08)';
    if (isSubmenu) {
      boxCss.paddingBottom = '4px';
    }
  }
  return (
    <Box mb={isSubmenu ? 0 : 2} style={boxCss}>
      {link !== undefined ? (
        <ListItem
          component={getLinkComponent(isAbsolute, isNeohubLink)}
          to={link}
          isNeohub={isNeohub}
          button
          onClick={onClick}
          data-test={`${hyphenatedSectionTitle}-section`}
          style={{
            color: 'black',
          }}
        >
          <ListItemIcon style={{ opacity: open ? 1 : 0.4, minWidth: '2rem' }}>
            {icon && (
              <Svg
                name={icon}
                s={{ width: '20px', height: '20px' }}
                className="product-icon"
              />
            )}
          </ListItemIcon>
          <ListItemText
            style={{ flex: iconRight ? '0 1 auto' : '1 1 auto' }}
            primary={title}
            primaryTypographyProps={{
              variant: 'button',
              style: {
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 'bold',
              },
            }}
          />
          {iconRight && (
            <ListItemIcon style={{ minWidth: '2rem', padding: '0 6px' }}>
              <Svg
                name={iconRight}
                s={{ width: '32px', height: '15px' }}
                className="nav-icon"
              />
            </ListItemIcon>
          )}
        </ListItem>
      ) : (
        // The selected attribute is no more applied as it was causing issues with colors.
        // This data-selected attribute is only added for the sake of testing.
        <ListItem
          button
          data-selected={open}
          onClick={() => setOpen(!open)}
          data-test={`${hyphenatedSectionTitle}-section`}
        >
          <ListItemIcon style={{ opacity: open ? 1 : 0.4, minWidth: '2rem' }}>
            {icon && (
              <Svg
                name={icon}
                s={{ width: '20px', height: '20px' }}
                className="product-icon"
              />
            )}
          </ListItemIcon>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              variant: 'button',
              style: {
                textTransform: 'none',
                fontSize: '14px',
                fontWeight: 'bold',
              },
            }}
          />
          {iconRight && (
            <ListItemIcon style={{ minWidth: '2rem', padding: '0 6px' }}>
              <Svg
                name={iconRight}
                s={{ width: '32px', height: '15px' }}
                className="nav-icon"
              />
            </ListItemIcon>
          )}
          {open ? <ArrowDropDownIcon /> : <ArrowRightIcon color="disabled" />}
        </ListItem>
      )}
      {link === undefined && (
        <Collapse
          style={{ paddingLeft: isSubmenu ? 20 : 0 }}
          in={open}
          timeout="auto"
        >
          <List component="div" dense disablePadding>
            {items.map(item => {
              const {
                title: linkText,
                isAbsolute: itemIsAbsolute,
                isNeohubLink: itemIsNeohubLink,
                link: itemLink,
                iconRight: itemIcon,
                items: subitems = [],
              } = item;
              if (subitems.length) {
                return (
                  <li>
                    <NavigationDropdown
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...item}
                      subMenuPrefix={hyphenatedSectionTitle}
                    />
                  </li>
                );
              }
              const hyphenatedItemTitle = linkText.replace(' ', '-');
              const dataTest = `${hyphenatedSectionTitle}-${hyphenatedItemTitle}-nav`;
              const Component = getLinkComponent(
                itemIsAbsolute,
                itemIsNeohubLink,
              );
              // The selected attribute is no more applied as it was causing issues with colors.
              // This data-selected attribute is only added for the sake of testing.
              return (
                <ListItem data-selected key={linkText}>
                  <Component
                    to={itemLink}
                    onClick={onClick}
                    style={{
                      marginLeft: '2rem',
                      color: 'black',
                      fontWeight: '400',
                      fontSize: '14px',
                    }}
                    data-test={dataTest}
                    isNeohub={isNeohub}
                  >
                    {linkText}
                  </Component>
                  {itemIcon && (
                    <ListItemIcon
                      style={{ minWidth: '2rem', padding: '0 6px' }}
                    >
                      <Svg
                        name={itemIcon}
                        s={{ width: '32px', height: '15px' }}
                        className="nav-icon"
                      />
                    </ListItemIcon>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      )}
    </Box>
  );
};

NavigationDropdown.propTypes = {
  subMenuPrefix: PropTypes.string,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  iconRight: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      isAbsolute: PropTypes.bool,
      link: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      props: PropTypes.objectOf(PropTypes.any),
      isNeohubLink: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  onClick: PropTypes.func,
  isNeohub: PropTypes.bool.isRequired,
  link: PropTypes.string,
  isAbsolute: PropTypes.bool,
  isNeohubLink: PropTypes.bool,
};

NavigationDropdown.defaultProps = {
  subMenuPrefix: '',
  iconRight: undefined,
  onClick: undefined,
  link: undefined,
  isAbsolute: undefined,
  isNeohubLink: undefined,
};

export default NavigationDropdown;
