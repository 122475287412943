import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const neoHubDomain = (to, isNeohub) => {
  if (process.env.NODE_ENV === 'test') {
    return to;
  }

  if (isNeohub) {
    return to;
  }
  let neohubHost = window.location.origin;
  if (!neohubHost.includes('hubn')) {
    neohubHost = neohubHost.replace('hub', 'hubn');
  }
  return `${neohubHost}${to}`;
};

const NeohubLink = ({ to, children, isNeohub, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link href={neoHubDomain(to, isNeohub)} {...props}>
    {children}
  </Link>
);

NeohubLink.propTypes = {
  to: PropTypes.string.isRequired,
  isNeohub: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default NeohubLink;
