import moment from 'moment';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';

const DATE_FILTER_REGEX = /\d{4}-\d{2}-\d{2}/g;

/*
  params:
    dateFilter: string, e.g. "order_datetime" ge 2018-03-19 and "order_datetime" lt 2018-04-18"
*/
const parseDateFilter = dateFilter => {
  const dateRange = dateFilter.match(DATE_FILTER_REGEX);
  const dateFilterFieldRegEx = /"(.*?)"/;
  const dateFilterField = dateFilter.match(dateFilterFieldRegEx)[1];
  const startDate = moment(dateRange[0]);
  const endDate = moment(dateRange[1]);
  return { startDate, endDate, dateFilterField };
};

// same as parseDateFilter, but returns an inclusive end date (subtracts 1 day from end date)
const parseDateFilterToInclusiveEnd = dateFilter => {
  const { startDate, endDate, dateFilterField } = parseDateFilter(dateFilter);
  return {
    startDate,
    endDate: endDate.subtract(1, 'day'),
    dateFilterField,
  };
};

const buildDateFilter = ({ startDate, endDate, dateFilterField }) =>
  `"${dateFilterField}" ge ${moment(startDate).format(
    API_DATE_FORMAT,
  )} and "${dateFilterField}" lt ${moment(endDate).format(API_DATE_FORMAT)}`;

export { parseDateFilter, parseDateFilterToInclusiveEnd, buildDateFilter };
