// See https://github.com/narvar/noflake-schema/blob/master/schemas/hub/hub_requests.json for event schema
const noflake = noflakeUrl => {
  const logEvent = event => {
    if (typeof window === 'undefined') {
      return;
    }

    const dateTime = new Date().toISOString();
    const body = {
      ...event,
      tag: 'noflake.hub_requests',
      event_ts: dateTime,
      ingestion_timestamp: dateTime,
      client_location_href: window.location.href,
      client_agent: window.navigator.userAgent,
      client_language: window.navigator.language,
      client_timezone_offset: new Date().getTimezoneOffset().toString(),
    };

    fetch(noflakeUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.error('Error sending data to Noflake.', e);
    });
  };

  return logEvent;
};

export default noflake;
