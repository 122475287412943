import React from 'react';
import * as ENDPOINTS from '../../constants/endpoints';
import { API_INVALIDATE_PATH, API_REQUEST } from '../../lib/api';

export const RECEIVE_HOLIDAYS = 'PLATFORM/RECEIVE_HOLIDAYS';
export const RECEIVE_POST_HOLIDAYS_CSV = 'PLATFORM/RECEIVE_POST_HOLIDAYS_CSV';
export const SHOW_ALERT = 'PLATFORM/SHOW_ALERT';

export const showAlert = (color, text) => dispatch =>
  dispatch({
    type: SHOW_ALERT,
    payload: {
      color,
      text,
    },
  });

export const fetchHolidays = (page = 0) => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PLATFORM_HOLIDAYS,
    name: 'fetchHolidays',
    query: {
      current_page_index: page,
      records_per_page: 25,
    },
  }).then(
    json => {
      dispatch({
        type: RECEIVE_HOLIDAYS,
        payload: json,
      });
    },
    err => {
      console.error(err);
      dispatch({
        type: SHOW_ALERT,
        payload: {
          color: 'danger',
          text: 'Sorry, there was an error retrieving your holidays.',
        },
      });
    },
  );

export const fetchHolidaysCsv = () => () =>
  window.open(ENDPOINTS.PLATFORM_HOLIDAYS_CSV);

export const postHolidaysCsv = csv => dispatch =>
  dispatch({
    type: API_REQUEST,
    contentType: 'text/plain',
    method: 'POST',
    path: ENDPOINTS.PLATFORM_HOLIDAYS_CSV,
    name: 'postHolidaysCsv',
    body: csv,
  }).then(
    json => {
      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.PLATFORM_HOLIDAYS,
      });
      dispatch({ type: RECEIVE_HOLIDAYS, payload: json });
      dispatch({
        type: SHOW_ALERT,
        payload: {
          color: 'success',
          text: 'Your holidays CSV was uploaded successfully.',
        },
      });
    },
    err => {
      dispatch(
        showAlert(
          'danger',
          <div>
            Sorry there was an error posting your CSV. Please click{' '}
            <a href={`data:text/csv;charset=utf-8, ${err.errors}`}>here</a> to
            download your CSV with error warnings.
          </div>,
        ),
      );
    },
  );
