import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  background-color: ${colors.grayLight};
  border-radius: 2px;
  cursor: pointer;
  height: 40px;
  line-height: 20px;
  border: 1px solid ${colors.gray};
  padding: 10px 30px 10px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  color: ${colors.gray}-dark;

  &:hover {
    color: #212121;

    & .close {

      & path {
        fill-opacity: 1;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
    color: $text-muted;

    &:hover {
      background-color: transparent;
    }
  }

  & .close {
    position: absolute;
    right: 10px;
    transform: scale(0.5);
    margin-left: 5px;

    & path {
      fill: ${colors.black};
      fill-opacity: 0.4;
      transition: fill-opacity 200ms;
    }

  }

@media print {
  .Tag {
    .close {
      display: none;
    }
  }
}`;

export default s;
