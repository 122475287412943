import cloneDeep from 'lodash.clonedeep';
import { LOCATION_CHANGE } from 'connected-react-router';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';
import {
  RECEIVE_TEMPLATE_LIST,
  RECEIVE_TEMPLATE,
  RECEIVE_SUPPORTED_TYPES,
  RECEIVE_SUPPORTED_LOCALES,
  SUCCESS,
  ERROR,
  TEST_EMAIL_SUCCESS,
  CANNOT_PUBLISH,
} from '~src/actions/emailTemplatesActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  templateList: undefined,
  template: {},
  supportedTypes: undefined,
  supportedLocales: undefined,
  alert: {
    show: false,
  },
  canPublish: false,
};

const emailTemplates = (state = cloneDeep(initialState), action) => {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_CSM_LOGOUT:
      return cloneDeep(initialState);
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: cloneDeep(initialState.alert),
        canPublish: false,
        template: cloneDeep(initialState.template),
      };
    case RECEIVE_TEMPLATE_LIST:
      return {
        ...state,
        templateList: payload.templates,
      };
    case RECEIVE_TEMPLATE:
      return {
        ...state,
        template: payload.template,
      };
    case RECEIVE_SUPPORTED_TYPES:
      return {
        ...state,
        supportedTypes: payload.supportedTypes,
      };
    case RECEIVE_SUPPORTED_LOCALES:
      return {
        ...state,
        supportedLocales: payload.supportedLocales,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: cloneDeep(initialState.alert),
      };
    case SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: payload.message,
        },
      };
    case TEST_EMAIL_SUCCESS:
      return {
        ...state,
        canPublish: true,
        alert: {
          color: 'success',
          show: true,
          text: payload.message,
        },
      };
    case ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload.message,
        },
      };
    case CANNOT_PUBLISH:
      return {
        ...state,
        canPublish: false,
      };
    default:
      return state;
  }
};

export default emailTemplates;
