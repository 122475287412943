// Hub-Home-Page-Analytics-Component [CPMT-1443]
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { fetchMetabaseURL } from '../../actions/metabaseAction';

const mapStateToProps = ({ metabaseReducer: { urlMap } }) => ({
  urlMap,
});

const Analytics = ({ dashboardType, urlMap, height }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMetabaseURL(dashboardType));
  }, [dashboardType, dispatch]);

  const minHeight = height || '350px';

  return (
    <iframe
      title={`Metabase-${dashboardType}`}
      src={urlMap[dashboardType]}
      style={{ width: '100%', minHeight, borderWidth: '0' }}
    />
  );
};

Analytics.propTypes = {
  dashboardType: PropTypes.string.isRequired,
  height: PropTypes.string,
  urlMap: PropTypes.shape({
    consumerPreferences: PropTypes.string.isRequired,
    peakPerformance: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps)(React.memo(Analytics));
