import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  & .star,
  & .star-empty,
  & .star-half {
    transform: scale(0.42);
    margin-left: -10px;
  }

  & .star polygon {
    fill: ${colors.blue};
  }
`;

export default s;
