import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const subtle = css`
  display: flex;
  overflow: hidden;
  padding-right: 20px;
  position: relative;
  width: 100%;

  & span {
    color: $text-muted;
  }

  & strong {
    color: ${colors.dark2};
  }

  & .input-container {
    border-bottom: 1px solid ${colors.gray};
    margin: 0 0 10px auto;
    opacity: 0;
    transform: translate(200px, 0);
    transition: opacity 100ms, transform 200ms;

    &.is-active {
      transform: translate(0, 0);
      opacity: 1;
    }

  }

  & .icon-container {
    cursor: pointer;
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
  }

  & input {
    background: transparent;
    border: 0;
    padding-left: 10px;
    padding-bottom: 4px;
    width: 225px;

    &.focus {
      outline: none;
    }
  }

  & .close {
    position: absolute;
    right: 25px;
    top: 6px;
    transform: scale(0.6);

    & path {
      color: ${colors.dark1};
      fill-opacity:  0.4;
      transition: fill-opacity 200ms;
    }

    &:hover {
      & path {
        fill-opacity: 1;
      }
    }
  }

  & .icon-search,
  .icon-muted {
    position: absolute;
    top: 8px;
    right: 25px;

    & path {
      fill-opacity:  1;
      transition: fill-opacity 200ms;
    }

    &:hover {
      & path {
        fill-opacity: 1;
      }
    }
  }
  & .icon-muted {
    & path {
      fill-opacity: ${colors.iconMute};
    }
  } 
`;

const standard = css`
  display: table;
  width: 100%;
  height: 40px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 2px;
  padding: 0 5px;

  &:hover {
    border-color: ${colors.dark4}
  }

  .error {
    border-color: ${colors.red}
  }

  & .input-container,
  & .icon-container {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;
  }

  & .input-container {
    width: 100%;
    & input {
      width: 100%;
      background: transparent;
      border: 0;

      &.focus {
        outline: none;
      }
    }
  }

  & .icon-container {
    cursor: pointer;
    text-align: center;

    & .close {
      transform: scale(0.6);
    }

    & svg {
      vertical-align: middle;

      &.icon-search {
        & path {
          fill-opacity: 1;
        }
      }

      & path {
        fill-opacity: ${colors.iconMute};
        transition: fill-opacity 200ms;
      }
    }

    &:hover {
      & svg {
        & path {
          fill-opacity: 1;
        }
      }
    }
  }
`;

export {
  subtle,
  standard,
};
