import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import { push } from 'connected-react-router';
import {
  downloadPortableConfig,
  uploadPortableConfig,
  confirmUploadPortableConfig,
  isManifestHasValidModules,
} from '~src/actions/portableConfigActions';

export const RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE =
  'RETURN_REASONS/RECEIVE_UPLOAD_RESPONSE';
export const RESET_UPLOADED_PORTABLE_CONFIG =
  'RETURN_REASONS/RESET_UPLOADED_CONFIG';
export const RECEIVE_RETURN_REASON_SETS = 'RECEIVE_RETURN_REASON_SETS';
export const RECEIVE_UPDATED_RETURN_REASONS_SET =
  'RECEIVE_UPDATED_RETURN_REASONS_SET';
export const RECEIVE_ADD_RETURN_REASONS_SET = 'RECEIVE_ADD_RETURN_REASONS_SET';
export const RECEIVE_RETURN_REASONS_ERROR = 'RECEIVE_RETURN_REASONS_ERROR';
export const RECEIVE_RETURN_REASONS_SUCCESS = 'RECEIVE_RETURN_REASONS_SUCCESS';
export const RESET_RETURN_REASONS_FORM_AND_ERRORS =
  'RESET_RETURN_REASONS_FORM_AND_ERRORS';
export const RECEIVE_RETURN_REASONS_LOCALES = 'RECEIVE_RETURN_REASONS_LOCALES';

const receiveReasonSets = payload => ({
  type: RECEIVE_RETURN_REASON_SETS,
  payload,
});

const receiveUpdatedReasonSet = payload => ({
  type: RECEIVE_UPDATED_RETURN_REASONS_SET,
  payload,
});

const receiveAddReasonSet = payload => ({
  type: RECEIVE_ADD_RETURN_REASONS_SET,
  payload,
});

const receiveErrors = payload => ({
  type: RECEIVE_RETURN_REASONS_ERROR,
  payload,
});

const receiveSuccess = payload => ({
  type: RECEIVE_RETURN_REASONS_SUCCESS,
  payload,
});

export const resetFormAndErrors = () => ({
  type: RESET_RETURN_REASONS_FORM_AND_ERRORS,
});

export const fetchReturnReasonLocales = () => async dispatch => {
  try {
    const { response, status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.RETURN_REASON_SETS_CONFIGS}`,
      name: 'fetchReturnReasonLocales',
    });

    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.reduce(
        (finalStr, { message }) => `${finalStr} ${message}`,
        '',
      );
      return dispatch(receiveErrors(errorMsgs));
    }
    return dispatch({
      type: RECEIVE_RETURN_REASONS_LOCALES,
      payload: {
        locales: response.supported_locales,
        categories: response.product_categories,
      },
    });
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const fetchReasonSets = () => async dispatch => {
  try {
    const { response, status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
      name: 'fetchReasonSets',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
    });
    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.map(({ message }) => message).join(' ');
      return dispatch(receiveErrors(errorMsgs));
    }
    return dispatch(receiveReasonSets(response.reason_sets));
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const fetchReasonSet = ({
  locale,
  name,
  productCategory,
}) => async dispatch => {
  try {
    const { response, status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
      name: 'fetchReasonSet',
      query: {
        locale,
        name,
        product_category: productCategory,
      },
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
    });
    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.map(({ message }) => message).join(' ');
      return dispatch(receiveErrors(errorMsgs));
    }
    return dispatch(receiveReasonSets(response.reason_sets));
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const fetchAddReasonSet = data => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
    });
    const { response, status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
      name: 'fetchAddReasonSet',
      body: JSON.stringify(data),
    });
    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.reduce(
        (finalStr, { message }) => `${finalStr} ${message}`,
        '',
      );
      return dispatch(receiveErrors(errorMsgs));
    }
    dispatch(receiveSuccess('Added new return reasons successfully.'));
    return dispatch(receiveAddReasonSet(response.reason_set));
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const fetchUpdateReasonSet = data => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
    });
    const { response, status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
      name: 'fetchUpdateReasonSet',
      body: JSON.stringify(data),
    });
    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.reduce(
        (finalStr, { message }) => `${finalStr} ${message}`,
        '',
      );
      return dispatch(receiveErrors(errorMsgs));
    }
    dispatch(receiveSuccess('Updated return reasons successfully.'));
    return dispatch(receiveUpdatedReasonSet(response.reason_set));
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const fetchDeleteReasonSet = data => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
    });
    const { status, errors } = await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path: `${ENDPOINTS.RETURN_REASON_SETS}`,
      name: 'fetchDeleteReasonSet',
      body: JSON.stringify(data),
    });
    if (status.toLowerCase() === 'error') {
      const errorMsgs = errors.reduce(
        (finalStr, { message }) => `${finalStr} ${message}`,
        '',
      );
      return dispatch(receiveErrors(errorMsgs));
    }
    dispatch(receiveSuccess('Deleted return reasons successfully.'));
    return dispatch(push('/return/return-reasons'));
  } catch (err) {
    return dispatch(receiveErrors(err.message));
  }
};

export const downloadReturnReasonsPortableConfig = () => async dispatch => {
  try {
    await dispatch(
      downloadPortableConfig({
        appName: 'returns',
        module: { reason_codes: true },
      }),
    );
    dispatch(receiveSuccess('Successfully Download'));
  } catch (error) {
    dispatch(receiveErrors('Download Fail'));
  }
};

export const uploadReturnReasonsPortableConfig = ({
  selectedFile,
}) => async dispatch => {
  try {
    const {
      data: manifest,
      data: { module },
    } = await dispatch(
      uploadPortableConfig({
        selectedFile,
      }),
    );

    const isUploadedConfigValid = isManifestHasValidModules({
      manifestModule: module,
      validModules: ['reason_codes'],
    });

    if (isUploadedConfigValid) {
      dispatch({
        type: RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
        payload: manifest,
      });
    } else {
      dispatch(
        receiveErrors(
          'Please only upload Return Reaons Configuration on this page',
        ),
      );
    }
  } catch (error) {
    dispatch(receiveErrors('Upload Fail'));
  }
};

export const resetUploadedPortableConfig = () => dispatch => {
  dispatch({
    type: RESET_UPLOADED_PORTABLE_CONFIG,
  });
};

export const confirmReturnReasonsPortableConfig = ({
  manifest,
}) => async dispatch => {
  try {
    await dispatch(
      confirmUploadPortableConfig({
        manifest: {
          ...manifest,
          module: {
            reason_codes: true,
          },
        },
      }),
    );
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch(receiveSuccess('Successfully Uploaded'));
    dispatch(fetchReasonSets());
  } catch (error) {
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch(receiveErrors('Upload Fail'));
  }
};
