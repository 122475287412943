import { useEffect } from 'react';
import i18n from '../i18n';

const useInitialiseChameleonInfo = (user, profile) =>
  useEffect(() => {
    if (!window.chmln || !user || !profile) return;

    const {
      id,
      current_retailer_id,
      customerSupportLogin,
      email,
      retailerIdToRetailerInfo,
      userRetailerAssociationList,
    } = user;
    const { department, how_to_use_hub, level, user_id } = profile;

    if (
      !id ||
      !current_retailer_id ||
      !retailerIdToRetailerInfo ||
      !(current_retailer_id in retailerIdToRetailerInfo) ||
      !(customerSupportLogin || user_id)
    )
      return;

    const retailerMoniker =
      retailerIdToRetailerInfo[current_retailer_id].uri_moniker;

    window.chmln.identify(email, {
      company: {
        uid: current_retailer_id,
        isHipaa: retailerIdToRetailerInfo[current_retailer_id].hipaa_compliance,
        locales: retailerIdToRetailerInfo[current_retailer_id].locales,
        products: retailerIdToRetailerInfo[current_retailer_id].products,
        retailerMoniker,
      },
      department: department?.option ?? null,
      email,
      howToUseHub: how_to_use_hub.map(h => h.option),
      hubId: id.toString(),
      hubLanguage: i18n.language,
      isCsm: customerSupportLogin ?? false,
      level: level?.option ?? null,
      retailerMoniker,
      role: userRetailerAssociationList.find(
        r => r.retailerId === current_retailer_id,
      ).roles[0].name,
    });

    window.chmln.on('app:navigate', opts => {
      return window.history.pushState(null, '', opts.to);
    });
  }, [user, profile]);

export default useInitialiseChameleonInfo;
