import objectPath from 'object-path';
import { RULE_MONIKERS } from '../../../../lib/returns/rulesConfig';

export const isVersion = props => ({
  isV1: Object.keys(props).includes('actions'),
  isV2: Object.keys(props).includes('result'),
});

export const v1RulesCount = ({ rules = [] }) =>
  rules.map(rule => isVersion(rule)).filter(e => e.isV1).length;

export const getWarning = ({ modes, ruleMoniker, props }) => {
  const { isV1 } = isVersion(props);
  if (ruleMoniker === RULE_MONIKERS.RETURN_FEES && modes.feesMode && isV1) {
    const isDefault =
      objectPath.get(props, 'criteria.0.attribute') === 'default_return_fees';
    return `${'This retailer has been upgraded to fee rules v2. ' +
      'This rule will no longer be evaluated. '}${
      isDefault
        ? 'This default rule will be automatically removed once all other v1 rules have been removed. '
        : 'Please recreate this rule using the "Add Rule" button and delete this rule when ready.'
    }`;
  }
  return null;
};
