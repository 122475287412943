import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  & td {
    color: ${colors.dark2};

    & div {
      padding: 0 10px 0 10px;
    }

    &:first-child {
      & div {
        margin-left: 20px;
        padding: 0 10px 0 0px;
      }
    }

    &:last-child {
      & div {
        margin-right: 20px;
        padding: 0 0px 0 10px;
      }
    }

  }

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: ${colors.grayLightest};

      & td {
        color: ${colors.dark1};
      }
    }
    
    &:active {
      background-color: ${colors.blue};

      & td {
        color: ${colors.white};
      }
    }
    
  }

  &:last-child {
    border-bottom-color: transparent;

    & div, td {
      border-bottom-color: transparent;
    }
  }
`;

export default s;
