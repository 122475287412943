import moment from 'moment';
import { uploadAsset } from '~src/actions/returnsSettingsActions';
import { dismissAlertCard } from '~src/actions/uiActions';
import { IMAGE_DEFAULT_ERROR_MSG } from '~src/constants/returns/uiBuilder';

export const UPDATE_BRANDING_SELECTED_LOCALE =
  'UPDATE_BRANDING_SELECTED_LOCALE';
export const UPDATE_BRANDING_FORM = 'UPDATE_BRANDING_FORM';
export const UPDATE_BRANDING_HAS_PREVIEWED = 'UPDATE_BRANDING_HAS_PREVIEWED';
export const RESET_BRANDING_PUBLISH_STATE = 'RESET_BRANDING_PUBLISH_STATE';
export const TOGGLE_BRANDING_PUBLISH_MODAL = 'TOGGLE_BRANDING_PUBLISH_MODAL';
export const RECEIVE_BRANDING_SETTINGS = 'RECEIVE_BRANDING_SETTINGS';
export const RECEIVE_BRANDING_PUBLISH = 'RECEIVE_BRANDING_PUBLISH';
export const FETCHING_BRANDING_SETTINGS = 'FETCHING_BRANDING_SETTINGS';
export const UPDATE_BRANDING_SETTINGS = 'UPDATE_BRANDING_SETTINGS';
export const FETCHING_BRANDING_ERROR = 'FETCHING_BRANDING_ERROR';

export const updateSelectedLocale = selectedLocale => dispatch => {
  dispatch(dismissAlertCard());
  dispatch({
    type: UPDATE_BRANDING_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });
};

export const updateBrandingForm = ({
  form,
  changeDetected,
  hasPreviewed,
}) => dispatch => {
  dispatch(dismissAlertCard());
  dispatch({
    type: UPDATE_BRANDING_FORM,
    payload: {
      form,
      changeDetected,
      hasPreviewed,
    },
  });
};

export const updateHasPreviewed = hasPreviewed => dispatch => {
  dispatch({
    type: UPDATE_BRANDING_HAS_PREVIEWED,
    payload: {
      hasPreviewed,
    },
  });
};

export const fetchingBrandingError = () => dispatch => {
  dispatch({
    type: FETCHING_BRANDING_ERROR,
  });
};

export const resetPublishState = () => dispatch => {
  dispatch({
    type: RESET_BRANDING_PUBLISH_STATE,
  });
};

export const togglePublishModal = () => dispatch => {
  dispatch({
    type: TOGGLE_BRANDING_PUBLISH_MODAL,
  });
};

export const uploadImage = (file, device, field) => async (
  dispatch,
  getState,
) => {
  const {
    returnsBrandingReducer: { form, changeDetected },
  } = getState();
  const { name } = file;
  const fileExtension = name.split('.').pop();
  const timestamp = moment().format('YYYYMMDDHHmmSS');
  const fileName = `RETURNS_${device}_${timestamp}.${fileExtension}`;
  try {
    const imageUrl = await dispatch(
      uploadAsset({
        file,
        fileName,
        type: 'image',
      }),
    );

    const formField = device === 'DESKTOP' ? 'desktopImage' : 'mobileImage';

    dispatch(
      updateBrandingForm({
        form: {
          ...form,
          [formField]: imageUrl,
          errors: {
            ...form.errors,
            [field]: '',
          },
        },
        changeDetected,
      }),
    );
  } catch (error) {
    dispatch(
      updateBrandingForm({
        form: {
          ...form,
          errors: {
            ...form.errors,
            [field]: IMAGE_DEFAULT_ERROR_MSG,
          },
        },
        changeDetected,
      }),
    );
  }
};
