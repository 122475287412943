import React from 'react';
import { MigrationCard } from '../ui';
import { generateMethodKey } from '../../../../../../../../lib/returns/uiBuilder';

export const store = ({ option, returnOptionsTemplate }) => ({
  option,
  newOption: {
    option: {
      id: 'store',
      methods: ['store'],
      displayMethods: false,
    },
    methods: {
      store: {
        ...returnOptionsTemplate.methods[generateMethodKey[option]],
        id: 'store',
        get_address: option.get_address,
        disable_email: option.disable_email,
        payments: option.payments,
      },
    },
  },
  message: (
    <MigrationCard title="Store" validation="valid">
      Store will be migrated.
    </MigrationCard>
  ),
});
