import { colors, fonts } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  & input.form-control,
  & textarea.form-control {
    padding: 10px;
    border-color: ${colors.gray};
    color: ${colors.dark1};
    font-family: ${fonts.regular};

    &.error {
      box-shadow: none;
      border-color: ${colors.red};
    }

    &:disabled {
      box-shadow: none;
      background-color: ${colors.grayLightest};
      color: ${colors.dark4};
      
      &:hover {
        border-color: ${colors.gray};
      }
      
    }
    
    &:hover {
      border-color: ${colors.dark4};
    }
    
    &:focus {
      box-shadow: inset 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
      border-color: ${colors.dark4};
    }
    
    &::-webkit-input-placeholder {
      color: ${colors.dark4};
      font-family: ${fonts.regular};
    }
  
    
  }

  & input.form-control {
    height: 40px;
  }

  & textarea {
    resize: none;
    min-height: 80px;

    &.no-resize {
      resize: none;
    }

    &.code {
      font-family: 'Operator Mono', monospace;
      min-height: 600px;
      width: 100%;
      
      &:disabled {
        background-color: ${colors.grayLightest};
        border-color: ${colors.gray};
        box-shadow: none;
      }
      
      
   }
  }

  ::-webkit-input-placeholder {
    color: ${colors.dark4};
  }

  & .label {
    font-family: ${fonts.bold};
    display: inline-block;
    margin-bottom: 10px;
    min-width: 150px;
    width: 100%;
  }

  & .form-group {
    margin-bottom: 20px;
  }

  & .hint-msg {
    margin-top: 4px;
    margin-bottom: 0;
  }

  & .error-msg {
    color: ${colors.red};
    margin-top: 4px;
  }

  & hr {
  margin: 20px 0;
}

& input[type='checkbox'] { 
  ${'' /* checkbox styles adopted from www.inserthtml.com/2012/06/custom-form-radio-checkbox/ */}
	-webkit-appearance: none;
	background-color: ${colors.white};
	border: 1px solid ${colors.blue};
	border-radius: 2px;
	float: left;
	height: 20px;
	margin-right: 5px;
	padding-bottom: 5px;
	padding-top: 5px;
	position: relative;
	width: 20px;
}

input[type='checkbox']:active,
input[type='checkbox']:checked:active,
input[type='checkbox']:focus {
	outline: 0;
}

input[type='checkbox']:checked {
	background-color: ${colors.blue};
	border: 1px solid ${colors.blue};
	color: ${colors.white};
}

input[type='checkbox']:checked:after {
	content: 2713;
	position: absolute;
	top: -2px;
	left: 4px;
	color: ${colors.white};
}

& .hex-color {
  position: relative;

   & input {
    width: 120px;
    border-radius: 100px;
  }

  & .preview {
    border-radius: 15px;
    border: 1px solid ${colors.gray};
    height: 20px;
    left: 90px;
    position: absolute;
    top: 10px;
    width: 20px;
  }

}

& .char-limit {
  float: right;
  margin-top: 2px
}

& .static-text {
  margin-top: -10px;
 }

& .date-picker {
  border: 1px solid ${colors.gray};
  height: 40px;
  box-sizing: content-box;
  border-radius: 2px;
  &.error {
    box-shadow: none;
    border-color: ${colors.red};
  }
}

 & .divider {
   border-bottom: 1px solid ${colors.gray};
   margin-top: 10px;
   margin-bottom: 10px;
 }`;

export default s;
