import { API_REQUEST } from '~src/lib/api';
import { pascalizeReportName } from '~src/lib/helpers';
import {
  MESSAGING_SMS_ANALYTICS_DASHBOARD_LAYOUT,
  MESSAGING_SMS_ANALYTICS_REPORTING,
  CSM_TRACKING_ASSETS_SAVE,
} from '~src/constants/endpoints';
import { createChartData } from '~src/containers/Messaging/MessagingSmsDashboardAnalytics/messagingChartData';

export const RECEIVE_ERRORS = 'RECEIVE_ERRORS_TRACKING';
export const RECEIVE_NEW_SMS_ENABLED = 'RECEIVE_NEW_SMS_ENABLED';
export const RECEIVE_DASHBOARD = 'MESSAGING_SMS_ANALYTICS/RECEIVE_DASHBOARD';
export const UPDATE_DATE_RANGE = 'MESSAGING_SMS_ANALYTICS/UPDATE_DATE_RANGE';
export const UPDATE_SELECTED_LOCALE =
  'MESSAGING_SMS_ANALYTICS/UPDATE_SELECTED_LOCALE';
export const RECEIVE_CHART_DATA = 'MESSAGING_SMS_ANALYTICS/RECEIVE_CHART_DATA';

const mapLocales = locales => locales.map(locale => ({ locale }));

export const updateSelectedLocale = selectedLocale => dispatch =>
  dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });

export const updateDateRange = (startDate, endDate) => dispatch =>
  dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

const receiveChartData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_DATA,
  payload: { chartData, tableData },
  meta: { chartName },
});

export const fetchDashboard = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchDashboard:sms_engagement',
    path: MESSAGING_SMS_ANALYTICS_DASHBOARD_LAYOUT,
  })
    .then(json => {
      const {
        dashboard_definitions /* eslint-disable-line camelcase */,
        dashboard_definitions: {
          sms_engagement /* eslint-disable-line camelcase */,
          sms_engagement: { layout },
        },
        report_instances: reportInstances,
        report_definitions: reportDefinitions,
        supported_locales: supportedLocales,
        default_locale: defaultLocale,
      } = json;

      // TODO: Refactor the mapp logic of laypout property once API structure updated
      dispatch({
        type: RECEIVE_DASHBOARD,
        payload: {
          dashboard_definitions: {
            ...dashboard_definitions /* eslint-disable-line camelcase */,
            sms_engagement: {
              ...sms_engagement /* eslint-disable-line camelcase */,
              layout,
            },
          },
          reportInstances,
          reportDefinitions,
          supportedLocales: mapLocales(supportedLocales),
          defaultLocale,
        },
      });
    })
    .catch(error => {
      console.error(
        'Error fetching dashboard',
        error,
      ); /* eslint-disable-line no-console */
    });
};
export const fetchChartData = (query, chartName, chartInfo) => dispatch => {
  const name = `fetch${pascalizeReportName(chartName)}`;
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: MESSAGING_SMS_ANALYTICS_REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        console.error(
          `${json.error}: ${json.msg}`,
        ); /* eslint-disable-line no-console */
      }
      // If we don't have the `object` from the report definition lets
      // get it from the query:
      const object = query.object.replace(/"/g, '');
      const records = json[object];
      if (records) {
        let chartData;
        if (chartInfo.type === 'single-metric') {
          chartData = Object.values(records[0].metrics)[0];
        } else if (chartInfo.type === 'single-metric-with-benchmark') {
          chartData = [];
          if (records[0]) {
            const [mainMetricName, subMetricName] = query.metrics.split(','); //  example: ""percent_click_through_rate"",""percent_diff_from_benchmark""
            const {
              [mainMetricName.replace(/"/g, '')]: firstChartData,
              [subMetricName.replace(/"/g, '')]: secondChartData,
            } = records[0].metrics;
            chartData = [firstChartData, secondChartData];
          }
        } else if (chartInfo.type === 'single-metric-with-percent-split') {
          chartData = [];
          const [
            firstMetricName,
            secondMetricName,
            thirdMetricName,
          ] = query.metrics.replace(/"/g, '').split(',');

          const metricsInfo =
            Array.isArray(records) && records.length > 0
              ? records[0].metrics
              : {};
          const firstChartData = metricsInfo[firstMetricName] || 0;
          const secondChartData = metricsInfo[secondMetricName] || 0;
          const thirdChartData = metricsInfo[thirdMetricName] || 0;

          chartData = [firstChartData, secondChartData, thirdChartData];
        } else if (chartInfo.type === 'multiitem') {
          chartData = { t_return_details: records };
        } else {
          chartData = createChartData(records, query, chartName, chartInfo);
        }
        dispatch(receiveChartData(chartData, chartName, records));
      }
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};

export const receiveError = error => {
  console.error(error);
  return {
    type: RECEIVE_ERRORS,
    meta: { error },
  };
};

export const receiveNewSMSEnabled = payload => ({
  type: RECEIVE_NEW_SMS_ENABLED,
  payload,
});

// This determines if the retailer is enabled for new platform i.e. notification center

export const fetchRetailerSettings = () => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: CSM_TRACKING_ASSETS_SAVE,
    });

    const { retailer_settings: retailerSettings } = json;

    const parsedSettings = JSON.parse(retailerSettings);
    const {
      features: {
        messaging_preferences: {
          sms: { enabled: newSMSEnabled = false } = {},
        } = {},
      } = {},
    } = parsedSettings;
    dispatch(receiveNewSMSEnabled({ newSMSEnabled }));
  } catch (e) {
    dispatch(receiveError(e));
  }
};
