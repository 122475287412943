import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  background: #ffffff;
  & svg {
    cursor: default;
  }

  & .table-align {
    display: inline-table;
    height: 100%;

    & .table-align-cell {
      display: table-cell;
      vertical-align: middle;
      line-height: 0;
      white-space: nowrap;
    }

    & .right-cell {
      width: 100%
    }

    & .delete-icon {
      float:right;

      & svg {
        cursor: pointer;

        & path {
          fill-opacity: 0.2;
        }

        &:hover {

          & path {
            fill-opacity: 0.8;
          }
        }
      }
    }
  }

  & .link {
    color: #1399d6;
    cursor: pointer;
    display: inline;
    padding: 0;
  }

  & .line-row {
    height: 30px;
    width: 100%;
  }

  & .muted {
    color: #cdcdcd;

    & svg {
      path {
        fill: #cdcdcd;
        fill-opacity: 1;
      }
    }

    & .link {
      cursor: default;
      color: #cdcdcd
    }
  }

  & .file-detail-icon {
    display: inline;
    margin-right: 10px;

  }
`;

export default s;
