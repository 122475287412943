const { countries } = require('moment-timezone/data/meta/latest.json');

// eslint-disable-next-line no-unused-vars
const timezones = Object.entries(countries).reduce((acc, [_, { zones }]) => {
  zones.forEach(zone => {
    acc[zone] = zone;
  });

  return acc;
}, {});

const timezoneList = Object.keys(timezones).map(tz => ({
  timezone: tz,
  value: tz,
}));

export default timezoneList;
export const tzListSorted = (() => {
  let tzSortedList;
  return () => {
    tzSortedList =
      tzSortedList ??
      [...timezoneList].sort((ta, tb) => (ta.value <= tb.value ? -1 : 1));
    return tzSortedList;
  };
})();
