import { DISMISS_ALERT } from '~src/actions/uiActions.js';
import {
  CLEAR_FORM_VALUES,
  RECEIVE_CARRIERS,
  RECEIVE_DETAILS,
  RECEIVE_DETAILS_DELETE,
  RECEIVE_SAVE_ADD_DETAILS,
  RECEIVE_DETAILS_UPDATE,
  RECEIVE_SELECTOR_LIST,
  RECEIVE_ERROR,
  RESET_DETAILS,
  ON_DETAILS_CHANGE,
  ON_HANDOFFS_SELECTER_CHANGE,
  TOGGLE_DELETE_CONFIRM_MODAL,
} from '~src/actions/handoffsActions';

const setHandoffsCarrierChange = ({
  state = {},
  payload: { name, selectedName } = {},
} = {}) => {
  const {
    handoffsDetails,
    handoffsDetails: {
      initial_carriers: initialCarriers,
      initial_carrier: initialCarrier,
      handoff_carriers: handoffCarriers,
      handoff_carrier: handoffCarrier,
    },
  } = state;

  const unselectedCarriers =
    selectedName === 'initial_carriers' ? initialCarriers : handoffCarriers;

  const selectedCarriers = unselectedCarriers.map((carrier, index) => ({
    ...carrier,
    selected: name === `${index}`,
  }));

  const { key: selectedCarrier } = selectedCarriers.find(
    ({ selected }) => selected,
  );

  return {
    ...state,
    handoffsDetails: {
      ...handoffsDetails,
      initial_carriers:
        selectedName === 'initial_carriers'
          ? selectedCarriers
          : initialCarriers,
      initial_carrier:
        selectedName === 'initial_carriers' ? selectedCarrier : initialCarrier,
      handoff_carriers:
        selectedName === 'handoff_carriers'
          ? selectedCarriers
          : handoffCarriers,
      handoff_carrier:
        selectedName === 'handoff_carriers' ? selectedCarrier : handoffCarrier,
    },
    changeDetected: true,
  };
};

const initialState = {
  alert: {
    show: false,
  },
  changeDetected: false,
  handoffsSelectorList: {
    body: [],
    header: [],
  },
  handoffsDetails: {
    initial_carriers: [],
    handoff_carriers: [],
    handoff_condition: '',
  },
  errors: [],
  locales: {
    locale_code: 'en_US',
  },
  messages: [],
  showDeleteConfirmModal: false,
  totalResults: 0,
};

const handoffs = (state = initialState, { meta, type, payload } = {}) => {
  switch (type) {
    case RECEIVE_SELECTOR_LIST:
      return {
        ...state,
        errors: [],
        handoffsSelectorList: payload.content,
        totalResults: meta.totalElements,
      };

    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occured.'
              : meta.error,
        },
        changeDetected: false,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_CARRIERS:
      return {
        ...state,
        handoffsDetails: payload,
      };

    case RECEIVE_DETAILS:
      return {
        ...state,
        handoffsDetails: payload,
      };

    case RESET_DETAILS:
      return {
        ...state,
        handoffsDetails: initialState.handoffsDetails,
      };

    case ON_DETAILS_CHANGE:
      return {
        ...state,
        changeDetected: true,
        handoffsDetails: {
          ...state.handoffsDetails,
          handoff_condition: payload,
        },
      };

    case ON_HANDOFFS_SELECTER_CHANGE:
      return setHandoffsCarrierChange({ state, payload });

    case RECEIVE_SAVE_ADD_DETAILS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Handoff was added successfully.',
        },
        changeDetected: false,
        handoffsDetails: payload,
      };

    case RECEIVE_DETAILS_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Handoff was updated successfully.',
        },
        changeDetected: false,
        handoffsDetails: payload,
      };

    case RECEIVE_DETAILS_DELETE:
      return {
        ...state,
        changeDetected: false,
        handoffsDetails: initialState.handoffsDetails,
      };

    case TOGGLE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: !state.showDeleteConfirmModal,
      };

    case CLEAR_FORM_VALUES:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default handoffs;
