import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import cx from 'classnames';
import { debounce } from '../../lib/helpers';
import Loader from '../Loader';
import Svg from '../Svg';
import SearchableSelectItem from './SearchableSelectItem';
import s from './SearchableSelect.styles';

const HIDDEN = Symbol('HIDDEN');

export class SearchableSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 0,
      activeDimensionIdx: 0,
      activeValueIdx: 0,
      isOpen: props.isOpen ? props.isOpen : false,
      selected: null,
      value: '',
    };

    this.throttledChange = debounce(
      event => {
        if (event.target) {
          props?.onChange(event.target.value);
        }
      },
      150,
    );
  }

  componentDidMount() {
    if (this.props.items.length > 0 && this.props.items[0].group) {
      this.groupSelectInitFocus();
    }

    if (this.props.selected && !this.state.selected) {
      this.setState({
        value: this.props.selected[this.props.readableKey],
        selected: this.props.selected,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selected && !this.state.selected) {
      this.setState({
        value: nextProps.selected[nextProps.readableKey],
        selected: nextProps.selected,
      });
    }

    if (
      (this.props.selected !== null && nextProps.selected === null) ||
      (this.state.selected !== null && nextProps.selected === null)
    ) {
      this.setState({
        value: '',
        selected: nextProps.selected,
      });
    }

    this.setState({ uiItems: nextProps.items });
  }

  groupSelectInitFocus() {
    this.searchableSelect.focus();
  }

  handleClickOutside = () => {
    if (!this.state.selected) {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.setState({
      selected: null,
      value: '',
    });

    // eslint-disable-next-line no-unused-expressions
    this.props.onChange?.('');

    if (this.props.onCancel) {
      this.props.onCancel(this.props.name);
    }
  };

  handleClickInput = event => {
    event.stopPropagation();

    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleChange = event => {
    event.persist();
    this.throttledChange(event);

    if (!this.state.isOpen) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }

    this.setState({
      value: event.currentTarget.value,
      selected: null,
    });

    if (this.props.autoSearch) {
      const { readableKey } = this.props;
      const searchText = event.target.value;
      let uiItems = this.props.items;
      if (searchText) {
        const searchTextL = searchText.toLowerCase();
        uiItems = uiItems.map(item => ({
          ...item,
          [HIDDEN]: !item[readableKey].toLowerCase().includes(searchTextL),
        }));
      }
      this.setState({ value: searchText, uiItems });
    }
  };

  handleFocus = () => {
    if (this.props.onFocus) {
      this.props.onFocus(this.state.value);
    }
  };

  handleSelect = (event, props) => {
    const { items } = this.props;
    const selectName = this.props.name;
    if (items[0].group) {
      this.props.onSelect(event, props);
      this.handleToggle();
    } else {
      this.setState(
        {
          value: this.props.items[event.currentTarget.name][
            this.props.readableKey
          ],
          selected: this.props.items[event.currentTarget.name],
          selectedIndex: parseInt(event.currentTarget.name, 10),
        },
        this.handleToggle,
      );
      this.props.onSelect(event, selectName);
    }
  };

  handleToggle = () => {
    const { isOpen, selected } = this.state;
    const { items, onToggle } = this.props;
    const dropdownEl = ReactDOM.findDOMNode(this.searchableSelectDropdown);
    const itemHeight = 40;

    const updatedState = {
      isOpen: !isOpen,
      activeItem: items.length && items[0].group ? 1 : 0,
    };

    if (!isOpen && selected) {
      updatedState.activeItem = this.state.selectedIndex;
    }

    if (!isOpen) {
      if (this.searchableSelect !== null) {
        this.searchableSelect.focus();
      }
    }

    if ((items.length === 0 && isOpen) || (!selected && isOpen)) {
      updatedState.value = '';
    }

    this.setState(updatedState, () => {
      if (dropdownEl) {
        if (this.state.activeItem > 4) {
          dropdownEl.parentElement.scrollTop =
            itemHeight * this.state.activeItem;
        } else {
          dropdownEl.parentElement.scrollTop = 0;
        }
      }
    });

    if (onToggle) {
      onToggle();
    }
  };

  handleKeyUp = event => {
    const { keyCode } = event;

    if (keyCode === 32) {
      event.preventDefault();
    }
  };

  handleGroupKeyDown = event => {
    const { keyCode } = event;
    let { activeDimensionIdx, activeValueIdx } = this.state; // active counter
    const { items, readableKey } = this.props;

    if (keyCode === 40) {
      if (items[activeDimensionIdx].values.length > activeValueIdx + 1) {
        activeValueIdx++;
      } else if (items.length > activeDimensionIdx + 1) {
        activeDimensionIdx++;
        activeValueIdx = 0;
      }
    } else if (keyCode === 38) {
      if (activeValueIdx - 1 >= 0) {
        activeValueIdx--;
      } else if (activeDimensionIdx - 1 >= 0) {
        activeDimensionIdx--;
        activeValueIdx = items[activeDimensionIdx].values.length - 1;
      }
    } else if (keyCode === 13 && items.length > 0) {
      this.props.onSelect(event, {
        item: items[activeDimensionIdx].values[activeValueIdx],
      });
      this.handleToggle();
      return;
    } else {
      this.handleToggle();
      this.searchableSelect.blur();
    }

    this.setState({
      activeDimensionIdx,
      activeValueIdx,
      value: items[activeDimensionIdx].values[activeValueIdx][readableKey],
      selected: null,
    });
  };

  handleKeyDown = event => {
    const { keyCode } = event;
    const selectName = this.props.name;

    if (
      !(keyCode === 40 || keyCode === 38 || keyCode === 13 || keyCode === 27)
    ) {
      return;
    }

    if (this.props.items.length > 0 && this.props.items[0].group) {
      this.handleGroupKeyDown(event);
      return;
    }

    let { activeItem } = this.state;
    const { items, readableKey } = this.props;

    const itemHeight = 40;
    const dropdownEl = ReactDOM.findDOMNode(this.searchableSelectDropdown);
    const currentScroll = dropdownEl.parentElement.scrollTop;

    // Down Arrow key
    if (keyCode === 40 && activeItem + 1 < items.length) {
      activeItem++;
      this.setState({
        activeItem,
        value: items[activeItem][readableKey],
        selected: null,
      });
      if (activeItem >= currentScroll / itemHeight + 4) {
        dropdownEl.parentElement.scrollTop += 160;
      }
      // Up Arrow key
    } else if (keyCode === 38 && activeItem - 1 >= 0) {
      event.preventDefault();
      activeItem--;
      this.setState({
        activeItem,
        value: items[activeItem][readableKey],
        selected: null,
      });
      if (itemHeight * activeItem + 1 > dropdownEl.parentElement.scrollTop) {
        // empty
      } else if (activeItem <= currentScroll / itemHeight + 4) {
        dropdownEl.parentElement.scrollTop -= itemHeight;
      }
    } else if (keyCode === 13 && items.length > 0) {
      this.setState(
        {
          value: items[activeItem][readableKey],
          selected: items[activeItem],
          selectedIndex: activeItem,
        },
        this.handleToggle,
      );
      this.props.onSelect({ currentTarget: { name: activeItem } }, selectName);

      this.searchableSelect.blur();
    } else {
      this.handleToggle();
      this.searchableSelect.blur();
    }
  };
  render() {
    const {
      autoFocus,
      color,
      descriptionKey,
      disabled,
      divider,
      isFetching,
      items,
      name,
      pullRight,
      readableKey,
      placeholder,
      testAttr,
      autoSearch,
    } = this.props;
    const {
      isOpen,
      id,
      activeDimensionIdx,
      activeValueIdx,
      selected,
      value,
      uiItems = items,
    } = this.state;

    const inputCss = autoSearch
      ? {
          height: 27,
        }
      : {};
    return (
      <Dropdown
        className={cx(
          s,
          selected ? 'is-selected' : '',
          isFetching && isOpen ? 'is-fetching' : '',
        )}
        data-test={`${testAttr}-select`}
        disabled={disabled}
        id={id}
        isOpen={isOpen}
        toggle={this.handleToggle}
      >
        <DropdownToggle caret color={color} disabled={disabled} name={name}>
          <input
            autoFocus={autoFocus}
            type="text"
            ref={ref => (this.searchableSelect = ref)}
            placeholder={placeholder}
            value={value}
            onChange={this.handleChange}
            onClick={this.handleClickInput}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            onFocus={this.handleFocus}
            className="form-control"
            title={value}
            disabled={disabled}
            style={inputCss}
            data-test="search-sel"
          />
          {isFetching && isOpen && (
            <Loader show={isFetching} className="loader" />
          )}
          {selected && !this.props.removeDisabled && (
            <div className="close" onClick={this.handleCancel}>
              <Svg className="icon" name="close" />
            </div>
          )}
        </DropdownToggle>
        {!isFetching && (
          <DropdownMenu color="primary" right={pullRight}>
            <div ref={ref => (this.searchableSelectDropdown = ref)}>
              {uiItems.length === 0 && !isFetching && (
                <span className="no-results">No results found.</span>
              )}
              {uiItems.map(
                (item, index) =>
                  !item[HIDDEN] && (
                    <div key={index}>
                      <button
                        data-test={`${
                          item.divider
                            ? 'divider'
                            : String(item[readableKey]).toLowerCase()
                        }-select-item`}
                        name={index}
                        key={index}
                        onClick={!item.group ? this.handleSelect : null}
                        className={cx(
                          'btn',
                          'select-item',
                          item.group ? 'select-group' : null,
                          index === this.state.activeItem && !item.group
                            ? 'activeItem'
                            : null,
                          selected &&
                            item[readableKey] ===
                              this.state.selected[readableKey]
                            ? 'active'
                            : null,
                        )}
                        title={item[readableKey]}
                      >
                        <span style={item.labelStyles}>
                          {item[readableKey]}
                          {descriptionKey && (
                            <span className="description">
                              {item[descriptionKey]}
                            </span>
                          )}
                        </span>
                      </button>
                      {divider === index ? <DropdownItem divider /> : null}
                      {item.values &&
                        item.values.length > 0 &&
                        item.values.map((value, valueIdx) => (
                          <SearchableSelectItem
                            key={valueIdx}
                            index={valueIdx}
                            item={value}
                            isActive={
                              index == activeDimensionIdx &&
                              valueIdx == activeValueIdx
                            }
                            selected={selected}
                            readableKey={readableKey}
                            onSelect={this.handleSelect}
                          />
                        ))}
                    </div>
                  ),
              )}
            </div>
          </DropdownMenu>
        )}
        {this.props.children}
      </Dropdown>
    );
  }
}

SearchableSelect.propTypes = {
  autoSearch: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onToggle: PropTypes.func,
  placeholder: PropTypes.string,
  readableKey: PropTypes.string.isRequired,
  selected: PropTypes.shape({
    group: PropTypes.string,
  }),
  testAttr: PropTypes.string,
  color: PropTypes.string,
  descriptionKey: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.any,
  isFetching: PropTypes.any,
  name: PropTypes.string,
  pullRight: PropTypes.bool,
  removeDisabled: PropTypes.any,
  children: PropTypes.node,
};

SearchableSelect.defaultProps = {
  autoSearch: false,
  autoFocus: false,
  disabled: false,
  isFetching: false,
  isOpen: false,
  testAttr: 'searchable-select',
  selected: null,
  placeholder: '',
  onSelect: null,
  onCancel: null,
  onChange: null,
  onFocus: null,
  onToggle: null,
  children: null,
};

export default SearchableSelect;
