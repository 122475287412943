import moment from 'moment';
import { ALL_CARRIERS_VALUE } from '~src/constants/exceptionsAnalyticsApis';
import {
  DISMISS_ALERT,
  RECEIVE_EXCEPTIONS_ANALYTICS_REPORT,
  RECEIVE_ERRORS,
  SELECT_DATE_RANGE,
  RECEIVE_FEATURES_ENABLED_IN_EXCEPTIONS,
  SELECT_CARRIER,
  RECEIVE_CHART_DETAILS,
  RECEIVE_EXPORT_CHART_DETAILS,
  UPDATE_ACTIVE_PAGE,
  UPDATE_HAS_DATE_CHANGED,
  RECEIVE_CARRIERS_LIST,
} from '~src/actions/exceptionAnalyticsActions';

import { API_DATE_FORMAT } from '../constants/dateFormats';

// TODO: Move this to a constant files
const DANGER = 'danger';

const initialState = {
  alert: {
    show: false,
  },
  dateRange: {
    startDate: moment()
      .subtract(6, 'days')
      .format(API_DATE_FORMAT),
    endDate: moment().format(API_DATE_FORMAT),
  },
  reports: {},
  selectedCarrier: ALL_CARRIERS_VALUE,
  exportDetails: {},
  chartDetails: {},
  activePage: 1,
  hasDateChanged: false,
  carriers: [],
};

function exceptionsAnalyticsReducer(state = initialState, action) {
  const { type, payload, meta } = action;

  switch (type) {
    case RECEIVE_ERRORS:
      return {
        ...state,
        alert: {
          color: DANGER,
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'There was an error. Please try again.'
              : `${meta.error}.`,
        },
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };
    case RECEIVE_EXCEPTIONS_ANALYTICS_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };
    case SELECT_DATE_RANGE:
      return {
        ...state,
        dateRange: payload.dateRange,
      };
    case SELECT_CARRIER:
      return {
        ...state,
        selectedCarrier: payload.selectedCarrier,
      };
    case RECEIVE_FEATURES_ENABLED_IN_EXCEPTIONS:
      return {
        ...state,
        exceptionsFeatureEnabled: payload.exceptionsFeatureEnabled,
      };
    case RECEIVE_CARRIERS_LIST: {
      return {
        ...state,
        carriers: payload.carriers,
      };
    }
    case RECEIVE_EXPORT_CHART_DETAILS:
      return {
        ...state,
        exportDetails: {
          ...state.exportDetails,
          ...payload.exportDetails,
        },
      };
    case RECEIVE_CHART_DETAILS:
      return {
        ...state,
        chartDetails: {
          ...state.chartDetails,
          ...payload.chartDetails,
        },
      };
    case UPDATE_ACTIVE_PAGE:
      return {
        ...state,
        activePage: payload.activePage,
      };
    case UPDATE_HAS_DATE_CHANGED:
      return {
        ...state,
        hasDateChanged: payload.hasDateChanged,
      };
    default:
      return state;
  }
}

export default exceptionsAnalyticsReducer;
