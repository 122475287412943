import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import LocaleSelector from '@narvar/hum-locale-selector';
import image from '../../assets/images/narvar_logo_footer.png';
import s from './Footer.styles';
import CaretDown from './svg/CaretDown';
import CaretUp from './svg/CaretUp';

export const Footer = ({
  i18n,
  showLocale,
  currentLocale,
  locales,
  onLocaleChange,
}) => {
  const [languageSelectorOpen, setLanguageSelectorOpen] = useState(false);

  const handleLocaleChange = locale => {
    if (onLocaleChange) onLocaleChange(locale);
  };

  const handleLanguageSelectorOpen = () => {
    setLanguageSelectorOpen(true);
  };

  const handleLanguageSelectorClose = () => {
    setLanguageSelectorOpen(false);
  };

  return (
    <div className={cx(s, 'footer')}>
      <Container>
        <Row>
          <Col md={12}>
            <div className="footer-container">
              <a href="https://corp.narvar.com/">
                <img src={image} alt="Narvar" />
              </a>
              <div className="narvar-links">
                <div className="language-selector-wrapper">
                  <LocaleSelector
                    currentLocale={currentLocale}
                    show={showLocale}
                    locales={locales}
                    onChange={handleLocaleChange}
                    onOpen={handleLanguageSelectorOpen}
                    onClose={handleLanguageSelectorClose}
                  >
                    <div className="language-selector-btn-wrapper">
                      <div className="language-selector-arrow">
                        {languageSelectorOpen ? <CaretDown /> : <CaretUp />}
                      </div>
                      <span className="footer-link">
                        {i18n.language ?? 'Language'}
                      </span>
                    </div>
                  </LocaleSelector>
                </div>
                {showLocale && <div className="footer-divider" />}
                <div>
                  <a
                    className="footer-link terms-of-use"
                    href="http://corp.narvar.com/terms"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {i18n.termsOfUse ?? 'Terms of Use'}
                  </a>
                </div>
                <div className="footer-divider" />
                <div>
                  <a
                    className="footer-link contact-us"
                    href="mailto:support@narvar.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {i18n.contactUs ?? 'Contact Us'}
                  </a>
                </div>
                <div className="footer-divider" />
                <div>
                  <a
                    className="footer-link privacy-policy"
                    href="https://corp.narvar.com/privacy-policy"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {i18n.privacyPolicy ?? 'Privacy Policy'}
                  </a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.propTypes = {
  i18n: PropTypes.objectOf(PropTypes.string),
  locales: PropTypes.arrayOf(PropTypes.string),
  showLocale: PropTypes.bool,
  currentLocale: PropTypes.string,
  onLocaleChange: PropTypes.func,
};

Footer.defaultProps = {
  i18n: {},
  locales: [],
  showLocale: true,
  currentLocale: 'en',
  onLocaleChange: undefined,
};

Footer.displayName = 'Footer';

export default Footer;
