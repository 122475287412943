import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';

const hubDomain = (to, isNeohub) => {
  if (process.env.NODE_ENV === 'test') {
    return to;
  }

  if (!isNeohub) {
    return to;
  }

  return `${window.location.origin.replace('hubn', 'hub')}${to}`;
};

const HubLink = ({ to, children, isNeohub, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link href={hubDomain(to, isNeohub)} {...props}>
    {children}
  </Link>
);

HubLink.propTypes = {
  to: PropTypes.string.isRequired,
  isNeohub: PropTypes.bool.isRequired,
  children: PropTypes.string.isRequired,
};

export default HubLink;
