import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './TableBody.styles';
import TableRow from '../TableRow';

export const TableBody = ({
  body,
  children,
  headings,
  htmlTableCells,
  isFetching,
  name,
  tableBodyHeight,
}) => {
  const full = body.length >= 9;
  return (
    <tbody
      className={cx(
        s,
        'table-body',
        isFetching ? 'is-fetching' : '',
        full ? 'full-table' : '',
      )}
      style={{ height: tableBodyHeight }}
    >
      {!isFetching &&
        body &&
        body.map((row, rowIndex) => (
          <TableRow
            headings={headings}
            htmlTableCells={
              htmlTableCells !== undefined ? htmlTableCells : undefined
            }
            key={rowIndex}
            name={name}
            row={row}
            rowIndex={rowIndex}
          />
        ))}
      {children}
    </tbody>
  );
};

TableBody.defaultProps = {
  body: [],
  children: null,
  headings: [],
  htmlTableCells: undefined,
  isFetching: false,
  name: '',
  tableBodyHeight: 'auto',
};

TableBody.propTypes = {
  body: PropTypes.arrayOf(
    PropTypes.shape({
      onRowClick: PropTypes.func,
      text: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      ),
    }),
  ),
  children: PropTypes.element,
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      width: PropTypes.number,
    }),
  ),
  htmlTableCells: PropTypes.array,
  isFetching: PropTypes.bool,
  name: PropTypes.string,
  tableBodyHeight: PropTypes.string,
};

TableBody.displayName = 'TableBody';

export default TableBody;
