import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  &.is-chart-detail {
    margin: 0 10px 10px 10px;
  }

  & > button {
    text-transform: capitalize;
    border: none;
    padding-left: 0px;

    &:hover {
      color: ${colors.blueDark} !important;
      & path {
        fill: ${colors.blueDark};
      }
    }

    &:hover, &:focus {
      box-shadow: none;
      text-decoration: none;
      transition: initial;
    }
  }

  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 40px;
  }

  & .dropdown {
    width: 200px;
  }

  & .plus {
    width: 25px;
    height: 25px;
    transform: scale(0.5);
    & path {
      fill: ${colors.blue};
      fill-opacity: 1;
    }
  }

@media print {
  &>.add-filter {
    display: none;
  }
}`;

export default s;
