import moment from 'moment-timezone';
import numeral from 'numeral';
import currencyFormatter from '../../Returns/ReturnsDashboardAnalytics/convertCurrency';

export default class ChartData {
  constructor() {
    this._colors = undefined;

    /**
     * Array of series for x & y data points
     * @example
     * [
     *     {
     *         x: 12,
     *         y: 22,000
     *         color: #eee
     *     },
     *     {
     *         x: 13,
     *         y: 15,563
     *         color: undefined
     *     }
     *     ...
     * ]
     * @type {Array}
     */
    this._data = []; // array of series point for x & y

    /**
     * Formatter is a object with 2 keys for xAxis & yAxis
     * This function is suppose to follow the same semantic
     * as Highcharts label.formatter function
     *
     * @type {Object}
     */
    this._formatter = {
      xAxis: undefined, // eslint-disable-line no-undefined
      yAxis: undefined, // eslint-disable-line no-undefined
      tooltip: undefined, // eslint-disable-line no-undefined
    };

    /**
     * Represents the stack type for column/bar charts
     * when multiple series is present
     *
     * @type {String}
     * @default [normal]
     */
    this._stackingType = 'normal';
    this._chartType = undefined;
    this._tickInterval = undefined;
    this._categories = {};
    this._categoryIndex = 0;
  }

  /**
   * Highcharts accepts multiple series (Like Multiple Linecharts)
   * This function allows adding series to our ChartData._data
   *
   * @param {Object} series Object which is returned from `createSeries`
   */
  addSeries(series) {
    this._data.push(series);
    return this;
  }

  /**
   * A formatter function for highchart label
   * in X Axis
   *
   * @param {Function} func
   */
  setXAxisFormatter(func) {
    if (typeof func !== 'function') {
      throw new Error('X Axis Formatter must be a function');
    }
    this._formatter.xAxis = func;
    return this;
  }

  /**
   * A formatter function for highchart label
   * in Y Axis
   *
   * @param {Function} func
   */
  setYAxisFormatter(func) {
    if (typeof func !== 'function') {
      throw new Error('Y Axis Formatter must be a function');
    }
    this._formatter.yAxis = func;
    return this;
  }

  setTooltipFormatter(func) {
    this._formatter.tooltip = func;
    return this;
  }

  /*
   * `normal`: stacking will happen based on values
   * 'percent': stacking will happen based on total percentage
   * null: stacking will be cleared & multiple series will be placed
   * side by side
   *
   */

  setYAxisTitle(title) {
    this._yAxisTitle = title;
    return this;
  }

  setYAxisMax(max) {
    this._yAxisMax = max;
    return this;
  }

  setStackingType(type) {
    if (type && ['normal', 'percent'].indexOf(type) === -1) {
      throw new Error('Stacking type can be null, normal or percent only');
    }
    this._stackingType = type;
    return this;
  }

  setChartType(type) {
    if (type && ['datetime', 'category'].indexOf(type) === -1) {
      throw new Error('Chart type can be null, category, or datetime only');
    }
    if (type === 'datetime') {
      this._tickInterval = 24 * 3600 * 1000;
    }
    this._chartType = type;
    return this;
  }

  setTickInterval(interval) {
    if (interval && typeof interval !== 'number') {
      throw new Error('Chart interval must be a number');
    }
    this._tickInterval = interval;
    return this;
  }

  createCategories(category) {
    if (this._categories[category] === undefined) {
      this._categories[category] = this._categoryIndex;
      this._categoryIndex += 1;
    }
    return this._categories[category];
  }

  addCategories() {
    /*
    this._categories is an object with { value: index } pairs.
    where index is the index of that data point in the original api response
    (see createCategories method)
    */
    const catKeys = Object.keys(this._categories);
    const sortedCatKeys = catKeys.sort((keyA, keyB) => {
      const { [keyA]: indexA, [keyB]: indexB } = this._categories;
      return indexA - indexB;
    });
    this._categories = sortedCatKeys;
    // this._catogories is now an array of just the values, sorted by index.
    return this;
  }

  sortCategories(func) {
    this._categories.sort(func);
    return this;
  }
}

/**
 * It creates a series object which can be stored in ChartData._data
 *
 * @param  {Array} dataPoints Array of points which are returned from `createDataPoint`
 * @param  {String=} name
 * @param  {String=} color
 *
 * @return {Object}
 */
export function createSeries(dataPoints, name, color) {
  if (!Array.isArray(dataPoints)) {
    throw new Error('Passed `dataPoints` should be an array of DataPoint type');
  }
  if (!name) {
    throw new Error('Name of the series is required');
  }
  return {
    name,
    color,
    points: dataPoints,
  };
}

/**
 * It created a data point object which together consitutes a `points`
 * for a series
 *
 * @param  {String|Number} x
 * @param  {String|Number} y
 * @param  {String=} color
 * @return {Object}
 */
export function createDataPoint(x, y, color, otherInfo) {
  return {
    x,
    y,
    color,
    otherInfo,
  };
}

export function applyChartFormatters(chartData, chartInfo, query, chartName) {
  if (chartInfo && chartInfo.X.axis_type === 'day') {
    chartData.setXAxisFormatter(value => {
      const point = moment
        .utc(value)
        .tz('America/Los_Angeles')
        .format('MMM D');
      return point;
    });

    if (chartInfo.type !== 'bar') {
      chartData.setChartType('datetime');
    }
  }

  if (chartInfo && chartInfo.X.axis_type === 'days') {
    chartData.setXAxisFormatter(value => {
      const suffix = value > 1 ? 'days' : 'day';
      return `${value} ${suffix}`;
    });
  }

  if (chartInfo && chartInfo.X.axis_type === 'string') {
    chartData.setXAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return value;
      }
      return value;
    });
  }

  if (chartName === 'order_conversion_rate_over_time') {
    chartData.setYAxisTitle('Order Conversion Rate');
  }

  if (chartInfo.type === 'bar') {
    chartData.setYAxisTitle('Percentage');
    chartData.setChartType('category');
  }

  if (chartInfo && chartInfo.Y.axis_type === 'integer') {
    chartData.setYAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return numeral(value).format('0,0');
      }
      return numeral(value)
        .format('0.[0]a')
        .toUpperCase();
    });

    if (chartInfo.type === 'grouped_bar') {
      chartData.setStackingType('');
    } else if (chartInfo.type === 'stacked_bar') {
      chartData.setStackingType('normal');
    }
  }

  if (chartInfo && chartInfo.Y.axis_type === 'currency') {
    chartData.setYAxisFormatter(value =>
      currencyFormatter({
        currencyValue: value,
        locale: query.locale,
      }),
    );
  }

  if (chartInfo && chartInfo.Y.axis_type === 'percentage') {
    const percentFormat = chartInfo.type === 'donut' ? '0.0' : '0,0';

    if (chartInfo.type === 'donut') {
      chartData
        .setYAxisFormatter(value => `${numeral(value).format(percentFormat)}%`)
        .setStackingType('percent');
    } else if (chartInfo.type === 'line') {
      // I think the above block was messing with the line chart if the y-axis was in percentage type, so I kept it only for donut type
      chartData.setYAxisFormatter(
        value => `${numeral(value).format(percentFormat)}%`,
      );
      // set a max value for y axis to be the min(10% higher than the max data point y-value, 100)
      let yMax = 0;
      chartData._data.forEach(series => {
        series.points.forEach(point => {
          if (point.y > yMax) {
            yMax = point.y;
          }
        });
      });
      chartData.setYAxisMax(Math.min(yMax * 1.1, 100));
    } else if (chartInfo.type === 'grouped_bar') {
      chartData
        .setYAxisFormatter(value => `${numeral(value).format(percentFormat)}%`)
        .setStackingType('');
    } else if (chartInfo.type === 'stacked_bar') {
      chartData
        .setYAxisFormatter(value => `${numeral(value).format(percentFormat)}%`)
        .setStackingType('normal')
        .setYAxisMax(100);
    } else if (chartInfo.type === 'bar') {
      chartData
        .setYAxisFormatter(value => `${numeral(value).format(percentFormat)}%`)
        .setStackingType('normal')
        .setYAxisMax(100);
    }
  }

  if (
    chartInfo &&
    (chartInfo.Y.axis_type === 'average' || chartInfo.Y.axis_type === 'float')
  ) {
    chartData.setYAxisFormatter((value, isTooltip) => {
      if (isTooltip) {
        return numeral(value).format('0,0.00');
      }
      return numeral(value)
        .format('0.[0]a')
        .toUpperCase();
    });
  }

  if (chartInfo && chartInfo.X.axis_type === 'day') {
    const { metric } = chartInfo;
    chartData.setTooltipFormatter((title, subtitle, value) => {
      let formattedTitle;
      if (chartInfo.X.axis_type === 'day') {
        formattedTitle = moment
          .utc(title)
          .tz('America/Los_Angeles')
          .format('MMM DD, YYYY');
      } else if (chartData._formatter.xAxis) {
        formattedTitle = chartData._formatter.xAxis(title, true);
      }

      const tooltipValue = chartData._formatter.yAxis(value, true);

      return {
        title: formattedTitle,
        value: tooltipValue,
        type: metric,
      };
    });
  }

  return chartData;
}

export function createChartData(records, query, chartName, chartInfo) {
  const chartData = new ChartData();
  let metric;
  if (query.percent_col) {
    metric = 'percent';
  } else if (query.metrics.split(',').length > 1) {
    //  if query.metrics looks like
    //  ""percent_open_rate","count_distinct_denominator","count_distinct_numerator""
    //  want to convert to array of strings
    metric = query.metrics.split(',').map(e => JSON.parse(e));
  } else {
    metric = JSON.parse(query.metrics);
  }

  const dimensions = query.dimensions
    ? query.dimensions.split(',').map(d => JSON.parse(d))
    : [];
  const precision = query.precision || '';
  const xValueLabel = dimensions.length ? dimensions : precision;
  let dataPoints;
  const isDate = chartInfo && chartInfo.X.axis_type === 'day';
  const isDonut = chartInfo.type === 'donut';

  const donutChartTotal =
    isDonut &&
    records.reduce((currentItemTotal, { metrics }) => {
      const { count_distinct_return_process_info_id: itemTotal } = metrics;

      return currentItemTotal + itemTotal;
    }, 0);

  if (
    (dimensions.length > 1 && !precision.length) ||
    (dimensions.length && precision.length && chartInfo.type === 'line') ||
    (dimensions.length === 1 && precision.length)
  ) {
    if (precision.length) {
      dimensions.unshift(precision);
    }
    const [dimensionOne, dimensionTwo] = dimensions;
    //  dimensions= ['day', 'pageview_groups']

    const groupedRecords = records.reduce((gr, r) => {
      if (!gr[r.metrics[dimensionTwo]]) {
        gr[r.metrics[dimensionTwo]] = []; // eslint-disable-line no-param-reassign
      }
      //  if numerous metrics. ie if metric is an array
      if (Array.isArray(metric)) {
        const recordToInsert = {};
        metric.forEach(elem => {
          recordToInsert[elem] = r.metrics[elem];
        });
        recordToInsert[dimensionOne] = r.dimensions[dimensionOne];
        gr[r.metrics[dimensionTwo]].push(recordToInsert);
      } else {
        // if a single metric
        gr[r.metrics[dimensionTwo]].push({
          [metric]: r.metrics[metric],
          [dimensionOne]: r.dimensions[dimensionOne],
        });
      }
      return gr;
    }, {});

    const formatData = ({ label, color }) => {
      if (!groupedRecords[label]) return;
      dataPoints = groupedRecords[label].map(r => {
        const xValue = isDate
          ? moment.utc(r[dimensionOne]).valueOf()
          : chartData.createCategories(r[dimensionOne]);
        if (Array.isArray(metric)) {
          //  other info will be data additional to the x and y coordinates
          const otherInfo = {};
          metric.forEach((elem, index) => {
            if (index > 0) {
              otherInfo[elem] = r[elem];
            }
          });
          return createDataPoint(xValue, r[metric[0]], undefined, otherInfo);
        }
        return createDataPoint(xValue, r[metric]);
      });
      chartData.addSeries(
        createSeries(dataPoints.sort((a, b) => a.x - b.x), label, color),
      );
    };
    Object.keys(groupedRecords).map(key => formatData({ label: key }));
    chartData.addCategories();
  } else if (dimensions.length > 1 && precision.length) {
    const dimensionOne = dimensions[0];
    const dimensionTwo = dimensions[1];
    const groupedRecords = records.reduce((acc, r) => {
      if (acc[r.dimensions[dimensionTwo]] === undefined) {
        acc[r.dimensions[dimensionTwo]] = {
          [r.dimensions[dimensionOne]]: {
            metric: r.metrics[metric],
            [dimensionOne]: r.dimensions[dimensionOne],
          },
        };
      } else if (
        acc[r.dimensions[dimensionTwo]][r.dimensions[dimensionOne]] ===
        undefined
      ) {
        acc[r.dimensions[dimensionTwo]] = {
          ...acc[r.dimensions[dimensionTwo]],
          [r.dimensions[dimensionOne]]: {
            metric: r.metrics[metric],
            [dimensionOne]: r.dimensions[dimensionOne],
          },
        };
      } else {
        acc[r.dimensions[dimensionTwo]][r.dimensions[dimensionOne]].metric +=
          r.metrics[metric];
      }
      return acc;
    }, {});

    Object.keys(groupedRecords).forEach(status => {
      dataPoints = Object.keys(groupedRecords[status]).map(r => {
        const xValue = chartData.createCategories(
          groupedRecords[status][r][dimensionOne],
        );
        return createDataPoint(xValue, groupedRecords[status][r].metric);
      });

      chartData.addSeries(
        createSeries(dataPoints.sort((a, b) => a.x - b.x), status),
      );
    });
    chartData.addCategories();
  } else {
    dataPoints = records.map(r => {
      if (dimensions.length) {
        const category = r.dimensions[xValueLabel[0]];
        chartData.createCategories(category);
      }

      let xValue =
        dimensions.length === 1 ? undefined : r.dimensions[xValueLabel];
      if (chartInfo.type === 'bar') {
        xValue = r.dimensions[xValueLabel[0]];
      }

      return createDataPoint(xValue, r.metrics[metric]);
    });
    chartData.addSeries(createSeries(dataPoints, [])).addCategories();
  }

  const finalChartData = applyChartFormatters(
    chartData,
    chartInfo,
    query,
    chartName,
  );

  if (isDonut) finalChartData.totalValue = donutChartTotal;

  return finalChartData;
}
