import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  & .select-checkbox-label {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    color: ${colors.dark2};

    &.select-checkbox-label-dropdown {
      padding: 10px 20px 0;
    }

    &:hover {
      color: ${colors.dark1};
    }

    &.select-checkbox-label-selected {
      color: ${colors.dark1};
    }

    & .select-checkbox-label-text {
      flex: 1;
      margin-right: 2px;
    }
  }

  & label {
    & svg {
      transform: scale(0.4);
      transform-origin: 10% 24% 0px;
      & path {
        fill: #fff;
        fill-opacity: 1;
      }
    }
  }

  & .select-checkbox {
    border: 1px solid ${colors.blue};
    border-radius: 2px;
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }

  & input {
    display: none;

    &[type='checkbox']:checked {
      & ~ .select-checkbox {
        background-color: ${colors.blue};
        & svg {
          opacity: 1;
        }
      }
    }

    &[type='checkbox']:not(:checked) {
      & ~ .select-checkbox {
        & svg {
          opacity: 0;
        }
      }
    }
  }
`;

export default s;
