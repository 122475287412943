import styled from 'styled-components';

export const EditorWrapper = styled.div`
  height: 600px;
  border: 1px solid black;
  overflow: hidden;
  position: relative;
  width: 100%;
  resize: horizontal;
  z-index: 1;
  transition: all 1s linear;
  max-width: 1200px;
  margin-top: 20px;
`;

export const FooterBar = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
`;
