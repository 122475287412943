import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  display: inline-table;
  vertical-align: middle;

  & svg {
    cursor: default;
  }

  & .table-align-cell {
    display: table-cell;
    vertical-align: middle;
    line-height: 0;
    white-space: nowrap;
  }

  & .svgbutton-icon {
    display: inline;
    margin-right: 10px;

    &.muted {
      path {
        fill: #cdcdcd;
        fill-opacity: 1;
      }
    }
  }

  & .svgbutton-button {
    color: ${colors.blue};
    cursor: pointer;
    display: inline;
    padding: 0;
    outline: none;

    &.muted {
      cursor: default;
      pointer-events: none;
      color: #cdcdcd;
    }
  }
`;

export default s;
