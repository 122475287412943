import {
  RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
  RESET_UPLOADED_PORTABLE_CONFIG,
  RECEIVE_RETURN_REASON_SETS,
  RECEIVE_UPDATED_RETURN_REASONS_SET,
  RECEIVE_ADD_RETURN_REASONS_SET,
  RECEIVE_RETURN_REASONS_ERROR,
  RECEIVE_RETURN_REASONS_SUCCESS,
  RESET_RETURN_REASONS_FORM_AND_ERRORS,
  RECEIVE_RETURN_REASONS_LOCALES,
} from '~src/actions/returnReasonSetsActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';

const initialState = {
  alert: {
    show: false,
  },
  reasonSets: [],
  productCategories: [],
  locales: [],
  isSaveable: true,
  uploadedPortableConfig: null,
  showUploadConfirmationModal: false,
};

function returnReasonSetsReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE: {
      return {
        ...state,
        uploadedPortableConfig: payload,
        showUploadConfirmationModal: true,
      };
    }

    case RESET_UPLOADED_PORTABLE_CONFIG:
      return {
        ...state,
        uploadedPortableConfig: initialState.uploadedPortableConfig,
        showUploadConfirmationModal: initialState.showUploadConfirmationModal,
      };

    case RECEIVE_RETURN_REASONS_LOCALES:
      return {
        ...state,
        locales: payload.locales,
        productCategories: payload.categories.map(category => ({
          key: category,
          description: category,
        })),
      };

    case RECEIVE_RETURN_REASON_SETS:
      return {
        ...state,
        reasonSets: payload,
      };

    case RECEIVE_UPDATED_RETURN_REASONS_SET:
      return {
        ...state,
        reasonSets: state.reasonSets.map(reasonSet => {
          if (!reasonSet || !payload) return reasonSet;
          if (reasonSet.reason_set === payload.reason_set) {
            return payload;
          }
          return reasonSet;
        }),
        isSaveable: true,
      };

    case RECEIVE_ADD_RETURN_REASONS_SET:
      return {
        ...state,
        reasonSets: [...state.reasonSets, payload],
        isSaveable: false,
      };

    case RECEIVE_RETURN_REASONS_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: payload,
        },
      };

    case RECEIVE_RETURN_REASONS_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload,
        },
      };

    case RESET_RETURN_REASONS_FORM_AND_ERRORS:
      return {
        ...state,
        isSaveable: true,
        alert: {
          show: false,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export default returnReasonSetsReducer;
