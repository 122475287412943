import { push } from 'connected-react-router';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import { pascalizeReportName } from '~src/lib/helpers';
import { parseQueryFilters } from '~src/lib/monitor/filters';
import { createChartData } from '~src/lib/ChartData';

export const DISMISS_ALERT = 'REPORTING/DISMISS_ALERT';
export const LAUNCH_DASHBOARD_PAYLOAD_CONFIRMATION_MODAL =
  'REPORTING/LAUNCH_DASHBOARD_PAYLOAD_CONFIRMATION_MODAL';
export const LAUNCH_REPORTS_PAYLOAD_CONFIRMATION_MODAL =
  'REPORTING/LAUNCH_REPORTS_PAYLOAD_CONFIRMATION_MODAL';
export const RECEIVE_CHART_DATA = 'REPORTING/RECEIVE_CHART_DATA';
export const RECEIVE_FILTERS_DATA = 'REPORTING/RECEIVE_FILTERS_DATA';
export const RECEIVE_DASHBOARD = 'REPORTING/RECEIVE_DASHBOARD';
export const RECEIVE_DASHBOARDS = 'REPORTING/RECEIVE_DASHBOARDS';
export const RECEIVE_REPORT = 'REPORTING/RECEIVE_REPORT';
export const RECEIVE_REPORT_DEFINITIONS =
  'REPORTING/RECEIVE_REPORT_DEFINITIONS';
export const RECEIVE_DASHBOARD_AND_REPORTS =
  'REPORTING/RECEIVE_DASHBOARD_AND_REPORTS';
export const RECEIVE_DASHBOARD_DETAILS_ERROR =
  'REPORTING/RECEIVE_DASHBOARD_DETAILS_ERROR';
export const RECEIVE_SAVE_REPORT_ERROR = 'REPORTING/RECEIVE_SAVE_REPORT_ERROR';
export const RECEIVE_CREATE_REPORT = 'REPORTING/RECEIVE_CREATE_REPORT';
export const RECEIVE_CREATE_DASHBOARD = 'REPORTING/RECEIVE_CREATE_DASHBOARD';
export const RECEIVE_SAVE_REPORT = 'REPORTING/RECEIVE_SAVE_REPORT';
export const RECEIVE_SAVE_DASHBOARD = 'REPORTING/RECEIVE_SAVE_DASHBOARD';
export const RECEIVE_DELETE_DASHBOARD = 'REPORTING/RECEIVE_DELETE_DASHBOARD';
export const RESET_PAYLOAD_CONFIRMATION_STATE =
  'REPORTING/RESET_PAYLOAD_CONFIRMATION_STATE';
export const DISMISS_PAYLOAD_ERRORS = 'REPORTING/DISMISS_PAYLOAD_ERRORS';
export const UPDATE_DASHBOARD_DATES = 'REPORTING/UPDATE_DASHBOARD_DATES';

const compare = (a, b) => {
  const aDims = a.dimensions;
  const bDims = b.dimensions;
  const aDimKeys = Object.keys(aDims);
  const bDimKeys = Object.keys(bDims);

  if (aDims[aDimKeys[0]] === null) {
    return 1;
  } else if (bDims[bDimKeys[0]] === null) {
    return -1;
  } else if (aDims[aDimKeys[0]] < bDims[bDimKeys[0]]) {
    return -1;
  } else if (aDims[aDimKeys[0]] > bDims[bDimKeys[0]]) {
    return 1;
  } else if (aDims[aDimKeys[1]] < bDims[aDimKeys[1]]) {
    return -1;
  } else if (aDims[aDimKeys[1]] > bDims[aDimKeys[1]]) {
    return 1;
  }
  return 0;
};

const receiveChartData = (chartData, chartName, tableData) => ({
  type: RECEIVE_CHART_DATA,
  payload: { chartData, tableData },
  meta: { chartName },
});

export const fetchChartData = (queryParams, chartName, chartInfo) => (
  dispatch,
  getState,
) => {
  let { object } = getState().reporting;
  const name = `fetch${pascalizeReportName(chartName)}`;
  const query = { ...queryParams, dataset: '"analytics"' };

  // Remove unecessary keys from the request
  Object.keys(query).forEach(key => {
    if (
      key === 'id' ||
      key === 'title' ||
      key === 'type' ||
      key === 'referrer' ||
      !query[key].length
    ) {
      delete query[key];
    }
  });

  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name,
    path: ENDPOINTS.REPORTING,
    query,
  })
    .then(json => {
      if (json.error) {
        throw new Error(`${json.error}: ${json.msg}`);
      }

      // If we don't have the `object` from the report definition lets
      // get it from the query:
      object = object || query.object.replace(/"/g, '');
      const records = json[object].sort(compare);
      let chartData;

      if (chartInfo.type === 'single-metric') {
        let metricFilter;
        let dimension;
        let filter;

        if (chartInfo.metric_filter) {
          metricFilter = parseQueryFilters(chartInfo.metric_filter)[0];
          dimension = metricFilter.name.split('.')[1];
          filter = metricFilter.value;
        } else {
          dimension = queryParams.dimensions;
        }
        const { metrics } = records.find(
          r => r.dimensions[dimension] === filter,
        );
        const metricName = chartInfo.metric.split('.')[1]; // often undefined
        chartData = metricName
          ? metrics[metricName]
          : Object.values(metrics)[0];
      } else {
        chartData = createChartData(records, query, chartName, chartInfo);
      }

      dispatch(receiveChartData(chartData, chartName, records));
    })
    .catch(error => {
      console.error(
        `Error fetching chart data for ${chartName}:`,
        error,
      ); /* eslint-disable-line no-console */
    });
};

const receiveFiltersData = (filters, filterTypes, dimensions) => ({
  type: RECEIVE_FILTERS_DATA,
  payload: {
    filters,
    filterTypes,
    dimensions,
  },
});

// This is for moving the `values` out of the nested
// filter metadata response
const flattenFilters = filters => dispatch => {
  const flattenedFilters = [];

  // `filterTypes` we use to map display name back with
  // currently active filters
  const filterTypes = {};
  // `dimensions` is just an array of `filterTypes` for
  // <SearchableSelect />
  const dimensions = [];

  filters.forEach(filter => {
    const { display_name, key } = filter; /* eslint-disable-line camelcase */
    const groupKey = key;
    filterTypes[key] = {
      display_name,
    };
    dimensions.push({
      display_name,
      key,
    });
    const updatedFilter = { display_name, key, group: true };
    const filterValues = [];
    if (filter.values) {
      filter.values.forEach(value => {
        if (value) {
          filterValues.push({
            display_name: value,
            key: value,
            groupKey,
            group: false,
          });
        }
      });
    } else if (filter.type === 'calendar') {
      filterValues.push({
        ...updatedFilter,
        display_name: `${updatedFilter.display_name} Range`,
        group: false,
        groupKey: 'date_filters',
      });
    }
    updatedFilter.values = filterValues;
    flattenedFilters.push(updatedFilter);
  });

  dispatch(receiveFiltersData(flattenedFilters, filterTypes, dimensions));
};

export const fetchFilters = () => (dispatch, getState) => {
  const { object } = getState().reporting;
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchFilters',
    path: `${ENDPOINTS.FILTERS}/${object}/all?ignore_calendar=all`,
  })
    .then(json => {
      if (json[object]) {
        dispatch(flattenFilters(json[object]));
      }
    })
    .catch(err => console.error(err)); /* eslint-disable-line no-console */
};

export const fetchDashboard = dashboardId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: `fetchDashboard:${dashboardId}`,
    path: `${ENDPOINTS.DASHBOARD}/${dashboardId}`,
  })
    .then(json => {
      const {
        dashboard_definitions /* eslint-disable-line camelcase */,
        report_instances /* eslint-disable-line camelcase */,
        report_definitions /* eslint-disable-line camelcase */,
      } = json;

      dispatch({
        type: RECEIVE_DASHBOARD,
        payload: {
          dashboard_definitions,
          report_instances,
          report_definitions,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: RECEIVE_DASHBOARD_DETAILS_ERROR,
        error: error.errors,
      });
    });

export const fetchReport = reportId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchReport',
    path: `${ENDPOINTS.REPORT}/${reportId}`,
  })
    .then(json => {
      const {
        report_definitions, // eslint-disable-line camelcase
        report_instances, // eslint-disable-line camelcase
      } = json;
      dispatch({
        type: RECEIVE_REPORT,
        payload: {
          report_instances,
          report_definitions,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: RECEIVE_DASHBOARD_DETAILS_ERROR,
        error: error.errors,
      });
    });

// Probably should be the first thing called ever
export const fetchReportDefinitions = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchReportDefinitions',
    path: `${ENDPOINTS.REPORT_DEFINITIONS}/`,
  })
    .then(json => {
      if (json.response.length > 0) {
        dispatch({
          type: RECEIVE_REPORT_DEFINITIONS,
          payload: {
            // NOTE: We are assuming there is only *one* report
            // definition per tenant
            report_definitions: json.response[0],
          },
        });

        // We cannot fetch filters until we have
        // `object`
        dispatch(fetchFilters());
      }
    })
    .catch(err => console.error(err)); /* eslint-disable-line no-console */
};

export const fetchDashboards = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.DASHBOARD}?product=monitor`,
    name: 'fetchDashboards',
  })
    .then(json => {
      const {
        dashboard_definitions, // eslint-disable-line camelcase
        report_instances, // eslint-disable-line camelcase
      } = json; /* eslint-disable-line camelcase */
      dispatch({
        type: RECEIVE_DASHBOARDS,
        payload: {
          dashboard_definitions,
          report_instances,
        },
      });
    })
    .catch(error => {
      dispatch({
        type: RECEIVE_DASHBOARD_DETAILS_ERROR,
        error: error.errors,
      });
    });

const buildLayoutArray = cards => {
  const layout = [];
  let rowIndex = 0;
  cards.forEach(card => {
    const isNewReport = card.id.indexOf('EMPTY_REPORT') > -1;
    const id = isNewReport
      ? card.name.toLowerCase().replace(/ /g, '_')
      : card.id;

    // Also need to consider `single-metric` where
    // we fit as many as possible in first row
    if (card.fullWidth === true) {
      // Single column
      layout.push([
        {
          id,
        },
      ]);
      rowIndex += 1;
    } else if (layout[rowIndex] && layout[rowIndex].length < card.col) {
      // Second item in row
      layout[rowIndex].push({
        id,
      });
    } else {
      // First item in row, but `fullWidth` is false
      layout.push([
        {
          id,
        },
      ]);
    }
  });
  return layout;
};

// `Save` from confirmation modal
export const fetchSaveReports = (
  reports,
  dashboard,
  dashboardId,
) => dispatch => {
  Promise.all(
    reports.map((report, index) => {
      // Parse to catch any validation issues and also
      // get the `id`
      let parsedReport = {};

      try {
        parsedReport = JSON.parse(report);
      } catch (err) {
        dispatch({
          type: RECEIVE_SAVE_REPORT_ERROR,
          error: err.toString(),
          meta: {
            reportIndex: index,
          },
        });
        return 'error';
      }

      const isNewReport = parsedReport.id.indexOf('EMPTY_REPORT') > -1;
      const path = isNewReport
        ? ENDPOINTS.REPORT
        : `${ENDPOINTS.REPORT}/${parsedReport.id}`;

      if (isNewReport) {
        parsedReport.id = parsedReport.name.toLowerCase().replace(/ /g, '_');
      }

      return dispatch({
        type: API_REQUEST,
        method: isNewReport ? 'POST' : 'PUT',
        body: JSON.stringify(parsedReport),
        path,
        name: 'fetchSaveReports',
      }).catch(err => {
        // Throw and reject on server so we can handle in the catch below with the correct index
        throw { ...err, index }; /* eslint-disable-line no-throw-literal */
      });
    }),
  )
    .then(promisesJson => {
      let error = false;

      promisesJson.forEach((json, index) => {
        if (json === 'error') {
          error = true;
        } else {
          const isLastReport = index === reports.length - 1;
          const isNewReport = json.id.indexOf('EMPTY_REPORT') > -1;
          if (isLastReport && !error) {
            const layout = buildLayoutArray(dashboard.cards);
            dispatch({
              type: API_INVALIDATE_PATH,
              path: `${ENDPOINTS.DASHBOARD}/${dashboardId}`,
            });
            dispatch({
              type: isNewReport ? RECEIVE_CREATE_REPORT : RECEIVE_SAVE_REPORT,
              payload: json,
            });
            return dispatch({
              type: LAUNCH_DASHBOARD_PAYLOAD_CONFIRMATION_MODAL,
              payload: JSON.stringify(layout, null, 2),
            });
          }
          return dispatch({
            type: isNewReport ? RECEIVE_CREATE_REPORT : RECEIVE_SAVE_REPORT,
            payload: json,
          });
        }
        return null;
      });
    })
    .catch(error => {
      const { index, errors } = error;
      if (typeof index !== 'undefined') {
        return dispatch({
          type: RECEIVE_SAVE_REPORT_ERROR,
          error: errors.map(err => err.message)[0],
          meta: {
            reportIndex: index,
          },
        });
      }
      return dispatch({
        type: RECEIVE_SAVE_REPORT_ERROR,
        error: errors.map(err => err.message)[0],
        meta: {
          reportIndex: index,
        },
      });
    });
};

export const fetchSaveDashboard = (dashboardPayload, form, id) => dispatch => {
  const isNewDashboard = id.includes('EMPTY_DASHBOARD');
  const body = JSON.stringify({
    id: isNewDashboard ? form.name.toLowerCase().replace(/ /g, '_') : id,
    built_in: false,
    display_name: form.name,
    layout: JSON.parse(dashboardPayload),
    product: 'monitor',
  });
  return dispatch({
    type: API_REQUEST,
    method: isNewDashboard ? 'POST' : 'PUT',
    body,
    path: isNewDashboard
      ? ENDPOINTS.DASHBOARD_DEFINITIONS
      : `${ENDPOINTS.DASHBOARD_DEFINITIONS}/${id}`,
    name: 'fetchSaveDashboard',
  })
    .then(json => {
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.DASHBOARD}/${id}`,
      });
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.DASHBOARD}`,
      });
      dispatch({
        type: isNewDashboard
          ? RECEIVE_CREATE_DASHBOARD
          : RECEIVE_SAVE_DASHBOARD,
        payload: json,
      });
    })
    .catch(error => {
      dispatch({
        type: RECEIVE_DASHBOARD_DETAILS_ERROR,
        error: error.errors,
      });
    });
};

const buildReportPayload = report => (dispatch, getState) => {
  const { object } = getState().reporting;

  // Build filters query
  let filters = '';
  report.filters.forEach(({ name, value }, index) => {
    if (index !== report.filters.length - 1) {
      filters += `( "${name}" eq '${value}' ) and `;
    } else {
      filters += `( "${name}" eq '${value}' )`;
    }
  });

  let chart = {
    type: report.type.value,
    X: {
      axis_type: report.xAxisType ? report.xAxisType.value : null,
    },
    Y: {
      axis_type: report.yAxisType ? report.yAxisType.value : null,
    },
    segments: {},
  };

  if (report.type.value === 'single-metric') {
    chart = {
      type: 'single-metric',
      metric: '',
      metric_filter: '',
    };
  }

  const payload = {
    built_in: false,
    chart,
    dimensions: report.dimensions
      .filter(dimension => dimension && dimension.key)
      .map((d, index) => ({ field: d.key, order: index })),
    fields: null,
    id: report.id,
    metrics:
      report.metrics || report.operations
        ? [
            {
              field: report.metrics ? report.metrics.value : null,
              metric: report.operations ? report.operations.value : null,
              order: 1,
            },
          ]
        : [],
    precision: report.precision ? report.precision.value : '',
    name: report.name,
    report_definition_id: object,
    sorts: [],
    filters,
  };

  return dispatch({
    type: LAUNCH_REPORTS_PAYLOAD_CONFIRMATION_MODAL,
    payload: JSON.stringify(payload, null, 2),
  });
};

// From `save` in details view
// Build report payloads for modal
// saveReports -> buildReportPayload -> fetchSaveReport
export const saveReports = dashboard => dispatch => {
  const { cards: reports = [] } = dashboard;
  reports.forEach(report => {
    dispatch(buildReportPayload(report));
  });
};

export const resetPayloadConfirmation = () => ({
  type: RESET_PAYLOAD_CONFIRMATION_STATE,
});

export const dismissPayloadErrors = () => ({
  type: DISMISS_PAYLOAD_ERRORS,
});

export const fetchDeleteReport = id => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.REPORT}/${id}`,
    name: 'fetchDeleteReport',
  })
    .then(json =>
      dispatch({
        type: RECEIVE_SAVE_REPORT,
        payload: json,
      }),
    )
    .catch(error =>
      dispatch({
        type: RECEIVE_DASHBOARD_DETAILS_ERROR,
        error: error.errors,
      }),
    );

export const fetchDeleteDashboard = dashboardId => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.DASHBOARD_DEFINITIONS}/${dashboardId}`,
    name: 'fetchDeleteDashboard',
  })
    .then(() => {
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.DASHBOARD}`,
      });
      dispatch(fetchDashboards());
      dispatch(push('/monitor/dashboards/edit'));
    })
    .catch(err => console.error(err)); /* eslint-disable-line no-console */
};

export const receiveDeleteDashboard = id => ({
  type: RECEIVE_DELETE_DASHBOARD,
  meta: {
    id,
  },
});

export const updateDashDates = (startDate, endDate) => dispatch => {
  dispatch({
    type: UPDATE_DASHBOARD_DATES,
    payload: { startDate, endDate },
  });
};
