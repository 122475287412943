import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import apiReducer from './apiReducer';
import feedbackEngagementReducer from './feedbackEngagementReducer';
import returnsAnalyticsReducer from './returnsAnalyticsReducer';
import userReducer from './userReducer';
import experienceManagerReducer from './experienceManagerReducer';
import returnsRulesReducer from './returnsRulesReducer';
import tenantsReducer from './tenantsReducer';
import tenantReducer from './tenantReducer';
import broadcastMessageReducer from './broadcastMessageReducer';
import trackingAnalyticsReducer from './trackingAnalyticsReducer';
import uiReducer from './uiReducer';
import reporting from './reportingReducer';
import trackingCarrierCredentials from './trackingCarrierCredReducer';
import returnCarrierCredentialsReducer from './returnCarrierCredentialsReducer';
import packages from './packagesReducer';
import eddRulesDefinitionReducer from './eddRulesDefinitionReducer';
import distributionCenters from './distributionCenters';
import eddRulesInfoReducer from './eddRulesInfoReducer';
import shipSettingsReducer from './shipSettingsReducer';
import serviceCodesReducer from './serviceCodesReducer';
import trackSettingsReducer from './trackSettingsReducer';
import returnReasonSetsReducer from './returnReasonSetsReducer';
import courierStatusCodesReducer from './courierStatusCodesReducer';
import assetsManager from './assetsManagerReducer';
import emailTemplates from './emailTemplatesReducer';
import notifySettingsReducer from './notifySettingsReducer';
import packingSlipLabelReducer from './packingSlipLabelReducer';
import partnershipsSalesforceReducer from './partnershipsSalesforceReducer';
import partnershipsZendeskReducer from './partnershipsZendeskReducer';
import notifyStyling from './notifyStylingReducer';
import handoffs from './handoffsReducer';
import holidays from './platform/holidays';
import workingDays from './platform/workingDays';
import assistAdmin from './assistAdminReducer';
import assistConfiguration from './assistConfigurationReducer';
import assistSettings from './assistSettingsReducer';
import assistContent from './assist/content';
import carrierAliasReducer from './carrierAliasReducer';
import returnsDashboardAnalyticsReducer from './returnsDashboardAnalyticsReducer';
import returnsRestrictedEmailsReducer from './returnsRestrictedEmailsReducer';
import returnsSettingsReducer from './returnsSettingsReducer';
import returnsOrderListReducer from './returnsOrderListReducer';
import returnsUIBuilderReducer from './returnsUIBuilderReducer';
import eddPreview from './platform/eddPreview';
import wysiwygReducer from './wysiwygEditorReducer';
import messagingEmailDashboardAnalyticsReducer from './messagingEmailDashboardAnalyticsReducer';
import exceptionsAnalyticsReducer from './exceptionsAnalyticsReducer';
import exceptionsReportsReducer from './exceptionsReportsReducer';
import messagingSmsDashboardAnalyticsReducer from './messagingSmsDashboardAnalyticsReducer';
import partnershipsMagentoReducer from './partnershipsMagentoReducer';
import partnershipsSfccReducer from './partnershipsSfccReducer';
import partnershipsLomReducer from './partnershipsLomReducer';
import partnershipsBigcommerceReducer from './partnershipsBigcommerceReducer';
import shipAbTestDashboardAnalyticsReducer from './shipAbTestDashboardAnalyticsReducer';
import metabaseReducer from './metabaseReducer';
import entitlementReducer from './entitlementReducer';
import returnsBrandingReducer from './returnsBrandingReducer';
import shipMetabaseReducer from './shipMetabaseReducer';
import promiseAnalyticsDashboardReducer from './promiseAnalyticsDashboardReducer';
import promiseDataQualityReducer from './promiseDataQualityReducer';

export const createRootReducer = history => {
  const appReducer = combineReducers({
    apiReducer,
    assetsManager,
    assistAdmin,
    assistConfiguration,
    assistContent,
    assistSettings,
    broadcastMessageReducer,
    carrierAliasReducer,
    courierStatusCodesReducer,
    distributionCenters,
    eddPreview,
    eddRulesDefinitionReducer,
    eddRulesInfoReducer,
    emailTemplates,
    experienceManagerReducer,
    feedbackEngagementReducer,
    handoffs,
    holidays,
    notifySettingsReducer,
    notifyStyling,
    packages,
    packingSlipLabelReducer,
    partnershipsBigcommerceReducer,
    partnershipsSalesforceReducer,
    partnershipsZendeskReducer,
    partnershipsMagentoReducer,
    partnershipsSfccReducer,
    partnershipsLomReducer,
    reporting,
    returnCarrierCredentialsReducer,
    returnReasonSetsReducer,
    returnsAnalyticsReducer,
    returnsDashboardAnalyticsReducer,
    returnsOrderListReducer,
    returnsRestrictedEmailsReducer,
    returnsRulesReducer,
    returnsSettingsReducer,
    returnsUIBuilderReducer,
    router: connectRouter(history),
    serviceCodesReducer,
    shipSettingsReducer,
    tenantReducer,
    tenantsReducer,
    trackingAnalyticsReducer,
    trackingCarrierCredentials,
    trackSettingsReducer,
    uiReducer,
    wysiwygReducer,
    userReducer,
    workingDays,
    messagingEmailDashboardAnalyticsReducer,
    exceptionsAnalyticsReducer,
    exceptionsReportsReducer,
    messagingSmsDashboardAnalyticsReducer,
    shipAbTestDashboardAnalyticsReducer,
    metabaseReducer,
    entitlementReducer,
    returnsBrandingReducer,
    shipMetabaseReducer,
    promiseAnalyticsDashboardReducer,
    promiseDataQualityReducer,
  });

  return (state, action) => {
    if (action.type === 'RECEIVE_LOGOUT') {
      state = undefined; // eslint-disable-line no-param-reassign
    }

    return appReducer(state, action);
  };
};

export default createRootReducer;
