import {
  DISPLAY_ERROR,
  DISPLAY_SUCCESS,
  ADD_NEW_TOKEN,
  RECEIVE_TOKENS,
  RECEIVE_ZENDESK_VALID,
  RECEIVE_ZENDESK_CONFIG,
  RECEIVE_ZENDESK_ASSIST_TRIGGER,
  ZENDESK_RESET,
} from '~src/actions/partnershipsZendeskActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  accessTokens: [],
  isValid: false,
  host: '',
  secret: '',
  triggerId: null,
  readyForOauth: false,
};

function partnershipsZendeskReducer(
  state = initialState,
  {
    payload: {
      text = '',
      accessToken = '',
      accessTokens = [],
      host = '',
      isValid = false,
      triggerId = null,
    } = {},
    type,
  },
) {
  switch (type) {
    case DISPLAY_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };
    }
    case RECEIVE_TOKENS: {
      return {
        ...state,
        accessTokens,
      };
    }
    case ADD_NEW_TOKEN: {
      return {
        ...state,
        accessTokens: [...state.accessTokens, accessToken],
      };
    }
    case RECEIVE_ZENDESK_VALID: {
      return {
        ...state,
        isValid,
      };
    }
    case RECEIVE_ZENDESK_CONFIG: {
      return {
        ...state,
        host,
        readyForOauth: Boolean(host),
      };
    }
    case RECEIVE_ZENDESK_ASSIST_TRIGGER: {
      return {
        ...state,
        triggerId,
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    case ZENDESK_RESET: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}

export default partnershipsZendeskReducer;
