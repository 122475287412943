import { push } from 'connected-react-router';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';
import {
  createRequestData,
  flattenRetailerSettings,
} from '~src/reducers/experienceManagerHelper';
import { memorySizeOf } from '~src/lib/helpers';
import * as ENDPOINTS from '~src/constants/endpoints';
import qs from 'qs';

const SAVE_NEW_ASSET_SUCCESS_EVENT =
  'Scheduled Assets - Successfully Saved New Asset';
const SAVE_NEW_ASSET_FAILURE_EVENT =
  'Scheduled Assets - Failed to Save New Asset';
const SAVE_EXISTING_ASSET_SUCCESS_EVENT =
  'Scheduled Assets - Successfully Updated Existing Asset';
const SAVE_EXISTING_ASSET_FAILURE_EVENT =
  'Scheduled Assets - Failed to Update Existing Asset';
const PUBLISH_NEW_ASSET_SUCCESS_EVENT =
  'Scheduled Assets - Successfully Published New Asset';
const PUBLISH_NEW_ASSET_FAILURE_EVENT =
  'Scheduled Assets - Failed to Publish New Asset';
const PUBLISH_EXISTING_ASSET_SUCCESS_EVENT =
  'Scheduled Assets - Successfully Published Existing Asset Update';
const PUBLISH_EXISTING_ASSET_FAILURE_EVENT =
  'Scheduled Assets - Failed to Publish Existing Asset Update';

export const RECEIVE_IMAGES_LIST = 'RECEIVE_IMAGES_LIST';
export const RECEIVE_IMAGE = 'RECEIVE_IMAGE';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const RESET_ASSET_DATA = 'RESET_ASSET_DATA';
export const UPDATE_IMAGE_UPLOADER = 'UPDATE_IMAGE_UPLOADER';
export const RESET_ASSET_DETAILS = 'RESET_ASSET_DETAILS';
export const TOGGLE_IS_UPLOADING = 'TOGGLE_IS_UPLOADING';
export const RECIEVE_SAVE_IMAGE_SUCCESS = 'RECIEVE_SAVE_IMAGE_SUCCESS';
export const RECIEVE_PUBLISH_IMAGE_SUCCESS = 'RECIEVE_PUBLISH_IMAGE_SUCCESS';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const UPDATE_FORM_INPUT = 'UPDATE_FORM_INPUT';
export const SCHEDULED_ASSETS_CREATE_TOOLTIP_STATE =
  'SCHEDULED_ASSETS_CREATE_TOOLTIP_STATE';
export const SCHEDULED_ASSETS_TOGGLE_TOOLTIP =
  'SCHEDULED_ASSETS_TOGGLE_TOOLTIP';

const formRequestBody = form =>
  Object.keys(form).reduce((formGroup, key) => {
    const formValue = form[key];
    if (key === 'start_end_dates') {
      return {
        ...formGroup,
        [key]: {
          start: formValue.value_start,
          end: formValue.value_end,
        },
      };
    }
    return {
      ...formGroup,
      [key]: formValue.options
        ? formValue.options[formValue.selected].value
        : formValue.value,
    };
  }, {});

const mapCardComponents = card =>
  card.reduce(
    (cardGroup, component) =>
      component.type === 'title'
        ? { ...cardGroup, title: component.value }
        : { ...cardGroup, card: [...cardGroup.card, component] },
    { title: '', card: [] },
  );

const buildFormControls = (card, formState) =>
  card.reduce((newFormState, nextCardComponent) => {
    const { request_property: formProperty } = nextCardComponent;
    const formValue = formState[formProperty];
    const previousValue = formValue && (formValue.value || formValue.selected);

    if (!formProperty) return newFormState;

    // TODO Should improve the update flow to remove this hard coded 'click_category' logic
    return {
      ...newFormState,
      [formProperty]:
        previousValue && formProperty !== 'click_category'
          ? formValue
          : nextCardComponent,
    };
  }, {});

const getDisplaySchema = (section, formState) =>
  section.reduce(
    (cards, nextCard) => ({
      ...cards,
      schema: [...cards.schema, mapCardComponents(nextCard)],
      form: { ...cards.form, ...buildFormControls(nextCard, formState) },
    }),
    { form: {}, schema: [] },
  );

export const dismissAlert = () => ({
  type: DISMISS_ALERT,
});

export const receiveErrors = errors => ({
  type: RECEIVE_ERRORS,
  meta: { errors },
});

export const resetImageDetails = () => ({
  type: RESET_ASSET_DETAILS,
});

export const updateFormInput = (property, valueKey, value) => ({
  type: UPDATE_FORM_INPUT,
  payload: {
    property,
    valueKey,
    value,
  },
});

export const receiveImagesList = ListResponse => ({
  type: RECEIVE_IMAGES_LIST,
  payload: {
    ListResponse,
  },
});

export const receiveImage = (schema, property) => ({
  type: RECEIVE_IMAGE,
  payload: {
    schema,
    property,
  },
});

export const sendSegmentAnalytics = (eventName, value = {}) => (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: { id, username, retailerId },
    },
  } = getState();

  if (typeof analytics !== 'undefined') {
    analytics.track(eventName, {
      category: 'Hub Track Scheduled Assets',
      value: {
        ...value,
        id,
        username,
        retailerId,
      },
    });
  }
};

export const createTooltipState = allSchemas => dispatch => {
  const componentTooltips = allSchemas.reduce(
    (allSchemaCards, { card }) => [
      ...allSchemaCards,
      ...card.filter(({ tooltip }) => tooltip),
    ],
    [],
  );

  const tooltips = componentTooltips.reduce((allTooltips, { label }) => {
    const tooltipKey = label.replace(/ /g, '');

    return {
      ...allTooltips,
      [tooltipKey]: false,
    };
  }, {});

  dispatch({
    type: SCHEDULED_ASSETS_CREATE_TOOLTIP_STATE,
    tooltips,
  });
};

export const fetchMatchedImage = (id, param = null) => async (
  dispatch,
  getState,
) => {
  const path = `${ENDPOINTS.ASSETS_MANAGER_MATCHED_ASSET}${
    id !== 'new' ? `/${id}` : ''
  }${param ? `?${qs.stringify(param, { encode: false })}` : ''}`;

  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
      name: 'fetchMatchedImage',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    const {
      assetsManager: { assetForm },
    } = getState();
    const { response } = json;

    const { schema: leftSchema, form: leftForm } = getDisplaySchema(
      response.cards,
      assetForm,
    );

    const { schema: rightSchema, form: rightForm } = getDisplaySchema(
      response.sidebar,
      assetForm,
    );

    dispatch(createTooltipState([...leftSchema, ...rightSchema]));
    const assetFormControls = { ...leftForm, ...rightForm };

    dispatch(
      receiveImage(
        {
          left: leftSchema,
          right: rightSchema,
        },
        assetFormControls,
      ),
    );
  } catch (error) {
    dispatch(receiveErrors(error.errors[0].message));
  }
};

export const updateImageCondition = (property, index) => async (
  dispatch,
  getState,
) => {
  await dispatch(updateFormInput(property, 'selected', index));
  // Fire the API to fetch the new Cards and Side Panel UI form
  // based on the combination of Locale and Position
  if (property === 'position' || property === 'locale') {
    const {
      assetsManager: {
        assetForm: { position, locale },
      },
    } = getState();
    dispatch(
      fetchMatchedImage('new', {
        position: position.options[position.selected].value,
        locale: locale.options[locale.selected].value,
      }),
    );
  }
};

export const fetchImagesList = () => async dispatch => {
  const path = ENDPOINTS.ASSETS_MANAGER_LIST;
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
      name: 'fetchImagesList',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    dispatch(receiveImagesList(json.response));
  } catch (error) {
    dispatch(receiveErrors(error.errors[0].message));
  }
};

export const receiveSaveResponse = (previewUrl, dateModified) => ({
  type: RECIEVE_SAVE_IMAGE_SUCCESS,
  payload: {
    previewUrl,
    dateModified,
  },
});

export const saveImage = assetUrlParam => async (dispatch, getState) => {
  const {
    assetsManager: { assetForm },
  } = getState();
  const path = ENDPOINTS.ASSETS_MANAGER_MATCHED_ASSET;
  const requestBody = formRequestBody(assetForm);
  const segmentSuccessEventName =
    assetUrlParam === 'new'
      ? SAVE_NEW_ASSET_SUCCESS_EVENT
      : SAVE_EXISTING_ASSET_SUCCESS_EVENT;
  const segmentFailureEventName =
    assetUrlParam === 'new'
      ? SAVE_NEW_ASSET_FAILURE_EVENT
      : SAVE_EXISTING_ASSET_FAILURE_EVENT;

  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path,
      body: JSON.stringify(requestBody),
      name: 'fetchSaveImage',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    dispatch(
      receiveSaveResponse(
        json.response.preview_url,
        json.response.date_modified,
      ),
    );

    dispatch(sendSegmentAnalytics(segmentSuccessEventName, requestBody));
  } catch (error) {
    const errorMessage = error.errors[0].message;
    dispatch(receiveErrors(errorMessage));
    dispatch(sendSegmentAnalytics(segmentFailureEventName, { errorMessage }));
  }
};

export const receivePublishResponse = () => ({
  type: RECIEVE_PUBLISH_IMAGE_SUCCESS,
});

export const pulishImageChanges = (
  dateModified,
  locale,
  assetUrlParam,
) => async dispatch => {
  const path = `${ENDPOINTS.CSM_TRACKING_ASSETS_PUBLISH}?locale=${locale}`;
  const segmentSuccessEventName =
    assetUrlParam === 'new'
      ? PUBLISH_NEW_ASSET_SUCCESS_EVENT
      : PUBLISH_EXISTING_ASSET_SUCCESS_EVENT;
  const segmentFailureEventName =
    assetUrlParam === 'new'
      ? PUBLISH_NEW_ASSET_FAILURE_EVENT
      : PUBLISH_EXISTING_ASSET_FAILURE_EVENT;

  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path,
      body: JSON.stringify({ date_modified: dateModified }),
      name: 'fetchPublishImage',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });
    if (json.status === 'success') {
      dispatch(receivePublishResponse());
      dispatch(sendSegmentAnalytics(segmentSuccessEventName, { dateModified }));
    } else {
      dispatch(receiveErrors(json.errors[0].message));
    }
  } catch (error) {
    const errorMessage = error.errors[0].message;
    dispatch(receiveErrors(errorMessage));
    dispatch(sendSegmentAnalytics(segmentFailureEventName, { errorMessage }));
  }
};

export const updateImageUploader = (device, data) => ({
  type: UPDATE_IMAGE_UPLOADER,
  payload: { device, data },
});

export const toggleImageUploadingFlag = () => ({
  type: TOGGLE_IS_UPLOADING,
});

export const uploadAssetImage = (
  imageObject,
  fileName,
  device,
  locale,
) => async dispatch => {
  const path = `${ENDPOINTS.TRACKING_ASSETS_UPLOAD}?locale=${locale}&type=image`;
  const form = new FormData();

  dispatch(dismissAlert());
  form.append('file', imageObject, fileName);
  await dispatch(toggleImageUploadingFlag());

  const response = await dispatch({
    type: API_REQUEST,
    path,
    body: form,
    contentType: false,
    method: 'POST',
    name: 'uploadImageAsset',
  });

  if (response.status === 'success') {
    const { asset_url: uploadedAssetUrl } = response;
    dispatch(updateFormInput(`${device}_img`, 'value', uploadedAssetUrl));
    dispatch(toggleImageUploadingFlag());
    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });
  } else {
    dispatch(receiveErrors('Sorry, there was a problem uploading your image.'));
  }
};

export const resetAssetData = () => ({
  type: RESET_ASSET_DATA,
});

export const toggleTooltip = tooltipName => dispatch => {
  dispatch({
    type: SCHEDULED_ASSETS_TOGGLE_TOOLTIP,
    tooltipName,
  });
};
