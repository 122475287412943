import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { formatStringToTitle } from '../../lib/helpers';
import { SearchableSelect } from '../SearchableSelect';
import s from './Dimensions.styles';

export class Dimensions extends Component {
  static propTypes = {
    dimensions: PropTypes.arrayOf(PropTypes.object),
    isFetching: PropTypes.bool,
    onRemove: PropTypes.func,
    onSelect: PropTypes.func,
    selectedDimensions: PropTypes.arrayOf(PropTypes.object),
    ignoreHandleClickOutside: PropTypes.bool,
    isChartDetailView: PropTypes.bool,
    name: PropTypes.string,
    selectedMetrics: PropTypes.string,
    selectedPrecision: PropTypes.bool,
    removeDisabled: PropTypes.bool,
    disableFirst: PropTypes.bool,
  };

  static defaultProps = {
    dimensions: [],
    isFetching: false,
    onRemove: null,
    onSelect: null,
    selectedDimensions: [],
    ignoreHandleClickOutside: false,
    isChartDetailView: false,
    name: null,
    selectedMetrics: null,
    selectedPrecision: false,
    removeDisabled: false,
    disableFirst: false,
  };

  state = {
    searchResults: this.props.dimensions || [],
  };

  componentWillReceiveProps(nextProps) {
    const { dimensions } = this.props;
    if (dimensions.length === 0 && nextProps.dimensions.length > 0) {
      this.setState({
        searchResults: nextProps.dimensions,
      });
    }
  }

  handleRemove = index => {
    const { onRemove } = this.props;
    onRemove(index);
  };

  handleChangeSearch = value => {
    const { dimensions } = this.props;
    const searchResults = [];

    dimensions.forEach(dimension => {
      if (dimension.display_name.toLowerCase().startsWith(value.toLowerCase())) {
        searchResults.push(dimension);
      }
    });

    this.setState({
      searchResults,
    });
  };

  handleSelect = (event, selectName) => {
    const {
      dimensions,
      onSelect,
    } = this.props;
    const { searchResults } = this.state;
    const dimensionIndex = event.currentTarget.name;

    // Pass index in original items array
    const selectedDimensionIndex = dimensions.findIndex(item =>
      item.key === searchResults[dimensionIndex].key);

    onSelect(selectedDimensionIndex, selectName);
  };

  render() {
    const {
      ignoreHandleClickOutside,
      isChartDetailView,
      isFetching,
      name,
      selectedDimensions,
      selectedMetrics,
      selectedPrecision,
      removeDisabled,
      disableFirst,
    } = this.props;

    const { searchResults } = this.state;
    const metricsForDisplay = selectedMetrics
      ? formatStringToTitle(selectedMetrics)
      : '';
    return (
      <div className={cx(s, isChartDetailView ? 'is-chart-detail' : '')}>
        {metricsForDisplay &&
          <div className="item">
            <h4>Metric</h4>
            {metricsForDisplay}
          </div>}
        <div className={cx(ignoreHandleClickOutside && 'ignore-react-onclickoutside', 'item')}>
          {isChartDetailView && <h4>Dimension</h4>}
          <SearchableSelect
            items={searchResults}
            isFetching={isFetching}
            name={name ? `${name}/dimension1` : 'dimension1'}
            onCancel={this.handleRemove}
            onChange={this.handleChangeSearch}
            onSelect={this.handleSelect}
            placeholder="Select a dimension"
            readableKey="display_name"
            selected={selectedDimensions[0] || null}
            removeDisabled={(!selectedPrecision && removeDisabled) || disableFirst}
            disabled={disableFirst}
          />
        </div>
        {!selectedPrecision &&
          <div className="item">
            {isChartDetailView && <h4>Dimension</h4>}
            <SearchableSelect
              items={searchResults}
              isFetching={isFetching}
              name={name ? `${name}/dimension2` : 'dimension2'}
              onCancel={this.handleRemove}
              onChange={this.handleChangeSearch}
              onSelect={this.handleSelect}
              placeholder="Select a dimension"
              readableKey="display_name"
              selected={selectedDimensions[1] || null}
              removeDisabled={removeDisabled}
            />
          </div>}
      </div>
    );
  }
}

export default Dimensions;
