export const res = {
  'Google Places': ['source', 'business_type', 'store_name'],
  'Kate Spade Website': ['source', 'website_url'],
  '': ['source'],
};

export const placeholder = {
  source: 'e.g.  Google Places',
  business_type: 'e.g.  Department Store',
  store_name: 'e.g.  Peninsula',
  search_by: 'e.g.  Rank By',
  search_by_drilldown: 'e.g.  Distance or 100',
  website_url: 'e.g.  https://stores.penisula.com/maps/api/place',
};
