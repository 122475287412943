import {
  RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
  RESET_UPLOADED_PORTABLE_CONFIG,
  RECEIVE_SUPPORTED_LOCALES,
  RECEIVE_TEMPLATE_SETTINGS,
  RECEIVE_SETTINGS,
  RECEIVE_SETTING_SUCCESS,
  RECEIVE_SETTINGS_ERROR,
  RECEIVE_PREVIEW_INFO,
  RESET_SETTINGS,
  RECEIVE_SETTINGS_PUBLISH,
  RESET_ALERTS,
  RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS,
} from '~src/actions/returnsSettingsActions';
import { DISMISS_ALERT, DISMISS_ALERT_CARD } from '~src/actions/uiActions';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';

import { UPDATE_LOCALES } from '../graphql/mutations/updateReturns';

const initialState = {
  alert: {
    show: false,
  },
  alertCard: {
    show: false,
  },
  supportedLocales: [],
  defaultLocale: {},
  returnsSettings: {},
  returnsSettingsTemplate: {},
  language: {},
  languageTemplate: {},
  meta: {
    currentFirstName: '',
    currentLastName: '',
    currentUserEmail: '',
    returnsSettingsCreated: '',
    returnsSettingsModified: '',
  },
  uploadedPortableConfig: null,
  showUploadConfirmationModal: false,
  productAttributeDisplaySettings: {},
};

const returnsSettingsReducer = (
  state = initialState,
  {
    type,
    payload: {
      text,
      order,
      zip,
      url,
      email,
      confirmationEmail,
      returnsSettings,
      language,
      supportedLocales,
      defaultLocale,
      currentFirstName,
      currentLastName,
      currentUserEmail,
      manifest,
      returnsSettingsCreated,
      returnsSettingsModified,
      productAttributeDisplaySettings = {},
      warningNotice,
    } = {},
  },
) => {
  switch (type) {
    case UPDATE_LOCALES:
      return {
        ...state,
        defaultLocale,
        supportedLocales,
      };

    case RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE: {
      return {
        ...state,
        uploadedPortableConfig: manifest,
        showUploadConfirmationModal: true,
      };
    }

    case RESET_UPLOADED_PORTABLE_CONFIG:
      return {
        ...state,
        uploadedPortableConfig: initialState.uploadedPortableConfig,
        showUploadConfirmationModal: initialState.showUploadConfirmationModal,
      };

    case RECEIVE_SUPPORTED_LOCALES:
      return {
        ...state,
        supportedLocales,
      };

    case RECEIVE_SETTINGS:
      return {
        ...state,
        returnsSettings,
        language,
        meta: {
          ...state.meta,
          currentFirstName,
          currentLastName,
          currentUserEmail,
          returnsSettingsCreated,
          returnsSettingsModified,
        },
      };

    case RECEIVE_TEMPLATE_SETTINGS:
      return {
        ...state,
        returnsSettingsTemplate: returnsSettings,
        languageTemplate: language,
      };

    case RECEIVE_PREVIEW_INFO: {
      const withCredentials = order && (zip || email);
      return {
        ...state,
        alertCard: {
          type: 'success',
          show: true,
          title: withCredentials
            ? 'The preview link and credentials have been generated.'
            : 'The preview link has been generated',
          toggleText: 'Dismiss',
          header: withCredentials
            ? 'To view your Return experience, please use the URL and credentials listed below:'
            : 'To view your Return experience, please use the URL listed below:',
          order,
          zip,
          url,
          email,
          confirmationEmail,
          warningNotice,
        },
      };
    }

    case RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS: {
      return {
        ...state,
        productAttributeDisplaySettings: {
          ...state.productAttributeDisplaySettings,
          ...productAttributeDisplaySettings,
        },
      };
    }

    case RECEIVE_SETTING_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };

    case RECEIVE_SETTINGS_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };

    case RECEIVE_SETTINGS_PUBLISH:
      return {
        ...state,
        alertCard: {
          type: 'success',
          show: true,
          title: 'Your changes are published.',
          toggleText: 'Dismiss',
          header:
            'To view your Return experience, please use the URL listed below:',
          url,
          confirmationEmail,
          warningNotice,
        },
      };

    case RESET_SETTINGS:
      return {
        ...initialState,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case DISMISS_ALERT_CARD:
      return {
        ...state,
        alertCard: initialState.alertCard,
      };

    case RESET_ALERTS:
      return {
        ...state,
        alert: initialState.alert,
        alertCard: initialState.alertCard,
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default returnsSettingsReducer;
