exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".PWXb7rTzRVElAWdZpAQ0o .s2Tyqc9lUXIwEhTXDimsA {\n  width: 50%; }\n\n.PWXb7rTzRVElAWdZpAQ0o ._1uxqERqqxEO3kM4lQOJbc7 {\n  width: 48%;\n  display: inline-block; }\n  .PWXb7rTzRVElAWdZpAQ0o ._1uxqERqqxEO3kM4lQOJbc7 > div > p {\n    display: table; }\n\n.PWXb7rTzRVElAWdZpAQ0o ._3j5fgqLKQjXC2uMtHL_5ZB {\n  width: 48%;\n  display: inline-block;\n  margin-left: 10px; }\n", ""]);

// exports
exports.locals = {
	"NotifySettings": "PWXb7rTzRVElAWdZpAQ0o",
	"notify-half-width": "s2Tyqc9lUXIwEhTXDimsA",
	"sender-email-view": "_1uxqERqqxEO3kM4lQOJbc7",
	"static-domain-text": "_3j5fgqLKQjXC2uMtHL_5ZB"
};