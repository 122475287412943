import {
  DISMISS_ALERT,
  DISMISS_ADD_TENANT_ERROR,
  RECEIVE_TENANTS,
  RECEIVE_TENANTS_FOR_SEARCH,
  RECEIVE_TENANT_OPTIONS,
  RECEIVE_A_TENANT_OPTION,
  REQUEST_ADD_TENANT,
  RECEIVE_ADD_TENANT,
  RECEIVE_ADD_TENANT_ERROR,
} from '~src/actions/tenantsActions';

const initialState = {
  alert: {
    show: false,
  },
  errors: [],
  messages: [],
  newTenant: null,
  tenants: [],
  tenantOptions: {
    apis: [],
    carriers: [],
    dtFeatures: [],
    locales: [],
    products: [],
    types: [],
    carrier_services: [],
  },
  totalResults: 0,
  searchTenants: [],
};

function tenants(state = initialState, action) {
  const { meta, payload, type } = action;

  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case DISMISS_ADD_TENANT_ERROR:
      return {
        ...state,
        errors: [],
      };

    case RECEIVE_TENANTS:
      return {
        ...state,
        errors: [],
        newTenant: null,
        tenants: payload.tenants,
        totalResults: meta.totalResults,
      };

    case RECEIVE_TENANTS_FOR_SEARCH:
      return {
        ...state,
        searchTenants: payload.searchTenants,
      };

    case RECEIVE_TENANT_OPTIONS: {
      const { tenantOptions } = payload;

      return {
        ...state,
        tenantOptions: {
          ...state.tenantOptions,
          ...tenantOptions,
        },
      };
    }

    case RECEIVE_A_TENANT_OPTION: {
      const { tenantOption } = payload;

      return {
        ...state,
        tenantOptions: {
          ...state.tenantOptions,
          ...tenantOption,
        },
      };
    }

    case REQUEST_ADD_TENANT:
      return {
        ...state,
        errors: [],
      };

    case RECEIVE_ADD_TENANT_ERROR:
      return {
        ...state,
        errors: meta.errors,
        showLoaderOnAddTenantButton: false,
      };

    case RECEIVE_ADD_TENANT:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: ' has been added.',
          meta: {
            title: payload.retailer_name,
            url: `/admin/tenants/${payload.retailer_id}`,
          },
        },
        errors: [],
        newTenant: payload,
        showLoaderOnAddTenantButton: false,
      };

    default:
      return state;
  }
}

export default tenants;
