export const DISPLAY_ERROR = 'DISPLAY_ERROR_SFCC';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS_SFCC';
export const ADD_NEW_TOKEN = 'ADD_NEW_TOKEN_SFCC';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS_SFCC';

export const displayError = text => ({
  type: DISPLAY_ERROR,
  payload: { text },
});

export const displaySuccess = text => ({
  type: DISPLAY_SUCCESS,
  payload: { text },
});

export const addNewToken = accessToken => ({
  type: ADD_NEW_TOKEN,
  payload: { accessToken },
});

export const receiveTokens = accessTokens => ({
  type: RECEIVE_TOKENS,
  payload: { accessTokens },
});
