import qs from 'qs';

import neoHubDomain from './neoHubDomain';

const getPagesAndTrackType = ({
  rolesList: [
    {
      name = '',
      permissions: {
        company_management: companyMgmt,
        designer,
        tenant_mgmt: tenantMgmt,
        user_management: userMgmt,
      } = {},
    } = {},
  ] = [],
  customerSupportLogin,
  retailerId,
  retailerIdToRetailerInfo: { [retailerId]: { products } = {} } = {},
} = {}) => {
  const tenantsPage = tenantMgmt && tenantMgmt.list && !customerSupportLogin;

  const companyPage =
    (companyMgmt &&
      companyMgmt.all &&
      (name === 'OWNER' ||
        name === 'ADMIN' ||
        name === 'NARVAR_ADMIN' ||
        name === 'NARVAR_SUPER_ADMIN')) ||
    (userMgmt && userMgmt.admin);

  const assetsPageOrTrackExperiencesPage = designer && designer.all;

  const dynamicTrack =
    Array.isArray(products) && products.indexOf('Track Beta') > -1;

  return {
    tenantsPage,
    companyPage,
    assetsPageOrTrackExperiencesPage,
    dynamicTrack,
  };
};

const DYNAMIC_TRACK_QUERY_STRING = qs.stringify(
  { dynamicTrack: true },
  { addQueryPrefix: true },
);

export default (user, homepageEnabled) => {
  const isNarvarEmployee = user.rolesList.some(role =>
    role.name.startsWith('NARVAR_'),
  );

  if (
    homepageEnabled &&
    (isNarvarEmployee === false || user.customerSupportLogin === true)
  ) {
    return {
      external: false,
      url: '/home',
    };
  }

  const {
    tenantsPage,
    companyPage,
    assetsPageOrTrackExperiencesPage,
    dynamicTrack,
  } = getPagesAndTrackType(user);

  if (tenantsPage) {
    return {
      external: false,
      url: '/admin/tenants',
    };
  }

  if (companyPage) {
    return {
      external: false,
      url: '/admin/users',
    };
  }

  if (assetsPageOrTrackExperiencesPage) {
    return {
      external: dynamicTrack,
      url: dynamicTrack ? `${neoHubDomain()}/track/versions` : '/track/assets',
    };
  }

  return {
    external: false,
    url: `/track/analytics${dynamicTrack ? DYNAMIC_TRACK_QUERY_STRING : ''}`,
  };
};
