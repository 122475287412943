export default function entry(obj) {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i); // preallocate the Array
  while (i--) { // eslint-disable-line no-plusplus
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }

  return resArray;
}

if (!Object.entries) {
  Object.entries = entry;
}
