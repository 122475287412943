export const actionsDefinitions = [
  {
    category: 'return_dc',
    description:
      'Return to a specific destination created in Destination Center',
    display_value: 'Configured Destination',
    type: 'returns_a_value',
    return_value_type: 'picklist',
    values: [],
    dc_criteria: {
      result: {
        op: 'get_destination_by_id',
        id_expr: {
          op: 'literal<String>',
          value: '{{value}}',
        },
      },
    },
    overrides: {
      label: '',
    },
    meta: {
      element: {
        componentBottom: [
          '<p>You can add or edit destination details on the <a href="/promise/distribution-centers/add-dc" target="_blank">Destination Centers & Locations</a> page.</p>',
        ],
      },
    },
  },
  {
    category: 'address',
    description: 'Return to a specific address',
    display_value: 'Raw Address (only use in special cases)',
    type: 'returns_a_value',
    return_value_type: 'address',
    value: {},
    dc_criteria: {
      result: {
        op: 'literal<PolicyAddress>',
        value: '{{value}}',
      },
    },
    overrides: {
      label: '',
    },
  },
  {
    category: 'smart_dc',
    description:
      'Return to a specific destination created in Destination Center',
    display_value: '✨ Calculated Destination',
    type: 'returns_a_value',
    return_value_type: 'picklist',
    values: [
      {
        picklist_value: 'Nearest Distribution Center',
        picklist_token: '{"result":{"op":"get_nearest_destination"}}',
      },
      {
        picklist_value: 'Nearest Distribution Center within Country',
        picklist_token:
          '{"result":{"op":"get_nearest_destination","only_same_country_expr":{"op":"literal<Boolean>","value":true}}}',
      },
      {
        picklist_value: 'Nearest Retail Store',
        picklist_token: '{"result":{"op":"get_nearest_retail_store"}}',
      },
    ],
    dc_criteria: 'parseValue',
    overrides: {
      label: '',
    },
  },
  {
    category: 'return_to_same_vendor',
    display_value: 'Return to Same Vendor',
    type: 'returns_a_value',
    return_value_type: 'static_value',
    value: 'return_to_same_vendor',
    dc_criteria: {
      result: {
        json_path: '$.request.items[0].vendor',
        op: 'read_env',
        type: 'PolicyVendor',
      },
    },
    overrides: {
      hideLabel: true,
    },
  },
];
