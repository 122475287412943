import { RULE_TYPES } from '../../../constants/returns/returnRules';

export { fetchPublishRules } from './fetchPublish';
export { fetchDefinitions, receiveDefinitions } from './fetchDefinitions';
export { fetchRules, receiveRules } from './fetchRules';

export const policyItemKeys = [
  RULE_TYPES.RETURN_DESTINATION_POLICY,
  RULE_TYPES.RETURN_METHODS_POLICY,
  RULE_TYPES.RETURN_FEES_POLICY,
];
