import React from 'react';
import PropTypes from 'prop-types';
import { LanguageListItem, ListButton } from '../styled';

const LocaleOption = ({ locale, onOptionClick }) => {
  const languageNames = new Intl.DisplayNames([locale], {
    type: 'language',
  });

  const handleClick = () => {
    onOptionClick(locale);
  };

  return (
    <LanguageListItem>
      <ListButton type="button" onClick={handleClick}>
        <span>{languageNames.of(locale)}</span>
      </ListButton>
    </LanguageListItem>
  );
};

LocaleOption.propTypes = {
  locale: PropTypes.string,
  onOptionClick: PropTypes.func,
};

LocaleOption.defaultProps = {
  locale: '',
  onOptionClick: undefined,
};

export default LocaleOption;
