import deviceProfiler from '@narvar/device-profiler';

export default ({
  dataCallback,
  segmentTrack,
  segmentPage,
  snowplow,
  snowplowSchema,
  trackingFuncSuffix,
}) => {
  // default to server-side, set to `null` before `logEvent` fired below if on
  // browser client
  let deviceProfile = {};
  const eventQueue = [];

  const logEvent = (data) => {
    if (!deviceProfile) {
      eventQueue.push(data); // queue events until deviceProfile is resolved
      return;
    }

    const dataWithDeviceProfile = {
      ...deviceProfile,
      ...data,
    };

    if (dataCallback) dataCallback(dataWithDeviceProfile);

    if (segmentTrack) {
      if (data.event_type === 'page_load') {
        // `lvl_1` is page_name
        segmentPage(data.lvl_1_val, dataWithDeviceProfile);
      } else {
        segmentTrack(data.event_type, dataWithDeviceProfile);
      }
    }

    if (snowplow) {
      snowplow(
        `trackSelfDescribingEvent${trackingFuncSuffix ? `:${trackingFuncSuffix}` : ''}`,
        {
          data: dataWithDeviceProfile,
          schema: snowplowSchema,
        },
      );
    }
  };

  if (typeof window === 'object') {
    // wait for deviceProfile to load
    deviceProfile = null;
    deviceProfiler.then((dP) => {
      // once deviceProfile is resolved, log all events in queue
      deviceProfile = dP;
      eventQueue.forEach(data => logEvent(data));

      // reset eventQueue
      eventQueue.length = 0;
    });
  }

  return logEvent;
};
