const isValidMethod = method => method !== 'POST' && method !== 'PUT';

const isValidCached = ({ cached, method, contentType }) =>
  cached && cached.method === method && cached.contentType === contentType;

export default ({
  action: { query, method, contentType, path, name },
  queryString,
  apiState: {
    paths: { [path]: cacheKey } = {},
    isFetching: { [name]: isFetching } = {},
  },
}) => {
  const cached = cacheKey && query ? cacheKey[queryString] : cacheKey;

  if (
    isValidCached({ cached, method, contentType }) &&
    isValidMethod(method) &&
    isFetching === false
  ) {
    return cached;
  }
  return null;
};
