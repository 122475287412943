import React, { Component } from 'react';
import s from './HeadwayApp.styles';
import cx from 'classnames';
import Svg from '../Svg';

export class HeadwayApp extends Component {
  componentDidMount() {
    if (!window) {
      return false;
    }

    // if (window.HeadwayApp && !window.HeadwayApp.initialized) {
    //   window.HeadwayApp.init();
    // } else if (window.Headway && window.Headway.elements) {
    //   const el = document.getElementsByClassName('headwayapp')[0];
    //   el.appendChild(window.Headway.elements.badgeCont);
    // }
  }

  render() {
    const { isCustomerSupport } = this.props;

    return (
      <div
        className={cx(
          s,
          'headwayapp',
          isCustomerSupport ? 'is-customer-support' : ''
        )}
      >
        <Svg
          data-test={'icon-bell'}
          name={'bell'}
          width={'14'}
          height={'17'}
        />
      </div>
    );
  }
}

export default HeadwayApp;
