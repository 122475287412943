/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, isValidElement } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './TableCell.styles';
import { Markup } from 'interweave';

export class TableCell extends Component {
  constructor(props) {
    super(props);
    this.getDataCellValue = this.getDataCellValue.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    // This will get tricky with `children` but this is a costly component
    // to re-render constantly. We may end up comparing `this.props.children[i].key`
    // eslint-disable-next-line react/destructuring-assignment
    return this.props.data !== nextProps.data;
  }

  getDataCellValue() {
    const { data } = this.props;

    if (!data) return undefined;

    if (isValidElement(data) || typeof data !== 'object') return data;
    if (typeof data === 'object') return data.value ?? undefined;

    return undefined;
  }

  render() {
    const { children, data, dataIndex, width, isHtml } = this.props;
    const style = data && data.meta ? data.meta.style : {};
    let title;

    if (isValidElement(data)) {
      title = null;
    } else {
      title = data && data.type ? data.type : data;
    }

    const titleProps = title
      ? {
          'data-test': `${String(title).toLowerCase()}-row`,
          title,
        }
      : {};

    return (
      <td
        className={cx(s, `table-col-${dataIndex}`, 'ellipsis')}
        key={dataIndex}
        width={width ? `${(width / 12) * 100}%` : null}
        {...titleProps}
      >
        <div className="data-container">
          {isHtml ? (
            <Markup content={this.getDataCellValue()} className="data" />
          ) : (
            <span className="data" style={style}>
              {this.getDataCellValue()}
            </span>
          )}
          {children}
        </div>
      </td>
    );
  }
}

TableCell.defaultProps = {
  data: undefined,
  width: undefined,
  children: null,
  isHtml: undefined,
};

TableCell.propTypes = {
  dataIndex: PropTypes.number.isRequired,
  data: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  width: PropTypes.number,
  children: PropTypes.node,
  isHtml: PropTypes.bool,
};

export default TableCell;
