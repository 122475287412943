import moment from 'moment';
import { API_DATE_FORMAT } from '~src/constants/dateFormats';
import { API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import { RETURNS } from '~src/constants/reports';
import { pascalizeReportName } from '~src/lib/helpers';

export const RECEIVE_RETURNS_ANALYTICS = 'RECEIVE_RETURNS_ANALYTICS';

export const receiveReturnsAnalytics = json => {
  return {
    type: RECEIVE_RETURNS_ANALYTICS,
    payload: json,
  };
};

export const fetchReturnsAnalytics = (startDate, endDate) => dispatch => {
  const body = JSON.stringify({
    searchContext: {
      dateFilter: {
        startDate,
        endDate,
      },
    },
  });

  Object.keys(RETURNS).forEach(report => {
    const name = `fetch${pascalizeReportName(report)}`;

    dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: `${ENDPOINTS.RETURNS_ANALYTICS}${report}`,
      body,
      name,
    }).then(json => {
      dispatch(receiveReturnsAnalytics(json.reports));
    });
  });
};
