import { API_REQUEST } from '~src/lib/api';
import { INVALIDATE_CACHE_TABLE } from '~src/constants/endpoints';

// type = 'add' || 'remove' || 'update'
export const invalidateCacheTable = opts => dispatch => {
  const { type, table, primary_key, key_value } = opts;

  const body = JSON.stringify({
    type,
    table,
    primary_key,
    key_value,
  });
  return dispatch({
    type: API_REQUEST,
    method: 'POST',
    body,
    path: `${INVALIDATE_CACHE_TABLE}`,
    name: 'invalidateCacheTable',
  });
};
