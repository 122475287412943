import { css } from 'emotion';

export const labelStyles = css`
  font-family: circular-bold, sans-serif;
  display: inline-block;
  margin-bottom: 10px;
  min-width: 150px;
  width: 100%;
`;

export const formStyles = css`
  [class$='indicatorSeparator'],
  [class$='indicatorContainer'] {
    display: none;
  }

  [class$='IndicatorsContainer'] {
    padding-right: 10px;
    &:after {
      display: inline-block;
      vertical-align: 0.255em;
      content: '';
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      color: #2c3239;
      opacity: 0.4;
      margin: auto 0 auto auto;
      transition: all 100ms;
    }
  }

  &:hover [class$='IndicatorsContainer']:after {
    opacity: 1;
  }
`;
