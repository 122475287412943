import { push } from 'connected-react-router';
import * as ENDPOINTS from '../constants/endpoints';
import { API_REQUEST, API_INVALIDATE_PATH } from '../lib/api';
import uuid from '../lib/uuid';
import {
  MESSAGING_LITE_EMAILS,
  MESSAGING_STANDARD_EMAIL,
  MESSAGING_STANDARD_SMS,
  TIER_TYPE,
} from '../constants/messaging/engage';

export const DISMISS_ALERT = 'DISMISS_ALERT';
export const REQUEST_TENANTS = 'REQUEST_TENANTS';
export const RECEIVE_TENANTS = 'RECEIVE_TENANTS';
export const RECEIVE_TENANTS_FOR_SEARCH = 'RECEIVE_TENANTS_FOR_SEARCH';
export const REQUEST_TENANT_OPTIONS = 'REQUEST_TENANT_OPTIONS';
export const RECEIVE_TENANT_OPTIONS = 'RECEIVE_TENANT_OPTIONS';
export const RECEIVE_A_TENANT_OPTION = 'RECEIVE_A_TENANT_OPTION';
export const REQUEST_ADD_TENANT = 'REQUEST_ADD_TENANT';
export const RECEIVE_ADD_TENANT = 'RECEIVE_ADD_TENANT';
export const RECEIVE_ADD_TENANT_ERROR = 'RECEIVE_ADD_TENANT_ERROR';
export const DISMISS_ADD_TENANT_ERROR = 'DISMISS_ADD_TENANT_ERROR';
export const RECEIVE_TIER_SETUP_SUCCESS = 'RECEIVE_TIER_1_SETUP_SUCCESS';
export const RECEIVE_TIER_SETUP_ERROR = 'RECEIVE_TIER_1_SETUP_ERROR';

export const dismissAlert = () => ({ type: DISMISS_ALERT });
export const dismissAddTenantError = () => ({ type: DISMISS_ADD_TENANT_ERROR });

export const receiveTenants = json => {
  return {
    meta: {
      totalResults: json.total_records,
    },
    payload: {
      tenants: json.content,
    },
    type: RECEIVE_TENANTS,
  };
};

export const receiveTenantsForSearch = json => {
  return {
    payload: {
      searchTenants: json.content,
    },
    type: RECEIVE_TENANTS_FOR_SEARCH,
  };
};

export const receiveAddTenantError = errors => {
  return {
    type: RECEIVE_ADD_TENANT_ERROR,
    meta: {
      errors,
    },
  };
};

export const fetchTenantsForSearch = ({
  search = '',
  current_page_index: currentPageIndex = 0,
  records_per_page: recordsPerPage = 20,
}) => dispatch => {
  const query = {
    search,
    current_page_index: currentPageIndex ? currentPageIndex - 1 : 0,
    records_per_page: recordsPerPage,
  };

  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TENANTS,
    name: 'fetchTenantsForSearch',
    query,
  }).then(
    json => {
      // tenants retrieved for SearchSelect are stored in a different prop
      dispatch(receiveTenantsForSearch(json));
    },
    err => console.error(err),
  );
};

export const fetchTenants = ({
  search = '',
  current_page_index: currentPageIndex = 0,
  records_per_page: recordsPerPage = 20,
}) => dispatch => {
  const query = {
    search,
    current_page_index: currentPageIndex ? currentPageIndex - 1 : 0,
    records_per_page: recordsPerPage,
  };
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TENANTS,
    name: 'fetchTenants',
    query,
  }).then(
    json => {
      dispatch(receiveTenants(json));
    },
    err => console.error(err),
  );
};

export const receiveTenantOptions = tenantOptions => {
  return {
    type: RECEIVE_TENANT_OPTIONS,
    payload: {
      tenantOptions,
    },
  };
};

export const receiveATenantOption = tenantOption => {
  return {
    type: RECEIVE_A_TENANT_OPTION,
    payload: {
      tenantOption,
    },
  };
};

const getFeatures = (featureManagement, key) => {
  const featureList = featureManagement.find(f => f.key === key);
  return featureList ? featureList.features : [];
};

export const fetchTenantOptions = () => dispatch => {
  const query = {
    option_name: 'all',
  };
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TENANTS_OPTIONS}`,
    query,
    name: 'fetchTenantOptions',
  })
    .then(json => {
      const tenantOptions = {
        apis: json.apis,
        carriers: json.carriers,
        locales: json.locales,
        feature_management: json.feature_management,
        dtFeatures: getFeatures(json.feature_management, 'dynamic_track'),
        messagingFeatures: getFeatures(json.feature_management, 'messaging'),
        products: json.products,
        types: json.types.map(type => {
          return {
            key: type,
            display_name: type,
          };
        }),
      };

      dispatch(receiveTenantOptions(tenantOptions));
    })
    .catch(err => {
      console.error(err);
    });
};

export const fetchATenantOption = query => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TENANTS_OPTIONS}`,
    query,
    name: 'fetchATenantOption',
  })
    .then(json => {
      const tenantOption = {
        carrier_services: Array.isArray(json.carrier_services)
          ? json.carrier_services
              .sort((a, b) => a.carrier_id.localeCompare(b.carrier_id))
              .reduce((acc, carrierService) => {
                const carrierServiceObj = { ...carrierService };
                const sortedServices = carrierServiceObj.services.sort((a, b) =>
                  a.description.localeCompare(b.description),
                );
                carrierServiceObj.services = sortedServices;
                acc.push(carrierServiceObj);
                return acc;
              }, [])
              .reduce((acc, carrierService) => {
                const { services } = carrierService;
                const serviceMapping = services.map(service => ({
                  carrier: carrierService.carrier_id,
                  id: service.id,
                  key: service.code,
                  display_name: `${carrierService.carrier_id.toUpperCase()} ${
                    service.description
                  }`,
                }));
                acc.push(...serviceMapping);
                return acc;
              }, [])
          : [],
      };

      dispatch(receiveATenantOption(tenantOption));
    })
    .catch(err => {
      console.error(err);
    });
};

export const receiveAddTenant = payload => {
  return {
    type: RECEIVE_ADD_TENANT,
    payload,
  };
};

export const fetchAddTenant = (tenantOptions, isPnP = false) => async (
  dispatch,
  getState,
) => {
  const { userReducer: { user: { email, id } = '' } = {} } = getState();

  dispatch({
    type: REQUEST_ADD_TENANT,
  });

  const shouldRunTierSetup = tenantOptions.products.some(product =>
    [
      MESSAGING_LITE_EMAILS,
      MESSAGING_STANDARD_EMAIL,
      MESSAGING_STANDARD_SMS,
    ].includes(product),
  );
  let retailerId = '';

  const productPlans = tenantOptions.product_plans;
  const selectedProducts = Object.keys(productPlans).filter(
    key => productPlans[key].selected,
  );
  const selectedProductPlans = selectedProducts.map(
    product => productPlans[product].selectedPlan,
  );
  const selectedProductAddons = selectedProducts
    .flatMap(product => productPlans[product].addons)
    .filter(addon => addon.selected)
    .map(addon => addon.id);

  try {
    const addTenantResponse = await dispatch({
      type: API_REQUEST,
      body: JSON.stringify({
        tenant_add: {
          tenant: {
            retailer_name: tenantOptions.retailer_name,
            web_url: tenantOptions.web_url,
            shopify_url: '',
            uri_moniker: tenantOptions.uri_moniker,
            feature_management: tenantOptions.feature_management,
            types: tenantOptions.types,
            products: isPnP ? [] : tenantOptions.products,
            default_locale: tenantOptions.default_locale,
            carriers: tenantOptions.carriers,
            locales: tenantOptions.locales,
            enabled: true,
            assist_type: tenantOptions.assist_type,
            parent_tenant_id: tenantOptions.parentId,
            salesforce_account_id: tenantOptions.salesforce_account_id,
            carrier_services: tenantOptions.carrier_services,
            hipaa_compliance: tenantOptions.hipaa_compliance,
            entitlement_plans: selectedProductPlans,
            entitlement_addons: selectedProductAddons,
          },
        },
      }),
      method: 'POST',
      path: ENDPOINTS.TENANTS,
      name: 'fetchAddTenant',
    });

    if (addTenantResponse.status.toLowerCase() === 'success') {
      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.TENANTS,
      });
      if (!shouldRunTierSetup) {
        dispatch(fetchTenants({ current_page_index: 0, records_per_page: 20 }));
        dispatch(push('/admin/tenants'));
      }
      dispatch(receiveAddTenant(addTenantResponse.tenant));
    } else {
      throw addTenantResponse;
    }

    if (shouldRunTierSetup) {
      retailerId = addTenantResponse.tenant.retailer_id;
      const tiers = tenantOptions.products
        .map(p => TIER_TYPE[p])
        .filter(p => !!p);
      const engageTierSetupResponse = await dispatch({
        type: API_REQUEST,
        body: JSON.stringify({
          user_id: id,
          user_email: email,
          tracer_id: uuid(),
          tiers,
          locales: tenantOptions.locales,
          retailer: tenantOptions.retailer_name,
        }),
        method: 'POST',
        path: ENDPOINTS.ENGAGE_TIER_SETUP(tenantOptions.uri_moniker),
        name: 'engageTierSetup',
      });

      if (engageTierSetupResponse.status_code === 200) {
        dispatch(push(`/admin/tenants/${retailerId}`));
        dispatch({ type: RECEIVE_TIER_SETUP_SUCCESS });
      } else {
        throw engageTierSetupResponse;
      }
    }
  } catch (error) {
    console.error(error);
    if (error.messages) {
      dispatch(push(`/admin/tenants/${retailerId}`));
      dispatch({
        type: RECEIVE_TIER_SETUP_ERROR,
        payload: (error.messages || []).map(e => e.message),
      });
    } else {
      dispatch(receiveAddTenantError(error.errors));
    }
  }
};
