import HumNavigation from '@narvar/hum-navigation';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useGrowthBook } from '@growthbook/growthbook-react';

import { toggleNavigation } from '../../actions/uiActions';
import { fetchSwitchTenant } from '../../actions/userActions';

const Navigation = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation('hum');

  const handleToggle = () => dispatch(toggleNavigation());
  const handleSelectRetailer = retailerInfo => {
    handleToggle();
    dispatch(fetchSwitchTenant(retailerInfo.retailer_id));
  };

  const growthbook = useGrowthBook();

  const {
    user,
    reporting: {
      dashboards,
      object: reportDefinitionName,
      reportDefinitions: { [reportDefinitionName]: reportDefinition } = {},
    } = {},
    exceptionsFeatureEnabled,
    open,
  } = useSelector(
    ({ userReducer, reporting, exceptionsAnalyticsReducer, uiReducer }) => ({
      user: userReducer.user,
      isCsm: userReducer.user ? userReducer.user.customerSupportLogin : false,
      retailerId: userReducer.user ? userReducer.user.retailerId : undefined,
      retailerIdToRetailerInfo: userReducer.user
        ? userReducer.user.retailerIdToRetailerInfo
        : {},
      reporting,
      open: uiReducer.isNavOpen,
      exceptionsFeatureEnabled:
        exceptionsAnalyticsReducer.exceptionsFeatureEnabled,
    }),
  );

  return (
    <HumNavigation
      user={user}
      isOpen={open}
      reportDefinition={reportDefinition}
      dashboards={dashboards}
      exceptionsFeatureEnabled={exceptionsFeatureEnabled}
      drawerType="temporary"
      handleToggle={handleToggle}
      switchRetailer={handleSelectRetailer}
      isNeohub={false}
      i18n={t('navigation', { returnObjects: true })}
      growthbook={growthbook}
    />
  );
};

Navigation.propTypes = {};
Navigation.displayName = 'HubNavigation';

export default Navigation;
