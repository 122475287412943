// Broadcast Message
export const GLOBAL_BROADCAST_MESSAGE = '/api/global_broadcast_message';
export const BROADCAST_MESSAGE = '/api/broadcast_message';

// Tracking Analytics
export const TRACKING_ANALYTICS = '/api/report/v1';
export const TRACK_SEGMENTS = '/api/report/v1/segments';
export const TRACKING_LIVE_PREVIEW_1 = '//narvar.com/tracking/';
export const TRACKING_LIVE_PREVIEW_2 =
  '/narvar-speedee?tracking_numbers=1Z12345E0390515214';
export const TRACKING_ANALYTICS_EXCEL_ENDPOINT = '/api/report/v1/spreadsheetV2';
export const FEEDBACK_DRILLDOWN_ENDPOINT =
  '/track/analytics/feedback-engagement';
export const FEEDBACK_ENGAGEMENT_DRILL_DOWN = 'FEEDBACK_ENGAGEMENT_DRILL_DOWN';

// removing notification analytics on dt page so don't want to fetch them
export const REPORTS_DYNAMIC_TRACK = {
  DASHBOARD_TOP_BAR: 'DASHBOARD_TOP_BAR',
  FEEDBACK_ENGAGEMENT_RATING: 'FEEDBACK_ENGAGEMENT_RATING',
  FEEDBACK_ENGAGEMENT_TOP_KEYWORDS_IN_COMMENTS:
    'FEEDBACK_ENGAGEMENT_TOP_KEYWORDS_IN_COMMENTS',
  MARKETING_ASSETS_PERFORMANCE: 'MARKETING_ASSETS_PERFORMANCE',
  MARKETING_ENGAGEMENT_CLICKS_DAILY: 'MARKETING_ENGAGEMENT_CLICKS_DAILY',
  MARKETING_ENGAGEMENT_BY_FEATURE: 'MARKETING_ENGAGEMENT_BY_FEATURE',
  MOST_VISITED_STATUS_V1: 'MOST_VISITED_STATUS_V1',
  SUPPORT_ENGAGEMENT: 'SUPPORT_ENGAGEMENT',
  SUPPORT_CHAT_ENGAGEMENT: 'SUPPORT_CHAT_ENGAGEMENT',
};

export const REPORTS = {
  ...REPORTS_DYNAMIC_TRACK,
  TOTAL_OPTINS: 'TOTAL_OPTINS',
  UNIQUE_OPTINS: 'UNIQUE_OPTINS',
  OPTINS_BY_USER: 'OPTINS_BY_USER',
  OPTIN_BREAKDOWN: 'OPTIN_BREAKDOWN',
};

//  Messaging Email Analytics
export const MESSAGING_EMAIL_ANALYTICS_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/email_engagement';
export const MESSAGING_EMAIL_ANALYTICS_REPORTING = '/reporting';

// Returns Analytics
export const RETURNS_ANALYTICS = '/api/report?report=';

// Returns Analytics V2
export const RETURNS_ANALYTICS_DASHBOARD = '/api/dashboard-instances';
export const RETURNS_ANALYTICS_REPORT = '/api/report-instances';
export const RETURNS_ANALYTICS_REPORTING = '/reporting';
export const RETURNS_ANALYTICS_REPORT_DEFINITIONS = '/api/report-definitions';

// Order and Shipment Analytics
export const DASHBOARD = '/api/dashboard-instances';
export const DASHBOARD_DEFINITIONS = '/api/dashboard-definitions';
export const REPORT = '/api/report-instances';
export const REPORT_DEFINITIONS = '/api/report-definitions';
export const REPORTING = '/reporting';
export const FILTERS = '/reporting/metadata';
export const REPORTING_ROWS = '/reporting/rows';
export const REPORTING_ROWS_DOWNLOADS = '/reporting/downloads/rows';
export const ATLAS_ORDER = '/api/proxy/atlas/order';

//  Ship A/B Test Analytics
export const SHIP_AB_TEST_ANALYTICS_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/ship_js_ab_test';
export const SHIP_AB_TEST_ANALYTICS_REPORTING = '/reporting';

// Promise Analytics
export const PROMISE_ANALYTICS_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/promise_analytics';

// Promise Data Quality
export const PROMISE_ORDER_DATA_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/promise_data_quality';
export const PROMISE_SHIPMENT_DATA_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/promise_shipment_quality';

// Users
export const SSO_LOGIN = '/sso/zendesk/login';
export const USERS = '/api/users';
export const CHANGE_USER_RETAILER = `${USERS}/signedin/change-user-retailer`;
export const SIGNUP = `${USERS}/signup`;
export const SIGNED_IN = `${USERS}/signedin`;
export const CHANGE_PASSWORD = 'change-password';
export const UPDATE_FORGOT_PASSWORD = '/updateForgotPassword';
export const SELF = '/api/users/self';
export const ROLES = '/api/roles/assignable';

// Tenants
export const CUSTOMER_SUPPORT_LOGIN = '/api/admin/user/customerSupportLogin';
export const SWITCHBACK = '/api/admin/user/switchback';
export const TENANTS = '/api/tenants';
export const TENANTS_OPTIONS = '/api/tenants/v1/options';

// Entitlements
export const ENTITLEMENT_PRODUCTS = '/api/tenants/entitlement/products';
export const TENANT_ENTITLEMENTS = retailerId =>
  `/api/tenants/${retailerId}/entitlements`;

// Company
export const COMPANY = '/api/company';

// Experience Manager
export const TRACKING_ASSETS_UPLOAD = '/api/assets/designer/tracking/assets';
export const CSM_TRACKING_ASSETS_SAVE = '/api/designer/tracking';
export const CARRIER_CONFIGURATION_DELETE = '/api/designer/tracking/delete';
export const TRACKING_ASSETS_SAVE = '/api/assets/designer/tracking';
export const CSM_TRACKING_ASSETS_PUBLISH = '/api/designer/tracking/publish';
export const TRACKING_ASSETS_PUBLISH = '/api/assets/designer/tracking/publish';

// Asset Manager
export const ASSETS_MANAGER_LIST = '/api/v1/assets/designer/tracking/list';
export const ASSETS_MANAGER_MATCHED_ASSET = '/api/v1/assets/designer/tracking';

// Tracking Image
export const TRACKING_IMG_SETTINGS_SAVE = '/api/designer/trackingimg';
export const TRACKING_IMG_SETTINGS_PUBLISH =
  '/api/designer/trackingimg/publish';

// Shopify
export const SHOPIFY_RECORDS = '/api/shopify-records';

// Returns Rules
export const RETURNS_RULES_DEFINITIONS =
  '/api/designer/returns/rules/definition';
export const RETURNS_RULES = '/api/designer/returns/rules';

// Destinations
export const RETURN_DESTINATION_POLICYS_POLICY = '/api/destinations/policies';

// Carrier Credentials
export const CARRIER_CONFIGURATION = '/api/carriers/products';
export const CARRIERS = '/api/carriers/';
export const TRACKING_CARRIER_CREDENTIALS = '/api/carriers/tracking';

// Carrier Push
export const CARRIER_PUSH_BATCH = '/api/toran/v1/api/v1/carrier-data/batch';

// Platform EDD Rules and Definitions
export const EDD_RULES_DEFINITION =
  '/api/toran/v1/platform/edd/rulesDefinition';
export const EDD_RULES_INFO = '/api/toran/v1/platform/edd/rulesInfo';

// Platform EDD Preview
export const EDD_PREVIEW = '/api/toran/v1/platform/edd/preview';
export const EDD_PREVIEW_DEFINITION = `${EDD_PREVIEW}/definition`;
export const EDD_PREVIEW_DETAILS = `${EDD_PREVIEW}/details`;

// Distribution Centers
export const DISTRIBUTION_CENTERS = '/api/toran/v1/ship/distributionCenters';
export const DISTRIBUTION_CENTER = '/api/toran/v1/ship/distributionCenter';
export const DISTRIBUTION_CENTER_COSTS = '/api/toran/v1/ship/dc/costs';
export const DISTRIBUTION_CENTERS_CSV = '/api/toran/v1/ship/dc/csv';
export const COPY_DISTRIBUTION_CENTERS = '/api/toran/v1/ship/dc/syncDcs';
export const SHIP_DASHBOARD = '/api/toran/v1/ship/dashboards';

export const ITEMS_FULLFILMENT_COSTS = '/api/toran/v1/ship/items_costs';
export const ITEMS_FULLFILMENT_COSTS_CSV = '/api/toran/v1/ship/items_costs/csv';

// Carrier Alias
export const CARRIER_ALIAS = '/api/carrieralias/';

// Ship Settings
export const SHIP_SETTINGS = '/api/toran/v1/ship/settings';
export const UNKNOWN_ORIGIN = '/api/toran/v1/ship/settings/unknownOrigin';
export const REFRESH_EDD_CACHE = '/api/toran/v1/ship/refresh_cache';
// Retailer Configurations
export const RETAILER_CONFIGURATIONS_LIST =
  '/api/designer/tracking/configurations';

// Track settings
export const TRACK_SETTINGS = '/api/track/settings/edd';
export const TRACK_SETTINGS_FILE_UPLOAD = '/api/track/settings/zipstntupload';

// Store Loctaor settings
export const STORE_LOCATOR_CONFIGURATION = '/api/storelocator/tracking';

// Connect settings
export const CONNECT_VALIDATE_FACEBOOK =
  '/api/node/v1/hub/connect/auth/facebook';
export const CONNECT_VALIDATE_MESSAGE_SERVICE =
  '/api/node/v1/hub/connect/auth/messageService';
export const CONNECT_SETTINGS = '/api/node/v1/hub/connect/settings';
export const NOTIFY_FACEBOOK = `${CONNECT_SETTINGS}/facebook`;
export const NOTIFY_MESSAGE_SERVICE = `${CONNECT_SETTINGS}/messageService`;
export const CONNECT_GET_SETTINGS_USER_NAME = '/api/node/v1/hub/user/fullName';
export const FACEBOOK_EDD_IMAGE_SAVE = '/trackingimg/facebook';
export const NOTIFY_EMAIL_CHANNEL = `${CONNECT_SETTINGS}/emailChannel`;
export const NOTIFY_EMAIL_CHANNEL_SENDER = `${CONNECT_SETTINGS}/emailChannel/sender`;
export const NOTIFY_EMAIL_CHANNEL_CREATE_GENERATE =
  '/api/node/v1/hub/connect/emailChannel/create';

// Partnerships
export const PARTNERSHIPS_TOKEN = ({ retailerMoniker, provider }) =>
  `/api/toran/v1/partnerships/${retailerMoniker}/${provider}/credentials`;
export const PARTNERSHIPS_ZENDESK_HOST = ({ retailerMoniker }) =>
  `/api/toran/v1/partnerships/${retailerMoniker}/zendesk/host`;
export const PARTNERSHIPS_ZENDESK_VERIFY = `${__CSP_API_HOST__}/api/v1/zendesk/oauth/verify`;
export const PARTNERSHIPS_OAUTH = retailerMoniker =>
  `${__CSP_API_HOST__}/api/v1/zendesk/auth/${retailerMoniker}`;
export const PARTNERSHIPS_ZENDESK_ASSIST_TRIGGER_GET = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/packagehelp/zendesk/assist/trigger/${retailerMoniker}`;
export const PARTNERSHIPS_ZENDESK_ASSIST_TRIGGER_POST = `${__CSP_API_HOST__}/api/v1/packagehelp/zendesk/assist/trigger`;
export const PARTNERSHIPS_SALESFORCE_OAUTH = retailerMoniker =>
  `${__CSP_API_HOST__}/api/v1/salesforce/auth/${retailerMoniker}`;
export const PARTNERSHIPS_SALESFORCE_CONFIGS = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/packagehelp/salesforce/host/${retailerMoniker}`;
export const PARTNERSHIPS_SALESFORCE_ASSIST_METADATA = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/packagehelp/salesforce/assist/metadata/${retailerMoniker}`;
export const PARTNERSHIPS_SALESFORCE_METADATA = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/packagehelp/metadata/${retailerMoniker}`;
export const PARTNERSHIPS_SALESFORCE_MULTIBRAND = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/packagehelp/salesforce/multibrand/${retailerMoniker}`;
export const PARTNERSHIPS_SALESFORCE_VERIFY = ({ retailerMoniker }) =>
  `${__CSP_API_HOST__}/api/v1/salesforce/auth/verify/${retailerMoniker}`;
// Service Codes
export const SERVICE_CODES = '/api/track/servicecodes';
export const SERVICE_CODES_CARRIER_SERVICES =
  '/api/track/servicecodes/carrierservices';

// Return Reason Sets
export const RETURN_REASON_SETS = '/api/returns/reasons';
export const RETURN_REASON_SETS_CONFIGS = '/api/returns/configs/reasons';

// Courier Status Codes
export const COURIER_STATUS_CODES = '/api/status-codes';

// Email Templates
export const EMAIL_TEMPLATES = '/api/node/v1/emailTemplates';
export const EMAIL_TEMPLATES_SUPPORTED_TYPES =
  '/api/node/v1/emailTemplates/supportedTypes';
export const SEND_EMAIL = '/api/node/v1/send-email';
export const EMAIL_TEMPLATES_LIST_CUSTOM = `${EMAIL_TEMPLATES}?email_type=custom`;
export const EMAIL_TEMPLATES_LIST_WYSIWYG = `${EMAIL_TEMPLATES}?processor=mjml&email_type=wysiwyg`;
export const GET_EMAIL_TEMPLATE_BY_PARAMS = ({
  type,
  emailType,
  locale,
  retailerMoniker,
}) =>
  `${EMAIL_TEMPLATES}/${emailType}/${locale}/${type}?retailer=${retailerMoniker}`;
export const EMAIL_TEMPLATES_ID = id => `${EMAIL_TEMPLATES}/${id}`;

export const TEMPLATE_PROC_PROXY = '/template-processor-proxy';
export const TEMPLATE_PROC_GENERATE_FROM_JSON = `${TEMPLATE_PROC_PROXY}/templateProcessor/v1/generateFromJson`;

// Wysiwyg Templates Builder
export const WYSIWYG_LOCALES = ({ retailerMoniker }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/locales`;
export const WYSIWYG_TEMPLATES = ({ retailerMoniker, locale, isNarvarUser }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/${locale}/templates${
    isNarvarUser ? '?role=NARVAR_USER' : ''
  }`;
export const WYSIWYG_CREATE_BLOCK = ({ retailerMoniker, locale }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/${locale}/block`;
export const WYSIWYG_BLOCKS = ({ retailerMoniker, locale }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/${locale}/blocks`;
export const WYSIWYG_TEMPLATE = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/template/${templateId}`;
export const WYSIWYG_TEMPLATE_DETAILS = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/template/${templateId}/details`;
export const WYSIWYG_BLOCK = ({ retailerMoniker, blockId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/block/${blockId}`;
export const WYSIWYG_BLOCK_LOCALES = ({ retailerMoniker, blockId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/block/${blockId}/locales`;
export const WYSIWYG_TEMPLATE_LOCALES = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/template/${templateId}/locales`;
export const WYSIWYG_BLOCK_AFFECTED_TEMPLATE = ({
  retailerMoniker,
  locale,
  blockId,
}) =>
  `/api/wysiwyg/v1/${retailerMoniker}/${locale}/affected/templates/${blockId}`;
export const WYSIWYG_TP_PREVIEW = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/preview/${templateId}`;
export const WYSIWYG_COMPONENTS = '/api/wysiwyg/v1/components';
export const WYSIWYG_GLOBAL_SETTING = ({ retailerMoniker, locale }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/${locale}/settings`;
export const WYSIWYG_TEST_DATA = ({ retailerMoniker }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/testdata`;
export const WYSIWYG_TEST_DATA_DETAILS = ({ retailerMoniker, testDataId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/testdata/${testDataId}`;
export const WYSIWYG_PREVIEW = ({ retailerMoniker, templateId, testDataId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/preview/${templateId}/${testDataId}`;
export const WYSIWYG_PREVIEW_DRAFT = ({ retailerMoniker, testDataId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/preview/${testDataId}`;
export const WYSIWYG_VALIDATE_TEMPLATE = ({ retailerMoniker, testDataId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/test/blocks/${testDataId}`;

export const WYSIWYG_MESSAGING_OPTIONS = ({ retailerMoniker }) =>
  `/api/wysiwyg/v1/admin/${retailerMoniker}/rnp/settings/options`;

export const WYSIWYG_MESSAGING_UPDATE_RNP_DATA = ({ retailerMoniker }) =>
  `/api/wysiwyg/v1/admin/${retailerMoniker}/rnp/update/settings`;
export const WYSIWYG_MESSAGING_ON_BOARDING = ({ retailerMoniker }) =>
  `/api/wysiwyg/v1/messaging/onboard/${retailerMoniker}`;

export const WYSIWYG_TEMPLATE_PROCESSOR_JSON = ({
  retailerMoniker,
  templateId,
  testDataId,
}) =>
  `/api/wysiwyg/v1/${retailerMoniker}/template/json/${templateId}/${testDataId}`;
export const WYSIWYG_PUBLISH = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/publish/template/${templateId}`;
export const WYSIWYG_UNPUBLISH = ({ retailerMoniker, templateId }) =>
  `/api/wysiwyg/v1/${retailerMoniker}/unpublish/template/${templateId}`;
export const WYSIWYG_ZENDESK_WIDGET_SETTINGS = `/api/wysiwyg/v1/zendesk/settings`;

// Assist
export const CARE_INVALIDATE_CACHE = '/api/toran/v1/assist/invalidate';
export const CARE_RETAILER_CONFIGURATION = '/api/toran/v1/assist/configuration';
export const CARE_RETAILER_SETTINGS = '/api/toran/v1/assist/settings';
export const CARE_RETAILER_CONTENT = '/api/toran/v1/assist/content';

// Packing Slip and Shipping Label
export const RETURNS_PACKING_SLIP_AND_LABEL = '/api/returns/templates';
export const RETURNS_PACKING_SLIP_AND_LABEL_CONFIGS =
  '/api/returns/configs/templates';

// Returns UI Builder and Files
export const RETURNS_SUPPORTED_LOCALES =
  '/api/node/v1/returns/supportedLocales';
export const RETURNS_SETTINGS = '/api/node/v1/returns/getSettings';
export const RETURNS_TEMPLATE = '/api/node/v1/returns/templates';
export const RETURNS_ENABLE_NEW_RETAILER =
  '/api/node/v1/returns/initializeRetailer';
export const RETURNS_MODIFY_SETTINGS = '/api/node/v1/returns/modifySettings';
export const RETURNS_PREVIEW_VERSION = '/api/node/v1/returns/generatePreview';
export const RETURNS_CREATE_TEST_ORDER = locale =>
  `/api/returns/configs/createTestOrder?is_random=false&items=4&include_shipping=true&locale=${locale}`;

export const INVALIDATE_CACHE_TABLE = '/api/node/v1/invalidate';

export const RETURNS_VERIFY_REFUND_OPTIONS =
  '/api/designer/returns/rules/verifyRefundOption';

export const RETURNS_RESTRICTED_EMAILS = '/api/returns/restricted-emails';

// Handoffs
export const HANDOFFS = '/api/handoffs';

// Config Portability
export const PORTABILITY_DOWNLOAD = '/api/node/v1/portability/download';
export const PORTABILITY_UPLOAD = '/api/node/v1/portability/upload';
export const PORTABILITY_POST = '/api/node/v1/portability/post';

// Platform
export const PLATFORM_HOLIDAYS = '/api/toran/v1/platform/holidays';
export const PLATFORM_HOLIDAYS_CSV = `${PLATFORM_HOLIDAYS}/csv`;
export const PLATFORM_WORKING_DAYS = '/api/toran/v1/platform/workingDays';

// Messaging SMS Analytics
export const MESSAGING_SMS_ANALYTICS_DASHBOARD_LAYOUT =
  '/api/dashboard-instances/sms_engagement';
export const MESSAGING_SMS_ANALYTICS_REPORTING = '/reporting';

// Proxies
export const TORAN_GRAPHQL_ENDPOINT = '/api/toran/v1/graphql';
export const NARVAR_JS_ENDPOINT = '/api/github/webhooks/narvarJS';
export const TORAN_ENDPOINT = '/api/toran/v1';
export const WYSIWYG_ENDPOINT = '/api/wysiwyg/v1';
export const MESSAGING_PROVISIONER_ENDPOINT = '/api/v1/engage';
export const NODE_PROXY_ENDPOINT = '/api/node/*';
export const RETURNS_CONFIG_TOKEN_ENDPOINT = '/returns_config_token';

// API Endpoints
export const RETURNS_CONFIG_ENDPOINT =
  'https://returns-config.qa01.narvar.qa/graphql';

// Engage Tier
export const ENGAGE_TIER_SETUP = retailer => `/api/v1/engage/${retailer}`;

// Metabase
export const METABASE = '/api/metabase/dashboard';
