import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const SearchableSelectItem = ({
  index,
  item,
  isActive,
  readableKey,
  onSelect,
}) => (
  <div key={index}>
    <button
      name={index}
      key={index}
      onClick={e => onSelect(e, {
        index,
        item,
        isActive,
        readableKey,
        onSelect,
      })}
      className={cx(
        'btn',
        'select-item',
        isActive ? 'activeItem' : null,
      )}
      title={item[readableKey]}
    >
      <span style={item.labelStyles}>
        {item[readableKey]}
      </span>
    </button>
  </div>
);

SearchableSelectItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.string,
  isActive: PropTypes.bool,
  readableKey: PropTypes.string,
  onSelect: PropTypes.func,
};

export default (SearchableSelectItem);
