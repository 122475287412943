// Tracking Reports
export const TRACKING = {
  UNIQUE_PACKAGES_TRACKED: 'UNIQUE_PACKAGES_TRACKED',
  TOTAL_USER_VISITS: 'TOTAL_USER_VISITS',
  MARKETING_ENGAGEMENT_CLICKS: 'MARKETING_ENGAGEMENT_CLICKS',
  MARKETING_ENGAGEMENT_RATE: 'MARKETING_ENGAGEMENT_RATE',
};

// Returns Reports
export const RETURNS = {
  RETURN_INITIATED_RECEIVED: 'RETURN_INITIATED_RECEIVED',
  RETURNS_RECEIVED: 'RETURNS_RECEIVED',
  RETURN_REASONS: 'RETURN_REASONS',
  RETURN_METHODS: 'RETURN_METHODS',
  RETURNS_PER_ORDER: 'RETURNS_PER_ORDER',
  ITEMS_PER_RETURN: 'ITEMS_PER_RETURN',
  MOST_RETURNED_ITEM: 'MOST_RETURNED_ITEM',
};

export const DASHBOARDS = {
  CUSTOMER_EXPERIENCE: 'customer_experience',
  ORDER_VOLUME: 'order_volume',
};
