import { REPORTING_ROWS } from '~src/constants/endpoints';
import { ENTER_KEY } from '~src/constants/keycodes';
import { API_REQUEST } from '~src/lib/api';
import { fetchDashboard } from '~src/actions/returnsDashboardAnalyticsActions';
import generateOrderListQuery from '~src/lib/returns/generateOrderListQuery';
import mapOrderRowsToTable from '~src/containers/Returns/ReturnsOrderListView/mapOrderRowsToTable';
import { RETURN_ANALYTICS } from '../constants/returns/dashboardAnalytics';

export const RECORDS_PER_PAGE = 25;
export const SEARCH_FILTERS = [
  {
    displayName: 'Order Number',
    searchKey: 'order_number',
  },
  {
    displayName: 'Tracking Number',
    searchKey: 'tracking_number',
  },
];

export const UPDATE_SELECTED_LOCALE =
  'RETURNS_ORDER_LIST/UPDATE_SELECTED_LOCALE';
export const RECEIVE_ORDER_LIST = 'RETURNS_ORDER_LIST/RECEIVE_ORDER_LIST';
export const RECEIVE_EXPORT_ORDERS = 'RETURNS_ORDER_LIST/RECEIVE_EXPORT_ORDERS';
export const UPDATE_DATE_RANGE = 'RETURNS_ORDER_LIST/UPDATE_DATE_RANGE';
export const UPDATE_ACTIVE_PAGE = 'RETURNS_ORDER_LIST/UPDATE_ACTIVE_PAGE';
export const UPDATE_SEARCH_FILTER = 'RETURNS_ORDER_LIST/UPDATE_SEARCH_FILTER';
export const UPDATE_SEARCH_INPUT = 'RETURNS_ORDER_LIST/UPDATE_SEARCH_INPUT';
export const TOGGLE_IS_SEARCHING = 'RETURNS_ORDER_LIST/TOGGLE_IS_SEARCHING';
export const CANCEL_SEARCH = 'RETURNS_ORDER_LIST/CANCEL_SEARCH';

export const fetchOrders = ({
  offset,
  whereFilters = [],
  exportOnly = false,
} = {}) => async (dispatch, getState) => {
  const {
    returnsDashboardAnalyticsReducer: {
      reportDefinitions: {
        t_return_details: {
          fields: columns = [],
          field_definitions: columnConfiguration = {},
        } = {},
      } = {},
    } = {},
    returnsOrderListReducer: {
      selectedLocale: { locale },
      startDate,
      endDate,
    } = {},
  } = getState();

  const localeFilter = locale
    ? [
        {
          filterKey: 'locale',
          filterValue: locale,
        },
      ]
    : [];

  const queryBase = {
    columns,
    id: 'v_return_details_looker',
    offset,
    startDate,
    endDate,
    whereFilters: [...localeFilter, ...whereFilters],
    dataset: 'analytics',
  };

  try {
    const {
      v_return_details_looker: { rows: orders },
      total_records_with_filters: totalRecordsWithFilters,
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      name: 'fetchReturnOrders',
      path: `${REPORTING_ROWS}${generateOrderListQuery({
        ...queryBase,
        ...(exportOnly ? {} : { limit: 25 }),
      })}`,
    });

    if (exportOnly) {
      dispatch({
        type: RECEIVE_EXPORT_ORDERS,
        payload: {
          exportOrders: orders,
        },
      });
    } else {
      const { tableHeaders, tableBody } = mapOrderRowsToTable({
        orders,
        columns,
        columnConfiguration,
      });

      dispatch({
        type: RECEIVE_ORDER_LIST,
        payload: {
          exportParameters: generateOrderListQuery(queryBase),
          tableHeaders,
          tableBody,
          totalRecordsWithFilters,
        },
      });
    }
  } catch (err) {
    console.error(err); // eslint-disable-line
  }
};

export const clearSearchInput = () => ({
  type: UPDATE_SEARCH_INPUT,
  payload: { searchInput: '' },
});

export const updateSelectedLocale = selectedLocale => dispatch => {
  dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });

  dispatch(clearSearchInput());
  dispatch(fetchOrders());
};

export const updateActivePage = newPage => ({
  type: UPDATE_ACTIVE_PAGE,
  payload: { activePage: newPage },
});

export const fetchDashboardAndOrderList = (userLocales = []) => async (
  dispatch,
  getState,
) => {
  await dispatch(fetchDashboard(RETURN_ANALYTICS, userLocales));
  const {
    returnsDashboardAnalyticsReducer: { selectedLocale },
  } = getState();

  // Use the same default locale as fetchDashboard instead of en_US
  await dispatch({
    type: UPDATE_SELECTED_LOCALE,
    payload: {
      selectedLocale,
    },
  });
  await dispatch(fetchOrders());
};

export const updateDateRange = (startDate, endDate) => async dispatch => {
  await dispatch({
    type: UPDATE_DATE_RANGE,
    payload: {
      startDate,
      endDate,
    },
  });

  dispatch(clearSearchInput());
  dispatch(updateActivePage(1));
};

export const updateSearchFilter = searchFilter => ({
  type: UPDATE_SEARCH_FILTER,
  payload: { searchFilter },
});

export const handleSearchInputChange = searchInput => async dispatch => {
  if (!searchInput) await dispatch(fetchOrders());

  dispatch({
    type: UPDATE_SEARCH_INPUT,
    payload: { searchInput },
  });
};

export const toggleIsSearching = isSearching => ({
  type: TOGGLE_IS_SEARCHING,
  payload: { isSearching },
});

export const cancelSearch = () => ({ type: CANCEL_SEARCH });

export const submitSearchInput = () => async (dispatch, getState) => {
  const {
    returnsOrderListReducer: {
      searchFilter: { searchKey: filterKey } = {},
      searchInput: filterValue,
    } = {},
  } = getState();

  dispatch(toggleIsSearching(true));

  await dispatch(
    fetchOrders({
      whereFilters: [
        {
          filterKey,
          filterValue,
        },
      ],
    }),
  );

  dispatch(toggleIsSearching(false));
};

export const handleSearchKeydown = keyCode => (dispatch, getState) => {
  const { returnsOrderListReducer: { isSearching } = {} } = getState();

  if (keyCode === ENTER_KEY) {
    dispatch(submitSearchInput());
  } else if (isSearching) {
    dispatch(toggleIsSearching(false));
  }
};

export const requestNewPage = requestedPage => async dispatch => {
  const offset = (requestedPage - 1) * RECORDS_PER_PAGE;

  await dispatch(fetchOrders({ offset }));
  dispatch(updateActivePage(requestedPage));
};
