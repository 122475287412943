import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FeaturesReady } from '@growthbook/growthbook-react';
import NarvarGrowthBookProvider from '~src/hooks/NarvarGrowthBookProvider';
import Loading from '~src/components/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Authorized } from '..';
import { growthbook } from '../../growthbook';

const App = () => {
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.loadFeatures();
  }, []);

  return (
    <NarvarGrowthBookProvider growthbook={growthbook}>
      <FeaturesReady timeout={500} fallback={<Loading />}>
        <Switch>
          <Route path="/" component={Authorized} />
        </Switch>
      </FeaturesReady>
    </NarvarGrowthBookProvider>
  );
};

export default App;
