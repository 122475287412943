import {
  RECEIVE_TRACK_SETTINGS,
  RECEIVE_TRACK_SETTINGS_ERROR,
  DISMISS_TRACK_SETTINGS_ALERT,
  RECEIVE_TRACK_SETTINGS_SAVE,
  RECEIVE_STORE_LOCATOR_CONFIG,
  UPDATE_EDD_USE_PROMISE_DATE,
  UPDATE_URL_OVERRIDE_ORDER,
  UPDATE_EDD_FORMAT_SELECT,
  UPDATE_EDD_INTERNATIONAL_DATE_FORMATTING,
  ADD_NO_EDD_CARRIER,
  REMOVE_NO_EDD_CARRIER,
  ADD_EDD_HOLIDAY_CLOSURE_FIELD,
  UPDATE_EDD_HOLIDAY_CLOSURE_FIELD,
  DELETE_EDD_HOLIDAY_CLOSURE_FIELD,
  UPDATE_EDD_WORKING_SCHEDULE,
  UPDATE_STORE_LOCATOR_SELECT,
  UPDATE_STORE_LOCATOR_INPUT,
  SWITCH_STORE_LOCATOR_CONFIG,
  RECEIVE_DELETE_STORE_LOCATOR_CONFIG,
  RESET_STORE_LOCATOR_STATE,
  UPDATE_IS_EDITING_FORM,
} from '~src/actions/trackSettingsActions';
import { res } from '~src/constants/storeLocator.js';
import { LOCATION_CHANGE } from 'connected-react-router';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';

const resetStoreLocatorState = state => {
  const newState = { ...state };
  const newStoreLocatorState = Object.keys(newState.storeLocatorConfig).reduce(
    (newStoreLocatorGroup, key) => {
      if (key === 'enabled') {
        return {
          ...newStoreLocatorGroup,
          enabled: false,
        };
      }
      if (
        typeof newStoreLocatorGroup[key] === 'object' &&
        Object.keys(newStoreLocatorGroup[key]).length
      ) {
        return newStoreLocatorGroup[key].options
          ? {
              ...newStoreLocatorGroup,
              [key]: {
                ...newStoreLocatorGroup[key],
                selected: '',
              },
            }
          : {
              ...newStoreLocatorGroup,
              [key]: {
                ...newStoreLocatorGroup[key],
                data: '',
              },
            };
      }
      return newStoreLocatorGroup;
    },
    newState.storeLocatorConfig,
  );
  newState.storeLocatorConfig = newStoreLocatorState;
  return newState;
};

export const initialState = {
  alert: {
    show: false,
  },
  isEditingForm: false,
  isFetching: false,
  eddConfig: {
    working_schedule: [0, 0, 0, 0, 0, 0, 0],
    edd_holiday_closures: [],
    no_edd_carriers: [],
    edd_format_values: [],
  },
  storeLocatorConfig: {},
};

function trackSettings(state = initialState, action) {
  const { meta, payload, type, isEditingForm } = action;
  switch (type) {
    case DISMISS_TRACK_SETTINGS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_TRACK_SETTINGS:
      return {
        ...state,
        isFetching: false,
        eddConfig: {
          ...state.eddConfig,
          ...payload.response,
        },
      };

    case LOCATION_CHANGE:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    case RESET_STORE_LOCATOR_STATE:
      return resetStoreLocatorState(state);

    case RECEIVE_TRACK_SETTINGS_ERROR:
      return {
        ...state,
        isEditingForm: false,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case RECEIVE_DELETE_STORE_LOCATOR_CONFIG:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Store Locator Configuration is Deleted.',
        },
      };

    case UPDATE_EDD_USE_PROMISE_DATE:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          use_retailer_promise_date: meta.checked,
        },
      };

    case UPDATE_URL_OVERRIDE_ORDER:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          url_override_order: meta.checked,
        },
      };

    case UPDATE_EDD_FORMAT_SELECT:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          current_edd_format: meta.value,
        },
      };

    case UPDATE_EDD_INTERNATIONAL_DATE_FORMATTING:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          international_date_formatting: meta.checked,
        },
      };

    case ADD_NO_EDD_CARRIER:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          no_edd_carriers: [...state.eddConfig.no_edd_carriers, meta.value],
        },
      };

    case REMOVE_NO_EDD_CARRIER:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          no_edd_carriers: state.eddConfig.no_edd_carriers.filter(
            item => item !== meta.value,
          ),
        },
      };

    case ADD_EDD_HOLIDAY_CLOSURE_FIELD:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          edd_holiday_closures: [
            ...state.eddConfig.edd_holiday_closures,
            { name: '', date: meta.defaultDate },
          ],
        },
      };

    case UPDATE_EDD_HOLIDAY_CLOSURE_FIELD:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          edd_holiday_closures: state.eddConfig.edd_holiday_closures.map(
            (item, i) =>
              i === meta.closureIndex
                ? { ...item, [meta.field]: meta.value }
                : item,
          ),
        },
      };

    case DELETE_EDD_HOLIDAY_CLOSURE_FIELD:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          edd_holiday_closures: [
            ...state.eddConfig.edd_holiday_closures.slice(0, meta.closureIndex),
            ...state.eddConfig.edd_holiday_closures.slice(
              meta.closureIndex + 1,
            ),
          ],
        },
      };

    case UPDATE_EDD_WORKING_SCHEDULE:
      return {
        ...state,
        eddConfig: {
          ...state.eddConfig,
          working_schedule: state.eddConfig.working_schedule.map((item, i) =>
            i === meta.weekdayIndex ? meta.value : item,
          ),
        },
      };

    case UPDATE_STORE_LOCATOR_INPUT:
      return {
        ...state,
        storeLocatorConfig: {
          ...state.storeLocatorConfig,
          [meta.field]: {
            ...state.storeLocatorConfig[meta.field],
            data: meta.value,
          },
        },
      };

    case UPDATE_STORE_LOCATOR_SELECT: {
      if (state.storeLocatorConfig[meta.field].drilldown) {
        return {
          ...state,
          storeLocatorConfig: {
            ...state.storeLocatorConfig,
            [meta.field]: {
              ...state.storeLocatorConfig[meta.field],
              selected: meta.value,
            },
            [`${meta.field}_drilldown`]: {
              ...state.storeLocatorConfig[meta.field].drilldown[meta.value],
            },
          },
        };
      }
      return {
        ...state,
        storeLocatorConfig: {
          ...state.storeLocatorConfig,
          [meta.field]: {
            ...state.storeLocatorConfig[meta.field],
            selected: meta.value,
          },
        },
      };
    }

    case SWITCH_STORE_LOCATOR_CONFIG: {
      return {
        ...state,
        storeLocatorConfig: {
          ...state.storeLocatorConfig,
          [meta.field]: meta.value,
        },
      };
    }

    case RECEIVE_TRACK_SETTINGS_SAVE:
      return {
        ...state,
        isEditingForm: false,
        alert: {
          color: 'success',
          show: true,
          text: 'Track settings saved.',
        },
      };

    case RECEIVE_STORE_LOCATOR_CONFIG: {
      const storeLocatorState = Object.keys(payload).reduce(
        (fieldGroup, field) => {
          const currentProperty = payload[field];
          return currentProperty.drilldown
            ? {
                ...fieldGroup,
                [field]: currentProperty,
                [`${field}_drilldown`]: {
                  selected: currentProperty.selected
                    ? currentProperty.drilldown[currentProperty.selected]
                        .selected
                    : '',
                  options: currentProperty.selected
                    ? currentProperty.drilldown[currentProperty.selected]
                        .options
                    : [],
                },
              }
            : {
                ...fieldGroup,
                [field]: payload[field],
              };
        },
        {},
      );
      return {
        ...state,
        isFetching: false,
        storeLocatorConfig: {
          ...storeLocatorState,
        },
      };
    }
    case UPDATE_IS_EDITING_FORM:
      return {
        ...state,
        isEditingForm,
      };

    default:
      return state;
  }
}

export default trackSettings;
