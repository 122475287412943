// The reqBodyRedactor is an optional function the caller can provide. eg: fetchSetHmacToken in tenantActions.js
// It's meant to remove/redact sensitve info from the request body, so not to be logged by noflake
const sendMetricToNoflake = async (
  user = {},
  requestUrl = '',
  fetchConfig = {},
  res = {},
  resBody = {},
  name = '',
  reqBodyRedactor = null,
) => {
  if (user === null) return;

  const dateTime = new Date().toISOString();
  const { body: requestBody = '', headers, method } = fetchConfig;
  const { status, statusText, url: responseUrl, duration = '' } = res;
  const {
    username,
    rolesList,
    retailerId = '',
    retailerIdToRetailerInfo: {
      [retailerId]: { uri_moniker: retailerMoniker = '' } = {},
    } = {},
  } = user;
  let userRole = '';
  if (Array.isArray(rolesList) && rolesList.length > 0) {
    userRole = rolesList[0].name;
  }

  const noflakePayload = {
    tag: 'noflake.hub_requests',
    event_ts: dateTime,
    ingestion_timestamp: dateTime,
    username: username,
    user_role: userRole,
    retailer_moniker: retailerMoniker,
    client_location_href: window.location.href,
    client_agent: window.navigator.userAgent,
    client_language: window.navigator.language,
    client_timezone_offset: new Date().getTimezoneOffset().toString(),
    request_headers: JSON.stringify(headers),
    request_body:
      typeof reqBodyRedactor === 'function'
        ? reqBodyRedactor(requestBody)
        : requestBody,
    request_method: method,
    request_url: requestUrl,
    request_name: name,
    response_url: responseUrl,
    response_status_code: status.toString(),
    response_status_message: statusText,
    response_status_body: JSON.stringify(resBody),
    response_duration_ms: duration.toString(),
  };

  try {
    await fetch(process.env.NOFLAKE_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(noflakePayload),
    });
  } catch (e) {
    console.error('Error sending data to Noflake.', e);
  }
};

export default sendMetricToNoflake;
