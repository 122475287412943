import {
  RECEIVE_RETAILER_INFO_SETTINGS_JSON,
  RECEIVE_VALIDATION_FACEBOOK,
  RECEIVE_SETTINGS_ERROR,
  RECEIVE_SETTINGS_SAVE,
  RECEIVE_CURRENT_LOCALE,
  RECEIVE_SETTINGS_DATE_MODIFIED,
  TOGGLE_SETTINGS_CONFIRM_MODAL,
  RESET_SETTINGS,
  UPDATE_SETTINGS_STORE,
  RECEIVE_SETTINGS_FACEBOOK,
  RECEIVE_SETTINGS_MESSAGE_SERVICE,
  RECEIVE_EMAIL_CONFIGS,
} from '~src/actions/notifySettingsActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const checkIsNarvarDomain = input =>
  input.endsWith('.narvar.com') || input.endsWith('.narvar.qa');

const responsesList = [
  {
    description: 'Use default Narvar responses',
    type: 'default',
  },
  {
    description: 'Mute responses',
    type: 'mute',
  },
  {
    description: 'Send custom message',
    type: 'custom',
  },
];

const responseFrequencyList = [
  {
    description: 'Only once',
    type: 'single',
  },
  {
    description: 'Every Time',
    type: 'every',
  },
];

const emailDomainList = [
  {
    description: 'Narvar Domain',
    type: 'narvar',
  },
  {
    description: 'Customer Domain',
    type: 'customer',
  },
];
const [narvarDomain, customerDomain] = emailDomainList;

const imageAspectRatioList = [
  {
    description: 'Horizontal',
    type: 'horizontal',
  },
  {
    description: 'Square',
    type: 'square',
  },
];

export const initialState = {
  alert: {
    show: false,
  },
  facebook: {
    page_id: '',
    page_name: '',
    auth_token: '',
    // enabled: false,
    custom_message: '',
    app_id: '',
    use_retailer_page: false,
    item_visibility_enabled: false,
    item_visibility_proactive: false,
    responses_list: responsesList,
    responses_list_selected: responsesList[0],
    response_frequency_list: responseFrequencyList,
    response_frequency_list_selected: responseFrequencyList[0],
    item_visibility_image_aspect_ratio_list: imageAspectRatioList,
    item_visibility_image_aspect_ratio_list_selected: imageAspectRatioList[0],
    cold_start_message_enabled: false,
    isFetching: false,
  },
  _facebook: {
    auth_token: '',
    custom_message: '',
    use_retailer_page: false,
    item_visibility_proactive: false,
    responses_list_selected: responsesList[0],
    response_frequency_list_selected: responseFrequencyList[0],
    item_visibility_image_aspect_ratio_list_selected: imageAspectRatioList[0],
    cold_start_message_enabled: false,
  },
  messageService: {
    service_id: '',
  },
  _messageService: {
    service_id: '',
  },
  email: {
    domain_list: emailDomainList,
    domain_list_selected: narvarDomain,
    sender_domain: '',
    host_name: '',
    validation_status: false,
    dkim_record: '',
    sender_email: '',
    sender_name: '',
    reply_to_email: '',
    has_api_key: false,
  },
  _email: {
    sender_email: '',
    sender_name: '',
    reply_to_email: '',
  },
  _dateModified: {
    retailerInfoSettings: {
      date_modified: '',
      modified_id: '',
    },
    facebook: {
      date_modified: '',
      modified_id: '',
    },
    messageService: {
      date_modified: '',
      modified_id: '',
    },
    email: {
      date_modified: '',
      modified_id: '',
    },
  },
  retailerInfoSettingsJson: {},
  currentLocale: 'en_US',
  showConfirmModal: false,
};

function notifySettings(state = initialState, action) {
  const { payload = {}, type } = action;
  switch (type) {
    case RECEIVE_SETTINGS_FACEBOOK: {
      return {
        ...state,
        facebook: {
          ...state.facebook,
          auth_token: payload.facebook.auth_token,
          // enabled: payload.facebook.enabled,
        },
        _facebook: {
          ...state._facebook,
          auth_token: payload.facebook.auth_token,
        },
        _dateModified: {
          ...state._dateModified,
          facebook: {
            ...state._dateModified.facebook,
            date_modified: payload.date_modified,
            modified_id: payload.modified_id,
          },
        },
      };
    }
    case RECEIVE_SETTINGS_MESSAGE_SERVICE: {
      return {
        ...state,
        messageService: {
          ...state.messageService,
          service_id: payload.messageService.service_id,
        },
        _messageService: {
          ...state._messageService,
          service_id: payload.messageService.service_id,
        },
        _dateModified: {
          ...state._dateModified,
          messageService: {
            ...state._dateModified.messageService,
            date_modified: payload.date_modified,
            modified_id: payload.modified_id,
          },
        },
      };
    }
    case RECEIVE_RETAILER_INFO_SETTINGS_JSON: {
      const { facebook: fbSettings, email } = payload;

      return {
        ...state,
        ...payload,
        facebook: {
          ...state.facebook,
          ...fbSettings,
        },
        _facebook: {
          ...state._facebook,
          ...fbSettings,
        },
        email: {
          ...state.email,
          email,
        },
        _dateModified: {
          ...state._dateModified,
          retailerInfoSettings: {
            ...state._dateModified.retailerInfoSettings,
            date_modified: payload.date_modified,
            modified_id: payload.modified_id,
          },
        },
      };
    }
    case RECEIVE_CURRENT_LOCALE: {
      return {
        ...state,
        currentLocale: payload,
      };
    }
    case RECEIVE_VALIDATION_FACEBOOK: {
      return {
        ...state,
        facebook: {
          ...state.facebook,
          app_id: payload.app_id,
          page_id: payload.page_id,
          page_name: payload.page_name,
        },
      };
    }
    case UPDATE_SETTINGS_STORE: {
      return {
        ...state,
        ...payload,
      };
    }
    case RECEIVE_SETTINGS_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload,
        },
      };
    }
    case RECEIVE_SETTINGS_DATE_MODIFIED: {
      return {
        ...state,
        _dateModified: {
          ...state._dateModified,
          retailerInfoSettings: {
            ...state._dateModified.retailerInfoSettings,
            date_modified: payload,
          },
        },
      };
    }
    case RECEIVE_SETTINGS_SAVE: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text:
            'Publish successful. The updates will be available to all your customers shortly.',
        },
        _facebook: {
          ...state.facebook,
        },
        _messageService: {
          ...state.messageService,
        },
        _email: {
          ...state.email,
        },
      };
    }
    case TOGGLE_SETTINGS_CONFIRM_MODAL:
      return {
        ...state,
        showConfirmModal: !state.showConfirmModal,
      };
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    case RESET_SETTINGS: {
      return {
        ...state,
        ...initialState,
      };
    }
    case RECEIVE_EMAIL_CONFIGS: {
      const {
        has_api_key: hasApiKey,
        ownership_verified: ownershipVerified,
        verify_error: verifyError,
        dkim_key: dkimKey,
        sender_email: senderEmail,
        sender_name: senderName,
        reply_to_email: replyToEmail,
        date_modified: dateModified,
        modified_id: modifiedId,
        host_name: hostName,
      } = payload;

      const newState = {
        ...state,
        email: {
          ...state.email,
          has_api_key: hasApiKey || false,
          dkim_record: dkimKey || '',
          validation_status: ownershipVerified || false,
          validation_error: verifyError || '',
          sender_domain: payload.sender_domain || '',
          sender_email: senderEmail ? senderEmail.split('@')[0] : '',
          sender_name: senderName || '',
          reply_to_email: replyToEmail || '',
          host_name: hostName || '',
        },
        _email: {
          sender_email: senderEmail ? senderEmail.split('@')[0] : '',
          sender_name: senderName || '',
          reply_to_email: replyToEmail || '',
        },
        _dateModified: {
          ...state._dateModified,
          email: {
            ...state._dateModified.email,
            date_modified: dateModified,
            modified_id: modifiedId,
          },
        },
      };

      if (
        payload.sender_domain &&
        !checkIsNarvarDomain(payload.sender_domain)
      ) {
        newState.email.domain_list_selected = customerDomain;
      }
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default notifySettings;
