import React, { isValidElement } from 'react';
import Helmet from 'react-helmet';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import withStyles from '~src/lib/isomorphic-style-loader/withStyles';
import {
  Alert,
  Button,
  Dropdown,
  Card,
  FormControl,
  Svg,
  AlertCard,
  Tag,
} from '@narvar/hum';
import { Header, Tabs, LocalePicker } from '~src/components';
import { dismissAlert, dismissAlertCard } from '~src/actions/uiActions';
import s from './DetailsView.scss';

import { CodeBox, PSSLCodeBoxWithPreview } from '../customComponents';

const customComponents = {
  codeBox: CodeBox,
  psslCodeBoxWithPreview: PSSLCodeBoxWithPreview,
};

const mapDispatchToProps = dispatch => ({
  handleDismissAlert: () => {
    dispatch(dismissAlert());
  },
  handleDismissAlertCard: () => {
    dispatch(dismissAlertCard());
  },
});

const DetailsView = ({
  alertCard: {
    show: alertCardShow,
    type: alertCardType,
    title: alertCardTitle,
    toggleText: alertCardToggleText,
    children: alertCardChildren,
  },
  alert,
  containerTop,
  tabs,
  changeDetected,
  form,
  isFetching,
  isDynamicSchema,
  handleDismissAlert,
  handleDismissAlertCard,
  schema: {
    addCard,
    addCardLabel,
    cards,
    header,
    tags,
    rightSection: {
      // Would like to deprecate `meta` and use consistent API with schema.cards
      cards: rightCards,
      meta: rightMeta = [],
      formControls: rightFormControls,
      className: rightClassName,
      header: rightHeader,
    },
  } = {},
}) => {
  const Components = {
    Dropdown,
    Button,
    Svg,
    LocalePicker,
  };

  // if rightCards exists (preferable) render cards in a similar fashion to the left side
  // cards. Otherwise fallback to old style using meta.
  const rightSectionCardNodes = rightCards ? (
    <div data-test="right-section-cards">
      {rightCards.map((card, cardIndex) => (
        <Card
          key={card.id || cardIndex}
          isFetching={isFetching}
          {...card.props}
        >
          <h3>{card.header}</h3>
          {card.formControls.map(formControl => (
            <FormControl
              data={
                form.rightCards[cardIndex][formControl.props.name.split('/')[1]]
              }
              key={formControl.props.name}
              {...formControl.props}
            />
          ))}
        </Card>
      ))}
    </div>
  ) : (
    <Card
      isFetching={isFetching}
      className={cx(rightClassName, s['details-card'])}
    >
      <h3>{rightHeader}</h3>
      {rightFormControls.map((formControl, index) =>
        isDynamicSchema ? (
          <FormControl key={formControl.id || index} {...formControl.props} />
        ) : (
          <FormControl
            key={formControl.props.name}
            data={form[formControl.props.name]}
            selected={form[formControl.props.name]}
            {...formControl.props}
          />
        ),
      )}
      {rightMeta.map((item, index) => (
        <div key={item.id || index} className={s['details-card-text']}>
          <span>{item.label}</span>
          <p>{item.text}</p>
        </div>
      ))}
    </Card>
  );

  return (
    <div className={s.DetailsView}>
      <Helmet />
      <Header
        backButton={header.backButton}
        secondaryTitle={header.secondaryTitle}
      >
        {header.leftActions && (
          <div className={cx('flex-left', header.className, s.leftActions)}>
            {header.leftActions.map((action, index) => {
              if (isValidElement(action)) return action;
              const Action = Components[action.component];
              return (
                <Action
                  key={typeof children === 'string' ? action.children : index}
                  {...action.props}
                >
                  {action.children}
                </Action>
              );
            })}
          </div>
        )}
        {header.rightActions && (
          <div
            className={cx(
              'flex-right',
              'flex-row',
              header.className,
              s.rightActions,
            )}
          >
            {header.rightActions.map((action, index) => {
              if (isValidElement(action)) return action;
              const Component = Components[action.component];
              return (
                <Component
                  // TODO: add id's to all actions and remove this ternary
                  key={action.id === undefined ? index : action.id}
                  disabled={!changeDetected}
                  {...action.props}
                >
                  {action.children}
                </Component>
              );
            })}
          </div>
        )}
      </Header>
      {tabs && <Tabs tabs={tabs} />}
      <Alert
        toggle={alert.toggle || handleDismissAlert}
        color={alert.color}
        show={alert.show}
      >
        {alert.text}
      </Alert>
      <Container>
        {containerTop && containerTop()}
        {alertCardShow && (
          <div className={s['alert-card']}>
            <AlertCard
              show={alertCardShow}
              type={alertCardType}
              title={alertCardTitle}
              toggle={handleDismissAlertCard}
              toggleText={alertCardToggleText}
            >
              {alertCardChildren}
            </AlertCard>
          </div>
        )}
        {Array.isArray(tags) && (
          <Row>
            <Col md={12}>
              <div className="d-flex flex-wrap">
                {tags.map(tag => (
                  <Tag label={tag} readOnly />
                ))}
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={8} data-test="left-section-cards">
            {isFetching && cards.length === 0 && <Card isFetching />}
            {cards.map((card, cardIndex) => {
              if (card.hidden) return null;
              return (
                <Card
                  key={card.id || cardIndex}
                  isFetching={isFetching}
                  {...card.props}
                >
                  <h3>{card.header}</h3>
                  {card.notice}
                  {card.formControls.map((formControl, formControlIndex) => {
                    if (isValidElement(formControl)) return formControl;
                    if (formControl.props.hidden) return null;
                    if (
                      Object.keys(customComponents).includes(
                        formControl.props.type,
                      )
                    ) {
                      return customComponents[formControl.props.type]({
                        key: formControl.id || formControlIndex,
                        data:
                          form.cards[cardIndex][
                            formControl.props.name.split('/')[1]
                          ],
                        formControl,
                        form,
                      });
                    }
                    return isDynamicSchema ? (
                      <FormControl
                        key={formControl.id || formControlIndex}
                        {...formControl.props}
                      />
                    ) : (
                      <FormControl
                        data={
                          form.cards[cardIndex][
                            formControl.props.name.split('/')[1]
                          ]
                        }
                        selected={
                          form.cards[cardIndex][
                            formControl.props.name.split('/')[1]
                          ]
                        }
                        key={formControl.props.name}
                        {...formControl.props}
                      />
                    );
                  })}
                </Card>
              );
            })}
            {addCard && !isFetching && (
              <Button color="link" onClick={addCard} icon="plus">
                {addCardLabel || 'Add Chart'}
              </Button>
            )}
          </Col>
          <Col md={4}>{rightSectionCardNodes}</Col>
        </Row>
      </Container>
    </div>
  );
};

DetailsView.propTypes = {
  alertCard: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    type: PropTypes.string,
    title: PropTypes.string,
    toggleText: PropTypes.string,
    children: PropTypes.node,
  }),
  alert: PropTypes.shape({
    color: PropTypes.string,
    show: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    text: PropTypes.string,
  }),
  containerTop: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      active: PropTypes.bool,
    }),
  ),
  handleDismissAlert: PropTypes.func.isRequired, // Passed in through mapDispatchToProps
  handleDismissAlertCard: PropTypes.func.isRequired,
  changeDetected: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
  isDynamicSchema: PropTypes.bool,
  form: PropTypes.shape({
    cards: PropTypes.array,
    rightCards: PropTypes.array,
  }), // required if not a dynamic schema
  schema: PropTypes.shape({
    addCard: PropTypes.func,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string.isRequired,
        formControls: PropTypes.array.isRequired,
        id: PropTypes.string,
      }),
    ).isRequired,
    header: PropTypes.object,
    rightSection: PropTypes.shape({
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          header: PropTypes.string.isRequired,
          formControls: PropTypes.array.isRequired,
          id: PropTypes.string, // required if card order might change
        }),
      ),
      header: PropTypes.string,
      formControls: PropTypes.array,
      meta: PropTypes.array,
    }).isRequired,
  }).isRequired,
};

DetailsView.defaultProps = {
  alertCard: {
    show: false,
  },
  alert: {
    show: false,
  },
  changeDetected: false,
  isDynamicSchema: false,
  form: null,
  tabs: [],
  containerTop: null,
};

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(s)(DetailsView));
