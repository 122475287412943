import buildPackagesQuery from './buildPackagesQuery';
import {
  RETURNS_VERSION_3_PRODUCT_NAME,
  RETURN_RULES_VERSION_3_PRODUCT_NAME,
  RETURN_RULES_PRODUCT_NAME,
  RETURNS_PRODUCT_NAME,
  RETURNS_RESTRICTED_EMAILS_NAME,
  PARTNERSHIPS_CSP_API,
  MAGENTO_PRODUCT,
  SFCC_PRODUCT,
  LOM_PRODUCT,
  BC_PRODUCT,
  SAP_PRODUCT,
  SHOPIFY_PRODUCT,
  EMAIL_DASHBOARD_PRODUCT,
  SMS_DASHBOARD_PRODUCT,
  MONITOR_PRODUCT,
  MONITOR_V2_PRODUCT,
  SHIP_PRODUCT,
  ASSIST_PRODUCT,
  NOTIFY_PRODUCT,
  WYSIWYG_EMAIL_EDITOR_PRODUCT,
  WYSIWYG_SMS_EDITOR_PRODUCT,
  NOTIFICATION_HISTORY_PRODUCT,
  SHIP_EMBED_PRODUCT,
  TRANSPORTATION_MANAGEMENT_PRODUCT,
  DELIVERY_PRODUCT,
  TRACK_PRODUCT,
  TRACK_BETA,
  APPLE_WALLET_PRODUCT,
  SHIP_UNKNOWN_ORIGIN,
  PROMISE_V2_PRODUCT,
} from './constants';

const getSections = ({
  role,
  isCsm,
  retailerIdToRetailerInfo,
  retailerId,
  t2Retailer,
  exceptionsEnabled,
  dashboards,
  reportDefinition,
  monitorV2UrlBase,
  i18n,
  growthbook,
}) => {
  const permissions = {
    analyst: role.permissions.analyst.all,
    broadcastMessage: role.permissions.broadcast_message.all,
    carrierManagement: role.permissions.carrier_mgmt.all,
    companyManagement: role.permissions.company_management.all,
    designer: role.permissions.designer.all,
    globalBroadcastMessage: role.permissions.global_broadcast_message.all,
    tenants: role.permissions.tenant_mgmt.list,
    userManagement: role.permissions.user_management.admin,
  };

  const {
    products = [],
    apis = [],
    feature_management: {
      dynamic_track: dynamicTrackFeatures = {},
      messaging: messagingFeatures = {},
    } = {},
    uri_moniker: retailerMoniker,
    onboarding: { promise: promiseOnboarding = 'started' } = {},
  } = retailerIdToRetailerInfo[retailerId];

  const narvarAdmin =
    role.name === 'NARVAR_ADMIN' || role.name === 'NARVAR_SUPER_ADMIN';
  const ownerOrAdmin =
    role.name === 'OWNER' || role.name === 'ADMIN' || narvarAdmin;
  const ownerOrAdminOrManager = ownerOrAdmin || role.name === 'MANAGER';
  const ownerOrAdminOrUser = ownerOrAdmin || role.name === 'NARVAR_USER';

  const ownerOrAdminOrUserOrAuthor =
    ownerOrAdminOrUser || role.name === 'NARVAR_AUTHOR';
  // Navar Author has limited product menus.
  const isNarvarAuthor = role.name === 'NARVAR_AUTHOR';
  const isNarvarAuthorCsm = isNarvarAuthor && isCsm;

  const isAdminOrUser =
    narvarAdmin || role.name === 'ADMIN' || role.name === 'NARVAR_USER';

  // Ship
  const showShip = products.includes(SHIP_PRODUCT);

  // Ship Embed
  const showShipEmbed = products.includes(SHIP_EMBED_PRODUCT);

  // Ship Unknown Origin
  const showShipUnknownOrigin = products.includes(SHIP_UNKNOWN_ORIGIN);

  // Promise V2
  const showPromise = products.includes(PROMISE_V2_PRODUCT);

  // Dynamic Track
  const showDynamicTrack = products.includes(TRACK_BETA);

  // Track
  const showTrack = products.includes(TRACK_PRODUCT);

  // Assist
  const hasAssist = products.includes(ASSIST_PRODUCT);
  const showAssist = hasAssist && role.name !== 'ANALYST';

  // Return
  const hasReturns = products.includes(RETURNS_PRODUCT_NAME);
  const showReturnRules = Object.keys(retailerIdToRetailerInfo).find(
    key => retailerIdToRetailerInfo[key].features.returns_rules,
  );
  const hasReturnRules =
    showReturnRules || products.includes(RETURN_RULES_PRODUCT_NAME);
  const hasReturnRulesV3 = products.includes(
    RETURN_RULES_VERSION_3_PRODUCT_NAME,
  );
  const hasReturnsV3 = products.includes(RETURNS_VERSION_3_PRODUCT_NAME);
  const showReturnsVersion3 = hasReturnsV3 && isCsm;
  const showReturn =
    hasReturns || hasReturnRules || hasReturnRulesV3 || hasReturnsV3 || isCsm;
  const showReturnSettingsValidation = hasReturnsV3 && isCsm;
  const hasReturnsRestrictedEmails = products.includes(
    RETURNS_RESTRICTED_EMAILS_NAME,
  );
  const showReturnsRestrictedEmails =
    hasReturnsRestrictedEmails && (isCsm || ownerOrAdmin);

  // Messaging
  const showMessaging =
    (messagingFeatures && messagingFeatures.emailAnalytics) ||
    products.includes(EMAIL_DASHBOARD_PRODUCT);
  const showMessagingSms =
    (messagingFeatures && messagingFeatures.smsAnalytics) ||
    products.includes(SMS_DASHBOARD_PRODUCT);
  const showNotifyEmails = products.includes(NOTIFY_PRODUCT) && isCsm;
  const showWysiwygEmailEditor =
    (messagingFeatures && messagingFeatures.emailEditor) ||
    (products.includes(WYSIWYG_EMAIL_EDITOR_PRODUCT) &&
      (ownerOrAdminOrManager || isCsm));
  const showWysiwygSMSEditor =
    (messagingFeatures && messagingFeatures.smsEditor) ||
    (products.includes(WYSIWYG_SMS_EDITOR_PRODUCT) &&
      (ownerOrAdminOrManager || isCsm));
  const showMessagingWebhook =
    messagingFeatures && messagingFeatures.messagingWebhooks && isCsm;
  const showNotifyStyling = isCsm && t2Retailer;
  const showNotifySettings =
    isCsm && t2Retailer && (ownerOrAdmin || isNarvarAuthor);
  const showNotify =
    showNotifyEmails ||
    showNotifyStyling ||
    showNotifySettings ||
    showWysiwygEmailEditor ||
    showWysiwygSMSEditor ||
    showMessaging ||
    showMessagingSms;
  const showNotificationHistory =
    isCsm ||
    (messagingFeatures && messagingFeatures.notificationHistory) ||
    products.includes(NOTIFICATION_HISTORY_PRODUCT);
  const showTriggerSetup = ownerOrAdminOrManager || isCsm;
  const showChannelSetup = ownerOrAdminOrManager || isCsm;
  const showSettingsValidation =
    isCsm || (messagingFeatures && messagingFeatures.settingsValidation);

  // Partnerships
  const showPartnerships =
    ownerOrAdminOrUserOrAuthor && products.includes(PARTNERSHIPS_CSP_API);

  const showMagento =
    ownerOrAdminOrUserOrAuthor && products.includes(MAGENTO_PRODUCT);
  const showSfcc =
    ownerOrAdminOrUserOrAuthor && products.includes(SFCC_PRODUCT);
  const showLom = ownerOrAdminOrUserOrAuthor && products.includes(LOM_PRODUCT);
  const showBc = ownerOrAdminOrUserOrAuthor && products.includes(BC_PRODUCT);
  const showSap = ownerOrAdminOrUserOrAuthor && products.includes(SAP_PRODUCT);
  const showShopify =
    ownerOrAdminOrUserOrAuthor && products.includes(SHOPIFY_PRODUCT);
  const showKlaviyo =
    ownerOrAdminOrUserOrAuthor && showNotify && apis.includes('orders');
  const showAttentive = ownerOrAdminOrUserOrAuthor && showNotify;
  const showPartnershipProducts = [
    showPartnerships,
    showMagento,
    showSfcc,
    showLom,
    showBc,
    showSap,
    showShopify,
    showKlaviyo,
    showAttentive,
  ];

  // Monitor
  const showMonitor = products.includes(MONITOR_PRODUCT);
  const showMonitorV2 = products.includes(MONITOR_V2_PRODUCT);

  const showAdmin = permissions.companyManagement && ownerOrAdmin;

  // Apple Wallet
  const showAppleWallet =
    ownerOrAdmin && products.includes(APPLE_WALLET_PRODUCT);

  const showTms = products.includes(TRANSPORTATION_MANAGEMENT_PRODUCT);

  // Carrier management
  const showCarrierManagement = products.includes(DELIVERY_PRODUCT);

  // Integration Health
  const showIntegrationHealth = narvarAdmin;

  const retailersToEnableCarrierCreds = ['ftpl'];

  const retailersToEnableAnalytics = ['neimanmarcus', 'patagonia'];

  const showPlatform =
    showShip ||
    showTrack ||
    showShipEmbed ||
    showShipUnknownOrigin ||
    showDynamicTrack ||
    showCarrierManagement ||
    showPromise;

  const enableCarrierCredsForRetailer =
    ownerOrAdmin &&
    retailersToEnableCarrierCreds.includes(retailerMoniker) &&
    t2Retailer;
  const displaySettingsAndServiceCode = ownerOrAdmin && isCsm;
  const displayCarrierCreds =
    enableCarrierCredsForRetailer || displaySettingsAndServiceCode;

  const miscAdmin =
    (isNarvarAuthorCsm && permissions.companyManagement) ||
    permissions.userManagement ||
    permissions.tenants ||
    permissions.globalBroadcastMessage;

  const narvarEmployeeNotCsm = role.name.startsWith('NARVAR_') && !isCsm;

  const showHome = growthbook && growthbook.isOn('hub-homepage');
  const showJourneyBuilder = growthbook && growthbook.isOn('hub-journey');
  const showAssistAnalytics =
    growthbook && growthbook.isOn('hub-assist-analytics');

  const isPromiseOnboardingCompleted = promiseOnboarding === 'completed';

  // Menu Structure
  const menuStructure = {
    top: [
      {
        title: 'Home',
        icon: 'home',
        iconRight: 'newStatus',
        condition: showHome,
        link: narvarEmployeeNotCsm ? '/admin/tenants' : '/home',
        items: [],
      },
      {
        title: 'Labs',
        icon: 'labs',
        iconRight: 'newStatus',
        condition: true,
        link: '/labs',
        isNeohubLink: true,
        items: [],
      },
    ],
    product: [
      {
        title: 'Notify',
        condition: showNotify || exceptionsEnabled,
        icon: 'notify',
        items: [
          {
            title: 'Exceptions Analytics',
            condition: exceptionsEnabled,
            link: '/notify/exceptions/analytics',
          },
          {
            title: 'Exceptions Reports',
            condition: exceptionsEnabled,
            link: '/notify/exceptions/reports',
          },
          {
            title: 'Custom Email Templates',
            condition: showNotify && showNotifyEmails && !isNarvarAuthor,
            link: '/notify/emails',
          },
          {
            title: 'Email Engagement Analytics',
            condition: showNotify && showMessaging,
            link: '/messaging/email-dashboard-analytics',
          },
          {
            title: 'SMS Engagement Analytics',
            condition: showNotify && showMessagingSms,
            link: '/messaging/sms-dashboard-analytics',
          },
          {
            title: 'Channel Setup',
            condition: showNotify && showChannelSetup,
            items: [
              {
                title: 'Email',
                link: '/messaging/channel-setup/email',
                isNeohubLink: true,
                condition: true,
              },
              {
                title: 'SMS',
                link: '/messaging/channel-setup/sms',
                isNeohubLink: true,
                condition: true,
              },
              {
                title: 'Webhook',
                link: '/messaging/channel-setup/webhooks',
                isNeohubLink: true,
                condition: showMessagingWebhook,
              },
            ],
          },
          {
            title: 'Event Setup',
            condition: showNotify && showTriggerSetup,
            link: '/messaging/triggers',
            isNeohubLink: true,
          },
          {
            title: 'Templates',
            condition:
              showNotify &&
              (showWysiwygSMSEditor ||
                showWysiwygEmailEditor ||
                showMessagingWebhook),
            items: [
              {
                title: 'Test Data',
                condition:
                  showNotify &&
                  (showWysiwygEmailEditor || showMessagingWebhook),
                link: '/messaging/wysiwyg/test-data',
                isNeohubLink: true,
              },
              {
                title: 'Email',
                condition: showNotify && showWysiwygEmailEditor,
                link: '/messaging/email-templates',
                isNeohubLink: true,
              },
              {
                title: 'SMS',
                condition: showNotify && showWysiwygSMSEditor,
                link: '/messaging/sms-templates',
                isNeohubLink: true,
              },
              {
                title: 'Webhook',
                condition: showNotify && showMessagingWebhook,
                link: '/messaging/webhook/templates',
                isNeohubLink: true,
              },
            ],
          },
          {
            title: 'Image Assets',
            condition: showNotify && showWysiwygEmailEditor,
            link: '/marketing-assets',
            isNeohubLink: true,
          },
          {
            title: 'Settings Validation',
            condition: showNotify && showSettingsValidation && isCsm,
            items: [
              {
                title: 'Advanced Validations',
                condition: showNotify && showSettingsValidation,
                link: '/messaging/retailer-settings-validation',
                isNeohubLink: true,
              },
              {
                title: 'Settings Validations',
                condition: showNotify && showSettingsValidation,
                link: '/messaging/settings-validation',
                isNeohubLink: true,
              },
            ],
          },
          {
            title: 'Settings Validations',
            condition: showNotify && showSettingsValidation && !isCsm,
            link: '/messaging/settings-validation',
            isNeohubLink: true,
          },
          {
            title: 'Notification History',
            condition: showNotify && showNotificationHistory,
            link: '/messaging/notification-history-v2',
            isNeohubLink: true,
          },
        ],
      },
      {
        title: 'Promise',
        condition: showShipEmbed || showShip || showTms || showPromise,
        icon: 'ship',
        items: [
          {
            title: 'Settings',
            condition: !(showPromise || showShipEmbed),
            link: '/promise/settings',
          },
          {
            title: 'Embeds',
            condition: showShipEmbed && !showPromise,
            link: '/promise/embeds',
            isNeohubLink: true,
          },
          {
            title: 'A/B Testing',
            condition: !(showPromise || showShip),
            link: '/promise/ab-test',
            isNeohubLink: true,
          },
          {
            title: 'Local Delivery Settings',
            condition: showTms && !showPromise,
            link: '/local-delivery',
            isNeohubLink: true,
          },
          {
            title: 'Get Started',
            condition:
              showPromise &&
              !isPromiseOnboardingCompleted &&
              role.name !== 'ANALYST',
            link: (showShopify || showSfcc) ? '/onramp/platform' : '/onramp/promise',
            isNeohubLink: true,
          },
          {
            title: 'Phased Rollout',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/rollout',
            isNeohubLink: true,
          },
          {
            title: 'Delivery Options',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/delivery-options',
            isNeohubLink: true,
          },
          {
            title: 'Order Conversion Analytics',
            condition:
              (showPromise && isPromiseOnboardingCompleted) ||
              retailersToEnableAnalytics.includes(retailerMoniker),
            link: '/promise/roi',
          },
          {
            title: 'Delivery Performance Analytics',
            condition:
              (showPromise && isPromiseOnboardingCompleted) ||
              retailersToEnableAnalytics.includes(retailerMoniker),
            link: '/promise/delivery-performance',
            isNeohubLink: true,
          },
          {
            title: 'Simulations',
            condition: showPromise && isCsm && isPromiseOnboardingCompleted,
            link: '/promise/simulations',
            isNeohubLink: true,
          },
          {
            title: 'Locations',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/platform/locations',
            isNeohubLink: true,
          },
          {
            title: 'Holidays and Closures',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/holidays',
          },
          {
            title: 'Estimated Delivery Date Rules',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/edd-rules',
            isNeohubLink: showPromise && isPromiseOnboardingCompleted,
          },
          {
            title: 'Estimated Delivery Date Preview',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/edd-preview',
          },
          {
            title: 'Estimated Delivery Date Audit Page',
            condition: showPromise && isPromiseOnboardingCompleted,
            link: '/promise/edd-audit',
            isNeohubLink: false,
          },
        ],
      },
      {
        title: 'Dynamic Track',
        condition: showDynamicTrack,
        icon: 'track',
        items: [
          {
            title: 'Analytics',
            condition: permissions.analyst,
            link: '/track/analytics?dynamicTrack=true',
          },
          {
            title: 'Experiences',
            condition: permissions.designer,
            link: '/track/versions',
            isNeohubLink: true,
          },
          {
            title: 'Experience Manager',
            condition: permissions.designer,
            link: '/track/experience-manager',
            isNeohubLink: true,
          },
          {
            title: 'Image Assets',
            condition: permissions.designer,
            link: '/marketing-assets',
            isNeohubLink: true,
          },
          {
            title: 'Banner Messages',
            condition: permissions.designer && !isNarvarAuthor,
            link: '/track/banner-messages',
            isNeohubLink: true,
          },
          {
            title: 'Branding Assets',
            condition: permissions.designer,
            link: '/fonts',
            isNeohubLink: true,
          },
          {
            title: 'Pre-ship Statuses',
            condition:
              permissions.designer && dynamicTrackFeatures.preshipStatuses,
            link: '/track/preship-statuses',
            isNeohubLink: true,
          },
          {
            title: 'EDD Settings',
            condition:
              permissions.designer && isCsm && (ownerOrAdmin || isNarvarAuthor),
            link: '/track/settings/edd',
            isNeohubLink: false,
          },
          {
            title: 'EDD Image Styling',
            condition: permissions.designer && isCsm && narvarAdmin,
            link: '/track/style?dynamicTrack=true',
          },
          {
            title: 'Embeds',
            condition: permissions.designer && dynamicTrackFeatures.embedded,
            link: '/track/embeds',
            isNeohubLink: true,
          },
        ],
      },
      {
        title: 'Track',
        condition: showTrack && !isNarvarAuthor,
        icon: 'track',
        items: [
          {
            title: 'Analytics',
            condition: permissions.analyst,
            link: '/track/analytics',
          },
          {
            title: 'Messages',
            condition: permissions.broadcastMessage,
            link: '/track/message',
          },
          {
            title: 'Assets',
            condition: permissions.designer && ownerOrAdminOrManager,
            link: '/track/assets',
          },
          {
            title: 'Styling',
            condition: permissions.designer && isCsm && t2Retailer,
            link: '/track/style',
          },
          {
            title: 'Tracking Image Assets',
            condition:
              permissions.designer && isCsm && t2Retailer && !isNarvarAuthor,
            link: '/track/trackingimageassets',
          },
          {
            title: 'Files',
            condition: permissions.designer && isCsm && t2Retailer,
            link: '/track/files',
          },
          {
            title: 'Configurations',
            condition:
              permissions.designer && isCsm && t2Retailer && !isNarvarAuthor,
            link: '/track/configurations',
          },
          {
            title: 'Gifting Settings',
            condition: permissions.designer && t2Retailer && !isNarvarAuthor,
            link: '/track/gifting',
          },
          {
            title: 'Carrier Credentials',
            condition: displayCarrierCreds,
            link: '/track/credentials',
          },
          {
            title: 'Service Codes',
            condition: displaySettingsAndServiceCode,
            link: '/track/service-codes',
          },
          {
            title: 'Settings',
            condition: displaySettingsAndServiceCode,
            link: '/track/settings',
          },
        ],
      },
      {
        title: 'Return',
        condition: showReturn && !isNarvarAuthor,
        icon: 'return',
        items: [
          {
            title: 'Analytics',
            condition: isCsm || permissions.analyst,
            link: '/return/dashboard-analytics-2',
          },
          {
            title: 'Orders',
            condition: isCsm || permissions.analyst,
            link: '/return/order-list',
          },
          {
            title: 'Return Rules',
            condition:
              (isCsm || role.name === 'ADMIN') &&
              (products.includes(RETURN_RULES_VERSION_3_PRODUCT_NAME) ||
                products.includes(RETURN_RULES_PRODUCT_NAME)),
            link: products.includes(RETURN_RULES_VERSION_3_PRODUCT_NAME)
              ? '/return/return-rules-3.0'
              : '/return/return-rules',
          },
          {
            title: 'Carrier Credentials',
            condition:
              (isCsm || permissions.analyst) &&
              isCsm &&
              permissions.carrierManagement,
            link: '/return/credentials',
          },
          {
            title: 'Return Reasons',
            condition: (isCsm || permissions.analyst) && isCsm,
            link: '/return/return-reasons',
          },
          {
            title: 'Packing Slips Shipping Labels',
            condition: (isCsm || permissions.analyst) && isCsm,
            link: '/return/packing-slip-labels',
          },
          {
            title: 'UI Builder',
            condition: (isCsm || permissions.analyst) && showReturnsVersion3,
            link: '/return/uibuilder',
          },
          {
            title: 'Theme',
            iconRight: 'newStatus',
            condition: true,
            link: '/return/theme',
          },
          {
            title: 'Files',
            condition: (isCsm || permissions.analyst) && showReturnsVersion3,
            link: '/return/files',
          },
          {
            title: 'Settings',
            condition: (isCsm || permissions.analyst) && showReturnsVersion3,
            link: '/return/return-settings',
          },
          {
            title: 'Header Settings',
            condition: (isCsm || permissions.analyst) && isCsm && narvarAdmin,
            link: '/track/style?returns=true',
          },
          {
            title: 'Legacy Assets',
            condition: (isCsm || permissions.analyst) && isCsm && narvarAdmin,
            link: '/track/assets',
          },
          {
            title: 'Settings Validation',
            condition:
              (isCsm || permissions.analyst) && showReturnSettingsValidation,
            link: '/return/settings-validation',
            isNeohubLink: true,
          },
          {
            title: 'Restricted Emails',
            condition: showReturnsRestrictedEmails,
            link: '/return/restricted-emails',
          },
        ],
      },
      {
        title: 'Assist',
        condition: showAssist && !isNarvarAuthor,
        icon: 'assist',
        items: [
          {
            title: 'Policy Editor',
            condition: showJourneyBuilder,
            link: '/assist/policy-editor',
            isNeohubLink: true,
            iconRight: 'newStatus',
          },
          {
            title: 'Analytics',
            condition: showAssistAnalytics,
            link: '/assist/analytics',
          },
        ],
      },
      {
        title: 'Partnerships',
        condition: showPartnershipProducts.includes(true),
        icon: 'assist',
        items: [
          {
            title: 'Salesforce',
            condition: showPartnerships,
            link: '/partnerships/salesforce',
          },
          {
            title: 'Zendesk',
            condition: showPartnerships,
            link: '/partnerships/zendesk',
          },
          {
            title: 'Magento',
            condition: showMagento,
            link: '/partnerships/magento',
          },
          {
            title: 'SFCC',
            condition: showSfcc,
            link: '/partnerships/sfcc',
            isNeohubLink: true,
          },
          {
            title: 'LOM',
            condition: showLom,
            link: '/partnerships/lom',
          },
          {
            title: 'Bigcommerce',
            condition: showBc,
            link: '/partnerships/bigcommerce',
          },
          {
            title: 'SAP',
            condition: showSap,
            link: '/partnerships/sap',
          },
          {
            title: 'Shopify',
            condition: showShopify,
            link: '/partnerships/shopify',
          },
          {
            title: 'Klaviyo',
            condition: showKlaviyo,
            link: '/partnerships/klaviyo',
          },
          {
            title: 'Attentive',
            condition: showAttentive,
            link: '/partnerships/attentive',
          },
        ],
      },
      {
        title: showMonitorV2 ? 'Monitor (Classic)' : 'Monitor',
        condition: showMonitor && !isNarvarAuthor,
        icon: 'monitor',
        items: [
          ...Object.keys(dashboards).map(key => ({
            title: dashboards[key].display_name,
            condition: true,
            link: `/monitor/dashboards/${key.replace(/_/g, '-')}`,
          })),
          {
            title: 'Packages',
            condition: reportDefinition,
            link: `/monitor/packages${
              reportDefinition ? buildPackagesQuery(reportDefinition) : ''
            }`,
          },
        ],
      },
      {
        title: 'Monitor',
        condition: showMonitorV2 && !isNarvarAuthor,
        icon: 'monitor',
        items: [
          {
            title: 'Dashboard',
            condition: true,
            isAbsolute: true,
            link: `https://${retailerMoniker}.${monitorV2UrlBase}`,
          },
        ],
      },
      {
        title: 'Apple Wallet',
        condition: showAppleWallet,
        icon: 'apple',
        items: [
          {
            title: 'Apple Wallet Integration Setup',
            condition: true,
            link: '/partnerships/apple-wallet',
            isNeohubLink: true,
          },
        ],
      },
    ],
    general: [
      {
        title: 'Integration Health',
        condition: showIntegrationHealth,
        icon: 'monitor',
        items: [
          {
            title: 'Order Data Quality',
            condition: true,
            link: '/integration-health/orders',
            isNeohubLink: true,
          },
        ],
      },
      {
        title: 'Locations',
        condition: (showTms && !isNarvarAuthor) || showPlatform,
        icon: 'location',
        link: '/platform/locations',
        isNeohubLink: true,
        items: [],
      },
      {
        title: 'Estimated Delivery Date Settings',
        condition: showPlatform,
        icon: 'platform',
        items: [
          {
            title: 'Estimated Delivery Date Rules',
            condition: true,
            link: '/promise/edd-rules',
            isNeohubLink: true,
          },
          {
            title: 'Holidays and Closures',
            condition: true,
            link: '/platform/holidays',
          },
          {
            title: 'Working Days',
            condition: !showPromise,
            link: '/platform/working-days',
          },
          {
            title: 'Estimated Delivery Date Preview',
            condition: true,
            link: '/platform/edd-preview',
          },
          {
            title: 'Unknown Origin Settings',
            condition: showShipUnknownOrigin,
            link: '/platform/unknown-origin',
            isNeohubLink: true,
          },
          {
            title: 'Carrier Management',
            condition:
              showCarrierManagement && (isAdminOrUser || isNarvarAuthor),
            link: '/carrier-management',
            isNeohubLink: true,
          },
          {
            title: 'Shipping Costs',
            condition:
              showCarrierManagement && (isAdminOrUser || isNarvarAuthor),
            link: '/shipping-costs',
            isNeohubLink: true,
          },
          {
            title: 'Estimated Delivery Date Audit Page',
            condition: true,
            link: '/platform/edd-audit',
            isNeohubLink: false,
          },
        ],
      },
      {
        title: 'Carriers',
        condition: (permissions.carrierManagement || isCsm) && !isNarvarAuthor,
        icon: 'carriers',
        items: [
          {
            title: 'Status Codes',
            condition: true,
            link: '/couriers/courier-selector',
          },
          {
            title: 'Carrier Alias',
            condition: true,
            link: '/couriers/carrier-alias',
          },
          {
            title: 'Event Push',
            condition: isCsm && apis.includes('carrier-data'),
            link: '/carrier/push',
          },
        ],
      },
      {
        title: 'Admin',
        condition: showAdmin || miscAdmin,
        icon: 'admin',
        items: [
          {
            title: 'Security',
            condition:
              showAdmin || (isNarvarAuthorCsm && permissions.companyManagement),
            link: '/admin/security',
            isNeohubLink: true,
          },
          {
            title: 'Company',
            condition:
              showAdmin || (isNarvarAuthorCsm && permissions.companyManagement),
            link: '/admin/company',
          },
          {
            title: 'Settings',
            condition: narvarAdmin,
            link: '/track/configurations',
          },
          {
            title: 'Users',
            condition: permissions.userManagement,
            link: '/admin/users',
            isNeohubLink: true,
          },
          {
            title: 'Tenants',
            condition: permissions.tenants,
            link: '/admin/tenants',
          },
          {
            title: 'Messages',
            condition: permissions.globalBroadcastMessage,
            link: '/admin/messages',
          },
        ],
      },
    ],
  };

  const buildSections = menuTree => {
    return menuTree
      .filter(menuItem => menuItem.condition)
      .map(menuItem => {
        if (menuItem.items && menuItem.items.length > 0) {
          /* eslint-disable no-param-reassign */
          menuItem.items = buildSections(menuItem.items);
        }

        const { condition, ...rest } = menuItem;

        const title = i18n[menuItem.title] || `${menuItem.title}`;

        return { ...rest, title };
      });
  };

  return {
    top: buildSections(menuStructure.top),
    product: buildSections(menuStructure.product),
    general: buildSections(menuStructure.general),
  };
};

export default getSections;
