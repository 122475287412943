import React from 'react';
import { MigrationCard } from '../ui';

export const keeptheitem = ({ option }) => ({
  option,
  newOption: {
    option: {
      id: 'keeptheitem',
      methods: ['keeptheitem'],
      displayMethods: false,
    },
    methods: {
      keeptheitem: {
        id: 'keeptheitem',
        capture_user_payment: false,
      },
    },
  },
  message: (
    <MigrationCard title="Keep the Item" validation="valid">
      Keep the item will be migrated.
    </MigrationCard>
  ),
});
