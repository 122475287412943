import { css } from 'emotion';
import { colors } from '@narvar/hum-constants';

const s = css`
  background-color: ${colors.white};
  border: 1px solid ${colors.lightPurple};
  box-shadow: 0 4px 11px -8px ${colors.darkGrey}, 0 0 4px 1px ${colors.lightBlue};
  border-radius: 4px;
  margin: 0 auto 20px;
  padding: 20px;
  position: relative;
  width: 100%;

  &.is-collapsed {
    max-height: 60px;
    overflow: hidden;
  }

  & .controls {
    position: absolute;
    right: 20px;
    top: 15px;

    & span {
      height: 35px;
    }

    & svg {
      cursor: pointer;
      margin-left: 15px;

      & path,
      & polygon {
        fill-opacity: 0.4;
      }

      &:hover {
        & path,
        & polygon {
          fill-opacity: 0.8;
        }
      }
      
      &:active {
        & path,
        & polygon {
          fill-opacity: 1;
        }
      }
    }
    
    & svg[name="delete"] {
      &:hover {
        & path {
          fill: ${colors.red};
          fill-opacity: 1;
        }
      }
      &:active {
        & path {
          fill: ${colors.darkRed};
        }
      }
    }
    

    & .chevron {
      cursor: pointer;
      margin-bottom: 4px;
      transition: transform 200ms;
    }

  }

  & .chevron-down {
    margin: auto 0;
    transform: rotate(90deg) scale(0.5);
  }

  & .chevron-up {
    margin: auto 0;
    transform: rotate(-90deg) scale(0.5);
  }

  & .content {
    background-color: ${colors.white};
    opacity: 1;
    transition: opacity 400ms;

    &.is-fetching {
      opacity: 0;
      min-height: 100px;
    }

    & h3 {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 20px;
    }

    & > :last-child {
      .form-group {
        margin-bottom: 0;
      }
    }
  }

  & .card-loader {
    position: absolute;
    left: 50%;
    top: 45%;
    margin-left: -40px;
    margin-top: -10px;
  }

@media print {
  .Card {
    border: none;
    page-break-inside: avoid;
  }
}`;

export default s;
