import { RECEIVE_CSM_LOGOUT } from '../actions/userActions';
import { DISMISS_ALERT } from '../actions/uiActions';

import {
  WYSIWYG_EDITOR_RECEIVE_LOCALES,
  WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LIST,
  WYSIWYG_EDITOR_RECEIVE_BLOCK_LIST,
  WYSIWYG_EDITOR_RECEIVE_GLOBAL_SETTINGS,
  WYSIWYG_EDITOR_RECEIVE_TEMPLATE,
  WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LOCALES,
  WYSIWYG_EDITOR_RECEIVE_COMPONENTS,
  WYSIWYG_EDITOR_RECEIVE_BLOCK,
  WYSIWYG_EDITOR_RECEIVE_BLOCK_LOCALES,
  WYSIWYG_EDITOR_SUCCESS_ALERT,
  WYSIWYG_EDITOR_ERROR_ALERT,
  WYSIWYG_EDITOR_RECEIVE_TEST_DATA_LIST,
  WYSIWYG_EDITOR_RECEIVE_TEST_DATA_DETAILS,
  WYSIWYG_EDITOR_PREVIEW_TEMPLATE,
  WYSIWYG_EDITOR_RECEIVE_TEMPLATE_DETAILS,
  WYSIWYG_EDITOR_RECEIVE_AFFECTED_TEMPLATE,
  WYSIWYG_EDITOR_CLEAR_DETAILS,
  WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST,
  WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST_CUSTOM,
  WYSIWYG_EDITOR_UPDATE_TEMPLATE_DETAILS_ON_PUBLISH_UN_PUBLISH,
  WYSIWYG_FETCH_CHANNEL_LIST,
} from '../actions/wysiwygActions';
import { SUCCESS, DANGER } from '../constants/colors';

export const initialState = {
  publishedTemplates: [],
  supportedLocales: [],
  templateList: [],
  blockList: [],
  globalSettings: {},
  template: {},
  templateLocales: [],
  block: {},
  blockLocales: [],
  components: undefined,
  testDataList: [],
  testDataDetails: {},
  templateDetails: {},
  alert: {
    show: false,
  },
  previewMarkup: null,
  affectedTemplates: [],
  messagingFieldOptions: {
    channelsList: [],
    triggersList: [],
    isNewRetailer: false,
  },
};

const wysiwygReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case RECEIVE_CSM_LOGOUT:
      return initialState;
    case WYSIWYG_EDITOR_RECEIVE_LOCALES:
      return {
        ...state,
        supportedLocales: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LIST:
      return {
        ...state,
        templateList: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_BLOCK_LIST:
      return {
        ...state,
        blockList: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_GLOBAL_SETTINGS:
      return {
        ...state,
        globalSettings: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_COMPONENTS:
      return {
        ...state,
        components: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_BLOCK:
      return {
        ...state,
        block: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_BLOCK_LOCALES:
      return {
        ...state,
        blockLocales: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEMPLATE:
      return {
        ...state,
        template: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LOCALES:
      return {
        ...state,
        templateLocales: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEST_DATA_LIST:
      return {
        ...state,
        testDataList: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEST_DATA_DETAILS:
      return {
        ...state,
        testDataDetails: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_TEMPLATE_DETAILS:
      return {
        ...state,
        templateDetails: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST:
      return {
        ...state,
        publishedTemplates: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST_CUSTOM:
      return {
        ...state,
        publishedTemplatesCustom: payload,
      };
    case WYSIWYG_EDITOR_PREVIEW_TEMPLATE:
      return {
        ...state,
        previewMarkup: payload,
      };
    case WYSIWYG_EDITOR_RECEIVE_AFFECTED_TEMPLATE:
      return {
        ...state,
        affectedTemplates: payload,
      };
    case WYSIWYG_EDITOR_UPDATE_TEMPLATE_DETAILS_ON_PUBLISH_UN_PUBLISH:
      return {
        ...state,
        templateDetails: payload.templateDetails,
        publishedTemplates: payload.publishedTemplates,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          ...initialState.alert,
        },
      };
    case WYSIWYG_EDITOR_SUCCESS_ALERT:
      return {
        ...state,
        alert: {
          color: SUCCESS,
          show: true,
          text: payload,
        },
      };
    case WYSIWYG_EDITOR_ERROR_ALERT:
      return {
        ...state,
        alert: {
          color: DANGER,
          show: true,
          text: payload,
        },
      };
    case WYSIWYG_FETCH_CHANNEL_LIST:
      return {
        ...state,
        messagingFieldOptions: {
          ...state.messagingFieldOptions,
          channelsList: payload.channelOptions,
          triggersList: payload.triggerOptions,
          isNewRetailer: payload.isNewRetailer,
        },
      };
    case WYSIWYG_EDITOR_CLEAR_DETAILS:
      return initialState;
    default:
      return state;
  }
};

export default wysiwygReducer;
