import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import {
  RETURNS_SUPPORTED_LOCALES,
  RETURNS_ENABLE_NEW_RETAILER,
  RETURNS_SETTINGS,
  RETURNS_TEMPLATE,
  CSM_TRACKING_ASSETS_SAVE,
  RETURNS_MODIFY_SETTINGS,
  RETURNS_CREATE_TEST_ORDER,
  SEND_EMAIL,
} from '~src/constants/endpoints';
import {
  downloadPortableConfig,
  uploadPortableConfig,
  confirmUploadPortableConfig,
  isManifestHasValidModules,
} from '~src/actions/portableConfigActions';
import {
  PUBLISH_EMAIL_TEMPLATE_ID,
  PREVIEW_EMAIL_TEMPLATE_ID,
} from '~src/constants/returns/configurations';
import { formatCreatedAndModifiedDate } from '~src/lib/helpers';

export const RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE =
  'RETURNS_SETTING/RECEIVE_UPLOAD_RESPONSE';
export const RESET_UPLOADED_PORTABLE_CONFIG =
  'RETURNS_SETTING/RESET_UPLOADED_CONFIG';
export const RECEIVE_SUPPORTED_LOCALES =
  'RETURNS_SETTINGS/RECEIVE_SUPPORTED_LOCALES';
export const RECEIVE_SETTINGS = 'RETURNS_SETTINGS/RECEIVE_SETTINGS';
export const RECEIVE_TEMPLATE_SETTINGS =
  'RETURNS_SETTINGS/RECEIVE_TEMPLATE_SETTINGS';
export const RECEIVE_SETTINGS_ERROR = 'RETURNS_SETTINGS/RECEIVE_SETTINGS_ERROR';
export const RECEIVE_SETTING_SUCCESS =
  'RETURNS_SETTINGS/RECEIVE_SETTING_SUCCESS';
export const RESET_SETTINGS = 'RETURNS_SETTINGS/RESET_SETTINGS';
export const RECEIVE_SETTINGS_PUBLISH =
  'RETURNS_SETTINGS/RECEIVE_SETTINGS_PUBLISH';
export const RESET_ALERTS = 'RETURNS_SETTINGS/RESET_ALERTS';
export const RECEIVE_PREVIEW_INFO = 'RETURNS_SETTINGS/RECEIVE_PREVIEW_INFO';
export const RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS =
  'RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS';

export const generateReturnsPreviewUrl = ({ locale, uriMoniker, version }) => {
  const RETURNS_PREVIEW_DOMAIN = window.location.origin.replace(
    'hub',
    'returns',
  );
  const versionParam = version ? `&preview=true&version=${version}` : '';
  return `${RETURNS_PREVIEW_DOMAIN}/${uriMoniker}/returns?locale=${locale}${versionParam}`;
};

export const resetReturnsSettings = () => ({
  type: RESET_SETTINGS,
});

export const resetAlerts = () => ({
  type: RESET_ALERTS,
});

export const receiveError = errorText => ({
  type: RECEIVE_SETTINGS_ERROR,
  payload: {
    text: errorText,
  },
});

export const receieveProductAttributeList = allowedAttributes => ({
  type: RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS,
  payload: {
    productAttributeDisplaySettings: { allowedAttributes },
  },
});

export const saveReturnsLocaleSetting = ({
  uriMoniker,
  supportedLocales,
  localeCode,
  readableLocale,
}) => async dispatch => {
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: `${RETURNS_ENABLE_NEW_RETAILER}/${uriMoniker}`,
      name: 'saveReturnsLocaleSetting',
      body: JSON.stringify({
        locales: supportedLocales,
        defaultLocale: localeCode,
        defaultReadableLocale: readableLocale,
      }),
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${RETURNS_ENABLE_NEW_RETAILER}/${uriMoniker}`,
    });
    return dispatch({
      type: RECEIVE_SETTING_SUCCESS,
      payload: {
        text: 'Successfully Save Locale Setting',
      },
    });
  } catch (error) {
    return dispatch(receiveError(error.errorMessage));
  }
};

export const fetchReturnsSupportedLocales = uriMoniker => async dispatch => {
  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${RETURNS_SUPPORTED_LOCALES}/${uriMoniker}`,
      name: 'fetchReturnsSupportedLocales',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: `${RETURNS_SUPPORTED_LOCALES}/${uriMoniker}`,
    });
    return dispatch({
      type: RECEIVE_SUPPORTED_LOCALES,
      payload: {
        supportedLocales: response,
      },
    });
  } catch (error) {
    const text = `There was an issue loading the Return locales: ${error.errorMessage}`;
    return dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text,
      },
    });
  }
};

export const fetchTemplateSettings = locale => async dispatch => {
  try {
    const path = `${RETURNS_TEMPLATE}/${locale}`;
    const {
      returns_settings_json: returnsSettings,
      language_json: language,
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
      name: 'fetchTemplateSettings',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    return dispatch({
      type: RECEIVE_TEMPLATE_SETTINGS,
      payload: {
        returnsSettings,
        language,
      },
    });
  } catch (error) {
    const text = `There was an issue loading the Return Template locale: ${error.errorMessage}`;
    return dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text,
      },
    });
  }
};

export const fetchReturnsSettings = (uriMoniker, locale) => async dispatch => {
  try {
    const path = `${RETURNS_SETTINGS}/${uriMoniker}/${locale}`;
    const {
      returns_settings_json: returnsSettings,
      language_json: language,
      createdDate,
      createdFirstName,
      createdLastName,
      modifiedDate,
      modifiedFirstName,
      modifiedLastName,
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path,
      name: 'fetchReturnsSettings',
    });

    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    return dispatch({
      type: RECEIVE_SETTINGS,
      payload: {
        returnsSettings,
        language,
        returnsSettingsCreated: formatCreatedAndModifiedDate({
          date: createdDate,
          firstName: createdFirstName,
          lastName: createdLastName,
        }),
        returnsSettingsModified: formatCreatedAndModifiedDate({
          date: modifiedDate,
          firstName: modifiedFirstName,
          lastName: modifiedLastName,
        }),
      },
    });
  } catch (error) {
    const text = `There was an issue loading the Return locales: ${error.errorMessage}`;
    return dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text,
      },
    });
  }
};

export const uploadAsset = ({ file, fileName, type }) => async dispatch => {
  const form = new FormData();
  form.append('file', file, fileName);

  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: `${CSM_TRACKING_ASSETS_SAVE}/assets?type=${type}`,
      body: form,
      contentType: false,
      name: 'fetchUploadReturnsAsset',
    });
    return response.asset_url;
  } catch (error) {
    throw new Error('There was an issue uploading the file.');
  }
};

export const sendConfirmationEmail = ({
  uriMoniker,
  previewUrl,
  order,
  zip,
  email,
  templateId,
}) => async (dispatch, getState) => {
  const {
    userReducer: {
      user: { email: currentUserEmail, firstName, lastName },
    },
  } = getState();
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: SEND_EMAIL,
      body: JSON.stringify({
        content: {
          template_id: templateId,
        },
        substitution_data: {
          first_name: firstName,
          retailer_name: uriMoniker,
          returns_preview_link: previewUrl,
          billing_zip_code: zip,
          shipping_zip_code: zip,
          order_number: order,
          email,
        },
        recipients: [
          {
            address: {
              email: currentUserEmail,
              name: `${firstName} ${lastName}`,
            },
          },
        ],
      }),
      name: 'sendConfirmationEmail',
    });
    return currentUserEmail;
  } catch (error) {
    dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text: 'An error occurred for sending the confirmation email',
      },
    });
    return '';
  }
};

export const publishReturnsSettings = ({
  uriMoniker,
  locale,
  returnsSettings,
  language,
}) => async dispatch => {
  try {
    const previewUrl = generateReturnsPreviewUrl({ locale, uriMoniker });
    await dispatch(resetAlerts());
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: `${RETURNS_MODIFY_SETTINGS}/${uriMoniker}/${locale}`,
      body: JSON.stringify({
        returns_settings_json: returnsSettings,
        language_json: language,
      }),
      name: 'publishReturnsSettings',
    });

    const confirmationEmail = await dispatch(
      sendConfirmationEmail({
        order: null,
        zip: null,
        email: null,
        uriMoniker,
        previewUrl,
        templateId: PUBLISH_EMAIL_TEMPLATE_ID,
      }),
    );

    return dispatch({
      type: RECEIVE_SETTINGS_PUBLISH,
      payload: {
        url: previewUrl,
        confirmationEmail,
      },
    });
  } catch (error) {
    const text = 'There was an issue with publishing the Settings';
    return dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text,
      },
    });
  }
};

export const fetchPreview = ({
  uriMoniker,
  locale,
  returnsSettings,
  version,
  isTestOrderEligible,
}) => async dispatch => {
  try {
    await dispatch(resetAlerts());

    // TODO: should make these 3 API we called within this Actions into 1
    // move the checking logic into the Portal-Auth

    const secondCredentialType =
      returnsSettings.returns.validation.input_controls[1][0].name;
    const previewUrl = generateReturnsPreviewUrl({
      locale,
      uriMoniker,
      version,
    });
    let testCredential = { order: null, zip: null, email: null };

    if (isTestOrderEligible) {
      const {
        response: {
          order_info: {
            order_number: testOrder,
            billing: {
              billed_to: {
                email: testEmail,
                address: { zip: testZip } = {},
              } = {},
            } = {},
          } = {},
        } = {},
      } = await dispatch({
        type: API_REQUEST,
        method: 'GET',
        path: RETURNS_CREATE_TEST_ORDER(locale),
        name: 'fetchReturnsPreviewTestOrder',
      });

      testCredential = {
        order: testOrder,
        zip: secondCredentialType === 'zip' ? testZip : null,
        email: secondCredentialType === 'email' ? testEmail : null,
      };

      dispatch({
        type: API_INVALIDATE_PATH,
        path: RETURNS_CREATE_TEST_ORDER(locale),
      });
    }

    const confirmationEmail = await dispatch(
      sendConfirmationEmail({
        ...testCredential,
        uriMoniker,
        previewUrl,
        templateId: PREVIEW_EMAIL_TEMPLATE_ID,
      }),
    );

    dispatch({
      type: RECEIVE_PREVIEW_INFO,
      payload: {
        ...testCredential,
        url: previewUrl,
        confirmationEmail,
      },
    });
  } catch (error) {
    dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text:
          'An error occurred during generation of the preview link and credentials',
      },
    });
  }
};

export const downloadReturnSettingPortableConfig = () => async dispatch => {
  try {
    await dispatch(
      downloadPortableConfig({
        appName: 'returns',
        module: { settings: true },
      }),
    );
    dispatch({
      type: RECEIVE_SETTING_SUCCESS,
      payload: {
        text: 'Successfully Download',
      },
    });
  } catch (error) {
    dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text: 'Download Fail',
      },
    });
  }
};

export const uploadReturnSettingPortableConfig = ({
  selectedFile,
}) => async dispatch => {
  try {
    const {
      data: manifest,
      data: { module },
    } = await dispatch(
      uploadPortableConfig({
        selectedFile,
      }),
    );

    const isUploadedConfigValid = isManifestHasValidModules({
      manifestModule: module,
      validModules: ['settings'],
    });

    if (isUploadedConfigValid) {
      dispatch({
        type: RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
        payload: { manifest },
      });
    } else {
      dispatch({
        type: RECEIVE_SETTINGS_ERROR,
        payload: {
          text: 'Please only upload ReturnSetting Configuration on this page',
        },
      });
    }
  } catch (error) {
    dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text: 'Upload Fail',
      },
    });
  }
};

export const resetUploadedPortableConfig = () => dispatch => {
  dispatch({
    type: RESET_UPLOADED_PORTABLE_CONFIG,
  });
};

export const confirmUploadReturnSettingPortableConfig = ({
  manifest,
  uriMoniker,
}) => async dispatch => {
  try {
    await dispatch(
      confirmUploadPortableConfig({
        manifest: {
          ...manifest,
          module: {
            settings: true,
          },
        },
      }),
    );
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch({
      type: RECEIVE_SETTING_SUCCESS,
      payload: {
        text: 'Successfully Upload',
      },
    });
    dispatch(fetchReturnsSupportedLocales(uriMoniker));
  } catch (error) {
    dispatch({
      type: RESET_UPLOADED_PORTABLE_CONFIG,
    });
    dispatch({
      type: RECEIVE_SETTINGS_ERROR,
      payload: {
        text: 'Upload Fail',
      },
    });
  }
};

export const receieveProductAttributeSettings = productAttributeDisplaySettings => ({
  type: RECEIVE_PRODUCT_ATTRIBUTE_SETTINGS,
  payload: { productAttributeDisplaySettings },
});
