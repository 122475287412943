import { API_REQUEST } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';

export const RECEIVE_ENTITLEMENT_PRODUCTS = 'RECEIVE_ENTITLEMENT_PRODUCTS';
export const RECEIVE_ENTITLEMENT_PRODUCTS_ERROR =
  'RECEIVE_ENTITLEMENT_PRODUCTS_ERROR';

export const receiveEntitlementProducts = products => ({
  payload: {
    products,
  },
  type: RECEIVE_ENTITLEMENT_PRODUCTS,
});

export const receiveEntitlementProductsError = error => ({
  payload: {
    error,
  },
  type: RECEIVE_ENTITLEMENT_PRODUCTS_ERROR,
});

export const fetchEntitlementProducts = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.ENTITLEMENT_PRODUCTS,
    name: 'fetchEntitlementProducts',
  }).then(
    json => dispatch(receiveEntitlementProducts(json)),
    err => dispatch(receiveEntitlementProductsError(err)),
  );
