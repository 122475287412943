import {
  RECEIVE_EDD_RULES_INFO,
  REQUEST_EDD_RULES_INFO,
  RECEIVE_EDD_RULE_DELETE_SUCCESS,
  RECEIVE_EDD_RULE_DATA,
  RECEIVE_EDD_RULES_INFO_ERROR,
  RECEIVE_EDD_RULES_INFO_UPDATE,
  RECEIVE_EDD_RULES_INFO_PUBLISH,
  CLEAR_EDD_RULES_FORM_AND_ERRORS,
} from '~src/actions/eddRulesInfoActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';
import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = {
  alert: {
    show: false,
  },
  isFetching: false,
  rule: {},
  rules: [],
  totalRecords: 0,
  totalPages: 0,
  currentPageIndex: 0,
  recordsPerPage: 0,
  publishedNewRule: false,
};

function eddRulesInfo(state = initialState, action) {
  const { meta, payload, type } = action;
  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_EDD_RULES_INFO:
      return {
        ...state,
        rules: [...payload],
        totalRecords: meta.totalRecords,
        totalPages: meta.totalPages,
        currentPageIndex: meta.currentPageIndex,
        recordsPerPage: meta.recordsPerPage,
      };

    case RECEIVE_EDD_RULE_DATA:
      return {
        ...state,
        isFetching: false,
        rule: {
          ...payload,
        },
      };

    case RECEIVE_EDD_RULES_INFO_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case RECEIVE_EDD_RULES_INFO_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes are published successfully.',
        },
        rule: {
          ...payload,
        },
      };

    case RECEIVE_EDD_RULE_DELETE_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: meta,
        },
      };

    case RECEIVE_EDD_RULES_INFO_PUBLISH:
      return {
        ...state,
        publishedNewRule: true,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes are published successfully.',
        },
        rule: {
          ...payload,
        },
      };

    case CLEAR_EDD_RULES_FORM_AND_ERRORS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

export default eddRulesInfo;
