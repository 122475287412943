import { GrowthBook } from '@growthbook/growthbook-react';
import noflake from '~src/lib/noflake';

function enableDevMode() {
  if (process.env.NODE_ENV === 'development') {
    return true;
  }

  if (
    typeof window !== 'undefined' &&
    window.location.search.includes('growthbook_dev')
  ) {
    return true;
  }

  return false;
}

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.GROWTHBOOK_KEY || '',
  enableDevMode: enableDevMode(),
  trackingCallback: (experiment, result) => {
    const logEvent = noflake(process.env.NOFLAKE_URL);

    const attributes = growthbook.getAttributes();
    if (!attributes.username || !attributes.retailer) {
      return;
    }

    logEvent({
      username: attributes.username,
      request_method: 'experiment',
      retailer_moniker: attributes.retailer,
      response_status_code: experiment.key,
      response_status_message: String(result.value),
    });
  },
  onFeatureUsage: (key, result) => {
    const logEvent = noflake(process.env.NOFLAKE_URL);

    const attributes = growthbook.getAttributes();
    if (!attributes.username || !attributes.retailer) {
      return;
    }

    logEvent({
      username: attributes.username,
      request_method: 'feature',
      retailer_moniker: attributes.retailer,
      response_status_code: key,
      response_status_message: String(result.value),
    });
  },
});
