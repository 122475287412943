import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Checkbox from '../Checkbox';
import FormControl from '../FormControl';
import s from './MultiTimeInput.styles';

class MultiTimeInput extends Component {
  state = {
    items: this.props.items,
    error: [],
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      items: nextProps.items,
    });
  }

  handleBlur = index => event => {
    const { items, error } = this.state;
    const { onChange } = this.props;
    const { value } = event.target;
    let updatedTime = value.toUpperCase();
    const validationError =
      moment(updatedTime, 'h:mm A', true).isValid() ? '' : 'Please use "hh:mm AM/PM" format';

    // Formatting Input Field for time format hh:mm am/pm
    if (!validationError) {
      updatedTime = moment(updatedTime, 'h:mm A').format('hh:mm A');
      const meridiemRegex = /[AP][M]$/;
      if (!meridiemRegex.test(updatedTime)) {
        updatedTime.concat('M');
      }
    }

    items[index].time = updatedTime;
    error[index] = validationError;
    onChange(items, index);
    this.setState({
      items,
      error,
    });
  }

  handleKeyDown = event => {
    /* Accepted Values
      * Numbers 0 - 9
      * Letters : [AM,PM,am,pm]
      * ASCII Codes: 3 -> ETX, 8 -> backspace, 186 -> colon,
      * 32 -> space, 37 -> left arrow, 39 -> right arrow
    */
    const { key, keyCode } = event;
    const acceptedKeyCodes = [3, 8, 186, 32, 37, 39];
    const acceptedKeyValues = ['A', 'P', 'M'];
    if (
      !((keyCode >= 48 && keyCode <= 58) ||
      acceptedKeyCodes.includes(keyCode) ||
      acceptedKeyValues.includes(key.toUpperCase()))
    ) {
      event.preventDefault();
    }
  }

  handleTimeChange = index => event => {
    const { items, error } = this.state;
    const { value } = event.target;
    const inputLength = value.length;
    let updatedTime = value.toUpperCase();

    if (inputLength === 1 && (value < 10 && value > 1)) {
      updatedTime = `0${value}:`;
    }

    if (inputLength === 2 && (value.indexOf(':') < 0)) {
      updatedTime += ':';
    }

    items[index].time = updatedTime;
    error[index] = '';
    this.setState({ items, error });
  }

  render() {
    const { error } = this.state;
    const { items, onChange } = this.props;
    return (
      <div className={s}>
        {items.map((item, index) => {
          const { selected, time, label } = item;
          return (
            <div className="multi-select-time" key={label}>
              <Checkbox
                item={{
                  selected,
                  time,
                  label,
                  name: label,
                }}
                index={index}
                readableKey="label"
                onChange={() => {
                  items[index].selected = !items[index].selected;
                  onChange(items, index);
                  this.setState({ items });
                }}
              />
              <FormControl
                data={time}
                className="time-input"
                name={label}
                value={time}
                onChange={this.handleTimeChange(index)}
                onKeyDown={this.handleKeyDown}
                onBlur={this.handleBlur(index)}
                validationError={error[index]}
                placeholder="12:00 AM"
                type="text"
              />
            </div>
          );
        })}
      </div>
    );
  }
}

MultiTimeInput.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    time: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MultiTimeInput;
