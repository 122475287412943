import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './TableRow.styles';
import Svg from '../Svg';
import TableCell from '../TableCell';

export class TableRow extends React.Component {
  render() {
    const { headings, htmlTableCells, name, row, rowIndex } = this.props;

    // Make sure we don't create more body cells than heading cells
    const text = row.text.slice(0, headings ? headings.length : 0);
    const dataAttributes = row.dataAttributes
      ? {
          ...row.dataAttributes,
        }
      : {};

    return (
      <tr
        className={cx(s, row.onRowClick ? 'clickable' : '')}
        data-index={rowIndex}
        data-name={name}
        onClick={row.onRowClick}
        data-test={`narvar-table-row-${rowIndex}`}
        {...dataAttributes}
      >
        {text.map((data, dataIndex) => (
          <TableCell
            data={data}
            dataIndex={dataIndex}
            key={dataIndex}
            width={headings[dataIndex].width}
            isHtml={
              htmlTableCells !== undefined
                ? htmlTableCells[dataIndex]
                : undefined
            }
          />
        ))}
      </tr>
    );
  }
}

TableRow.propTypes = {
  headings: PropTypes.array,
  htmlTableCells: PropTypes.array,
  name: PropTypes.string,
  row: PropTypes.object,
  rowIndex: PropTypes.number,
  isHtml: PropTypes.bool,
};

export default TableRow;
