import cloneDeep from 'lodash.clonedeep';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  memorySizeOf,
  removeLineBreaks,
  formatByteSize,
} from '~src/lib/helpers';
import { trackChatDefaults } from '~src/reducers/experienceManagerHelper';
import {
  RECEIVE_ERRORS,
  DISMISS_ALERT,
  DISMISS_ASSET_MODAL,
  RECEIVE_ASSETS,
  RECEIVE_TRACKING_IMG_SETTINGS,
  REQUEST_IMAGE_UPLOAD,
  RECEIVE_IMAGE_UPLOAD,
  RECEIVE_FILE_UPLOAD,
  RECEIVE_SETTINGS_UPLOAD,
  RECEIVE_TRANSLATIONS_UPLOAD,
  RECEIVE_FONT_UPLOAD,
  RECEIVE_ASSETS_SAVE,
  RECEIVE_TRACKING_ASSETS_PUBLISH,
  RECEIVE_TRACKING_IMG_SETTINGS_PUBLISH,
  RECEIVE_ASSETS_PUBLISH,
  RECEIVE_RETAILER_SETTINGS_JSON_UPLOAD,
  RECEIVE_TRACKING_ASSETS_SAVE,
  RECEIVE_TRACKING_IMG_SETTINGS_SAVE,
  CHANGE_DETECTED,
  RESET_MARKETING_GRID,
  RECEIVE_CSS_FILES,
  RECEIVE_CONFIGURATIONS_LIST,
  RECEIVE_CONFIGURATION_DETAILS,
  UPDATE_CONFIGURATION_SELECTED_CONDITION,
  RECEIVE_DEFAULT_CONDITIONED_CONFIGURATION_ASSETS,
  RECEIVE_ASSETS_SAVED_CONDITION_DUPLICATED,
  TOGGLE_DELETE_CONFIRM_MODAL,
  RECEIVE_SUCCESS_MESSAGE,
  RECEIVE_TRACK_CHAT_ICON_UPLOAD,
} from '~src/actions/experienceManagerActions';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';

const getCurrentFontFaces = fontCssStr => {
  fontCssStr = fontCssStr.split('}');
  fontCssStr.pop();
  for (var i = 0; i < fontCssStr.length; i++) {
    fontCssStr[i] = fontCssStr[i] + '}';
  }
  return fontCssStr;
};

// Parse fonts returned by design/tracking endpoint
// return array of additional font names
const getAdditionalFonts = (branding, files, fontCss) => {
  if (!fontCss) {
    return files;
  }
  let fontCssStr = fontCss.css.replace(/\s/g, '');
  fontCssStr = fontCssStr.replace(/[\n\r]+/g, '');
  let currentFontFaces = getCurrentFontFaces(fontCssStr);
  const re = /^.*font-family:(.*?);src.*$/;
  let parsedFonts = [];
  for (let i = 0; i < currentFontFaces.length; i++) {
    const found = currentFontFaces[i].match(re);
    if (found && found.length === 2) {
      parsedFonts.push(found[1]);
    }
  }
  // `font_css` contains primary, secondary, and base and additional fonts 1-5
  // filter out additional fonts by name
  const mainFonts = [
    branding.font_family_primary,
    branding.font_family_secondary,
    branding.font_family_base,
  ];
  parsedFonts = parsedFonts.filter(font => {
    if (mainFonts.includes(font)) {
      return false;
    }
    return true;
  });

  // fonts CSS file could contain over 5 fonts
  parsedFonts = parsedFonts.slice(0, 5);
  parsedFonts.map((font, i) => {
    files['additionalFont' + (i + 1)].name = font;
  });

  return files;
};

export const initialState = {
  alert: {
    show: false,
  },
  assets: {
    brandLogo: {},
    grid: [],
    gridSettings: {
      grid: [{}],
      headerCopy: [{}],
      headerStyles: {},
    },
    marketingPanel: [],
    header: {},
    branding: {},
    tracking_chat: cloneDeep(trackChatDefaults),
    savedTrackingChat: JSON.stringify(trackChatDefaults),
  },
  changeDetected: false,
  currentLocale: {
    locale_code: '',
    readable_locale: '',
  },
  dateModified: null,
  errors: '',
  // `Configurations` tab
  configurations: {
    configurationsType: [],
    configurationsList: [],
    isAdding: false,
    requestParam: null,
    configurationDetails: {
      conditions: [],
      conditionsOrder: [],
      modifiedTime: {},
      conditionSelected: null,
      uncombinableConditionSelected: null,
    },
  },
  // `Files` tab
  files: {
    retailerSettings: {
      dateModified: null,
      format: 'JSON',
      name: 'RetailerInfoSettings',
      size: null,
    },
    translations: {
      dateModified: null,
      format: 'JSON',
      name: 'Translations',
      size: null,
    },
    translationsCsv: {
      dateModified: null,
      format: 'CSV',
      name: 'Translations',
      size: null,
    },
    customCss: {
      css: '',
      dateModified: null,
      format: 'CSS',
      name: 'Custom',
      size: null,
    },
    fontCss: {
      css: '',
      dateModified: null,
      format: 'CSS',
      name: 'Fonts',
      size: null,
    },
    headerCss: {
      css: '',
      dateModified: null,
      format: 'CSS',
      name: 'Header',
      size: null,
    },
    primaryFont: {
      name: '',
      label: 'Primary Font',
      fontPos: 1,
      format: 'TTF',
    },
    secondaryFont: {
      name: '',
      label: 'Secondary Font',
      fontPos: 2,
      format: 'TTF',
    },
    baseFont: {
      name: '',
      label: 'Base Font',
      fontPos: 3,
      format: 'TTF',
    },
    additionalFont1: {
      name: '',
      label: 'Additional Font 1',
      fontPos: 4,
      format: 'TTF',
    },
    additionalFont2: {
      name: '',
      label: 'Additional Font 2',
      fontPos: 5,
      format: 'TTF',
    },
    additionalFont3: {
      name: '',
      label: 'Additional Font 3',
      fontPos: 6,
      format: 'TTF',
    },
    additionalFont4: {
      name: '',
      label: 'Additional Font 4',
      fontPos: 7,
      format: 'TTF',
    },
    additionalFont5: {
      name: '',
      label: 'Additional Font 5',
      fontPos: 8,
      format: 'TTF',
    },
    trackingImgDateFont: {
      name: '',
      label: 'In-Email Track Snippet: Date Font',
      fontPos: 9,
      format: 'TTF',
    },
    trackingImgTextFont: {
      name: '',
      label: 'In-Email Track Snippet: Text Font',
      fontPos: 10,
      format: 'TTF',
    },
  },
  isPreviewable: false,
  isPublishable: false,
  showDeleteConfirmModal: false,
  locales: {
    locale_code: 'en_US',
  },
  panelsLastModified: {},
  panelsModified: [],
  panelsSaved: [],
  previewUrl: '',
  previewTrackingImgUrl: '',
  previewTrackingUrl: '',
  settingsDisplayName: 'Retailer Settings',
  translations: {},
  translationsCsv: '',
  translationsCsvDisplayName: 'Translations CSV',
  translationsDisplayName: 'Translations',
  uploadStatus: {},
  uriMoniker: '',
  _retailerSettings: {},
  trackingImg: {
    assets: [
      { alt_text: 'Status: None', src: 'status_none_img_url', url: '' },
      { alt_text: 'Status: Shipped', src: 'status_shipped_img_url', url: '' },
      {
        alt_text: 'Status: On Its Way',
        src: 'status_transit_img_url',
        url: '',
      },
      { alt_text: 'Status: Delayed', src: 'status_delay_img_url', url: '' },
      {
        alt_text: 'Status: Delivered',
        src: 'status_delivered_img_url',
        url: '',
      },
    ],
    settings: {
      text_font_url: 'DEFAULT',
      date_font_url: 'DEFAULT',
      status_none_img_url: 'DEFAULT',
      status_shipped_img_url: 'DEFAULT',
      status_transit_img_url: 'DEFAULT',
      status_delay_img_url: 'DEFAULT',
      status_delivered_img_url: 'DEFAULT',
      custom_button_img_url: 'DEFAULT',
    },
  },
};

// TODO: ideally should avoid mutating the inital state in the first place,
// but since we already do, this gives us a preserved copy to use for resets
const initialStateCopy = cloneDeep(initialState);

function experienceManager(state = initialState, action) {
  const { meta, payload, type } = action;
  const files = { ...state.files };

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    case RECEIVE_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            meta && meta.errors ? meta.errors : 'An unknown error occurred.',
        },
        errors:
          meta && meta.errors ? meta.errors : 'An unknown error occurred.',
      };

    case RESET_MARKETING_GRID:
      return {
        ...state,
        assets: {
          ...state.assets,
          grid: meta.grid,
        },
      };

    case CHANGE_DETECTED:
      let panelsModified = [...state.panelsModified];
      let panelModified = '';
      let updatedAssets = { ...state.assets };
      let updatedTrackingImg = { ...state.trackingImg.settings };
      const isGrid = !isNaN(meta.panel);

      if (meta.panel) {
        // Marketing Side Panel Image
        if (meta.panel === 'panel') {
          panelModified = 'marketing_panel';
          meta.settings.click_category = 'Marketing';
          updatedAssets['marketingPanel'][0] = {
            ...updatedAssets['marketingPanel'][0],
            ...meta.settings,
          };

          // Grid Images
        } else if (isGrid) {
          panelModified = `marketing_grid_${meta.panel}`;
          meta.settings.click_category = 'Marketing';
          updatedAssets['grid'][meta.panel - 1] = {
            ...updatedAssets['grid'][meta.panel - 1],
            ...meta.settings,
          };

          // Grid Settings
        } else if (meta.panel === 'gridSettings') {
          panelModified = 'gridSettings';
          meta.settings.click_category = 'Marketing';
          updatedAssets['gridSettings'] = meta.settings;

          // Logo Image
        } else if (meta.panel === 'logo') {
          (panelModified = 'logo'),
            (meta.settings.click_category = 'Navigation');
          updatedAssets['brandLogo'] = {
            ...updatedAssets['brandLogo'],
            ...meta.settings,
          };
          // Branding section
        } else if (meta.panel === 'branding') {
          (panelModified = 'branding'),
            (updatedAssets[meta.panel] = {
              ...updatedAssets[meta.panel],
              ...meta.settings,
            });
        } else if (meta.panel === 'header') {
          (panelModified = 'header'),
            (updatedAssets[meta.panel] = {
              ...updatedAssets[meta.panel],
              ...meta.settings,
            });
        } else if (meta.panel === 'tracking_img') {
          (panelModified = 'trackingImg'),
            (updatedTrackingImg = {
              ...updatedTrackingImg,
              ...meta.settings,
            });
        } else if (meta.panel === 'tracking_chat') {
          (panelModified = 'tracking_chat'),
            (updatedAssets[meta.panel] = {
              ...updatedAssets[meta.panel],
              ...meta.settings,
            });
        }
        if (!panelsModified.includes(panelModified)) {
          panelsModified.push(panelModified);
        }
      }

      return {
        ...state,
        changeDetected: true,
        panelsModified,
        assets: updatedAssets,
        trackingImg: {
          ...state.trackingImg,
          settings: updatedTrackingImg,
        },
        isPreviewable: false,
        isPublishable: false,
      };

    case RECEIVE_CSS_FILES:
      return {
        ...state,
        files: {
          ...state.files,
          fontCss: {
            ...state.files.fontCss,
            css: payload.fontCss
              ? removeLineBreaks(payload.fontCss.css)
              : initialState.files.fontCss.css,
            dateModified: payload.fontCss
              ? payload.fontCss.date_modified
              : initialState.files.fontCss.date_modified,
            size: payload.fontCss
              ? memorySizeOf(payload.fontCss.css)
              : initialState.files.fontCss.size,
            modified: false,
          },
          customCss: {
            ...state.files.customCss,
            css: payload.customCss
              ? removeLineBreaks(payload.customCss.css)
              : initialState.files.customCss.css,
            dateModified: payload.customCss
              ? payload.customCss.date_modified
              : initialState.files.customCss.date_modified,
            size: payload.customCss
              ? memorySizeOf(payload.customCss.css)
              : initialState.files.customCss.size,
            modified: false,
          },
          headerCss: {
            ...state.files.headerCss,
            css: payload.headerCss
              ? removeLineBreaks(payload.headerCss.css)
              : initialState.files.headerCss.css,
            dateModified: payload.headerCss
              ? payload.headerCss.date_modified
              : initialState.files.headerCss.date_modified,
            size: payload.headerCss
              ? memorySizeOf(payload.headerCss.css)
              : initialState.files.headerCss.size,
            modified: false,
          },
        },
      };

    case RECEIVE_ASSETS:
      // `Flattened` response that is much better for the UI
      // and removes some of the deep nesting which makes updating
      // particularly cumbersome

      let {
        retailerSettings: { assets, locales, panelsLastModified },
        _retailerSettings,
        translations,
        translationsCsv,
      } = payload;

      // necessary to preserve defaults
      assets.tracking_chat = Object.assign(
        state.assets.tracking_chat,
        assets.tracking_chat,
      );

      assets.savedTrackingChat = JSON.stringify(assets.tracking_chat);

      let { dateModified, uriMoniker, fileSize } = meta;
      let currentLocale = state.currentLocale;
      let settingsDisplayName = state.settingsDisplayName;
      let translationsDisplayName = state.translationsDisplayName;
      let translationsCsvDisplayName = state.translationsCsvDisplayName;

      if (locales.supported_locales.length > 1 && meta.localeCode) {
        // Locale switching with multiple locales
        currentLocale = locales.supported_locales.find(
          locale => locale.locale_code === meta.localeCode,
        );
        settingsDisplayName = `Retailer Settings: ${currentLocale.locale_code}`;
        translationsDisplayName = `Translations: ${currentLocale.locale_code}`;
        translationsCsvDisplayName = `Translations: ${currentLocale.locale_code}`;
      } else if (locales.supported_locales.length > 1 && !meta.localeCode) {
        // Initial load with multiple locales and NON en_US default
        currentLocale = {
          locale_code: locales.default,
          readable_locale: locales.default_readable_locale,
        };
        settingsDisplayName = `Retailer Settings: ${currentLocale.locale_code}`;
        translationsDisplayName = `Translations: ${currentLocale.locale_code}`;
        translationsCsvDisplayName = `Translations: ${currentLocale.locale_code}`;
      } else {
        // No locales
        currentLocale = {
          locale_code: locales.default,
          readable_locale:
            locales.readable_locale || locales.default_readable_locale,
        };
      }

      return {
        ...state,
        alert: initialState.alert,
        assets,
        changeDetected: false,
        currentLocale,
        dateModified,
        configurations: initialState.configurations,
        files: {
          ...state.files,
          retailerSettings: {
            ...state.files.retailerSettings,
            dateModified,
            size: fileSize,
          },
          translations: {
            ...state.files.translations,
            dateModified,
            size: memorySizeOf(translations),
          },
          translationsCsv: {
            ...state.files.translationsCsv,
            dateModified,
            size: memorySizeOf(translationsCsv),
          },
        },
        isPreviewable: false,
        isPublishable: false,
        locales,
        panelsLastModified,
        settingsDisplayName,
        translations,
        translationsCsv,
        translationsDisplayName,
        translationsCsvDisplayName,
        uriMoniker,
        _retailerSettings,
      };

    case RECEIVE_FONT_UPLOAD:
      panelsModified = [...state.panelsModified];
      panelModified =
        meta.file.fontPos === 9 || meta.file.fontPos === 10
          ? 'trackingImgFont'
          : 'trackingFont';
      let oldFont = null;
      const branding = Object.keys(state.assets.branding).length;
      let updatedBranding = {};
      let updatedTrackingImgSettings = {};
      //Saving font urls in branding section of retailerSettings
      const relativePath = payload.replace(/^.*\/\/[^\/]+/, '');
      if (meta.file.fontPos === 1) {
        oldFont = branding ? null : branding.font_family_primary;
        updatedBranding.font_family_primary = meta.newFontName;
        updatedBranding.primary_font_url = relativePath;
      } else if (meta.file.fontPos === 2) {
        oldFont = branding ? null : branding.font_family_secondary;
        updatedBranding.font_family_secondary = meta.newFontName;
        updatedBranding.secondary_font_url = relativePath;
      } else if (meta.file.fontPos === 3) {
        oldFont = branding ? null : branding.font_family_base;
        updatedBranding.font_family_base = meta.newFontName;
        updatedBranding.base_font_url = relativePath;
      } else if (meta.file.fontPos === 9) {
        updatedTrackingImgSettings.date_font_url = payload;
      } else if (meta.file.fontPos === 10) {
        updatedTrackingImgSettings.text_font_url = payload;
      }

      files[meta.fileIndex] = {
        ...state.files[meta.fileIndex],
        dateModified: new Date(),
        size: formatByteSize(meta.selectedFile[0].size),
      };

      if (!panelsModified.includes(panelModified)) {
        panelsModified.push(panelModified);
      }

      return {
        ...state,
        files: files,
        panelsModified,
        assets: {
          ...state.assets,
          branding: {
            ...state.assets.branding,
            ...updatedBranding,
          },
        },
        trackingImg: {
          ...state.trackingImg,
          settings: {
            ...state.trackingImg.settings,
            ...updatedTrackingImgSettings,
          },
        },
        alert: {
          color: 'info',
          show: true,
          text: 'Upload successful.',
        },
      };

    case RECEIVE_TRACKING_IMG_SETTINGS:
      // Initial mapping of Tracking Img assets to the payload
      assets = [...state.trackingImg.assets];
      updatedAssets = assets.map(asset => {
        let updatedAsset = { ...asset };
        updatedAsset.url = payload[updatedAsset.src];
        return updatedAsset;
      });
      return {
        ...state,
        trackingImg: {
          assets: updatedAssets,
          settings: payload,
        },
      };

    case REQUEST_IMAGE_UPLOAD:
      return {
        ...state,
        uploadStatus: {
          device: meta.device,
          gridPosition: meta.gridPos,
        },
      };

    case RECEIVE_TRACK_CHAT_ICON_UPLOAD:
      const icon = {
        url: payload.asset_url,
        name: meta.originalName,
      };
      panelsModified = [...state.panelsModified];
      if (!panelsModified.includes('tracking_chat')) {
        panelsModified.push('tracking_chat');
      }

      return {
        ...state,
        assets: {
          ...state.assets,
          tracking_chat: {
            ...state.assets.tracking_chat,
            icon,
          },
        },
        panelsModified,
        changeDetected: true,
      };

    case RECEIVE_IMAGE_UPLOAD:
      const fileName = payload.asset_url.substring(
        payload.asset_url.lastIndexOf('/') + 1,
        payload.asset_url.lastIndexOf('@2x'),
      );
      const assetUrl = payload.asset_url.split('@2x');
      const extension = assetUrl[1];
      assets = { ...state.assets };
      let updatedTrackingImgAssets = [...state.trackingImg.assets];
      panelsModified = [...state.panelsModified];
      panelModified =
        meta.gridPos === 'trackingimg' ? 'trackingImgAsset' : 'trackingAsset';
      // Carefully update the uploaded asset into deeply nested assets
      if (meta.gridPos === 'trackingimg') {
        let trackingImgAssets = [...state.trackingImg.assets];
        updatedTrackingImgAssets = trackingImgAssets.map(asset => {
          let updatedAsset = { ...asset };
          if (updatedAsset.src === meta.device) {
            updatedAsset.url = payload.asset_url;
          }
          return updatedAsset;
        });
      } else if (meta.gridPos === 'panel') {
        if (meta.device === 'desktop') {
          assets.marketingPanel[0].desktop_src_2x = `${
            assetUrl[0]
          }@2x${extension}`;
          assets.marketingPanel[0].desktop_src = `${assetUrl[0]}${extension}`;
          assets.marketingPanel[0].tracking_id = fileName;
        } else {
          assets.marketingPanel[0].mobile_src_2x = `${
            assetUrl[0]
          }@2x${extension}`;
          assets.marketingPanel[0].mobile_src = `${assetUrl[0]}${extension}`;
          assets.marketingPanel[0].tracking_id_mobile = fileName;
        }
        assets.marketingPanel[0].click_category = meta.clickCategory;
      } else if (meta.gridPos === 'logo') {
        if (meta.device === 'desktop') {
          assets.brandLogo.desktop_src_2x = `${assetUrl[0]}@2x${extension}`;
          assets.brandLogo.desktop_src = `${assetUrl[0]}${extension}`;
          assets.brandLogo.tracking_id = fileName;
          assets.layout.header = `${assetUrl[0]}@2x${extension}`;
        } else {
          assets.brandLogo.mobile_src_2x = `${assetUrl[0]}@2x${extension}`;
          assets.brandLogo.mobile_src = `${assetUrl[0]}${extension}`;
          assets.brandLogo.tracking_id_mobile = fileName;
        }
        assets.brandLogo.click_category = meta.clickCategory;
      } else {
        if (meta.device === 'desktop') {
          assets.grid[meta.gridPos - 1].desktop_src_2x = `${
            assetUrl[0]
          }@2x${extension}`;
          assets.grid[meta.gridPos - 1].desktop_src = `${
            assetUrl[0]
          }${extension}`;
          assets.grid[meta.gridPos - 1].tracking_id = fileName;

          // Mobile
        } else {
          assets.grid[meta.gridPos - 1].mobile_src_2x = `${
            assetUrl[0]
          }@2x${extension}`;
          assets.grid[meta.gridPos - 1].mobile_src = `${
            assetUrl[0]
          }${extension}`;
          assets.grid[meta.gridPos - 1].tracking_id_mobile = fileName;
        }
        assets.grid[meta.gridPos - 1].click_category = meta.clickCategory;
      }
      if (!panelsModified.includes(panelModified)) {
        panelsModified.push(panelModified);
      }

      return {
        ...state,
        assets,
        panelsModified,
        trackingImg: {
          ...state.trackingImg,
          assets: updatedTrackingImgAssets,
        },
        changeDetected: true,
        uploadStatus: initialState.uploadStatus,
      };

    case RECEIVE_TRACKING_IMG_SETTINGS_SAVE:
      const previewTrackingImgUrl = action.preview_url;
      return {
        ...state,
        previewTrackingImgUrl,
      };

    case RECEIVE_TRACKING_ASSETS_SAVE:
      const previewTrackingUrl = action.preview_url;
      return {
        ...state,
        previewTrackingUrl,
      };

    case RECEIVE_ASSETS_SAVE:
      let panelsSaved = [...state.panelsSaved];
      panelsModified = [...state.panelsModified];
      panelsSaved = [...new Set([...panelsSaved, ...panelsModified])];
      panelsModified = [];

      return {
        ...state,
        panelsSaved,
        panelsModified,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
        changeDetected: false,
        isPreviewable: true,
        isPublishable: true,
        assets: {
          ...state.assets,
          savedTrackingChat: JSON.stringify(state.assets.tracking_chat),
        },
      };

    case RECEIVE_FILE_UPLOAD:
      files[meta.fileIndex] = {
        ...state.files[meta.fileIndex],
        dateModified: new Date(),
        size: memorySizeOf(payload),
        css: payload,
        modified: true,
      };
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Upload successful.',
        },
        files,
      };

    case RECEIVE_TRACKING_ASSETS_PUBLISH:
      return {
        ...state,
        panels_modified: [],
        publishStatusCode: payload.status_code,
        dateModified: payload.date_modified,
      };

    case RECEIVE_TRACKING_IMG_SETTINGS_PUBLISH:
      return {
        ...state,
        panels_modified: [],
        publishStatusCode: payload.status_code,
        dateModified: payload.date_modified,
      };

    case RECEIVE_SETTINGS_UPLOAD:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Upload successful.',
        },
        files: {
          ...state.files,
          retailerSettings: {
            ...state.files.retailerSettings,
            name: payload.fileName,
            dateModified: new Date(),
            size: memorySizeOf(payload.retailerSettingsUpload),
          },
        },
      };

    case RECEIVE_RETAILER_SETTINGS_JSON_UPLOAD:
      return {
        ...state,
        assets: {
          ...state.assets,
          ...payload.retailerSettings.assets,
        },
        _retailerSettings: payload._retailerSettings,
      };

    case RECEIVE_TRANSLATIONS_UPLOAD:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: 'Upload successful.',
        },
        files: {
          ...state.files,
          translations: {
            ...state.files.translations,
            name: payload.fileName,
            dateModified: new Date(),
            size: memorySizeOf(payload.translationsUpload),
          },
          translationsCsv: {
            ...state.files.translationsCsv,
            name: payload.fileName,
            dateModified: new Date(),
            size: memorySizeOf(payload.translationsUpload),
          },
        },
        translations: payload.translationsUpload,
        translationsCsv: payload.translationsUpload,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case DISMISS_ASSET_MODAL:
      return {
        ...state,
        errors: initialState.errors,
      };

    case RECEIVE_ASSETS_PUBLISH:
      panelsSaved = [];
      return {
        ...state,
        panelsSaved,
        alert: {
          color: 'success',
          show: true,
          text:
            'Publish successful. The updates will be available to all your customers shortly.',
        },
        isPreviewable: false,
        isPublishable: false,
      };

    case RECEIVE_CSM_LOGOUT:
      return cloneDeep(initialStateCopy);

    case RECEIVE_CONFIGURATIONS_LIST:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          configurationsType: payload.header[0].row,
          configurationsList: payload.body,
          isAdding: false,
          requestParam: null,
          configurationDetails: {
            conditions: {},
            conditionsOrder: [],
            modifiedTime: {},
            conditionSelected: null,
            uncombinableConditionSelected: null,
          },
        },
      };

    case RECEIVE_CONFIGURATION_DETAILS:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          isAdding: meta.param === 'addConfiguration=true',
          requestParam: meta.param,
          configurationDetails: {
            conditions: payload.condition.reduce(
              (conditionGroup, condition) => ({
                ...conditionGroup,
                [condition.label.toLowerCase()]: {
                  ...condition,
                  // TODO: Adding this as a preliminary way to disable combonations of
                  // CTC conditions. Backend should return this eventually, at which point
                  // We can remove this stub.
                  isCombinableCondition: condition.label === 'Locale',
                },
              }),
              {},
            ),
            conditionsOrder: payload.condition.map(({ label }) =>
              label.toLowerCase(),
            ),
            modifiedTime: {
              created: payload.created,
              modified: payload.modified,
            },
            conditionSelected: payload.condition.reduce(
              (conditionGroup, condition) => ({
                ...conditionGroup,
                [condition.label.toLowerCase()]: condition.selected,
              }),
              {},
            ),
          },
        },
      };

    case UPDATE_CONFIGURATION_SELECTED_CONDITION:
      const newState = { ...state };
      const {
        configurations: {
          configurationDetails: {
            conditions: {
              [meta.conditionType]: { isCombinableCondition },
            },
          },
        },
      } = state;
      if (!isCombinableCondition) {
        newState.configurations.configurationDetails.uncombinableConditionSelected = meta.newCondition
          ? meta.conditionType
          : null;
      }
      newState.changeDetected = false;
      newState.configurations.configurationDetails.conditionSelected = {
        ...newState.configurations.configurationDetails.conditionSelected,
        [meta.conditionType]: meta.newCondition,
      };
      return newState;

    case RECEIVE_DEFAULT_CONDITIONED_CONFIGURATION_ASSETS:
      return {
        ...state,
        alert: initialState.alert,
        assets: {
          ...state.assets,
          ...payload.retailerSettings.assets,
        },
        changeDetected: false,
        dateModified: meta.dateModified,
        files: {
          ...state.files,
          retailerSettings: {
            ...state.files.retailerSettings,
            dateModified: meta.dateModified,
            size: meta.retailerSettingsFileSize,
          },
          translations: {
            ...state.files.translations,
            dateModified: meta.dateModified,
            size: meta.translationsFileSize,
          },
          translationsCsv: {
            ...state.files.translationsCsv,
            dateModified: meta.dateModified,
            size: meta.translationsCsvFileSize,
          },
        },
        isPreviewable: false,
        isPublishable: false,
        locales: payload.retailerSettings.locales,
        panelsLastModified: payload.retailerSettings.panelsLastModified,
        translations: payload.translations,
        translationsCsv: payload.translationsCsv,
        uriMoniker: meta.uriMoniker,
        _retailerSettings: payload._retailerSettings,
      };

    case RECEIVE_ASSETS_SAVED_CONDITION_DUPLICATED:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            'Your changes cannot be saved as this configuration already exists.',
        },
        changeDetected: false,
        isPreviewable: false,
        isPublishable: false,
      };
    case RECEIVE_SUCCESS_MESSAGE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: meta.successText || 'Success.',
        },
      };
    case TOGGLE_DELETE_CONFIRM_MODAL:
      return {
        ...state,
        showDeleteConfirmModal: !state.showDeleteConfirmModal,
      };

    default:
      return state;
  }
}

export default experienceManager;
