import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Svg from '../Svg';
import TableMeta from '../TableMeta';
import * as styles from './Search.styles';

export class Search extends Component {
  handleClickSearch = () => {
    if (this.searchInput) {
      this.searchInput.focus();
      this.props.handleClick();
    }
  };

  render() {
    const {
      cancel,
      handleChange,
      handleKeyDown,
      isActive,
      isFetching,
      isSearching,
      isTableSearch,
      totalResults,
      value,
      placeholder,
      stylePreset,
      error,
    } = this.props;

    const s = styles[stylePreset];

    return (
      <div
        className={cx(s, error ? 'error' : undefined)}
      >
        {isTableSearch && (
          <TableMeta isFetching={isFetching} totalRecords={totalResults} />
        )}
        <div
          className={cx(
            'input-container',
            isActive ? 'is-active' : undefined,
          )}
        >
          <input
            className={isActive ? 'focus' : undefined}
            data-test="input-search"
            ref={input => {
              this.searchInput = input;
            }}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="text"
            value={value}
            placeholder={placeholder}
          />
        </div>
        {!isSearching ?
          <div
            className="icon-container"
            onClick={this.handleClickSearch}
            data-test="button-search"
          >
            <Svg
              className={value ? 'icon-search' : 'icon-muted'}
              name="search"
            />
          </div> :
          <div
            className="icon-container"
            onClick={cancel}
            data-test="button-search"
          >
            <Svg className="close" name="close" />
          </div>
        }
      </div>
    );
  }
}

Search.propTypes = {
  cancel: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleKeyDown: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  isSearching: PropTypes.bool,
  totalResults: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  isFetching: PropTypes.bool,
  stylePreset: PropTypes.oneOf([
    'subtle',
    'standard',
  ]),
  error: PropTypes.bool,
};

Search.defaultProps = {
  isActive: false,
  isSearching: false,
  placeholder: 'Search',
  stylePreset: 'subtle',
  error: false,
};

Search.displayName = 'Search';

export default Search;
