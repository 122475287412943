import React from 'react';
import { push } from 'connected-react-router';
import objectPath from 'object-path';
import { API_INVALIDATE_PATH, API_REQUEST } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import neoHubDomain from '../lib/neoHubDomain';
import { policyUrls } from './returns/rules/fetchRules';
import { RULE_TYPES } from '../constants/returns/returnRules';

export const REQUEST_DISTRIBUTION_CENTERS = 'REQUEST_DISTRIBUTION_CENTERS';
export const RECEIVE_DISTRIBUTION_CENTERS = 'RECEIVE_DISTRIBUTION_CENTERS';
export const REQUEST_CREATE_DISTRIBUTION_CENTER =
  'REQUEST_CREATE_DISTRIBUTION_CENTER';
export const RECEIVE_CREATE_DISTRIBUTION_CENTER =
  'RECEIVE_CREATE_DISTRIBUTION_CENTER';
export const REQUEST_MODIFY_DISTRIBUTION_CENTER =
  'REQUEST_MODIFY_DISTRIBUTION_CENTER';
export const RECEIVE_MODIFY_DISTRIBUTION_CENTER =
  'RECEIVE_MODIFY_DISTRIBUTION_CENTER';
export const REQUEST_DISTRIBUTION_CENTER_DATA =
  'REQUEST_DISTRIBUTION_CENTER_DATA';
export const RECEIVE_DISTRIBUTION_CENTER_DATA =
  'RECEIVE_DISTRIBUTION_CENTER_DATA';
export const RECEIVE_DISTRIBUTION_NEW_CENTER =
  'RECEIVE_DISTRIBUTION_NEW_CENTER';
export const UPDATE_SUCCESS = 'UPDATE_SUCCESS';
export const CREATE_SUCCESS = 'CREATE_SUCCESS';
export const MODIFY_DISTRIBUTION_CENTER_ERROR =
  'MODIFY_DISTRIBUTION_CENTER_ERROR';
export const CREATE_DISTRIBUTION_CENTER_ERROR =
  'CREATE_DISTRIBUTION_CENTER_ERROR';
export const CLEAR_DC_ALERTS = 'CLEAR_DC_ALERTS';
export const RECEIVE_DELETE_DISTRIBUTION_CENTER_ERROR =
  'RECEIVE_DELETE_DISTRIBUTION_CENTER_ERROR';
export const RECEIVE_SHIPPING_TAGS = 'RECEIVE_SHIPPING_TAGS';
export const SELECT_SHIPPING_TAG = 'SELECT_SHIPPING_TAG';

export const SHOW_ALERT = 'DISTRIBUTION_CENTER/SHOW_ALERT';

export const TOGGLE_LOCAL_DELIVERY_OPTION = 'TOGGLE_LOCAL_DELIVERY_OPTION';
export const SET_ELIGIBLE_ZIPS = 'SET_ELIGIBLE_ZIPS';
export const SET_CARRIER_INSTRUCTIONS = 'SET_CARRIER_INSTRUCTIONS';

export const RECEIVE_RULE_DATA = 'RECEIVE_RULE_DATA';

export const showAlert = (color, text) => dispatch =>
  dispatch({
    type: SHOW_ALERT,
    payload: {
      color,
      text,
    },
  });

export const fetchDeleteDistributionCenter = id => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.DISTRIBUTION_CENTER}/${id}`,
    name: fetchDeleteDistributionCenter,
  })
    .then(() => {
      window.location.href = `${neoHubDomain()}/promise/distribution-centers`;
    })
    .catch(() => dispatch({ type: RECEIVE_DELETE_DISTRIBUTION_CENTER_ERROR }));

export const clearDcAlerts = () => ({
  type: CLEAR_DC_ALERTS,
});

export const receiveDistributionCenters = json => ({
  type: RECEIVE_DISTRIBUTION_CENTERS,
  payload: {
    distributionCenters: json.content,
  },
  meta: {
    totalResults: json.total_records,
  },
});

export const receiveDistributionCenterData = payload => ({
  type: RECEIVE_DISTRIBUTION_CENTER_DATA,
  payload,
});

export const fetchRuleData = payload => ({
  type: RECEIVE_RULE_DATA,
  payload,
});

export const receiveDistributionNewCenter = payload => ({
  type: RECEIVE_DISTRIBUTION_NEW_CENTER,
  payload,
});

export const receiveCreateDistributionCenter = payload => ({
  type: CREATE_SUCCESS,
  payload,
});

export const receiveCreateDistributionCenterError = messages => ({
  type: CREATE_DISTRIBUTION_CENTER_ERROR,
  messages,
});

export const fetchCreateDistributionCenter = data => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${ENDPOINTS.DISTRIBUTION_CENTER}`,
    body: JSON.stringify(data),
    name: 'fetchCreateDistributionCenter',
  })
    .then(json => {
      const query = {
        current_page_index: 0,
        records_per_page: 20,
        keyword: '',
        search_by: [],
      };
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.DISTRIBUTION_CENTERS}`,
        query,
      });
      dispatch(push(`/promise/distribution-centers/${json.response.id}`));
      dispatch(receiveCreateDistributionCenter(json));
    })
    .catch(err =>
      dispatch(receiveCreateDistributionCenterError(`${err.errors[0]}.`)),
    );

export const receiveModifyDistributionCenter = payload => ({
  type: UPDATE_SUCCESS,
  payload,
});

export const receiveModifyDistributionCenterError = messages => ({
  type: MODIFY_DISTRIBUTION_CENTER_ERROR,
  messages,
});

export const receiveShippingTags = payload => ({
  type: RECEIVE_SHIPPING_TAGS,
  payload,
});

export const fetchDistributionCenters = ({
  recordsPerPage = 20,
  activePage = 0,
  keyword = '',
  searchBy = [],
}) => dispatch => {
  const query = {
    current_page_index: activePage ? activePage - 1 : 0,
    records_per_page: recordsPerPage,
    keyword,
    search_by: [...searchBy],
  };
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.DISTRIBUTION_CENTERS}`,
    name: 'fetchDistributionCenters',
    query,
  }).then(
    json => {
      dispatch(receiveDistributionCenters(json));
    },
    err => console.error(err), // eslint-disable-line
  );
};

export const fetchModifyDistributionCenter = (data, id) => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: `${ENDPOINTS.DISTRIBUTION_CENTER}/${id}`,
    body: JSON.stringify(data),
    name: 'fetchModifyDistributionCenter',
  })
    .then(json => {
      if (json.status.toLowerCase() === 'success') {
        dispatch({
          type: API_INVALIDATE_PATH,
          path: `${ENDPOINTS.DISTRIBUTION_CENTERS}`,
        });

        dispatch(receiveModifyDistributionCenter(json));
      } else {
        dispatch(
          receiveModifyDistributionCenterError(`${json.errors[0].message}.`),
        );
      }
    })
    .catch(err =>
      dispatch(receiveCreateDistributionCenterError(`${err.errors[0]}.`)),
    );

export const fetchShippingTags = () => async dispatch => {
  const json = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.SHIP_SETTINGS}`,
    name: 'fetchShippingTags',
  });
  dispatch(receiveShippingTags(json));
};

export const fetchDistributionCenterData = id => async dispatch => {
  if (id && id !== '') {
    const res = await fetch(
      policyUrls[RULE_TYPES.RETURN_DESTINATION_POLICY],
    ).then(e => e.json());
    const rules = objectPath.get(res, 'policy.statements', []);
    dispatch(fetchRuleData(rules));
    return dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.DISTRIBUTION_CENTER}/${id}`,
      name: 'fetchDistributionCenterData',
    }).then(json => {
      dispatch(receiveDistributionCenterData(json));
      dispatch(fetchShippingTags());
    });
  }
  return dispatch(receiveDistributionNewCenter());
};

export const selectShippingTag = idx => ({
  type: SELECT_SHIPPING_TAG,
  meta: {
    selectedIndex: idx,
  },
});

export const fetchDistributionCentersCsv = () => () =>
  window.open(ENDPOINTS.DISTRIBUTION_CENTERS_CSV);

export const postDistributionCentersCsv = csv => dispatch =>
  dispatch({
    type: API_REQUEST,
    contentType: 'text/plain',
    method: 'POST',
    path: ENDPOINTS.DISTRIBUTION_CENTERS_CSV,
    name: 'postDistributionCentersCsv',
    body: csv,
  })
    .then(() => {
      const query = {
        current_page_index: 0,
        records_per_page: 20,
      };
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${ENDPOINTS.DISTRIBUTION_CENTERS}`,
        query,
      });
      dispatch(fetchDistributionCenters({}));
      dispatch(
        showAlert(
          'success',
          'Your Distribution Centers CSV was uploaded successfully',
        ),
      );
    })
    .catch(err => {
      console.error('error while updaing Dcs csv ', err);
      if (err.status_code !== 422) {
        return dispatch(
          showAlert(
            'danger',
            `Sorry! Unable to upload Distribution Centers CSV`,
          ),
        );
      }

      return dispatch(
        showAlert(
          'danger',
          <div>
            Sorry there was an error posting your CSV. Please click{' '}
            <a href={`data:text/csv;charset=utf-8, ${escape(err.errors)}`}>
              here
            </a>{' '}
            to download your CSV with error warnings.
          </div>,
        ),
      );
    });

export const toggleLocalDeliveryOption = flag => ({
  type: TOGGLE_LOCAL_DELIVERY_OPTION,
  meta: {
    flag,
  },
});

export const changeEligibleZips = zips => ({
  type: SET_ELIGIBLE_ZIPS,
  meta: {
    zips,
  },
});

export const changeCarrierInstructions = instructions => {
  return {
    type: SET_CARRIER_INSTRUCTIONS,
    meta: {
      instructions,
    },
  };
};
