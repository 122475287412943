import { colors, fonts } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  padding-bottom: 10px;
  padding-left: 20px;
  min-height: 31px;
  transition: opacity 200ms;
  opacity: 1;

  strong {
    font-family: ${fonts.bold};
  }

  &.is-fetching {
    opacity: 0.25;
  }
`;

export default s;
