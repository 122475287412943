import { css } from 'emotion';

const s = css`
  display: flex;
  flex-direction: column;

  & .multi-select-time {
    display: inline-flex;
    align-items baseline;
    justify-content: space-between;

    & .time-input {
      width: 40%;
    }
  }
`;

export default s;
