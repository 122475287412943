import { createTheme } from '@material-ui/core/styles';

const fontFamily = 'Circular, "Helvetica Neue", Helvetica, sans-serif';

export default createTheme({
  palette: {
    primary: {
      main: '#2C3239',
      light: '#979797',
    },
    secondary: {
      main: '#1399D6',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily,
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 700,
    },
    button: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
    },
    subtitle2: {
      fontWeight: 700,
    },
    body1: {
      fontSize: '0.875rem',
    },
    caption: {
      color: '#757575',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Circular',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `
            local('Circular'),
            local('Circular Book'),
            local('Circular Std Book'),
            url('/static/circular-std-book.woff2') format('woff2')
          `,
          },
          {
            fontFamily: 'Circular',
            fontStyle: 'bold',
            fontDisplay: 'swap',
            fontWeight: 700,
            src: `
            local('Circular Bold'),
            local('Circular Std Bold'),
            url('/static/circular-std-bold.woff2') format('woff2')
          `,
          },
        ],
      },
    },
    MuiTableCell: {
      head: {
        borderBottomWidth: '2px',
        fontWeight: 700,
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'initial',
        fontWeight: 700,
      },
      fullWidth: {
        borderBottom: '2px solid #e0e0e0',
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: '#424242',
      },
    },
    MuiFormControlLabel: {
      root: { marginBottom: 0, marginLeft: '-0.5rem' },
      label: { fontSize: '0.875rem' },
    },
    MuiTextField: {
      root: {
        width: '100%',
      },
    },
    MuiFab: {
      root: {
        minHeight: '2rem',
      },
      sizeSmall: {
        height: '2rem',
        width: '2rem',
        '&$extended': {
          height: '2rem',
        },
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 2,
        width: '100%',
      },
      multiline: {
        padding: '0.5rem',
        fontSize: '0.875rem',
        lineHeight: '1.5rem',
      },
      input: {
        padding: '0.75rem 1rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.75rem 1rem',
        width: '100%',
      },
    },
    MuiInput: {
      input: {
        padding: '0.75rem 1rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '2px',
        padding: '0.5rem 1.5rem',
      },
      outlinedSizeSmall: {
        padding: '3px 8px',
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 1200,
      },
    },
    MuiDialog: {
      paper: {
        zIndex: 1250,
        padding: '1.25rem',
      },
    },
    MuiDialogTitle: {
      root: {
        padding: '1.25rem',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 1.25rem 1.25rem 1.25rem',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '1.25rem',
      },
    },
    MuiPickersModal: {
      dialogRoot: {
        padding: 0,
      },
    },
    MuiSwitch: {
      root: {
        width: '2.5rem',
        height: '1.5rem',
        padding: 0,
      },
      thumb: {
        width: '1.25rem',
        height: '1.25rem',
        marginTop: '1px',
        color: '#fff',
        border: 'none',
        boxShadow: 'none',
      },
      switchBase: {
        padding: 1,
        '&$checked': {
          transform: 'translateX(1rem)',
        },
      },
      track: {
        borderRadius: '0.75rem',
        backgroundColor: '#bbb',
        opacity: 1,
        '$checked$checked + &': {
          opacity: 1.0,
          backgroundColor: '#2ECC71',
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '2rem',
      },
    },
    MuiCardContent: {
      root: {
        paddingTop: 0,
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: '0.5rem',
      },
      h6: {
        fontSize: '1rem',
      },
    },
    MuiLink: {
      root: {
        fontWeight: 700,
        '&:hover': {
          color: 'inherit',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        backgroundColor: '#f5f5f5',
        minHeight: 0,
        padding: '0.625rem 1rem',
        '&$expanded': {
          minHeight: 0,
        },
      },
      content: {
        margin: 0,
        '&$expanded': {
          margin: 0,
        },
      },
      expandIcon: {
        padding: 0,
        margin: 0,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '1rem',
      },
    },
    MuiButtonBase: {
      root: {
        fontFamily,
      },
    },
    MuiRadio: {
      root: {
        padding: '0.25rem 0.5rem',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.875rem',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiButton: {
      disableElevation: true,
    },
  },
});
