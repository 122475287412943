import { css, injectGlobal } from 'emotion';
import { colors } from '@narvar/hum-constants';

injectGlobal`
.modal-backdrop {
  background-color: ${colors.black};

  &.show {
    opacity: 0.8;
  }
}`;

const s = css`
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
  font-weight: 300;
  margin-top: 100px;
  min-width: 600px;
  position: relative;

  & .modal-content {
    border: 1px solid ${colors.gray};
  }

  & .modal-header {
    padding: 40px 40px 0 40px;

    & .modal-title {
      margin-right: auto;
      font-size: 1.4rem;
      line-height: 1.4rem;
      color: ${colors.dark1};
    }

    & .close {
      transform: scale(0.6);
      position: absolute;
      right: 40px;
      top: 34px;
      opacity: 0.4;
      transition: opacity 250ms;

      & svg {
        & path {
          fill: ${colors.black};
          fill-opacity: 1;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }

  & .modal-body {
    padding: 20px 40px 0 40px;
  }

  & .modal-header,
  .modal-footer {
    border-color: transparent;
  }

  & .modal-footer {
    padding: 0 40px 40px 40px;
  }

  & .btn {
    min-width: 100px;
  }

& .dots {
  bottom: -40px;
  margin: 10px auto;
  position: absolute;
  text-align: center;
  width: 100%;

  > div {
    border: 5px solid ${colors.white};
    border-radius: 5px;
    display: inline-block;
    margin: 0 5px;
    opacity: 0.2;


    &.is-active {
      border-color: ${colors.blue};
      opacity: 1;
    }
  }
}`;

export default s;
