import stubData from '~src/constants/stubData';
import { push } from 'connected-react-router';
import * as ENDPOINTS from '~src/constants/endpoints';
import findReport from './findReport';
import { RECEIVE, ERROR } from './constants';
import sendMetricToNoflake from './noflake';

export default async function request(
  path = '',
  config = {},
  contentType,
  name,
  queryString = '',
  user,
  dispatch,
  reqBodyRedactor,
) {
  const passedConfig = {
    ...config,
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // For `Content-Type: 'mutlipart/form-data'` we let the browser handle accordingly
  // so that it appends the formBoundary
  if (contentType === false) {
    delete passedConfig.headers['Content-Type'];
  } else if (contentType === 'application/x-www-form-urlencoded') {
    passedConfig.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  } else if (contentType) {
    passedConfig.headers['Content-Type'] = contentType;
  }

  let response;
  let text;
  let json;

  if (__DEV__) {
    const report = findReport(path + queryString);

    if (report && stubData[report]) {
      let stubbedJson = {};

      if (Object.keys(ENDPOINTS.REPORTS).includes(report)) {
        // Tracking
        stubbedJson.response = {
          reports: stubData[report],
        };
      } else {
        // Returns
        stubbedJson = {
          reports: {
            [report]: stubData[report],
          },
        };
      }

      dispatch({
        type: RECEIVE,
        path,
        json: stubbedJson,
        queryString,
        name,
      });

      return stubbedJson;
    }
  }

  try {
    const fullPath = queryString ? path + queryString : path;
    const startTime = new Date();

    response = await fetch(fullPath, passedConfig);
    response.duration = new Date() - startTime;
    const statusCode = response.status;

    if (statusCode >= 200 && statusCode < 300) {
      // NOTE: `/uilogout` returns a text/html not json
      // should probably pass different headers, MT
      if (path.includes('uilogout')) {
        return response;
      }

      text = await response.text();

      if (text) {
        try {
          json = JSON.parse(text);
        } catch (e) {
          // sometimes res.text() is just a plain OK or SUCCESS; in this case json.parse will fail
          json = { text };
        }
      }

      sendMetricToNoflake(
        user,
        fullPath,
        passedConfig,
        response,
        json,
        name,
        reqBodyRedactor,
      );
      dispatch({
        type: RECEIVE,
        path,
        json,
        name,
        queryString,
      });

      return json;
    }

    json = await response.json();
    sendMetricToNoflake(
      user,
      fullPath,
      passedConfig,
      response,
      json,
      name,
      reqBodyRedactor,
    );

    switch (statusCode) {
      case 400:
        throw json;

      case 401: {
        document.cookie = `KC_REDIRECT=${window.location.pathname}; path=/;`;
        window.location.replace('/sso/login');
        throw json;
      }
      case 403:
        dispatch(push('/'));
        throw json;

      case 500:
        throw json;

      default:
        throw json;
    }
  } catch (error) {
    dispatch({
      type: ERROR,
      path,
      name,
      queryString,
    });

    throw error;
  }
}
