import objectPath from 'object-path';
import { schemaLabelOverrides } from './schema';
import { RULE_TYPES } from '../../../constants/returns/returnRules';

export const standardActions = ({
  ruleType = RULE_TYPES.RETURN_DESTINATION_POLICY,
}) => {
  return {
    [RULE_TYPES.RETURN_DESTINATION_POLICY]: [
      'return_dc',
      'address',
      'smart_dc',
    ],
    [RULE_TYPES.RETURN_METHODS_POLICY]: ['return_methods_with_carrier'],
  }[ruleType];
};
export const ORDER_KEY = '$.request.';
export const ITEM_KEY = '$.item.';

export const policyUiVersion = 1;

export const aggregatorMap = {
  item: [
    { picklist_token: 'all_items', picklist_value: 'All Items' },
    { picklist_token: 'any_item', picklist_value: 'Any Item' },
  ],
};

export const operators = {
  String: [
    '=',
    'not equals',
    'starts with',
    'ends with',
    'contains',
    'not contains',
    'null',
    'not null',
    'in',
    'not in',
  ],
  Float64: ['=', 'not equals', '>', '>=', '<', '<='],
  Integer64: ['=', 'not equals', '>', '>=', '<', '<='],
  Boolean: ['=', 'not ='],
  ZonedDateTime: ['before', 'after'],
};

export const allowedSchemaTypes = [
  'String',
  'Boolean',
  'Integer64',
  'Float64',
  'ZonedDateTime',
];

export const orderSchema = {
  '$.request.brand': 'String',
  '$.request.carrier_moniker': 'String',
  '$.request.customer.address': 'PolicyAddress',
  '$.request.customer.address.address1': 'String',
  '$.request.customer.address.address2': 'String',
  '$.request.customer.address.attention': 'String',
  '$.request.customer.address.city': 'String',
  '$.request.customer.address.country_code': 'String',
  '$.request.customer.address.department': 'String',
  '$.request.customer.address.name': 'String',
  '$.request.customer.address.postal_code': 'String',
  '$.request.customer.address.region_code': 'String',
  '$.request.locale': 'String',
  '$.request.retailer_moniker': 'String',
  '$.request.ship_from_address': 'PolicyAddress',
  '$.request.ship_from_address.address1': 'String',
  '$.request.ship_from_address.address2': 'String',
  '$.request.ship_from_address.attention': 'String',
  '$.request.ship_from_address.city': 'String',
  '$.request.ship_from_address.country_code': 'String',
  '$.request.ship_from_address.department': 'String',
  '$.request.ship_from_address.name': 'String',
  '$.request.ship_from_address.postal_code': 'String',
  '$.request.ship_from_address.region_code': 'String',
  '$.request.type': 'String',
  '$.item.custom_attributes': 'Map<String, String>',
  '$.item.destination': 'PolicyAddress',
  '$.item.destination.address1': 'String',
  '$.item.destination.address2': 'String',
  '$.item.destination.attention': 'String',
  '$.item.destination.city': 'String',
  '$.item.destination.country_code': 'String',
  '$.item.destination.department': 'String',
  '$.item.destination.name': 'String',
  '$.item.destination.postal_code': 'String',
  '$.item.destination.region_code': 'String',
  '$.item.dimensions.height': 'Float64',
  '$.item.dimensions.length': 'Float64',
  '$.item.dimensions.unit': 'String',
  '$.item.dimensions.width': 'Float64',
  '$.item.hazmat': 'Boolean',
  '$.item.item_id': 'String',
  '$.item.item_name': 'String',
  '$.item.item_return_type': 'String',
  '$.item.order_date': 'ZonedDateTime',
  '$.item.order_id': 'String',
  '$.item.order_locale': 'String',
  '$.item.order_return_override_code': 'String',
  '$.item.personalized': 'Boolean',
  '$.item.purchase_price.amount': 'Float64',
  '$.item.purchase_price.currency': 'String',
  '$.item.quantity': 'Float64',
  '$.item.reason_code': 'String',
  '$.item.shipment_id': 'String',
  '$.item.sku': 'String',
  '$.item.special_handling': 'Boolean',
  '$.item.tags': 'String',
  '$.item.variant': 'String',
  '$.item.vendor': 'PolicyVendor',
  '$.item.vendor.address': 'PolicyAddress',
  '$.item.vendor.address.address1': 'String',
  '$.item.vendor.address.address2': 'String',
  '$.item.vendor.address.city': 'String',
  '$.item.vendor.address.country_code': 'String',
  '$.item.vendor.address.department': 'String',
  '$.item.vendor.address.name': 'String',
  '$.item.vendor.address.postal_code': 'String',
  '$.item.vendor.address.region_code': 'String',
  '$.item.vendor.name': 'String',
  '$.item.vendor.type': 'String',
  '$.item.weight.unit': 'String',
  '$.item.weight.value': 'Float64',
};

export const criteriaTemplates = ({ attribute }) => ({
  Core: {
    String: {
      condition: {
        op: '{{operator}}',
        '{{expr_a}}': {
          op: 'read_env',
          type: 'String',
          json_path: attribute,
        },
        '{{expr_b}}': {
          op: 'literal<String>',
          value: '{{value}}',
        },
      },
    },
    Boolean: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'read_env',
        type: 'Boolean',
        json_path: attribute,
      },
      '{{expr_b}}': {
        op: 'literal<Boolean>',
        value: '{{value}}',
      },
    },
    Integer64: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'read_env',
        type: 'Integer64',
        json_path: attribute,
      },
      '{{expr_b}}': {
        op: 'literal<Integer64>',
        value: '{{value}}',
      },
    },
    Float64: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'read_env',
        type: 'Float64',
        json_path: attribute,
      },
      '{{expr_b}}': {
        op: 'literal<Float64>',
        value: '{{value}}',
      },
    },
    ZonedDateTime: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'read_env',
        type: 'ZonedDateTime',
        json_path: attribute,
      },
      '{{expr_b}}': {
        op: 'literal<ZonedDateTime>',
        value: '{{value}}',
      },
    },
  },
  Item: {
    String: {
      condition: {
        op: `{{aggregator}}`,
        exprs: [
          {
            op: '{{operator}}',
            '{{expr_a}}': {
              op: 'read_env',
              type: 'String',
              json_path: attribute,
            },
            '{{expr_b}}': {
              op: 'literal<String>',
              value: '{{value}}',
            },
          },
        ],
      },
    },
    Boolean: {
      condition: {
        op: `{{aggregator}}`,
        exprs: [
          {
            op: '{{operator}}',
            '{{expr_a}}': {
              op: 'read_env',
              type: 'Boolean',
              json_path: attribute,
            },
            '{{expr_b}}': {
              op: 'literal<Boolean>',
              value: '{{value}}',
            },
          },
        ],
      },
    },
    Integer64: {
      condition: {
        op: `{{aggregator}}`,
        exprs: [
          {
            op: '{{operator}}',
            '{{expr_a}}': {
              op: 'read_env',
              type: 'Integer64',
              json_path: attribute,
            },
            '{{expr_b}}': {
              op: 'literal<Integer64>',
              value: '{{value}}',
            },
          },
        ],
      },
    },
    Float64: {
      condition: {
        op: `{{aggregator}}`,
        exprs: [
          {
            op: '{{operator}}',
            '{{expr_a}}': {
              op: 'read_env',
              type: 'Float64',
              json_path: attribute,
            },
            '{{expr_b}}': {
              op: 'literal<Float64>',
              value: '{{value}}',
            },
          },
        ],
      },
    },
    ZonedDateTime: {
      condition: {
        op: `{{aggregator}}`,
        exprs: [
          {
            op: '{{operator}}',
            '{{expr_a}}': {
              op: 'read_env',
              type: 'ZonedDateTime',
              json_path: attribute,
            },
            '{{expr_b}}': {
              op: 'literal<ZonedDateTime>',
              value: '{{value}}',
            },
          },
        ],
      },
    },
  },
});

export const uiTemplates = ({
  attribute = '',
  attribute_display_value = '',
  criteria,
  value = 'String',
  aggregators,
  dcCriteria,
  ruleType,
}) => {
  const commonProps = {
    actions: standardActions({ ruleType }),
    attribute,
    attribute_display_value,
    operators: operators[value],
    aggregator: null,
    aggregators,
    dc_criteria:
      dcCriteria || objectPath.get(criteriaTemplates({ attribute }), criteria),
    separator: null,
    value: null,
    policyUiVersion,
  };
  return {
    String: {
      ...commonProps,
      type: 'text',
    },
    Boolean: {
      ...commonProps,
      type: 'boolean',
      values: [true, false],
    },
    Integer64: {
      ...commonProps,
      type: 'numeric',
    },
    Float64: {
      ...commonProps,
      type: 'numeric',
      sub_type: attribute.endsWith('price.amount') ? 'price' : undefined,
    },
    ZonedDateTime: {
      ...commonProps,
      type: 'date',
      value: undefined,
    },
  };
};

export const getTitle = ({
  titlePrepend,
  index,
  attribute,
  titleAppend,
  labelPrepend,
}) =>
  Object.keys(schemaLabelOverrides).includes(attribute) &&
  schemaLabelOverrides[attribute]
    ? schemaLabelOverrides[attribute]
    : `${[
        titlePrepend,
        [labelPrepend, attribute.replace(index, '')].filter(Boolean).join(' '),
      ]
        .filter(Boolean)
        .join('.')
        .replace(/_/g, ' ')
        .replace(/\b\w/g, l => l.toUpperCase())
        .replace(/\./g, ' - ')}${titleAppend ? ` ${titleAppend}` : ''}`;

const configMap = {
  [ORDER_KEY]: {
    subset: 'Core',
    aggregators: null,
    labelPrepend: null,
    titlePrepend: null,
    titleAppend: null,
    aggregatorTitle: null,
  },
  [ITEM_KEY]: {
    subset: 'Item',
    aggregators: aggregatorMap.item,
    labelPrepend: 'Item',
    titlePrepend: null,
    titleAppend: null,
    aggregatorTitle: 'Items',
  },
};

export const generateCriteria = ({ schema, ruleType }) => {
  const criteria = [];

  Object.entries(schema).forEach(([attribute, value]) => {
    if (!allowedSchemaTypes.includes(value)) {
      return;
    }
    let index = null;

    if (attribute.startsWith(ORDER_KEY)) {
      index = ORDER_KEY;
    } else if (attribute.startsWith(ITEM_KEY)) {
      index = ITEM_KEY;
    }

    if (!index) return;

    const { aggregators, subset } = configMap[index];

    criteria.push(
      objectPath.get(
        uiTemplates({
          attribute,
          value,
          ruleType,
          attribute_display_value: getTitle({
            index,
            attribute,
            ...configMap[index],
          }),
          criteria: [subset, value].filter(Boolean).join('.'),
          aggregators,
        }),
        value,
      ),
    );
  });

  return criteria;
};
