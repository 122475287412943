import moment from 'moment';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  RECEIVE_DASHBOARD,
  RECEIVE_CHART_DATA,
  RECEIVE_ERROR,
  DISMISS_ALERT,
  UPDATE_DATE_RANGE,
} from '../actions/promiseDashboardAnalyticsActions';

const initialState = {
  startDate: moment()
    .subtract(30, 'days')
    .format(API_DATE_FORMAT),
  endDate: moment()
    .subtract(1, 'days')
    .format(API_DATE_FORMAT),
  chartData: {},
  dimensions: [],
  errors: [],
  dashboard: {},
  object: '',
  reports: {},
  reportDefinitions: {},
  reportsPayload: [],
  alert: {
    show: false,
  },
};

export default (
  state = initialState,
  {
    type,
    meta,
    meta: { chartName } = {},
    payload: {
      startDate,
      endDate,
      chartData,
      tableData,
      report_definitions: reportDefinitions,
      report_instances: reportInstances,
      dashboard_definitions: dashboard,
    } = {},
  } = {},
) => {
  let object;
  switch (type) {
    case RECEIVE_DASHBOARD:
      object = Object.keys(reportDefinitions)[0];
      return {
        ...state,
        dashboard,
        object,
        reports: {
          ...state.reports,
          ...reportInstances,
        },
        reportDefinitions,
      };
    case RECEIVE_CHART_DATA:
      return {
        ...state,
        chartData: {
          ...state.chartData,
          [chartName]: chartData,
        },
        tableData: {
          ...state.tableData,
          [chartName]: tableData,
        },
      };
    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'Unknown error occured.'
              : `${meta.error}.`,
        },
      };
    case UPDATE_DATE_RANGE:
      return {
        ...state,
        startDate,
        endDate,
      };
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };
    default:
      return state;
  }
};
