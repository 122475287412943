// Not sure why we have to disable the line below, it's in package.json but still fails linting :/
import analyticsService from '@narvar/analytics-service'; // eslint-disable-line import/no-extraneous-dependencies

const SNOWPLOW_SCHEMA = 'iglu:com.narvar/monitor/jsonschema/1-0-0';

const EVENTS = {
  PAGE_LOAD: 'page_load', // mapped to 'event_type'
  EXPORT: 'export',
};

const EVENT_FIELDS = {
  PAGE_NAME: 'page_name',
  DRILL_LEVEL: 'drill_level',
  NAME_000: '000_name',
  NAME_001: '001_name',
  EXPORT_TYPE: 'export_type', // pdf | excel
  USER_ID: 'user_id',
  RESPONSE_MS: 'response_ms',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  URL: 'url',
};

const EVENTS_WITH_FIELDS = {
  [EVENTS.PAGE_LOAD]: [
    EVENT_FIELDS.PAGE_NAME, // lvl_1
    EVENT_FIELDS.DRILL_LEVEL, // lvl_2
    EVENT_FIELDS.NAME_000, // lvl_3
    EVENT_FIELDS.NAME_001, // etc...
    null,
    EVENT_FIELDS.USER_ID,
    EVENT_FIELDS.RESPONSE_MS,
    EVENT_FIELDS.START_DATE,
    EVENT_FIELDS.END_DATE,
    EVENT_FIELDS.URL,
  ],
  [EVENTS.EXPORT]: [
    EVENT_FIELDS.PAGE_NAME,
    EVENT_FIELDS.DRILL_LEVEL,
    EVENT_FIELDS.NAME_000,
    EVENT_FIELDS.NAME_001,
    EVENT_FIELDS.EXPORT_TYPE,
    EVENT_FIELDS.USER_ID,
    EVENT_FIELDS.RESPONSE_MS,
    EVENT_FIELDS.START_DATE,
    EVENT_FIELDS.END_DATE,
    EVENT_FIELDS.URL,
  ],
};

const config = {
  trackerName: 'monitor',
  snowplowSchema: SNOWPLOW_SCHEMA,
  eventsWithFields: EVENTS_WITH_FIELDS,
  staticProps: {
    event_schema: 'monitor',
  },
};

if (__ENV__ !== 'production') {
  config.dataCallback = data => console.info('Sending to Snowplow: ', data); // eslint-disable-line no-console
}

const analytics = analyticsService(config);

export { analytics, EVENTS, EVENT_FIELDS };
