import {
  aggregatorMap,
  operators,
  policyUiVersion,
  standardActions,
  uiTemplates,
} from './orderSchema';
import { getCurrencyCodes } from './helpers';

const category = ({ ruleType }) => ({
  policyUiVersion,
  actions: standardActions({ ruleType }),
  attribute: '$.category',
  attribute_display_value: 'Product Category',
  type: 'text',
  operators: ['in', 'not in'],
  aggregators: aggregatorMap.item,
  dc_criteria: {
    condition: {
      op: `{{aggregator}}`,
      exprs: [
        {
          op: `{{operator}}`,
          needle_expr: {
            op: 'literal<String>',
            value: '{{value}}',
          },
          haystack_expr: {
            op: 'read_env',
            type: 'List<String>',
            json_path: '$.item.categories',
          },
        },
      ],
    },
  },
});

const itemCustomAttribute = ({ ruleType }) => ({
  policyUiVersion,
  actions: standardActions({ ruleType }),
  attribute: '$.item.custom_attribute',
  attribute_display_value: 'Item Custom Attribute',
  type: 'key_value_text',
  operators: ['=', 'not equals', 'in', 'not in'],
  aggregators: aggregatorMap.item,
  separator: ':',
  dc_criteria: {
    condition: {
      op: `{{aggregator}}`,
      exprs: [
        {
          op: `{{operator}}`,
          '{{expr_b}}': {
            op: 'literal<String>',
            value: '{{value}}',
          },
          '{{expr_a}}': {
            op: 'get_item_attribute',
            attribute_name_expr: {
              op: 'literal<String>',
              value: '{{key}}',
            },
          },
        },
      ],
    },
  },
});

const orderCustomAttribute = ({ ruleType }) => ({
  policyUiVersion,
  actions: standardActions({ ruleType }),
  attribute: '$.custom_attribute',
  attribute_display_value: 'Order Custom Attribute',
  type: 'key_value_text',
  operators: ['=', 'not equals', 'in', 'not in'],
  separator: ':',
  dc_criteria: {
    condition: {
      op: 'all_items',
      exprs: [
        {
          op: `{{operator}}`,
          '{{expr_b}}': {
            op: 'literal<String>',
            value: '{{value}}',
          },
          '{{expr_a}}': {
            op: 'get_item_order_attribute',
            attribute_name_expr: {
              op: 'literal<String>',
              value: '{{key}}',
            },
          },
        },
      ],
    },
  },
});

const productVendorSame = ({ ruleType }) => ({
  policyUiVersion,
  actions: ['return_to_same_vendor', ...standardActions({ ruleType })],
  attribute: 'product_vendor_same',
  attribute_display_value: 'Vendor Same For All Items',
  type: 'static_value',
  separator: null,
  tokens: {},
  dc_criteria: {
    condition: {
      op: 'is_list_unique_and_non_null',
      expr: {
        op: 'read_env',
        type: 'List<PolicyVendor>',
        json_path: '$.request.items[*].vendor',
      },
    },
  },
});

const totalItemsQuantity = ({ ruleType }) =>
  uiTemplates({
    attribute: 'total_items_quantity',
    attribute_display_value: 'Total Items Quantity',
    dcCriteria: {
      condition: {
        op: '{{operator}}',
        '{{expr_a}}': {
          op: 'total_items_quantity',
        },
        '{{expr_b}}': {
          op: 'literal<Integer64>',
          value: '{{value}}',
        },
      },
    },
    criteria: 'Integer64',
    value: 'Integer64',
    ruleType,
  }).Integer64;

const alwaysTrue = ({ ruleType }) => ({
  actions: standardActions({ ruleType }),
  attribute: 'always_true',
  attribute_display_value: 'Always True',
  dc_criteria: {
    condition: { op: 'literal<Boolean>', value: true },
  },
  type: 'static_value',
});

const totalItemsWeight = ({ ruleType }) => ({
  actions: standardActions({ ruleType }),
  attribute: 'total_items_weight',
  attribute_display_value: 'Total Weight',
  sub_selection_items: [
    {
      type: 'select',
      values: [
        { picklist_token: 'KG', picklist_value: 'KG' },
        { picklist_token: 'LB', picklist_value: 'LB' },
        { picklist_token: 'OZ', picklist_value: 'OZ' },
      ],
      key: 'total_items_weight.unit',
      placeholder: 'Select Unit',
    },
  ],
  sub_selection_item_values: [],
  type: 'numeric',
  operators: operators.Float64,
  dc_criteria: {
    condition: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'total_items_weight',
        result_unit: '{{sub_selection_items.0}}',
      },
      '{{expr_b}}': {
        op: 'literal<Float64>',
        value: '{{value}}',
      },
    },
  },
});

const totalItemPurchasePrice = ({ ruleType }) => ({
  actions: standardActions({ ruleType }),
  attribute: 'total_items_purchase_price',
  attribute_display_value: 'Total Items Purchase Price',
  sub_selection_items: [
    {
      type: 'select',
      values: getCurrencyCodes(),
      key: 'total_items_purchase_price.currency_code',
      placeholder: 'Select Currency',
    },
  ],
  sub_selection_item_values: ['Any Currency'],
  type: 'numeric',
  sub_type: 'price',
  operators: operators.Integer64,
  dc_criteria: {
    condition: {
      op: '{{operator}}',
      '{{expr_a}}': {
        op: 'total_items_purchase_price',
        currency_code: '{{sub_selection_items.0}}',
      },
      '{{expr_b}}': {
        op: 'literal<Integer64>',
        value: '{{value}}',
      },
    },
  },
});

const orderTotalPurchasePriceAmount = ({ ruleType }) => ({
  actions: standardActions({ ruleType }),
  attribute: 'order_total_purchase_price_amount',
  attribute_display_value: 'Total Purchase Price Amount',
  type: 'numeric',
  sub_type: 'price',
  operators: operators.Integer64,
  dc_criteria: {
    condition: {
      op: 'all_items',
      exprs: [
        {
          op: '{{operator}}',
          '{{expr_a}}': {
            op: 'read_env',
            type: 'Integer64',
            json_path: '$.item.order_total_purchase_price.amount',
          },
          '{{expr_b}}': {
            op: 'literal<Integer64>',
            value: '{{value}}',
          },
        },
      ],
    },
  },
});
const orderTotalPurchasePriceCurrency = ({ ruleType }) => ({
  actions: standardActions({ ruleType }),
  attribute: 'order_total_purchase_price_currency',
  attribute_display_value: 'Total Purchase Price Currency',
  type: 'text',
  operators: operators.String,
  dc_criteria: {
    condition: {
      op: 'all_items',
      exprs: [
        {
          op: '{{operator}}',
          '{{expr_a}}': {
            op: 'read_env',
            type: 'String',
            json_path: '$.item.order_total_purchase_price.currency',
          },
          '{{expr_b}}': {
            op: 'literal<String>',
            value: '{{value}}',
          },
        },
      ],
    },
  },
});

export const customCriteriaDefinitions = ({ ruleType }) =>
  [
    alwaysTrue,
    category,
    itemCustomAttribute,
    orderCustomAttribute,
    orderTotalPurchasePriceAmount,
    orderTotalPurchasePriceCurrency,
    productVendorSame,
    totalItemPurchasePrice,
    totalItemsQuantity,
    totalItemsWeight,
  ].map(fn => fn({ ruleType }));
