import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Switch.styles';

// NOTE add label as a required prop

export class Switch extends Component {
  shouldComponentUpdate(nextProps) {
    const { value, disabled } = this.props;

    if (
      value !== nextProps.value ||
      disabled !== nextProps.disabled
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { label, name, testAttr, value, showSubText, enabledText, disabledText, disabled } = this.props;

    return (
      <div>
        <div
          className={cx(s, { 'Switch-on': value }, {'showSubText': showSubText})}
          data-test={
            `${testAttr ? testAttr.toLowerCase() : label ? label.toLowerCase() : 'narvar'}-switch`
          }
        >
          <input name={name} type={'checkbox'} disabled={disabled} onChange={this.props.onChange} checked={value} />
          <span />
        </div>
        {showSubText &&
          <div className={cx(s,{'subText': showSubText})} >
            <p className={cx('small', 'text-muted')}>
              {value ? (enabledText || 'ON') : (disabledText || 'OFF')}
            </p>
          </div>
        }
      </div>
    );
  }
}

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  enabledText: PropTypes.string,
  disabledText: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  showSubText: PropTypes.bool,
};

export default Switch;
