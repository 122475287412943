import { css } from 'emotion';

const s = css`
  border-radius: 30px;
  height: 10px;
  margin: 6px 10px auto 10px;
  width: 10px;
`;

export default s;
