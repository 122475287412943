import { isEmail } from 'validator';
import { push } from 'connected-react-router';
import { API_REQUEST, API_INVALIDATE_PATH } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import {
  ACTIONS_COPY as COPY,
  EMAIL_TYPE,
} from '../constants/copy/wysiwygEditor';

export const NEW = 'new';

export const WYSIWYG_EDITOR_SUCCESS_ALERT = 'WYSIWYG_EDITOR_SUCCESS_ALERT';
export const WYSIWYG_EDITOR_ERROR_ALERT = 'WYSIWYG_EDITOR_ERROR_ALERT';
export const WYSIWYG_EDITOR_RECEIVE_LOCALES = 'WYSIWYG_EDITOR_RECEIVE_LOCALES';
export const WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LIST =
  'WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LIST';
export const WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LOCALES =
  'WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LOCALES';
export const WYSIWYG_EDITOR_RECEIVE_BLOCK_LIST =
  'WYSIWYG_EDITOR_RECEIVE_BLOCK_LIST';
export const WYSIWYG_EDITOR_RECEIVE_BLOCK_LOCALES =
  'WYSIWYG_EDITOR_RECEIVE_BLOCK_LOCALES';
export const WYSIWYG_EDITOR_RECEIVE_GLOBAL_SETTINGS =
  'WYSIWYG_EDITOR_RECEIVE_GLOBAL_SETTINGS';
export const WYSIWYG_EDITOR_RECEIVE_TEMPLATE =
  'WYSIWYG_EDITOR_RECEIVE_TEMPLATE';
export const WYSIWYG_EDITOR_RECEIVE_TEMPLATE_DETAILS =
  'WYSIWYG_EDITOR_RECEIVE_TEMPLATE_DETAILS';
export const WYSIWYG_EDITOR_RECEIVE_AFFECTED_TEMPLATE =
  'WYSIWYG_EDITOR_RECEIVE_AFFECTED_TEMPLATE';
export const WYSIWYG_EDITOR_RECEIVE_BLOCK = 'WYSIWYG_EDITOR_RECEIVE_BLOCK';
export const WYSIWYG_EDITOR_RECEIVE_COMPONENTS =
  'WYSIWYG_EDITOR_RECEIVE_COMPONENTS';
export const WYSIWYG_EDITOR_RECEIVE_TEST_DATA_LIST =
  'WYSIWYG_EDITOR_RECEIVE_TEST_DATA_LIST';
export const WYSIWYG_EDITOR_RECEIVE_TEST_DATA_DETAILS =
  'WYSIWYG_EDITOR_RECEIVE_TEST_DATA_DETAILS';
export const WYSIWYG_EDITOR_CLEAR_DETAILS = 'WYSIWYG_EDITOR_CLEAR_DETAILS';
export const WYSIWYG_EDITOR_PREVIEW_TEMPLATE =
  'WYSIWYG_EDITOR_PREVIEW_TEMPLATE';
export const WYSIWYG_EDITOR_PUBLISH_TEMPLATE =
  'WYSIWYG_EDITOR_PUBLISH_TEMPLATE';
export const WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST =
  'WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST';
export const WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST_CUSTOM =
  'WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST_CUSTOM';
export const WYSIWYG_EDITOR_UPDATE_TEMPLATE_DETAILS_ON_PUBLISH_UN_PUBLISH =
  'WYSIWYG_EDITOR_UPDATE_TEMPLATE_DETAILS_ON_PUBLISH_UN_PUBLISH';
export const WYSIWYG_FETCH_CHANNEL_LIST = 'WYSIWYG_FETCH_CHANNEL_LIST';

const resetComponentErrorState = () => {
  const editor = window.GEditor.getEditor(0);
  const components = editor.DomComponents.getComponents();
  components.each(component => {
    component.removeClass('gjs-comp-error');
  });
};

export const errorAlert = message => ({
  type: WYSIWYG_EDITOR_ERROR_ALERT,
  payload: message,
});

export const successAlert = message => ({
  type: WYSIWYG_EDITOR_SUCCESS_ALERT,
  payload: message,
});

export const resetDetails = () => ({
  type: WYSIWYG_EDITOR_CLEAR_DETAILS,
});

const handleApiError = (dispatch, err, fallbackMessage) => {
  const { clientMessage } = err;
  const alertMsg = clientMessage || fallbackMessage;
  dispatch(errorAlert(alertMsg));
};

const getRetailerMonikerFromState = state => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: uriMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = state;
  return uriMoniker;
};

export const getUserLocales = userReducer => {
  const {
    user: {
      retailerId = '',
      retailerIdToRetailerInfo: {
        [retailerId]: { uri_moniker: retailerMoniker = '' } = {},
      } = {},
      userLocales: { [retailerMoniker]: locales = [] } = {},
    } = {},
  } = userReducer;
  return locales;
};

export const getRetailerMonikerFromUser = user => {
  const {
    current_retailer_id: currentRetailerId,
    retailerIdToRetailerInfo: {
      [currentRetailerId]: { uri_moniker: uriMoniker } = {},
    } = {},
  } = user;
  return uriMoniker;
};

const isCsm = state => {
  const {
    userReducer: { user: { customerSupportLogin = false } = {} } = {},
  } = state;
  return customerSupportLogin;
};

export const fetchStandardComponents = () => async dispatch => {
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_COMPONENTS,
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_COMPONENTS,
      name: 'fetchWysiwygComponents',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_COMPONENTS,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_COMPONENTS_FAILED));
  }
};

export const fetchRetailerLocales = () => async (dispatch, getState) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_LOCALES({
        retailerMoniker,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_LOCALES({
        retailerMoniker,
      }),
      name: 'fetchRetailerLocales',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_LOCALES,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEMPLATES_FAILED));
  }
};

export const fetchTemplateList = locale => async (dispatch, getState) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  const isNarvarUser = isCsm(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEMPLATES({
        retailerMoniker,
        locale,
        isNarvarUser,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEMPLATES({
        retailerMoniker,
        locale,
        isNarvarUser,
      }),
      name: 'fetchWysiwygTemplates',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LIST,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEMPLATES_FAILED));
  }
};

export const fetchWysiwygTemplateLocales = templateId => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEMPLATE_LOCALES({
        retailerMoniker,
        templateId,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEMPLATE_LOCALES({
        retailerMoniker,
        templateId,
      }),
      name: 'fetchWysiwygTemplateLocales',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEMPLATE_LOCALES,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEMPLATES_FAILED));
  }
};

export const fetchRetailerBlocks = locale => async (dispatch, getState) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_BLOCKS({ retailerMoniker, locale }),
    });
    let { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_BLOCKS({ retailerMoniker, locale }),
      name: 'fetchWysiwygBlocks',
    });
    if (
      !isCsm(state) &&
      data.filter(item => item.category === 'Custom Blocks').length < 3
    ) {
      data = data.filter(item => item.category !== 'Custom Blocks');
    }
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_BLOCK_LIST,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_BLOCKS_FAILED));
  }
};

export const fetchWysiwygBlockLocales = blockId => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_BLOCK_LOCALES({
        retailerMoniker,
        blockId,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_BLOCK_LOCALES({
        retailerMoniker,
        blockId,
      }),
      name: 'fetchWysiwygBlockLocales',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_BLOCK_LOCALES,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_BLOCK_FAILED));
  }
};

export const fetchGlobalSettings = locale => async (dispatch, getState) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_GLOBAL_SETTING({ retailerMoniker, locale }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_GLOBAL_SETTING({ retailerMoniker, locale }),
      name: 'fetchWysiwygSettings',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_GLOBAL_SETTINGS,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_SETTINGS_FAILED));
  }
};

export const fetchWysiwygBlock = (blockId, locale) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_BLOCK({
        retailerMoniker,
        locale,
        blockId,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_BLOCK({
        retailerMoniker,
        locale,
        blockId,
      }),
      name: 'fetchWysiwygBlock',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_BLOCK,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_BLOCK_FAILED));
  }
};

export const fetchWysiwygTemplate = templateId => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEMPLATE({
        retailerMoniker,
        templateId,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEMPLATE({
        retailerMoniker,
        templateId,
      }),
      name: 'fetchWysiwygTemplate',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEMPLATE,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEMPLATE_FAILED));
  }
};

export const fetchWysiwygTemplateDetails = templateId => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEMPLATE_DETAILS({
        retailerMoniker,
        templateId,
      }),
    });
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEMPLATE_DETAILS({
        retailerMoniker,
        templateId,
      }),
      name: 'fetchWysiwygTemplateDetails',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEMPLATE_DETAILS,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEMPLATE_FAILED));
  }
};

export const saveWysiwygTemplate = (templateId, templateData) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(templateData),
      path: ENDPOINTS.WYSIWYG_TEMPLATE({
        retailerMoniker,
        templateId,
      }),
      name: 'saveWysiwygTemplate',
    });
    dispatch(successAlert(COPY.SAVE_TEMPLATE_SUCCESS));
  } catch (err) {
    handleApiError(dispatch, err, COPY.SAVE_TEMPLATE_ERROR);
  }
};

export const createWysiwygBlock = (blockData, locale) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(blockData),
      path: ENDPOINTS.WYSIWYG_CREATE_BLOCK({
        retailerMoniker,
        locale,
      }),
      name: 'createWysiwygBlock',
    });
    dispatch(successAlert(COPY.CREATE_BLOCK_SUCCESS));
    dispatch(fetchRetailerBlocks(locale));
  } catch (err) {
    handleApiError(dispatch, err, COPY.CREATE_BLOCK_ERROR);
  }
};

export const saveWysiwygBlock = (blockId, blockData) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(blockData),
      path: ENDPOINTS.WYSIWYG_BLOCK({
        retailerMoniker,
        blockId,
      }),
      name: 'saveWysiwygBlock',
    });
    dispatch(successAlert(COPY.SAVE_BLOCK_SUCCESS));
  } catch (err) {
    handleApiError(dispatch, err, COPY.SAVE_BLOCK_ERROR);
  }
};

export const saveGlobalSettings = (payload, locale) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.WYSIWYG_GLOBAL_SETTING({ retailerMoniker, locale }),
      body: JSON.stringify(payload),
      name: 'saveWysiwygSettings',
    });
    dispatch(successAlert(COPY.SAVE_SETTINGS_SUCCESS));
  } catch (err) {
    dispatch(errorAlert(COPY.SAVE_SETTINGS_ERROR));
  }
};

export const fetchTestDataList = () => async (dispatch, getState) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    const {
      data: { testData },
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEST_DATA({ retailerMoniker }),
      name: 'fetchWysiwygTestData',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEST_DATA_LIST,
      payload: testData,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEST_DATA_FAILED));
  }
};

export const fetchTestDataDetails = testDataId => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.WYSIWYG_TEST_DATA_DETAILS({
      retailerMoniker,
      testDataId,
    }),
  });
  try {
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_TEST_DATA_DETAILS({
        retailerMoniker,
        testDataId,
      }),
      name: 'fetchWysiwygTestDataDetails',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_TEST_DATA_DETAILS,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_TEST_DATA_FAILED));
  }
};

export const saveTestData = (testDataId, payload) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path:
        testDataId === NEW
          ? ENDPOINTS.WYSIWYG_TEST_DATA({ retailerMoniker })
          : ENDPOINTS.WYSIWYG_TEST_DATA_DETAILS({
              retailerMoniker,
              testDataId,
            }),
      body: JSON.stringify(payload),
      name: 'saveWysiwygTestData',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEST_DATA({ retailerMoniker }),
    });

    dispatch(resetDetails());
    dispatch(push('/notify/wysiwyg-editor/test-data'));
    dispatch(successAlert(COPY.SAVE_TESTDATA_SUCCESS));
  } catch (err) {
    dispatch(errorAlert(COPY.SAVE_TESTDATA_ERROR));
  }
};

export const deleteTestData = testDataId => async (dispatch, getState) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: ENDPOINTS.WYSIWYG_TEST_DATA_DETAILS({
        retailerMoniker,
        testDataId,
      }),
      name: 'deleteWysiwygTestDataDetails',
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.WYSIWYG_TEST_DATA({ retailerMoniker }),
    });
    dispatch(resetDetails());
    dispatch(push('/notify/wysiwyg-editor/test-data'));
    dispatch(successAlert(COPY.DELETE_TESTDATA_SUCCESS));
  } catch (err) {
    dispatch(errorAlert(COPY.DELETE_TESTDATA_ERROR));
  }
};

export const previewTemplate = (templateData, testDataId) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    resetComponentErrorState();
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.WYSIWYG_PREVIEW_DRAFT({
        retailerMoniker,
        testDataId,
      }),
      body: JSON.stringify(templateData),
      name: 'previewWysiwygTemplate',
    });
    dispatch({
      type: WYSIWYG_EDITOR_PREVIEW_TEMPLATE,
      payload: data,
    });
  } catch (err) {
    try {
      const { data } = await dispatch({
        type: API_REQUEST,
        method: 'POST',
        path: ENDPOINTS.WYSIWYG_VALIDATE_TEMPLATE({
          retailerMoniker,
          testDataId,
        }),
        body: JSON.stringify(templateData),
        name: 'validateWysiwygTemplate',
      });

      if (data && data.length > 0) {
        const message = data.reduce((a, c) => {
          return `${a ? `${a},` : ''} Block #${c.index} - ${c.blockType}`;
        }, '');

        const editor = window.GEditor.getEditor(0);
        const components = editor.DomComponents.getComponents();
        data.forEach(block => {
          components.at(block.index).addClass('gjs-comp-error');
        });

        dispatch(errorAlert(`Syntax error in following blocks: ${message}`));
      } else {
        dispatch(errorAlert(COPY.FETCH_PREVIEW_FAILED));
      }
    } catch (error) {
      dispatch(errorAlert(COPY.FETCH_PREVIEW_FAILED));
    }

    dispatch({
      type: WYSIWYG_EDITOR_PREVIEW_TEMPLATE,
      payload: {},
    });
  }
};

export const fetchAffectedTemplates = (blockId, locale) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_BLOCK_AFFECTED_TEMPLATE({
        retailerMoniker,
        locale,
        blockId,
      }),
      name: 'fetchWysiwygAffectedTemplate',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_AFFECTED_TEMPLATE,
      payload: data,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_AFFECTED_TEMPLATES_FAILED));
  }
};

const validateEmailList = list => {
  const emails = list.split(',');
  return emails.every(email => isEmail(email.trim()));
};

const generateSparkPostBody = ({ recipients, html, subject }) => {
  const generateRecipientList = recipientString => {
    const emails = recipientString.split(',');
    return emails.map(email => ({
      address: { email: email.trim(), name: email.split('@')[0].trim() },
    }));
  };

  const recipientList = generateRecipientList(recipients);
  const sendFromEmail = 'support@narvar.com';
  const replyToEmail = 'support@narvar.com';
  const sendFromName = 'Narvar Support';
  return {
    options: {
      open_tracking: true,
      click_tracking: true,
      transactional: true,
    },
    recipients: recipientList,
    content: {
      from: {
        email: sendFromEmail,
        name: sendFromName,
      },
      text: '',
      html,
      subject,
      reply_to: replyToEmail,
    },
  };
};

export const sendTestEmail = ({ templateId, testDataId, recipients }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const retailerMoniker = getRetailerMonikerFromState(state);
  if (!validateEmailList(recipients)) {
    dispatch(errorAlert(COPY.INVALID_RECIPIENT_EMAIL));
    return;
  }
  try {
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.WYSIWYG_PREVIEW({
        retailerMoniker,
        templateId,
        testDataId,
      }),
      body: JSON.stringify({
        testDataName: testDataId,
      }),
      name: 'previewWysiwygTemplate',
    });
    const { body: html, subject } = data;
    const sparkPostBody = generateSparkPostBody({
      html,
      subject,
      recipients,
    });
    await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(sparkPostBody),
      path: ENDPOINTS.SEND_EMAIL,
      name: 'sendWysiwygEmail',
    });
    dispatch(successAlert(COPY.TEST_EMAIL_SUCCESS));
  } catch (err) {
    dispatch(errorAlert(COPY.TEST_EMAIL_FAILED));
  }
};

export const fetchPublishedTemplatesList = () => async dispatch => {
  try {
    const { templates } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.EMAIL_TEMPLATES_LIST_WYSIWYG}`,
      name: 'fetchPublishedTemplateList',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST,
      payload: templates,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_PUBLISHED_TEMPLATES_FAILED));
  }
};

export const fetchPublishedTemplatesListCustom = () => async dispatch => {
  try {
    const { templates } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.EMAIL_TEMPLATES_LIST_CUSTOM}`,
      name: 'fetchPublishedTemplateListCustom',
    });
    dispatch({
      type: WYSIWYG_EDITOR_RECEIVE_PUBLISHED_TEMPLATES_LIST_CUSTOM,
      payload: templates,
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_PUBLISHED_TEMPLATES_FAILED));
  }
};

const invalidatePaths = async (dispatch, paths) => {
  const promises = paths.map(path => {
    return dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });
  });
  await Promise.all(promises);
};

const updateTemplateDetails = (
  templateId,
  retailerMoniker,
) => async dispatch => {
  try {
    await invalidatePaths(dispatch, [
      ENDPOINTS.WYSIWYG_TEMPLATE_DETAILS({
        retailerMoniker,
        templateId,
      }),
      ENDPOINTS.EMAIL_TEMPLATES_LIST_WYSIWYG,
    ]);

    const [
      { templates: publishedTemplates },
      { data: templateDetails },
    ] = await Promise.all([
      dispatch({
        type: API_REQUEST,
        method: 'GET',
        path: `${ENDPOINTS.EMAIL_TEMPLATES_LIST_WYSIWYG}`,
        name: 'fetchPublishedTemplateList',
      }),
      dispatch({
        type: API_REQUEST,
        method: 'GET',
        path: ENDPOINTS.WYSIWYG_TEMPLATE_DETAILS({
          retailerMoniker,
          templateId,
        }),
        name: 'fetchWysiwygTemplateDetails',
      }),
    ]);

    dispatch({
      type: WYSIWYG_EDITOR_UPDATE_TEMPLATE_DETAILS_ON_PUBLISH_UN_PUBLISH,
      payload: { publishedTemplates, templateDetails },
    });
  } catch (error) {
    dispatch(errorAlert(COPY.FETCH_PUBLISHED_TEMPLATES_FAILED));
  }
};

const getTemplateProcessorJson = async (
  dispatch,
  { retailerMoniker, templateId, testDataId },
) => {
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.WYSIWYG_TEMPLATE_PROCESSOR_JSON({
      retailerMoniker,
      templateId,
      testDataId,
    }),
    name: 'publishWysiwygTemplate',
  });
};

const getExistingPublishedTemplate = async (
  dispatch,
  triggerType,
  locale = '',
  retailerMoniker,
) => {
  await invalidatePaths(dispatch, [
    ENDPOINTS.GET_EMAIL_TEMPLATE_BY_PARAMS({
      type: triggerType,
      locale: locale.toLowerCase(),
      emailType: EMAIL_TYPE.WYSIWYG,
      retailerMoniker,
    }),
  ]);
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.GET_EMAIL_TEMPLATE_BY_PARAMS({
      type: triggerType,
      locale: locale.toLowerCase(),
      emailType: EMAIL_TYPE.WYSIWYG,
      retailerMoniker,
    }),
    name: 'fetchExistingEmailTemplate',
  });
};

export const publishTemplate = (templateId, testDataId) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await invalidatePaths(dispatch, [
      ENDPOINTS.WYSIWYG_TEMPLATE_PROCESSOR_JSON({
        retailerMoniker,
        templateId,
        testDataId,
      }),
      ENDPOINTS.WYSIWYG_PUBLISH({ retailerMoniker, templateId }),
    ]);

    const {
      data: {
        locale,
        readable_locale: readableLocale,
        trigger_type: triggerType,
        name: triggerName,
        template_data_string: templateDataString,
        test_data_string: testDataString,
      },
    } = await getTemplateProcessorJson(dispatch, {
      retailerMoniker,
      templateId,
      testDataId,
    });

    const { template: existingTemplate } = await getExistingPublishedTemplate(
      dispatch,
      triggerType,
      locale,
      retailerMoniker,
    );

    let templatePayload = {
      locale: {
        locale_code: locale.toLowerCase(),
        readable_locale: readableLocale || locale,
      },
      type: {
        display_name: triggerName,
        key: triggerType,
      },
      template_data_string: templateDataString,
      test_data_json: testDataString,
      processor: 'mjml',
      email_type: EMAIL_TYPE.WYSIWYG,
    };

    let method = 'POST';
    if (existingTemplate) {
      method = 'PUT';

      templatePayload = {
        ...existingTemplate,
        ...templatePayload,
      };
    }
    // Publish template to event proc retailer_templates db
    await dispatch({
      type: API_REQUEST,
      method,
      body: JSON.stringify({ template: templatePayload }),
      path: `${ENDPOINTS.EMAIL_TEMPLATES}`,
      name: 'publishEmailTemplate',
    });

    // Publish changes to wysiwyg db
    await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_PUBLISH({ retailerMoniker, templateId }),
      name: 'publishWysiwygTemplate',
    });

    await dispatch(updateTemplateDetails(templateId, retailerMoniker));
    dispatch(successAlert(COPY.PUBLISH_SUCCESS));
  } catch (err) {
    dispatch(
      errorAlert(
        err.clientMessage && err.clientMessage.includes('Stale state')
          ? err.clientMessage
          : COPY.PUBLISH_FAILED,
      ),
    );
  }
};

export const unpublishTemplate = (templateId, triggerType, locale) => async (
  dispatch,
  getState,
) => {
  const retailerMoniker = getRetailerMonikerFromState(getState());
  try {
    await invalidatePaths(dispatch, [
      ENDPOINTS.WYSIWYG_UNPUBLISH({ retailerMoniker, templateId }),
    ]);

    const { template: existingTemplate } = await getExistingPublishedTemplate(
      dispatch,
      triggerType,
      locale,
      retailerMoniker,
    );

    if (existingTemplate) {
      await dispatch({
        type: API_REQUEST,
        method: 'DELETE',
        path: ENDPOINTS.EMAIL_TEMPLATES_ID(existingTemplate.id),
        name: 'deleteEmailTemplate',
      });
    }

    await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_UNPUBLISH({ retailerMoniker, templateId }),
      name: 'unpublishWysiwygTemplate',
    });
    await dispatch(updateTemplateDetails(templateId, retailerMoniker));
    dispatch(successAlert(COPY.UNPUBLISH_SUCCESS));
  } catch (err) {
    dispatch(
      errorAlert(
        err.clientMessage && err.clientMessage.includes('Stale state')
          ? err.clientMessage
          : COPY.UNPUBLISH_FAILED,
      ),
    );
  }
};

export const fetchMessagingSettingOptions = retailerMoniker => async dispatch => {
  try {
    const { data } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.WYSIWYG_MESSAGING_OPTIONS({
        retailerMoniker,
      }),
      name: 'fetchRetailerChannels',
    });
    if (data) {
      dispatch({
        type: WYSIWYG_FETCH_CHANNEL_LIST,
        payload: data,
      });
    }
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_CHANNELS_FAILED));
  }
};

export const fetchSaveMessagingData = formData => async dispatch => {
  try {
    const {
      channelsList,
      triggersList,
      isNewRetailer,
      retailerMoniker,
    } = formData;
    const method = 'POST';
    await dispatch({
      type: API_REQUEST,
      method,
      body: JSON.stringify({
        channelsList,
        triggersList,
        isNewRetailer,
        retailerMoniker,
      }),
      path: ENDPOINTS.WYSIWYG_MESSAGING_UPDATE_RNP_DATA({
        retailerMoniker,
      }),
      name: 'fetchSaveMessagingData',
    });
  } catch (err) {
    dispatch(errorAlert(COPY.FETCH_SAVE_RNP_DATA));
  }
};

export const initiateMessagingOnBoarding = formData => async dispatch => {
  try {
    const {
      channelsList,
      triggersList,
      retailerMoniker,
      retailerName,
      locales,
    } = formData;

    const method = 'POST';

    const { data } = await dispatch({
      type: API_REQUEST,
      method,
      body: JSON.stringify({
        retailerName,
        triggers: triggersList,
        locales,
        channels: channelsList,
      }),
      path: ENDPOINTS.WYSIWYG_MESSAGING_ON_BOARDING({
        retailerMoniker,
      }),
      name: 'initiateMessagingOnBoarding',
    });

    return data.jobId;
  } catch (err) {
    dispatch(errorAlert(COPY.INITIATE_MESSAGING_ON_BOARDING_FAILED));
  }
  return null;
};
