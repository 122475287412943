import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  align-items: center;
  border-radius: 2px;
  display: flex;
  font-family: ${fonts.bold};
  height: 40px;
  justify-content: center;
  min-width: 100px;
  text-transform: uppercase;
  justify-content: center;

  &.btn {
    background-color: ${colors.red};
    box-shadow: none;

    &:disabled {
      opacity: 1;
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(19, 153, 214, .5);
    }

  }

  &.btn-sm {
    height: 20px;
    padding: 0 20px;
  }

  &.btn-primary {
    background-color: ${colors.blue};
    border-color: ${colors.blue};
    color: ${colors.white};

    &:disabled {
      opacity: 1;
      background-color: ${colors.gray};
      border-color: ${colors.gray};
      box-shadow: none;

      &:hover {
        background-color: ${colors.gray};
        border-color: ${colors.gray};
      }

      &.is-fetching {
        background-color: ${colors.blue};
        border-color: ${colors.blue};
      }

    }

    &:hover {
      border-color: ${colors.darkBlue};
      background-color: ${colors.darkBlue};
    }

    &:active {
      border-color: ${colors.blue};
      background-color: ${colors.blue};
    }

    & .button-loader {
      border-top-color: ${colors.white};
      border-right-color: ${colors.white};
      border-bottom-color: ${colors.white};
      border-left-color: transparent;
    }

  }

  &.btn-secondary {
    background-color: ${colors.white};
    border-color: ${colors.blue};
    color: ${colors.blue};

    &:disabled {
      background-color: ${colors.white};
      border-color: ${colors.gray};
      color: ${colors.gray};

      &:hover {
        background-color: ${colors.white};
        color: ${colors.gray};

      }

      &.is-fetching {
        border-color: ${colors.blue};
      }

    }

    &:hover {
      background-color: ${colors.blue};
      color: ${colors.white};
    }

    &:active {
      background-color: ${colors.darkBlue};
      color: ${colors.white};
    }
  }

  &.btn-link {
    background-color: transparent;
    color: ${colors.blue};
    text-decoration: none;
    border-color: transparent;

    &:hover {
      background-color: ${colors.grayLightest};
    }

    &:active {
      background-color: ${colors.grayLight};
    }

    &:disabled {
      color: ${colors.gray};

      &.is-fetching {
        background-color: transparent;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }

  &.btn-icon {
    min-width: 0;
    padding: 0;
    text-transform: capitalize;

    &:hover {
      background-color: transparent;
      color: ${colors.darkBlue};

      & .icon-inner svg path {
        fill: ${colors.darkBlue};
        transition: fill .2s;
      }

    }

    &:disabled {
      & .icon-inner svg path {
        fill: ${colors.gray};
      }

      &:hover {
        color: ${colors.gray};
      }

    }

    & .icon-inner {
      align-items: center;
      display: flex;
    }

  }

  &.btn-hidden {
    display: none;
  }

  &.btn-inline {
    display: inline-block;
  }

  &.btn-danger {
    background-color: ${colors.red};
    border-color: ${colors.red};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.darkRed};
      border-color: ${colors.darkRed};
    }

    &:focus {
      box-shadow: 0 0 0 2px rgba(229, 57, 53, .5);
    }

    &:active {
      background-color: ${colors.red};
      border-color: ${colors.red};
    }

    &:disabled {
      &:hover {
        background-color: ${colors.red};
      }
    }

    & .button-loader {
      border-top-color: ${colors.white};
      border-right-color: ${colors.white};
      border-bottom-color: ${colors.white};
      border-left-color: transparent;
    }

  }

  & .button-loader {
    border-width: .5em;
    border-top-color: $white;
    border-right-color: $white;
    border-bottom-color: $white;
    border-left-color: $blue;
    height: 4em;
    margin: 0 auto;
    width: 4em;
  }

  & svg {
    width: 25px;
    height: 25px;
    transform: scale(0.5);

    & path {
      fill: ${colors.blue};
      fill-opacity: 1;
      transition: fill .2s;
    }
  }`;

export default s;
