import {
  DISPLAY_ERROR,
  DISPLAY_SUCCESS,
  ADD_NEW_TOKEN,
  RECEIVE_TOKENS,
  RECEIVE_OAUTH_URL,
  DELETE_OAUTH_URL,
} from '../actions/partnershipsMagentoActions';
import { DISMISS_ALERT } from '../actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  accessTokens: [],
  oauthUrls: {},
};

function partnershipsMagentoReducer(
  state = initialState,
  {
    payload: {
      text = '',
      accessToken = '',
      accessTokens = [],
      oauthUrls = {},
    } = {},
    type,
  } = {},
) {
  switch (type) {
    case DISPLAY_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };
    }
    case ADD_NEW_TOKEN: {
      return {
        ...state,
        accessTokens: [...state.accessTokens, accessToken],
      };
    }
    case RECEIVE_TOKENS: {
      return {
        ...state,
        accessTokens,
      };
    }
    case RECEIVE_OAUTH_URL: {
      return {
        ...state,
        oauthUrls,
      };
    }
    case DELETE_OAUTH_URL: {
      return {
        ...state,
        oauthUrls: {},
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    default: {
      return state;
    }
  }
}

export default partnershipsMagentoReducer;
