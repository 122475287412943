import { push } from 'connected-react-router';
import { API_INVALIDATE_PATH, API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import {
  hackyWayToReceiveUpsReadyData,
  hackyWayToSendUpsReadyData,
} from '~src/constants/carrierCredentials/temporaryHackyUpsReadyFormHandling';
import { changeSelectedCarrier, checkIfFormIsEditable } from './formActions';

export * from './formActions';
export const RECEIVE_TRACKING_CARRIER_CREDENTIALS_LIST =
  'RECEIVE_TRACKING_CARRIER_CREDENTIALS_LIST';
export const RECEIVE_TRACKING_CARRIER_CREDENTIALS =
  'RECEIVE_TRACKING_CARRIER_CREDENTIALS';
export const RECEIVE_CREDENTIALS_ERROR = 'RECEIVE_CREDENTIALS_ERROR';
export const RECEIVE_CREDENTIALS_SUCCESS = 'RECEIVE_CREDENTIALS_SUCCESS';
export const DISMISS_CREDENTIALS_ALERT = 'DISMISS_CREDENTIALS_ALERT';
export const TOGGLE_DELETE_CONFIRM_MODAL = 'TOGGLE_DELETE_CONFIRM_MODAL';
export const CLEAR_TRACK_CREDENTIALS_STATE = 'CLEAR_TRACK_CREDENTIALS_STATE';
export const TRACKING_TYPE_SELECTED = 'TRACKING_TYPE_SELECTED';

const UPDATE_CREDENTIALS_ERROR_ALERT_DEFAULT =
  'Carrier credentials failed testing and have not been saved. Please check you have entered all the information correctly.';

export const clearTrackCredentialsState = () => ({
  type: CLEAR_TRACK_CREDENTIALS_STATE,
});

export const receiveCredentialsError = errorText => ({
  type: RECEIVE_CREDENTIALS_ERROR,
  errorText,
});

export const receiveCredentialsSuccess = successText => ({
  type: RECEIVE_CREDENTIALS_SUCCESS,
  successText,
});

export const dismissCredentialsAlert = () => ({
  type: DISMISS_CREDENTIALS_ALERT,
});

export const invalidateTrackingCredentialsListPath = () => dispatch => {
  dispatch({
    type: API_INVALIDATE_PATH,
    path: ENDPOINTS.TRACKING_CARRIER_CREDENTIALS,
  });
};

export const receiveTrackingCarrierCredentialsList = payload => dispatch => {
  dispatch({
    type: RECEIVE_TRACKING_CARRIER_CREDENTIALS_LIST,
    payload,
  });
};

export const fetchTrackingCarrierCredentialsList = () => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.TRACKING_CARRIER_CREDENTIALS,
    name: 'fetchTrackingCarrierCredentialsList',
  }).then(json => {
    dispatch(receiveTrackingCarrierCredentialsList(json));
  });
};

const carrierIsSelected = carriers =>
  carriers.find(carrier => carrier.selected);

export const receiveTrackingCarrierCredentials = json => async dispatch => {
  const respBody = json.body;
  const selectedCarrier =
    carrierIsSelected(respBody.available_carriers) || null;

  await dispatch({
    type: RECEIVE_TRACKING_CARRIER_CREDENTIALS,
    formCredentials: hackyWayToReceiveUpsReadyData(respBody),
    availableCarriers: respBody.available_carriers,
    selectedCarrier,
    carrierSelector: respBody.carrier_selector || '',
    isNewForm: !respBody.id || false,
    lastModifiedDate: respBody.modified_date,
    createdDate: respBody.created_date,
  });

  if (selectedCarrier) {
    await dispatch(changeSelectedCarrier(selectedCarrier));
    await dispatch(checkIfFormIsEditable(selectedCarrier));
  }
};

export const fetchTrackingCarrierCredentials = id => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TRACKING_CARRIER_CREDENTIALS}/${id}`,
    name: 'fetchTrackingCarrierCredentials',
  }).then(json => {
    dispatch(receiveTrackingCarrierCredentials(json));
  });
};

export const saveTrackingCarrierCredentials = () => async (
  dispatch,
  getState,
) => {
  const {
    tenantReducer: {
      tenantViewDataReducer: {
        tenant: { retailer_name: retailerName },
      },
    },
    trackingCarrierCredentials: {
      formCredentials,
      trackingNumber,
      selectedType,
      carrierSelector,
      selectedCarrier: { carrier_moniker: carrier } = {},
    } = {},
  } = getState();

  try {
    const response = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.TRACKING_CARRIER_CREDENTIALS,
      body: JSON.stringify({
        api_credentials: formCredentials,
        carrier_moniker: carrier,
        tracking_number: trackingNumber,
        type: selectedType,
        carrier_selector: carrierSelector,
        ...hackyWayToSendUpsReadyData({ ...formCredentials, retailerName }),
      }),
      name: 'saveTrackingCarrierCredentials',
    });

    dispatch(clearTrackCredentialsState());
    dispatch(push(`/track/credentials/${response.id}`));
    dispatch(fetchTrackingCarrierCredentials(response.id));
    dispatch(
      receiveCredentialsSuccess('Credentials tested and saved successfully.'),
    );
  } catch (err) {
    const {
      errors: [
        { message, code = UPDATE_CREDENTIALS_ERROR_ALERT_DEFAULT } = {},
      ] = [],
    } = err;

    dispatch(receiveCredentialsError(message || code));
  }
};

export const updateTrackingCarrierCredentials = id => async (
  dispatch,
  getState,
) => {
  const path = `${ENDPOINTS.TRACKING_CARRIER_CREDENTIALS}/${id}`;
  const {
    trackingCarrierCredentials: {
      formCredentials,
      trackingNumber,
      selectedType,
      carrierSelector,
      selectedCarrier: { carrier_moniker: carrier } = {},
    } = {},
  } = getState();

  try {
    dispatch({
      type: API_INVALIDATE_PATH,
      path,
    });

    await dispatch({
      type: API_REQUEST,
      method: 'PUT',
      path,
      body: JSON.stringify({
        id,
        api_credentials: formCredentials,
        carrier_moniker: carrier,
        tracking_number: trackingNumber,
        type: selectedType,
        carrier_selector: carrierSelector,
      }),
      name: 'updateTrackingCarrierCredentials',
    });

    dispatch(fetchTrackingCarrierCredentials(id));
    dispatch(
      receiveCredentialsSuccess('Credentials tested and updated successfully.'),
    );
  } catch (err) {
    const {
      errors: [
        { message, code = UPDATE_CREDENTIALS_ERROR_ALERT_DEFAULT } = {},
      ] = [],
    } = err;

    dispatch(receiveCredentialsError(message || code));
  }
};

export const toggleDeleteConfirmModal = () => ({
  type: TOGGLE_DELETE_CONFIRM_MODAL,
});

export const deleteTrackingCarrierCredentials = id => async (
  dispatch,
  getState,
) => {
  const { trackingCarrierCredentials: { carrierSelector } = {} } = getState();

  try {
    await dispatch({
      type: API_REQUEST,
      method: 'DELETE',
      path: `${ENDPOINTS.TRACKING_CARRIER_CREDENTIALS}/${id}`,
      body: JSON.stringify({
        id,
        carrier_selector: carrierSelector,
      }),
      name: 'deleteTrackingCarrierCredentials',
    });

    await dispatch(invalidateTrackingCredentialsListPath());
    dispatch(toggleDeleteConfirmModal());
    await dispatch(clearTrackCredentialsState());
    dispatch(push('/track/credentials'));
    dispatch(receiveCredentialsSuccess('Credentials deleted successfully.'));
  } catch (err) {
    dispatch(toggleDeleteConfirmModal());
    dispatch(
      receiveCredentialsError(
        'Something went wrong. Credentials not able to be deleted.',
      ),
    );
  }
};

export const trackingTypeSelected = selectedType => ({
  type: TRACKING_TYPE_SELECTED,
  selectedType,
});
