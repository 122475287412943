import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { css } from 'emotion';
import Loader from '../Loader';
import Pagination from '../Pagination';
import Search from '../Search';
import TableBody from '../TableBody';
import TableHeader from '../TableHeader';
import ZeroState from '../ZeroState';
import { colors } from '@narvar/hum-constants';

const s = css`
  & .table-container {
    background-color: ${colors.white};
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
  }

  & .table-borders {
    border: 1px solid ${colors.lightPurple};
    box-shadow: 0 4px 11px -8px ${colors.darkGrey}, 0 0 4px 1px ${colors.lightBlue};
  }

  & .table-min-height {
    min-height: 400px;
  }

  & table {
    margin: 0 auto;
    max-width: none;
    width: 100%;
    table-layout: fixed;

    & tr td {
      padding: 0;
    }

    & tr:last-child {
      border: none;

      & div {
        border-bottom: none;
      }
    }
  }

  & .table-loader {
    position: absolute;
    left: 52%;
    top: 39%;
    margin-left: -40px;
    margin-top: 0;
  }

  .body {
    opacity: 1;
    transition: opacity 150ms;
    min-height: 400px;

    & .is-fetching {
      opacity: 0;
    }
  }

  .empty-state-container {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    svg {
      path {
        fill-opacity: 1;
      }
    }
  }
}`;

const isScrolledStyle = css`
  tr {
    position: relative;
  }

  .table-col-0 {
    background: white;
    box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.25);
    height: 41px;
    margin-top: 1px;
    position: absolute !important;
    width: 160px;
    z-index: 1;
  }

  .table-col-1 {
    padding-left: 162px;
  }

  & thead th.table-heading-col-0 {
    background: white;
    border-bottom: 2px solid ${colors.gray};
    border-radius: 3px 0 0 0;
    box-shadow: 5px 0 5px -2px rgba(0, 0, 0, 0.25);
    height: 43px;
    position: absolute !important;
    width: 160px;
    z-index: 1;
  }

  & .table-heading-col-1 {
    padding-left: 162px;
  }
`;

const isScrollableStyle = css`
  & .table-container {
    overflow-x: scroll;
  }

  & table {
    width: auto;
    overflow-x: scroll;

    & th,
    td {
      white-space: nowrap;
    }
  }
`;

export class Table extends PureComponent {
  constructor(props) {
    super(props);
    const { initialSearchValue } = props;
    this.state = {
      isActive: Boolean(initialSearchValue),
      isSearching: Boolean(initialSearchValue),
      isScrolled: false,
      value: initialSearchValue,
    };
  }

  handleChangeSearch = event => {
    const { value } = event.currentTarget;

    this.setState({
      value,
    });
  };

  handleClickSearch = () => {
    const { isActive, value } = this.state;

    if (isActive) {
      this.props.onSubmit({ search: value });
      this.setState({
        isSearching: true,
      });
    } else {
      this.setState({
        isActive: true,
      });
    }
  };

  handleKeyDown = event => {
    const { keyCode } = event;

    // Submit search
    if (keyCode === 13) {
      this.setState({
        isSearching: true,
      });
      this.props.onSubmit({ search: this.state.value });
    }

    // Escape and clear search
    if (keyCode === 27) {
      this.cancel();
    }

    // Search is empty and they have hit backspace
    // again
    if (keyCode === 8 && !this.state.value) {
      this.setState({
        isActive: false,
        isSearching: false,
      });

      this.props.onSubmit({ search: '' });
    }

    if (this.state.isSearching) {
      this.setState({
        isSearching: false,
      });
    }
  };

  cancel = () => {
    if (!this.state.value) {
      this.setState({
        isActive: false,
        isSearching: false,
      });
    } else {
      this.setState({
        isActive: false,
        isSearching: false,
        value: '',
      });
      this.props.onSubmit({ search: '' });
    }
  };

  handleClickOutside = () => {
    if (this.props.clearOnOutsideClick) {
      this.cancel();
    }
  };

  handleScroll = ({ target }) => {
    this.setState({
      isScrolled: target.scrollLeft > 0,
    });
  };

  render() {
    const {
      activePage,
      body,
      name,
      headings,
      htmlTableCells,
      isFetching,
      isScrollable,
      isTableSearch,
      onClickPagination,
      onRowClick,
      pagination,
      sortable,
      totalResults,
      totalPages,
      zeroStateProps,
      borders,
      minHeight,
      enableSearch,
      searchPlaceholder,
      children,
    } = this.props;
    const { isScrolled } = this.state;

    return (
      <div
        className={cx(
          s,
          { [isScrolledStyle]: isScrolled },
          { [isScrollableStyle]: isScrollable },
        )}
        onScroll={this.handleScroll}
      >
        {enableSearch && (
          <Search
            totalResults={totalResults}
            cancel={this.cancel}
            handleKeyDown={this.handleKeyDown}
            handleClick={this.handleClickSearch}
            handleChange={this.handleChangeSearch}
            isFetching={isFetching}
            isTableSearch={isTableSearch}
            placeholder={searchPlaceholder}
            {...this.state}
          />
        )}
        <div
          className={cx(
            s,
            'table-container',
            { 'table-min-height': minHeight },
            { 'table-borders': borders },
          )}
        >
          <table>
            {headings && (
              <TableHeader headings={headings} sortable={sortable} />
            )}
            {body && (
              <TableBody
                body={body}
                headings={headings}
                htmlTableCells={
                  htmlTableCells !== undefined ? htmlTableCells : undefined
                }
                isFetching={isFetching}
                name={name}
                onRowClick={onRowClick}
              />
            )}
            {children}
          </table>
          {body && body.length === 0 && !isFetching && (
            <ZeroState
              noResults={enableSearch && totalResults === 0}
              {...zeroStateProps}
            />
          )}
          <Loader show={isFetching || false} />
        </div>
        {pagination && (
          <Pagination
            activePage={activePage}
            onClick={onClickPagination}
            totalPages={totalPages}
          />
        )}
      </div>
    );
  }
}

Table.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onClickPagination: PropTypes.func,
  activePage: PropTypes.number,
  body: PropTypes.array.isRequired,
  headings: PropTypes.array.isRequired,
  htmlTableCells: PropTypes.array,
  zeroStateProps: PropTypes.shape({
    objectName: PropTypes.string,
    onClickAdd: PropTypes.func,
    message: PropTypes.string,
    subMessage: PropTypes.string,
    iconName: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func, // handler for search submit
  pagination: PropTypes.bool, // enable search
  totalPages: PropTypes.number,
  totalResults: PropTypes.number,
  minHeight: PropTypes.bool,
  borders: PropTypes.bool,
  enableSearch: PropTypes.bool,
  initialSearchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  clearOnOutsideClick: PropTypes.bool,
  onRowClick: PropTypes.func,
  sortable: PropTypes.bool,
  isScrollable: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.node,
};

Table.defaultProps = {
  minHeight: true,
  borders: true,
  isScrollable: false,
  sortable: false,
  enableSearch: false,
  initialSearchValue: '',
  clearOnOutsideClick: true,
  searchPlaceholder: undefined, // want to keep the default in Search component
};

export default onClickOutside(Table);
