export default [
  { id: 'US', country: 'United States' },
  { id: 'AF', country: 'Afghanistan' },
  { id: 'AX', country: 'Åland Islands' },
  { id: 'AL', country: 'Albania' },
  { id: 'DZ', country: 'Algeria' },
  { id: 'AS', country: 'American Samoa' },
  { id: 'AD', country: 'Andorra' },
  { id: 'AO', country: 'Angola' },
  { id: 'AI', country: 'Anguilla' },
  { id: 'AQ', country: 'Antarctica' },
  { id: 'AG', country: 'Antigua & Barbuda' },
  { id: 'AR', country: 'Argentina' },
  { id: 'AM', country: 'Armenia' },
  { id: 'AW', country: 'Aruba' },
  { id: 'AC', country: 'Ascension Island' },
  { id: 'AU', country: 'Australia' },
  { id: 'AT', country: 'Austria' },
  { id: 'AZ', country: 'Azerbaijan' },
  { id: 'BS', country: 'Bahamas' },
  { id: 'BH', country: 'Bahrain' },
  { id: 'BD', country: 'Bangladesh' },
  { id: 'BB', country: 'Barbados' },
  { id: 'BY', country: 'Belarus' },
  { id: 'BE', country: 'Belgium' },
  { id: 'BZ', country: 'Belize' },
  { id: 'BJ', country: 'Benin' },
  { id: 'BM', country: 'Bermuda' },
  { id: 'BT', country: 'Bhutan' },
  { id: 'BO', country: 'Bolivia' },
  { id: 'BA', country: 'Bosnia & Herzegovina' },
  { id: 'BW', country: 'Botswana' },
  { id: 'BR', country: 'Brazil' },
  { id: 'IO', country: 'British Indian Ocean Territory' },
  { id: 'VG', country: 'British Virgin Islands' },
  { id: 'BN', country: 'Brunei' },
  { id: 'BG', country: 'Bulgaria' },
  { id: 'BF', country: 'Burkina Faso' },
  { id: 'BI', country: 'Burundi' },
  { id: 'KH', country: 'Cambodia' },
  { id: 'CM', country: 'Cameroon' },
  { id: 'CA', country: 'Canada' },
  { id: 'IC', country: 'Canary Islands' },
  { id: 'CV', country: 'Cape Verde' },
  { id: 'BQ', country: 'Caribbean Netherlands' },
  { id: 'KY', country: 'Cayman Islands' },
  { id: 'CF', country: 'Central African Republic' },
  { id: 'EA', country: 'Ceuta & Melilla' },
  { id: 'TD', country: 'Chad' },
  { id: 'CL', country: 'Chile' },
  { id: 'CN', country: 'China' },
  { id: 'CX', country: 'Christmas Island' },
  { id: 'CC', country: 'Cocos (Keeling) Islands' },
  { id: 'CO', country: 'Colombia' },
  { id: 'KM', country: 'Comoros' },
  { id: 'CG', country: 'Congo - Brazzaville' },
  { id: 'CD', country: 'Congo - Kinshasa' },
  { id: 'CK', country: 'Cook Islands' },
  { id: 'CR', country: 'Costa Rica' },
  { id: 'CI', country: 'Côte d’Ivoire' },
  { id: 'HR', country: 'Croatia' },
  { id: 'CU', country: 'Cuba' },
  { id: 'CW', country: 'Curaçao' },
  { id: 'CY', country: 'Cyprus' },
  { id: 'CZ', country: 'Czech Republic' },
  { id: 'DK', country: 'Denmark' },
  { id: 'DG', country: 'Diego Garcia' },
  { id: 'DJ', country: 'Djibouti' },
  { id: 'DM', country: 'Dominica' },
  { id: 'DO', country: 'Dominican Republic' },
  { id: 'EC', country: 'Ecuador' },
  { id: 'EG', country: 'Egypt' },
  { id: 'SV', country: 'El Salvador' },
  { id: 'GQ', country: 'Equatorial Guinea' },
  { id: 'ER', country: 'Eritrea' },
  { id: 'EE', country: 'Estonia' },
  { id: 'ET', country: 'Ethiopia' },
  { id: 'FK', country: 'Falkland Islands' },
  { id: 'FO', country: 'Faroe Islands' },
  { id: 'FJ', country: 'Fiji' },
  { id: 'FI', country: 'Finland' },
  { id: 'FR', country: 'France' },
  { id: 'GF', country: 'French Guiana' },
  { id: 'PF', country: 'French Polynesia' },
  { id: 'TF', country: 'French Southern Territories' },
  { id: 'GA', country: 'Gabon' },
  { id: 'GM', country: 'Gambia' },
  { id: 'GE', country: 'Georgia' },
  { id: 'DE', country: 'Germany' },
  { id: 'GH', country: 'Ghana' },
  { id: 'GI', country: 'Gibraltar' },
  { id: 'GR', country: 'Greece' },
  { id: 'GL', country: 'Greenland' },
  { id: 'GD', country: 'Grenada' },
  { id: 'GP', country: 'Guadeloupe' },
  { id: 'GU', country: 'Guam' },
  { id: 'GT', country: 'Guatemala' },
  { id: 'GG', country: 'Guernsey' },
  { id: 'GN', country: 'Guinea' },
  { id: 'GW', country: 'Guinea-Bissau' },
  { id: 'GY', country: 'Guyana' },
  { id: 'HT', country: 'Haiti' },
  { id: 'HN', country: 'Honduras' },
  { id: 'HK', country: 'Hong Kong SAR China' },
  { id: 'HU', country: 'Hungary' },
  { id: 'IS', country: 'Iceland' },
  { id: 'IN', country: 'India' },
  { id: 'ID', country: 'Indonesia' },
  { id: 'IR', country: 'Iran' },
  { id: 'IQ', country: 'Iraq' },
  { id: 'IE', country: 'Ireland' },
  { id: 'IM', country: 'Isle of Man' },
  { id: 'IL', country: 'Israel' },
  { id: 'IT', country: 'Italy' },
  { id: 'JM', country: 'Jamaica' },
  { id: 'JP', country: 'Japan' },
  { id: 'JE', country: 'Jersey' },
  { id: 'JO', country: 'Jordan' },
  { id: 'KZ', country: 'Kazakhstan' },
  { id: 'KE', country: 'Kenya' },
  { id: 'KI', country: 'Kiribati' },
  { id: 'XK', country: 'Kosovo' },
  { id: 'KW', country: 'Kuwait' },
  { id: 'KG', country: 'Kyrgyzstan' },
  { id: 'LA', country: 'Laos' },
  { id: 'LV', country: 'Latvia' },
  { id: 'LB', country: 'Lebanon' },
  { id: 'LS', country: 'Lesotho' },
  { id: 'LR', country: 'Liberia' },
  { id: 'LY', country: 'Libya' },
  { id: 'LI', country: 'Liechtenstein' },
  { id: 'LT', country: 'Lithuania' },
  { id: 'LU', country: 'Luxembourg' },
  { id: 'MO', country: 'Macau SAR China' },
  { id: 'MK', country: 'Macedonia' },
  { id: 'MG', country: 'Madagascar' },
  { id: 'MW', country: 'Malawi' },
  { id: 'MY', country: 'Malaysia' },
  { id: 'MV', country: 'Maldives' },
  { id: 'ML', country: 'Mali' },
  { id: 'MT', country: 'Malta' },
  { id: 'MH', country: 'Marshall Islands' },
  { id: 'MQ', country: 'Martinique' },
  { id: 'MR', country: 'Mauritania' },
  { id: 'MU', country: 'Mauritius' },
  { id: 'YT', country: 'Mayotte' },
  { id: 'MX', country: 'Mexico' },
  { id: 'FM', country: 'Micronesia' },
  { id: 'MD', country: 'Moldova' },
  { id: 'MC', country: 'Monaco' },
  { id: 'MN', country: 'Mongolia' },
  { id: 'ME', country: 'Montenegro' },
  { id: 'MS', country: 'Montserrat' },
  { id: 'MA', country: 'Morocco' },
  { id: 'MZ', country: 'Mozambique' },
  { id: 'MM', country: 'Myanmar (Burma)' },
  { id: 'NA', country: 'Namibia' },
  { id: 'NR', country: 'Nauru' },
  { id: 'NP', country: 'Nepal' },
  { id: 'NL', country: 'Netherlands' },
  { id: 'NC', country: 'New Caledonia' },
  { id: 'NZ', country: 'New Zealand' },
  { id: 'NI', country: 'Nicaragua' },
  { id: 'NE', country: 'Niger' },
  { id: 'NG', country: 'Nigeria' },
  { id: 'NU', country: 'Niue' },
  { id: 'NF', country: 'Norfolk Island' },
  { id: 'KP', country: 'North Korea' },
  { id: 'MP', country: 'Northern Mariana Islands' },
  { id: 'NO', country: 'Norway' },
  { id: 'OM', country: 'Oman' },
  { id: 'PK', country: 'Pakistan' },
  { id: 'PW', country: 'Palau' },
  { id: 'PS', country: 'Palestinian Territories' },
  { id: 'PA', country: 'Panama' },
  { id: 'PG', country: 'Papua New Guinea' },
  { id: 'PY', country: 'Paraguay' },
  { id: 'PE', country: 'Peru' },
  { id: 'PH', country: 'Philippines' },
  { id: 'PN', country: 'Pitcairn Islands' },
  { id: 'PL', country: 'Poland' },
  { id: 'PT', country: 'Portugal' },
  { id: 'PR', country: 'Puerto Rico' },
  { id: 'QA', country: 'Qatar' },
  { id: 'RE', country: 'Réunion' },
  { id: 'RO', country: 'Romania' },
  { id: 'RU', country: 'Russia' },
  { id: 'RW', country: 'Rwanda' },
  { id: 'WS', country: 'Samoa' },
  { id: 'SM', country: 'San Marino' },
  { id: 'ST', country: 'São Tomé & Príncipe' },
  { id: 'SA', country: 'Saudi Arabia' },
  { id: 'SN', country: 'Senegal' },
  { id: 'RS', country: 'Serbia' },
  { id: 'SC', country: 'Seychelles' },
  { id: 'SL', country: 'Sierra Leone' },
  { id: 'SG', country: 'Singapore' },
  { id: 'SX', country: 'Sint Maarten' },
  { id: 'SK', country: 'Slovakia' },
  { id: 'SI', country: 'Slovenia' },
  { id: 'SB', country: 'Solomon Islands' },
  { id: 'SO', country: 'Somalia' },
  { id: 'ZA', country: 'South Africa' },
  { id: 'GS', country: 'South Georgia & South Sandwich Islands' },
  { id: 'KR', country: 'South Korea' },
  { id: 'SS', country: 'South Sudan' },
  { id: 'ES', country: 'Spain' },
  { id: 'LK', country: 'Sri Lanka' },
  { id: 'BL', country: 'St. Barthélemy' },
  { id: 'SH', country: 'St. Helena' },
  { id: 'KN', country: 'St. Kitts & Nevis' },
  { id: 'LC', country: 'St. Lucia' },
  { id: 'MF', country: 'St. Martin' },
  { id: 'PM', country: 'St. Pierre & Miquelon' },
  { id: 'VC', country: 'St. Vincent & Grenadines' },
  { id: 'SD', country: 'Sudan' },
  { id: 'SR', country: 'Suriname' },
  { id: 'SJ', country: 'Svalbard & Jan Mayen' },
  { id: 'SZ', country: 'Swaziland' },
  { id: 'SE', country: 'Sweden' },
  { id: 'CH', country: 'Switzerland' },
  { id: 'SY', country: 'Syria' },
  { id: 'TW', country: 'Taiwan' },
  { id: 'TJ', country: 'Tajikistan' },
  { id: 'TZ', country: 'Tanzania' },
  { id: 'TH', country: 'Thailand' },
  { id: 'TL', country: 'Timor-Leste' },
  { id: 'TG', country: 'Togo' },
  { id: 'TK', country: 'Tokelau' },
  { id: 'TO', country: 'Tonga' },
  { id: 'TT', country: 'Trinidad & Tobago' },
  { id: 'TA', country: 'Tristan da Cunha' },
  { id: 'TN', country: 'Tunisia' },
  { id: 'TR', country: 'Turkey' },
  { id: 'TM', country: 'Turkmenistan' },
  { id: 'TC', country: 'Turks & Caicos Islands' },
  { id: 'TV', country: 'Tuvalu' },
  { id: 'UM', country: 'U.S. Outlying Islands' },
  { id: 'VI', country: 'U.S. Virgin Islands' },
  { id: 'UG', country: 'Uganda' },
  { id: 'UA', country: 'Ukraine' },
  { id: 'AE', country: 'United Arab Emirates' },
  { id: 'GB', country: 'United Kingdom' },
  { id: 'UY', country: 'Uruguay' },
  { id: 'UZ', country: 'Uzbekistan' },
  { id: 'VU', country: 'Vanuatu' },
  { id: 'VA', country: 'Vatican City' },
  { id: 'VE', country: 'Venezuela' },
  { id: 'VN', country: 'Vietnam' },
  { id: 'WF', country: 'Wallis & Futuna' },
  { id: 'EH', country: 'Western Sahara' },
  { id: 'YE', country: 'Yemen' },
  { id: 'ZM', country: 'Zambia' },
  { id: 'ZW', country: 'Zimbabwe' },
];
