import {
  RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
  RESET_UPLOADED_PORTABLE_CONFIG,
  RECEIVE_RETURNS_RULES,
  RECEIVE_RETURNS_RULES_DEFINITIONS,
  RECEIVE_RETURNS_RULES_METADATA,
  RECEIVE_RETURNS_RULES_ERROR,
  RECEIVE_RETURNS_RULES_SUCCESS,
  RECEIVE_RETURNS_RULES_PUBLISH,
  SAVE_RULE,
  RECEIVE_MODES,
  RECEIVE_DCS,
  MULTI_CARRIER_MIGRATION,
  PUBLISHING_STATE,
  API_ERRORS,
  PRELOAD_RETURN_RULES,
} from '../actions/returnsRulesActions';
import { RECEIVE_CSM_LOGOUT } from '../actions/userActions';
import { DISMISS_ALERT } from '../actions/uiActions';
import i18n from '../i18n';

const initialState = {
  alert: {
    show: false,
  },
  definitions: {},
  isFetching: false,
  rules: {},
  uploadedPortableConfig: null,
  showUploadConfirmationModal: false,
  modifiedBy: '',
  modifiedDate: '',
  policyData: {},
  modes: {
    destinationMode: null,
    returnMethodsMode: null,
    feesMode: null,
  },
  isPublishing: false,
  fetchComplete: {
    rules: false,
    definitions: false,
  },
  apiErrors: {},
};

function returnsRulesReducer(state = initialState, action) {
  switch (action.type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE: {
      return {
        ...state,
        uploadedPortableConfig: action.payload,
        showUploadConfirmationModal: true,
      };
    }

    case RESET_UPLOADED_PORTABLE_CONFIG:
      return {
        ...state,
        uploadedPortableConfig: initialState.uploadedPortableConfig,
        showUploadConfirmationModal: initialState.showUploadConfirmationModal,
      };

    case RECEIVE_RETURNS_RULES:
      return {
        ...state,
        isFetching: false,
        rules: {
          ...state.rules,
          ...action.rules,
        },
        policies: {
          ...action.policies,
        },
        dcs: action.dcs,
        fetchComplete: {
          ...state.fetchComplete,
          rules: true,
        },
      };

    case RECEIVE_RETURNS_RULES_DEFINITIONS:
      return {
        ...state,
        definitions: {
          ...state.definitions,
          ...action.definitions,
        },
        fetchComplete: {
          ...state.fetchComplete,
          definitions: true,
        },
      };

    case RECEIVE_MODES:
      return {
        ...state,
        modes: action.modes,
      };

    case RECEIVE_DCS:
      return {
        ...state,
        dcs: action.dcs,
        fetchComplete: {
          ...state.fetchComplete,
          dcs: true,
        },
      };

    case RECEIVE_RETURNS_RULES_METADATA:
      return {
        ...state,
        modifiedBy: action.modifiedBy,
        modifiedDate: action.modifiedDate,
      };

    case RECEIVE_RETURNS_RULES_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case SAVE_RULE:
      return {
        ...state,
        alert: {
          color: 'info',
          show: true,
          text: i18n.t('returnRules:publish_to_save'),
        },
      };

    case RECEIVE_RETURNS_RULES_PUBLISH:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: i18n.t('returnRules:change_published'),
        },
      };

    case RECEIVE_RETURNS_RULES_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: action.messages,
        },
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    case MULTI_CARRIER_MIGRATION:
      return {
        ...state,
        rules: {
          ...state.rules,
          return_methods: action.payload,
        },
        methodMigrationComplete: true,
      };

    case PUBLISHING_STATE:
      return {
        ...state,
        isPublishing: action.isPublishing,
      };

    case API_ERRORS:
      return {
        ...state,
        apiErrors: {
          ...state.apiErrors,
          ...action.error,
        },
      };

    case PRELOAD_RETURN_RULES:
      return {
        ...state,
        preload: {
          ...action.payload,
          preloadComplete: false,
        },
      };

    default:
      return state;
  }
}

export default returnsRulesReducer;
