import { buildQueryString } from '~src/lib/helpers';
import request from './request';
import getCachedResponse from './getCachedResponse';
import {
  INVALIDATE,
  INVALIDATE_PATH,
  REQUEST,
  API_INVALIDATE,
  API_INVALIDATE_PATH,
  API_REQUEST,
} from './constants';

const isValidApiAction = type =>
  [API_REQUEST, API_INVALIDATE, API_INVALIDATE_PATH].includes(type);

export default ({ dispatch, getState }) => next => async action => {
  const {
    body,
    contentType,
    method,
    name,
    query,
    type,
    path,
    reqBodyRedactor,
    logger = () => {},
  } = action;

  if (!isValidApiAction(type)) {
    return next(action);
  }

  const { apiReducer: apiState, userReducer: { user } = {} } = getState();

  const queryString = buildQueryString(query);
  const fullPath = path + queryString;

  const logData = {
    fullPath,
    initiatingUrl: document.location.href,
  };

  if (type === API_REQUEST) {
    // Return cached response if the path + query string
    // matches a key within the api state tree and its NOT a POST
    // or a PUT and the cached method matches the requested method
    const cachedResponse = getCachedResponse({ action, queryString, apiState });
    if (cachedResponse) {
      if (__DEV__) {
        console.info('CACHED RESPONSE:', fullPath); // eslint-disable-line no-console
      }
      logger({
        ...logData,
        cached: true,
      });
      // Return the promise so it behaves as the original request did
      return Promise.resolve(cachedResponse.json);
    }

    next({
      body,
      contentType,
      method,
      name,
      path,
      queryString,
      reqBodyRedactor,
      type: REQUEST,
    });

    const startTime = window.performance.now();
    let error;
    let json;

    try {
      // Fetch
      json = await request(
        path,
        {
          body,
          method,
        },
        contentType,
        name,
        queryString,
        user,
        dispatch,
        reqBodyRedactor,
      );
    } catch (err) {
      error = err;
    }

    const completeLogData = {
      ...logData,
      responseTime: window.performance.now() - startTime,
      fullPath: path + queryString,
      cached: false,
    };
    if (error) {
      completeLogData.error = error;
    }
    logger(completeLogData);

    if (error) throw error;
    return json;
  } else if (type === API_INVALIDATE) {
    if (window.stop && typeof window.stop === 'function') {
      // not in IE
      /*
      stops inflight requests (such as to analytics api) that may still be pending when someone
      switches tenants; otherwise these requests can cause erroneous errors to be displayed:
      */
      window.stop();
    }

    return next({
      type: INVALIDATE,
    });
  } else if (type === API_INVALIDATE_PATH) {
    return next({
      type: INVALIDATE_PATH,
      path,
    });
  }
  return next(action);
};
