export const configSchema = {
  dataCallback: 'function',
  snowplowSchema: 'string',
  eventsWithFields: 'object',
  staticProps: 'object',
  trackerName: 'string',
  snowplowName: 'string',
  segmentName: 'string',
};

export default ({ config, snowplow }) => {
  Object.entries(config).forEach(([key, val]) => {
    const type = typeof val;
    const expectedType = configSchema[key];
    if (val && type !== expectedType) {
      throw new Error(`expected config.${key} to be type ${expectedType}; instead received ${type}`);
    }
  });
  const {
    snowplowSchema,
    eventsWithFields,
    staticProps,
  } = config;
  if (snowplow) {
    if (!snowplowSchema) {
      throw new Error('must provide snowplowSchema in config');
    }
    if (!eventsWithFields) {
      throw new Error('must provide eventsWithFields in config');
    }
    if (!staticProps) {
      throw new Error('must provide staticProps in config');
    }
  }
};
