import * as ENDPOINTS from '~src/constants/endpoints';
import { API_REQUEST } from '~src/lib/api';

export const RECEIVE_ERROR = 'CARE/RECEIVE_ERROR';
export const RECEIVE_SUCCESS = 'CARE/RECEIVE_SUCCESS';
export const DISMISS_ALERT = 'DISMISS_ALERT';

export const fetchInvalidateAssistCache = uriMoniker => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'DELETE',
    path: `${ENDPOINTS.CARE_INVALIDATE_CACHE}/${uriMoniker}`,
    name: 'fetchInvalidateAssistCache',
  })
    .then(() => dispatch({ type: RECEIVE_SUCCESS }))
    .catch(err =>
      dispatch({
        type: RECEIVE_ERROR,
        error: err.errorMessage,
      }),
    );
};

export const dismissAlert = () => ({
  type: DISMISS_ALERT,
});
