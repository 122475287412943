import {
  RECEIVE_CONTENT,
  RECEIVE_ERROR,
  RECEIVE_UPDATE_CONTENT,
  RECEIVE_CONTENT_BY_ID,
} from '~src/actions/assist/content';
import {
  REQUEST_UPLOAD_ASSET_IMAGE,
  RECEIVE_UPDATE_ASSET_IMAGE,
} from '~src/actions/assistSettingsActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  categories: [],
  content: [],
  contentDetails: null,
  isFetchingUploadAssetImage: false,
  uploadedImage: {},
};

const assistContentReducer = (state = initialState, action) => {
  const { error, meta, payload, type } = action;

  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          show: true,
          color: 'danger',
          text: error.errorMessage,
        },
      };

    case REQUEST_UPLOAD_ASSET_IMAGE:
      return {
        ...state,
        isFetchingUploadAssetImage: true,
      };

    case RECEIVE_UPDATE_CONTENT:
      return {
        ...state,
        alert: {
          show: true,
          color: 'success',
          text: 'Your content was updated successfully.',
        },
        content: payload.content,
      };

    case RECEIVE_UPDATE_ASSET_IMAGE:
      return {
        ...state,
        isFetchingUploadAssetImage: false,
        uploadedImage: {
          url: payload,
          name: meta.key,
        },
      };
    case RECEIVE_CONTENT:
      return {
        ...state,
        categories: payload.categories,
        content: payload.content,
      };

    case RECEIVE_CONTENT_BY_ID:
      return {
        ...state,
        contentDetails: payload,
      };

    default:
      return state;
  }
};

export default assistContentReducer;
