import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import noflake from '~src/lib/noflake';

// Context is expected to be
//
// {
//  noflakeUrl: '...',
// }
const NoflakeContext = createContext(undefined);

export const NoflakeProvider = NoflakeContext.Provider;

const useNoflake = () => {
  const context = useContext(NoflakeContext);
  if (context === undefined) {
    throw new Error('useNoflake must be used within a NoflakeProvider');
  }

  const { noflakeUrl } = context;
  const logEvent = noflake(noflakeUrl);

  const logEventRef = useRef(logEvent);

  useEffect(() => {
    logEventRef.current = logEvent;
  }, [logEventRef, logEvent]);

  return useCallback((...args) => logEventRef.current(...args), [logEventRef]);
};

export default useNoflake;
