import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import numeral from 'numeral';
import s from './TableMeta.styles';

export class TableMeta extends PureComponent {
  render() {
    const {
      isFetching,
      totalRecordsWithFilters,
      totalRecords,
      i18n: {
        general: { showing = 'Showing', of = 'of' },
      },
    } = this.props;
    return (
      <div className={cx(s, isFetching ? 'is-fetching' : null)}>
        {typeof totalRecords === 'number' && (
          <span>
            {showing}{' '}
            {typeof totalRecordsWithFilters === 'number' && (
              <span>
                <strong>
                  {' '}
                  {numeral(totalRecordsWithFilters).format('0,0')}{' '}
                </strong>{' '}
                {of}{' '}
              </span>
            )}
            <strong> {numeral(totalRecords).format('0,0')} </strong>
          </span>
        )}
      </div>
    );
  }
}

TableMeta.propTypes = {
  isFetching: PropTypes.bool,
  totalRecordsWithFilters: PropTypes.number,
  totalRecords: PropTypes.number,
  i18n: PropTypes.shape({
    general: PropTypes.shape({
      showing: PropTypes.string,
      of: PropTypes.string,
    }),
  }),
};

TableMeta.defaultProps = {
  isFetching: false,
  totalRecordsWithFilters: undefined,
  totalRecords: undefined,
  i18n: {
    general: {
      showing: 'Showing',
      of: 'of',
    },
  },
};

export default TableMeta;
