import objectPath from 'object-path';
import { returnMethodDefNestedTemplate } from '../definition';

export const multiCarrierActionMigration = ({
  returnMethods,
  steppedItems = [],
}) =>
  returnMethods.map(method => {
    if (method.actions.length === 1) return method;
    const returnMethodAction = method.actions.find(
      ({ category }) => category === 'return_method',
    );
    const returnDcAction = method.actions.find(
      ({ category }) => category === 'return_dc',
    );
    if (
      !returnMethodAction ||
      !returnDcAction ||
      !Array.isArray(returnMethodAction.value)
    ) {
      return method;
    }

    return {
      ...method,
      actions: [
        {
          ...returnMethodAction,
          category: 'return_methods_with_carrier',
          value: returnMethodAction.value.map(action => ({
            ...action,
            carriers: steppedItems.includes(action.picklist_token)
              ? returnDcAction.value
              : [],
          })),
        },
      ],
    };
  });

export const multiCarrierDefinitionMigration = ({ returnMethods }) => {
  const [returnMethod, returnDc] = returnMethods.actions;
  objectPath.set(returnMethodDefNestedTemplate, 'values', [
    returnMethod.values,
    returnDc.values,
  ]);
  return returnMethodDefNestedTemplate;
};
