import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './DualActionModal.scss';
import withStyles from '~src/lib/isomorphic-style-loader/withStyles';
import { ModalFooter, ModalBody } from 'reactstrap';
import { Button, Modal } from '@narvar/hum';

export const DualActionModal = ({
  title,
  mainText,
  subText,
  primaryActionText,
  primaryActionColor,
  secondaryActionText,
  secondaryActionColor,
  close,
  primaryAction,
  secondaryAction,
  showModal,
  children,
}) => (
  <Modal
    isOpen={showModal}
    toggle={close}
    className={s['DualActionModal']}
    title={title}
  >
    <ModalBody>
      <p>{mainText}</p>
      <p className={'strong'}>{subText}</p>
      {children}
    </ModalBody>
    <ModalFooter>
      <Button color={secondaryActionColor} onClick={secondaryAction}>
        {secondaryActionText}
      </Button>
      <Button color={primaryActionColor} onClick={primaryAction}>
        {primaryActionText}
      </Button>
    </ModalFooter>
  </Modal>
);

DualActionModal.defaultProps = {
  title: '',
  mainText: '',
  subText: '',
  children: null,
};

DualActionModal.propTypes = {
  title: PropTypes.string,
  mainText: PropTypes.string,
  subText: PropTypes.string,
  showModal: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  primaryActionText: PropTypes.string.isRequired,
  primaryActionColor: PropTypes.string.isRequired,
  primaryAction: PropTypes.func.isRequired,
  secondaryActionText: PropTypes.string.isRequired,
  secondaryActionColor: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  children: PropTypes.node,
};

DualActionModal.displayName = 'DualActionModal';

export default withStyles(s)(DualActionModal);
