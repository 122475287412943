import {
  RECEIVE_PACKAGES,
  REQUEST_PACKAGES,
  RECEIVE_ORDER,
  ERROR,
} from '~src/actions/packagesActions';
import { LOCATION_CHANGE } from 'connected-react-router';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    color: 'info',
    show: false,
    text: '',
  },
  isFetchingPackagesList: false,
  items: [],
  totalRecords: 0,
  totalRecordsWithFilters: 0,
  orderDetails: {},
};

function packages(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case LOCATION_CHANGE:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    case REQUEST_PACKAGES:
      return {
        ...state,
        isFetchingPackagesList: true,
      };

    case RECEIVE_PACKAGES:
      return {
        ...state,
        items: payload.items,
        totalRecords: payload.totalRecords,
        totalRecordsWithFilters: payload.totalRecordsWithFilters,
        isFetchingPackagesList: false,
      };

    case RECEIVE_ORDER:
      return {
        ...state,
        orderDetails: payload.orderDetails,
      };

    case ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload.message,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    default:
      return state;
  }
}

export default packages;
