import {
  DISPLAY_ERROR,
  DISPLAY_SUCCESS,
  ADD_NEW_TOKEN,
  RECEIVE_TOKENS,
} from '../actions/partnershipsSfccActions';
import { DISMISS_ALERT } from '../actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  accessTokens: [],
};

function partnershipsSfccReducer(
  state = initialState,
  {
    payload: { text = '', accessToken = '', accessTokens = [] } = {},
    type,
  } = {},
) {
  switch (type) {
    case DISPLAY_ERROR: {
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text,
        },
      };
    }
    case DISPLAY_SUCCESS: {
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text,
        },
      };
    }
    case ADD_NEW_TOKEN: {
      return {
        ...state,
        accessTokens: [...state.accessTokens, accessToken],
      };
    }
    case RECEIVE_TOKENS: {
      return {
        ...state,
        accessTokens,
      };
    }
    case DISMISS_ALERT: {
      return {
        ...state,
        alert: initialState.alert,
      };
    }
    default: {
      return state;
    }
  }
}

export default partnershipsSfccReducer;
