import gql from 'graphql-tag';
import FRAGMENT_TIMESTAMP_FIELDS from '../fragments/timestampFields';

export const UPDATE_OVERRIDE_CODES = gql`
  mutation UpdateOverrideCodes($enabled: [String]!) {
    updateReturns {
      overrideCodes(enabled: $enabled) {
        enabled
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const UPDATE_CARRIERS = gql`
  mutation UpdateCarriers($enabled: [String]!) {
    updateReturns {
      carriers(enabled: $enabled) {
        enabled
        options {
          key
          displayName
        }
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const UPDATE_PRODUCT_CATEGORIES = gql`
  mutation UpdateProductCategories($enabled: [ProductCategoryInput]!) {
    updateReturns {
      productCategories(enabled: $enabled) {
        enabled {
          id
          category
        }
      }
    }
  }
`;

export const UPDATE_LOCALES = gql`
  mutation UpdateLocales(
    $enabled: [String!]
    $default: String!
    $exchanges: [String]!
  ) {
    updateReturns {
      locales(enabled: $enabled, default: $default, exchanges: $exchanges) {
        enabled
        default
        exchanges
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const UPDATE_HOME_PICKUPS = gql`
  mutation UpdateHomePickups($payload: PickupProgramsInput) {
    updatePickups {
      programs(payload: $payload) {
        pickupRetailerPrograms {
          active
          id
          pickupProgramId
          retailerMoniker
        }
      }
    }
  }
`;

export const UPDATE_LOCALES_WITH_EXCHANGES = gql`
  mutation UpdateLocalesWithExchanges(
    $enabled: [String!]
    $default: String!
    $exchanges: [String]!
    $shopify: Boolean!
    $completeDraft: ExchangeTriggers
  ) {
    updateReturns {
      locales(enabled: $enabled, default: $default, exchanges: $exchanges) {
        enabled
        default
        exchanges
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      exchangeSettings(shopify: $shopify, completeDraft: $completeDraft) {
        shopify {
          enabled
          options {
            label
            value
          }
        }
        completeDraft {
          enabled
          options {
            label
            value
          }
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const MUTATE_PRODUCT_ATTRIBUTES = gql`
  mutation updateProductAttributeSettings(
    $propertyType: [String]!
    $locale: String!
    $enabled: Boolean!
  ) {
    updateReturns {
      productAttributeSettings(
        propertyType: $propertyType
        locale: $locale
        enabled: $enabled
      ) {
        enabled
        displayPropertyTypeList {
          locale
          propertyType
        }
      }
    }
  }
`;

export const PUBLISH_UI_BUILDER_SETTINGS_ONLY = () => gql`
  mutation PublishUIBuilderSettingsOnly(
    $locale: String!
    $languageJson: JSON!
    $settingsJson: JSON!
    $navigationMenu: NavigationMenuInput
  ) {
    updateReturns {
      returnSettingsBlobUpdate(
        locale: $locale
        languageJson: $languageJson
        settingsJson: $settingsJson
        navigationMenu: $navigationMenu
      ) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const PUBLISH_UI_BUILDER_SETTINGS_AND_PRODUCT_ATTRS = () => gql`
  mutation PublishUIBuilderSettingsAndProductAttrs(
    $locale: String!
    $productAttrEnabled: Boolean!
    $propertyType: [String]!
    $languageJson: JSON!
    $settingsJson: JSON!
    $navigationMenu: NavigationMenuInput
  ) {
    updateReturns {
      returnSettingsBlobUpdate(
        locale: $locale
        languageJson: $languageJson
        settingsJson: $settingsJson
        navigationMenu: $navigationMenu
      ) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
      productAttributeSettings(
        locale: $locale
        enabled: $productAttrEnabled
        propertyType: $propertyType
      ) {
        enabled
        displayPropertyTypeList {
          propertyType
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const PUBLISH_FILES_SETTINGS = gql`
  mutation PublishFilesSettings(
    $locale: String!
    $languageJson: JSON!
    $settingsJson: JSON!
  ) {
    updateReturns {
      returnSettingsBlobUpdate(
        locale: $locale
        languageJson: $languageJson
        settingsJson: $settingsJson
      ) {
        languageJson
        settingsJson
        created {
          ...TimestampFields
        }
        modified {
          ...TimestampFields
        }
      }
    }
  }
  ${FRAGMENT_TIMESTAMP_FIELDS}
`;

export const DISTRIBUTION_CENTERS_CREATE = gql`
  mutation CreateDistributionCenter(
    $distributionCenter: ReturnsDistributionCenterInput!
  ) {
    updateReturns {
      distributionCenterCreate(distributionCenter: $distributionCenter) {
        dcID
        locale
      }
    }
  }
`;

export const GET_PREVIEW = () => gql`
  mutation GetPreview(
    $locale: String!
    $languageJson: JSON!
    $settingsJson: JSON!
    $navigationMenu: NavigationMenuInput
  ) {
    updateReturns {
      generatePreview(
        locale: $locale
        languageJson: $languageJson
        settingsJson: $settingsJson
        navigationMenu: $navigationMenu
      ) {
        version
        message
        isTestOrderEligible
      }
    }
  }
`;

export const GET_BRANDING_PREVIEW = () => gql`
  mutation GetPreview(
    $locale: String!
    $brandingConfig: BrandingConfigInput
    $navigationMenu: NavigationMenuInput
  ) {
    generateBrandingPreview(
      locale: $locale
      brandingConfig: $brandingConfig
      navigationMenu: $navigationMenu
    ) {
      version
      message
      isTestOrderEligible
    }
  }
`;
export const PUBLISH_BRANDING_SETTINGS = () => gql`
  mutation PublishBranding(
    $locale: String!
    $navigationMenu: NavigationMenuInput
    $brandingConfig: BrandingConfigInput
  ) {
    publishBranding(
      locale: $locale
      navigationMenu: $navigationMenu
      brandingConfig: $brandingConfig
    ) {
      headerStyle
      backgroundImage {
        desktop
        mobile
      }
    }
  }
`;
