exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* App z-index tracking */\n._3d71Q1bsuE9FAlFfunAmSq .jNcDAXOcCTi9e4dLq92Ss {\n  align-items: center; }\n\n._3d71Q1bsuE9FAlFfunAmSq ._1FuHQRO04PpQ3sX6ovL29V {\n  margin-bottom: 20px; }\n\n._3d71Q1bsuE9FAlFfunAmSq ._1yHM0YmsHRfWKnPtVbiLXp {\n  position: fixed;\n  width: 350px;\n  max-height: calc(100vh - 200px);\n  overflow: auto;\n  z-index: 1; }\n  ._3d71Q1bsuE9FAlFfunAmSq ._1yHM0YmsHRfWKnPtVbiLXp ._2EISr4cN4dq8FMQe3zsGhR span {\n    font-family: \"circular\", \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    margin-bottom: 10px; }\n", ""]);

// exports
exports.locals = {
	"DetailsView": "_3d71Q1bsuE9FAlFfunAmSq",
	"rightActions": "jNcDAXOcCTi9e4dLq92Ss",
	"alert-card": "_1FuHQRO04PpQ3sX6ovL29V",
	"details-card": "_1yHM0YmsHRfWKnPtVbiLXp",
	"details-card-text": "_2EISr4cN4dq8FMQe3zsGhR"
};