import {
  RECEIVE_ENTITLEMENT_PRODUCTS,
  RECEIVE_ENTITLEMENT_PRODUCTS_ERROR,
} from '../actions/entitlementAction';

const initialState = {
  products: [],
  error: '',
};

const entitlements = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_ENTITLEMENT_PRODUCTS:
      return {
        ...state,
        products: payload.products,
      };

    case RECEIVE_ENTITLEMENT_PRODUCTS_ERROR:
      return {
        ...state,
        error: payload.error,
      };

    default:
      return state;
  }
};

export default entitlements;
