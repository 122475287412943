import moment from 'moment';
import * as ENDPOINTS from '../constants/endpoints';
import { TRACKING } from '../constants/reports';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import {
  DISMISS_ALERT,
  RECEIVE_TRACKING_ANALYTICS_REPORT,
  RECEIVE_ERRORS,
  RECEIVE_NEW_SMS_ENABLED,
  SELECT_DATE_RANGE,
  SELECT_LOCALE,
  SELECT_TRACKING_TYPE,
  SELECT_SEGMENT_IDS,
  RECEIVE_TRACK_SEGMENTS,
  OUTBOUND,
  RECEIVE_CUSTOM_COMPONENTS_INSTALLED,
} from '../actions/trackingAnalyticsActions';

const clientSide = typeof window === 'object';

const dismissedAlert = {
  show: false,
};

const initialState = {
  alert: dismissedAlert,
  dateRange: {
    startDate: moment()
      .subtract(30, 'days')
      .format(API_DATE_FORMAT),
    endDate: moment()
      .subtract(1, 'days')
      .format(API_DATE_FORMAT),
    clientSide,
  },
  reports: Object.fromEntries(
    Object.keys({
      ...ENDPOINTS.REPORTS,
      ...TRACKING,
    }).map(key => [key, {}]),
  ),
  trackingType: OUTBOUND,
  segmentIds: [],
  retailerCustomComponents: [],
};

export default (state = initialState, { meta, type, payload }) => {
  switch (type) {
    case RECEIVE_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            typeof meta.error !== 'string'
              ? 'There was an error. Please try again.'
              : `${meta.error}.`,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: dismissedAlert,
      };
    case RECEIVE_TRACKING_ANALYTICS_REPORT:
      return {
        ...state,
        reports: {
          ...state.reports,
          [payload.key]: payload,
        },
      };
    case RECEIVE_NEW_SMS_ENABLED:
      return {
        ...state,
        newSMSEnabled: payload.newSMSEnabled,
      };
    case SELECT_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          ...payload.dateRange,
          clientSide,
        },
      };
    case SELECT_LOCALE:
      return {
        ...state,
        selectedLocale: payload.selectedLocale,
      };
    case SELECT_TRACKING_TYPE:
      return {
        ...state,
        trackingType: payload.trackingType,
      };
    case SELECT_SEGMENT_IDS:
      return {
        ...state,
        segmentIds: payload.segmentIds,
      };
    case RECEIVE_TRACK_SEGMENTS:
      return {
        ...state,
        segments: payload.segments,
      };
    case RECEIVE_CUSTOM_COMPONENTS_INSTALLED:
      return {
        ...state,
        retailerCustomComponents: payload.retailerCustomComponents,
      };
    default:
      return state;
  }
};
