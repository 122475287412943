import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  & .footer-container {
    border-top: 1px solid ${colors.gray};
    margin: 40px auto 50px;
    padding: 0;
    display: flex;
  }

  & img {
    margin: 20px auto auto 0;
  }

  & .narvar-links {
    display: flex;
    align-items: center;
    margin: 20px 0 auto auto;

    & .footer-divider {
      display: inline-block;
      height: 12px;
      border-left: 1px solid ${colors.grayDark};
    }

    & .footer-link {
      color: ${colors.grayDark};
      font-size: 12px;
      line-height: 14px;
      padding-left: 10px;
      padding-right: 10px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  & .language-selector-wrapper {
    display: inline-block;
  }

  & .language-selector-btn-wrapper {
    display: flex;
    align-items: baseline;
  }

  & .language-selector-arrow {
    width: 11px;
    height: 11px;
  }

  @media screen {
    & .print-footer {
      display: none;
    }
  }

  @media print {
    display: none;

    & .print-footer {
      display: table;
      position: fixed;
      bottom: 0;
    }
  }
`;

export default s;
