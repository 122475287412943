import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  &.DateRange {
    position: relative;
    box-sizing: border-box;

    & + button {
      margin-left: 10px;
    }

    & button + button {
      margin: 0;
    }

    & .date-picker-container {
      background-color: ${colors.white};
      border-bottom: 0;
      border-radius: 4px;
      border-right: 0;
      box-shadow: none;
      padding: 20px 0px 0px 10px;
      position: absolute;
      right: 192px;
      top: 41px;
      width: 574px;
      z-index: 1;
    }

    & .bottom-section-container {
      background-color: ${colors.white};
      border-top: 0;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      height: 90px;
      padding: 20px;
      padding-top: 0;
      position: absolute;
      right: 0;
      top: 345px;
      width: 766px;
      z-index: 1;
      border-radius: 4px;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      & .btn {
        text-align: center;
        justify-content: center;
      }
    }

    &.no-dropdown {
      width: 300px;
      position: absolute;

      & .date-picker-container {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        right: 0px;
        left: 0px;
        top: 40px;
        z-index: 2;

        & .input[type='text'] {
        }
      }
    }

    & .dropdown {
      width: 100%;
    }

    & .rdr-Calendar:first-child {
      margin-left: 22px;
    }

    & .rdr-Calendar {
      margin-left: 47px;
    }

    & .bottom-button-container {
      display: flex;
    }

    & .date-display-container {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: space-around;
      height: 70px;
      margin-bottom: 10px;
      width: 550px;
    }

    & .date-display-button-container {
      flex: 0 45%;
    }

    & .button-spacer {
      width: 25px;
    }

    & .horizontal-line {
      border-top: 1px solid ${colors.gray};
      padding-bottom: 20px;
      margin-top: 10px;
    }

    & .vertical-line {
      border-left: 1px solid ${colors.gray};
      display: inline-block;
      float: right;
      height: 270px;
      width: 1px;
      margin-right: 8px;
    }
  }

  & .Dropdown {
    & .singleDateReset {
      &.dropdown-toggle:after {
        display: none;
      }
    }

    & .btn {
      border-radius: 2px;
      height: 100%;
      min-height: 40px;
      min-width: 100px;
      width: 100%;
    }

    & .bottom-button-container {
      & .btn-link {
        border: none;
        box-shadow: none;
        &:hover {
          background-color: ${colors.blue};
          color: ${colors.white};
        }
      }
    }

    & .btn-default {
      @extend .btn;
      background-color: ${colors.white};
      border: 1px solid ${colors.gray};
      color: ${colors.grayDark};
    }

    & .dropdown-toggle:after {
      color: ${colors.black};
      opacity: ${colors.iconMute};
      margin: auto;
      margin-right: 10px;
      transition: color 0.2s;
    }

    & .single-input-date {
      width: 100%;
      text-align: left;
    }

    & .dropdown-toggle:hover:after {
      opacity: 1;
    }
    & .dropdown {
      & .btn.dropdown-toggle:disabled:after {
        display: none;
      }
    }

    & .btn.btn-secondary.dropdown-toggle {
      border: 0;
      padding: 0;
      background: ${colors.white};
      color: ${colors.dark1};
      border: 1px solid ${colors.gray};

      span {
        color: ${colors.dark2};
        transition: color 0.2s;
      }

      &:hover {
        border-color: ${colors.dark4};

        span {
          color: ${colors.dark1};
        }
      }

      &:focus {
        box-shadow: inset 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
        border-color: ${colors.dark4};
      }
    }

    & .dropdown-menu {
      padding: 10px 0;
      border: none;
      z-index: 2;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      min-width: 200px;
    }

    & .dropdown-toggle.active {
      &.dropdown-menu {
        box-shadow: 2px 4px 4px -2px rgba(0, 0, 0, 0.2);
      }
    }

    & .dropdown-input-display {
      margin: 0 10px;
      font-family: ${fonts.regular};
    }

    & .dropdown-item {
      border-left: 3px solid transparent;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      line-height: 33px;
      color: ${colors.dark2};
      &:hover {
        color: ${colors.dark1};
      }
    }

    & .dropdown-item:active {
      background-color: ${colors.blue};
      color: ${colors.white};
    }

    & .dropdown-item:focus {
      background-color: ${colors.white};
    }

    & .dropdown-item:focus:active {
      background-color: ${colors.blue};
    }

    & .dropdown-item.active {
      @extend .dropdown-item;
      color: ${colors.dark1};
      border-left-color: ${colors.brandPrimary};
      background-color: ${colors.grayLightest};
    }
  }
`;

export default s;
