import { combineReducers } from 'redux';
import {
  REQUEST_COMMENTS,
  RECEIVE_COMMENTS,
  RECEIVE_FEEDBACK_ERROR,
} from '~src/actions/feedbackEngagementActions';

function survey(
  state = {
    isFetching: false,
    filters: {},
    carriers: [],
    comments: [],
    total: 0,
    page: 0,
  },
  action,
) {
  const update = {};
  switch (action.type) {
    case REQUEST_COMMENTS:
      update.isFetching = true;
      update.filters = action.filters;
      update.page = action.page;
      break;
    case RECEIVE_COMMENTS:
      update.isFetching = false;
      update.comments = action.comments;
      update.carriers = action.carriers;
      update.total = action.total;
      break;

    case RECEIVE_FEEDBACK_ERROR:
      update.isFetching = false;
      break;

    default:
      return state;
  }
  return { ...state, ...update };
}

const surveyCommentsReducer = combineReducers({ survey });

export default surveyCommentsReducer;
