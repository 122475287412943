export const API_DATE_FORMAT = 'YYYY-MM-DD';

export const colors = {
  gray: '#e0e0e0',
  grayDark: '#616161',
  grayDarkest: '#424242',
  grayLight: '#edeeef',
  grayLighter: '#eceeef',
  grayLightest: '#f5f5f5',

  lightBlue: 'rgba(104,195,232,0.13)',
  darkGrey: 'rgba(80,81,81,0.47)',
  lightPurple: '#F5F3F5',

  dark1: '#424242',
  dark2: '#616161',
  dark3: '#757575',
  dark4: '#9E9E9E',

  green: '#27AE60',
  black: '#2C3239',
  blue: '#1399d6',
  darkBlue: '#0e719e',
  red: '#e53935',
  darkRed: '#CE332F',
  purple: '#9b59b6',
  orange: '#f65300',
  yellow: '#fdd835',
  white: '#ffffff',

  chart: {
    color1: '#3990c0',
    color2: '#e9722e',
    color3: '#983192',
    color4: '#87ccce',
    color5: '#f6b94d',
    color6: '#eb678b',
    color7: '#1b2781',
    colorNeutral: '#e0e0e0',
  },

  body: '#616161',

  brandPrimary: '#1399d6',
  brandSuccess: '#27AE60',
  brandInfo: '#1399d6',
  brandWarning: '#fdd835',
  brandDanger: '#e53935',

  textMuted: '#757575',
  iconMute: 0.4,
};

// TODO: Grid system
export const spacing = {
  sm: '0.313rem',
  m: '0.625rem',
  l: '1.25rem',
};

export const fonts = {
  regular: 'circular',
  bold: 'circular-bold',
};
