import React, { Component } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { shouldUpdate } from '../../lib/helpers';
import Button from '../Button';
import s from './Dropdown.styles';

export class NarvarDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { isOpen } = this.state;
    const {
      items,
      isFetching,
    } = this.props;
    if (
      shouldUpdate(items, nextProps.items)
      || isOpen
      || ((!isOpen && nextState.isOpen) || isFetching !== nextProps.isFetching)
    ) {
      return true;
    }
    return false;
  }

  onToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const {
      color,
      buttonText,
      disabled,
      divider,
      isFetching,
      items,
      right,
      testAttr,
    } = this.props;
    const { isOpen, id } = this.state;

    return (
      <div className={s}>
        <Dropdown
          className="Dropdown"
          data-test={`${testAttr}-dropdown`}
          disabled={disabled}
          id={id}
          isOpen={isOpen}
          toggle={this.onToggle}
        >
          {isFetching
            ? <Button isFetching={isFetching} color="secondary" /> :
            <DropdownToggle
              caret
              className="toggle"
              color={color}
              disabled={disabled}
            >
              {buttonText}
            </DropdownToggle>}
          <DropdownMenu right={right}>
            {items.map((item, index) => (
              <div key={item.text}>
                <DropdownItem
                  className={cx('dropdown-item', item.className)}
                  data-test={
                    `${item.divider ? 'divider' : item.text.toString().toLowerCase()}-dropdown-item`
                  }
                  onClick={item.onClick}
                  title={item.text}
                >
                  <span style={item.labelStyles}>
                    {item.text}
                  </span>
                </DropdownItem>
                {divider === index && <DropdownItem divider />}
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

NarvarDropdown.defaultProps = {
  isFetching: false,
  disabled: false,
  right: false,
  buttonText: null,
  divider: null,
  color: undefined, // use reactstrap default
};

NarvarDropdown.propTypes = {
  color: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  divider: PropTypes.number,
  right: PropTypes.bool,
  isFetching: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.any,
    onClick: PropTypes.func,
    testAttr: PropTypes.string,
    className: PropTypes.string,
    labelStyles: PropTypes.any,
    divider: PropTypes.any,
  })).isRequired,
  testAttr: PropTypes.string.isRequired,
};

export default NarvarDropdown;
