// helper function maps events as objects to event fields lv_1, lv_1_val...
export default ({ event: { eventType, properties }, eventsWithFields }) => {
  if (!properties) {
    return {}; // could have event with no properties
  }
  const eventFields = eventsWithFields[eventType];
  return eventFields.reduce((eventData, eventFieldOrArray, index) => {
    let eventField = eventFieldOrArray;

    if (Array.isArray(eventFieldOrArray)) {
      eventField = eventFieldOrArray.find(field => {
        const propertiesHasVal = typeof properties[field] !== 'undefined';
        const fieldAlreadyAdded = Object.keys(eventData).includes(field);
        return (propertiesHasVal && !fieldAlreadyAdded);
      });
    }

    const eventVal = eventField && properties[eventField];

    const eventLevel = `lvl_${index + 1}`;
    eventData[eventLevel] = typeof eventField !== 'undefined' ? eventField : null;
    eventData[`${eventLevel}_val`] = typeof eventVal !== 'undefined' ? eventVal : null;

    return eventData;
  }, {});
};
