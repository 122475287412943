import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  display: flex;
  flex-direction: row;
  margin: 0 10px 20px 20px;
  width: 200px;

  & .item {
    margin-right: 20px;
    min-width: 200px;
    padding-right: 10px;

    & h4 {
      margin-bottom: 0;
    }

    & .dropdown {
      height: 15px;
      margin-top: 4px;
    }

    & .dropdown-menu {
      margin-top: 5px;
    }

    & .dropdown-toggle {
      background: transparent;
      border: none;
      box-shadow: none;
      height: 15px;
      min-height: 15px;
      padding: 0;
      
      & input + div {
        right: 0;
        top: -1px;
      }

      & div {
        color: ${colors.grayDark};
      }

      &.active {
        background-color: transparent;
      }

      & input {
        background-color: transparent;
      }
    }
  }
`;

export default s;
