import {
  REQUEST_RETAILER_SETTINGS,
  RECEIVE_ERROR,
  RECEIVE_RETAILER_SETTINGS,
  REQUEST_UPDATE_RETAILER_SETTINGS,
  RECEIVE_UPDATE_RETAILER_SETTINGS,
  REQUEST_UPLOAD_ASSET_IMAGE,
  RECEIVE_UPDATE_ASSET_IMAGE,
} from '~src/actions/assistSettingsActions';

import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    show: false,
    color: 'info',
    text: '',
  },
  configuration: {
    model: {
      canonicalUrl: 'input',
      cookieDomain: 'input',
      desktopScripts: 'input',
      enabled: 'switch',
      favicon: 'input',
      metaDescription: 'input',
      mobileScripts: 'input',
      searchInterface: 'select',
      searchInterfaceHeroImage: 'imageUploader',
      titleTag: 'input',
    },
    options: {
      searchInterface: [
        {
          title: 'Default Search',
          id: 'defaultSearch',
        },
        {
          title: 'Hero Image Search',
          id: 'heroImageSearch',
        },
      ],
    },
  },
  retailerSettings: {},
  isFetchingAssistRetailerSettings: false,
  isFetchingUpdateAssistRetailerSettings: false,
  isFetchingUploadAssetImage: false,
  uriMoniker: '',
};

export default function(state = initialState, action) {
  const { error, type, meta, payload } = action;

  switch (type) {
    case RECEIVE_RETAILER_SETTINGS:
      return {
        ...state,
        retailerSettings: payload.settings,
        isFetchingAssistRetailerSettings: false,
        uriMoniker: meta.uriMoniker,
      };

    case REQUEST_RETAILER_SETTINGS:
      return {
        ...state,
        isFetchingAssistRetailerSettings: true,
      };

    case REQUEST_UPDATE_RETAILER_SETTINGS:
      return {
        ...state,
        isFetchingUpdateAssistRetailerSettings: true,
      };

    case RECEIVE_UPDATE_RETAILER_SETTINGS:
      return {
        ...state,
        retailerSettings: payload,
        isFetchingUpdateAssistRetailerSettings: false,
        uriMoniker: meta.uriMoniker,
        alert: {
          show: true,
          color: 'success',
          text: meta.message,
        },
      };

    case REQUEST_UPLOAD_ASSET_IMAGE:
      return {
        ...state,
        isFetchingUploadAssetImage: true,
      };

    case RECEIVE_UPDATE_ASSET_IMAGE:
      return {
        ...state,
        isFetchingUploadAssetImage: false,
        retailerSettings: {
          ...state.retailerSettings,
          [meta.key]: payload,
        },
      };

    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          show: true,
          color: 'danger',
          text: error,
        },
        isFetchingUpdateAssistRetailerSettings: false,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          show: false,
          color: 'info',
          text: '',
        },
      };

    default:
      return state;
  }
}
