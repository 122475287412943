export const RULE_TYPES = {
  RETURN_FEES: 'return_fees',
  RETURN_FEES_POLICY: 'fees_policy',
  RETURN_ELIGIBILITY: 'return_eligibility',
  RETURN_METHODS: 'return_methods',
  RETURN_REASONS: 'return_reasons',
  RETURN_REFUND_OPTIONS: 'return_refund_options',
  RETURN_DESTINATION_POLICY: 'destinations_policy',
  RETURN_METHODS_POLICY: 'return_methods_policy',
};

export const MODES = {
  FEES: 'feesMode',
  DESTINATIONS: 'destinationsMode',
  RETURN_METHODS: 'returnMethodsMode',
};

export const CATEGORIES = {
  RETURN_METHOD: 'return_method',
  RETURN_DC: 'return_dc',
  RETURN_FEES: 'return_fees',
  RETURN_ELIGIBILITY_MESSAGE: 'return_eligibility_message',
  PRODUCT_ELIGIBILITY: 'product_eligibility',
};

export const FORM_TYPES = {
  DEFAULT: 'default',
  MULTI_PICKLIST: 'multi_picklist',
  BOOLEAN: 'boolean',
  DATE: 'date',
  TEXT: 'text',
  DAYS: 'days',
  NUMERIC: 'numeric',
  KEY_VALUE_TEXT: 'key_value_text',
  LIST_KEY_VALUE_TEXT: 'list_key_value_text',
  STATIC_VALUE: 'static_value',
  SUB_ITEMS: 'sub_items',
  CODE: 'code',
};
