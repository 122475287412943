import * as React from 'react';
import i18n from '../i18n';

const useInitialiseFullStoryInfo = (user, profile) =>
  React.useEffect(() => {
    if (typeof window !== 'object') return;
    if (
      !window.FS ||
      !user ||
      !user.id ||
      !user.current_retailer_id ||
      !user.retailerIdToRetailerInfo ||
      !(user.current_retailer_id in user.retailerIdToRetailerInfo) ||
      !profile ||
      !(user.customerSupportLogin || profile.user_id)
    )
      return;

    window.FS.identify(user.id.toString(), {
      retailerMoniker:
        user.retailerIdToRetailerInfo[user.current_retailer_id].uri_moniker,
      email: user.email,
      role: user.userRetailerAssociationList.find(
        r => r.retailerId === user.current_retailer_id,
      ).roles[0].name,
      department: profile.department?.option || '',
      level: profile.level?.option || '',
      howToUseHub: profile.how_to_use_hub.map(h => h.option),
      isCsm: user.customerSupportLogin || false,
      hubLanguage: i18n.language,
    });
  }, [user, profile]);

export default useInitialiseFullStoryInfo;
