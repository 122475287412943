import company from './company.json';
import general from './general.json';
import hum from './hum.json';
import messaging from './messaging.json';
import profile from './profile.json';
import returns from './returns.json';
import track from './track.json';
import wysiwyg from './wysiwyg.json';
import returnRules from './returnRules.json';

export default {
  general,
  company,
  hum,
  messaging,
  profile,
  returns,
  track,
  wysiwyg,
  returnRules,
};
