import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  position: relative;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 2px;
  box-shadow: 0px 3px 2px -2px ${colors.grayDark};
  width: 100%;
  padding: 10px 40px;
  color: ${colors.black};

  &.success {
    border-Left: 2px solid ${colors.green};
  }

  &.error {
    border-Left: 2px solid ${colors.red};
  }

  & .alert-title{
    margin-bottom: 20px;
  }

  & .alert-button{
    font-family: ${fonts.bold};
    color: ${colors.blue};
    outline: none;
    margin-top: 20px;
    cursor: pointer;
    display: inline-block;
  }

  & .icon{
    position: absolute;
    top: -5px;
    left: -5px;
    transform: scale(0.4);
  }
`;

export default s;
