import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Tooltip } from 'reactstrap';
import s from './Tooltip.styles';

export const NarvarTooltip = ({
  autohide,
  children,
  className,
  delay,
  isOpen,
  placement,
  target,
  toggle,
}) => (
  <Tooltip
    autohide={autohide}
    className={cx(className, s)}
    isOpen={isOpen}
    placement={placement}
    target={target}
    toggle={toggle}
    delay={delay}
  >
    {children}
  </Tooltip>
);

NarvarTooltip.defaultProps = {
  placement: 'top',
  className: '',
  children: null,
  autohide: true,
  delay: undefined,
};

NarvarTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  delay: PropTypes.oneOfType([
    PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
    PropTypes.number,
  ]),
  placement: PropTypes.string, // see reactstrap for allowed
  target: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  autohide: PropTypes.bool,
};

NarvarTooltip.displayName = 'NarvarTooltip'; // to distinguish from reactstrap tooltip

export default NarvarTooltip;
