import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  overflow: hidden;
  border-bottom: 1px solid ${colors.gray};

  & .data-container {
    display: flex;
    min-height: 40px;
    align-items: center;
  }

  & .data {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default s;
