import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './CarouselPagination.styles';
import Svg from '../Svg';
import Tooltip from '../Tooltip';

export class CarouselPagination extends Component {
  state = {
    startIndex: 0,
    endIndex: 6,
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dateIndex !== this.props.dateIndex ||
      nextProps.sections !== this.props.sections
    ) {
      this.generateUISections(nextProps.dateIndex, nextProps.sections);
    }
  }

  generateUISections = (dateIndex, sections) => {
    const parsedDateIndex = parseInt(dateIndex);

    let startIndex;
    let endIndex;

    if (parsedDateIndex - 3 < 0) {
      startIndex = 0;
      endIndex = 6;
    } else if (parsedDateIndex + 3 > sections - 1) {
      startIndex = parsedDateIndex + 3 - sections - 1 - 3;
      endIndex = sections - 1;
    } else {
      startIndex = parsedDateIndex - 3;
      endIndex = parsedDateIndex + 3;
    }

    this.setState({
      startIndex,
      endIndex,
    });
  };

  render() {
    const {
      dateIndex,
      handleDateSelection,
      handleChevronClick,
      handleToggleTooltip,
      isTooltipOpen,
      sections,
      pillText,
      tooltipText,
    } = this.props;

    const { startIndex, endIndex } = this.state;

    return (
      <div className={cx(s, 'flex-right')}>
        {dateIndex != 0 &&
          <Svg
            data-id={'left'}
            name={'chevron'}
            height={'26'}
            width={'16'}
            className={'chevron-left'}
            onClick={handleChevronClick}
          />}
        {sections &&
          [...Array(sections)].map(
            (section, i) =>
              startIndex <= i && i <= endIndex
                ? i == dateIndex
                    ? <div
                        className={'pill'}
                        onClick={handleDateSelection}
                        data-index={i}
                        key={i}
                      >
                        {pillText}
                      </div>
                    : <div
                        className={'section-container'}
                        onClick={handleDateSelection}
                        data-index={i}
                        id={`carousel-pagination-section-${i}`}
                        key={i}
                      >
                        <span className={'section'} />
                        <Tooltip
                          placement={'top'}
                          isOpen={isTooltipOpen[i] ? isTooltipOpen[i] : false}
                          target={`carousel-pagination-section-${i}`}
                          toggle={handleToggleTooltip(i)}
                          delay={0}
                        >
                          {tooltipText}
                        </Tooltip>
                      </div>
                : null
          )}
        {dateIndex != sections - 1 &&
          <Svg
            data-id={'right'}
            name={'chevron'}
            height={'26'}
            width={'16'}
            className={'chevron'}
            onClick={handleChevronClick}
          />}
      </div>
    );
  }
}

CarouselPagination.propTypes = {
  dateIndex: PropTypes.number,
  handleToggleTooltip: PropTypes.func.isRequired,
  isTooltipOpen: PropTypes.array,
  pillText: PropTypes.string,
  sections: PropTypes.number,
  tooltipText: PropTypes.string,
};

export default CarouselPagination;
