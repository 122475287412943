import { push } from 'connected-react-router';
import { API_REQUEST } from '~src/lib/api';
import { buildQueryString } from '~src/lib/helpers';
import { logExport } from '~src/analytics/monitor';
import * as ENDPOINTS from '~src/constants/endpoints';

export const REQUEST_PACKAGES = 'PACKAGES/REQUEST_PACKAGES';
export const RECEIVE_PACKAGES = 'PACKAGES/RECEIVE_PACKAGES';
export const RECEIVE_ORDER = 'PACKAGES/RECEIVE_ORDER';
export const ERROR = 'PACKAGES/ERROR';

const errorAlert = message => ({
  type: ERROR,
  payload: { message },
});

export const fetchPackages = query => (dispatch, getState) => {
  const { object } = getState().reporting;

  dispatch({
    type: REQUEST_PACKAGES,
  });

  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    name: 'fetchPackages',
    path: ENDPOINTS.REPORTING_ROWS,
    query,
  })
    .then(json => {
      dispatch({
        type: RECEIVE_PACKAGES,
        payload: {
          items: json[object].rows,
          totalRecords: json.total_records,
          totalRecordsWithFilters: json.total_records_with_filters,
        },
      });
    })
    .catch(err => {
      console.error(err); // eslint-disable-line
    });
};

export const exportExcel = query => dispatch => {
  dispatch(logExport('excel'));
  const queryWithLimit = {
    ...query,
    limit: '65000',
  };
  const queryString = buildQueryString(queryWithLimit);
  const link = document.createElement('a');

  link.href = ENDPOINTS.REPORTING_ROWS_DOWNLOADS + queryString;
  link.style = 'visibility:hidden';
  link.target = '_blank';

  if (document) {
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export const fetchOrder = orderNumber => async dispatch => {
  const query = {
    order_number: orderNumber,
  };

  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.ATLAS_ORDER,
      name: `fetchOrder:${orderNumber}`,
      query,
    });
    const orderDetails = {
      order: json.order,
      tracking: json.tracking,
    };
    dispatch({
      type: RECEIVE_ORDER,
      payload: { orderDetails },
    });
  } catch (err) {
    dispatch(errorAlert('Error: unable to fetch the order details'));
  }
};

export const loadPackageView = ({
  orderNumber,
  trackingNumber,
}) => dispatch => {
  dispatch(
    push({
      pathname: '/monitor/package',
      search: `?order_number=${orderNumber}&tracking_number=${trackingNumber}`,
    }),
  );
};
