import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './Loading.styles';

export const Loading = ({
  className,
}) => (
  <div className={cx(s, className)}>
    <div className="header" />
  </div>
);

Loading.defaultProps = {
  className: '',
};

Loading.propTypes = {
  className: PropTypes.string,
};

Loading.displayName = 'Loading';

export default Loading;
