import { API_REQUEST } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const DISPLAY_ERROR = 'DISPLAY_ERROR_ZENDESK';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS_ZENDESK';
export const ADD_NEW_TOKEN = 'ADD_NEW_TOKEN_ZENDESK';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS_ZENDESK';
export const RECEIVE_ZENDESK_VALID = 'RECEIVE_ZENDESK_IS_VALID';
export const RECEIVE_ZENDESK_CONFIG = 'RECEIVE_ZENDESK_CONFIG';
export const RECEIVE_ZENDESK_ASSIST_TRIGGER = 'RECEIVE_ZENDESK_ASSIST_TRIGGER';
export const ZENDESK_RESET = 'ZENDESK_RESET';

export const displayError = text => ({
  type: DISPLAY_ERROR,
  payload: { text },
});

export const displaySuccess = text => ({
  type: DISPLAY_SUCCESS,
  payload: { text },
});

export const addNewToken = accessToken => ({
  type: ADD_NEW_TOKEN,
  payload: { accessToken },
});

export const receiveTokens = accessTokens => ({
  type: RECEIVE_TOKENS,
  payload: { accessTokens },
});

export const receiveZendeskValid = isValid => ({
  type: RECEIVE_ZENDESK_VALID,
  payload: { isValid },
});

export const receiveZendeskConfig = ({ host }) => ({
  type: RECEIVE_ZENDESK_CONFIG,
  payload: {
    host,
  },
});

export const receiveZendeskAssistTrigger = ({ triggerId }) => ({
  type: RECEIVE_ZENDESK_ASSIST_TRIGGER,
  payload: {
    triggerId,
  },
});

export const resetState = () => ({
  type: ZENDESK_RESET,
});

export const fetchIsZendeskValid = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { isValid } = await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_ZENDESK_VERIFY,
    name: 'partnershipsVerifyZendesk',
    body: JSON.stringify({
      retailerMoniker,
    }),
  });
  await dispatch(receiveZendeskValid(isValid));
};

export const fetchZendeskConfigs = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { host } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_ZENDESK_HOST({ retailerMoniker }),
    name: 'partnershipsfetchZendeskConfigs',
  });
  dispatch(receiveZendeskConfig({ host }));
};

export const updateZendeskConfigs = ({ host }) => async (
  dispatch,
  getState,
) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: ENDPOINTS.PARTNERSHIPS_ZENDESK_HOST({ retailerMoniker }),
    name: 'partnershipsUpdateZendesk',
    body: JSON.stringify({
      retailerMoniker,
      host,
    }),
  });
  dispatch(fetchZendeskConfigs());
};

export const fetchZendeskAssistTriggerId = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { id: triggerId } = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PARTNERSHIPS_ZENDESK_ASSIST_TRIGGER_GET({
      retailerMoniker,
    }),
    name: 'partnershipsFetchZendeskAssistTriggerId',
  });
  dispatch(receiveZendeskAssistTrigger({ triggerId }));
};

export const updateZendeskAssistTrigger = () => async (dispatch, getState) => {
  const {
    userReducer: {
      user: {
        id: userId,
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();
  const { id: triggerId } = await dispatch({
    type: API_REQUEST,
    method: 'POST',
    body: JSON.stringify({
      retailerMoniker,
      retailerId: currentRetailerId,
      userId,
    }),
    path: ENDPOINTS.PARTNERSHIPS_ZENDESK_ASSIST_TRIGGER_POST,
    name: 'partnershipsUpdateZendeskAssistTrigger',
  });
  dispatch(receiveZendeskAssistTrigger({ triggerId }));
};
