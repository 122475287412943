import {
  UPDATE_UI_BUILDER_FORM,
  UPDATE_PRODUCT_ATTRIBUTES,
  UPDATE_HAS_PREVIEWED,
  RESET_PUBLISH_STATE,
  TOGGLE_RETURN_METHOD_MODAL,
  TOGGLE_PUBLISH_MODAL,
  RECEIVE_UI_BUILDER_SETTINGS,
  RECEIVE_UI_BUILDER_PUBLISH,
  FETCHING_UI_BUILDER_SETTINGS,
  UPDATE_LOCATIONS_MODAL,
  GET_ALL_QUERY_PARAMS,
  RECEIVE_CONFIGURED_REFUND_OPTIONS,
} from '../actions/returnsUIBuilderActions';
import { flattenSettings } from '../lib/returns/uiBuilder';
import { formatCreatedAndModifiedDate } from '../lib/helpers';

export const initialState = {
  isFetching: false,
  form: {
    flatReturnsSettings: {},
    language: {},
    meta: {
      returnsSettingsCreated: '',
      returnsSettingsModified: '',
    },
    errors: {},
    cards: [{}, {}, {}, {}, {}],
    productAttributeDisplaySettings: {
      enabled: false,
      properties: {
        allowedAttributes: [],
        property_type: [],
      },
    },
    rightCards: [{}],
    navigationMenuItems: [],
    useTrackNav: false,
  },
  deleteReturnMethodModal: {
    methodKey: '',
    show: false,
  },
  locationsModal: {
    show: false,
    mainText: '',
    title: '',
  },
  diff: {},
  languageTemplate: {},
  returnsSettingsTemplate: {},
  queryParams: {},
  showPublishModal: false,
  changeDetected: false,
  productAttributeChangeDetected: false,
  hasPreviewed: false,
  uiSchemaConfigs: [],
  supportedReturnMethods: {},
  supportedRefundOptions: {},
  navigationMenus: {},
  configuredRefundOptions: [],
};

const returnsUIBuilderReducer = (
  state = initialState,
  {
    type,
    payload: {
      form,
      changeDetected,
      hasPreviewed,
      language,
      settings,
      created,
      modified,
      uiSchemaConfigs,
      supportedReturnMethods,
      supportedRefundOptions,
      languageTemplate,
      returnsSettingsTemplate,
      queryParams,
      locationsModal,
      diff,
      productAttributeDisplaySettings = {},
      methodKey,
      navigationMenus,
      navigationMenuItems,
      useTrackNav,
      configuredRefundOptions = [],
    } = {},
  } = {},
) => {
  switch (type) {
    case FETCHING_UI_BUILDER_SETTINGS:
      return {
        ...state,
        isFetching: true,
      };

    case GET_ALL_QUERY_PARAMS:
      return {
        ...state,
        queryParams,
      };

    case RECEIVE_UI_BUILDER_PUBLISH:
      return {
        ...state,
        isFetching: false,
        form: {
          ...state.form,
          language,
          flatReturnsSettings: flattenSettings(settings),
          meta: {
            returnsSettingsCreated: formatCreatedAndModifiedDate({
              date: created.date,
              firstName: created.firstName,
              lastName: created.lastName,
            }),
            returnsSettingsModified: formatCreatedAndModifiedDate({
              date: modified.date,
              firstName: modified.firstName,
              lastName: modified.lastName,
            }),
          },
        },
      };

    case RECEIVE_UI_BUILDER_SETTINGS:
      return {
        ...state,
        isFetching: false,
        languageTemplate,
        returnsSettingsTemplate,
        uiSchemaConfigs,
        supportedReturnMethods,
        supportedRefundOptions,
        navigationMenus,
        form: {
          ...state.form,
          useTrackNav,
          navigationMenuItems,
          language,
          flatReturnsSettings: flattenSettings(settings),
          productAttributeDisplaySettings,
          meta: {
            returnsSettingsCreated: formatCreatedAndModifiedDate({
              date: created.date,
              firstName: created.firstName,
              lastName: created.lastName,
            }),
            returnsSettingsModified: formatCreatedAndModifiedDate({
              date: modified.date,
              firstName: modified.firstName,
              lastName: modified.lastName,
            }),
          },
        },
      };

    case UPDATE_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        form,
        changeDetected: true,
        productAttributeChangeDetected: true,
        hasPreviewed: false,
      };

    case UPDATE_UI_BUILDER_FORM:
      return {
        ...state,
        form,
        changeDetected,
        hasPreviewed,
      };

    case UPDATE_HAS_PREVIEWED:
      return {
        ...state,
        hasPreviewed,
      };

    case RESET_PUBLISH_STATE:
      return {
        ...state,
        changeDetected: false,
        productAttributeChangeDetected: false,
        hasPreviewed: false,
      };

    case TOGGLE_RETURN_METHOD_MODAL:
      return {
        ...state,
        deleteReturnMethodModal: {
          methodKey,
          show: !state.deleteReturnMethodModal.show,
        },
      };

    case TOGGLE_PUBLISH_MODAL:
      return {
        ...state,
        showPublishModal: !state.showPublishModal,
      };

    case UPDATE_LOCATIONS_MODAL:
      return {
        ...state,
        locationsModal,
        diff,
      };

    case RECEIVE_CONFIGURED_REFUND_OPTIONS:
      return {
        ...state,
        configuredRefundOptions,
      };

    default:
      return state;
  }
};

export default returnsUIBuilderReducer;
