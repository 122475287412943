import {
  RESTRICTED_EMAILS_PAGE_LOADED,
  RECEIVE_RESTRICTED_EMAILS_FILE_SELECTED,
} from '../actions/returnsRestrictedEmailsActions';

const initialState = {
  file: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESTRICTED_EMAILS_PAGE_LOADED:
      return {
        ...state,
        file: null,
      };

    case RECEIVE_RESTRICTED_EMAILS_FILE_SELECTED:
      return {
        ...state,
        file: action.file,
      };

    default:
      return state;
  }
};
