import moment from 'moment';

export default ({ orders = [], columns = [], columnConfiguration = {} }) => ({
  tableHeaders: columns.map(column => {
    const {
      [column]: { label },
    } = columnConfiguration;

    return {
      field: column,
      label,
    };
  }),

  tableBody: orders.map(order => ({
    text: columns.map(column => {
      const labelProperty = column.split('.')[1];
      const { [labelProperty]: columnValue } = order;
      const { [column]: { format } = {} } = columnConfiguration;

      if (format === 'date' && moment(columnValue).isValid()) {
        return moment(columnValue).format('MMM DD, YYYY');
      }

      if (typeof columnValue === 'boolean') {
        return columnValue ? 'Yes' : 'No';
      }

      return columnValue;
    }),
  })),
});
