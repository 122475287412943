import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select } from '@narvar/hum';
import { fetchStatus } from '../../actions/userActions';

const LocalePicker = ({
  locales,
  selectedLocale,
  onSelect,
  allLocales,
  disableUserFilter,
}) => {
  const [userLocales, setUserLocales] = useState(locales);
  const [localSelectedLocale, updateLocalSelectedLocale] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation('general');

  const { user, retailerId, retailerIdToRetailerInfo } = useSelector(
    ({ userReducer }) => ({
      user: userReducer.user,
      retailerId: userReducer.user ? userReducer.user.retailerId : undefined,
      retailerIdToRetailerInfo: userReducer.user
        ? userReducer.user.retailerIdToRetailerInfo
        : {},
    }),
  );

  const LOCALE_READABLE_KEY = 'locale';
  const ALL_LOCALES_VALUE = t('localePicker.allLocations');

  useEffect(() => {
    if (!locales || !locales.length) return;
    setUserLocales(locales);
  }, [locales]);

  useEffect(() => {
    if (disableUserFilter) return;
    if (!user) dispatch(fetchStatus());

    const retailerMoniker =
      Number.isInteger(retailerId) && retailerIdToRetailerInfo
        ? retailerIdToRetailerInfo[retailerId].uri_moniker
        : null;

    if (!retailerMoniker) return;

    const {
      userLocales: { [retailerMoniker]: localeAccess = [] },
    } = user;

    // For products that use a custom locale list, filter out user locales from that instead
    setUserLocales(
      locales.length
        ? localeAccess.filter(locale => locales.indexOf(locale) !== -1)
        : localeAccess,
    );
  }, [
    dispatch,
    user,
    retailerId,
    retailerIdToRetailerInfo,
    locales,
    disableUserFilter,
  ]);

  const selectableLocaleOptions = useMemo(() => {
    if (!userLocales || !userLocales.length) return [];
    const sortedLocales = userLocales.sort((a, b) => a.localeCompare(b));
    const userHasAllLocales = locales.every(v => sortedLocales.includes(v));
    const allOptions =
      allLocales && userHasAllLocales
        ? [ALL_LOCALES_VALUE, ...sortedLocales]
        : sortedLocales;
    return allOptions.map(x => ({
      [LOCALE_READABLE_KEY]: x,
    }));
  }, [allLocales, userLocales, locales]);

  useEffect(() => {
    if (selectableLocaleOptions) {
      updateLocalSelectedLocale(selectedLocale || ALL_LOCALES_VALUE);
    }
  }, [selectableLocaleOptions, selectedLocale, updateLocalSelectedLocale]);

  const localeChange = ({ currentTarget: { name: localeIndex } = {} }) => {
    const newLocale = selectableLocaleOptions[localeIndex][LOCALE_READABLE_KEY];

    if (selectedLocale !== newLocale) {
      updateLocalSelectedLocale(newLocale);
    }

    onSelect(newLocale === ALL_LOCALES_VALUE ? null : newLocale);
  };

  if (!userLocales || !userLocales.length) return null;

  // Display the locale name if there is only one locale (plus all locales option)
  if (userLocales.length === 1) {
    const onlyLocale = userLocales[0];
    return (
      <div style={{ margin: '0 2rem' }}>
        {t('viewing')} {onlyLocale}
      </div>
    );
  }
  return (
    <div>
      <Select
        items={selectableLocaleOptions}
        name="locales"
        onSelect={localeChange}
        selected={{ [LOCALE_READABLE_KEY]: localSelectedLocale }}
        readableKey={LOCALE_READABLE_KEY}
      />
    </div>
  );
};

LocalePicker.propTypes = {
  locales: PropTypes.arrayOf(PropTypes.string),
  selectedLocale: PropTypes.string,
  onSelect: PropTypes.func,
  allLocales: PropTypes.bool,
  disableUserFilter: PropTypes.bool,
};

LocalePicker.defaultProps = {
  locales: [],
  onSelect: () => {},
  selectedLocale: '',
  allLocales: false,
  disableUserFilter: false,
};

export default LocalePicker;
