import moment from 'moment';
import { uploadAsset } from '../actions/returnsSettingsActions';
import {
  IMAGE_DEFAULT_ERROR_MSG,
  INTERPOLATION_KEY,
} from '../constants/returns/uiBuilder';
import { swap } from '../lib/helpers';
import {
  flattenSettings,
  unflattenSettings,
  getSupplementaryLanguage,
  generateMethodKey,
  getAvailableMethods,
  getLanguageKeysFromMethodKey,
} from '../lib/returns/uiBuilder';
import { API_REQUEST } from '../lib/api';
import { RETURNS_VERIFY_REFUND_OPTIONS } from '../constants/endpoints';
import { generateDiff } from '../containers/Returns/UIBuilder/Details/schema/MethodSelection/migration';

export const UPDATE_UI_BUILDER_FORM = 'UPDATE_UI_BUILDER_FORM';
export const UPDATE_PRODUCT_ATTRIBUTES = 'UPDATE_PRODUCT_ATTRIBUTES';
export const UPDATE_HAS_PREVIEWED = 'UPDATE_HAS_PREVIEWED';
export const RESET_PUBLISH_STATE = 'RESET_PUBLISH_STATE';
export const TOGGLE_RETURN_METHOD_MODAL = 'TOGGLE_RETURN_METHOD_MODAL';
export const TOGGLE_PUBLISH_MODAL = 'TOGGLE_PUBLISH_MODAL';
export const RECEIVE_UI_BUILDER_SETTINGS = 'RECEIVE_UI_BUILDER_SETTINGS';
export const RECEIVE_UI_BUILDER_PUBLISH = 'RECEIVE_UI_BUILDER_PUBLISH';
export const FETCHING_UI_BUILDER_SETTINGS = 'FETCHING_UI_BUILDER_SETTINGS';
export const TOGGLE_LOCATIONS_UI = 'TOGGLE_LOCATIONS_UI';
export const UPDATE_LOCATIONS_MODAL = 'UPDATE_LOCATIONS_MODAL';
export const GET_ALL_QUERY_PARAMS = 'GET_ALL_QUERY_PARAMS';
export const RECEIVE_CONFIGURED_REFUND_OPTIONS =
  'RECEIVE_CONFIGURED_REFUND_OPTIONS';

export const updateUIBuilderForm = ({
  form,
  changeDetected,
  hasPreviewed,
}) => ({
  type: UPDATE_UI_BUILDER_FORM,
  payload: {
    form,
    changeDetected,
    hasPreviewed,
  },
});

export const updateProductAttributes = form => ({
  type: UPDATE_PRODUCT_ATTRIBUTES,
  payload: {
    form,
  },
});

export const updateHasPreviewed = hasPreviewed => ({
  type: UPDATE_HAS_PREVIEWED,
  payload: {
    hasPreviewed,
  },
});

export const resetPublishState = () => ({
  type: RESET_PUBLISH_STATE,
});

export const updateForm = ({ field, value, formProperty }) => (
  dispatch,
  getState,
) => {
  const {
    returnsUIBuilderReducer: { form, changeDetected },
  } = getState();

  let newForm = {};
  if (formProperty === 'errors') {
    newForm = {
      ...form,
      errors: {
        [field]: value,
      },
    };
  } else {
    newForm = {
      ...form,
      [formProperty]: {
        ...form[formProperty],
        [field]: value,
      },
      errors: {
        [field]: '',
      },
    };
  }

  dispatch(
    updateUIBuilderForm({
      form: newForm,
      changeDetected: changeDetected || formProperty !== 'errors',
      hasPreviewed: false,
    }),
  );
};

export const uploadImage = (file, device, field) => async dispatch => {
  const { name } = file;
  const fileExtension = name.split('.').pop();
  const timestamp = moment().format('YYYYMMDDHHmmSS');
  const fileName = `RETURNS_${device}_${timestamp}.${fileExtension}`;
  try {
    const imageUrl = await dispatch(
      uploadAsset({
        file,
        fileName,
        type: 'image',
      }),
    );

    dispatch(
      updateForm({
        field,
        value: imageUrl,
        formProperty: 'language',
      }),
    );
  } catch (error) {
    dispatch(
      updateForm({
        field,
        value: IMAGE_DEFAULT_ERROR_MSG,
        formProperty: 'errors',
      }),
    );
  }
};

export const handleAddLegacyMethod = () => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings, language },
      returnsSettingsTemplate,
      languageTemplate,
    },
  } = getState();
  const newReturnsSettings = unflattenSettings(flatReturnsSettings);
  const returnMethods = newReturnsSettings.returns.return_methods;
  const availableMethods = getAvailableMethods(
    newReturnsSettings,
    returnsSettingsTemplate,
  );

  const newMethod = {
    ...availableMethods[0],
    enabled: true,
    default: false,
  };
  const newMethodKey = generateMethodKey(newMethod);
  const newReturnMethods = returnMethods.filter(
    method => generateMethodKey(method) !== newMethodKey,
  );

  newReturnMethods.push(newMethod);
  newReturnsSettings.returns.return_methods = newReturnMethods;

  const newLanguageKeys = getLanguageKeysFromMethodKey(newMethodKey);
  const newLanguage = {
    ...language,
    ...getSupplementaryLanguage(newLanguageKeys, languageTemplate),
  };

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings(newReturnsSettings),
        language: newLanguage,
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const toggleLocationsModal = () => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form: { flatReturnsSettings },
      locationsModal: { show },
      returnsSettingsTemplate,
    },
  } = getState();
  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const { returns: { returnOptions: { enabled } = {} } = {} } = returnsSettings;

  const diff = generateDiff({
    options: returnsSettings.returns.return_methods.filter(
      method => method.enabled,
    ),
    returnsSettingsTemplate,
  });

  dispatch({
    type: UPDATE_LOCATIONS_MODAL,
    payload: {
      diff,
      locationsModal: {
        show: !show,
        title: enabled
          ? 'Turn off Return Methods Page with Locations?'
          : 'Upgrade Return Methods Page?',
        mainText: enabled
          ? 'This will permanently delete these details, including return methods and descriptions.'
          : 'This will permanently delete these configurations, including return methods and descriptions.',
      },
    },
  });
};

export const confirmLocationsModal = () => async (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings, language },
      diff: {
        mergedConfigs: { options, methods },
      },
      supportedReturnMethods,
      languageTemplate,
      uiSchemaConfigs,
      returnsSettingsTemplate: {
        returns: { returnOptions: returnOptionsTemplate = {} } = {},
      } = {},
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const { returns: { returnOptions: { enabled } = {} } = {} } = returnsSettings;

  if (enabled) {
    delete returnsSettings.returns.returnOptions;
    returnsSettings.returns.return_methods = [];
    await dispatch(
      updateUIBuilderForm({
        form: {
          ...form,
          flatReturnsSettings: flattenSettings(returnsSettings),
        },
        changeDetected: true,
        hasPreviewed: false,
      }),
    );
    await dispatch(handleAddLegacyMethod());
  } else {
    returnsSettings.returns.returnOptions = {
      ...returnOptionsTemplate,
      enabled: true,
      useLocationJS: true,
      options,
      methods,
    };

    let dynamicLanguage = {};

    const languageToInsert = options.reduce((finalLanguage, option) => {
      const {
        languageKeys = [],
        dynamicLanguageKeys = [],
      } = uiSchemaConfigs.find(({ id }) => id === option.id);
      const standardMethodLanguage = languageKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: languageTemplate[key],
        }),
        {},
      );
      let methodCarrierLanguage = {};

      option.methods.forEach(methodKey => {
        const standardMethodCarrierLanguage = supportedReturnMethods[
          methodKey
        ].language.reduce(
          (acc, key) => ({ ...acc, [key]: languageTemplate[key] }),
          {},
        );
        const dynamicMethodCarrierLanguage = dynamicLanguageKeys.reduce(
          (acc, { renderKey, dynamicKey }) => ({
            ...acc,
            [renderKey]:
              option.methods.length > 1
                ? languageTemplate[renderKey]
                : languageTemplate[
                    dynamicKey.replace(INTERPOLATION_KEY, methodKey)
                  ],
          }),
          {},
        );
        methodCarrierLanguage = {
          ...methodCarrierLanguage,
          ...standardMethodLanguage,
          ...standardMethodCarrierLanguage,
        };
        dynamicLanguage = {
          ...dynamicLanguage,
          ...dynamicMethodCarrierLanguage,
        };
      });

      return { ...finalLanguage, ...methodCarrierLanguage };
    }, {});

    await dispatch(
      updateUIBuilderForm({
        form: {
          ...form,
          flatReturnsSettings: flattenSettings(returnsSettings),
          language: {
            ...languageToInsert,
            ...language,
            ...dynamicLanguage,
            'returns.return_options.store.store_link':
              language['returns.return_methods.store.store_link'],
            'returns.return_options.store.store_link_text':
              language['returns.return_methods.store.store_link_text'],
          },
        },
        changeDetected: true,
        hasPreviewed: false,
      }),
    );
  }

  dispatch({
    type: UPDATE_LOCATIONS_MODAL,
    payload: {
      locationsModal: {
        show: false,
        title: '',
        mainText: '',
      },
    },
  });
};

export const selectLocationsUIOption = (selectedId, optionIndex) => (
  dispatch,
  getState,
) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings, language },
      returnsSettingsTemplate: {
        returns: {
          returnOptions: {
            options: templateOptions = [],
            methods: templateMethods = {},
          },
        },
      },
      languageTemplate,
      uiSchemaConfigs,
    },
  } = getState();
  const { languageKeys = [] } = uiSchemaConfigs.find(
    ({ id }) => id === selectedId,
  );
  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: { returnOptions: configToEdit },
  } = returnsSettings;

  const optionToDelete = configToEdit.options[optionIndex];
  const methodsToDelete = optionToDelete.methods;
  const optionToAdd = templateOptions.find(option => option.id === selectedId);
  const methodsToMergeIn = optionToAdd.methods.reduce(
    (acc, methodKey) => ({
      ...acc,
      [methodKey]: templateMethods[methodKey],
    }),
    {},
  );

  methodsToDelete.forEach(key => delete configToEdit.methods[key]);
  configToEdit.options[optionIndex] = optionToAdd;
  configToEdit.methods = {
    ...configToEdit.methods,
    ...methodsToMergeIn,
  };

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: configToEdit,
          },
        }),
        language: {
          ...language,
          ...languageKeys.reduce(
            (acc, key) => ({
              ...acc,
              [key]: languageTemplate[key],
            }),
            {},
          ),
        },
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const addLocationsUIOption = () => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: { returnOptions: configToEdit },
  } = returnsSettings;

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...configToEdit,
              options: [...configToEdit.options, { methods: [] }],
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const removeLocationsUIOption = index => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: { returnOptions: configToEdit },
  } = returnsSettings;

  const optionToDelete = configToEdit.options[index];
  const methodsToDelete = optionToDelete.methods;
  methodsToDelete.forEach(key => delete configToEdit.methods[key]);
  configToEdit.options.splice(index, 1);

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: configToEdit,
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const sortLocationsUIOption = (fromIndex, direction) => (
  dispatch,
  getState,
) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: {
      returnOptions: { options },
    },
  } = returnsSettings;
  const toIndex = direction === 'up' ? fromIndex - 1 : fromIndex + 1;
  const newOptions = swap(options, fromIndex, toIndex);

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              options: newOptions,
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const selectShippingMethod = ({
  cardIndex,
  selectedShippingMethod,
  checkbox,
  locationProviders,
}) => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings, language },
      returnsSettingsTemplate: {
        returns: {
          returnOptions: {
            methods: { [selectedShippingMethod]: newMethodTemplate },
          },
        },
      },
      languageTemplate,
      supportedReturnMethods: {
        [selectedShippingMethod]: { language: selectedMethodLanguageKeys = [] },
      },
      uiSchemaConfigs,
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: {
      returnOptions: { options, methods },
    },
  } = returnsSettings;
  const { id: methodId, methods: configuredMethods } = options[cardIndex];
  const { dynamicLanguageKeys = [] } = uiSchemaConfigs.find(
    ({ id }) => id === methodId,
  );

  let newMethods = [];

  if (checkbox) {
    newMethods = configuredMethods.includes(selectedShippingMethod)
      ? configuredMethods.filter(method => method !== selectedShippingMethod)
      : [...configuredMethods, selectedShippingMethod];
  } else {
    newMethods = [selectedShippingMethod];
  }

  const hasMultipleCarriers = newMethods.length > 1;

  options[cardIndex].methods = newMethods;
  options[cardIndex].displayMethods = hasMultipleCarriers;

  const methodLanguage = selectedMethodLanguageKeys.reduce(
    (acc, key) => ({ ...acc, [key]: languageTemplate[key] }),
    {},
  );

  const newDynamicLanguage = dynamicLanguageKeys.reduce(
    (acc, { renderKey, dynamicKey }) => {
      if (hasMultipleCarriers) {
        return {
          ...acc,
          [renderKey]: languageTemplate[renderKey],
        };
      }
      return {
        ...acc,
        [renderKey]:
          languageTemplate[
            dynamicKey.replace(
              INTERPOLATION_KEY,
              newMethods[newMethods.length - 1],
            )
          ],
      };
    },
    {},
  );

  // If the key is already set, do not override it.
  const updatedKeys = Object.fromEntries(
    Object.entries(newDynamicLanguage).map(([key, val]) =>
      form.language[key] ? [key, form.language[key]] : [key, val],
    ),
  );

  if (
    locationProviders[selectedShippingMethod] &&
    locationProviders[selectedShippingMethod].options &&
    newMethodTemplate.store_locator_query_params
  ) {
    let carrierIndex = -1;
    if (
      locationProviders[selectedShippingMethod].carriersToRemove &&
      locationProviders[selectedShippingMethod].carriersToRemove.length > 0
    ) {
      carrierIndex = newMethodTemplate.store_locator_query_params.findIndex(
        param =>
          param.key === 'carriers' &&
          param.value &&
          param.value.some(c =>
            locationProviders[selectedShippingMethod].carriersToRemove.includes(
              c,
            ),
          ),
      );
    }

    if (carrierIndex >= 0) {
      const brands = locationProviders[selectedShippingMethod].options.map(
        provider => provider.data.value,
      );
      locationProviders[selectedShippingMethod].carriersToRemove.forEach(c => {
        const i = newMethodTemplate.store_locator_query_params[
          carrierIndex
        ].value.indexOf(c);
        newMethodTemplate.store_locator_query_params[carrierIndex].value.splice(
          i,
          1,
        );
      });
      if (
        newMethodTemplate.store_locator_query_params[carrierIndex].value
          .length == 0
      ) {
        newMethodTemplate.store_locator_query_params.splice(carrierIndex, 1);
      }
      newMethodTemplate.store_locator_query_params.push({
        key: 'brands',
        value: brands,
      });
    }
  }

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              options,
              methods: {
                ...methods,
                [selectedShippingMethod]: newMethodTemplate,
              },
            },
          },
        }),
        language: {
          ...language,
          ...methodLanguage,
          ...updatedKeys,
        },
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const updateExchanges = exchangeFormEnabled => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();
  const returnsSettings = unflattenSettings(flatReturnsSettings);
  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            displayFormIfExchangeIncluded: exchangeFormEnabled,
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const updateLanguage = (key, value) => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { language },
    },
  } = getState();

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        language: {
          ...language,
          [key]: value,
        },
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const updateMethodSetting = ({
  inputId,
  selectedShippingMethod,
  checked,
}) => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const newMethods = {
    ...returnsSettings.returns.returnOptions.methods,
    [selectedShippingMethod]: {
      ...returnsSettings.returns.returnOptions.methods[selectedShippingMethod],
      [inputId]: checked,
    },
  };
  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              methods: newMethods,
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const updateStoreMethodSetting = storeLocatorEnabled => (
  dispatch,
  getState,
) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
    userReducer: {
      user: {
        retailerId: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: uriMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: {
      returnOptions: {
        methods: {
          store: {
            store_locator_query_params: storeLocatorQueryParams = [],
            ...configuredStoreMethods
          } = {},
        },
      },
    },
  } = returnsSettings;
  const newMethods = {
    ...returnsSettings.returns.returnOptions.methods,
    store: {
      ...returnsSettings.returns.returnOptions.methods.store,
      ...(storeLocatorEnabled
        ? {
            store_locator_radius: 50,
            placeholder_location: true,
            store_locator_query_params: [
              {
                value: [uriMoniker],
                key: 'providers',
              },
              {
                key: 'supportedactions',
                value: ['dropoff'],
              },
              ...storeLocatorQueryParams,
            ],
            ...configuredStoreMethods,
            store_locator: true,
          }
        : {
            ...configuredStoreMethods,
            store_locator_query_params: storeLocatorQueryParams,
            store_locator: false,
          }),
    },
  };
  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              methods: newMethods,
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const updateMultipleMethods = ({ inputId, value, methodKeys = [] }) => (
  dispatch,
  getState,
) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();
  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const newMethods = methodKeys.reduce(
    (acc, methodKey) => ({
      ...acc,
      [methodKey]: {
        ...acc[methodKey],
        [inputId]: value,
      },
    }),
    {
      ...returnsSettings.returns.returnOptions.methods,
    },
  );

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              methods: newMethods,
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const selectLocations = ({
  cardIndex,
  providersWithLocations,
  data: { key: paramKey, value: paramValue, method: methodKey },
}) => (dispatch, getState) => {
  const paramValueIsArray = Array.isArray(paramValue);
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
      uiSchemaConfigs,
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);
  const {
    returns: {
      returnOptions: {
        options: {
          [cardIndex]: { id, methods: configuredMethods },
        },
        methods,
      },
    },
  } = returnsSettings;

  const { inputs: schemaInputs, validationMessage } = uiSchemaConfigs.find(
    config => config.id === id,
  );

  const existingStoreLocatorParams =
    methods[methodKey].store_locator_query_params || [];
  let newStoreLocatorQueryParams = [];

  if (!existingStoreLocatorParams.length) {
    newStoreLocatorQueryParams = [
      {
        key: paramKey,
        value: paramValueIsArray ? paramValue : [paramValue],
      },
    ];
  } else {
    const paramKeyExists = existingStoreLocatorParams.find(
      ({ key }) => key === paramKey,
    );

    if (paramKeyExists && paramValueIsArray) {
      newStoreLocatorQueryParams = existingStoreLocatorParams
        .map(({ key: currentKey, value: currentValue }) => {
          if (currentKey !== paramKey)
            return { key: currentKey, value: currentValue };

          const isDeselectValue = paramValue.every(newValue =>
            currentValue.includes(newValue),
          );
          const updatedValue = isDeselectValue
            ? currentValue.filter(
                valueToKeep => !paramValue.includes(valueToKeep),
              )
            : Array.from(new Set([...currentValue, ...paramValue]));
          return {
            key: currentKey,
            value: updatedValue.filter(val => val),
          };
        })
        .filter(({ value }) => value.length > 0);
    } else if (paramKeyExists) {
      newStoreLocatorQueryParams = existingStoreLocatorParams
        .map(({ key: currentKey, value: currentValue }) => {
          if (currentKey !== paramKey)
            return { key: currentKey, value: currentValue };

          const isReset = !paramValue;
          const isDeselectValue = currentValue.includes(paramValue);
          const updatedValue = isDeselectValue
            ? currentValue.filter(valueToKeep => valueToKeep !== paramValue)
            : [...currentValue, paramValue];
          return {
            key: currentKey,
            value: isReset ? [] : updatedValue.filter(val => val),
          };
        })
        .filter(({ value }) => value.length > 0);
    } else {
      newStoreLocatorQueryParams = [
        ...existingStoreLocatorParams,
        {
          key: paramKey,
          value: paramValueIsArray ? paramValue : [paramValue],
        },
      ];
    }
  }

  const requiredParamKeys = schemaInputs
    .filter(({ locatorQueryParam }) => locatorQueryParam)
    .map(reqParam => reqParam.id);

  const locationsAreNotSelected = providersWithLocations
    ? newStoreLocatorQueryParams
        .filter(({ key }) => key === 'brands')
        .every(({ value }) => value.length === 0)
    : newStoreLocatorQueryParams
        .filter(({ key }) => requiredParamKeys.includes(key))
        .every(({ value }) => value.length === 0);

  dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              methods: {
                ...methods,
                [methodKey]: {
                  ...methods[methodKey],
                  store_locator_query_params: newStoreLocatorQueryParams,
                },
              },
            },
          },
        }),
        errors: {
          ...form.errors,
          [id]: locationsAreNotSelected ? validationMessage : '',
        },
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};

export const verifyRefundOptions = () => async dispatch => {
  try {
    const {
      response: { refund_options: configuredRefundOptions = [] } = {},
    } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: RETURNS_VERIFY_REFUND_OPTIONS,
      name: 'verifyRefundOptions',
    });
    return dispatch({
      type: RECEIVE_CONFIGURED_REFUND_OPTIONS,
      payload: {
        configuredRefundOptions,
      },
    });
  } catch (error) {
    throw new Error('There was an issue Verifying Refund Options.');
  }
};

export const toggleShowIndividualOptions = () => (dispatch, getState) => {
  const {
    returnsUIBuilderReducer: {
      form,
      form: { flatReturnsSettings },
    },
  } = getState();

  const returnsSettings = unflattenSettings(flatReturnsSettings);

  return dispatch(
    updateUIBuilderForm({
      form: {
        ...form,
        flatReturnsSettings: flattenSettings({
          ...returnsSettings,
          returns: {
            ...returnsSettings.returns,
            returnOptions: {
              ...returnsSettings.returns.returnOptions,
              show_individual_options: !returnsSettings.returns.returnOptions
                .show_individual_options,
            },
          },
        }),
      },
      changeDetected: true,
      hasPreviewed: false,
    }),
  );
};
