import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { Col, Row } from 'reactstrap';
import Svg from '../Svg';
import cx from 'classnames';
import s from './FileUploader.styles.js';

export class FileUploader extends Component {
  fileOnDrop = selectedFile => {
    const { file, fileKey, onDrop } = this.props;
    onDrop(selectedFile, file, fileKey);
  }

  fileOnDelete = event => {
    const { file, fileKey, onDelete } = this.props;
    onDelete(event, file, fileKey);
  }

  fileOnDownlod = event => {
    const { file, fileKey, onDownload } = this.props;
    onDownload(event, file, fileKey);
  }

  render() {
    const {
      file,
      onDownload,
      onDelete,
      placeholder,
      uploadBtnText,
      downloadBtnText,
    } = this.props;

    const validFile = Boolean(file && file.name && file.format);
    const fileNameText = validFile
      ? `${file.name}.${file.format.toLowerCase()}`
      : placeholder;

    return (
      <div className={s}>
        <Row className="line-row">
          <Col xs={12}>
            <div className="table-align">
              <div className={cx('table-align-cell', { muted: !validFile })}>
                <Svg
                  className="file-detail-icon"
                  name="add_attachment"
                  height={20}
                  width={20}
                  viewBox="5 0 20 20"
                />
              </div>
              <div className={cx('table-align-cell', { muted: !validFile })}>
                <div>
                  {fileNameText}
                </div>
              </div>
              <div className="table-align-cell right-cell">
                {onDelete && validFile && (
                  <div
                    className={cx('delete-icon', { muted: !validFile })}
                    onClick={this.fileOnDelete}
                  >
                    <Svg
                      name="delete"
                      disabled
                      height={20}
                      width={20}
                      viewBox="0 0 20 20"
                    />
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="line-row">
          <Col xs={12} md={6}>
            <div className="table-align">
              <div className="table-align-cell">
                <Svg
                  className="file-detail-icon"
                  name="upload"
                  height={20}
                  width={20}
                  viewBox="5 0 20 20"
                />
              </div>
              <div className="table-align-cell">
                <Dropzone
                  className="link"
                  onDrop={this.fileOnDrop}
                >
                  {uploadBtnText}
                </Dropzone>
              </div>
            </div>
          </Col>
          {onDownload && (
            <Col xs={12} md={6}>
              <div className={cx('table-align', { muted: !validFile })}>
                <div className="table-align-cell">
                  <Svg
                    className="file-detail-icon"
                    name="download"
                    height={20}
                    width={20}
                  />
                </div>
                <div className="table-align-cell">
                  <div
                    className="link"
                    onClick={this.fileOnDownlod}
                  >
                    {downloadBtnText}
                  </div>
                </div>
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

FileUploader.propTypes = {
  file: PropTypes.shape({
    name: PropTypes.string,
    format: PropTypes.string,
  }),
  fileKey: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
  uploadBtnText: PropTypes.string,
  downloadBtnText: PropTypes.string,
};

FileUploader.defaultProps = {
  file: null,
  onDelete: null,
  onDownload: null,
  fileKey: null,
  placeholder: 'No file added yet',
  uploadBtnText: 'Upload File',
  downloadBtnText: 'Download File',
};

export default FileUploader;
