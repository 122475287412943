import {
  RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE,
  RESET_UPLOADED_PORTABLE_CONFIG,
  RECEIVE_PACKING_SLIP_AND_LABEL_LIST,
  RECEIVE_PACKING_SLIP_LABEL_ERROR,
  RECEIVE_PACKING_SLIP_LABEL_SUCCESS,
  RECEIVE_PACKING_SLIP_LABEL_UPDATE,
  RECEIVE_PACKING_SLIP_LABEL_SAVE,
  RECEIVE_PACKING_SLIP_LABEL_PREVIEW,
  RECEIVE_PACKING_SLIP_LABEL_DETAILS,
  RECEIVE_PACKING_SLIP_LABEL_CONFIGS,
  RECEIVE_SAVE_ERRORS,
  RESET_FORM_VALUES_AND_ERRORS,
} from '~src/actions/packingSlipLabelActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const bifurcateTemplateVariables = (variables = []) => {
  variables.sort((a, b) => a.description.localeCompare(b.description));
  return variables.reduce(
    (accumulator, variable) => {
      accumulator[variable.custom ? 'custom' : 'standard'].push(variable);
      return accumulator;
    },
    { custom: [], standard: [] },
  );
};

export const initialState = {
  alert: {
    show: false,
  },
  previewUrl: '',
  returnConfigs: null,
  packingSlipLabelDetails: null,
  packingSlipLabelsList: [],
  uploadedPortableConfig: null,
  showUploadConfirmationModal: false,
};

function packingSlipLabelsReducer(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case RECEIVE_PORTABLE_CONFIG_UPLOAD_RESPONSE: {
      return {
        ...state,
        uploadedPortableConfig: payload.manifest,
        showUploadConfirmationModal: true,
      };
    }

    case RESET_UPLOADED_PORTABLE_CONFIG:
      return {
        ...state,
        uploadedPortableConfig: initialState.uploadedPortableConfig,
        showUploadConfirmationModal: initialState.showUploadConfirmationModal,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_SAVE_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: 'Please fix all errors before you hit save.',
        },
      };

    case RECEIVE_PACKING_SLIP_LABEL_DETAILS:
      const templateConfig = payload.template_config;
      if (payload.updateConfig === false) {
        delete payload.template_config;
      }
      return {
        ...state,
        isFetching: false,
        templateConfig,
        packingSlipLabelDetails: {
          ...payload,
          packing_slip_html: payload.packing_slip_html,
          shipping_label_html: payload.shipping_label_html,
        },
      };

    case RECEIVE_PACKING_SLIP_LABEL_CONFIGS: {
      const {
        order_variables: orderVariables = [],
        item_variables: itemVariables = [],
        shipment_variables: shipmentVariables = [],
      } = payload;

      const {
        standard: standardOrderVariables,
        custom: customOrderVariables,
      } = bifurcateTemplateVariables(orderVariables);

      const {
        standard: standardItemVariables,
        custom: customItemVariables,
      } = bifurcateTemplateVariables(itemVariables);

      const {
        standard: standardShipmentVariables,
        custom: customShipmentVariables,
      } = bifurcateTemplateVariables(shipmentVariables);

      return {
        ...state,
        isFetching: false,
        returnConfigs: {
          ...payload,
          carriers: payload.carriers.map(carrier => ({
            key: carrier.carrier_moniker,
            description: carrier.carrier_name,
            ...carrier,
          })),
          order_variables: standardOrderVariables,
          order_variables_custom: customOrderVariables,
          item_variables: standardItemVariables,
          item_variables_custom: customItemVariables,
          shipment_variables: standardShipmentVariables,
          shipment_variables_custom: customShipmentVariables,
          format: [
            {
              type: 'text',
              description: 'Text',
            },
            {
              type: 'barcode',
              description: 'Barcode',
            },
          ],
        },
      };
    }

    case RECEIVE_PACKING_SLIP_AND_LABEL_LIST:
      return {
        ...state,
        isFetching: false,
        packingSlipLabelsList: [...payload],
      };

    case RECEIVE_PACKING_SLIP_LABEL_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case RECEIVE_PACKING_SLIP_LABEL_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: action.messages,
        },
      };

    case RECEIVE_PACKING_SLIP_LABEL_PREVIEW:
      return {
        ...state,
        previewUrl: payload,
      };

    case RECEIVE_PACKING_SLIP_LABEL_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Shipping Label updated.',
        },
        packingSlipLabelDetails: {
          ...payload,
        },
      };

    case RECEIVE_PACKING_SLIP_LABEL_SAVE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Packing Slip & Shipping Label changes saved',
        },
        packingSlipLabelDetails: {
          ...payload,
        },
      };

    case RESET_FORM_VALUES_AND_ERRORS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default packingSlipLabelsReducer;
