import { invalidateCacheTable } from '~src/actions/cacheActions';
import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';
import moment from 'moment';
import { getCardDiff } from '~src/containers/Notify/Settings';

const getNotifySettingsFromRetailerInfo = retailerInfoSettings => {
  const {
    features: {
      fb_messenger: {
        page_id: pageId,
        // enabled,
        custom_message: customMessage = '',
        use_retailer_page: useRetailerPage = false,
        response_type: responseType = 'default',
        response_frequency: responseFrequency = 'single',
        item_visibility_image_aspect_ratio: imageAspectRatio = 'horizontal',
        item_visibility_proactive: itemVisibilityProactive = false,
        cold_start_message_enabled: coldStartMessageEnabled = false,
      } = {},
      item_visibility: { enabled: itemVisibilityEnabled = false } = {},
    } = {},
  } = retailerInfoSettings;
  return {
    facebook: {
      page_id: pageId,
      // enabled,
      custom_message: customMessage,
      use_retailer_page: useRetailerPage,
      response_type: responseType,
      response_frequency: responseFrequency,
      item_visibility_image_aspect_ratio: imageAspectRatio,
      item_visibility_proactive: itemVisibilityProactive,
      item_visibility_enabled: itemVisibilityEnabled,
      cold_start_message_enabled: coldStartMessageEnabled,
    },
    retailerInfoSettingsJson: retailerInfoSettings,
  };
};

const findItemInList = (type, list) =>
  list.find(element => element.type === type);

const replaceIfUndefinedOrEmpty = (value, defaultValue) => {
  switch (typeof value) {
    case 'undefined':
      return defaultValue;
    case 'string':
      return value.length > 0 ? value : defaultValue;
    default:
      return value;
  }
};

export const RECEIVE_SETTINGS_FACEBOOK = 'NOTIFY/RECEIVE_SETTINGS_FACEBOOK';
export const RECEIVE_VALIDATION_FACEBOOK = 'NOTIFY/RECEIVE_VALIDATION_FACEBOOK';
export const RECEIVE_SETTINGS_MESSAGE_SERVICE =
  'NOTIFY/RECEIVE_SETTINGS_MESSAGE_SERVICE';

export const RECEIVE_RETAILER_INFO_SETTINGS_JSON =
  'NOTIFY/RECEIVE_RETAILER_INFO_SETTINGS_JSON';
export const RECEIVE_CURRENT_LOCALE = 'NOTIFY/RECIEVE_CURRENT_LOCALE';
export const RECEIVE_SETTINGS_DATE_MODIFIED =
  'NOTIFY/RECEIVE_SETTINGS_DATE_MODIFIED';

export const UPDATE_SETTINGS_STORE = 'NOTIFY/UPDATE_SETTINGS_STORE';
export const RECEIVE_SETTINGS_ERROR = 'NOTIFY/RECEIVE_SETTINGS_ERROR';
export const RECEIVE_SETTINGS_SAVE = 'NOTIFY/RECEIVE_SETTINGS_SAVE';
export const RESET_SETTINGS = 'NOTIFY/RESET_SETTINGS';
export const TOGGLE_SETTINGS_CONFIRM_MODAL =
  'NOTIFY/TOGGLE_SETTINGS_CONFIRM_MODAL';

export const RECEIVE_EMAIL_CONFIGS = 'NOTIFY/RECEIVE_EMAIL_CONFIGS';

export const receiveFacebookSettings = payload => ({
  type: RECEIVE_SETTINGS_FACEBOOK,
  payload,
});
export const receiveFacebookValidation = payload => ({
  type: RECEIVE_VALIDATION_FACEBOOK,
  payload,
});
export const receiveMessageServiceSettings = payload => ({
  type: RECEIVE_SETTINGS_MESSAGE_SERVICE,
  payload,
});

export const receiveRetailerInfoSettingsJson = payload => ({
  type: RECEIVE_RETAILER_INFO_SETTINGS_JSON,
  payload,
});
export const receiveCurrentLocale = payload => ({
  type: RECEIVE_CURRENT_LOCALE,
  payload,
});
export const receiveDateModified = payload => ({
  type: RECEIVE_SETTINGS_DATE_MODIFIED,
  payload,
});
export const updateSettingsStore = payload => ({
  type: UPDATE_SETTINGS_STORE,
  payload,
});
export const receiveNotifySettingsError = payload => ({
  type: RECEIVE_SETTINGS_ERROR,
  payload,
});
export const receiveNotifySettingsSave = payload => ({
  type: RECEIVE_SETTINGS_SAVE,
  payload,
});
export const resetFormAndErrors = () => ({
  type: RESET_SETTINGS,
});
export const toggleConfirmModal = payload => ({
  type: TOGGLE_SETTINGS_CONFIRM_MODAL,
  payload,
});
export const receiveEmailConfigs = payload => ({
  type: RECEIVE_EMAIL_CONFIGS,
  payload,
});

export const fetchFacebookSettings = () => async dispatch => {
  const settings = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.NOTIFY_FACEBOOK,
    name: 'facebook',
  });

  await dispatch(receiveFacebookSettings(settings));
};

export const validateFacebook = accessToken => async dispatch => {
  try {
    const json = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify({
        access_token: `${accessToken}`,
      }),
      path: ENDPOINTS.CONNECT_VALIDATE_FACEBOOK,
      name: 'facebook',
    });
    dispatch(receiveFacebookValidation(json));
  } catch (err) {
    if (err.clientMessage) {
      throw new Error(err.clientMessage);
    } else {
      const errorDetails = JSON.parse(err.errorMessage);
      if (errorDetails.error.code === 100) {
        throw new Error('This Facebook Access Token is for a different app.');
      } else {
        throw new Error('Your Facebook Access Token is not valid.');
      }
    }
  }
};

export const validateTwilioService = serviceId => async dispatch => {
  try {
    const isValid = await dispatch({
      type: API_REQUEST,
      method: 'POST',
      path: ENDPOINTS.CONNECT_VALIDATE_MESSAGE_SERVICE,
      body: JSON.stringify({
        service_id: `${serviceId}`,
      }),
      name: 'messageService',
    });
    return isValid;
  } catch (err) {
    if (err.clientMessage) {
      throw new Error(err.clientMessage);
    } else {
      throw new Error('Your Twilio service ID is not valid.');
    }
  }
};

export const fetchMessageServiceSettings = () => async dispatch => {
  const settings = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.NOTIFY_MESSAGE_SERVICE,
    name: 'messageService',
  });
  await dispatch(receiveMessageServiceSettings(settings));
};

export const fetchRetailerInfoSettings = () => async (dispatch, getState) => {
  const json = await dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.CSM_TRACKING_ASSETS_SAVE,
    name: 'retailerInfoSettings',
  });
  try {
    if (json.status_code === 200) {
      const {
        notifySettingsReducer: {
          facebook: {
            responses_list: responsesList,
            response_frequency_list: responseFrequencyList,
            item_visibility_image_aspect_ratio_list: aspectRatioList,
          } = {},
          email: { domain_list: domainList } = {},
        } = {},
      } = getState();
      const parsedRetailerSettings = JSON.parse(json.retailer_settings);
      const updatedSettings = getNotifySettingsFromRetailerInfo(
        parsedRetailerSettings,
      );
      updatedSettings.date_modified = json.date_modified;
      const {
        facebook: {
          response_frequency: responseFrequency = 'single',
          response_type: responseType = 'default',
          item_visibility_image_aspect_ratio: imageAspectRatio,
        } = {},
        email: { domain_type: domainSelected = 'narvar' } = {},
      } = updatedSettings;

      const selectedAspectRatio = findItemInList(
        imageAspectRatio,
        aspectRatioList,
      );

      const selectedResponseType = findItemInList(responseType, responsesList);
      const selectedFrequencyType = findItemInList(
        responseFrequency,
        responseFrequencyList,
      );
      const facebook = {
        responses_list_selected: selectedResponseType,
        response_frequency_list_selected: selectedFrequencyType,
        item_visibility_image_aspect_ratio_list_selected: selectedAspectRatio,
      };
      updatedSettings.facebook = {
        ...updatedSettings.facebook,
        ...facebook,
      };

      const selectedDomainType = findItemInList(domainSelected, domainList);
      const email = {
        domain_list_selected: selectedDomainType,
        domain_list: domainList,
      };
      updatedSettings.email = {
        ...updatedSettings.email,
        ...email,
      };

      delete updatedSettings.facebook.response_frequency;
      delete updatedSettings.facebook.response_type;
      delete updatedSettings.email.domain_type;
      dispatch(receiveRetailerInfoSettingsJson(updatedSettings));
      const locale = json.configuration_details.condition.find(
        elem => elem.label.toLowerCase() === 'locale',
      );
      dispatch(receiveCurrentLocale(locale.selected));
    } else {
      throw new Error(json.errors || 'unknown error');
    }
  } catch (err) {
    dispatch(
      receiveNotifySettingsError(
        `There was an issue loading the retailer info settings: ${err.message}`,
      ),
    );
  }
};

export const saveFacebookRetailerInfoSettingsJson = (
  retailerInfoSettingsJson,
  newSettings,
) => async (dispatch, getState) => {
  const {
    page_id: pageId,
    app_id: appId,
    // enabled,
    custom_message: customMessage,
    use_retailer_page: useRetailerPage,
    response_type: responseType,
    response_frequency: responseFrequency,
    item_visibility_proactive: itemVisibilityProactive,
    image_aspect_ratio: imageAspectRatio,
    cold_start_message_enabled: coldStartMessageEnabled,
  } = newSettings;

  const {
    features: { fb_messenger: fbMessenger = {} } = {},
  } = retailerInfoSettingsJson;

  const updatedSettings = {
    ...retailerInfoSettingsJson,
    features: {
      ...retailerInfoSettingsJson.features,
      fb_messenger: {
        ...fbMessenger,
        page_id: pageId,
        app_id: appId,
        use_retailer_page: replaceIfUndefinedOrEmpty(useRetailerPage, false),
        item_visibility_proactive: itemVisibilityProactive,
        item_visibility_image_aspect_ratio: imageAspectRatio,
        cold_start_message_enabled: coldStartMessageEnabled,
        // enabled: replaceIfUndefinedOrEmpty(enabled, false),
      },
    },
  };
  const { features } = updatedSettings;

  if (useRetailerPage) {
    features.fb_messenger = {
      ...features.fb_messenger,
      response_type: replaceIfUndefinedOrEmpty(responseType, 'default'),
    };
    if (responseType === 'custom') {
      features.fb_messenger = {
        ...features.fb_messenger,
        response_frequency: replaceIfUndefinedOrEmpty(
          responseFrequency,
          'single',
        ),
        custom_message: replaceIfUndefinedOrEmpty(customMessage, ''),
      };
    }
  }
  const {
    notifySettingsReducer: {
      currentLocale: locale,
      _dateModified: {
        retailerInfoSettings: { date_modified: dateModified = '' } = {},
      } = {},
    } = {},
    userReducer: {
      user: {
        current_retailer_id: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: uriMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = getState();

  const stringInfoJson = JSON.stringify({
    retailer_settings: JSON.stringify(updatedSettings),
  });
  await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${ENDPOINTS.CSM_TRACKING_ASSETS_SAVE}?locale=${locale}`,
    body: stringInfoJson,
    name: 'facebook',
  });
  const publishPath = `${ENDPOINTS.CSM_TRACKING_ASSETS_PUBLISH}?locale=${locale}`;
  const json = await dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: publishPath,
    body: JSON.stringify({ date_modified: dateModified }),
    name: 'facebook',
  });
  if (json.status === 'FAILURE') {
    throw new Error(json.response || 'unknown error');
  } else if (json.errors && json.errors.length > 0) {
    throw new Error(JSON.stringify(json.errors));
  } else {
    dispatch(receiveDateModified(json.date_modified));
    dispatch({
      type: API_INVALIDATE_PATH,
      path: ENDPOINTS.CSM_TRACKING_ASSETS_SAVE,
    });
    dispatch({
      type: API_INVALIDATE_PATH,
      path: publishPath,
    });
    dispatch(
      invalidateCacheTable({
        type: 'update',
        table: 'retailer_info',
        primary_key: 'uri_moniker',
        key_value: uriMoniker,
      }),
    );
  }
};

export const saveFacebookRetailerInfo = (pageId, fbAccessToken) => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    body: JSON.stringify({
      access_token: fbAccessToken,
      page_id: pageId,
      date_modified: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
    }),
    path: ENDPOINTS.NOTIFY_FACEBOOK,
    name: 'facebook',
  });

export const fetchSaveFacebookSettings = (
  fbAccessToken,
  retailerInfoSettingsParams,
) => dispatch => {
  const { retailerSettings, newSettings } = retailerInfoSettingsParams;

  const dispatchArr = [];
  dispatchArr.push(
    dispatch(
      saveFacebookRetailerInfoSettingsJson(retailerSettings, newSettings),
    ),
  );
  const { page_id: pageId } = newSettings;

  dispatchArr.push(dispatch(saveFacebookRetailerInfo(pageId, fbAccessToken)));
  return Promise.all(dispatchArr);
};

export const fetchSaveMessageServiceSettings = messageServiceId => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    body: JSON.stringify({
      service_id: messageServiceId,
      date_modified: moment().format('YYYY-MM-DD HH:mm:ss.SSS'),
    }),
    path: ENDPOINTS.NOTIFY_MESSAGE_SERVICE,
    name: 'messageService',
  });

export const fetchEmailChannelConfigs = () => async dispatch => {
  try {
    const settings = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL,
      name: 'emailChannel',
    });
    dispatch(receiveEmailConfigs(settings));
    return settings;
  } catch (err) {
    dispatch(receiveNotifySettingsError(err.message));
    return err;
  }
};

export const fetchNotifySettings = hasAlerts => async dispatch => {
  try {
    if (hasAlerts) {
      dispatch(fetchEmailChannelConfigs());
    }
    await dispatch(fetchFacebookSettings());
    dispatch(fetchMessageServiceSettings());
  } catch (err) {
    dispatch(
      receiveNotifySettingsError(
        `There was an issue loading the Notify settings: ${err.message}`,
      ),
    );
  }
};

export const validateNotifySettings = (authToken, messageServiceId) => async (
  dispatch,
  getState,
) => {
  const { notifySettingsReducer: state } = getState();
  const validateFb = Object.keys(getCardDiff(state, 'facebook')).length > 0;
  const messageServiceDiff =
    Object.keys(getCardDiff(state, 'messageService')).length > 0;
  const validateMessageService =
    messageServiceDiff && messageServiceId.length > 0;

  try {
    if (validateFb) {
      await dispatch(validateFacebook(authToken));
    }
    if (validateMessageService) {
      await dispatch(validateTwilioService(messageServiceId));
    }
    dispatch(toggleConfirmModal());
  } catch (err) {
    dispatch(receiveNotifySettingsError(err.message || err.errorMessage));
  }
};

export const saveSenderAttributes = opts => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(opts),
      path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL_SENDER,
      name: 'emailChannel',
    })
      .then(() =>
        dispatch({
          type: API_INVALIDATE_PATH,
          path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL_SENDER,
        }),
      )
      .then(resolve, reject);
  });

export const fetchSaveNotifySettings = (
  fbAccessToken,
  messageServiceId,
  retailerInfoSettingsParams,
) => async (dispatch, getState) => {
  const { notifySettingsReducer: state } = getState();

  const saveFb = Object.keys(getCardDiff(state, 'facebook')).length > 0;
  const saveMessageService =
    Object.keys(getCardDiff(state, 'messageService')).length > 0;
  const emailDiff = getCardDiff(state, 'email');
  const saveEmail = Object.keys(emailDiff).length > 0;
  try {
    if (saveEmail) {
      await dispatch(
        saveSenderAttributes({
          reply_to_email: state.email.reply_to_email,
          sender_email: state.email.sender_email,
          sender_name: state.email.sender_name,
        }),
      );
    }
    if (saveFb) {
      await dispatch(
        fetchSaveFacebookSettings(fbAccessToken, retailerInfoSettingsParams),
      );
    }
    if (saveMessageService) {
      await dispatch(fetchSaveMessageServiceSettings(messageServiceId));
    }
    dispatch(receiveNotifySettingsSave());
  } catch (err) {
    const {
      details: { message: detMessage } = {},
      message,
      errorMessage,
      clientMessage,
    } = err;
    dispatch(
      receiveNotifySettingsError(
        `There was an error while saving your settings: ${clientMessage ||
          detMessage ||
          message ||
          errorMessage}`,
      ),
    );
  }
};

export const createSubaccount = name => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify({ name }),
      path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL_CREATE_GENERATE,
      name: 'emailChannel',
    })
      .then(() =>
        dispatch({
          type: API_INVALIDATE_PATH,
          path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL,
        }),
      )
      .then(() => dispatch(fetchEmailChannelConfigs()))
      .then(resolve, reject);
  });

export const generateDkim = opts => dispatch =>
  new Promise((resolve, reject) => {
    dispatch({
      type: API_REQUEST,
      method: 'POST',
      body: JSON.stringify(opts),
      path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL_CREATE_GENERATE,
      name: 'emailChannel',
    })
      .then(() =>
        dispatch({
          type: API_INVALIDATE_PATH,
          path: ENDPOINTS.NOTIFY_EMAIL_CHANNEL,
        }),
      )
      .then(() => dispatch(fetchEmailChannelConfigs()))
      .catch(e => {
        const { clientMessage, errorMessage } = e;
        const error = clientMessage || errorMessage;
        dispatch(receiveNotifySettingsError(error));
        return reject(error);
      });
  });
