import React from 'react';
import PropTypes from 'prop-types';
import HumLayout from '@narvar/hum-layout';
import { Route } from 'react-router';
import useZendesk from '../../hooks/useZendesk';

const Layout = ({ component, path, exact }) => {
  useZendesk(path);

  return (
    <HumLayout component={component} exact={exact} path={path} route={Route} />
  );
};

Layout.defaultProps = {
  path: undefined,
  exact: false,
};

Layout.propTypes = {
  component: PropTypes.element.isRequired,
  path: PropTypes.string,
  exact: PropTypes.bool,
};

export default Layout;
