import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

const tabsStyle = {
  textAlign: 'center',
  background: '#fff',
  marginBottom: '25px',
};

const itemStyle = {
  marginTop: '15px',
  paddingBottom: '15px',
  color: '#848383',
  fontWeight: 600,
  display: 'block',
  cursor: 'pointer',
};

const activeStyle = {
  borderBottom: '2px solid #000',
  marginTop: '15px',
  paddingBottom: '15px',
  fontWeight: '600',
  display: 'block',
  color: '#060707',
};

const Tabs = ({ tabs }) => {
  return (
    <div style={tabsStyle}>
      <Container>
        <Row>
          {tabs.map(({ title, path, active }, index) => (
            <Col key={`${index}-${path}`}>
              <Link style={active ? activeStyle : itemStyle} to={path}>
                {title}
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

Tabs.defaultProps = {
  tabs: [],
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object),
};
export default Tabs;
