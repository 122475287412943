import { combineReducers } from 'redux';
import { API_DATE_FORMAT } from '../constants/dateFormats';
import moment from 'moment';
import { RETURNS } from '~src/constants/reports';
import { RECEIVE_RETURNS_ANALYTICS } from '~src/actions/returnsAnalyticsActions';

const reports = {};

Object.keys(RETURNS).forEach(name => {
  reports[name] = {};
});

const originalState = {
  reports,
  dateRange: {
    startDate: moment()
      .subtract(30, 'days')
      .format(API_DATE_FORMAT),
    endDate: moment()
      .subtract(1, 'days')
      .format(API_DATE_FORMAT),
  },
};

function returnsAnalyticsReducer(state = originalState, action) {
  const { type, meta, payload, error } = action;

  switch (type) {
    case RECEIVE_RETURNS_ANALYTICS:
      return {
        ...state,
        reports: {
          ...state.reports,
          ...payload,
        },
      };

    default:
      return state;
  }
}

export default returnsAnalyticsReducer;
