import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Container, Row, Col } from 'reactstrap';
import Loader from '../Loader';
import Svg from '../Svg';
import Tooltip from '../Tooltip';
import s from './TopBar.styles';

export class TopBar extends Component {
  constructor(props) {
    super(props);

    const isTooltipOpen = {};

    props.sections.forEach((section, index) => {
      isTooltipOpen[index] = false;
    });

    this.state = {
      isTooltipOpen,
    };
  }

  onToggleTooltip = tooltipKey =>
    () => {
      this.setState({
        isTooltipOpen: {
          ...this.state.isTooltipOpen,
          [tooltipKey]: !this.state.isTooltipOpen[tooltipKey],
        },
      });
    };

  render() {
    const { isFetching, sections } = this.props;

    return (
      <div className={s}>
        <Container>
          {isFetching
            ? (
              <Row>
                <Loader className="card-loader" show={isFetching} />
              </Row>
            ) : (
              <Row>
                {sections.map((section, index) => (
                  <Col md={12 / sections.length} key={index}>
                    {section.title}
                    <Svg
                      id={`tooltip-${index}`}
                      className={cx('icon-section', 'icon')}
                      name="info"
                      width={32}
                      height={32}
                    />
                    <Tooltip
                      isOpen={this.state.isTooltipOpen[index]}
                      placement="top"
                      target={`tooltip-${index}`}
                      toggle={this.onToggleTooltip(index)}
                    >
                      {section.tooltip}
                    </Tooltip>
                    <h1
                      data-test={`tob-bar-${String(index).toLowerCase()}`}
                      className="display-1"
                    >
                      {section.statistic}
                    </h1>
                  </Col>
                ))}
              </Row>
            )}
        </Container>
      </div>
    );
  }
}

TopBar.defaultProps = {
  isFetching: true,
};

TopBar.propTypes = {
  isFetching: PropTypes.bool,
  sections: PropTypes.array,
};

export default TopBar;
