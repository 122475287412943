import { REPORT_ID } from '~src/constants/exceptionsAnalyticsApis';

const reportIdsWithoutDimensions = [
  REPORT_ID.EXCEPTIONS_TOP_ROW_SINGLE_QUERY,
  REPORT_ID.NOTIFICATION_CLICKTHROUGH,
  REPORT_ID.DELIVERED_NDRS,
  REPORT_ID.ONTIME_DELIVERY_BREAKDOWN,
  REPORT_ID.CTR_BREAKDOWN,
  REPORT_ID.NDR_RESPONSE_PERCENTAGE,
];
const reportIdsWithDimensions = [
  REPORT_ID.DELIVERY_RATES,
  REPORT_ID.NDR_DELIVERY_PERCENTAGE,
  REPORT_ID.MEDIAN_NDR_RESPONSE_TIME,
];

export default (reportId, response = {}) => {
  const { exceptions_dashboard: exceptionsDashboardData = [] } = response;

  let carrierData;
  const totalCarrierData = {};
  if (reportIdsWithoutDimensions.includes(reportId)) {
    carrierData = exceptionsDashboardData.reduce((acc, currentData) => {
      const {
        metrics: metricsData = {},
        dimensions: { carrier_moniker: carrierMoniker = '' } = {},
      } = currentData;

      const dashboardData = {};

      acc[carrierMoniker] = {};
      Object.keys(metricsData).forEach(k => {
        if (k !== 'carrier_moniker') {
          dashboardData[k] = metricsData[k] || 0;
          totalCarrierData[k] = totalCarrierData[k]
            ? totalCarrierData[k] + (metricsData[k] || 0)
            : metricsData[k] || 0;
        }
      });

      acc[carrierMoniker] = dashboardData;
      return acc;
    }, {});
  } else if (reportIdsWithDimensions.includes(reportId)) {
    carrierData = exceptionsDashboardData.reduce((acc, currentData) => {
      const {
        metrics = {},
        dimensions: { carrier_moniker: carrierMoniker = '', day, type } = {},
      } = currentData;

      if (!acc[carrierMoniker]) {
        acc[carrierMoniker] = {};
      }

      delete metrics.carrier_moniker;
      delete metrics.day;
      delete metrics.type;

      if (day) {
        acc[carrierMoniker][day] = metrics;
      } else if (type) {
        acc[carrierMoniker][type] = metrics;
      }

      return acc;
    }, {});
  }

  return { [reportId]: carrierData };
};
