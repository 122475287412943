import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
  height: 100%;
  width: 100%;

  & + button {
    margin-left: 10px;
  }

  & .Select {
    height: 100%;
    width: 100%;

    & button + button {
      margin: 0;
    }

    & .btn {
      border-color: ${colors.gray};
      color: ${colors.dark1};
      font-family: ${fonts.regular};
      border-radius: 2px;
      display: inline-flex;
      align-items: center;
      height: 100%;
      min-height: 40px;
      min-width: 100px;
      text-align: left;
      text-transform: none;
      width: 100%;

      &:hover {
        border-color: ${colors.dark4};
      }
    }

    & .dropdown-toggle span {
      flex: 1;
    }

    & .dropdown-toggle.btn.btn-primary:after {
      color: ${colors.blue};
    }

    & .btn:disabled {
      @extend .btn;
      opacity: 1;
    }

    & .dropdown-toggle {
      background-color: ${colors.white};
    }

    & .dropdown-toggle {
      &:after {
        color: ${colors.black};
        opacity: ${colors.iconMute};
        margin: auto 0 auto auto;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }

      &:active {
        &:after {
          opacity: 0.8;
        }
      }
    }

    & .dropdown-toggle:disabled:after {
      display: none;
    }

    & .selected-close-container {
      &.dropdown-toggle:after {
        color: transparent;
      }
    }

    & .close {
      position: absolute;
      right: -5px;
    }

    & .btn-secondary.active {
      background-color: ${colors.white};
    }

    &.show {
      & .dropdown-menu {
        border: 1px solid ${colors.gray};
        display: flex;
        flex-direction: column;
        max-height: calc((30px * 4.7) + 50px);
        min-height: 60px;
        max-width: 400px;
        min-width: 200px;
        overflow-y: scroll;
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .dropdown-menu {
      border: none;
      min-width: 200px;
      padding: 10px 0px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }

    & .dropdown-menu:before {
      position: absolute;
      top: -7px;
      left: 9px;
      display: inline-block;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #ccc;
      border-left: 7px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      content: '';
    }

    & .dropdown-menu:after {
      position: absolute;
      top: -6px;
      left: 10px;
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #ffffff;
      border-left: 6px solid transparent;
      content: '';
    }

    & .dropdown-menu.dropdown-menu-right:before {
      @extend .dropdown-menu:before;
      left: 135px;
    }

    & .dropdown-menu.dropdown-menu-right:after {
      @extend .dropdown-menu:after;
      left: 136px;
    }

    & .select-item {
      background: ${colors.white};
      border-width: 0 0 0 2px;
      border-color: transparent;
      border-radius: 0;
      box-shadow: none;
      color: ${colors.dark2};
      line-height: 2.86rem;
      padding: 0 20px;
      transition: background-color 100ms;

      &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.dark1};
      }

      &:active {
        background-color: ${colors.blue};
        color: ${colors.white};

        & .description {
          color: ${colors.white};
        }
      }

      &.active {
        border-left-color: ${colors.brandPrimary};
        background-color: ${colors.grayLight};
        color: ${colors.dark1};
      }

      &.disabled:not(.active) {
        color: ${colors.dark3};

        &:active {
          border-left-color: ${colors.white};
          background-color: ${colors.white};
          color: ${colors.dark3};
        }

        &:hover {
          border-left-color: ${colors.white};
          color: ${colors.dark3};
          cursor: default;
        }
      }

      & > span {
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        min-width: 0;
        white-space: nowrap;
      }

      & .description {
        margin: auto 10px auto 5px;
        color: ${colors.darkGray};
        font-size: 0.875rem;
      }
    }

    & .selected {
      margin-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 95%;
    }

    &:focus {
      box-shadow: inset 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
      border-color: ${colors.dark4};
    }

    &::-webkit-input-placeholder {
      color: ${colors.dark4};
      font-family: ${fonts.regular};
    }
  }
`;

export default s;
