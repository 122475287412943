import {
  RECEIVE_CARRIER_ALIASES,
  RECEIVE_CARRIER_ALIAS_DATA,
  RECEIVE_NEW_CARRIER_ALIAS_DATA,
  CLEAR_CARRIER_ALIAS_ALERTS,
  UPSERT_CARRIER_ALIAS_ERROR,
  UPSERT_CARRIER_ALIAS_SUCCESS,
} from '~src/actions/carrierAliasActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  alias_list: [],
  alias_details: {
    selected_carrier: null,
    carrier_alias: [],
  },
};

const handleListData = alias =>
  Object.keys(alias).map(carrier => ({
    text: [carrier, alias[carrier].join(', ')],
    row_id: carrier,
  }));

function carrierAlias(state = initialState, action) {
  const { payload, type } = action;
  switch (type) {
    case RECEIVE_CARRIER_ALIASES:
      return {
        ...state,
        alias_list: handleListData(payload.response.carrier_alias),
      };
    case RECEIVE_CARRIER_ALIAS_DATA:
      return {
        ...state,
        alias_details: {
          selected_carrier: payload.response.carrier,
          carrier_alias: payload.response.carrier_alias,
        },
      };
    case RECEIVE_NEW_CARRIER_ALIAS_DATA:
      return {
        ...state,
        alias_details: {
          selected_carrier: null,
          carrier_alias: [],
        },
      };
    case UPSERT_CARRIER_ALIAS_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };
    case UPSERT_CARRIER_ALIAS_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes will be published within 15 minutes.',
        },
      };
    case DISMISS_ALERT:
    case CLEAR_CARRIER_ALIAS_ALERTS:
      return {
        ...state,
        alert: initialState.alert,
      };
    default:
      return state;
  }
}
export default carrierAlias;
