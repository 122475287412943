export const ACTIONS_COPY = {
  FETCH_COMPONENTS_FAILED: 'Unable to fetch Components',
  FETCH_TEMPLATES_FAILED: 'Unable to fetch Templates',
  FETCH_BLOCKS_FAILED: 'Unable to fetch Blocks',
  FETCH_SETTINGS_FAILED: 'Unable to fetch Settings',
  FETCH_BLOCK_FAILED: 'Unable to fetch block',
  FETCH_TEMPLATE_FAILED: 'Unable to fetch template',
  FETCH_AFFECTED_TEMPLATES_FAILED: 'Unable to fetch affected templates',
  FETCH_PUBLISHED_TEMPLATES_FAILED: 'Unable to fetch published templates',
  SAVE_SETTINGS_SUCCESS: 'Settings saved successfully',
  CREATE_BLOCK_SUCCESS: 'New Block created successfully',
  SAVE_BLOCK_SUCCESS: 'Block saved successfully',
  SAVE_TEMPLATE_SUCCESS: 'Template saved successfully',
  SAVE_SETTINGS_ERROR: 'Error saving Settings',
  SAVE_BLOCK_ERROR: 'Error saving Block',
  SAVE_TEMPLATE_ERROR: 'Error saving Templates',
  CREATE_BLOCK_ERROR: 'Error creating new Block',
  FETCH_TEST_DATA_FAILED: 'Unable to fetch test data',
  FETCH_PREVIEW_FAILED: 'Unable to preview template',
  PUBLISH_SUCCESS: 'Template Published',
  PUBLISH_FAILED:
    'Unable to publish due to a syntax error in the template. Please resolve the error and try again.',
  UNPUBLISH_SUCCESS: 'Template UnPublished',
  UNPUBLISH_FAILED: 'Unable to UnPublish template',
  TEST_EMAIL_SUCCESS: 'Test email sent successfully',
  TEST_EMAIL_FAILED:
    'Unable to send test email due to a syntax error in the template. Please resolve the error and try again.',
  SAVE_TESTDATA_SUCCESS: 'Test data created successfully',
  SAVE_TESTDATA_ERROR: 'Unable to create test data',
  DELETE_TESTDATA_SUCCESS: 'Test data deleted successfully',
  DELETE_TESTDATA_ERROR: 'Unable to delete test data',
  INVALID_RECIPIENT_EMAIL: 'Error: invalid recipient address(es)',
  FETCH_CHANNELS_FAILED: 'Unable to fetch channels',
  FETCH_SAVE_RNP_DATA: 'Unable to save Messaging Settings',
  INITIATE_MESSAGING_ON_BOARDING_FAILED:
    'failed to initiate messaging auto onbaording',
};

export const BLOCKS_LIST = {
  actions: {
    previewButton: 'Preview',
    saveButton: 'Save',
  },
  createBlockModel: {
    title: 'Create New Block',
    mainText:
      'Create a block that can be dragged and dropped into multiple templates.',
    blockTypeLabel: 'Block Type',
    nameLabel: 'Name',
    primaryActionText: 'Create Block',
    secondaryActionText: 'Cancel',
  },
};

export const BLOCK_EDITOR = {
  actions: {
    saveAllInstancesButton: 'Save Block',
  },
  saveConfirmModel: {
    title: 'Save Block',
    mainText:
      'Saving this block will affect the following templates. Are you sure you want to save your changes for this block?',
    localeChangeDetails: 'Above changes will be saved to',
    otherLocaleSelectorLabel: 'Apply the above changes to other locales',
    primaryActionText: 'Save',
    secondaryActionText: 'Cancel',
    footNote:
      'Note: This Action will not publish the updated block changes. You will have to publish to see these changes live.',
  },
  confirmNavigateModel: {
    title: 'Are you sure you want to discard your changes',
    mainText:
      'Leaving this page will discard any unsaved changes. The action is not reversible ',
    primaryActionText: 'Discard Changes',
    secondaryActionText: 'Cancel',
  },
};

export const GLOBAL_SETTINGS = {
  errors: {
    saveError: 'Please fix all errors before you hit Save.',
  },
  saveConfirmModel: {
    title: 'Save Confirmation',
    mainText:
      'Saving these changes to global settings will affect all the templates. Are you sure you want to save your changes across global settings?',
    primaryActionText: 'Cancel',
    primaryActionColor: 'link',
    secondaryActionText: 'Publish',
    secondaryActionColor: 'primary',
    footNote:
      'Note: This action will create unpublished drafts for the affected templates. To see these changes live, please publish the draft after reviewing',
  },
  schema: {
    card: {
      type: {
        colorPicker: 'colorPicker',
      },
      label: {
        statusTextColor: 'Status Text Color',
        statusTitleTextColor: 'Status Title Text Color',
        headerBackgroundColor: 'Header Background Color',
        headerTextColor: 'Header Text Color',
        font: 'Font',
        backgroundColor: 'Background Color',
      },
      params: {
        bodyBackground: 'bodyBackground',
        brandCallToAction: 'brandCallToAction',
        linkColor: 'linkColor',
        textColor: 'textColor',
        font: 'font',
        backgroundColor: 'backgroundColor',
      },
    },
    formControl: {
      type: {
        hexColor: 'hex-color',
      },
      name: 'settings',
    },
    header: {
      title: 'Global Settings',
      component: {
        button: 'Button',
      },
      buttonLabel: {
        save: 'Save',
      },
    },
    detailsView: {
      title: 'Details',
      type: {
        staticText: 'static-text',
      },
      dateModified: 'Date Modified',
      dateFormat: 'lll',
      modifiedBy: 'Modified By',
    },
    validation: {
      hexPattern: '^#[A-Fa-f0-9]{6}$',
      error: 'Use a valid hex code.',
    },
  },
};

export const TEST_DATA_LIST = {
  header: {
    title: 'Test Order Data',
  },
  actions: {
    publish: 'Create New',
  },
  table: {
    name: 'Name',
    lastModified: 'Last Modified',
  },
  zeroState: {
    name: 'Test Data',
  },
};

export const TEST_DATA_DETAILS = {
  header: {
    title: 'Create New Test Data',
  },
  actions: {
    create: 'Create',
    save: 'Save',
    delete: 'Delete',
  },
  meta: {
    header: 'Details',
    sourceLabel: 'Source',
    lastModifiedBy: 'Last Modified By',
    lastModified: 'Last Modified',
  },
  nameCard: {
    label: 'Name',
    placeholder: 'Name of test data',
  },
  testDataCard: {
    orderNumberLabel: 'Order Number',
    orderNumberPlaceholder: 'order number',
    templateJsonLabel: 'Template Payload JSON',
    templateJsonPlaceholder: 'paste template payload json',
  },
  testEmailCard: {
    title: 'Test Email',
    dataSetDropdownLabel: 'Data Set',
    templateDropdownLabel: 'Template',
    recipientTextLabel: 'Recipients',
    recipientTextPlaceholder: 'e.g. john@peninsula.com, fanny@peninsula.com',
    sendEmailButton: 'SEND TEST EMAIL',
  },
};

export const EMAIL_TYPE = {
  CUSTOM: 'custom',
  WYSIWYG: 'wysiwyg',
};

export const ADD_TEMPLATES = 'ADD TEMPLATE';

export const LOCALE_EN_US = 'en_US';
export const BLOCKS_LIST_PAGE = '/notify/wysiwyg-editor/blocks';
export const GET_BLOCK_URL = '/notify/wysiwyg-editor/block';
export const TEMPLATE_DETAILS_URL = '/notify/wysiwyg-editor/template-details';
export const TEMPLATES_LIST_PAGE = '/notify/wysiwyg-editor/emails';
export const GLOBAL_SETTINGS_PAGE = '/notify/wysiwyg-editor/settings';
export const ADD_EMAIL_TEMPLATES_PAGE = '/messaging/email-templates/add';
