import { flatten, unflatten } from '../../lib/helpers';
import {
  DELIMITER,
  SIGNIN_INPUT_JSON_PROPERTIES,
  METHOD_SELECTION_JSON_PROPERTIES,
} from '../../constants/returns/uiBuilder';

const {
  INPUT_BASE,
  INPUT_LABEL,
  INPUT_GIFT_LABEL,
} = SIGNIN_INPUT_JSON_PROPERTIES;

const {
  STEP_1: { INITIATED_RETURNS_HEADER },
  STEP_2: {
    RETURN_METHODS_BASE,
    TITLE,
    FEATURE_1,
    FEATURE_2,
    FEATURE_3,
    STORE_LINK_TEXT,
    STORE_LINK,
    DESCRIPTION,
    DISCLAIMER,
    PRICE_LABEL,
  },
  STEP_3: { SUBHEADER, REVIEW_LABEL },
  STEP_4: {
    EMAIL_CONFIRMATION,
    EMAIL_SECONDARY_CONFIRMATION,
    INSTRUCTIONS_TITLE,
    INSTRUCTION_0_HEADER,
    INSTRUCTION_0_DESCRIPTION,
    INSTRUCTION_0_BUTTON,
    INSTRUCTION_1_HEADER,
    INSTRUCTION_1_DESCRIPTION,
    INSTRUCTION_2_HEADER,
    INSTRUCTION_2_DESCRIPTION,
  },
} = METHOD_SELECTION_JSON_PROPERTIES;

export const deleteFromLanguage = (languageKeys, language) => {
  const trimmedLanguage = { ...language };
  languageKeys.forEach(property => {
    delete trimmedLanguage[property];
  });
  return trimmedLanguage;
};

export const getSupplementaryLanguage = (languageKeys, languageTemplate) => {
  const supplementaryLanguage = {};
  languageKeys.forEach(property => {
    supplementaryLanguage[property] = languageTemplate[property];
  });
  return supplementaryLanguage;
};

export const generateMethodKey = ({ return_method: returnMethod, carrier }) =>
  carrier ? `${returnMethod}_${carrier}` : returnMethod;

export const getAvailableMethods = (
  returnsSettings,
  returnsSettingsTemplate,
) => {
  const {
    returns: { return_methods: returnMethods = [] } = {},
  } = returnsSettings;

  const {
    returns: { return_methods: returnMethodsTemplate = [] } = {},
  } = returnsSettingsTemplate;

  const availableMethods = returnMethodsTemplate.filter(methodTemplate => {
    const methodKeyTemplate = generateMethodKey(methodTemplate);
    const enabledTemplate = returnMethods.find(
      method =>
        method.enabled && methodKeyTemplate === generateMethodKey(method),
    );
    return !enabledTemplate;
  });

  return availableMethods;
};

export const constructReturnMethodMappings = methodKey => ({
  initiatedReturnsHeader: `${INITIATED_RETURNS_HEADER}${methodKey}`,
  title: `${RETURN_METHODS_BASE}${methodKey}${TITLE}`,
  firstBenefit: `${RETURN_METHODS_BASE}${methodKey}${FEATURE_1}`,
  secondBenefit: `${RETURN_METHODS_BASE}${methodKey}${FEATURE_2}`,
  thirdBenefit: `${RETURN_METHODS_BASE}${methodKey}${FEATURE_3}`,
  linkText: `${RETURN_METHODS_BASE}${methodKey}${STORE_LINK_TEXT}`,
  linkURL: `${RETURN_METHODS_BASE}${methodKey}${STORE_LINK}`,
  description: `${RETURN_METHODS_BASE}${methodKey}${DESCRIPTION}`,
  disclaimer: `${RETURN_METHODS_BASE}${methodKey}${DISCLAIMER}`,
  priceLabel: `${RETURN_METHODS_BASE}${methodKey}${PRICE_LABEL}`,
  subheader: `${SUBHEADER}${methodKey}`,
  reviewLabel: `${REVIEW_LABEL}${methodKey}`,
  emailConfirmation: `${EMAIL_CONFIRMATION}${methodKey}`,
  emailSecondaryConfirmation: `${EMAIL_SECONDARY_CONFIRMATION}${methodKey}`,
  instructionsTitle: `${INSTRUCTIONS_TITLE}${methodKey}`,
  instruction0Header: `${INSTRUCTION_0_HEADER}${methodKey}`,
  instruction0Description: `${INSTRUCTION_0_DESCRIPTION}${methodKey}`,
  instruction0Button: `${INSTRUCTION_0_BUTTON}${methodKey}`,
  instruction1Header: `${INSTRUCTION_1_HEADER}${methodKey}`,
  instruction1Description: `${INSTRUCTION_1_DESCRIPTION}${methodKey}`,
  instruction2Header: `${INSTRUCTION_2_HEADER}${methodKey}`,
  instruction2Description: `${INSTRUCTION_2_DESCRIPTION}${methodKey}`,
});

export const constructSignInInputMappings = inputName => ({
  inputLabel: `${INPUT_BASE}${inputName}${INPUT_LABEL}`,
  giftInputLabel: `${INPUT_BASE}${inputName}${INPUT_GIFT_LABEL}`,
});

export const getLanguageKeysFromMethodKey = methodKey => {
  const langMappings = constructReturnMethodMappings(methodKey);
  const langKeys = Object.values(langMappings);
  return langKeys;
};

export const getRefundTextLanguageKeyFromRefundType = refundType =>
  `returns.step_3.refunds.payments.${refundType}.text`;

export const getUnfilteredIdxFromFilteredIdx = (
  returnMethods,
  filteredIndex,
) => {
  const enabledReturnMethods = returnMethods.filter(({ enabled }) => enabled);
  const methodKey = generateMethodKey(enabledReturnMethods[filteredIndex]);
  const unfilteredIndex = returnMethods.findIndex(
    method => generateMethodKey(method) === methodKey,
  );
  return unfilteredIndex > -1 ? unfilteredIndex : filteredIndex;
};

export const flattenSettings = settings =>
  flatten(settings, { delimiter: DELIMITER });

export const unflattenSettings = settings =>
  unflatten(settings, { delimiter: DELIMITER });
