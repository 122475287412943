import {
  DISMISS_ALERT,
  RECEIVE_ERROR,
  RECEIVE_SUCCESS,
} from '~src/actions/assistAdminActions';

const initialState = {
  alert: {
    show: false,
    color: 'info',
    message: '',
  },
};

export default (state = initialState, action) => {
  const { error, type } = action;

  switch (type) {
    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: true,
          color: 'danger',
          message: error.message,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: false,
          message: '',
        },
      };

    case RECEIVE_SUCCESS:
      return {
        ...state,
        alert: {
          ...state.alert,
          show: true,
          color: 'success',
          message: "Assist's cache has been successfully cleared.",
        },
      };

    default:
      return state;
  }
};
