import { API_REQUEST, API_INVALIDATE_PATH } from '~src/lib/api';
import * as ENDPOINTS from '~src/constants/endpoints';

export const CREATE_NEW_MESSAGE = 'CREATE_NEW_MESSAGE';
export const DISMISS_ALERT = 'DISMISS_ALERT';
export const RECEIVE_MESSAGES_ERRORS = 'RECEIVE_MESSAGES_ERRORS';
export const RECEIVE_MESSAGES = 'RECEIVE_MESSAGES';
export const RECEIVE_TENANT_MESSAGE = 'RECEIVE_TENANT_MESSAGE';
export const FILTER_MESSAGES_BY_ID = 'FILTER_MESSAGES_BY_ID';
export const RECEIVE_CARRIERS = 'RECEIVE_CARRIERS';
export const RECEIVE_UPDATE_MESSAGE = 'RECEIVE_UPDATE_MESSAGE';
export const RECEIVE_CREATE_MESSAGE = 'RECEIVE_CREATE_MESSAGE';
export const RECEIVE_PUBLISH_MESSAGE = 'RECEIVE_PUBLISH_MESSAGE';
export const RECEIVE_HEADERS_LOCALES = 'RECEIVE_HEADERS_LOCALES';

const receiveErrors = errors => ({
  type: RECEIVE_MESSAGES_ERRORS,
  meta: { errors },
});

export const createNewMessage = () => ({
  type: CREATE_NEW_MESSAGE,
});

export const dismissAlert = () => ({
  type: DISMISS_ALERT,
  payload: {
    alert: null,
  },
});

function receiveMessages(data, locale) {
  const { broadcast_messages, headers } = data;

  return {
    type: RECEIVE_MESSAGES,
    broadcast_messages,
    headers,
    locale,
  };
}

function receiveHeaderLocales(data) {
  const { headers, locales } = data;

  return {
    type: RECEIVE_HEADERS_LOCALES,
    headers,
    locales,
  };
}

function receiveCarriers(carriers) {
  return {
    type: RECEIVE_CARRIERS,
    carriers,
  };
}

function receiveUpdateMessage(message, previewUrl) {
  return {
    type: RECEIVE_UPDATE_MESSAGE,
    message,
    previewUrl,
  };
}

function receiveCreateMessage(message, previewUrl) {
  return {
    type: RECEIVE_CREATE_MESSAGE,
    payload: {
      message,
      previewUrl,
    },
  };
}

function receivePublishMessage(message) {
  return {
    type: RECEIVE_PUBLISH_MESSAGE,
    message,
  };
}

export const fetchMessages = (locale, isGlobal) => dispatch => {
  const query = { locale };

  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.GLOBAL_BROADCAST_MESSAGE,
    query,
    name: 'fetchMessages',
  }).then(
    json => {
      dispatch(receiveMessages(json.response, locale));
      if (isGlobal) {
        const id = isGlobal;

        dispatch({
          type: FILTER_MESSAGES_BY_ID,
          meta: { id, locale },
        });
      }
      dispatch(receiveHeaderLocales(json.response));
    },
    err => {
      dispatch(receiveErrors(err));
    },
  );
};

export const fetchTenantMessage = (locale, carrierId = 'all') => dispatch => {
  const query = { locale };

  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.BROADCAST_MESSAGE,
    query,
    name: 'fetchTenantMessage',
  }).then(
    json => {
      const messageList = json.response.broadcast_messages;
      const message = messageList.find(msg => msg.carrier.key === carrierId);

      dispatch({
        type: RECEIVE_TENANT_MESSAGE,
        message,
        meta: { locale },
      });
      dispatch(receiveHeaderLocales(json.response));
    },
    err => {
      console.error(err);
      dispatch(receiveErrors(err));
    },
  );
};

export const filterMessagesById = (id, locale) => ({
  type: FILTER_MESSAGES_BY_ID,
  meta: { id, locale },
});

export const fetchCarriers = () => dispatch => {
  const query = { option_name: 'carriers' };
  return dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${ENDPOINTS.TENANTS_OPTIONS}`,
    query,
    name: 'fetchCarriers',
  }).then(
    json => dispatch(receiveCarriers(json.carriers)),
    err => dispatch(receiveErrors(err)),
  );
};

export const fetchDefaultCarriers = carriers => dispatch => {
  dispatch(receiveCarriers(carriers));
};

export const fetchUpdateMessage = (message, locale, isGlobal) => dispatch => {
  const carrier_id = message.carrier.key;
  delete message.carrier;
  delete message.id;
  const body = JSON.stringify({ ...message, carrier_id });
  const query = { locale };

  return dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: isGlobal
      ? ENDPOINTS.GLOBAL_BROADCAST_MESSAGE
      : ENDPOINTS.BROADCAST_MESSAGE,
    body,
    query,
    name: 'fetchUpdateMessage',
  })
    .then(json => {
      const previewUrl = json.preview_url;
      const message = json.response;

      dispatch(receiveUpdateMessage(message, previewUrl));
    })
    .catch(err => {
      dispatch(receiveErrors(err));
    });
};

export const fetchCreateMessage = (message, locale, isGlobal) => dispatch => {
  const carrier_id = message.carrier.key;
  delete message.carrier;
  const body = JSON.stringify({ ...message, carrier_id });
  const query = { locale };

  return dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: isGlobal
      ? ENDPOINTS.GLOBAL_BROADCAST_MESSAGE
      : ENDPOINTS.BROADCAST_MESSAGE,
    body,
    query,
    name: 'fetchCreateMessage',
  })
    .then(json => {
      dispatch(receiveCreateMessage(json.response, json.preview_url));
    })
    .catch(err => dispatch(receiveErrors(err)));
};

export const fetchPublishMessage = (
  id,
  locale,
  carrier_id = null,
  date_modified,
  isGlobalCreate,
) => dispatch => {
  const body = JSON.stringify({ carrier_id, date_modified });
  const query = { locale };

  return dispatch({
    type: API_REQUEST,
    method: 'POST',
    path: `${
      id || isGlobalCreate
        ? ENDPOINTS.GLOBAL_BROADCAST_MESSAGE
        : ENDPOINTS.BROADCAST_MESSAGE
    }/publish`,
    body,
    query,
    name: 'fetchPublishMessage',
  })
    .then(json => {
      const message = json.response;

      dispatch({
        type: API_INVALIDATE_PATH,
        path: ENDPOINTS.GLOBAL_BROADCAST_MESSAGE,
        query,
      });
      dispatch({
        type: RECEIVE_PUBLISH_MESSAGE,
        message,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch(receiveErrors(err));
    });
};
