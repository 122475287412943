import { RECEIVE_EDD_RULES_DEFINITION } from '~src/actions/eddRulesDefinitionActions';

export const initialState = {
  alert: {
    show: false,
  },
  actions: [],
  condition: [],
};

function eddRulesDefinition(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_EDD_RULES_DEFINITION:
      return {
        ...state,
        isFetching: false,
        condition: [...payload.condition],
        actions: [...payload.actions],
      };

    default:
      return {
        ...state,
      };
  }
}

export default eddRulesDefinition;
