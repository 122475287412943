import {
  RECEIVE_IMAGES_LIST,
  RECEIVE_IMAGE,
  DISMISS_ALERT,
  UPDATE_IMAGE_UPLOADER,
  RESET_ASSET_DATA,
  RESET_ASSET_DETAILS,
  TOGGLE_IS_UPLOADING,
  RECIEVE_SAVE_IMAGE_SUCCESS,
  RECIEVE_PUBLISH_IMAGE_SUCCESS,
  RECEIVE_ERRORS,
  UPDATE_FORM_INPUT,
  SCHEDULED_ASSETS_CREATE_TOOLTIP_STATE,
  SCHEDULED_ASSETS_TOGGLE_TOOLTIP,
} from '~src/actions/assetsActions';
import { RECEIVE_CSM_LOGOUT } from '~src/actions/userActions';
import { LOCATION_CHANGE } from 'connected-react-router';

export const initialState = {
  assetsList: {
    header: [],
    body: [],
  },
  asset: {
    desktop: {
      image: null,
      imageSrc: null,
      imageObject: null,
      progressBar: 0,
    },
    mobile: {
      image: null,
      imageSrc: null,
      imageObject: null,
      progressBar: 0,
    },
  },
  tooltips: {},
  assetForm: {},
  assetDisplaySchema: {},
  assetsUploading: 0,
  isUploading: false,
  changeDetected: false,
  isPreviewable: false,
  isPublishable: false,
  dateModified: '',
  alert: {
    show: false,
  },
  previewUrl: '',
};

function assetsManager(state = initialState, action) {
  const { meta, payload, type, tooltipName, tooltips } = action;

  switch (type) {
    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_CSM_LOGOUT:
      return initialState;

    case LOCATION_CHANGE:
      return {
        ...state,
        alert: {
          show: false,
        },
      };

    case RESET_ASSET_DETAILS:
      return {
        ...initialState,
      };

    case UPDATE_FORM_INPUT:
      return {
        ...state,
        assetForm: {
          ...state.assetForm,
          [payload.property]: {
            ...state.assetForm[payload.property],
            [payload.valueKey]: payload.value,
          },
        },
      };

    case RECEIVE_IMAGES_LIST:
      return {
        ...state,
        assetsList: payload.ListResponse,
        supportLocales: payload.supportedLocales,
      };

    case RECEIVE_IMAGE:
      return {
        ...state,
        assetForm: payload.property,
        assetDisplaySchema: payload.schema,
      };

    case UPDATE_IMAGE_UPLOADER:
      return {
        ...state,
        asset: {
          ...state.asset,
          [payload.device]: {
            ...state.asset[payload.device],
            ...payload.data,
          },
        },
      };

    case RESET_ASSET_DATA:
      return {
        ...state,
        asset: { ...initialState.asset },
      };

    case TOGGLE_IS_UPLOADING:
      return {
        ...state,
        isUploading: !state.isUploading,
      };

    case RECIEVE_SAVE_IMAGE_SUCCESS:
      return {
        ...state,
        isPublishable: true,
        isPreviewable: true,
        previewUrl: payload.previewUrl,
        dateModified: payload.dateModified,
        alert: {
          color: 'info',
          show: true,
          text: 'Your changes have been saved.',
        },
      };

    case RECIEVE_PUBLISH_IMAGE_SUCCESS:
      return {
        ...state,
        isPublishable: false,
        isPreviewable: false,
        dateModified: '',
        alert: {
          color: 'success',
          show: true,
          text:
            'Publish successful. The updates will be available to all your customers shortly.',
        },
      };

    case RECEIVE_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text:
            meta && meta.errors ? meta.errors : 'An unknown error occurred.',
        },
        errors:
          meta && meta.errors ? meta.errors : 'An unknown error occurred.',
      };

    case SCHEDULED_ASSETS_CREATE_TOOLTIP_STATE:
      return {
        ...state,
        tooltips,
      };

    case SCHEDULED_ASSETS_TOGGLE_TOOLTIP:
      return {
        ...state,
        tooltips: {
          ...state.tooltips,
          [tooltipName]: !state.tooltips[tooltipName],
        },
      };

    default:
      return state;
  }
}

export default assetsManager;
