import objectPath from 'object-path';
import { API_INVALIDATE_PATH, API_REQUEST } from '../../../lib/api';
import { RETURNS_RULES } from '../../../constants/endpoints';
import { RULE_TYPES } from '../../../constants/returns/returnRules';
import { queryParams } from '../../../containers/ReturnsHeadlessConfig/helpers';
import {
  apiErrors,
  RECEIVE_RETURNS_RULES,
  receiveRulesError,
  receiveRulesMetadata,
} from '../../returnsRulesActions';
import apolloClient from '../../../graphql/apolloClient';
import {
  GET_DCS_BY_RETAILER,
  GET_MODES,
} from '../../../graphql/queries/returns';
import { v4 as uuid } from 'uuid';
import { policyItemKeys } from './index';
import { v1RulesCount } from '../../../containers/Returns/ReturnsRules.v.3.0/RuleCards/helpers';

const monikerMap = {
  [RULE_TYPES.RETURN_DESTINATION_POLICY]: 'returnDestinationPolicy',
  [RULE_TYPES.RETURN_METHODS_POLICY]: 'returnMethodsPolicy',
  dcs: 'dcs',
};

export const policyUrls = {
  [RULE_TYPES.RETURN_DESTINATION_POLICY]: `/api/destinations/policies`,
  [RULE_TYPES.RETURN_METHODS_POLICY]: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_METHODS_POLICY}`,
  [RULE_TYPES.RETURN_FEES_POLICY]: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_FEES_POLICY}`,
};

export const receiveRules = ({ rules, policies, dcs }) => ({
  type: RECEIVE_RETURNS_RULES,
  rules,
  policies,
  dcs,
});
const getPolicyUI = ({ policyPayload }) => {
  const statements = objectPath.get(policyPayload, 'policy.statements', []);

  const policyUI = objectPath.get(policyPayload, 'policy_ui', []);

  const output = statements
    .reduce((result, item, index) => {
      const found = policyUI.some(d => d.id === item.id);
      if (!found) {
        result.push({ data: item, index });
      }
      return result;
    }, [])
    .map(
      ({
        data: { id, condition, destinations, enabled, title: name },
        index,
      }) => ({
        data: {
          id,
          name,
          meta: {
            locked: true,
            message:
              'This rule has been created by engineering and cannot be edited. Please contact support for more details.',
          },
          actions: [
            {
              attribute_display_value: 'Code',
              attribute: 'code',
              type: 'code',
              category: 'code',
              dc_criteria: { destinations },
            },
          ],
          enabled,
          criteria: [
            {
              attribute_display_value: 'Code',
              attribute: 'code',
              type: 'code',
              actions: ['code'],
              dc_criteria: { condition },
            },
          ],
          is_additive: false,
          break_on_first_match: false,
        },
        index,
      }),
    );

  const insertMissingItems = (extraItems, list) => {
    const newArray = [...list];

    for (let i = 0; i < extraItems.length; i++) {
      const { data: newData, index } = extraItems[i];
      newArray.splice(index, 0, newData);
    }

    return newArray;
  };

  const withCodeItems = insertMissingItems(output, policyUI);

  withCodeItems.forEach((item, index) => {
    if (!item.selectedActionType && item?.actions) {
      withCodeItems[index].selectedActionType = item.actions[0].category;
    }
  });

  return withCodeItems;
};
const getPolicyPayload = ({ moniker, url }) =>
  new Promise(resolve => {
    fetch(url)
      .then(async e => {
        if (e.status === 200) {
          return resolve({
            type: 'expression',
            moniker,
            value: await e.json(),
          });
        }
        if (e.status === 400 || e.status === 410) {
          return resolve({
            type: 'expression',
            moniker,
            value: {
              policy: {
                version: '2023-08-02',
                statements: [],
              },
              policy_ui: [],
              policy_effective_from: new Date().toISOString(),
              last_updated_at: new Date().toISOString(),
            },
          });
        }
        if (e.status === 500) {
          throw new Error(
            `There is a problem processing your rules. Please contact support for further details.`,
          );
        }
        if (e.error) {
          throw new Error(e.error);
        }
        return resolve({
          type: 'policy',
          moniker,
          value: await e.json(),
        });
      })
      .catch(e => {
        resolve({
          type: 'policy',
          moniker,
          error: { message: e },
        });
      });
  });

const getDcs = async ({ retailerMoniker }) => {
  const res = await apolloClient.query({
    query: GET_DCS_BY_RETAILER,
    variables: {
      retailerMoniker,
      ordering: {
        sort: 'NAME',
        direction: 'ASC',
      },
      countPerPage: 200,
      page: 1,
    },
    fetchPolicy: 'no-cache',
  });

  return res?.data
    ? {
        type: 'dcs',
        moniker: 'dcs',
        value: objectPath.get(res.data, 'listDcs', []),
      }
    : {
        moniker: 'dcs',
        type: 'dcs',
        value: [],
        error: {
          message: `Failed to fetch dcs for retailer.`,
        },
      };
};

export const fetchRules = (
  { apiVersion = 1, retailerMoniker = '', customerSupportLogin = false } = {
    apiVersion: 1,
    retailerMoniker: '',
    customerSupportLogin: false,
  },
) => dispatch => {
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: `${RETURNS_RULES}?version=${apiVersion}`,
    name: 'fetchRules',
  })
    .then(async json => {
      let destinationData = {
        policyPayload: { policyUI: {} },
        dcs: [],
      };

      const fetches = [];

      if (apiVersion > 1 && customerSupportLogin) {
        fetches.push(
          getPolicyPayload({
            moniker: RULE_TYPES.RETURN_DESTINATION_POLICY,
            url: `/api/destinations/policies`,
          }),
        );
        fetches.push(
          getDcs({ retailerMoniker }).catch(() => ({
            moniker: 'dcs',
            type: 'dcs',
            value: [],
            error: {
              message: `Failed to fetch dcs for retailer.`,
            },
          })),
        );
      }

      let modes;

      try {
        const modesData = await apolloClient
          .query({
            query: GET_MODES,
            fetchPolicy: 'no-cache',
          })
          .catch(() => ({}));

        modes = objectPath.get(modesData, 'data.returns.policies', {
          destinationMode: null,
          feesMode: null,
          returnMethodsMode: null,
        });
      } catch (error) {
        console.error(error);
      }

      const { multi, fees } = queryParams();

      if (multi || modes.returnMethodsMode) {
        fetches.push(
          getPolicyPayload({
            moniker: RULE_TYPES.RETURN_METHODS_POLICY,
            url: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_METHODS_POLICY}`,
          }),
        );
      }

      if (fees || modes.feesMode) {
        fetches.push(
          getPolicyPayload({
            moniker: RULE_TYPES.RETURN_FEES_POLICY,
            url: `/api/returns/policies?policy_type=${RULE_TYPES.RETURN_FEES_POLICY}`,
          }),
        );
      }

      if (fetches.length > 0) {
        try {
          await Promise.all(fetches)
            .then(items => {
              const errors = items.filter(item => item.error);
              const success = items.filter(item => !item.error);
              if (errors.length > 0) {
                dispatch(
                  apiErrors({
                    error: errors.reduce(
                      (result, value) => ({
                        ...result,
                        [monikerMap[value.moniker]]: {
                          hasError: true,
                          message: value.error.message,
                        },
                      }),
                      {},
                    ),
                  }),
                );
              }
              if (success.length > 0) {
                const dcs = items.find(item => item.moniker === 'dcs');
                const policyItems = policyItemKeys
                  .map(ruleItem => {
                    const foundItem = items.find(
                      item => item.moniker === ruleItem,
                    );
                    return foundItem?.value
                      ? [ruleItem, foundItem.value]
                      : null;
                  })
                  .filter(Boolean);
                destinationData = {
                  ...Object.fromEntries(policyItems),
                  dcs: dcs?.value ? dcs.value : [],
                };
              }
            })
            .catch(({ err, moniker }) => {
              dispatch(
                apiErrors({
                  error: {
                    [monikerMap[moniker]]: {
                      hasError: true,
                      message: err || `Failed to fetch ${moniker}.`,
                    },
                  },
                }),
              );
              dispatch(
                receiveRulesError(
                  typeof err === 'string'
                    ? err
                    : 'Failed to fetch destination policies. Please try again.',
                ),
              );
            });
        } catch (error) {
          console.error(error);
        }
      }

      const { dcs = [] } = destinationData;

      const validPolicyItems = policyItemKeys
        .map(ruleItem =>
          destinationData[ruleItem]
            ? [ruleItem, destinationData[ruleItem]]
            : null,
        )
        .filter(Boolean);

      const policies = Object.fromEntries(validPolicyItems);
      const policyUis = validPolicyItems.map(([ruleItem, policyPayload]) => [
        ruleItem,
        getPolicyUI({ policyPayload }),
      ]);

      const {
        response: {
          returns: {
            rule_types: ruleTypes,
            modified_by: modifiedBy,
            modified_date: modifiedDate,
          },
        },
      } = json;

      const rulesWithIds = Object.entries(ruleTypes).map(([key, value]) => {
        if (!value) return [key, value];
        return [
          key,
          value.map(item => ({ ...item, id: item.id ? item.id : uuid() })),
        ];
      });

      const rules = {
        ...Object.fromEntries(policyUis),
        ...Object.fromEntries(rulesWithIds),
      };

      if (policies?.fees_policy?.policy_ui) {
        if (
          modes.feesMode &&
          rules[RULE_TYPES.RETURN_FEES].filter(e =>
            Object.keys(e).includes('actions'),
          ).length < 2
        ) {
          rules[RULE_TYPES.RETURN_FEES] = policies.fees_policy.policy_ui;
        } else {
          rules[RULE_TYPES.RETURN_FEES] = [
            ...policies.fees_policy.policy_ui,
            ...rules[RULE_TYPES.RETURN_FEES],
          ];
        }
      }

      dispatch(
        receiveRules({
          rules,
          policies,
          dcs,
        }),
      );
      dispatch(receiveRulesMetadata({ modifiedBy, modifiedDate }));
      dispatch({
        type: API_INVALIDATE_PATH,
        path: `${RETURNS_RULES}?version=${apiVersion}`,
      });
    })
    .catch(err => {
      console.error(err);
      dispatch(
        apiErrors({ error: { fetchRules: { hasError: true, message: err } } }),
      );
    });
};
