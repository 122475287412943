import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@narvar/hum';

import { CodeBox, PSSLCodeBoxWithPreview } from '../customComponents';

const customComponents = {
  codeBox: CodeBox,
  psslCodeBoxWithPreview: PSSLCodeBoxWithPreview,
};

const FormControls = ({ formControls, cardIndex, isDynamicSchema, form }) => {
  return (
    <>
      {formControls.map((formControl, formControlIndex) => {
        if (formControl.props.hidden) return null;
        if (Object.keys(customComponents).includes(formControl.props.type)) {
          return customComponents[formControl.props.type]({
            key: formControl.id || formControlIndex,
            data: form.cards[cardIndex][formControl.props.name.split('/')[1]],
            formControl,
            form,
          });
        }
        return isDynamicSchema ? (
          <FormControl
            key={formControl.id || formControlIndex}
            {...formControl.props}
          />
        ) : (
          <FormControl
            data={form.cards[cardIndex][formControl.props.name.split('/')[1]]}
            selected={
              form.cards[cardIndex][formControl.props.name.split('/')[1]]
            }
            key={formControl.props.name}
            {...formControl.props}
          />
        );
      })}
    </>
  );
};

FormControls.propTypes = {
  formControls: PropTypes.arrayOf({
    id: PropTypes.string,
    props: PropTypes.object,
  }),
  isDynamicSchema: PropTypes.bool,
  form: PropTypes.shape({
    cards: PropTypes.array,
    rightCards: PropTypes.array,
  }), // required if not a dynamic schema
};

FormControls.defaultProps = {
  formControls: [],
  isDynamicSchema: false,
  form: null,
};

export default FormControls;
