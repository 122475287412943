import React, { Component } from 'react';
import Svg from '../Svg';
import PropTypes from 'prop-types';
import { Modal as ReactstrapModal, ModalHeader } from 'reactstrap';
import cx from 'classnames';
import s from './Modal.styles';

export const Modal = ({
  children,
  className,
  isOpen,
  step,
  numSteps,
  title,
  toggle,
  onStepClick,
  onClosed
}) => (
  <ReactstrapModal
    className={cx(s, className)}
    isOpen={isOpen}
    toggle={toggle}
    onClosed={onClosed}
  >
    <ModalHeader>
      <span className="modal-title">
        {title}
      </span>
      <span
        className="close"
        onClick={toggle}
        role="close"
      >
        <Svg name="close" />
      </span>
    </ModalHeader>
    {children}
    {step !== undefined &&
      <div className="dots">
        {[...Array(numSteps)].map((form, index) => (
          <div
            onClick={() => onStepClick(index + 1)}
            className={step === index ? 'is-active' : ''}
            key={index}
          />
        ))}
      </div>}
  </ReactstrapModal>
);

Modal.defaultProps = {
  numSteps: 2,
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.number,
  numSteps: PropTypes.number,
  onStepClick: PropTypes.func,
  title: PropTypes.string,
  toggle: PropTypes.func.isRequired,
};

export default Modal;
