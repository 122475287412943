import {
  RECEIVE_SERVICE_CODES_LIST,
  RECEIVE_SERVICE_CODES_ERROR,
  RECEIVE_SERVICE_CODES_UPDATE,
  RECEIVE_SERVICE_CODES_SAVE,
  RECEIVE_CARRIER_SERVICES,
  RECEIVE_SERVICE_CODE_DETAILS,
  RECEIVE_SAVE_ERRORS,
  RECEIVE_TNT_DAYS,
  CLEAR_FORM_VALUES_AND_ERRORS,
} from '~src/actions/serviceCodesActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

export const initialState = {
  alert: { show: false },
  timeInTransit: {},
  serviceCodeDetails: {},
  serviceCodesList: [],
  carrierServices: [],
};

export default (state = initialState, { type, payload, messages }) => {
  const {
    serviceCodesList: {
      content: serviceCodesList,
      total_pages: serviceCodesListTotalPages,
      total_records: serviceCodesListTotalRecords,
      current_page_index: serviceCodesListPageIndex,
      records_per_page: serviceCodesListRecordsPerPage,
    } = {},
    serviceCodeDetails,
    timeInTransit,
    carrierServices,
  } = payload || {}; // sometimes the payload is `null`, we must default like so

  switch (type) {
    case RECEIVE_CARRIER_SERVICES:
      return {
        ...state,
        carrierServices,
      };

    case RECEIVE_SERVICE_CODES_LIST:
      return {
        ...state,
        serviceCodesList,
        serviceCodesListTotalPages,
        serviceCodesListTotalRecords,
        serviceCodesListPageIndex: serviceCodesListPageIndex + 1,
        serviceCodesListRecordsPerPage,
      };

    case RECEIVE_TNT_DAYS:
      return {
        ...state,
        timeInTransit,
      };

    case RECEIVE_SERVICE_CODE_DETAILS:
      return {
        ...state,
        serviceCodeDetails,
      };

    case RECEIVE_SERVICE_CODES_SAVE:
    case RECEIVE_SERVICE_CODES_UPDATE:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: `Service code mapping ${
            type === RECEIVE_SERVICE_CODES_SAVE ? 'saved' : 'updated'
          }.`,
        },
        serviceCodeDetails,
      };

    case RECEIVE_SERVICE_CODES_ERROR:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: messages,
        },
      };

    case RECEIVE_SAVE_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: 'Please fix all errors before you hit save.',
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: { ...initialState.alert },
      };

    case CLEAR_FORM_VALUES_AND_ERRORS:
      return { ...initialState };

    default:
      return state;
  }
};
