import {
  REQUEST_RETAILER_CONFIGURATION,
  RECEIVE_ERROR,
  RECEIVE_RETAILER_CONFIGURATION,
  REQUEST_UPDATE_RETAILER_CONFIGURATION,
  RECEIVE_UPDATE_RETAILER_CONFIGURATION,
} from '~src/actions/assistConfigurationActions';

import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    show: false,
    color: 'info',
    text: '',
  },
  retailerConfiguration: {},
  isFetchingAssistRetailerConfiguration: false,
};

export default function(state = initialState, action) {
  const { error, type, meta, payload } = action;

  switch (type) {
    case RECEIVE_RETAILER_CONFIGURATION:
      return {
        ...state,
        retailerConfiguration: payload,
        isFetchingAssistRetailerConfiguration: false,
        uriMoniker: meta.uriMoniker,
      };

    case REQUEST_RETAILER_CONFIGURATION:
      return {
        ...state,
        isFetchingAssistRetailerConfiguration: true,
      };

    case REQUEST_UPDATE_RETAILER_CONFIGURATION:
      return {
        ...state,
        isFetchingUpdateAssistRetailerConfiguration: true,
      };

    case RECEIVE_UPDATE_RETAILER_CONFIGURATION:
      return {
        ...state,
        retailerConfiguration: payload,
        isFetchingUpdateAssistRetailerConfiguration: false,
        uriMoniker: meta.uriMoniker,
        alert: {
          show: true,
          color: 'success',
          text: meta.message,
        },
      };

    case RECEIVE_ERROR:
      return {
        ...state,
        alert: {
          show: true,
          color: 'danger',
          text: error,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: {
          show: false,
          color: 'info',
          text: '',
        },
      };

    default:
      return state;
  }
}
