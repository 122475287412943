import moment from 'moment';

const filterWrapperRegEx = /\([^()]+\)/g;

const removeFilter = (filters = '', filter) => {
  const filterToRemove = `( "${filter.name.trim()}" eq '${filter.value.trim()}' )`;
  let groups = filters.split(/\s+and\s+/); // Deconstruct to groups
  if (
    filter.range ||
    filter.name === 'tracking_number' ||
    filter.name === 'order_number'
  ) {
    groups = groups.filter(group => !group.includes(filter.name));
  } else {
    const groupIndex = groups.findIndex(group =>
      group.includes(filterToRemove),
    );
    const group = groups[groupIndex];
    const groupFilters = group.match(filterWrapperRegEx);
    if (groupFilters.length === 1) {
      groups.splice(groupIndex, 1);
    } else {
      const newGroupFilters = groupFilters.filter(
        filterStr => filterStr !== filterToRemove,
      );
      groups[groupIndex] = newGroupFilters.join(' or ');
    }
  }
  const updatedFilters = groups.join(' and ');
  return updatedFilters;
};

const addFilter = (filters = '', filter) => {
  const filterString = filter.dateFilter
    ? `( "${filter.groupKey}" ge ${filter.startDate} ) and ( "${filter.groupKey}" lt ${filter.endDate} )`
    : `( "${filter.groupKey}" eq '${filter.key}' )`;

  if (filters === '') {
    return `${filterString}`;
  }

  const groups = filters.split(/\s+and\s+/);
  const groupIndex = groups.findIndex(group =>
    group.includes(`"${filter.groupKey}"`),
  );
  if (groupIndex === -1) {
    groups.push(`(${filterString})`);
  } else {
    const groupToUpdate = groups[groupIndex];
    const groupFilters = groupToUpdate.match(filterWrapperRegEx);
    if (!groupFilters.includes(filterString)) {
      groupFilters.push(filterString);
      groups[groupIndex] = `(${groupFilters.join(' or ')})`;
    }
  }
  const updatedFilters = groups.join(' and ');
  return updatedFilters;
};

const parseQueryFilters = filterString => {
  // ( "customer_shipment_method" eq 'basic' ) and
  // ( ( "age" ge 20 ) or ( "age" ne 45 and "gender" eq 'FEMALE' ) )
  // 1. removing parentheses, quotation marks
  const re1 = /([()"'])+/g;
  const parsedFilterStr = filterString.replace(re1, '');

  // 2. spliting in groups e.g. age ge 20
  const re2 = /(?:\s+(?:and|or)\s+)\s*/;
  let parsedFilterArr = parsedFilterStr.split(re2);

  // 3. spliting in groups name:value e.g. {name: 'age', value: 20}
  const re3 = /(?:\s+(eq|ne|gt|ge|lt|le)\s+)\s*/;
  parsedFilterArr = parsedFilterArr.map(filter => {
    const filterKeyVal = { name: '', value: '', readOnly: false };
    const filterItems = filter.split(re3);

    if (filterItems.length === 3) {
      filterKeyVal.name = filterItems[0].trim();
      filterKeyVal.value = filterItems[2].trim();

      if (filterItems[1] === 'ge' || filterItems[1] === 'lt') {
        filterKeyVal.range = true;
      }

      if (filterKeyVal.name === 'days_since_order') {
        filterKeyVal.readOnly = true;
        filterKeyVal.range = false;
      }
    }

    return filterKeyVal;
  });
  const filters = [];
  const rangeItems = parsedFilterArr.reduce((acc, filter) => {
    if (filter.range) {
      if (acc[filter.name]) {
        acc[filter.name].value = `${moment(acc[filter.name].value).format(
          'MMM DD',
        )} - ${moment(filter.value).format('MMM DD')}`;
        acc[filter.name].start = acc[filter.name].value;
        acc[filter.name].end = filter.value;
      } else {
        acc[filter.name] = {
          name: filter.name,
          value: filter.value,
          range: true,
        };
      }
    } else {
      filters.push(filter);
    }
    return acc;
  }, {});

  return filters.concat(Object.keys(rangeItems).map(item => rangeItems[item]));
};

export { removeFilter, addFilter, parseQueryFilters };
