import carrierCredentialJson from '~src/constants/carrierCredentials/credentialFields.json';
import customCarrierCredentials from '~src/constants/carrierCredentials/customCredentialFields';
import { API_REQUEST } from '~src/lib/api';

export const SET_REQUIRED_INPUTS = 'SET_REQUIRED_INPUTS';
export const UPDATE_CREDENTIAL_FORM = 'UPDATE_CREDENTIAL_FORM';
export const FORM_CHANGE_DETECTED = 'FORM_CHANGE_DETECTED';
export const UPDATE_DETAILS_FORM = 'UPDATE_DETAILS_FORM';
export const RESET_CREDENTIAL_FROM = 'RESET_CREDENTIAL_FROM';
export const TOGGLE_EDITABLE_FORM = 'TOGGLE_EDITABLE_FORM';
export const CARRIER_SELECTED = 'CARRIER_SELECTED';
export const SET_REQUIRED_INPUTS_FILLED = 'SET_REQUIRED_INPUTS_FILLED';
export const TOGGLE_FORM_CARD_LOADING = 'TOGGLE_FORM_CARD_LOADING';

const allCarrierCredentialFields = {
  ...carrierCredentialJson,
  ...customCarrierCredentials,
};

export const formChangeDetected = () => ({
  type: FORM_CHANGE_DETECTED,
});

export const updateCredentialForm = formUpdate => ({
  type: UPDATE_CREDENTIAL_FORM,
  payload: { ...formUpdate },
});

export const updateDetailsForm = (formKey, formValue) => ({
  type: UPDATE_DETAILS_FORM,
  payload: { [formKey]: formValue },
});

export const resetCredentialForm = () => ({
  type: RESET_CREDENTIAL_FROM,
});

export const toggleEditableForm = (isEditableForm = true) => ({
  type: TOGGLE_EDITABLE_FORM,
  isEditableForm,
});

export const setRequiredInputs = requiredInputs => ({
  type: SET_REQUIRED_INPUTS,
  requiredInputs,
});

export const setRequiredInputsFilled = requiredInputsFilled => ({
  type: SET_REQUIRED_INPUTS_FILLED,
  requiredInputsFilled,
});

export const toggleFormCardLoading = cardIndex => ({
  type: TOGGLE_FORM_CARD_LOADING,
  cardIndex,
});

export const checkRequiredInputs = () => (dispatch, getState) => {
  const {
    trackingCarrierCredentials: { requiredInputs, formCredentials },
  } = getState();

  const requiredInputsFilled = requiredInputs.reduce(
    (inputsComplete, { requestProperty }) => {
      if (!inputsComplete) return false;
      return formCredentials[requestProperty];
    },
    true,
  );

  dispatch(setRequiredInputsFilled(requiredInputsFilled));
};

export const checkIfFormIsEditable = ({
  carrier_moniker: carrierMoniker,
}) => dispatch => {
  const {
    [carrierMoniker]: [{ isEditable = true } = {}] = [],
  } = allCarrierCredentialFields;

  dispatch(toggleEditableForm(isEditable));
};

export const carrierSelected = selectedCarrier => ({
  type: CARRIER_SELECTED,
  selectedCarrier,
  carrierCredentialFields:
    allCarrierCredentialFields[selectedCarrier.carrier_moniker] || [],
});

/*
 * formCards has the following structure: [{ form: [input] }]
 * This function returns an array of input objects that pass the filter
 */
const filterInputsByProperty = (formCards, filterProperty) =>
  formCards.reduce(
    (allFilteredInputs, { form = [] }, cardIndex) => [
      ...allFilteredInputs,
      ...form.reduce((filteredForm, currentInput) => {
        if (currentInput[filterProperty]) {
          return [
            ...filteredForm,
            {
              ...currentInput,
              cardIndex,
            },
          ];
        }

        return filteredForm;
      }, []),
    ],
    [],
  );

const extractDynamicContent = (pathToContent, response) =>
  pathToContent.reduce(
    (content, currentStep) => content && content[currentStep],
    response,
  );

const fetchDynamicContent = dynamicInputs => async dispatch => {
  const dynamicContent = (await Promise.all(
    dynamicInputs.map(async input => {
      const {
        requestProperty: name,
        dynamicSource: path,
        pathToDynamicValue,
        cardIndex,
      } = input;

      dispatch(toggleFormCardLoading(cardIndex));

      const contentContainer = await dispatch({
        type: API_REQUEST,
        method: 'GET',
        path,
        name,
      });

      dispatch(toggleFormCardLoading(cardIndex));

      return {
        [name]: extractDynamicContent(pathToDynamicValue, contentContainer),
      };
    }),
  )).reduce(
    (allDynamicContent, nextInput) => ({ ...allDynamicContent, ...nextInput }),
    {},
  );

  await dispatch(updateCredentialForm(dynamicContent));
};

export const changeSelectedCarrier = selectedCarrier => async dispatch => {
  const { carrier_moniker: carrierMoniker } = selectedCarrier;
  const { [carrierMoniker]: formCards = [] } = allCarrierCredentialFields;
  const dynamicInputs = filterInputsByProperty(formCards, 'dynamicSource');
  const requiredInputs = filterInputsByProperty(formCards, 'required');

  dispatch(fetchDynamicContent(dynamicInputs));
  dispatch(setRequiredInputs(requiredInputs));
  dispatch(checkRequiredInputs());
  dispatch(carrierSelected(selectedCarrier));
};
