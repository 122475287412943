import React from 'react';
import { MigrationCard } from '../ui';
import { checkValidation, configMethods, configOptions } from './mergeHelpers';
import { generateMethodKey } from '../../../../../../../../lib/returns/uiBuilder';

const Message = ({ carrier, validation }) => (
  <>
    {validation === 'valid' && (
      <MigrationCard title={`Mail with ${carrier}`} validation="valid">
        Mail with carrier <strong>{carrier}</strong> will be migrated. This
        method will be merged into the single Mail method.
      </MigrationCard>
    )}
    {validation === 'invalidcarrier' && (
      <MigrationCard title={`Mail with ${carrier}`} validation="invalid">
        Mail with carrier <strong>{carrier}</strong> will not be migrated. It is
        likely because the chosen carrier is not available in this locale.
      </MigrationCard>
    )}
  </>
);

export const mail = ({
  option,
  option: { return_method: returnMethod, carrier },
  returnOptionsTemplate,
}) => {
  const validation = checkValidation({
    returnMethod: option.return_method,
    carrier: option.carrier,
    returnOptionsTemplate,
  });
  return {
    returnMethod,
    carrier,
    newOption: {
      option: configOptions({ returnMethod, carrier, validation }),
      methods: configMethods({
        returnMethod,
        carrier,
        validation,
        extraConfig: {
          ...returnOptionsTemplate.methods[generateMethodKey(option)],
          get_address: option.get_address,
          disable_email: option.disable_email,
          payments: option.payments,
        },
      }),
    },
    message: <Message {...{ carrier, validation }} />,
  };
};
