import {
  RECEIVE_RETURN_CARRIERS_LIST,
  RECEIVE_RETURN_CARRIER_CREDENTIAL,
  RECEIVE_DELETE_RETURN_CARRIER_CREDENTIAL,
  RECEIVE_RETURN_CARRIER_CREDENTIAL_SUCCESS,
  RECEIVE_RETURN_CARRIER_CREDENTIAL_ERRORS,
  RECEIVE_RETURN_CARRIER_CREDENTIALS_LIST,
  RESET_FORM_VALUES_AND_ERRORS,
} from '~src/actions/returnCarrierCredentialsActions';
import { DISMISS_ALERT } from '~src/actions/uiActions';

const initialState = {
  alert: {
    show: false,
  },
  available_carriers: [],
  third_party_carriers: [],
  products: [],
  test_data: {},
  messages: [],
  carrier: {},
  carrier_configs: [],
  third_party: false,
};

const returnCarrierCredentialsReducer = (state = initialState, action) => {
  const { type, payload, meta } = action;
  switch (type) {
    case RECEIVE_RETURN_CARRIER_CREDENTIAL:
      return {
        ...state,
        carrier: payload.selected_carrier_config,
        test_data: payload.test_data,
        messages: payload.messages,
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case RECEIVE_RETURN_CARRIER_CREDENTIAL_SUCCESS:
      return {
        ...state,
        carrier: payload.selected_carrier_config,
        alert: {
          color: 'success',
          show: true,
          text: meta,
        },
      };

    case RECEIVE_RETURN_CARRIER_CREDENTIAL_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: payload.errors
            ? payload.errors[0].message
            : 'An error occurred',
        },
      };

    case RECEIVE_DELETE_RETURN_CARRIER_CREDENTIAL:
      return {
        ...state,
        alert: {
          color: 'success',
          text: payload,
          show: true,
        },
      };

    case RECEIVE_RETURN_CARRIER_CREDENTIALS_LIST:
      return {
        ...state,
        carrier_configs: payload.carrier_configs,
      };

    case RECEIVE_RETURN_CARRIERS_LIST:
      return {
        ...state,
        available_carriers: payload.available_carriers,
        third_party_carriers: payload.third_party_carriers,
        products: payload.products,
        locales: payload.locales,
        test_data: payload.test_data,
        messages: payload.messages,
      };

    case RESET_FORM_VALUES_AND_ERRORS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default returnCarrierCredentialsReducer;
