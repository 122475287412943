import moment from 'moment';
import { API_DATE_FORMAT } from '@narvar/hum-constants';
import qs from 'qs';

const buildPackagesQuery = ({ fields, id }) => {
  const select = fields.map(field => `"${field.split('.')[1]}"`);
  const startDate = moment()
    .subtract(30, 'days')
    .format(API_DATE_FORMAT);
  const endDate = moment().format(API_DATE_FORMAT);

  return qs.stringify(
    {
      offset: 0,
      from: `"${id}"`,
      select: select.join(),
      date_filter: `"order_datetime" ge ${startDate} and "order_datetime" lt ${endDate}`,
      sorts: '"day"',
      limit: 25,
    },
    { addQueryPrefix: true },
  );
};

export default buildPackagesQuery;
