import { css } from 'emotion';

const s = css`
  margin-bottom: -20px;
  
  & .time-input {
    width: 40%;
    margin-right: 20px;
  }

  & .timezone-input {
    width: 60%;
    max-width: 380px;
  }
  
  & .full-width{
    width: 100%;
  }
`;

export default s;
