import React from 'react';
import { MigrationCard } from '../ui';
import { checkValidation, configMethods, configOptions } from './mergeHelpers';
import { generateMethodKey } from '../../../../../../../../lib/returns/uiBuilder';

const Message = ({ carrier, validation }) => (
  <>
    {validation === 'valid' && (
      <MigrationCard
        title={`Scheduled Pickup with ${carrier}`}
        validation="valid"
      >
        Scheduled Pickup with carrier <strong>{carrier}</strong> will be
        migrated. This method will be merged into the single Scheduled Pickup
        method.
      </MigrationCard>
    )}
    {validation === 'invalidcarrier' && (
      <MigrationCard
        title={`Scheduled Pickup with ${carrier}`}
        validation="invalid"
      >
        Scheduled Pickup with carrier <strong>{carrier}</strong> will not be
        migrated. It is likely because the chosen carrier is not available in
        this locale.
      </MigrationCard>
    )}
  </>
);

export const pickup = ({
  option,
  option: { carrier },
  returnOptionsTemplate,
}) => {
  const returnMethod = 'pickup';
  const validation = checkValidation({
    returnMethod,
    carrier,
    returnOptionsTemplate,
  });
  return {
    returnMethod,
    carrier,
    newOption: {
      option: configOptions({
        returnMethod,
        carrier,
        validation,
      }),
      methods: configMethods({
        returnMethod,
        carrier,
        validation,
        extraConfig: {
          ...returnOptionsTemplate.methods[generateMethodKey(option)],
          get_address: option.get_address,
        },
      }),
    },
    message: <Message {...{ carrier, validation }} />,
  };
};
