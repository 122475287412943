import { css } from 'emotion';
import { colors, fonts } from '@narvar/hum-constants';

const s = css`
.expression {
  margin-bottom: 10px;
}

& .nested-expression {
  margin-bottom: 10px;
}

.row-container {
  padding: 10px 20px;
  border: 1px solid ${colors.gray};
  border-left-width: 5px;
  border-radius: 3px;
}

& .row-button {
  margin-left: 5px;
}

& .row-header {
  color: ${colors.dark1};
  font-family: ${fonts.bold};
  margin: 0px auto 10px auto;
}

& .connector {
  background: ${colors.grayLightest};
  border: 1px solid ${colors.gray};
  border-radius: 12px;
  font-family: ${fonts.bold};
  font-size: 0.9em;
  height: 24px;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  width: 60px;
}

& .vertical-line {
    width: 2px;
    height: 12px;
    background: ${colors.grayLightest};
    margin-left: 15px;
}

  & .full-width {
     display: inline-block;
     width: 100%;
     margin-left: 0px;
   }

  & .row-cols-4 {
     display: inline-block;
     margin-bottom: 5px;
     width: 25%;
     padding-right: 0;
     padding-left: 0;
  }

  & .bottom-padding-20 {
    padding-bottom: 20px;
  }

  & .icon-delete-container {
    align-items: flex-end;
    display: flex;
    height: 100%;
    opacity: .4;
    padding-bottom: 10px;

    & .icon-delete {
      cursor: pointer;
    }

    & svg path {
      fill: ${colors.black};
    }

    &:hover {
      opacity: 1;

      & svg path {
        fill: ${colors.red};
      }
    }
  }
`;

export default s;
