import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../DatePicker';
import Button from '../Button';
import SearchableSelect from '../SearchableSelect';
import Tag from '../Tag';
import cx from 'classnames';
import s from './Filters.styles';

export class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilters: props.filters || [],
      showSelect: false,
      showDateRangePicker: false,
      startDate: props.startDate,
      endDate: props.endDate,
      dateFilterPlaceholder: '',
      dateFilterType: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    const { filters } = this.props;
    const { filters: nextFilters } = nextProps;

    if (filters.length === 0 && nextFilters.length > 0) {
      const activeFilters = nextFilters.filter(filter => filter.values.length > 0);
      this.setState({ activeFilters });
    }
  }

  getActiveFilters = () => {
    const { filters } = this.props;
    return filters.filter(filter => filter.values.length > 0);
  };

  handleSelectFilter = (event, props) => {
    const { name } = this.props;

    if (props.item.groupKey === 'date_filters') {
      this.toggleDateRangePicker();
      this.setState({
        dateFilterType: props.item.key,
        dateFilterPlaceholder: props.item.display_name,
      });
    } else {
      this.props.onSelect(props.item, name);
      this.setState({ showSelect: false });
    }
  };

  handleRemoveFilter = filter => {
    const { name } = this.props;
    this.props.onRemove(filter, name);
  };

  handleFilterChange = value => {
    const { filters } = this.props;
    const activeFilters = [];

    filters.forEach(item => {
      // If search matches dimension we should display all possible values for that dimension
      if (item.display_name.toLowerCase().startsWith(value.toLowerCase())) {
        if (item.values.length > 0) {
          activeFilters.push(item);
        }
      } else {
        const filterValues = item.values.filter(filterValue => filterValue.display_name
          .toLowerCase()
          .startsWith(value.toLowerCase()));

        if (filterValues.length > 0) {
          activeFilters.push({ ...item, values: filterValues });
        }
      }
    });

    this.setState({ activeFilters });
  };

  handleFilterToggle = () => {
    this.setState({
      activeFilters: this.props.filters,
    });
  };

  handleAddFilter = () => {
    const activeFilters = this.getActiveFilters();
    this.setState({
      showSelect: !this.state.showSelect,
      activeFilters,
    });
  };

  handleFilterCancel = () => {
    this.setState({
      showSelect: false,
      showDateRangePicker: false,
    });
  };

  handleSelectDateFilterDays = (startDate, endDate) => {
    const { dateFilterType } = this.state;
    this.props.onDateSelect(dateFilterType, startDate, endDate);
    this.setState({
      showSelect: false,
      showDateRangePicker: false,
    });
  };

  toggleDateRangePicker = () => {
    const { showDateRangePicker } = this.state;
    this.setState({
      showDateRangePicker: !showDateRangePicker,
      showSelect: !showDateRangePicker,
    });
  };

  render() {
    const {
      filterTypes,
      ignoreHandleClickOutside,
      isChartDetailView,
      isFetching,
      name,
      selectedFilters,
      labelSeperator,
      addButtonText,
      readOnly,
    } = this.props;
    const {
      activeFilters,
      dateFilterPlaceholder,
      showSelect,
      showDateRangePicker,
      startDate,
      endDate,
    } = this.state;

    return (
      <div className={cx(s, isChartDetailView ? 'is-chart-detail' : '')}>
        {selectedFilters.map(item => {
          const { name: itemName, value, readOnly: itemReadOnly } = item;
          const {
            [itemName]: {
              display_name: displayName = itemName.replace(/_/g, ' '),
            } = {},
          } = filterTypes;
          return (
            <Tag
              key={`${itemName}${value}${readOnly}`}
              item={item}
              readOnly={itemReadOnly || readOnly}
              label={`${displayName}${labelSeperator}${value}`}
              onClick={this.handleRemoveFilter}
            />
          );
        })}
        {showSelect && (
          <div
            className={
              ignoreHandleClickOutside && 'ignore-react-onclickoutside'
            }
          >
            <SearchableSelect
              autoFocus
              isFetching={isFetching}
              isOpen
              items={activeFilters}
              name={name}
              placeholder={
                showDateRangePicker ? dateFilterPlaceholder : 'Filters'
              }
              onChange={this.handleFilterChange}
              onToggle={this.handleFilterToggle}
              onSelect={this.handleSelectFilter}
              onCancel={this.handleFilterCancel}
              readableKey="display_name"
            >
              {showDateRangePicker && (
                <DatePicker
                  showDropdown={false}
                  onCancel={this.toggleDateRangePicker}
                  handleSelect={this.handleSelectDateFilterDays}
                  startDate={startDate}
                  endDate={endDate}
                  ignoreHandleClickOutside
                  isDateRange
                />
              )}
            </SearchableSelect>
          </div>
        )}
        {!readOnly &&
          <Button
            onClick={this.handleAddFilter}
            color="link"
            className="add-filter"
            icon="plus"
          >
            {addButtonText}
          </Button>}
      </div>
    );
  }
}

Filters.propTypes = {
  name: PropTypes.string,
  filterTypes: PropTypes.shape({ display_name: PropTypes.string }),
  ignoreHandleClickOutside: PropTypes.bool,
  isChartDetailView: PropTypes.bool,
  isFetching: PropTypes.bool,

  // List of active filters
  selectedFilters: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
  })),

  // Comprehensive list of filters received from metadata endpoint
  filters: PropTypes.arrayOf(PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      display_name: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      groupKey: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,

  onRemove: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  onDateSelect: PropTypes.func,
  labelSeperator: PropTypes.string,
  addButtonText: PropTypes.string,
  readOnly: PropTypes.bool,
};

Filters.defaultProps = {
  name: '',
  filterTypes: {},
  ignoreHandleClickOutside: false,
  isChartDetailView: false,
  isFetching: false,
  selectedFilters: [],
  startDate: new Date().toString(),
  endDate: new Date().toString(),
  onDateSelect: () => undefined,
  labelSeperator: ': ',
  addButtonText: 'Add Filter',
  readOnly: false,
};

export default Filters;
