import { colors } from '@narvar/hum-constants';
import { css } from 'emotion';

const s = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66.67%;
  background-color: ${colors.white};

  & .pill {
    border: 1px solid ${colors.gray};
    border-radius: 10px;
    color: ${colors.dark2};
    flex: 1 0 160px;
    font-size: .86rem;
    height: 20px;
    line-height: 16px;
    margin: 0px 5px;
    max-width: 50%;
    min-width: 200px;
    text-align: center;
  }
  
  & .section-container {
    cursor: pointer;
    flex: 1;
    height: 20px;
    display: flex;
    align-items: center;
    margin: 0px 5px;
    min-width: 2px;
    max-width: 50%;

    &:hover {
      .section {
        opacity: 0.8;
      }
    }
  }

  & .section {
    height: 2px;
    border-radius: 1px;
    background-color: ${colors.black};
    opacity: 0.2;
    width: 100%;
    flex: 1;

  }

  & .chevron {
    cursor: pointer;
  }

  & .chevron-left {
    transform: rotate(180deg) scale(0.4);
  }

  & svg {
    transform: scale(0.4);

    & path {
      fill-opacity: 0.2;
    }

    &:hover {
      & path {
        fill-opacity: 0.8;
      }
    }
  }

.tooltip {
  > .tooltip-inner {
    text-align: center;
  }
}`;

export default s;
