import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Col,
  Row,
} from 'reactstrap';
import cx from 'classnames';
import Button from '../Button';
import FormControl from '../FormControl';
import Svg from '../Svg';
import s from './Expression.styles';

class Expression extends Component {
  handleRowClick = event => {
    const { name } = event.currentTarget.dataset;

    if (this.props.onClick) {
      this.props.onClick(name);
    }
  }

  handleOnClickDelete = event => {
    const { name } = event.currentTarget.dataset;

    this.props.onRemove(name);
  }

  render() {
    const {
      buttonText,
      connector,
      data,
      disableAdd,
      disabled,
      disableRemove,
      minSchemaLength,
      nestedConnector,
      isNestedExpression,
      nestedExpressionButtonText,
      onAdd,
      onNestedAdd,
      parentExpressionHeader,
      schema,
      stylesObj,
    } = this.props;

    const ButtonJSX = (rowId, text, handlers) => (
      <Button
        className="link-text"
        hidden={disableAdd}
        color="link"
        icon="plus"
        name={rowId}
        data-test="add-button"
        onClick={handlers.onAdd}
      >
        {text}
      </Button>
    );

    const singleRowJSX = (rowSchema, rowIndex, dataRow) => (
      rowSchema.map((item, index) => {
        let props = { ...item.props };

        // searchableSelect was not part of this condition earlier.
        // Hence, checking for item.props.autoSearch as autoSearch been introduced now
        // Older flows will work as is
        if (
          item.props.type === 'select' ||
          (item.props.type === 'searchableSelect' && item.props.autoSearch)
        ) {
          props = {
            ...item.props,
            selected: item.props.checkbox ? item.props.selected : dataRow[item.props.name],
            key: index,
            name: String(rowIndex),
            title: dataRow[item.props.name],
          };
        }

        return (
          <Col key={index} md={item.width} className={stylesObj.custom_col}>
            <FormControl {...props} />
          </Col>
        );
      }));

    const connectorJSX = (rowIndex, connectorType, dataRows) => (
      connectorType && rowIndex !== dataRows.length - 1 ?
        <div>
          <div className="vertical-line" />
          <div className="connector">{connectorType}</div>
          <div className="vertical-line" />
        </div> :
        null
    );

    const deleteIconJSX = (row, rowIndex, nested = false) => (
      <Col md="1">
        {schema.length > minSchemaLength &&
        <div className="icon-delete-container">
          <Svg
            data-name={nested ? row : (row.id ? row.id : rowIndex)}
            className="icon-delete"
            name="delete"
            onClick={this.handleOnClickDelete}
          />
        </div>}
      </Col>);

    const rowsJSX = data.map((row, rowIndex) => {
      const rowSchema = schema[rowIndex];
      // Nested Expression
      if (isNestedExpression && Array.isArray(row)) {
        return (
          <div>
            <Container className="row-container">
              <Row className="row-header"> {parentExpressionHeader} </Row>
              {row.map((dataRow, rowItemIndex) => (
                <div
                  key={dataRow.id}
                  name={dataRow.id}
                  data-name={dataRow.id}
                  onClick={this.handleRowClick}
                  className=""
                >
                  <Row>
                    {singleRowJSX(rowSchema[rowItemIndex], row.id, dataRow)}
                    {!disableRemove && deleteIconJSX(`${dataRow.id}/${dataRow.parentId}`, rowIndex, true) }
                  </Row>
                  {connectorJSX(rowItemIndex, nestedConnector, row)}
                </div>
              ))}
              <Row className="row-button">
                { isNestedExpression &&
                  ButtonJSX(row[0].parentId, nestedExpressionButtonText, { onAdd: onNestedAdd })}
              </Row>
            </Container>
            {connectorJSX(rowIndex, connector, data)}
          </div>);
      }

      return (
        <div
          key={row.id}
          name={row.id}
          data-name={row.id}
          onClick={this.handleRowClick}
          className={cx(isNestedExpression ? 'nested-expression' : 'expression')}
        >
          {rowSchema &&
            <Container className="row-container">
              {parentExpressionHeader && <Row className="row-header">{parentExpressionHeader}</Row>}
              <Row className={stylesObj ? stylesObj.custom_row : null}>
                {singleRowJSX(rowSchema, rowIndex, data[rowIndex])}
                {!disableRemove && deleteIconJSX(row, rowIndex) }
              </Row>
              <Row className="row-button">
                {isNestedExpression &&
                ButtonJSX(row.parentId, nestedExpressionButtonText, { onAdd: onNestedAdd })}
              </Row>
            </Container>
          }
          {connectorJSX(rowIndex, connector, data)}
        </div>
      );
    });

    return (
      <div className={cx(s)}>
        {!disabled && (
          <div className={stylesObj.custom_main_container}>
            {rowsJSX}
            {ButtonJSX('addButton', buttonText, { onAdd })}
          </div>
          )
        }
      </div>
    );
  }
}

Expression.defaultProps = {
  buttonText: 'Add',
  connector: null,
  disableAdd: false,
  disabled: false,
  disableRemove: false,
  minSchemaLength: 0,
  nestedConnector: null,
  isNestedExpression: false,
  nestedExpressionButtonText: '',
  parentExpressionHeader: '',
  stylesObj: {},
};

Expression.propTypes = {
  buttonText: PropTypes.string,
  connector: PropTypes.string,
  data: PropTypes.array.isRequired,
  disableAdd: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRemove: PropTypes.bool,
  minSchemaLength: PropTypes.number,
  nestedConnector: PropTypes.string,
  isNestedExpression: PropTypes.bool,
  nestedExpressionButtonText: PropTypes.string,
  parentExpressionHeader: PropTypes.string,
  schema: PropTypes.array.isRequired,
  stylesObj: PropTypes.object,
};

export default Expression;
