import * as ENDPOINTS from '../../constants/endpoints';
import { API_REQUEST } from '../../lib/api';

export const RECEIVE_WORKING_DAYS = 'PLATFORM/RECEIVE_WORKING_DAYS';
export const RECEIVE_UPDATE_WORKING_DAYS =
  'PLATFORM/RECEIVE_UPDATE_WORKING_DAYS';
export const SHOW_ALERT = 'PLATFORM/SHOW_ALERT';

export const showAlert = (color, text) => dispatch =>
  dispatch({
    type: SHOW_ALERT,
    payload: {
      color,
      text,
    },
  });

export const fetchWorkingDays = () => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'GET',
    path: ENDPOINTS.PLATFORM_WORKING_DAYS,
    name: 'fetchWorkingDays',
  }).then(
    json => {
      dispatch({
        type: RECEIVE_WORKING_DAYS,
        payload: json,
      });
    },
    err => {
      console.error(err);
      dispatch({
        type: SHOW_ALERT,
        payload: {
          color: 'danger',
          text: 'Sorry, there was an error retrieving your working days.',
        },
      });
    },
  );

export const fetchUpdateWorkingDays = workingDays => dispatch =>
  dispatch({
    type: API_REQUEST,
    method: 'PUT',
    path: ENDPOINTS.PLATFORM_WORKING_DAYS,
    name: 'fetchUpdateWorkingDays',
    body: JSON.stringify(workingDays),
  }).then(
    json => {
      dispatch({
        type: RECEIVE_UPDATE_WORKING_DAYS,
        payload: json,
      });
    },
    err => {
      console.error(err);
      dispatch({
        type: SHOW_ALERT,
        payload: {
          color: 'danger',
          text: 'Sorry, there was an error updating your working days.',
        },
      });
    },
  );
