import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Svg from '../Svg';
import s from './Tag.styles';

// NOTE this component is really weird
export class Tag extends Component {
  handleClick = () => {
    const {
      item,
      onClick,
    } = this.props;

    onClick(item);
  };

  render() {
    const { label, readOnly } = this.props;

    return (
      <div
        className={cx(s, readOnly ? 'disabled' : '')}
        onClick={!readOnly && this.handleClick}
        title={label}
        role="delete"
      >
        {label}
        {!readOnly && <Svg className="close" name="close" />}
      </div>
    );
  }
}

Tag.defaultProps = {
  onClick: () => {},
  readOnly: undefined,
};

Tag.propTypes = {
  item: PropTypes.any,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default Tag;
