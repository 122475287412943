import { API_REQUEST } from '../lib/api';
import * as ENDPOINTS from '../constants/endpoints';
import apolloClient from '../graphql/apolloClient';
import UPDATE_BIGCOMMERCE_CREDS from '../graphql/mutations/updateBigcommerce';

export const DISPLAY_ERROR = 'DISPLAY_ERROR_BIGCOMMERCE';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS_BIGCOMMERCE';

export const RECEIVE_BIGCOMMERCE_INPUT_CHANGES =
  'RECEIVE_BIGCOMMERCE_INPUT_CHANGES';
export const IS_BIGCOMMERCE_CREDS_SET_SUCCESSFULLY =
  'IS_BIGCOMMERCE_CREDS_SET_SUCCESSFULLY';
export const RESET_BIGCOMMERCE_CREDS = 'RESET_BIGCOMMERCE_CREDS';
export const RECEIVE_BIGCOMMERCE_CREDS = 'RECEIVE_BIGCOMMERCE_CREDS';

export const displayError = text => ({
  type: DISPLAY_ERROR,
  payload: { text },
});

export const displaySuccess = text => ({
  type: DISPLAY_SUCCESS,
  payload: { text },
});

export const setInputChanges = (key, value) => ({
  type: RECEIVE_BIGCOMMERCE_INPUT_CHANGES,
  payload: {
    inputData: {
      key,
      value,
    },
  },
});

export const setBcCredentialsStatus = status => ({
  type: IS_BIGCOMMERCE_CREDS_SET_SUCCESSFULLY,
  payload: {
    isSuccessfullySetCredentials: status,
  },
});

export const resetBcCredentials = currentValues => ({
  type: RESET_BIGCOMMERCE_CREDS,
  payload: {
    currentValues,
  },
});

export const receiveBigcommerceCreds = credentials => ({
  type: RECEIVE_BIGCOMMERCE_CREDS,
  payload: {
    currentValues: credentials,
    updatedValues: credentials,
  },
});

export const setBigcommerceCreds = credentials => dispatch => {
  dispatch(receiveBigcommerceCreds(credentials));
  if (Object.keys(credentials).length > 0 && credentials.storeBaseUrl) {
    dispatch(setBcCredentialsStatus(true));
  }
};
export const getRetailerMoniker = state => {
  const {
    userReducer: {
      user: {
        retailerId: currentRetailerId,
        retailerIdToRetailerInfo: {
          [currentRetailerId]: { uri_moniker: retailerMoniker } = {},
        } = {},
      } = {},
    } = {},
  } = state;

  return retailerMoniker;
};

export const fetchBigcommerceCredentials = provider => async (
  dispatch,
  getState,
) => {
  try {
    const retailerMoniker = getRetailerMoniker(getState());
    const { access_tokens: accessTokens } = await dispatch({
      type: API_REQUEST,
      method: 'GET',
      path: `${ENDPOINTS.PARTNERSHIPS_TOKEN({
        retailerMoniker,
        provider,
      })}`,
      name: 'bigcommerceGetCreds',
    });
    dispatch(setBigcommerceCreds(accessTokens[0] || {}));
  } catch (err) {
    const errMsg = err.error || err.clientMessage;
    dispatch(displayError(errMsg));
  }
};

export const saveBcCredentials = () => async (dispatch, getState) => {
  const currentState = getState();
  const {
    partnershipsBigcommerceReducer: {
      updatedValues: { clientId, clientSecret, accessToken, storeBaseUrl } = {},
    } = {},
  } = currentState;

  const retailerMoniker = getRetailerMoniker(currentState);

  try {
    const {
      data: {
        updateBigCommerceCreds: { responseMessage, statusCode },
      },
    } = await apolloClient.mutate({
      mutation: UPDATE_BIGCOMMERCE_CREDS,
      variables: {
        retailer: retailerMoniker,
        clientId,
        clientSecret,
        accessToken,
        storeBaseUrl,
      },
      fetchPolicy: 'no-cache',
    });

    if (statusCode === 200) {
      dispatch(
        setBigcommerceCreds({
          clientId,
          clientSecret,
          accessToken,
          storeBaseUrl,
        }),
      );
      dispatch(displaySuccess(responseMessage));
    } else {
      dispatch(displayError(responseMessage));
    }
  } catch (e) {
    dispatch(displayError('Failed to update bigcommerce credentials'));
  }
};

export const cancelEditingBcCredentials = () => async (dispatch, getState) => {
  const {
    partnershipsBigcommerceReducer: {
      currentValues = {},
      currentValues: { clientId, clientSecret, accessToken, storeBaseUrl } = {},
    } = {},
  } = getState();

  dispatch(resetBcCredentials(currentValues));
  if (clientId && clientSecret && accessToken && storeBaseUrl) {
    dispatch(setBcCredentialsStatus(true));
  }
};
