import {
  RECEIVE_SHIP_SETTINGS,
  UPDATE_SUCCESS,
  RECEIVE_SHIP_SETTINGS_ERRORS,
  CLEAR_SHIP_SETTINGS_FORM_AND_ERRORS,
  TOGGLE_COST_OPTIMIZATION,
} from '../actions/shipSettingsActions';
import { DISMISS_ALERT } from '../actions/uiActions';

export const initialState = {
  alert: {
    show: false,
  },
  account_key: '',
  carrierServices: {},
  shippingCountries: [],
  productCategories: [],
  shippingTags: [],
  isOriginOptimizationEnabled: false,
  dateModified: null,
};

function shipSettings(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case RECEIVE_SHIP_SETTINGS:
      return {
        ...state,
        shippingCountries: payload.shippingCountries,
        productCategories: payload.productCategories,
        shippingTags: payload.shippingTags,
        isOriginOptimizationEnabled: payload.isOriginOptimizationEnabled,
        dateModified: payload.dateModified,
      };

    case UPDATE_SUCCESS:
      return {
        ...state,
        alert: {
          color: 'success',
          show: true,
          text: 'Your changes are published successfully.',
        },
        shippingCountries: payload.shippingCountries,
        productCategories: payload.productCategories,
        shippingTags: payload.shippingTags,
        isOriginOptimizationEnabled: payload.isOriginOptimizationEnabled,
        dateModified: payload.dateModified,
      };

    case RECEIVE_SHIP_SETTINGS_ERRORS:
      return {
        ...state,
        alert: {
          color: 'danger',
          show: true,
          text: action.messages,
        },
      };

    case DISMISS_ALERT:
      return {
        ...state,
        alert: initialState.alert,
      };

    case CLEAR_SHIP_SETTINGS_FORM_AND_ERRORS:
      return {
        ...state,
        ...initialState,
      };
    case TOGGLE_COST_OPTIMIZATION:
      return {
        ...state,
        isOriginOptimizationEnabled: payload.isOriginOptimizationEnabled,
      };

    default:
      return {
        ...state,
      };
  }
}

export default shipSettings;
