import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

const StylesContext = React.createContext();

const withStyles = (...styles) => ComposedComponent => {
  class WithStyles extends React.Component {
    componentWillMount() {
      this.removeCss = this.insertCss(...styles);
    }

    componentWillUnmount() {
      if (this.removeCss) {
        setTimeout(this.removeCss, 0);
      }
    }

    insertCss = (...styles) => {
      const removeCss = styles.map(x => x._insertCss());

      return () => {
        removeCss.forEach(f => f());
      };
    };

    render() {
      return (
        <StylesContext.Provider value={this.insertCss}>
          <StylesContext.Consumer>
            {insertCss => (
              <ComposedComponent
                {...this.props}
                insertCss={insertCss(...styles)}
              />
            )}
          </StylesContext.Consumer>
        </StylesContext.Provider>
      );
    }
  }

  const displayName =
    ComposedComponent.displayName || ComposedComponent.name || 'Component';

  WithStyles.displayName = `WithStyles(${displayName})`;
  WithStyles.ComposedComponent = ComposedComponent;

  return hoistStatics(WithStyles, ComposedComponent);
};

export default withStyles;
