import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tag from '../Tag';
import cx from 'classnames';
import s from './Tagger.styles';

export class Tagger extends Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onKeyDown: PropTypes.func,
    tags: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
  }

  render() {
    const {
      className,
      onClick,
      tags,
      value,
      name,
    } = this.props;

    return (
      <div
        className={cx(s, 'tagger_container', className)}
        data-test={`${name}-tagger`}
      >
        <input
          className="form-control"
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
          value={value}
          type="text"
        />
        {tags.map((tag, index) => <Tag key={index} item={index} label={tag} onClick={onClick} />)}
      </div>
    );
  }
}

Tagger.defaultProps = {
  tags: [],
};

export default Tagger;
