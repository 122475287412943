import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Button from '../Button';
import Svg from '../Svg';
import s from './ZeroState.styles';

const ZeroState = ({
  className,
  noResults,
  objectName,
  onClickAdd,
  message,
  subMessage,
  iconName,
  i18n: {
    general: { noRecordsFound },
  },
}) => {
  const name = objectName.toLowerCase();
  let messageTitle = noRecordsFound || `No ${name} found.`;
  if (Boolean(onClickAdd) && !noResults)
    messageTitle = `No ${name} has been added yet.`;
  messageTitle = message || messageTitle;

  return (
    <div className={cx(s, className)}>
      <Svg name={iconName} height="100px" width="100px" />
      <h3>{messageTitle}</h3>
      {subMessage && <p className="text-muted">{subMessage}</p>}
      {Boolean(onClickAdd) && (
        <Button color="link" onClick={onClickAdd} className="btn-block">
          {`Add a new ${objectName}`}
        </Button>
      )}
    </div>
  );
};

ZeroState.propTypes = {
  className: PropTypes.string,
  noResults: PropTypes.bool,
  message: PropTypes.string,
  subMessage: PropTypes.string,
  onClickAdd: PropTypes.func,
  objectName: PropTypes.string,
  iconName: PropTypes.string,
  i18n: PropTypes.shape({
    general: PropTypes.shape({
      noRecordsFound: PropTypes.string,
    }),
  }),
};

ZeroState.defaultProps = {
  className: '',
  objectName: 'object',
  noResults: false,
  message: '',
  onClickAdd: null,
  subMessage: '',
  iconName: 'new',
  i18n: {
    general: {
      noRecordsFound: undefined,
    },
  },
};

export default ZeroState;
