import { RETURN_METHODS_WITH_DC } from '../../../../constants/returns/configurations';

export const returnMethodDefNestedTemplate = {
  category: 'return_methods_with_carrier',
  display_value: 'Method(s) Allowed',
  dc_criteria: {},
  type: 'returns_a_value',
  return_value_type: 'multi_carrier_methods',
  stepped: true,
  steppedItems: RETURN_METHODS_WITH_DC,
  value: [],
  values: [],
};
