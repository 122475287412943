import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import cx from 'classnames';
import { shouldUpdate } from '../../lib/helpers';
import Checkbox from '../Checkbox';
import Svg from '../Svg';
import s from './Select.styles';

export class Select extends Component {
  state = {
    isOpen: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (shouldUpdate(this.props.items, nextProps.items)) {
      return true;
    } else if (this.state.isOpen) {
      return true;
    } else if (!this.state.isOpen && nextState.isOpen) {
      return true;
    } else if (this.state.isOpen && !nextState.isOpen) {
      return true;
    } else if (!this.props.selected && nextProps.selected) {
      return true;
    } else if (this.props.divider !== nextProps.divider) {
      return true;
    } else if (this.props.selected && !nextProps.selected) {
      return true;
    } else if (shouldUpdate(this.props.selected, nextProps.selected)) {
      return true;
    } else if (this.props.disabled !== nextProps.disabled) {
      return true;
    }
    return false;
  }

  onCheckboxSelect = event => {
    const selectName = this.props.name;

    this.props.onSelect(event, selectName);
  };

  handleSelect = event => {
    const {
      name,
      onSelect,
      checkbox,
    } = this.props;

    if (onSelect) {
      this.props.onSelect(event, name);
    }

    if (!checkbox) {
      this.handleToggle();
    }
  };

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  handleCancel = () => {
    this.props.onSelect();
  };

  render() {
    const {
      color,
      checkbox,
      descriptionKey,
      disabled,
      divider,
      items,
      name,
      pullRight,
      readableKey,
      selected,
      showCloseIcon,
      placeholder,
      testAttr,
      disableKey,
    } = this.props;
    const { isOpen, id } = this.state;

    return (
      <div className={s}>
        <Dropdown
          className="Select"
          data-test={`${testAttr}-select`}
          disabled={disabled}
          id={id}
          isOpen={isOpen}
          toggle={this.handleToggle}
          title={selected ? selected[readableKey] : placeholder}
        >
          <DropdownToggle
            caret
            color={color}
            disabled={disabled}
            name={name}
            className={cx(
              selected && showCloseIcon ? 'selected-close-container' : '',
              'toggle',
            )}
          >
            <div className={selected ? 'selected' : 'placeholder'}>
              {selected ? selected[readableKey] : placeholder}
            </div>
            {selected &&
              showCloseIcon &&
              <div className="close" onClick={this.handleCancel}>
                <Svg className="icon" name="close" />
              </div>}
          </DropdownToggle>
          {checkbox ?
            <DropdownMenu
              right={pullRight}
              className="select-checkbox-container"
            >
              {items.map((item, index) => (
                <Checkbox
                  item={item}
                  index={index}
                  readableKey={readableKey}
                  onChange={this.onCheckboxSelect}
                  key={`${item[readableKey]}-${index}`}
                  isDropdown
                />
                ))}
            </DropdownMenu> :
            <DropdownMenu color="primary" right={pullRight}>
              {items.map((item, index) => {
                const isSelected = selected && selected[readableKey] === item[readableKey];
                const isDisabled = item[disableKey];
                const preventSelect = isSelected || isDisabled;

                return (
                  <div key={index}>
                    <button
                      data-test={
                          `${item.divider ? 'divider' : String(item[readableKey]).toLowerCase()}-select-item`
                        }
                      name={index}
                      title={item[readableKey]}
                      key={index}
                      onClick={preventSelect ? null : this.handleSelect}
                      className={cx(
                          'btn',
                          'select-item',
                          {
                            active: isSelected,
                            disabled: isDisabled,
                          },
                        )}
                    >
                      <span style={item.labelStyles}>
                        {item[readableKey]}
                        {descriptionKey &&
                        <span className="description">
                          {item[descriptionKey]}
                        </span>}
                      </span>
                    </button>
                    {divider === index ? <DropdownItem divider /> : null}
                  </div>
                )
              })}
            </DropdownMenu>}
        </Dropdown>
      </div>
    );
  }
}

Select.propTypes = {
  divider: PropTypes.number,
  checkbox: PropTypes.bool,
  items: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  readableKey: PropTypes.string.isRequired,
  selected: PropTypes.object,
  testAttr: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.string,
  descriptionKey: PropTypes.string,
  pullRight: PropTypes.any,
  showCloseIcon: PropTypes.bool,
  disableKey: PropTypes.string,
};

export default Select;
