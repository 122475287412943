import { criteriaDefinitions } from './criteriaDefinitions';
import { actionsDefinitions } from './actionDefinitions';
import { RULE_TYPES } from '../../../constants/returns/returnRules';

const refDestination = {
  title: 'Just Destination Reference',
  enabled: false,
  condition: {
    op: 'literal<Boolean>',
    value: false,
  },
  destinations: {
    op: 'literal<DestinationMapping>',
    value: {
      destination_reference: {
        value: 'ORD1',
        resolver_moniker: 'aps',
      },
      destination_type: 'reference',
    },
  },
};
const literalDestination = {
  title: 'Just Destination Address',
  enabled: false,
  condition: {
    op: 'literal<Boolean>',
    value: true,
  },
  destinations: {
    op: 'literal<DestinationMapping>',
    value: {
      destination_address: {
        name: 'IAD3',
        address1: '123 Anywhere',
        city: 'Los Yorkeous',
        region_code: 'YA',
        country_code: 'HZ',
        postal_code: '12345',
      },
      destination_type: 'address',
    },
  },
};
const localeEnUs = {
  title: 'Locale is en_US',
  enabled: true,
  condition: {
    op: 'eq',
    expr_a: {
      op: 'literal<String>',
      value: 'en_US',
    },
    expr_b: {
      op: 'read_env',
      type: 'String',
      json_path: '$.request.locale',
    },
  },
  destinations: {
    op: 'literal<PolicyAddress>',
    value: {
      name: 'ORD1',
      address1: '100 1st St',
      city: 'Des Plaines',
      region_code: 'IL',
      country_code: 'US',
      postal_code: '60016',
    },
  },
};
const uniqueitems = {
  condition: {
    op: 'is_list_unique_and_non_null',
    expr: {
      op: 'read_env',
      type: 'List<PolicyVendor>',
      json_path: '$.request.items[*].vendor',
    },
  },
  destinations: {
    op: 'read_env',
    type: 'PolicyAddress',
    json_path: '$.request.items[0].vendor.address',
  },
  title: 'Same vendor for all items goes back to the vendor',
  enabled: true,
};
const attributes = {
  condition: {
    op: 'in',
    needle_expr: {
      op: 'literal<String>',
      value: 'true',
    },
    haystack_expr: {
      op: 'get_items_attributes',
      attribute_name_expr: {
        op: 'literal<String>',
        value: 'is_finejewelry',
      },
    },
  },
  destinations: {
    op: 'get_destination_by_name',
    name_expr: {
      op: 'literal<String>',
      value: 'jewelrydc',
    },
  },
  title: 'If any of the items are jewelry, send it to the jewelry DC',
  enabled: true,
};
const unknownConfig = {
  condition: {
    op: 'unknown',
  },
  destinations: {
    op: 'unknown',
  },
  title: 'Unknown Config',
  enabled: true,
};

const Belk = [
  {
    condition: {
      op: 'all',
      exprs: [
        {
          op: 'is_list_unique_and_non_null',
          expr: {
            op: 'read_env',
            type: 'List<PolicyVendor>',
            json_path: '$.request.items[*].vendor',
          },
        },
        {
          op: 'eq',
          expr_a: {
            op: 'literal<String>',
            value: 'EASY STREET SALES CORP',
          },
          expr_b: {
            op: 'read_env',
            type: 'String',
            json_path: '$.request.items[0].vendor.name',
          },
        },
      ],
    },
    destinations: {
      op: 'literal<PolicyAddress>',
      value: {
        name: 'Easy Street Sales Corp',
        address1: '15 Oak Street',
        city: 'Rochester',
        region_code: 'NH',
        country_code: 'US',
        postal_code: '03839',
      },
    },
    title:
      'Vendor "Easy Street Sales Corporation" for all items goes back to the vendor',
    enabled: true,
  },
  {
    condition: {
      op: 'is_list_unique_and_non_null',
      expr: {
        op: 'read_env',
        type: 'List<PolicyVendor>',
        json_path: '$.request.items[*].vendor',
      },
    },
    destinations: {
      op: 'read_env',
      type: 'PolicyVendor',
      json_path: '$.request.items[0].vendor',
    },
    title: 'Same vendor for all items goes back to the vendor',
    enabled: true,
  },
  {
    condition: {
      op: 'in',
      needle_expr: {
        op: 'literal<String>',
        value: 'true',
      },
      haystack_expr: {
        op: 'get_items_attributes',
        attribute_name_expr: {
          op: 'literal<String>',
          value: 'is_finejewelry',
        },
      },
    },
    destinations: {
      op: 'get_destination_by_id',
      id_expr: {
        op: 'literal<String>',
        value: 'en_US:FEDEX:Jewelery',
      },
    },
    title: 'If any of the items are jewelry, send it to the jewelry DC',
    enabled: true,
  },
];

export const mockPayload = {
  policy: {
    statements: [
      refDestination,
      literalDestination,
      localeEnUs,
      uniqueitems,
      attributes,
      unknownConfig,
      ...Belk,
    ],
  },
  version: '2023-03-28',
};

const definitions = {
  criteria: criteriaDefinitions({
    ruleType: RULE_TYPES.RETURN_DESTINATION_POLICY,
  }),
  actions: [],
  actionsDefinitions,
  break_on_first_match: null,
  is_additive: null,
};

export const returnDestinationPolicyDefinitions = {
  [RULE_TYPES.RETURN_DESTINATION_POLICY]: definitions,
};
