import React from 'react';

const CaretDown = () => (
  <svg
    fill="#000000"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    transform="matrix(1, 0, 0, 1, 0, 0)"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g id="SVGRepo_iconCarrier">
      <path d="m0 6.4 12 12 12-12z" />
    </g>
  </svg>
);

export default CaretDown;
