export const DISPLAY_ERROR = 'DISPLAY_ERROR_MAGENTO';
export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS_MAGENTO';
export const ADD_NEW_TOKEN = 'ADD_NEW_TOKEN_MAGENTO';
export const RECEIVE_TOKENS = 'RECEIVE_TOKENS_MAGENTO';
export const RECEIVE_OAUTH_URL = 'RECEIVE_OAUTH_URL';
export const DELETE_OAUTH_URL = 'DELETE_OAUTH_URL';

export const displayError = text => ({
  type: DISPLAY_ERROR,
  payload: { text },
});

export const displaySuccess = text => ({
  type: DISPLAY_SUCCESS,
  payload: { text },
});

export const addNewToken = accessToken => ({
  type: ADD_NEW_TOKEN,
  payload: { accessToken },
});

export const receiveTokens = accessTokens => ({
  type: RECEIVE_TOKENS,
  payload: { accessTokens },
});

export const receiveOauthUrls = oauthUrls => ({
  type: RECEIVE_OAUTH_URL,
  payload: { oauthUrls },
});

export const deleteOauthUrls = () => ({
  type: DELETE_OAUTH_URL,
});
