import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import numeral from 'numeral';
import s from './Pagination.styles';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Svg from '../Svg';

export class NarvarPagination extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.activePage !== nextProps.activePage ||
      this.props.totalPages !== nextProps.totalPages;
  }

  render() {
    const { activePage, className, onClick, totalPages } = this.props;

    const pageItems = [];
    const maxItems = 5;
    let startPage;
    let endPage;

    if (5 < totalPages) {
      startPage = Math.max(
        Math.min(
          activePage - Math.floor(maxItems / 2, 10),
          totalPages - maxItems + 1
        ),
        1
      );
      endPage = startPage + maxItems - 1;
    } else {
      startPage = 1;
      endPage = totalPages;
    }

    for (let page = startPage; page <= endPage; page++) {
      // This makes sure that if there are two ellipsis, we dont have 3 items
      // vs the normal 5. There must be a simpler way to do this, MT
      if (
        (startPage > 1 && page === endPage && page + 1 < totalPages) ||
        (startPage > 2 && page === startPage && page + 4 < totalPages)
      ) {
      } else {
        pageItems.push(
          <PaginationItem active={page === activePage} key={page}>
            <PaginationLink onClick={onClick} data-page={page}>
              {page}
            </PaginationLink>
          </PaginationItem>
        );
      }
    }

    if (startPage > 1) {
      if (startPage > 2) {
        pageItems.unshift(
          <PaginationItem key={'ellipsisFirst'}>
            <span className={s['ellipsis']}>
              <PaginationLink>
                {'\u2026'}
              </PaginationLink>
            </span>
          </PaginationItem>
        );
      }

      pageItems.unshift(
        <PaginationItem key={1} active={false}>
          <PaginationLink
            data-page={1}
            data-test={'first-page-button'}
            onClick={onClick}
          >
            1
          </PaginationLink>
        </PaginationItem>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageItems.push(
          <PaginationItem key={'ellipsis'}>
            <span className={'ellipsis'}>
              <PaginationLink>
                {'\u2026'}
              </PaginationLink>
            </span>
          </PaginationItem>
        );
      }

      pageItems.push(
        <PaginationItem key={totalPages} active={false}>
          <PaginationLink
            data-page={totalPages}
            data-test={'last-page-button'}
            onClick={onClick}
          >
            {numeral(totalPages).format('0,0')}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return (
      <div className={cx(className, s)}>
        <Pagination>
          <PaginationItem disabled={activePage === 1}>
            <PaginationLink
              data-page={activePage - 1}
              data-test={'previous-page-button'}
              onClick={onClick}
              previous
            >
              <Svg
                name={'chevron'}
                height={'26'}
                width={'16'}
                className={'chevron-left'}
              />
            </PaginationLink>
          </PaginationItem>

          {pageItems}

          <PaginationItem disabled={activePage >= totalPages}>
            <PaginationLink
              data-page={activePage + 1}
              data-test={
                activePage < totalPages
                  ? 'next-page-button'
                  : 'next-page-button-disabled'
              }
              onClick={onClick}
              next
            >
              <Svg name={'chevron'} height={'26'} width={'16'} />
            </PaginationLink>
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

NarvarPagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  totalPages: PropTypes.number.isRequired,
};

export default NarvarPagination;
