import objectPath from 'object-path';

export const nullishConditionMutation = (condition, index, op) => {
  if (condition.op === '{{operator}}') {
    return {
      op,
      expr: objectPath.get(condition, '{{expr_a}}'),
    };
  }
  if (
    (condition.op === 'all_items' || condition.op === 'any_item') &&
    condition.exprs[0].op === '{{operator}}'
  ) {
    return {
      ...condition,
      exprs: [
        {
          op,
          expr: objectPath.get(condition, `exprs.0.{{expr_a}}`),
        },
      ],
    };
  }
  return condition;
};

export const inConditionMutation = (condition, index, op) => {
  if (condition.op === '{{operator}}') {
    return {
      op,
      needle_expr: condition['{{expr_a}}'],
      haystack_expr: {
        op: 'split',
        separator_expr: { op: 'literal<String>', value: ',' },
        string_expr: { op: 'literal<String>', value: '{{value}}' },
      },
    };
  }
  if (
    ['all_items', 'any_item', '{{aggregator}}'].includes(condition.op) &&
    condition.exprs[0].op === '{{operator}}'
  ) {
    return {
      ...condition,
      exprs: [
        {
          op,
          needle_expr: objectPath.get(condition, `exprs.0.{{expr_a}}`),
          haystack_expr: {
            op: 'split',
            separator_expr: { op: 'literal<String>', value: ',' },
            string_expr: { op: 'literal<String>', value: '{{value}}' },
          },
        },
      ],
    };
  }
  return condition;
};

export const methodMap = {
  boxless: 'boxless',
  digital: 'digital',
  concierge: 'concierge',
  concierge_partnership: 'concierge-partnership',
  home_pickup: 'home-pick-up',
  keeptheitem: 'keep-the-item',
  locker_dropoff: 'locker-drop-off',
  mail: 'mail',
  pickup: 'pickup',
  printerless_mail: 'printerless',
  self: 'ship-on-your-own',
  'store.return': 'store',
};
